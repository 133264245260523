import { InputAdornment, SxProps, TextField } from "@mui/material";
import { inputToNumber } from "@notemeal/shared-ui";

interface RecipeFormTimeInMinutesInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  sx?: SxProps;
}

export const RecipeFormTimeInMinutesInput = ({ label, value, onChange, disabled, sx }: RecipeFormTimeInMinutesInputProps) => {
  return (
    <TextField
      disabled={disabled}
      label={label}
      placeholder="–"
      value={value}
      onChange={e => onChange(e.target.value)}
      error={value !== "" && inputToNumber(value) === null}
      sx={sx}
      InputProps={{
        endAdornment: (
          <InputAdornment disablePointerEvents position="end">
            mins
          </InputAdornment>
        ),
      }}
    />
  );
};
