import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, DialogContent, DialogTitle, Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  LinkedProfile,
  NotemealProfile,
  ProfileSyncRuleWithEntities,
  assignProfilesToSyncRules,
  getEditTeamworksProfileLinksInput,
  getInitTeamworksProfilesState,
} from "@notemeal/profile-sync";
import { DraggableSpring, newId } from "@notemeal/shared-ui";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import classnames from "classnames";
import { useReducer, useState } from "react";
import { ReactEventHandlers } from "react-use-gesture/dist/types";
import LoadingBackdrop from "../../../../../components/universal/LoadingBackdrop";
import {
  EditOrgTeamworksSkippedProfile,
  OrgForLinkingFragment,
  TeamworksGroupFragment,
  TeamworksPositionLinkFragment,
  TeamworksProfileFragment,
  TeamworksTeamFragment,
  useEditOrgTeamworksProfilesMutation,
} from "../../../../../types";
import LinkModalHeader from "../shared/LinkModalHeader";
import ListsColumn from "../shared/ListColumn";
import ArchiveAthleteDialog from "./ArchiveAthlete/Dialog";
import ArchiveAthleteItem from "./ArchiveAthlete/Item";
import NotemealOnlyDialog from "./NotemealOnly/Dialog";
import NotemealOnlyItem from "./NotemealOnly/Item";
import OtherProfilesCard from "./OtherProfilesCard";
import SyncRuleDialog from "./SyncRule/Dialog";
import { LinkedProfilesList } from "./SyncRule/DisplayLists";
import DraggableProfileSyncRuleList from "./SyncRule/DraggableProfileSyncRuleList";
import SyncRuleItem from "./SyncRule/Item";
import { UnlinkArchiveProfileDialog } from "./UnlinkArchiveProfile/Dialog";
import { UnlinkProfileDialog } from "./UnlinkProfile/Dialog";
import { UnlinkTeamworksDialog } from "./UnlinkTeamworks/Dialog";
import { UnlinkTeamworksItem } from "./UnlinkTeamworks/Item";
import UnusedNotemealTeamWarning from "./UnusedNotemealTeamWarning";
import { enforceRulePriority, teamworksProfilesReducer } from "./reducer";

const useStyles = makeStyles(
  ({
    spacing,
    palette: {
      success,
      error,
      accents: { yellow },
    },
  }: Theme) =>
    createStyles({
      content: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      },
      subItem: {
        flexGrow: 1,
        margin: spacing(1, 2),
      },
      item: {
        margin: spacing(1, 2),
        flexShrink: 0,
      },
      syncStatus: {
        display: "flex",
        alignItems: "center",
        marginRight: spacing(3),
      },
      syncStatusIcon: {
        fontSize: 36,
        marginRight: spacing(),
      },
      syncStatusGreen: {
        color: success.dark,
      },
      syncStatusYellow: {
        color: yellow[400],
      },
      syncStatusRed: {
        color: error.main,
      },
      title: {
        margin: spacing(2, 0, 2, 2),
      },
      listColumn: {
        overflow: "hidden",
      },
    })
);

interface TeamworksProfileSyncContentProps {
  teamworksTeams: readonly TeamworksTeamFragment[];
  teamworksPositions: readonly TeamworksGroupFragment[];
  teamworksUserTypes: readonly TeamworksGroupFragment[];
  teamworksAthleteStatuses: readonly TeamworksGroupFragment[];
  teamworksPositionLinks: readonly TeamworksPositionLinkFragment[];
  allTeamworksProfiles: readonly TeamworksProfileFragment[];
  org: OrgForLinkingFragment;
  onClose: () => void;
  onNext: (skippedProfiles: readonly EditOrgTeamworksSkippedProfile[]) => void;
}

const TeamworksProfileSyncContent = ({
  teamworksTeams,
  teamworksUserTypes,
  teamworksAthleteStatuses,
  allTeamworksProfiles,
  teamworksPositionLinks,
  teamworksPositions,
  org,
  onClose,
  onNext,
}: TeamworksProfileSyncContentProps) => {
  const classes = useStyles();
  const { setMessage } = useSnackbar();
  const [state, dispatch] = useReducer(
    teamworksProfilesReducer,
    enforceRulePriority(
      getInitTeamworksProfilesState({
        org,
        allTeamworksProfiles,
        teamworksTeams,
        teamworksUserTypes,
        teamworksAthleteStatuses,
        teamworksPositions,
      })
    )
  );
  const [selectedProfileSyncRuleId, setSelectedProfileSyncRuleId] = useState<string | null>(null);

  const [notemealOnlyModalOpen, setNotemealOnlyModalOpen] = useState(false);
  const [archiveAthleteOpen, setArchiveAthleteOpen] = useState(false);
  const [unlinkTeamworksOpen, setUnlinkTeamworksOpen] = useState(false);

  const [profileToUnlink, setProfileToUnlink] = useState<LinkedProfile | null>(null);
  const selectedId = profileToUnlink?.notemeal?.id ?? null;
  const [unlinkProfileOpen, setUnlinkProfileOpen] = useState(false);
  const [unlinkArchiveProfileOpen, setUnlinkArchiveProfileOpen] = useState(false);

  const linkedTeamworksTeams = teamworksTeams.flatMap(teamworksTeam => (teamworksTeam.notemealTeams.length > 0 ? teamworksTeam : []));

  const syncRulesWithProfiles = assignProfilesToSyncRules(state, linkedTeamworksTeams);
  const selectedProfileSyncRule = syncRulesWithProfiles.syncRules.find(r => r.id === selectedProfileSyncRuleId);

  const handleAddRule = () => {
    const profileSyncRuleId = newId();
    dispatch({
      type: "ADD_PROFILE_SYNC_RULE",
      payload: {
        profileSyncRuleId,
      },
    });
    setSelectedProfileSyncRuleId(profileSyncRuleId);
  };

  const handleRemoveRule = (profileSyncRuleId: string) => {
    dispatch({
      type: "REMOVE_PROFILE_SYNC_RULE",
      payload: {
        profileSyncRuleId,
        linkedTeamworksTeams,
      },
    });
  };

  const [editProfiles, { loading: savingProfiles }] = useEditOrgTeamworksProfilesMutation({
    onCompleted: data => {
      setMessage("success", "Successfully created/linked/synced Profiles");
      const skippedProfiles = data.editOrgTeamworksProfiles.skippedProfiles;
      onNext(skippedProfiles);
      onClose();
    },
    onError: () => {
      setMessage("error", "Error occurred while creating/linking/syncing Profiles");
    },
  });

  const handleEditProfiles = () => {
    const input = getEditTeamworksProfileLinksInput(org, state, teamworksPositionLinks, linkedTeamworksTeams);
    if (input.type === "err") {
      setMessage("error", input.err);
    } else {
      editProfiles({
        variables: {
          input: input.ok,
        },
      });
    }
  };
  const openUnlinkProfile = (linkedProfile: LinkedProfile) => {
    setUnlinkProfileOpen(true);
    setProfileToUnlink(linkedProfile);
  };
  const openUnlinkArchiveProfile = (linkedProfile: LinkedProfile) => {
    setProfileToUnlink(linkedProfile);
    setUnlinkArchiveProfileOpen(true);
  };

  const handleUnlink = (profile: LinkedProfile, newEmail?: string, newPhone?: string) => {
    const { notemeal, teamworks } = profile;
    if (notemeal) {
      dispatch({
        type: "UNLINK_PROFILE",
        payload: { notemeal, teamworks, newEmail, newPhone },
      });
    }
    setProfileToUnlink(null);
  };

  const handleUnlinkRelink = (profileToUnlink: LinkedProfile, profileToRelink: NotemealProfile, newEmail?: string, newPhone?: string) => {
    const { teamworks, notemeal: oldNotemeal } = profileToUnlink;
    if (teamworks && oldNotemeal) {
      dispatch({
        type: "UNLINK_RELINK_PROFILE",
        payload: { teamworks, oldNotemeal, newNotemeal: profileToRelink, linkedTeamworksTeams, newEmail, newPhone },
      });
    }
  };

  const handleUnlinkArchive = (profile: LinkedProfile, newEmail?: string, newPhone?: string) => {
    const { teamworks, notemeal } = profile;
    if (teamworks && notemeal) {
      dispatch({
        type: "UNLINK_PROFILE",
        payload: { teamworks, notemeal, archiveAthlete: true, newEmail, newPhone },
      });
    }
  };

  const syncStatus = (() => {
    const currentIsNotemealLinked = org.isNotemealLinked;
    const pendingIsNotemealLinked = state.unlinkedNotemealProfiles.length === 0;
    const baseText = org.isFullySynced || (org.isTeamworksLinked && pendingIsNotemealLinked) ? "Fully Synced" : "Notemeal Linked";
    if (pendingIsNotemealLinked) {
      return (
        <div className={classes.syncStatus}>
          <CheckBoxIcon className={classnames(classes.syncStatusIcon, classes.syncStatusGreen)} />
          <Typography>{baseText}</Typography>
        </div>
      );
    } else {
      if (currentIsNotemealLinked) {
        return (
          <div className={classes.syncStatus}>
            <CancelIcon className={classnames(classes.syncStatusIcon, classes.syncStatusRed)} />
            <Typography>Breaking {baseText}!</Typography>
          </div>
        );
      } else {
        return (
          <div className={classes.syncStatus}>
            <WarningIcon className={classnames(classes.syncStatusIcon, classes.syncStatusYellow)} />
            <Typography>Not {baseText}</Typography>
          </div>
        );
      }
    }
  })();

  const unlinkedNotemealAthletes = state.unlinkedNotemealProfiles.flatMap(p => (p.__typename === "Athlete" ? p : []));
  const unlinkedNotemealProfiles = state.unlinkedNotemealProfiles;

  const matchOnProfileRules = syncRulesWithProfiles.syncRules.filter(rule => rule.matchOnProfiles);
  const nonProfileRules = syncRulesWithProfiles.syncRules.filter(rule => !rule.matchOnProfiles);

  const renderRule = (rule: ProfileSyncRuleWithEntities, bindProps?: ReactEventHandlers, spring?: DraggableSpring) => (
    <SyncRuleItem
      key={`${rule.id}-${rule.priority}`}
      profileSyncRule={rule}
      onClick={() => setSelectedProfileSyncRuleId(rule.id)}
      onRemove={() => handleRemoveRule(rule.id)}
      className={classes.item}
      allLinkedTeamworkTeams={linkedTeamworksTeams}
      bindProps={bindProps}
      spring={spring}
    />
  );

  return (
    <>
      {savingProfiles && <LoadingBackdrop open onClose={() => {}} />}
      <DialogTitle>
        <LinkModalHeader
          activeStep={1}
          nextDisabled={false}
          onNextClicked={handleEditProfiles}
          onClose={onClose}
          orgName={org.name}>
          {syncStatus}
        </LinkModalHeader>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <OtherProfilesCard
          className={classes.item}
          toDeactivateLinkedProfiles={syncRulesWithProfiles.toDeactivateLinkedProfiles}
          toDeactivateOrgMemberships={syncRulesWithProfiles.toDeactivateOrgMemberships}
        />
        <UnusedNotemealTeamWarning
          className={classes.item}
          syncRulesWithProfiles={syncRulesWithProfiles.syncRules}
          linkedTeamworksTeams={linkedTeamworksTeams}
        />
        <Grid container>
          <Grid item xs={6}>
            <NotemealOnlyItem
              className={classes.subItem}
              onClick={() => setNotemealOnlyModalOpen(true)}
              notemealOnlyCount={
                state.notemealOnlyState.notemealOnlyOrgMemberships.length + state.notemealOnlyState.notemealOnlyAthletes.length
              }
              unlinkedCount={unlinkedNotemealProfiles.length}
            />
          </Grid>
          <Grid item xs={6}>
            <ArchiveAthleteItem
              onClick={() => setArchiveAthleteOpen(true)}
              className={classes.subItem}
              archiveAthleteCount={state.archiveAthletes.length}
              unlinkedAthleteCount={unlinkedNotemealAthletes.length}
            />
          </Grid>
          <Grid item xs={6}>
            <UnlinkTeamworksItem
              onClick={() => setUnlinkTeamworksOpen(true)}
              className={classes.subItem}
              pendingUnlinkCount={state.unlinkProfiles.length}
            />
          </Grid>
        </Grid>
        <Box
          display="flex"
          flexDirection="row"
          sx={{ width: "100%", height: "100%", paddingBottom: 1 }}>
          <Box sx={{ width: "70%" }}>
            <Box
              display="flex"
              flexDirection="row"
              paddingX={2}
              justifyContent="space-between">
              <Typography variant="h3">Profile Sync Rules</Typography>
              <Button onClick={handleAddRule} sx={{ width: "fit-content" }}>
                Add Rule
              </Button>
            </Box>
            <Box height="90%" sx={{ overflow: "auto" }}>
              {matchOnProfileRules.length > 0 && (
                <Typography variant="h4" className={classes.title}>
                  Profile Based Rules
                </Typography>
              )}
              {matchOnProfileRules.map(rule => renderRule(rule))}
              {nonProfileRules.length > 0 && (
                <Typography variant="h4" className={classes.title}>
                  Team Based Rules
                </Typography>
              )}
              <DraggableProfileSyncRuleList
                rules={nonProfileRules}
                key={nonProfileRules.map(({ id }) => id).join("-")}
                renderRule={renderRule}
                ruleHeight={108}
                onChangeOrder={ids => dispatch({ type: "REORDER_OTHER_PROFILE_SYNC_RULES", payload: ids })}
              />
            </Box>
          </Box>
          <ListsColumn className={classes.listColumn}>
            <LinkedProfilesList
              selectedId={selectedId}
              profiles={state.linkedProfiles.filter(p => !p.isPending)}
              onUnlink={openUnlinkProfile}
              onUnlinkArchive={openUnlinkArchiveProfile}
            />
          </ListsColumn>
        </Box>
        {selectedProfileSyncRule && (
          <SyncRuleDialog
            open={!!selectedProfileSyncRule}
            onClose={() => setSelectedProfileSyncRuleId(null)}
            unlinkedNotemealProfiles={state.unlinkedNotemealProfiles}
            profileSyncRule={selectedProfileSyncRule}
            teamworksTeams={linkedTeamworksTeams}
            teamworksUserTypes={teamworksUserTypes}
            teamworksAthleteStatuses={teamworksAthleteStatuses}
            allTeamworksProfiles={allTeamworksProfiles}
            teamworksPositions={teamworksPositions}
            dispatch={dispatch}
          />
        )}
        {notemealOnlyModalOpen && (
          <NotemealOnlyDialog
            open={notemealOnlyModalOpen}
            onClose={() => setNotemealOnlyModalOpen(false)}
            unlinkedNotemealProfiles={unlinkedNotemealProfiles}
            notemealOnlyState={state.notemealOnlyState}
            dispatch={dispatch}
          />
        )}
        {archiveAthleteOpen && (
          <ArchiveAthleteDialog
            open={archiveAthleteOpen}
            onClose={() => setArchiveAthleteOpen(false)}
            unlinkedNotemealAthletes={unlinkedNotemealAthletes}
            archiveAthletes={state.archiveAthletes}
            onArchive={athleteId =>
              dispatch({
                type: "ARCHIVE_ATHLETE",
                payload: {
                  athleteId,
                },
              })
            }
            onUnarchive={athleteId =>
              dispatch({
                type: "UNARCHIVE_ATHLETE",
                payload: {
                  athleteId,
                },
              })
            }
          />
        )}
        {unlinkTeamworksOpen && (
          <UnlinkTeamworksDialog
            open={unlinkTeamworksOpen}
            onClose={() => setUnlinkTeamworksOpen(false)}
            state={state}
            dispatch={dispatch}
            onUnlink={openUnlinkProfile}
            onUnlinkArchive={openUnlinkArchiveProfile}
            selectedId={selectedId}
          />
        )}
        {unlinkProfileOpen && profileToUnlink && (
          <UnlinkProfileDialog
            open={unlinkProfileOpen}
            onClose={() => {
              setUnlinkProfileOpen(false);
            }}
            profileToUnlink={profileToUnlink}
            setProfileToUnlink={setProfileToUnlink}
            onUnlink={handleUnlink}
            onUnlinkRelink={handleUnlinkRelink}
            unlinkedNotemealProfiles={state.unlinkedNotemealProfiles}
          />
        )}

        {unlinkArchiveProfileOpen && profileToUnlink && (
          <UnlinkArchiveProfileDialog
            open={unlinkArchiveProfileOpen}
            onClose={() => {
              setUnlinkArchiveProfileOpen(false);
              setProfileToUnlink(null);
            }}
            profileToUnlink={profileToUnlink}
            onUnlinkArchive={handleUnlinkArchive}
          />
        )}
      </DialogContent>
    </>
  );
};

export default TeamworksProfileSyncContent;
