import { calculateChoExchangeServings, createDeselectNegativeFoodsMutationVars, createDistributeExchangeMutationVars, createDistributeMacrosMutationVars, createExchangeTargetTotalMutationVars, ExchangeMealPlan, getFullMealPlanFragment, useApolloResolverContext, useClientTimezone } from "@notemeal/shared-ui";
import ChooseAthletesForm from "apps/web/src/components/MealPlan/ChooseAthletes/ChooseAthletesContainer";
import { mealPlanChooseAthletesFormToSaveTooltips } from "apps/web/src/components/MealPlan/ChooseAthletes/utils";
import CopyOptionsForm from "apps/web/src/components/MealPlan/CopyOptionsForm";
import {
    initMealPlanCopyOptionsState,
    mealPlanCopyOptionsFormToSaveTooltips,
    mealPlanCopyOptionsReducer,
} from "apps/web/src/components/MealPlan/CopyOptionsForm/utils";

import { mealPlanFormReducer } from "apps/web/src/components/MealPlan/Form/utils";
import ActionDialogContent from "apps/web/src/components/universal/ActionDialogContent";
import {
    CopyableMealPlanFragment,
    ExchangeSetFragment,
    MealPlanBulkCopyTeamPreviewFragment,
    useBulkCopyMealPlanNewMutation,
    useEditExchangeMealTemplatesMutation,
    useEditMacroMealTemplatesMutation,
    useEditMealPlanExchangeTargetsMutation,
} from "apps/web/src/types";
import { useReducer, useState } from "react";
import { NameAndTypeForm } from "../../../../components/MealPlan/NameAndTypeForm";
import { nameAndTypeFormToSaveTooltips } from "../../../../components/MealPlan/NameAndTypeForm/utils";
import { evictMealPlanCalendarQueriesForAthlete } from "../Content/Calendar/cache";
import { dateAssignmentReducer, initDateAssignmentState } from "../Content/Calendar/Form/reducer";
import { mealPlanDateAssignmentToSaveTooltips } from "../Content/Calendar/Form/utils";
import DateAssignment from "../Content/DateAssignment";
import { FinalCopyStep, FinishCopyForm } from "./FinishCopyForm";
import { getBulkCopyMealPlanInput, initialBulkCopyMealPlanFormState } from "./utils";

const CALENDAR_STEP = "Calendar";
const NAME_TYPE_STEP = "Name and Type";
const AUTOMATION_STEP = "Automation Options";
const ATHLETES_STEP = "Choose Athletes";
const steps = [CALENDAR_STEP, NAME_TYPE_STEP, AUTOMATION_STEP, ATHLETES_STEP] as const;
type DialogStep = (typeof steps)[number];

interface BulkCopyMealPlanDialogContentProps {
  teamId: string;
  teams: readonly MealPlanBulkCopyTeamPreviewFragment[];
  exchangeSets: readonly ExchangeSetFragment[];
  mealPlan: CopyableMealPlanFragment;
  onClose: () => void;
}

const BulkCopyMealPlanDialogContent = ({ teamId, teams, exchangeSets, mealPlan, onClose }: BulkCopyMealPlanDialogContentProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const activeStepName: DialogStep = steps[activeStep];
  const [copyStep, setCopyStep] = useState<FinalCopyStep>("Copying");
  const [mealPlanProgressValue, setMealPlanProgressValue] = useState(0);

  const clientTimezone = useClientTimezone();
  const [dateAssignmentState, dateAssignmentDispatch] = useReducer(dateAssignmentReducer, initDateAssignmentState(clientTimezone));
  const [isAutoSuggestionsEnabled, setIsAutoSuggestionsEnabled] = useState(mealPlan.isAutoSuggestionsEnabled);
  const [mptFormState, mptFormDispatch] = useReducer(mealPlanFormReducer, initialBulkCopyMealPlanFormState(mealPlan, exchangeSets));
  const [selectedAthleteIds, setSelectedAthleteIds] = useState<string[]>([]);
  const [mptCopyOptionsFormState, mptCopyOptionsFormDispatch] = useReducer(
    mealPlanCopyOptionsReducer,
    initMealPlanCopyOptionsState({ mealPlan, type: mptFormState.type })
  );

  const apolloResolverContext = useApolloResolverContext();
  const [editMealPlanExchangeTargets] = useEditMealPlanExchangeTargetsMutation();
  const [editExchangeMealTemplates] = useEditExchangeMealTemplatesMutation();
  const [editMacroMealTemplates] = useEditMacroMealTemplatesMutation();

  const mealPlanProgressTarget = selectedAthleteIds.length * (mptFormState.type === "exchange" ? 3 : 1);
  const automationStrategy = mptCopyOptionsFormState.automationStrategy;

  const handleCalculateExchangeTotals = (mealPlanId: string) => {
    const mealPlan = getFullMealPlanFragment(mealPlanId, apolloResolverContext) as ExchangeMealPlan;
    const { vegetableServings, fruitServings, dairyServings } =
      automationStrategy === "ADJUST"
        ? calculateChoExchangeServings(mealPlan) // Calculate for each athlete using goal + macroProtocol
        : mptCopyOptionsFormState; // Use the fixed values
    const exchangeTargets = createExchangeTargetTotalMutationVars({
      mealPlan,
      vegetableServings,
      fruitServings,
      dairyServings,
    });

    if (["FIX_AND_ADJUST", "ADJUST"].includes(automationStrategy)) {
      editMealPlanExchangeTargets({
        variables: { input: { mealPlanId, exchangeTargets } },
        update: () => {
          setMealPlanProgressValue(value => value + 1);
          handleDistributeExchangeTargets(mealPlanId);
        },
      });
    } else {
      setMealPlanProgressValue(value => value + 1);
      handleDistributeExchangeTargets(mealPlanId);
    }
  };

  const handleDistributeExchangeTargets = (mealPlanId: string) => {
    const mealPlan = getFullMealPlanFragment(mealPlanId, apolloResolverContext);
    const variables = createDistributeExchangeMutationVars(mealPlan as ExchangeMealPlan);
    if (mptCopyOptionsFormState.automationStrategy !== "COPY") {
      editExchangeMealTemplates({
        variables,
        update: () => {
          setMealPlanProgressValue(value => value + 1);
          handleDeselectNegativeFoods(mealPlanId);
        },
      });
    } else {
      setMealPlanProgressValue(value => value + 1);
      handleDeselectNegativeFoods(mealPlanId);
    }
  };

  const handleDeselectNegativeFoods = (mealPlanId: string) => {
    const mealPlan = getFullMealPlanFragment(mealPlanId, apolloResolverContext);
    const variables = createDeselectNegativeFoodsMutationVars(mealPlan as ExchangeMealPlan);
    editExchangeMealTemplates({
      variables,
      update: () => {
        setMealPlanProgressValue(value => value + 1);
      },
    });
  };

  const handleDistributeMacroTargets = (mealPlanId: string) => {
    const mealPlan = getFullMealPlanFragment(mealPlanId, apolloResolverContext);
    const variables = createDistributeMacrosMutationVars(mealPlan);
    if (mptCopyOptionsFormState.automationStrategy === "ADJUST") {
      editMacroMealTemplates({
        variables,
        update: () => {
          setMealPlanProgressValue(value => value + 1);
        },
      });
    } else {
      setMealPlanProgressValue(value => value + 1);
    }
  };

  const [bulkCopyMealPlan] = useBulkCopyMealPlanNewMutation({
    update: (cache, { data }) => {
      if (data) {
        const copiedMealPlans = data.bulkCopyMealPlanNew.mealPlans;
        copiedMealPlans.forEach(({ id, athlete }) => {
          evictMealPlanCalendarQueriesForAthlete({
            athleteId: athlete.id,
            cache,
            type: "Create",
          });
        });
      }
    },
    onCompleted: data => {
      if (mptFormState.type === "exchange") {
        // Calculate exchange totals, then distribute targets, then deselect foods
        data.bulkCopyMealPlanNew.mealPlans.forEach(mpt => {
          handleCalculateExchangeTotals(mpt.id);
        });
      } else {
        // Distribute macro totals
        data.bulkCopyMealPlanNew.mealPlans.forEach(mpt => {
          handleDistributeMacroTargets(mpt.id);
        });
      }
    },
  });

  const getCanSaveTooltipItems = (): string[] => {
    switch (activeStepName) {
      case CALENDAR_STEP:
        return mealPlanDateAssignmentToSaveTooltips(dateAssignmentState);
      case NAME_TYPE_STEP:
        return nameAndTypeFormToSaveTooltips(mptFormState);
      case AUTOMATION_STEP:
        return mealPlanCopyOptionsFormToSaveTooltips(mptCopyOptionsFormState);
      case ATHLETES_STEP:
        return mealPlanChooseAthletesFormToSaveTooltips(selectedAthleteIds);
      default:
        return [];
    }
  };

  const runSideEffects = () => {
    switch (activeStepName) {
      case ATHLETES_STEP:
        const input = getBulkCopyMealPlanInput(
          selectedAthleteIds,
          mealPlan.id,
          mptFormState,
          dateAssignmentState,
          isAutoSuggestionsEnabled,
          mptCopyOptionsFormState
        );
        if (!input) {
          return;
        }

        bulkCopyMealPlan({
          variables: {
            input,
          },
        });
        break;
    }
  };

  return (
    <>
      <ActionDialogContent
        title={"Copy Meal Plans to Other Athletes"}
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        runSideEffects={runSideEffects}
        getCanSaveTooltipItems={getCanSaveTooltipItems}
        onClose={onClose}
      >
        {activeStepName === CALENDAR_STEP ? (
          <DateAssignment
            state={dateAssignmentState}
            dispatch={dateAssignmentDispatch}
            isAutoSuggestionsEnabled={isAutoSuggestionsEnabled}
            setIsAutoSuggestionsEnabled={setIsAutoSuggestionsEnabled}
            warning={
              "* This new meal plan will automatically override any calendar conflicts with current meal plans assigned to the athlete."
            }
          />
        ) : activeStepName === NAME_TYPE_STEP ? (
          <NameAndTypeForm
            state={mptFormState}
            dispatch={mptFormDispatch}
            editMode
            copyForm={true} />
        ) : activeStepName === AUTOMATION_STEP ? (
          <CopyOptionsForm
            toCopyMealPlan={mealPlan}
            state={mptCopyOptionsFormState}
            dispatch={mptCopyOptionsFormDispatch} />
        ) : activeStepName === ATHLETES_STEP ? (
          <ChooseAthletesForm
            teams={teams}
            teamId={teamId}
            selectedAthleteIds={selectedAthleteIds}
            setSelectedAthleteIds={setSelectedAthleteIds}
            toCopyMealPlan={mealPlan}
          />
        ) : (
          <FinishCopyForm
            activeStep={copyStep}
            setActiveStep={setCopyStep}
            mealPlanType={mptFormState.type}
            athleteIds={selectedAthleteIds}
            automationStrategy={automationStrategy}
            mealPlanProgressValue={mealPlanProgressValue}
            mealPlanProgressTarget={mealPlanProgressTarget}
            onClose={onClose}
          />
        )}
      </ActionDialogContent>
    </>
  );
};

export default BulkCopyMealPlanDialogContent;
