import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/CheckCircle";
import { Card, CardActionArea, Dialog, DialogContent, DialogTitle, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import { KdsTicketMealMenuPlateOrderFragment, KdsTicketMenuOrderFragment, useKdsPrintAllDialogMenusQuery } from "../../types";
import KdsPrintableLabel from "./Ticket/PrintableLabel";
import { GenericOrderPreview } from "./Ticket/types";
import { convertToOrderWithDiningStationItems } from "./Ticket/utils";
import { MakeHandleAirPrintFn, PrintMethod, WebPrinterTicketOrder } from "./types";
import { createTicketOrderForWebPrinter } from "./utils";

interface KdsPrintAllDialogProps {
  onClose: () => void;
  open: boolean;
  mealMenuIds: string[];
  diningStationIds: string[];
  menuOrderItemIdsToDiningStationName: Record<string, string>;
  sortedAndFilteredOrders: GenericOrderPreview[];
  makeHandleAirPrintMany: MakeHandleAirPrintFn;
  printMethod: PrintMethod;
  handleWebPrintMany?: (sortedOrders: WebPrinterTicketOrder[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noprint: {
      "@media print": {
        display: "none !important",
      },
      "@media screen": {
        display: "block",
      },
    },
    red: { color: theme.palette.error.light },
    green: { color: theme.palette.success.light },
    center: {
      padding: theme.spacing(3),
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      fontSize: 96,
    },
    dialogTitleRoot: {
      paddingBottom: 0,
      "@media print": {
        display: "none !important",
      },
      "@media screen": {
        display: "block",
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    square: {
      height: "200px",
      width: "200px",
      display: "flex",
    },
    flexCenterColumn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    spacer: {
      width: theme.spacing(3),
    },
  })
);

const KdsPrintAllDialog = ({
  onClose,
  open,
  sortedAndFilteredOrders,
  menuOrderItemIdsToDiningStationName,
  mealMenuIds,
  diningStationIds,
  makeHandleAirPrintMany,
  handleWebPrintMany,
  printMethod,
}: KdsPrintAllDialogProps) => {
  const classes = useStyles();

  const handlePrintAllOrders = async () => {
    if (printMethod === "WebPrint" && handleWebPrintMany) {
      await handleWebPrintMany(
        sortedAndFilteredOrders.map((orderPreview: GenericOrderPreview) => {
          return createTicketOrderForWebPrinter(orderPreview, flatOrders, menuOrderItemIdsToDiningStationName);
        })
      );
    } else {
      makeHandleAirPrintMany(() => "all-kds-orders")();
    }
  };
  const createPrintableOrder = (
    prev: GenericOrderPreview,
    fullOrders: (KdsTicketMenuOrderFragment | KdsTicketMealMenuPlateOrderFragment)[]
  ) => {
    const fullOrder = fullOrders.find(fo => fo.id === prev.id && fo.__typename === prev.__typename);
    if (fullOrder) {
      return (
        <KdsPrintableLabel
          key={fullOrder.id}
          order={convertToOrderWithDiningStationItems({
            order: fullOrder,
            menuOrderItemIdsToDiningStationName,
          })}
        />
      );
    } else {
      return null;
    }
  };

  const { data, loading } = useKdsPrintAllDialogMenusQuery({
    variables: { mealMenuIds, diningStationIds },
    fetchPolicy: "network-only",
  });
  const flatMenuOrders = data && !loading ? data.mealMenus.flatMap(mm => mm.allOrders) : [];
  const flatMealMenuPlateOrders = data && !loading ? data.mealMenus.flatMap(mm => mm.allMealMenuPlateOrders) : [];

  const flatOrders = [...flatMenuOrders, ...flatMealMenuPlateOrders];
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      classes={{ root: classes.noprint }}>
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <div className={classes.header}>
          <Typography variant="h4">Print all {sortedAndFilteredOrders.length} orders?</Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.center}>
        {!data || loading ? (
          <Loading progressSize="lg" />
        ) : (
          <>
            <Card className={classes.square}>
              <CardActionArea
                className={classes.flexCenterColumn}
                onClick={() => {
                  onClose();
                }}
              >
                <CancelIcon classes={{ root: classes.icon }} color="error" />
                <Typography color="textSecondary" variant="h4">
                  Cancel
                </Typography>
              </CardActionArea>
            </Card>
            <div className={classes.spacer} />
            <Card className={classes.square}>
              <CardActionArea
                className={classes.flexCenterColumn}
                onClick={async () => {
                  await handlePrintAllOrders();
                  onClose();
                }}
              >
                <CheckIcon classes={{ root: classes.icon }} className={classes.green} />
                <Typography color="textSecondary" variant="h4">
                  Print
                </Typography>
              </CardActionArea>
            </Card>
            <div id="all-kds-orders">{sortedAndFilteredOrders.map(sfo => createPrintableOrder(sfo, flatOrders))}</div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default KdsPrintAllDialog;
