import { Loading } from "@notemeal/shared-ui";
import { useState } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { ConfirmationDialog } from "../../../../componentLibrary";
import { initialNoteFormState as defaultInitialFormState } from "../../../../components/Notes/Form/utils";
import NotesModal from "../../../../components/Notes/Modal";
import NotesTable from "../../../../components/Notes/Table";
import { RefetchQueriesContextProvider } from "../../../../contexts/RefetchQueries";
import {
    AthleteNotesDocument,
    AthleteSummaryDocument,
    NoteWithTypeFragment,
    useAthleteNotesQuery,
    useDeleteNoteMutation,
} from "../../../../types";

export const AthleteNotesPage = () => {
  const { athleteId: maybeAthleteId } = useParams();
  const athleteId = maybeAthleteId || "";
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteableNote, setDeleteableNote] = useState<NoteWithTypeFragment | null>(null);
  // ^ Serves as "deleteModalOpen" as well
  const [initialFormState, setInitialFormState] = useState(defaultInitialFormState);
  const getAthleteNotesVars = { id: athleteId };

  const { data, loading } = useAthleteNotesQuery({
    variables: getAthleteNotesVars,
  });
  const refetchQueries = [
    { query: AthleteNotesDocument, variables: getAthleteNotesVars },
    {
      query: AthleteSummaryDocument,
      variables: { id: athleteId, inLastDays: 3 },
    },
  ];
  const [deleteNote] = useDeleteNoteMutation({
    refetchQueries,
  });

  if (loading || !data) {
    return <Loading progressSize="lg" />;
  }

  const handleRowClick = ({ id, type, content, datetime }: NoteWithTypeFragment) => {
    setInitialFormState({
      id,
      type: type,
      content,
      datetime: new Date(datetime),
    });
    setModalOpen(true);
  };
  const handleDeleteRowClick = (row: NoteWithTypeFragment) => {
    setDeleteableNote(row);
  };
  const handleModalClose = () => {
    setInitialFormState(defaultInitialFormState);
    setModalOpen(false);
  };
  const handleDeleteModalClose = () => {
    setDeleteableNote(null);
  };

  return (
    <RefetchQueriesContextProvider refetchQueries={refetchQueries}>
      <>
        <NotesTable
          tableRows={[...data.athlete.notes]}
          onClickRow={handleRowClick}
          onClickDeleteRow={handleDeleteRowClick}
          onClickAddEntry={() => setModalOpen(true)}
        />
        {modalOpen && (
          <NotesModal
            athleteId={athleteId}
            open={modalOpen}
            initialFormState={initialFormState}
            onClose={() => handleModalClose()} />
        )}
        {deleteableNote && (
          <ConfirmationDialog
            open={!!deleteableNote}
            title="Delete Note"
            message="Are you sure that you would like to delete the note?"
            onCancel={() => handleDeleteModalClose()}
            onConfirm={() => {
              deleteNote({ variables: { input: { id: deleteableNote.id } } });
              handleDeleteModalClose();
            }}
            variant="containedDestructive"
          />
        )}
      </>
    </RefetchQueriesContextProvider>
  );
};
