import { inputToNumber } from "@notemeal/shared-ui";
import { scaleNutrientValues } from "./utils";
import { BaseFoodFormType } from "./FoodFormSchema";
import { NutrientName } from "../../../usda/nutrients";
import {newId} from "@notemeal/shared-ui";
import { ZERO_MACROS } from "@notemeal/shared-utils-macro-protocol";
import { FoodServingType } from "./FoodServingSchema";
import { getGramValueOrNullForUnit } from "../../Serving/Units/utils";
import { UnitWithConversionsFragment } from "apps/web/src/types";
import { ServingUnitsType } from "./ServingUnitsSchema";

export type IndexedServing = FoodServingType & { index: number };

export const incrementServingDefaultAmount = (serving: FoodServingType): number => {
  return (serving.defaultAmount || 0) + 1;
};

export const decrementServingDefaultAmount = (serving: FoodServingType): number => {
  return Math.max((serving.defaultAmount || 0) - 1, 1);
};

export const createNewFoodServingState = (isDefault: boolean): FoodServingType => {
  return {
    id: newId(),
    isNewServing: true,
    units: {
      customUnits: "",
      unitPrefix: null,
      unit: null,
      unitSuffix: null,
    },
    weight: 0,
    defaultAmount: 1,
    isRecipeServingOnly: false,
    ...ZERO_MACROS,
    usdaWeightSeq: null,
    isDefault,
    nutrientAmounts: null,
  };
};

export const changeNutrientAmounts = ({
  foodForm,
  nutrientName,
  nutrientValueInput,
}: {
  foodForm: BaseFoodFormType;
  nutrientName: NutrientName;
  nutrientValueInput: string;
}) => {
  const { servings } = foodForm;

  const defaultServing = servings.find(s => s.isDefault);
  const newNutrientAmountsManualEntry = scaleNutrientValues(
    {
      ...defaultServing?.nutrientAmounts,
      [nutrientName]: nutrientValueInput,
    },
    100,
    (defaultServing?.weight || 100) * (defaultServing?.defaultAmount || 1)
  );

  const nutrientValueAmount = inputToNumber(nutrientValueInput);
  const newServings = servings.map(s =>
    s.isDefault
      ? {
          ...s,
          nutrientAmounts: {
            ...s.nutrientAmounts,
            [nutrientName]: nutrientValueInput,
          },
        }
      : {
          ...s,
          nutrientAmounts:
            nutrientValueAmount && s.weight
              ? {
                  ...s.nutrientAmounts,
                  [nutrientName]: ((nutrientValueAmount * s.weight) / 100).toString(),
                }
              : s.nutrientAmounts,
        }
  );

  return {
    newNutrientAmountsManualEntry,
    newServings,
  };
};

export const changeUnitsForServing = ({
  units,
  unitsData,
  gramUnit,
}: {
  units: ServingUnitsType;
  unitsData: readonly UnitWithConversionsFragment[];
  gramUnit: UnitWithConversionsFragment;
}) => {
  const newUnitId = units.unit?.id || null;
  const unitWeight = newUnitId ? getGramValueOrNullForUnit(unitsData, newUnitId, gramUnit) : null;

  return {
    newUnits: units,
    newUnitWeight: unitWeight,
  };
};

export const getValuesForNewWeight = ({
  serving,
  newInput,
  foodForm,
}: {
  serving: FoodServingType;
  newInput: string | null;
  foodForm: BaseFoodFormType;
}) => {
  const { choPer100g, proPer100g, fatPer100g, nutrientAmountsManualEntry } = foodForm;
  const { defaultAmount, cho, pro, fat, nutrientAmounts } = serving;

  const weight = inputToNumber(newInput || "");

  const newNutrientAmounts = getNewNutrientAmounts({
    nutrientAmountsManualEntry,
    nutrientAmounts,
    weight,
    defaultAmount,
  });
  const {
    cho: newCho,
    pro: newPro,
    fat: newFat,
  } = getNewMacros({
    choPer100g,
    proPer100g,
    fatPer100g,
    cho,
    pro,
    fat,
    weight,
    defaultAmount,
  });

  return {
    newWeight: weight,
    newNutrientAmounts,
    newCho,
    newPro,
    newFat,
  };
};

export const getValuesForNewDefaultAmount = ({
  serving,
  newInput,
  foodForm,
}: {
  serving: FoodServingType;
  newInput: string | null;
  foodForm: BaseFoodFormType;
}) => {
  const { choPer100g, proPer100g, fatPer100g, nutrientAmountsManualEntry } = foodForm;

  const defaultAmount = inputToNumber(newInput || "");
  const { cho, pro, fat, weight, nutrientAmounts } = serving;

  const newNutrientAmounts = getNewNutrientAmounts({
    nutrientAmountsManualEntry,
    nutrientAmounts,
    weight,
    defaultAmount,
  });
  const {
    cho: newCho,
    pro: newPro,
    fat: newFat,
  } = getNewMacros({ choPer100g, proPer100g, fatPer100g, cho, pro, fat, weight, defaultAmount });

  return {
    newDefaultAmount: defaultAmount,
    newNutrientAmounts,
    newCho,
    newPro,
    newFat,
  };
};

interface ChangeMacroServingProps {
  macro: "cho" | "pro" | "fat";
  serving: FoodServingType;
  newInput: string | null;
  indexedServings: IndexedServing[];
}

export const getNewValuesForMacro = ({ macro, serving, newInput, indexedServings }: ChangeMacroServingProps) => {
  if (!serving.isDefault) {
    throw new Error("Cannot change non-default serving macro");
  }

  const { weight: servingWeight, defaultAmount: servingDefaultAmount } = serving;
  const newServingMacro = newInput && inputToNumber(newInput);
  const macroValuePer100g =
    servingWeight && servingDefaultAmount && newServingMacro ? (newServingMacro / (servingWeight * servingDefaultAmount)) * 100 : 0;

  const newMacros: IndexedServing[] = indexedServings.map(s =>
    s.id === serving.id
      ? { ...s, [macro]: inputToNumber(newInput || "") }
      : {
          ...s,
          [macro]: getNewMacroValue({ macroValuePer100g, macroValue: s[macro], weight: s.weight, defaultAmount: s.defaultAmount }),
        }
  );

  return {
    macroValuePer100g,
    newMacros,
  };
};

const getNewMacros = ({
  choPer100g,
  proPer100g,
  fatPer100g,
  cho,
  pro,
  fat,
  weight,
  defaultAmount,
}: {
  choPer100g: number | null;
  proPer100g: number | null;
  fatPer100g: number | null;
  cho: number | null;
  pro: number | null;
  fat: number | null;
  weight: number | null;
  defaultAmount: number | null;
}) => {
  return {
    cho: getNewMacroValue({ macroValuePer100g: choPer100g, macroValue: cho, weight, defaultAmount }),
    pro: getNewMacroValue({ macroValuePer100g: proPer100g, macroValue: pro, weight, defaultAmount }),
    fat: getNewMacroValue({ macroValuePer100g: fatPer100g, macroValue: fat, weight, defaultAmount }),
  };
};

const getNewMacroValue = ({
  macroValuePer100g,
  macroValue,
  weight,
  defaultAmount,
}: {
  macroValuePer100g: number | null;
  macroValue: number | null;
  weight: number | null;
  defaultAmount: number | null;
}) => {
  return macroValuePer100g && weight ? ((defaultAmount || 1) * macroValuePer100g * weight) / 100 : macroValue;
};

const getNewNutrientAmounts = ({
  nutrientAmountsManualEntry,
  nutrientAmounts,
  weight,
  defaultAmount,
}: {
  nutrientAmountsManualEntry: Record<string, string> | null;
  nutrientAmounts: Record<string, string> | null;
  weight: number | null;
  defaultAmount: number | null;
}) => {
  return nutrientAmountsManualEntry && weight
    ? scaleNutrientValues(nutrientAmountsManualEntry, weight * (defaultAmount || 1), 100)
    : nutrientAmounts;
};
