import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Close from "@mui/icons-material/Close";
import { Box, Button, Divider, IconButton, MenuItem, TextField } from "@mui/material";
import { grayBackground } from "@notemeal/palette";
import { parseTime, serializeTime } from "@notemeal/utils-date-time";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import { eachMinuteOfInterval, format, startOfToday, startOfTomorrow, subMinutes } from "date-fns";
import React from "react";
import { Controller, UseFormReturn, useFieldArray } from "react-hook-form";
import TimezoneSelect from "../../../../Menus/SelectComponents/TimezoneSelect";
import { GoalConfigurationFormType } from "../GoalConfigurationSchema";

interface GoalConfigurationRemindersFormProps {
  form: UseFormReturn<GoalConfigurationFormType>;
}

export const GoalConfigurationRemindersForm = ({ form }: GoalConfigurationRemindersFormProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = form;

  const remindersArray = useFieldArray({ control, name: "reminders" });
  const timeFormat = "hh:mm aa";

  const timeOptions = () => {
    const quarterBeforeEndTime = subMinutes(startOfTomorrow(), 15);
    // avoid erroring when the interval would be 00:00:00 to 00:00:00
    return eachMinuteOfInterval({ start: startOfToday(), end: quarterBeforeEndTime }, { step: 15 }).map(serializeTime);
  };
  const type = watch("type");
  const task = watch("task");
  const emoji = type?.defaultEmoji ?? null;
  const taskName = task ?? null;
  const reminderMessage = taskName ? `${emoji ?? ""} Friendly reminder to ${taskName}` : "";

  return (
    <>
      <Box>
        <SectionHeader header="Reminder (optional)" subcopy="Set up reminders in order to send push notifications to the athlete" />
      </Box>
      <Box sx={{ display: "flex", gap: 2, pb: 2, flexDirection: "column" }}>
        {remindersArray.fields.length > 0 && (
          <Controller
            name={"timezone"}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TimezoneSelect
                sx={{ width: "300px" }}
                value={field.value ?? null}
                onChange={field.onChange}
                error={Boolean(errors.timezone)}
                helperText={errors.timezone?.message}
              />
            )}
          />
        )}
        {remindersArray.fields.map((reminder, index) => {
          const currentReminderError = errors?.reminders?.[index];
          return (
            <Box
              key={reminder.id}
              sx={{
                display: "flex",
                gap: 2,
                py: 1,
                px: 2,
                borderRadius: 0.5,
                backgroundColor: grayBackground,
                alignContent: "center",

                minHeight: "94px",
              }}
            >
              <Controller
                name={`reminders.${index}.message`}
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    label="Reminder"
                    {...field}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name={`reminders.${index}.time`}
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    sx={{ minWidth: "150px" }}
                    error={Boolean(currentReminderError)}
                    helperText={currentReminderError?.time?.message ?? ""}
                    label="Time"
                    placeholder="Select time"
                    select
                    SelectProps={{ IconComponent: AccessTimeIcon }}
                  >
                    {timeOptions().map(time => {
                      return (
                        <MenuItem key={time} value={time}>
                          {format(parseTime(time), timeFormat)}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />
              <IconButton
                size="small"
                sx={{ justifySelf: "right", alignSelf: "center" }}
                onClick={() => remindersArray.remove(index)}>
                <Close />
              </IconButton>
            </Box>
          );
        })}
        <Button
          sx={{ width: "fit-content" }}
          variant="outlined"
          onClick={() =>
            remindersArray.append({
              time: "",
              message: reminderMessage,
            })
          }
          disabled={remindersArray.fields.length > 5 || !taskName}
        >
          Add Reminder
        </Button>
      </Box>
      <Divider />
    </>
  );
};
