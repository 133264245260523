import { ApolloError } from "@apollo/client";
import { TableCell, TableRow } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { useState } from "react";
import TablePage from "../../../../components/universal/TablePage";
import {
    ActiveTransferFragment,
    useActiveTransfersOffsetConnectionQuery,
    useRecieveAthleteMutation,
    useReclaimAthleteMutation,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import { TransferModal } from "../../../../views/Transfers/TransferModal/TransferModal";

export const TransfersPage = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [focusedTransfer, setFocusedTransfer] = useState<ActiveTransferFragment | null>(null);
  const { setMessage } = useSnackbar();
  const { formatDateWithLocale } = useDateFormatting();

  const paginationHooks = useOffsetPagination();
  const { limit, offset } = paginationHooks;

  const {
    data: activeTransfersData,
    loading,
    error,
  } = useActiveTransfersOffsetConnectionQuery({
    variables: { input: { limit, offset } },
    fetchPolicy: "network-only",
  });

  const onError = (e: ApolloError) => {
    setMessage("error", e.message);
  };

  const [reclaimAthlete] = useReclaimAthleteMutation({
    onCompleted: () => {
      if (focusedTransfer?.athlete) {
        setMessage("success", `Reclaimed ${focusedTransfer.athlete.lastName}, ${focusedTransfer.athlete.firstName} from Transfer Portal`);
      }
      setModalOpen(false);
    },
    onError,
  });

  const [recieveAthlete] = useRecieveAthleteMutation({
    onCompleted: () => {
      if (focusedTransfer?.athlete) {
        setMessage("success", `Added ${focusedTransfer.athlete.lastName}, ${focusedTransfer.athlete.firstName} to the organization`);
      }
      setModalOpen(false);
    },
    onError,
  });

  const handleRowClick = (row: ActiveTransferFragment) => {
    setFocusedTransfer(row);
    setModalOpen(true);
  };

  const handleReclaimAthlete = () => {
    if (focusedTransfer) {
      reclaimAthlete({ variables: { input: { id: focusedTransfer.id } } });
    }
  };

  const handleRecieveAthlete = (teamId: string) => {
    if (focusedTransfer) {
      recieveAthlete({ variables: { input: { id: focusedTransfer.id, teamId } } });
    }
  };

  return error ? (
    <div>Error! {error.message}</div>
  ) : (
    <>
      <TablePage
        size="medium"
        loading={loading}
        header={<></>}
        tableHeaderRow={
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Birthdate</TableCell>
            <TableCell>Releasing Org</TableCell>
            <TableCell>Org Group</TableCell>
            <TableCell align="right">Date Initiated</TableCell>
          </TableRow>
        }
        tableBodyRows={
          !activeTransfersData
            ? null
            : activeTransfersData.activeAthleteTransferOffsetConnection.edges.map(row => {
                const createdAt = formatDateWithLocale(new Date(row.createdAt));
                const birthDateString = row.athlete?.birthDate;
                const birthDate = birthDateString ? formatDateWithLocale(new Date(birthDateString)) : "-";
                return (
                  <TableRow
                    sx={{ cursor: "pointer" }}
                    key={row.id}
                    hover
                    onClick={() => handleRowClick(row)}>
                    <TableCell>{row.athlete?.firstName ?? "-"}</TableCell>
                    <TableCell>{row.athlete?.lastName ?? "-"}</TableCell>
                    <TableCell>{birthDate}</TableCell>
                    <TableCell>{row.releasedByOrg?.name ?? "-"}</TableCell>
                    <TableCell>{row.orgGroup.name}</TableCell>
                    <TableCell align="right">{createdAt}</TableCell>
                  </TableRow>
                );
              })
        }
        total={activeTransfersData?.activeAthleteTransferOffsetConnection.pageInfo.total ?? 0}
        paginationHooks={paginationHooks}
      />
      {modalOpen && focusedTransfer && (
        <TransferModal
          transfer={focusedTransfer}
          onReclaim={handleReclaimAthlete}
          onRecieve={handleRecieveAthlete}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};
