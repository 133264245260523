import { z } from "zod";
import {
  AnthropometryEntryTypeSchema,
  applyInvalidMassValueError,
  BoneMineralDensityZScoreSchema,
  DateSchema,
  FloatSchema,
  PercentBodyFatSchema,
  VisceralFatAreaSchema,
} from "@notemeal/validators";
import {
  CreateMetricAnthropometryEntryInput,
  EditMetricAnthropometryEntryInput,
  MetricAnthropometryEntryFormInput,
  SexType,
} from "../../../types";
import { roundToHundredthsFloor } from "@notemeal/shared-utils-macro-protocol";

export const BaseMetricAnthropometryEntrySchema = z.object({
  id: z.string().optional(),

  heightInCm: FloatSchema.nullable().optional(),
  weightInKg: FloatSchema,
  leanBodyMassInKg: FloatSchema.nullable().optional(),
  bodyFatMassInKg: FloatSchema.nullable().optional(),
  dryLeanMassInKg: FloatSchema.nullable().optional(),
  skeletalMuscleMassInKg: FloatSchema.nullable().optional(),
  trunkFatInKg: FloatSchema.nullable().optional(),

  leanBodyMassIsRequired: z.boolean(),
  percentBodyFat: PercentBodyFatSchema.nullable().optional(),
  type: AnthropometryEntryTypeSchema,
  date: DateSchema,
  boneMass: FloatSchema.nullable().optional(),
  boneMineralDensityZScore: BoneMineralDensityZScoreSchema.nullable().optional(),
  visceralFatArea: VisceralFatAreaSchema.nullable().optional(),
  comment: z.string().nullable(),
});

export const MetricAnthropometryEntrySchema = (options?: { overrides: z.ZodObject<{}> }) =>
  BaseMetricAnthropometryEntrySchema.merge(options?.overrides ?? z.object({})).superRefine((state, ctx) => {
    const { id, comment, type, date, weightInKg, ...nonWeightFields } = state;

    if (Object.values(nonWeightFields).some(field => !!field) && !weightInKg) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["weightInKg"],
      });
    }

    if (state.leanBodyMassIsRequired && !state.leanBodyMassInKg) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["leanBodyMassInKg"],
      });
    }

    applyInvalidMassValueError(state.leanBodyMassInKg, state.weightInKg, ctx, "leanBodyMassInKg");
    applyInvalidMassValueError(state.skeletalMuscleMassInKg, state.weightInKg, ctx, "skeletalMuscleMassInKg");
    applyInvalidMassValueError(state.dryLeanMassInKg, state.weightInKg, ctx, "dryLeanMassInKg");
    applyInvalidMassValueError(state.trunkFatInKg, state.weightInKg, ctx, "trunkFatInKg");
    applyInvalidMassValueError(state.bodyFatMassInKg, state.weightInKg, ctx, "bodyFatMassInKg");
  });

export type MetricAnthropometryEntryType = z.infer<typeof BaseMetricAnthropometryEntrySchema>;

export const metricAnthropometryEntryFormDefaultValues = (): Partial<MetricAnthropometryEntryType> => ({
  heightInCm: null,
  leanBodyMassInKg: null,
  bodyFatMassInKg: null,
  dryLeanMassInKg: null,
  skeletalMuscleMassInKg: null,
  trunkFatInKg: null,
  leanBodyMassIsRequired: false,
  boneMass: null,
  boneMineralDensityZScore: null,
  percentBodyFat: null,
  visceralFatArea: null,
  type: "estimate",
  date: new Date(),
  comment: "",
});

export const roundMetricAnthroValues = ({
  leanBodyMassInKg,
  bodyFatMassInKg,
  heightInCm,
  dryLeanMassInKg,
  skeletalMuscleMassInKg,
  trunkFatInKg,
  weightInKg,
  ...rest
}: MetricAnthropometryEntryType): MetricAnthropometryEntryType => ({
  leanBodyMassInKg: leanBodyMassInKg && roundToHundredthsFloor(leanBodyMassInKg),
  bodyFatMassInKg: bodyFatMassInKg && roundToHundredthsFloor(bodyFatMassInKg),
  heightInCm: heightInCm && roundToHundredthsFloor(heightInCm),
  dryLeanMassInKg: dryLeanMassInKg && roundToHundredthsFloor(dryLeanMassInKg),
  skeletalMuscleMassInKg: skeletalMuscleMassInKg && roundToHundredthsFloor(skeletalMuscleMassInKg),
  trunkFatInKg: trunkFatInKg && roundToHundredthsFloor(trunkFatInKg),
  weightInKg: weightInKg && roundToHundredthsFloor(weightInKg),
  ...rest,
});

export const metricAnthropometryEntryFormStateToMetricFormInput = (
  {
    heightInCm,
    weightInKg,
    type,
    leanBodyMassInKg,
    date,
    percentBodyFat,
    bodyFatMassInKg,
    boneMineralDensityZScore,
    dryLeanMassInKg,
    skeletalMuscleMassInKg,
    visceralFatArea,
    trunkFatInKg,
    comment,
  }: MetricAnthropometryEntryType,
  athleteSex: SexType
): MetricAnthropometryEntryFormInput => ({
  heightInCm: heightInCm ?? null,
  weightInKg,
  sex: athleteSex,
  type,
  datetime: date.toISOString(),
  leanBodyMassInKg: leanBodyMassInKg ?? null,
  percentBodyFat: percentBodyFat ?? null,
  bodyFatMassInKg: bodyFatMassInKg ?? null,
  boneMineralDensityZScore: boneMineralDensityZScore ?? null,
  dryLeanMassInKg: dryLeanMassInKg ?? null,
  skeletalMuscleMassInKg: skeletalMuscleMassInKg ?? null,
  visceralFatArea: visceralFatArea ?? null,
  trunkFatInKg: trunkFatInKg ?? null,
  comment,
  boneMineralDensityTScore: null,
  boneArea: null,
  boneMass: null,
  boneMineralContent: null,
  boneMineralDensity: null,
});

export const metricAnthropometryEntryFormStateToCreateInput = (
  state: MetricAnthropometryEntryType,
  athleteId: string,
  athleteSex: SexType
): CreateMetricAnthropometryEntryInput => ({
  athleteId,
  anthropometryEntry: metricAnthropometryEntryFormStateToMetricFormInput(state, athleteSex),
});

export const metricAnthropometryEntryFormStateToEditInput = (
  state: MetricAnthropometryEntryType & { id: string },
  athleteSex: SexType
): EditMetricAnthropometryEntryInput => ({
  id: state.id,
  anthropometryEntry: metricAnthropometryEntryFormStateToMetricFormInput(state, athleteSex),
});
