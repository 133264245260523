import CloseIcon from "@mui/icons-material/Close";
import { IconButton, List, ListItemSecondaryAction } from "@mui/material";
import { MenuOrderItemWithAppearance } from "@notemeal/shared-ui";
import MenuOrderItemListItem from "../../MenuOrderItem/ListItem";

interface MenuOrderFormItemsProps {
  className?: string;
  menuOrderItems: readonly MenuOrderItemWithAppearance[];
  selectedItemId: string | null;
  onSelectItem: (item: MenuOrderItemWithAppearance) => void;
  onRemoveItem: (id: string) => void;
  disabled: boolean;
}

const MenuOrderFormItems = ({
  className,
  menuOrderItems,
  selectedItemId,
  onSelectItem,
  onRemoveItem,
  disabled,
}: MenuOrderFormItemsProps) => {
  return (
    <List dense className={className}>
      {menuOrderItems.map(menuOrderItem => (
        <MenuOrderItemListItem
          key={menuOrderItem.id}
          menuOrderItem={menuOrderItem}
          ListItemProps={{
            divider: true,
            disabled,
            onClick: () => onSelectItem(menuOrderItem),
            selected: menuOrderItem.id === selectedItemId,
          }}
          hidePercentConsumed
        >
          {!disabled && (
            <ListItemSecondaryAction>
              <IconButton size="small" onClick={() => onRemoveItem(menuOrderItem.id)}>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </MenuOrderItemListItem>
      ))}
    </List>
  );
};

export default MenuOrderFormItems;
