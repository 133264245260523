import { Box, LinearProgress, Typography, linearProgressClasses, useTheme } from "@mui/material";
import { Macros, getCaloriesPerMacro } from "@notemeal/shared-utils-macro-protocol";
import React from "react";

interface MacrosColorBarProps {
  macros: Macros;
  showKcals: boolean;
}

export const MacrosColorBar = ({ macros, showKcals }: MacrosColorBarProps) => {
  const {
    palette: { greyscale, cho, pro, fat },
  } = useTheme();
  const { cho: choCal, pro: proCal, fat: fatCal } = getCaloriesPerMacro(macros);
  const total = macros.kcal;
  const choBar = Math.round((choCal / total) * 100) + "%";
  const proBar = Math.round((proCal / total) * 100) + "%";
  const fatBar = Math.round((fatCal / total) * 100) + "%";

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography noWrap variant="subtitle1">
          Calories from Macros
        </Typography>
        {showKcals && <Typography variant="subtitle1">{`${Math.round(total)}kcal`}</Typography>}
      </Box>
      {total === 0 && (
        <LinearProgress
          sx={{
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: greyscale[200],
            },
          }}
          variant="determinate"
          value={100}
        />
      )}
      <Box sx={{ display: "flex" }}>
        {choCal > 0 && (
          <LinearProgress
            sx={{
              width: choBar,
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: cho.main,
              },
            }}
            variant="determinate"
            value={100}
          />
        )}
        {proCal > 0 && (
          <LinearProgress
            sx={{
              width: proBar,
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: pro.main,
              },
            }}
            variant="determinate"
            value={100}
          />
        )}
        {fatCal > 0 && (
          <LinearProgress
            sx={{
              width: fatBar,
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: fat.main,
              },
            }}
            variant="determinate"
            value={100}
          />
        )}
      </Box>
    </Box>
  );
};
