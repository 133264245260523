import { Box } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DateCalendar, PickersDayProps } from "@mui/x-date-pickers";
import { useUtils } from "@mui/x-date-pickers/internals";
import { Loading } from "@notemeal/shared-ui";
import { serializeDate } from "@notemeal/utils-date-time";
import { addDays } from "date-fns";
import { useState } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
    },
    loadingRoot: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  })
);

export interface CalendarMonthRendDayProps {
  isoDate: string;
  dayText: string;
  isCurrentMonth: boolean;
  disableDatesInPast?: boolean;
}

interface CalendarMonthProps {
  renderDay: (props: PickersDayProps<Date> & CalendarMonthRendDayProps) => JSX.Element;
  calendarDate?: Date;
  onChangeCalendarDate?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  loading?: boolean;
}

const CalendarMonth = ({
  renderDay,
  calendarDate: maybeCalendarDate,
  onChangeCalendarDate: maybeSetCalendarDate,
  minDate,
  maxDate,
  loading = false,
}: CalendarMonthProps) => {
  const classes = useStyles();
  const dateUtils = useUtils();

  const [_calendarDate, _setCalendarDate] = useState(new Date());
  const calendarDate = maybeCalendarDate ?? _calendarDate;
  const setCalendarDate = maybeSetCalendarDate ?? _setCalendarDate;

  const calendarMinDate = minDate ?? addDays(new Date(), -365);
  const calendarMaxDate = maxDate ?? addDays(new Date(), 365);

  const Day = (props: PickersDayProps<Date>) => {
    const { day, ...other } = props;
    if (!day) {
      return <></>;
    }
    const isoDate = serializeDate(day);
    const dayText = "" + day.getDate();
    const isCurrentMonth = dateUtils.isSameMonth(calendarDate, day);
    return renderDay({ day, isoDate, dayText, isCurrentMonth, ...other });
  };

  return (
    <Box className={classes.root}>
      <DateCalendar
        sx={{
          ".MuiPickersCalendarHeader-root": { mt: 0 },
          ".MuiDayCalendar-header > .MuiTypography-root": { fontSize: 12 },
        }}
        value={calendarDate}
        slots={{ day: Day }}
        onMonthChange={date => {
          if (date) {
            setCalendarDate(date);
          }
        }}
        disableHighlightToday={true}
        showDaysOutsideCurrentMonth={true}
        minDate={calendarMinDate}
        maxDate={calendarMaxDate}
      />
      {loading && (
        <Loading
          progressSize="md"
          classes={{
            root: classes.loadingRoot,
          }}
        />
      )}
    </Box>
  );
};

export default CalendarMonth;
