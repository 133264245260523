import IosShareIcon from "@mui/icons-material/IosShare";
import { Button } from "@mui/material";
import { capitalize } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { addDays, format, parse } from "date-fns";
import { Workbook, Worksheet } from "exceljs";
import { saveAs } from "file-saver";
import { UseFormReturn } from "react-hook-form";
import { MenuBuilderType } from "./Meal/MenuBuilderMealSchema";
import { useMenuBuilderContext } from "./MenuBuilderProvider";

interface Props {
  form: UseFormReturn<MenuBuilderType>;
  weekLabels: string[];
}

const formatTime = (time: string) => {
  return format(parse(time, "HH:mm:ss", new Date()), "h:mm aaaa");
};

const makePreviousRowBold = (worksheet: Worksheet) => {
  worksheet.getCell(`A${worksheet.rowCount}`).font = { bold: true };
};

const makePreviousRowBordered = (worksheet: Worksheet) => {
  worksheet.getRow(worksheet.rowCount).eachCell(cell => {
    cell.border = {
      top: { style: "thin" },
      right: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
    };
  });
};

export const MenuBuilderExportButton = ({ form, weekLabels }: Props) => {
  const { menuId, menuName, weeks, days } = useMenuBuilderContext();
  const exportToExcel = async () => {
    trackEvent("menu_builder.export_to_excel", { menuId });
    const menu = form.getValues();
    const workbook = new Workbook();

    menu.weeks.forEach((week, weekIndex) => {
      const startDate = weeks[weekIndex].startDate;
      const worksheet = workbook.addWorksheet(`Week ${weekIndex + 1}`, { properties: { defaultColWidth: 29 } });
      worksheet.addRow([menuName]);
      makePreviousRowBold(worksheet);

      worksheet.addRow([weekLabels[weekIndex]]);
      week.meals.forEach(meal => {
        // skip meals without data
        if (meal.rows.length === 0 || !meal.startTime || !meal.endTime || !meal.mealName) {
          return;
        }

        worksheet.addRow([]); // empty row

        // meal name and time
        worksheet.addRow([`${meal.mealName} (${formatTime(meal.startTime)} - ${formatTime(meal.endTime)})`]);
        makePreviousRowBold(worksheet);

        // table header
        const dayLabels = days.map((day, dayIndex) => `${capitalize(day)} ${format(addDays(startDate, dayIndex), "d")}`);
        worksheet.addRow(["Dining Station", ...dayLabels]);
        worksheet.getRow(worksheet.rowCount).alignment = { vertical: "middle", horizontal: "center" };
        makePreviousRowBordered(worksheet);
        meal.rows.forEach(row => {
          const itemsByDay = days.map(day => {
            return sortByKey(
              row.items.map((item, index) => ({ ...item, index })).filter(item => item.dayOfWeek === day),
              "position"
            );
          });

          // items for each station, the number of rows is determined by the day with the most items
          const maxItems = Math.max(...itemsByDay.map(items => items.length), 1);
          for (let i = 0; i < maxItems; i++) {
            const rowItems = itemsByDay.map(items => items[i]);
            worksheet.addRow([
              {
                richText: [{ font: { bold: true }, text: row.diningStationName }, { text: row.foodType ? ` (${row.foodType})` : "" }],
              },
              ...rowItems.map(item => item?.menuItem.name ?? ""),
            ]);
            makePreviousRowBordered(worksheet);
          }
          // merge the station name into one cell encompassing all the item rows
          if (maxItems > 1) {
            worksheet.mergeCells(`A${worksheet.rowCount - maxItems + 1}:A${worksheet.rowCount}`);
          }
        });
      });
    });

    // make all rows vertically centered
    workbook.eachSheet(worksheet => {
      worksheet.eachRow(row => {
        row.alignment = { ...row.alignment, vertical: "middle", wrapText: true };
      });
    });

    saveAs(new Blob([await workbook.xlsx.writeBuffer()]), `${menuName}.xlsx`);
  };

  return (
    <Button
      onClick={exportToExcel}
      variant="outlined"
      startIcon={<IosShareIcon />}>
      Export
    </Button>
  );
};
