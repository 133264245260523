import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Dialog, DialogContent, Divider, List, ListItem, ListItemText, Tooltip } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import { sortByFn, sortByKey } from "@notemeal/utils-sort";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { Fragment, useMemo, useState } from "react";
import { MenuSuggestionsPreviewQuery, useAthleteForMenuSuggestionPreviewQuery } from "../../../../types";
import { getCompareString, sortGroupAndFilterAthletesByTeam } from "../../../Tags/utils";
import Athlete from "../Athlete";

interface MenuSuggestionsPreviewDialogContentProps {
  data: MenuSuggestionsPreviewQuery;
  onClose: () => void;
  open: boolean;
}

const MenuSuggestionsPreviewDialogContent = ({ data, onClose, open }: MenuSuggestionsPreviewDialogContentProps) => {
  const [openTeamId, setOpenTeamId] = useState<string | null>(null);
  const [athleteId, setAthleteId] = useState<string | null>(null);

  const { data: athleteData } = useAthleteForMenuSuggestionPreviewQuery({
    variables: {
      athleteId: athleteId || "",
    },
    skip: athleteId === null,
  });

  const athletes = data.mealMenu.athletes;

  const groupedAthletes = useMemo(
    () =>
      sortGroupAndFilterAthletesByTeam({
        athletes,
        sortFn: athleteList => sortByFn(athleteList, getCompareString),
      }),
    [athletes]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{ sx: { height: "calc(100% - 64px)" } }}>
      <DialogTitle title="Menu Suggestions Preview" onClose={onClose} />
      <DialogContent sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ width: "20%", overflow: "auto", flexShrink: 0 }}>
          <List>
            {groupedAthletes.map(t => {
              const open = t.id === openTeamId;
              return (
                <Fragment key={t.id}>
                  <ListItem button onClick={() => setOpenTeamId(t.id)}>
                    <ListItemText primary={t.name} />
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit>
                    <List
                      component="div"
                      disablePadding
                      dense>
                      {sortByKey(t.athletes, "lastName").map(a => {
                        const noMealPlans = a.mealPlans.length === 0;
                        const athleteName = `${a.lastName}, ${a.firstName}`;

                        return (
                          // TODO: Disable selecting athlete if they have no meal plans...
                          <Tooltip key={a.id} title={noMealPlans ? "Athlete has no meal plans, will not have targets for suggestions" : ""}>
                            <ListItem
                              button
                              onClick={() => !noMealPlans && setAthleteId(a.id)}
                              selected={a.id === athleteId}>
                              <ListItemText primary={noMealPlans ? "" : athleteName} secondary={!noMealPlans ? "" : athleteName} />
                            </ListItem>
                          </Tooltip>
                        );
                      })}
                    </List>
                  </Collapse>
                  <Divider />
                </Fragment>
              );
            })}
          </List>
        </Box>
        <Divider orientation="vertical" />
        <Box sx={{ flexGrow: 1, ml: 2 }}>
          {athleteData ? (
            <Athlete
              key={athleteData.athlete.id}
              mealMenuId={data.mealMenu.id}
              mealMenuType={data.mealMenu.type}
              athlete={athleteData.athlete}
            />
          ) : athleteId !== null ? (
            <Loading />
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MenuSuggestionsPreviewDialogContent;
