import { CreateNoteInput, NoteType, NoteFormInput, EditNoteInput } from "../../../types";
import { canSaveDate } from "@notemeal/utils-date-time";

export interface NoteFormState {
  content: string;
  type: NoteType | null;
  datetime: Date | null;
  id?: string;
}

interface ChangeContent {
  type: "CHANGE_CONTENT";
  payload: string;
}

interface ChangeType {
  type: "CHANGE_TYPE";
  payload: NoteType;
}

interface ChangeDate {
  type: "CHANGE_DATE";
  payload: Date | null;
}

interface ResetForm {
  type: "RESET_FORM";
  payload: null;
}

export type NoteFormAction = ChangeType | ChangeDate | ResetForm | ChangeContent;

export const noteFormReducer = (state: NoteFormState, action: NoteFormAction): NoteFormState => {
  switch (action.type) {
    case "CHANGE_TYPE":
      return { ...state, type: action.payload };
    case "CHANGE_DATE":
      return { ...state, datetime: action.payload };
    case "CHANGE_CONTENT":
      return { ...state, content: action.payload };
    case "RESET_FORM":
      return initialNoteFormState();
  }
};

export const noteFormToSaveTooltips = (state: NoteFormState): string[] => {
  const tooltips = [];
  if (!state.content || state.content === "") {
    tooltips.push("Content is required");
  }
  if (!state.datetime || !canSaveDate(state.datetime)) {
    tooltips.push("Invalid date");
  }
  return tooltips;
};

type SaveableNoteFormState = NoteFormState & { datetime: Date };
type CreateableNoteFormState = SaveableNoteFormState;
type EditableNoteFormState = SaveableNoteFormState & { id: string };

export const noteFormStateToCreateInput = (state: CreateableNoteFormState, athleteId: string): CreateNoteInput => ({
  athleteId,
  note: noteFormStateToNoteFormInput(state),
});

export const noteFormStateToEditInput = (state: EditableNoteFormState): EditNoteInput => ({
  id: state.id,
  note: noteFormStateToNoteFormInput(state),
});

export const noteFormStateToNoteFormInput = (state: CreateableNoteFormState): NoteFormInput => ({
  noteTypeId: state.type ? state.type.id : null,
  datetime: state.datetime.toISOString(),
  content: state.content,
});

export const initialNoteFormState = (): NoteFormState => ({
  content: "",
  type: null,
  datetime: new Date(),
});
