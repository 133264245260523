import EditIcon from "@mui/icons-material/Edit";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { IconButton, TableCell, Theme, Tooltip, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getAthleteMobileState } from "@notemeal/shared-ui";
import { NutritionColor } from "apps/web/src/components/universal/CustomLogos/NutritionColor";
import TeamworksLogoBlack from "apps/web/src/components/universal/CustomLogos/TeamworksLogoBlack";
import { isSubscribedTo } from "../../../../components/FoodLog/NotificationSettings/utils";
import { FoodLogNotificationSettings } from "../../../../types";
import { mobileStateToIcon } from "../../../Athlete/Summary/AccountManagement/utils";
import { EnableAthlete } from "./Table";
import { IAthleteRowInputBase, mobileStateToTooltip } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
    },
    headerCell: { padding: theme.spacing(0.5) },
    paleGrey: {
      opacity: theme.palette.action.disabledOpacity,
    },
    smallFont: { fontSize: 13 },
    actions: { display: "flex", alignContent: "center", alignItems: "center" },
    teamworksLogoContainer: {
      height: 24,
      padding: 3,
    },
  })
);

interface AthleteActionsProps {
  row: IAthleteRowInputBase;
  displayEnableAccount: boolean;
  onClickEnableAccount: (athlete: EnableAthlete) => void;
  displaySubscribeToAthleteModal: (args: { athleteNotificationSettings: FoodLogNotificationSettings | null; athleteId: string }) => void;
  teamFoodLogNotificationSettings: FoodLogNotificationSettings | null;
  setEditing?: (editing: boolean) => void;
}

const AthleteActions = ({
  row,
  onClickEnableAccount,
  displaySubscribeToAthleteModal,
  teamFoodLogNotificationSettings,
  setEditing,
  displayEnableAccount,
}: AthleteActionsProps) => {
  const {
    palette: { success, greyscale },
  } = useTheme();
  const classes = useStyles();

  const athleteSubscriptionProps = {
    athleteNotificationSettings: row.foodLogNotificationSettings,
    teamNotificationSettings: teamFoodLogNotificationSettings,
  };

  const handleClickSubscribeToAthlete = () => {
    displaySubscribeToAthleteModal({
      athleteId: row.id,
      athleteNotificationSettings: athleteSubscriptionProps.athleteNotificationSettings,
    });
  };

  const hasTeamworksLink = row.orgMembership !== null && row.orgMembership.teamworksId !== null;
  const isNotemealOnly = !hasTeamworksLink && row.isProfileNotemealOnly;

  return (
    <TableCell onClick={e => e.stopPropagation()}>
      <div className={classes.actions}>
        {hasTeamworksLink && <TeamworksLogoBlack logoWrapperClassName={classes.teamworksLogoContainer} />}
        {isNotemealOnly && <NutritionColor logoWrapperClassName={classes.teamworksLogoContainer} />}
        {displayEnableAccount && (
          <Tooltip key={"enable"} title={mobileStateToTooltip(getAthleteMobileState(row))}>
            <IconButton
              disabled={row.isArchived}
              size={"small"}
              aria-label={mobileStateToTooltip(getAthleteMobileState(row))}
              onClick={() => onClickEnableAccount(row)}
            >
              {mobileStateToIcon(getAthleteMobileState(row), greyscale[300], success.light)}
            </IconButton>
          </Tooltip>
        )}
        {!row.orgMembership ? (
          <></>
        ) : (
          <Tooltip
            title={
              isSubscribedTo(athleteSubscriptionProps)
                ? "Click to unsubscribe from athlete notifications"
                : "Click to subscribe to athlete notifications"
            }
            aria-label={
              isSubscribedTo(athleteSubscriptionProps)
                ? "Click to unsubscribe from athlete notifications"
                : "Click to subscribe to athlete notifications"
            }
          >
            <IconButton
              disabled={row.isArchived}
              size={"small"}
              onClick={handleClickSubscribeToAthlete}>
              {isSubscribedTo(athleteSubscriptionProps) ? <RssFeedIcon /> : <RssFeedIcon className={classes.paleGrey} />}
            </IconButton>
          </Tooltip>
        )}
        {setEditing && (
          <IconButton
            sx={{ color: greyscale[400] }}
            disabled={row.isArchived}
            size={"small"}
            aria-label="Edit"
            onClick={() => setEditing(true)}
          >
            <EditIcon />
          </IconButton>
        )}
      </div>
    </TableCell>
  );
};
export default AthleteActions;
