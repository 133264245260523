import { Avatar, Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import { parseDate, serializeDate } from "@notemeal/utils-date-time";
import { sortByKey } from "@notemeal/utils-sort";
import classnames from "classnames";
import { addDays, startOfWeek } from "date-fns";
import { useState } from "react";
import { CompassLocationFragment, CompassMenuInput, useCompassMenuItemsQuery } from "../../../../types";
import WeekNavigation from "../../WeekNavigation";
import { groupItemsIntoMenus } from "./utils";

const useStyles = makeStyles(({ spacing, palette: { info, text, getContrastText } }: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto 1fr",
      height: "100%",
      overflow: "auto",
    },
    header: {
      position: "sticky",
      gridRowStart: 1,
      gridColumnStart: 1,
      zIndex: 1,
      top: 0,
      backgroundColor: "white",
    },
    headerDates: {
      display: "flex",
    },
    headerDate: {
      flex: 1,
      height: 80,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
    },
    dateAvatar: {
      backgroundColor: "white",
      color: text.secondary,
    },
    todayAvatar: {
      backgroundColor: info.main,
      color: getContrastText(info.main),
    },
    date: {
      flex: 1,
    },
    content: {
      display: "flex",
      gridRowStart: 2,
      gridColumnStart: 1,
    },
    mealPeriod: {
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
      margin: spacing(0.5),
      padding: spacing(0.5),
    },
    ignoreMealPeriod: {
      backgroundColor: info.light,
    },
    selectedMealPeriod: {
      backgroundColor: info.main,
    },
  })
);

const DAYS_IN_WEEK = 7;
const DAY_OF_WEEK_NAMES = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

interface MenusCompassDialogPreviewProps {
  location: CompassLocationFragment;
  selectedMenuIds: CompassMenuInput[];
  onToggleMenu: (menu: CompassMenuInput) => void;
  // Due to not knowing the menus (aka intersection of mealPeriodIds and dates) before querying the menuItems, pass the menus from here
  onSelectAllMenus: (menus: CompassMenuInput[]) => void;
}

const MenusCompassDialogPreview = ({ location, selectedMenuIds, onToggleMenu, onSelectAllMenus }: MenusCompassDialogPreviewProps) => {
  const classes = useStyles();
  const [startOfWeekDate, setStartOfWeekDate] = useState(startOfWeek(new Date()));

  const weekDates = [...Array(DAYS_IN_WEEK).keys()].map(d => serializeDate(addDays(startOfWeekDate, d)));
  const today = serializeDate(new Date());

  const { data, loading } = useCompassMenuItemsQuery({
    variables: {
      locationId: location.locationId,
      startDate: serializeDate(startOfWeekDate),
      days: DAYS_IN_WEEK,
    },
  });
  const menuItems = data?.compass_menuItems || [];

  const menus = groupItemsIntoMenus(location, menuItems);

  return !data || loading ? (
    <Loading />
  ) : (
    <div className={classes.root}>
      <div className={classes.header}>
        <WeekNavigation startOfWeekDate={startOfWeekDate} onChange={setStartOfWeekDate} />
        <div className={classes.headerDates}>
          {weekDates.map((date, dayOfWeek) => {
            const isToday = date === today;
            const dayOfWeekColor = isToday ? "info" : ("textSecondary" as const);
            const avatarClassName = isToday ? classes.todayAvatar : classes.dateAvatar;

            return (
              <div key={date} className={classes.headerDate}>
                <Typography variant="subtitle1" color={dayOfWeekColor}>
                  {DAY_OF_WEEK_NAMES[dayOfWeek]}
                </Typography>
                <Avatar className={avatarClassName}>
                  <Typography variant="h3" color="inherit">
                    {parseDate(date).getDate()}
                  </Typography>
                </Avatar>
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.content}>
        {weekDates.map(date => (
          <div key={date} className={classes.date}>
            {sortByKey(
              menus.filter(m => m.date === date),
              "displayOrder"
            ).map(mp => {
              const selected = Boolean(selectedMenuIds.find(s => s.mealPeriodId === mp.mealPeriodId && s.date === date));
              return (
                <Paper
                  key={mp.mealPeriodId}
                  className={classnames(classes.mealPeriod, {
                    [classes.selectedMealPeriod]: selected,
                    [classes.ignoreMealPeriod]: !selected,
                  })}
                  onClick={() =>
                    onToggleMenu({
                      mealPeriodId: mp.mealPeriodId,
                      date,
                    })
                  }
                >
                  <Typography>{mp.name}</Typography>
                  {sortByKey(mp.stations, "displayOrder").map(s => (
                    <Typography variant="subtitle1" key={s.stationId}>
                      {s.name}
                    </Typography>
                  ))}
                </Paper>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenusCompassDialogPreview;
