import { Box, styled, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { isFormDirty } from "apps/web/src/utils/form";
import { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import GoalTypeSelect from "../TypeSelect";
import { GoalType } from "./GoalFormSchema";

const Row = styled("div")(({ theme: { spacing } }) => ({
  width: "100%",
  display: "flex",
  gap: spacing(2),
  my: 5,
  py: 5,
}));

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) => {
  return createStyles({
    field: {
      flex: "1 1 0",
      width: 0,
      maxWidth: "50%",
    },
  });
});

interface GoalFormProps {
  form: UseFormReturn<GoalType>;
  editOptions?: { disableStartDateUntilDirty: boolean };
}

const GoalForm = ({ form, editOptions }: GoalFormProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = form;
  const classes = useStyles();

  const isDirty = isFormDirty(form);
  const isEditing = Boolean(editOptions);
  let isStartDateDisabled = false;

  if (editOptions) {
    isStartDateDisabled = true;

    if (editOptions.disableStartDateUntilDirty && isDirty) {
      isStartDateDisabled = false;
    }
  }

  useEffect(() => {
    const formWatcher = form.watch((state, { name }) => {
      if (name === "type" && state.type) {
        form.setValue("kcalOffset", state.type.defaultKcalOffset ?? 0);
      }
    });

    return () => formWatcher.unsubscribe();
  }, [form]);

  useEffect(() => {
    if (isEditing && isDirty) {
      setValue("start", new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isEditing]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography sx={{ my: 1 }} variant="body1Medium">
        Weight Target Data
      </Typography>
      <Row sx={{ mt: 1 }}>
        <Controller
          control={control}
          name="start"
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              className={classes.field}
              label="Start Date"
              disabled={isStartDateDisabled}
              slotProps={{
                textField: {
                  error: Boolean(errors.start),
                  helperText: errors.start?.message,
                },
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="end"
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              className={classes.field}
              label="End Date (Optional)"
              slotProps={{
                textField: {
                  error: Boolean(errors.end),
                  helperText: errors.end?.message,
                },
              }}
            />
          )}
        />
      </Row>
      <Row>
        <Controller
          control={control}
          name="type"
          render={({ field: { value, onChange } }) => (
            <GoalTypeSelect
              className={classes.field}
              variant="outlined"
              selectedType={value}
              onChange={onChange}
              error={Boolean(errors.type)}
              helperText={errors.type?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="kcalOffset"
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value === null || value === undefined ? "" : value}
              onChange={onChange}
              className={classes.field}
              margin="dense"
              label="Kcal Offset"
              type="number"
              inputProps={{ step: 50 }}
              error={Boolean(errors.kcalOffset)}
              helperText={errors.kcalOffset?.message}
            />
          )}
        />
      </Row>
    </Box>
  );
};

export default GoalForm;
