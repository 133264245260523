import { Box } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import { Macros } from "@notemeal/shared-utils-macro-protocol";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { useState } from "react";
import { MenuSelectionFormFragment, useRecordMenuOrderSuggestionDecisionMutation } from "../../../types";
import { RECENT_ORDERS, SUGGESTIONS } from "../MenuOrderUtils";
import MenuOrderQuickAddCards from "./Cards";

interface MenuOrderQuickAddTabsProps {
  suggestions: readonly MenuSelectionFormFragment[];
  onChoose: (quickAddOrder: MenuSelectionFormFragment) => void;
  recentOrders: readonly MenuSelectionFormFragment[];
  targetMacros: Macros | null;
  mealMenuId: string;
  athleteUserId?: string;
  loading: boolean;
}

const MenuOrderQuickAddTabs = ({
  suggestions,
  onChoose,
  recentOrders,
  targetMacros,
  athleteUserId,
  mealMenuId,
  loading,
}: MenuOrderQuickAddTabsProps) => {
  const [recordDecision] = useRecordMenuOrderSuggestionDecisionMutation();

  const [selected, setSelected] = useState(SUGGESTIONS);
  const tabs = [...(suggestions.length ? [SUGGESTIONS] : []), ...(recentOrders.length ? [RECENT_ORDERS] : [])];

  const displaySuggestions = selected === SUGGESTIONS && suggestions.length > 0;
  const displayedSelections = displaySuggestions ? suggestions : recentOrders;

  const handleChoose = (quickAddOrder: MenuSelectionFormFragment) => {
    if (displaySuggestions && athleteUserId && targetMacros) {
      recordDecision({
        variables: {
          input: {
            userId: athleteUserId,
            mealMenuId,
            restaurantMenuLinkId: null,
            restaurantMenuId: null,
            targetMacros: {
              cho: targetMacros.cho,
              pro: targetMacros.pro,
              fat: targetMacros.fat,
            },
            suggestion: {
              items: quickAddOrder.items.map(mia => ({
                menuItemId: mia.menuItem.id,
                amount: mia.amount,
                options: mia.options.map(oa => ({
                  menuItemChoiceOptionId: oa.menuItemChoiceOption.id,
                  amount: oa.amount,
                })),
              })),
            },
          },
        },
      });
    }
    onChoose(quickAddOrder);
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TWTabGroup
        outsideSelected={selected}
        tabs={tabs}
        onSelected={setSelected} />
      <MenuOrderQuickAddCards
        menuSelections={displayedSelections}
        onChoose={handleChoose}
        targetMacros={targetMacros} />
    </Box>
  );
};

export default MenuOrderQuickAddTabs;
