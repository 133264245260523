import React from "react";
import { useParams } from "react-router-dom-v5-compat";
import { Box, Typography } from "@mui/material";
import { BulkAssignMealPlanTemplateContent } from "apps/web/src/components/MealPlanTemplate/BulkAssignMealPlanTemplate/BulkAssignMealPlanTemplateContent";
import { BulkAssignMealPlanTemplateProvider } from "apps/web/src/components/MealPlanTemplate/BulkAssignMealPlanTemplate/BulkAssignMealPlanTemplateContext";
import { useMealPlanTemplateForAssignmentQuery } from "apps/web/src/types";
import { SecondaryNavLayout } from "../../SecondaryNav/SecondaryNavLayout";
import { mealPlanAssignTemplateLink, mealPlanTemplatesLink } from "./MealPlansPaths";

export const BulkAssignTemplatePage = () => {
  const { id: templateId } = useParams<{ id: string }>();

  const { data, loading } = useMealPlanTemplateForAssignmentQuery({ variables: { id: templateId ?? "" }, skip: !templateId });

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!templateId || !data?.mealPlanTemplate) {
    return <Typography>Error: no meal plan template found with id: {templateId}</Typography>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ flex: 0 }}>
        <SecondaryNavLayout
          current={mealPlanAssignTemplateLink}
          enableAthleteSearch={false}
          breadCrumbs={[mealPlanTemplatesLink]}
          tabs={[]}
          showTitle
        />
      </Box>
      <BulkAssignMealPlanTemplateProvider mealPlanTemplate={data.mealPlanTemplate}>
        <BulkAssignMealPlanTemplateContent />
      </BulkAssignMealPlanTemplateProvider>
    </Box>
  );
};
