import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import NutrientTreeItem from "./NutrientTreeItem";
import { CloseSquare, MinusSquare, PlusSquare } from "./StyledTreeItem";
import { generateTreeStyles } from "./styles";

import { VitaminNutrientGroup } from "@notemeal/shared-ui";

const useStyles = makeStyles((theme: Theme) => createStyles(generateTreeStyles(theme.palette.accents.yellow[50], theme)));
export interface VitaminTreeProps {
  vitamins: VitaminNutrientGroup;
  displayDri: boolean;
}

const VitaminTree = ({ vitamins, displayDri }: VitaminTreeProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h3">
        Vitamins
      </Typography>
      <div className={classes.content}>
        <TreeView
          className={classes.tree}
          defaultExpanded={["1"]}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
        >
          <NutrientTreeItem
            isRoot={true}
            nodeId="1"
            displayDri={displayDri}
            nutrientAmount={vitamins["Vitamin A"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="2"
            displayDri={displayDri}
            nutrientAmount={vitamins["Vitamin E"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="3"
            displayDri={displayDri}
            nutrientAmount={vitamins["Vitamin D"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="4"
            displayDri={displayDri}
            nutrientAmount={vitamins["Vitamin C"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="5"
            displayDri={displayDri}
            nutrientAmount={vitamins["Thiamin"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="6"
            displayDri={displayDri}
            nutrientAmount={vitamins["Riboflavin"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="7"
            displayDri={displayDri}
            nutrientAmount={vitamins["Niacin"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="8"
            displayDri={displayDri}
            nutrientAmount={vitamins["Vitamin B6"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="9"
            displayDri={displayDri}
            nutrientAmount={vitamins["Vitamin B12"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="10"
            displayDri={displayDri}
            nutrientAmount={vitamins["Choline"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="11"
            displayDri={displayDri}
            nutrientAmount={vitamins["Vitamin K"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="12"
            displayDri={displayDri}
            nutrientAmount={vitamins["Folate"]} />
        </TreeView>
      </div>
    </div>
  );
};

export default VitaminTree;
