import { PaperProps, SxProps } from "@mui/material";
import { dateToIsoInTz } from "@notemeal/utils-date-time";
import { addDays, addMinutes, areIntervalsOverlapping, compareAsc } from "date-fns";
import { ReactNode } from "react";

export const HOUR_HEIGHT = 40;
export const DAY_HEADER_HEIGHT = 74;

export interface CalendarEvent {
  id: string;
  start: Date;
  durationInMinutes: number;
  isAllDay?: boolean;
}

export interface RenderCalendarEventArgs {
  PaperProps: PaperProps;
  eventStyle?: React.CSSProperties;
  isAbbreviated: boolean;
  slot: number;
}

export type RenderCalendarPopoverArgs = {
  anchorEl: HTMLElement;
  onClose: () => void;
} & (
  | {
      action: "click";
    }
  | {
      action: "drag";
      newStart: Date;
    }
);

export type RenderCalendarEventPaper<E extends CalendarEvent> = (event: E, args: RenderCalendarEventArgs) => ReactNode;
export type RenderCalendarEventPopover<E extends CalendarEvent> = (event: E, args: RenderCalendarPopoverArgs) => ReactNode;

export type RenderCalendarEvent<E extends CalendarEvent> = {
  renderPaper: RenderCalendarEventPaper<E>;
  renderPopover: RenderCalendarEventPopover<E>;
};

export type CalendarDragSettings<E extends CalendarEvent> = {
  renderEventPaper: RenderCalendarEventPaper<E>;
  canDragEvent: (event: E) => boolean;
};

export interface CalendarDateHeaderProps {
  date: string;
  dayOfWeekLabelNode: ReactNode;
  dateLabelNode: ReactNode;
  showAllDayEvents: boolean;
}

export type RenderCalendarDateHeader = (props: CalendarDateHeaderProps) => ReactNode;

export interface BaseCalendarDayColumnProps<E extends CalendarEvent> {
  hours?: string[];
  hourHeight?: number;
  date: string;
  clientTimezone: string;
  events: readonly E[];
  showAllDayCell?: boolean;
  renderEvent: RenderCalendarEvent<E>;
  renderNewEvent?: RenderCalendarEvent<CalendarEvent>;
  dragSettings?: CalendarDragSettings<E>;
  displaySettings?: CalendarDisplaySettings;
  sx?: SxProps;
}

export type RenderCalendarDayColumn = <E extends CalendarEvent>(props: BaseCalendarDayColumnProps<E>) => ReactNode;

export type RenderCalendarOverlay = () => ReactNode;

export interface CalendarEventWithSlot<E extends CalendarEvent> {
  event: E;
  slot: number;
  maxSlot: number;
}

export const getCalendarEventsOnDate = <E extends CalendarEvent>(events: readonly E[], date: string, clientTimezone: string) => {
  return events.filter(mm => {
    const dateStart = new Date(dateToIsoInTz(date, clientTimezone));
    const dateEnd = addDays(dateStart, 1);
    return areIntervalsOverlapping(
      {
        start: mm.start,
        end: addMinutes(mm.start, mm.durationInMinutes),
      },
      {
        start: dateStart,
        end: dateEnd,
      }
    );
  });
};

export interface CalendarDisplaySettings {
  disableEditPast?: boolean;
}

export const getCalendarEventsWithSlots = <E extends CalendarEvent>(events: readonly E[]): readonly CalendarEventWithSlot<E>[] => {
  const paritions = partitionOverlappingCalendarEvents(events);
  return paritions.flatMap(p => addSlotsToCalendarEventPartition(p));
};

interface PartitionedCalendarEvents<E extends CalendarEvent> {
  events: E[];
  isAllDay: boolean;
  minStart: Date;
  maxEnd: Date;
}

const partitionOverlappingCalendarEvents = <E extends CalendarEvent>(events: readonly E[]): readonly E[][] => {
  const partitions = events.reduce((partitionedCalendarEvents: PartitionedCalendarEvents<E>[], nextCalendarEvent) => {
    const newPartition: PartitionedCalendarEvents<E> = {
      events: [nextCalendarEvent],
      isAllDay: nextCalendarEvent.isAllDay ?? false,
      minStart: nextCalendarEvent.start,
      maxEnd: getCalendarEventEnd(nextCalendarEvent),
    };
    const intersectingPartitions = partitionedCalendarEvents.filter(p => eventInPartition(nextCalendarEvent, p));
    if (!intersectingPartitions.length) {
      return [...partitionedCalendarEvents, newPartition];
    } else {
      const newCombinedPartition = intersectingPartitions.reduce((newPartition: PartitionedCalendarEvents<E>, nextPartition) => {
        return {
          events: [...newPartition.events, ...nextPartition.events],
          isAllDay: newPartition.isAllDay,
          minStart: compareAsc(newPartition.minStart, nextPartition.minStart) === 1 ? nextPartition.minStart : newPartition.minStart,
          maxEnd: compareAsc(newPartition.maxEnd, nextPartition.maxEnd) === -1 ? nextPartition.maxEnd : newPartition.maxEnd,
        };
      }, newPartition);
      return [...partitionedCalendarEvents.filter(p => !intersectingPartitions.includes(p)), newCombinedPartition];
    }
  }, []);
  return partitions.map(({ events }) => events);
};

const eventInPartition = <E extends CalendarEvent>(event: E, { isAllDay, minStart, maxEnd }: PartitionedCalendarEvents<E>): boolean => {
  // If event is all day and partition is not, or vice versa, then they are not overlapping
  if ((event.isAllDay && !isAllDay) || (!event.isAllDay && isAllDay)) {
    return false;
  }
  const end = getCalendarEventEnd(event);
  const startBeforeMaxEnd = compareAsc(event.start, maxEnd) === -1;
  const endAfterMinStart = compareAsc(end, minStart) === 1;
  return startBeforeMaxEnd && endAfterMinStart;
};

interface CalendarEventWithSlotOnly<E extends CalendarEvent> {
  event: E;
  slot: number;
}

const addSlotsToCalendarEventPartition = <E extends CalendarEvent>(events: E[]): readonly CalendarEventWithSlot<E>[] => {
  const initValue: [CalendarEventWithSlotOnly<E>[], number] = [[], 1];
  const sortedCalendarEvents = events.sort((e1, e2) => compareAsc(e1.start, e2.start));

  const [eventsWithSlots, maxSlot] = sortedCalendarEvents.reduce((eventsWithMaxSlot, nextCalendarEvent) => {
    const [events, maxSlot] = eventsWithMaxSlot;

    const overlappingSlots = events.reduce((overlappingSlots: number[], { event, slot }) => {
      // const [nextCalendarEvent, overlappingSlots] = nexCalendarEventWithOverlappingSlots;
      const eventEnd = getCalendarEventEnd(event);
      const overlaps = compareAsc(nextCalendarEvent.start, eventEnd) === -1;
      return overlaps ? [...overlappingSlots, slot] : overlappingSlots;
    }, []);

    const nonOverlappingSlots = Array.from(Array(maxSlot).keys())
      .map(i => i + 1)
      .filter(slot => {
        return !overlappingSlots.includes(slot);
      });

    const nextCalendarEventSlot = nonOverlappingSlots.length === 0 ? maxSlot + 1 : nonOverlappingSlots[0];
    const nextCalendarEventWithSlot = {
      event: nextCalendarEvent,
      slot: nextCalendarEventSlot,
    };

    const nextValue: [CalendarEventWithSlotOnly<E>[], number] = [
      [...events, nextCalendarEventWithSlot],
      Math.max(maxSlot, nextCalendarEventSlot),
    ];
    return nextValue;
  }, initValue);
  return eventsWithSlots.map(mm => ({ ...mm, maxSlot }));
};

// TODO: Link up with value that styles the min size
const MIN_VISUAL_MEAL_DURATION = 30;

const getCalendarEventEnd = ({ start, durationInMinutes }: CalendarEvent) => {
  return addMinutes(start, Math.max(durationInMinutes, MIN_VISUAL_MEAL_DURATION));
};

export const isDateInPast = (currentDate: Date, date: Date): boolean => {
  // compare today and day dates in mm-dd-yyyy format to see if startDate is in the past
  const current = new Date(currentDate).setHours(0, 0, 0, 0);
  const other = new Date(date).setHours(0, 0, 0, 0);
  return other < current;
};
