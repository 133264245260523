import { Reference } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
    DEFAULT_EXCHANGE_DISPLAY_SETTINGS,
    DEFAULT_MACRO_DISPLAY_SETTINGS,
    DEFAULT_NO_TARGETS_DISPLAY_SETTINGS,
} from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { EDSASettings } from "apps/web/src/components/DisplaySettings/EDSASettings";
import { useState } from "react";
import DisplaySettingsTable from "../../../../components/DisplaySettings/Table";
import {
    ExchangeMealPlanDisplaySettingsFragment,
    MacroMealPlanDisplaySettingsFragment,
    NoTargetsDisplaySettingsFragment,
    useEditTeamDisplaySettingsMutation,
} from "../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      justifyContent: "center",
      alignItems: "center",
      paddingRight: theme.spacing(2),
    },

    flexSpaceAround: {
      display: "flex",
      justifyContent: "space-around",
    },
  })
);

interface TeamDashboardDisplaySettingsEditModalProps {
  teamId: string;
  open: boolean;
  onClose: () => void;
  defaultMacroDisplaySettings: MacroMealPlanDisplaySettingsFragment | null;
  defaultExchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment | null;
  noTargetsDisplaySettings: NoTargetsDisplaySettingsFragment | null;
  hideAnthropometry: boolean | null;
  showEDSA: boolean;
  isEDSAEnabled: boolean;
}

const TeamDashboardDisplaySettingsEditModal = ({
  open,
  onClose,
  teamId,
  defaultMacroDisplaySettings,
  defaultExchangeDisplaySettings,
  noTargetsDisplaySettings: initNoTargetsDisplaySettings,
  hideAnthropometry: initHideAnthropometry,
  showEDSA,
  isEDSAEnabled,
}: TeamDashboardDisplaySettingsEditModalProps) => {
  const classes = useStyles();
  const [macroDisplaySettings, setMacroDisplaySettings] = useState(defaultMacroDisplaySettings || DEFAULT_MACRO_DISPLAY_SETTINGS);
  const [exchangeDisplaySettings, setExchangeDisplaySettings] = useState(
    defaultExchangeDisplaySettings || DEFAULT_EXCHANGE_DISPLAY_SETTINGS
  );
  const [noTargetsDisplaySettings, setNoTargetsDisplaySettings] = useState(
    initNoTargetsDisplaySettings || DEFAULT_NO_TARGETS_DISPLAY_SETTINGS
  );
  const [hideAnthropometry, setHideAnthropometry] = useState(initHideAnthropometry ?? false);
  const [enableEDSA, setEnableEDSA] = useState(isEDSAEnabled);

  const [editTeamDisplaySettings] = useEditTeamDisplaySettingsMutation({
    update: cache => {
      cache.modify({
        id: `Team:${teamId}`,
        fields: {
          athletes: (athletes: Reference[]) => {
            athletes.map(a =>
              cache.modify({
                id: a.__ref,
                fields: {
                  defaultMacroDisplaySettings: (_, { DELETE }) => DELETE,
                  defaultExchangeDisplaySettings: (_, { DELETE }) => DELETE,
                  foodLogDisplaySettings: (_, { DELETE }) => DELETE,
                  mealPlans: (mealPlans: Reference[]) => {
                    mealPlans.map(mp =>
                      cache.modify({
                        id: mp.__ref,
                        fields: {
                          macroDisplaySettings: (_, { DELETE }) => DELETE,
                          exchangeDisplaySettings: (_, { DELETE }) => DELETE,
                        },
                      })
                    );
                  },
                },
              })
            );
          },
        },
      });
    },
  });

  const handleSave = async () => {
    await editTeamDisplaySettings({
      variables: {
        input: {
          teamId,
          hideAnthropometry,
          enableEdsa: enableEDSA,
          noTargetsDisplaySettings: {
            dailyProgressViewsForMacros: noTargetsDisplaySettings.dailyProgressViewsForMacros,
            mealProgressViewsForMacros: noTargetsDisplaySettings.mealProgressViewsForMacros,
          },
          macroDisplaySettings: {
            dailyProgressViews: macroDisplaySettings.dailyProgressViews,
            mealProgressViews: macroDisplaySettings.mealProgressViews,
          },
          exchangeDisplaySettings: {
            dailyProgressViewsForExchanges: exchangeDisplaySettings.dailyProgressViewsForExchanges,
            dailyProgressViewsForMacros: exchangeDisplaySettings.dailyProgressViewsForMacros,
            mealProgressViewsForExchanges: exchangeDisplaySettings.mealProgressViewsForExchanges,
            mealProgressViewsForMacros: exchangeDisplaySettings.mealProgressViewsForMacros,
          },
        },
      },
    });
    onClose();
  };

  return (
    <Dialog
      maxWidth="lg"
      id="form-dialog-title"
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Display Settings" onClose={onClose} />
      <DialogContent>
        <div className={classes.content}>
          {showEDSA && <EDSASettings enableEDSA={enableEDSA} onChangeEDSASetting={setEnableEDSA} />}
          <DisplaySettingsTable
            macroDisplaySettings={macroDisplaySettings}
            exchangeDisplaySettings={exchangeDisplaySettings}
            noTargetsDisplaySettings={noTargetsDisplaySettings}
            hideAnthropometry={hideAnthropometry}
            onChangeMacroDisplaySettings={setMacroDisplaySettings}
            onChangeExchangeDisplaySettings={setExchangeDisplaySettings}
            onChangeNoTargetsDisplaySettings={setNoTargetsDisplaySettings}
            onChangeHideAnthropometry={setHideAnthropometry}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamDashboardDisplaySettingsEditModal;
