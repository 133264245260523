import { List, ListItem, ListItemText } from "@mui/material";
import { sortByKey } from "@notemeal/utils-sort";
import React from "react";
import { MenuItemChoiceFormFragment } from "../../../types";

interface MenuItemChoiceReadonlyListProps {
  choices: readonly MenuItemChoiceFormFragment[];
  setSelectedChoice?: (choice: MenuItemChoiceFormFragment) => void;
}

const MenuItemChoiceReadonlyList = ({ choices: _choices, setSelectedChoice }: MenuItemChoiceReadonlyListProps) => {
  const choices = sortByKey(_choices, "position");

  return (
    <List sx={{ m: 1, p: 0, position: "relative", overflowY: "auto" }}>
      {sortByKey(choices, "position").map(c => {
        return (
          <ListItem
            key={c.id}
            onClick={() => (setSelectedChoice ? setSelectedChoice(c) : undefined)}
            dense
            button={!!setSelectedChoice as any}
          >
            <ListItemText primary={c.name} secondary={c.required ? "Required" : "Optional"} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default MenuItemChoiceReadonlyList;
