import { environment } from "apps/web/src/environment";
import axios from "axios";

export const useDeleteResource = () => {
  const deleteUrl = `${environment.VITE_URL_SCHEME}${environment.VITE_SERVER_DOMAIN}/delete-education-resource`;

  const deleteResource = async (fileKey: string) => {
    try {
      await axios.delete(deleteUrl, {
        params: { fileKey },
        withCredentials: true,
      });
    } catch (err) {
      console.error({
        type: "misc",
        message: (err as Error).message,
      });
    }
  };
  return deleteResource;
};
