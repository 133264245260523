import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, List, TextField } from "@mui/material";
import { sortByKey } from "@notemeal/utils-sort";
import React, { useState } from "react";
import MenuItemDialogListItem from "./ListItem";
import { MenuOrderItemSummary } from "./types";

interface MenuItemDialogContentProps {
  menuOrders: MenuOrderItemSummary[];
}

const MenuItemDialogContent = ({ menuOrders }: MenuItemDialogContentProps) => {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={e => setSearchQuery(e.target.value || null)}
        placeholder="Search for Menu Items"
        fullWidth
      />
      <List sx={{ overflow: "auto" }}>
        {sortByKey(
          menuOrders.filter(moi => !searchQuery || moi.menuItem.name.toLowerCase().includes(searchQuery.toLowerCase())),
          "amount",
          { reverse: true }
        ).map(mois => (
          <MenuItemDialogListItem key={mois.menuItem.id} menuOrderItemSummary={mois} />
        ))}
      </List>
    </Box>
  );
};

export default MenuItemDialogContent;
