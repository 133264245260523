import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import { Avatar, Box, SxProps, Theme, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortByFn } from "@notemeal/utils-sort";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import classnames from "classnames";
import React, { useState } from "react";
import DarkIconButton from "../../../components/universal/DarkIconButton";
import { RestaurantMenuLinkState } from "../RestaurantMenu/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    avatarSpacing: {
      margin: theme.spacing(3, 0, 0, 3),
      position: "relative",
    },
    avatar: {
      cursor: "pointer",
      height: 48,
      width: 48,
    },
    removeButton: {
      position: "absolute",
      top: theme.spacing(-1),
      right: theme.spacing(-1),
    },
  })
);

interface MenuDialogMenuAvatarsProps {
  selectedRestaurantMenuLinkId: string | null;
  onSelectRestaurantMenuLink: (id: string | null) => void;
  restaurantMenuLinks: readonly RestaurantMenuLinkState[];
  onAddRestaurant?: () => void;
  onRemoveRestaurantMenuLink?: (id: string) => void;
  className?: string;
  sx?: SxProps;
}

const MenuDialogMenuAvatars = ({
  selectedRestaurantMenuLinkId,
  onSelectRestaurantMenuLink,
  onAddRestaurant,
  restaurantMenuLinks,
  onRemoveRestaurantMenuLink,
  className,
  sx,
}: MenuDialogMenuAvatarsProps) => {
  const classes = useStyles();
  const {
    palette: { grey },
  } = useTheme();
  const avatarGrey = grey[200];
  const combinedAvatarClass = classnames(classes.avatarSpacing, classes.avatar);
  const [removeRestaurantMenuLink, setRemoveRestaurantMenuLink] = useState<RestaurantMenuLinkState | null>(null);

  return (
    <Box sx={sx} className={classnames(classes.root, className)}>
      {/* In-House */}
      <InHouseAvatar selected={selectedRestaurantMenuLinkId === null} onClick={() => onSelectRestaurantMenuLink(null)} />

      {/* Restaurants */}
      {sortByFn(restaurantMenuLinks, l => l.restaurant.name).map(link => (
        <RestaurantMenuLinkAvatar
          key={link.id}
          onClick={() => onSelectRestaurantMenuLink(link.id)}
          onRemove={onRemoveRestaurantMenuLink ? () => setRemoveRestaurantMenuLink(link) : undefined}
          selected={selectedRestaurantMenuLinkId === link.id}
          restaurantName={link.restaurant.name}
        />
      ))}

      {/* Add Restaurant */}
      {onAddRestaurant && (
        <TWTooltip
          title="Add Restaurant"
          subcopy=""
          placement="right">
          <Avatar
            style={getAvatarStyles("black", false, avatarGrey)}
            className={combinedAvatarClass}
            onClick={onAddRestaurant}>
            <AddIcon />
          </Avatar>
        </TWTooltip>
      )}

      {removeRestaurantMenuLink && onRemoveRestaurantMenuLink && (
        <ConfirmationDialog
          open
          title="Remove Restaurant Item"
          message={`Remove ${removeRestaurantMenuLink.restaurant.name} from Menu?`}
          onCancel={() => setRemoveRestaurantMenuLink(null)}
          onConfirm={() => {
            setRemoveRestaurantMenuLink(null);
            onRemoveRestaurantMenuLink(removeRestaurantMenuLink.id);
          }}
        />
      )}
    </Box>
  );
};

interface ViewOnlyMenuAvatarsProps {
  selectedRestaurantMenuLinkId: string | null;
  onSelectRestaurantMenuLink: (id: string | null) => void;
  restaurantMenuLinks: readonly {
    id: string;
    restaurant: {
      name: string;
    };
  }[];
  hasDiningStations: boolean;
  className?: string;
}

export const ViewOnlyMenuAvatars = ({
  selectedRestaurantMenuLinkId,
  onSelectRestaurantMenuLink,
  restaurantMenuLinks,
  hasDiningStations,
  className,
}: ViewOnlyMenuAvatarsProps) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.root, className)}>
      {/* In-House */}
      {hasDiningStations && (
        <InHouseAvatar selected={selectedRestaurantMenuLinkId === null} onClick={() => onSelectRestaurantMenuLink(null)} />
      )}
      {/* Restaurants */}
      {sortByFn(restaurantMenuLinks, l => l.restaurant.name).map(link => (
        <RestaurantMenuLinkAvatar
          key={link.id}
          onClick={() => onSelectRestaurantMenuLink(link.id)}
          selected={selectedRestaurantMenuLinkId === link.id}
          restaurantName={link.restaurant.name}
        />
      ))}
    </div>
  );
};

interface InHouseAvatarProps {
  selected: boolean;
  onClick: () => void;
}

const InHouseAvatar = ({ selected, onClick }: InHouseAvatarProps) => {
  const classes = useStyles();
  const {
    palette: { grey },
  } = useTheme();
  const avatarGrey = grey[200];
  const combinedAvatarClass = classnames(classes.avatarSpacing, classes.avatar);
  return (
    <TWTooltip
      title="In-House Menu"
      subcopy=""
      placement="right">
      <Avatar
        style={getAvatarStyles("black", selected, avatarGrey)}
        className={combinedAvatarClass}
        onClick={onClick}>
        <HomeIcon />
      </Avatar>
    </TWTooltip>
  );
};

interface RestaurantMenuLinkAvatarProps {
  restaurantName: string;
  selected: boolean;
  onClick: () => void;
  onRemove?: () => void;
}

const RestaurantMenuLinkAvatar = ({ restaurantName, selected, onClick, onRemove }: RestaurantMenuLinkAvatarProps) => {
  const classes = useStyles();
  const {
    palette: { grey },
  } = useTheme();
  const avatarGrey = grey[200];

  return (
    <TWTooltip
      subcopy=""
      title={restaurantName}
      placement="right">
      <Box sx={{ mt: 3, mr: 0, mb: 0, ml: 3, display: "flex", alignItems: "center", position: "relative", gap: 2 }}>
        <Avatar
          style={getAvatarStyles(stringToColor(restaurantName), selected, avatarGrey)}
          className={classes.avatar}
          onClick={onClick}>
          {restaurantName[0]}
          {restaurantName[1]}
        </Avatar>

        {onRemove && (
          <DarkIconButton
            onClick={onRemove}
            size="small"
            sx={{ height: 30, width: 30 }}>
            <CloseIcon fontSize="small" />
          </DarkIconButton>
        )}
      </Box>
    </TWTooltip>
  );
};

const getAvatarStyles = (color: string, selected: boolean, grey: string): React.CSSProperties => {
  return selected ? { backgroundColor: color } : { color, backgroundColor: grey };
};

const stringToColor = (value: string): string => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < value.length; i += 1) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export default MenuDialogMenuAvatars;
