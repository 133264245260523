import React from "react";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import Check from "@mui/icons-material/Check";
import { greyBlue } from "apps/web/src/pages/Theme/Colors/Accents";

type TWStepperProps = {
  stepLabels: string[];
  activeStep: number;
};

export const TWStepper = ({ stepLabels, activeStep }: TWStepperProps) => {
  const TWStepIconRoot = styled("div")<{ ownerState: { active?: boolean; completed?: boolean } }>(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: greyBlue[100],
    "& .TWStepIcon-circle": {
      width: 24,
      height: 24,
      borderRadius: "50%",
      backgroundColor: "currentColor",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: 14,
        fontWeight: 600,
      },
    },
    variants: [
      {
        props: ({ ownerState }: any) => ownerState.active,
        style: {
          "& .TWStepIcon-circle": {
            border: `1px solid ${theme.palette.info.main}`,
            "& .MuiTypography-root": {
              color: theme.palette.info.main,
            },
          },
        },
      },
      {
        props: ({ ownerState }: any) => ownerState.completed,
        style: {
          "& .TWStepIcon-circle": {
            backgroundColor: theme.palette.info.main,
            ".TWStepIcon-completedIcon": {
              color: "white",
              zIndex: 1,
              fontSize: 18,
            },
          },
        },
      },
    ],
  }));

  const TWStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    return (
      <TWStepIconRoot ownerState={{ active, completed }} className={className}>
        <div className="TWStepIcon-circle">
          {completed ? <Check className="TWStepIcon-completedIcon" /> : <Typography>{props.icon}</Typography>}
        </div>
      </TWStepIconRoot>
    );
  };

  return (
    <Stepper
      sx={{ p: 0, mb: 3, justifyContent: "center", minWidth: 200 }}
      activeStep={activeStep}
      orientation="vertical">
      {stepLabels.map(label => (
        <Step key={label} sx={{ display: "flex", height: 24 }}>
          <StepLabel StepIconComponent={TWStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
