import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MenuOrderItemWithAppearance, PickupTime } from "@notemeal/shared-ui";
import MenuOrderFormItems from "./Items";
import KioskMenuOrderFormTimePicker from "./KioskTimePicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timePicker: {
      minWidth: 105,
    },
  })
);

interface MenuOrderFormForOrderProps {
  menuOrderItems: readonly MenuOrderItemWithAppearance[];
  selectedItemId: string | null;
  onSelectItem: (item: MenuOrderItemWithAppearance) => void;
  onRemoveItem: (menuOrderItemId: string) => void;
  pickupTime: PickupTime | null;
  orderPickupTimes: PickupTime[];
  onChangePickupTime: (pickupTime: PickupTime) => void;
  mealMenuTimezone: string;
  menuOrderingClosed: boolean;
  openTimePicker?: boolean;
  disabled?: boolean;
  mealMenuName: string;
}

const KioskMenuOrderFormForOrder = ({
  onRemoveItem,
  menuOrderItems,
  menuOrderingClosed,
  onChangePickupTime,
  pickupTime,
  orderPickupTimes,
  mealMenuTimezone,
  selectedItemId,
  onSelectItem,
  openTimePicker,
  disabled,
  mealMenuName,
}: MenuOrderFormForOrderProps) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body2">
        <strong>{mealMenuName}</strong>
      </Typography>
      <KioskMenuOrderFormTimePicker
        className={classes.timePicker}
        forOrder={true}
        pickupTime={pickupTime}
        onChangePickupTime={onChangePickupTime}
        pickupTimes={orderPickupTimes}
        mealMenuTimezone={mealMenuTimezone}
        disabled={disabled || menuOrderingClosed}
        openTimePicker={openTimePicker}
      />
      <MenuOrderFormItems
        selectedItemId={selectedItemId}
        onSelectItem={onSelectItem}
        onRemoveItem={onRemoveItem}
        menuOrderItems={menuOrderItems}
        disabled={disabled || menuOrderingClosed}
      />
    </>
  );
};

export default KioskMenuOrderFormForOrder;
