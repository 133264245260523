import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { addSelectionOptionsToMenuItem, useDateFormatting, useHasFeature } from "@notemeal/shared-ui";
import { sortByFn, sortByKey } from "@notemeal/utils-sort";
import { getMenuOrderUpdatedAt } from "../utils";
import { GenericWebPrinterTicketOrder } from "./types";
import { getTicketDisplayNameForAthlete } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    denseLineHeight: { lineHeight: 1 },
    label: {
      "@media screen": {
        display: "none",
      },
      margin: "0px !important",
      padding: "0px !important",
      pageBreakAfter: "always",
    },
    rowWrap: {
      display: "flex",
      flexFlow: "row wrap",
    },
    h6: {
      fontSize: "12px",
      lineHeight: 1,
    },
    diningStationName: {
      fontSize: "8px",
      textDecoration: "underline",
      lineHeight: 1,
      paddingTop: 0,
      marginTop: 0,
    },
    allergies: {
      fontSize: "8px",
      lineHeight: 0.8,
      paddingTop: 0,
      marginTop: 0,
    },
    caption: {
      fontSize: "8px",
      lineHeight: 1,
      paddingLeft: theme.spacing(),
      paddingTop: 0,
      marginTop: 0,
    },
    subtitle1: {
      fontSize: "10px",
      fontWeight: "bold",
      lineHeight: 0.8,
      padding: 0,
      margin: 0,
      paddingTop: theme.spacing(0.5),
    },
    subtitle2: {
      fontSize: "8px",
      lineHeight: 0.8,
      padding: 0,
      margin: 0,
      paddingTop: theme.spacing(0.5),
    },
    indent: {
      paddingLeft: theme.spacing(),
    },
    spacer: {
      flexGrow: 1,
    },
    menuItemRow: {
      display: "flex",
      flexFlow: "column",
      alignItems: "baseline",
      lineHeight: 1,
      justifyContent: "space-between",
    },
    choiceRow: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "flex-start",
      justifyContent: "space-between",
      //padding: theme.spacing(.5)
    },
    specialRequests: { display: "flex" },
    padding: {
      paddingLeft: theme.spacing(0.5),
      marginTop: -theme.spacing(0.5),
    },
    newLine: { minHeight: theme.spacing(3) },
  })
);

interface KdsTicketPrintableLabelProps {
  order: GenericWebPrinterTicketOrder;
}

// TODO: this needs to be updated to the latest designs still
const KdsTicketPrintableLabel = ({ order }: KdsTicketPrintableLabelProps) => {
  const classes = useStyles();
  const hasPrintableTags = useHasFeature("printableTags");
  const { formatEventDatetimeWithLocale } = useDateFormatting();
  const diningStationNames = order ? Object.keys(order.diningStationsToMenuOrderItems).sort() : [];
  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const athlete = order.athlete || null;
  const menuOrderUpdatedAt = getMenuOrderUpdatedAt(order, false);
  const deliveryLocation = order.__typename === "MealMenuPlateOrder" ? order.parentBulkOrder.deliveryLocation : null;
  const orderCode = order.__typename === "MealMenuPlateOrder" ? order.parentBulkOrder.code : order.code;

  const pickupTime = order.__typename === "MenuOrder" ? order.pickupTime : order.parentBulkOrder.pickupTime;

  return (
    <div id={`printable-order-${order.id}`} className={classes.label}>
      <Typography classes={{ root: classes.denseLineHeight }} variant="h3">
        {athlete ? getTicketDisplayNameForAthlete(athlete) : order.userFullName}
      </Typography>
      {athlete && (
        <Typography
          component="em"
          classes={{ root: classes.denseLineHeight }}
          variant="subtitle1">
          Team: {athlete.team.name}
          {athlete.position && `(${athlete.position.name})`}
        </Typography>
      )}
      {athlete && hasPrintableTags && (
        <div className={classes.menuItemRow}>
          <Typography classes={{ root: classes.allergies }} variant="subtitle1">
            Tags: {(athlete.printableTagsForAthlete || []).map(t => t.tagName.name).join(", ")}
          </Typography>
        </div>
      )}
      <Typography classes={{ root: classes.denseLineHeight }} variant="h3">
        #{orderCode} -{formatEventDatetimeWithLocale(pickupTime, { timeZone: clientTimezone })} (
        {Object.values(order.itemsByDiningStation).length}/{order.itemCount})
      </Typography>
      {deliveryLocation && (
        <Typography
          component="em"
          classes={{ root: classes.denseLineHeight }}
          variant="subtitle1">
          Notes: {deliveryLocation}
        </Typography>
      )}
      {menuOrderUpdatedAt && (
        <Typography classes={{ root: classes.denseLineHeight }} variant="h3">
          Updated At {formatEventDatetimeWithLocale(menuOrderUpdatedAt, { timeZone: clientTimezone })}
        </Typography>
      )}
      {athlete && (
        <div className={classes.menuItemRow}>
          <Typography classes={{ root: classes.allergies }} variant="subtitle1">
            {(athlete.dislikedFoodGroups || [])
              .concat(athlete.dislikedFoods || [])
              .map(f => `⛔ ${f.name}`)
              .join(", ")}
          </Typography>
        </div>
      )}
      {diningStationNames.map((dsName, idx) => (
        <div key={`${dsName}-${idx}`}>
          <Typography
            classes={{ root: classes.diningStationName }}
            variant="subtitle1"
            noWrap>
            {dsName}
          </Typography>
          {order &&
            sortByFn(order.diningStationsToMenuOrderItems[dsName], item => item.menuItem.name).map(
              ({ id, amount, status, menuItem, options, specialRequests, updateCount }) => (
                <div key={id} className={classes.indent}>
                  <div className={classes.menuItemRow}>
                    <Typography classes={{ root: classes.subtitle1 }} variant="body1Medium">
                      {updateCount > 0 && `UPDATES:${updateCount} => `}
                      {amount > 1 ? `*${amount}*` : amount} x {menuItem.name} ({menuItem.servingName})
                    </Typography>
                  </div>
                  <div className={classes.choiceRow}>
                    {sortByKey(addSelectionOptionsToMenuItem(menuItem, options).choices, "position").map(
                      c =>
                        c.options.filter(o => !!o.menuSelectionItemOption).length > 0 && (
                          <div key={`${menuItem.id}-${c.name}`}>
                            <Typography classes={{ root: classes.subtitle2 }} variant="body2Medium">
                              {c.name}
                            </Typography>
                            <div className={classes.rowWrap}>
                              {sortByKey(c.options, "position").map(
                                o =>
                                  o.menuSelectionItemOption && (
                                    <Typography
                                      key={o.id}
                                      classes={{ root: classes.caption }}
                                      variant="subtitle1">
                                      {o.menuSelectionItemOption.amount} x {o.name}
                                    </Typography>
                                  )
                              )}
                            </div>
                          </div>
                        )
                    )}
                  </div>
                  {options.length > 0 && <div className={classes.newLine}></div>}
                  {specialRequests && (
                    <div className={classes.specialRequests}>
                      <Typography classes={{ root: classes.subtitle2 }} variant="subtitle1">
                        Special Requests:
                      </Typography>
                      <div className={classes.padding} />
                      <Typography classes={{ root: classes.subtitle2 }} variant="subtitle1">
                        {specialRequests}
                      </Typography>
                    </div>
                  )}
                </div>
              )
            )}
        </div>
      ))}
    </div>
  );
};

export default KdsTicketPrintableLabel;
