import { useDebounce } from "@notemeal/shared-ui";
import { NamedTagForEditFragment, useIsTagNameUniqueQuery } from "../../../types";

export interface BaseTagState {
  name: string;
  description: string;
  isPrintable: boolean | null;
}

export interface EditNameAction {
  type: "EDIT_NAME_ACTION";
  payload: string;
}

export interface EditDescriptionAction {
  type: "EDIT_DESCRIPTION_ACTION";
  payload: string;
}

export interface EditIsPrintableAction {
  type: "EDIT_IS_PRINTABLE_ACTION";
  payload: boolean;
}

export type BaseTagAction = EditNameAction | EditDescriptionAction | EditIsPrintableAction;

export const MAX_NAME_LENGTH = 25;
export const MAX_DESCRIPTION_LENGTH = 100;

export const tagReducers = <T extends BaseTagState>(state: T, action: BaseTagAction): T => {
  switch (action.type) {
    case "EDIT_NAME_ACTION":
      if (action.payload.length > MAX_NAME_LENGTH) {
        return state;
      }
      return {
        ...state,
        name: action.payload,
      };
    case "EDIT_DESCRIPTION_ACTION":
      if (action.payload.length > MAX_DESCRIPTION_LENGTH) {
        return state;
      }
      return {
        ...state,
        description: action.payload,
      };
    case "EDIT_IS_PRINTABLE_ACTION":
      return {
        ...state,
        isPrintable: action.payload,
      };
    default:
      return state;
  }
};

export const buildInitialBaseTagState = (): BaseTagState => ({
  name: "",
  description: "",
  isPrintable: null,
});

export const buildInitialBaseTagStateFromNamedTag = (namedTag: NamedTagForEditFragment): BaseTagState => ({
  name: namedTag.tagName.name,
  description: namedTag.tagName.description,
  isPrintable: namedTag.tagName.isPrintable,
});

export const useValidateBaseTagState = (state: BaseTagState, existingName?: String): [string[], boolean] => {
  const { name, description } = state ?? { name: "", description: "" };
  const debouncedName = useDebounce(name, 200);
  const { data, loading } = useIsTagNameUniqueQuery({
    variables: { name: debouncedName },
    skip: debouncedName === "" || debouncedName === existingName,
    fetchPolicy: "network-only",
  });
  const errors: string[] = [];
  const matchesCurrentName = debouncedName.toLowerCase() === existingName?.toLowerCase();

  if (name === "") {
    errors.push("Name must be defined");
  } else if (matchesCurrentName) {
    // Do Nothing
  } else if (loading || !data) {
    errors.push("Loading...Confirming Name is Unique");
  } else if (!data.isTagNameUnique.isUnique) {
    errors.push("Tag Names must be unique");
  }

  if (name.length > MAX_NAME_LENGTH) {
    errors.push(`Tag Names must be less than ${MAX_NAME_LENGTH + 1} characters long`);
  }

  if (description.length > MAX_DESCRIPTION_LENGTH) {
    errors.push(`Tag Descriptions must be less than ${MAX_DESCRIPTION_LENGTH + 1} characters long`);
  }

  // TODO: what should we do in this case
  const backendUnique = data?.isTagNameUnique ? data.isTagNameUnique.isUnique : true;
  const isTagNameUnique = matchesCurrentName || backendUnique;
  return [errors, isTagNameUnique];
};
