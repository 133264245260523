import { Worksheet, Row, Cell } from "exceljs";
import { checkFirstRowForHeaders } from "./validate";
import { inputToNumber } from "@notemeal/shared-ui";
import { LocaleType } from "@notemeal/locale-utils";
import { isDate, isValid, parse } from "date-fns";
import { enGB, enUS } from "date-fns/locale";

const colNameAndRowNumber = (cell: Cell) => {
  const rowNumber = cell.row;
  const colName = cell.worksheet.getRow(1).getCell(cell.col).text;
  return { rowNumber, colName };
};

const cellToNumber = (cell: Cell | null): number | null => {
  if (!cell) {
    return null;
  }
  const value = inputToNumber(cell.text);
  if (cell.text && !value) {
    const { rowNumber, colName } = colNameAndRowNumber(cell);
    throw new Error(`Number '${cell.text}' in '${colName}' is formatted incorrectly (row: ${rowNumber})`);
  }

  return value;
};

const cellToRequiredNumber = (cell: Cell | null): number | null => {
  if (!cell) {
    return null;
  }

  const value = cellToNumber(cell);
  if (value === null) {
    const { rowNumber, colName } = colNameAndRowNumber(cell);
    throw new Error(`Required '${colName}' is missing (row: ${rowNumber})`);
  }
  return value;
};

const cellToDate = (cell: Cell | null, locale: LocaleType): Date | null => {
  if (!cell || !cell.text) {
    return null;
  }

  const { text, value, type } = cell;
  let rawDate: Date | undefined = undefined;
  // type 4 is an excelJS date
  if (type === 4 && isDate(value)) {
    rawDate = value as Date;
  } else if (locale === "en-US") {
    // "P" will only parse full localized data strings: MM/DD/YYYY
    rawDate = parse(text, "P", new Date(), { locale: enUS });
  } else if (locale === "en-GB") {
    // "P" will only parse full localized data strings: DD/MM/YYYY
    rawDate = parse(text, "P", new Date(), { locale: enGB });
  }

  const { rowNumber, colName } = colNameAndRowNumber(cell);
  if (rawDate === undefined || !isValid(rawDate)) {
    throw new Error(`Invalid date ${text} found in row ${rowNumber + 1}. Expected format: 10/10/1992`);
  } else if (rawDate.getMinutes() !== 0 || rawDate.getSeconds() !== 0) {
    throw new Error(`Date '${cell.text}' in col '${colName}' shouldn't include time (row: ${rowNumber})`);
  }
  // Hack to prevent "day before" bug
  return new Date(rawDate.getTime() + rawDate.getTimezoneOffset() * 60 * 1000);
};

const fieldToDate = (fieldValue: string, locale: LocaleType, rowIndex: number): Date | null => {
  const rawDate = parseImportDate(fieldValue, locale);

  if (rawDate === undefined || !isValid(rawDate) || rawDate.getFullYear() < 1000) {
    throw new Error(`Invalid date ${fieldValue} found in row ${rowIndex + 1}. Expected format: 10/10/1992`);
  } else if (rawDate.getMinutes() !== 0 || rawDate.getSeconds() !== 0) {
    throw new Error(`Date in row ${rowIndex} shouldn't include time`);
  }

  // Hack to prevent "day before" bug
  return new Date(rawDate.getTime() + rawDate.getTimezoneOffset() * 60 * 1000);
};

const cellToRequiredDate = (cell: Cell, locale: LocaleType): Date => {
  const dt = cellToDate(cell, locale);
  if (dt === null) {
    const { rowNumber, colName } = colNameAndRowNumber(cell);
    throw new Error(`Required date '${colName}' is missing or formatted incorrectly (row: ${rowNumber})`);
  }
  return dt;
};

const cellToString = (cell: Cell | null): string | null => {
  if (!cell) {
    return null;
  }
  if (cell.formula) {
    try {
      const linkedCell = cell.worksheet.getCell(cell.formula);
      return cellToString(linkedCell); // Recurse!
    } catch (e) {
      const { rowNumber, colName } = colNameAndRowNumber(cell);
      throw new Error(`Unsupported formula '${cell.formula}' is present in (row ${rowNumber}, col'${colName}')`);
    }
  }
  if (!cell.text) {
    return null;
  }
  return cell.text;
};

const cellToRequiredString = (cell: Cell): string => {
  const value = cellToString(cell);
  if (value === null) {
    const { rowNumber, colName } = colNameAndRowNumber(cell);
    throw new Error(`Required '${colName}' is missing (row: ${rowNumber})`);
  }
  return value;
};

export interface ImportedField<R, I> {
  fields: (keyof R)[]; // Can support many fields, i.e. "Most Recent Date" and "Date" in iDXA

  required?: boolean;
  importableField: keyof I;
  transform?: (val: any) => any;
}
// Date, Number, String
export interface IParse<R, I> {
  numberFields: ImportedField<R, I>[];
  dateFields: ImportedField<R, I>[];
  stringFields: ImportedField<R, I>[];
  onError: (msg: string) => void;
}

export interface IParseDelimitedFile<R, I> extends IParse<R, I> {
  rows: R[];
  locale: LocaleType;
}

const getField = <T>(row: T, keys: (keyof T)[], i: number, required?: boolean): string | undefined => {
  const value = keys.reduce((currValue, f) => (row[f] ? String(row[f]) : currValue), undefined as undefined | string);
  if (!value && required) {
    throw new Error(`Row '${i}' is missing required value in field '${keys.join(",")}'. ${JSON.stringify(row)}`);
  }
  return value;
};

export const parseDelimitedFile = <R, I>({
  rows,
  numberFields,
  dateFields,
  stringFields,
  onError,
  locale,
}: IParseDelimitedFile<R, I>): I[] => {
  let rowsToReturn: I[] = [];
  const defaultRow = {} as { [key in keyof I]: I[key] };
  try {
    rows.forEach((r, i) => {
      const numberFieldsRow = numberFields.reduce((rowObj, f) => {
        const val = getField(r, f.fields, i, f.required);
        const transformFn = f.transform ? f.transform : (v: any) => parseFloat(v);
        return {
          ...rowObj,
          [f.importableField]: val ? transformFn(val) : val,
        };
      }, defaultRow);
      const stringFieldsRow = stringFields.reduce((rowObj, f) => {
        const val = getField(r, f.fields, i, f.required);
        const transformFn = f.transform ? f.transform : (v: any) => v;
        return {
          ...rowObj,
          [f.importableField]: val ? transformFn(val) : val,
        };
      }, defaultRow);
      const dateFieldsRow = dateFields.reduce((rowObj, f) => {
        const transformFunc = f.transform ? f.transform : (i: any): any => i;
        const val = getField(r, f.fields, i, f.required);
        return {
          ...rowObj,
          [f.importableField]: val ? transformFunc(fieldToDate(val, locale, i)) : null,
        };
      }, defaultRow);
      const row = { ...stringFieldsRow, ...numberFieldsRow, ...dateFieldsRow };
      rowsToReturn.push(row);
    });
    return rowsToReturn;
  } catch (e) {
    if (e instanceof Error) {
      onError(e.message);
    } else {
      onError("Something went wrong!");
    }
    return [] as I[];
  }
};

interface IParseWorksheet<R, I> extends IParse<R, I> {
  worksheet: Worksheet;
  locale: LocaleType;
}

export const parseCsv = (raw: string, strDelimiter: string | undefined): string[][] => {
  strDelimiter = strDelimiter || ",";

  // Create a regular expression to parse the CSV values.
  const objPattern = new RegExp(
    // Delimiters.
    "(\\" +
      strDelimiter +
      "|\\r?\\n|\\r|^)" +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      strDelimiter +
      "\\r\\n]*))",
    "gi"
  );
  let arrData: string[][] = [[]];
  let arrMatches = null;
  while ((arrMatches = objPattern.exec(raw))) {
    // Get the delimiter that was found.
    const strMatchedDelimiter = arrMatches[1];
    // if the delimiter found is not our delimiter assume new row
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      arrData.push([]);
    }
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      const strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
      arrData[arrData.length - 1].push(strMatchedValue);
    } else {
      // We found a non-quoted value.
      const strMatchedValue = arrMatches[3];
      arrData[arrData.length - 1].push(strMatchedValue);
    }
  }
  // Return the parsed data. Filter is for Weird edge case in last row all being falsy (e.g. undefined's and '')
  return arrData.filter(row => row.some(elem => !!elem));
};

export const parseWorksheet = <R, I>({
  worksheet,
  numberFields,
  dateFields,
  stringFields,
  onError,
  locale,
}: IParseWorksheet<R, I>): I[] => {
  let rows: I[] = [];
  const firstRow = worksheet.getRow(1);
  const defaultRow = {} as { [key in keyof I]: I[key] };

  checkFirstRowForHeaders(firstRow, [...numberFields, ...dateFields, ...stringFields]);
  const fieldsToIndex = (firstRow.values as (keyof R)[]).reduce(
    (fieldMap, fieldName, idx) => ({
      ...fieldMap,
      [fieldName]: idx,
    }),
    {} as { [key in keyof R]: number }
  ); // Keep track of the initial "index" of the each field name in the excel file.
  const _getCellByName = (row: Row, fields: (keyof R)[]): Cell | null => {
    const value = fields.reduce((currVal, f) => {
      try {
        const newVal = row.getCell(fieldsToIndex[f]);
        return currVal || newVal;
      } catch (e) {
        // This allows us to supply alias column names...
        console.warn(`Failed to get cell: *${f as string}* for row ${row.number}`);
        return currVal;
      }
    }, null as null | Cell);
    return value;
  };

  const getRequiredCellByName = (row: Row, fields: (keyof R)[]): Cell => {
    const cell = _getCellByName(row, fields);
    if (!cell) {
      throw new Error(`Runtime Error: Expected one of fields: ${fields.join(",")}`);
    } else {
      return cell;
    }
  };

  const getOptionalCellByName = (row: Row, fields: (keyof R)[]): Cell | null => {
    return _getCellByName(row, fields);
  };

  worksheet.eachRow((excelRow, excelRowNumber) => {
    try {
      if (excelRowNumber > 1) {
        const numberFieldsRow = numberFields.reduce(
          (rowObj, f) => ({
            ...rowObj,
            [f.importableField]: f.required
              ? cellToRequiredNumber(getRequiredCellByName(excelRow, f.fields))
              : cellToNumber(getOptionalCellByName(excelRow, f.fields)),
          }),
          defaultRow
        );
        const stringFieldsRow = stringFields.reduce((rowObj, f) => {
          const transformFn = f.transform ? f.transform : (i: any): any => i;
          return {
            ...rowObj,
            [f.importableField]: f.required
              ? transformFn(cellToRequiredString(getRequiredCellByName(excelRow, f.fields)))
              : transformFn(cellToString(getOptionalCellByName(excelRow, f.fields))),
          };
        }, defaultRow);
        const dateFieldsRow = dateFields.reduce((rowObj, f) => {
          const transformFn = f.transform ? f.transform : (i: any): any => i;
          return {
            ...rowObj,
            [f.importableField]: f.required
              ? transformFn(cellToRequiredDate(getRequiredCellByName(excelRow, f.fields), locale))
              : transformFn(cellToDate(getOptionalCellByName(excelRow, f.fields), locale)),
          };
        }, defaultRow);
        const row = {
          ...numberFieldsRow,
          ...stringFieldsRow,
          ...dateFieldsRow,
        };
        rows.push(row);
      }
    } catch (e) {
      console.error(e, `Row ${excelRowNumber}: `, excelRow);
      if (e instanceof Error) {
        onError(`Error at row *${excelRowNumber}* - ${e.message}`);
      } else {
        onError("Something went wrong!");
      }
    }
  });
  return rows;
};

const parseImportDate = (textValue: string, locale: LocaleType): Date | undefined => {
  let rawDate: Date | undefined = undefined;
  const cleanInputDate = textValue.split(" ")[0];

  if (locale === "en-US") {
    // "P" will only parse full localized data strings: MM/DD/YYYY
    rawDate = parse(cleanInputDate, "P", new Date(), { locale: enUS });
  } else if (locale === "en-GB") {
    // "P" will only parse full localized data strings: DD/MM/YYYY
    rawDate = parse(cleanInputDate, "P", new Date(), { locale: enGB });
  }

  // not being able to enforce various reporting systems
  // means we will have to catch various cases not handled by date-fns.parse()
  // case sample - 2024.01.02
  if (rawDate === undefined || !isValid(rawDate)) {
    if (locale === "en-US") {
      rawDate = parse(cleanInputDate, "y.L.d", new Date(), { locale: enUS });
    } else if (locale === "en-GB") {
      rawDate = parse(cleanInputDate, "y.L.d", new Date(), { locale: enGB });
    }
  }

  return rawDate;
};
