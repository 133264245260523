import BarChartIcon from "@mui/icons-material/BarChart";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Card, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { Loading, getMacroProtocolMacros, getServingAmountsMacros } from "@notemeal/shared-ui";
import { Macros } from "@notemeal/shared-utils-macro-protocol";
import { MacrosProgress } from "apps/web/src/components/MacrosProgress/MacrosProgress";
import { getNavOrgAthleteMealPlansMealPlan } from "apps/web/src/pages/Auth/Org/Athlete/AthletePaths";
import { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import NutrientAmountDialog from "../../../../components/NutrientAmount/Modal";
import { FullServingAmountFragment, MealPlanTimelineFragment, PreviewMealPlanFragment } from "../../../../types";
import { useDriParams } from "../contexts/useDriParams";
import { useMacroProgressViews } from "../contexts/useMacroProgressViews";
import ChangeMealPlanDialog from "./ChangeMealPlanDialog";
import { TimelineDateColumnSelect } from "./Select";

interface TimelineDateColumnHeaderProps {
  athleteBirthdate: string | null;
  athleteMealPlans: readonly PreviewMealPlanFragment[];
  athleteId: string;
  mealPlan: MealPlanTimelineFragment | null;
  loading: boolean;
  date: string;
  onChangeDate: (date: string | null) => void;
  allServingAmounts: readonly FullServingAmountFragment[];
  targetDailyMacros: Macros | null;
  onEditMealPlanDate: (mealPlanId: string) => void;
  onRemoveMealPlanDate: () => void;
}

export const TimelineDateColumnHeader = ({
  athleteBirthdate,
  athleteMealPlans,
  athleteId,
  mealPlan,
  loading,
  date,
  onChangeDate,
  targetDailyMacros,
  allServingAmounts,
  onEditMealPlanDate,
  onRemoveMealPlanDate,
}: TimelineDateColumnHeaderProps) => {
  const navigate = useNavigate();
  const { age, sex, kcal } = useDriParams();
  const [nutrientModalOpen, setNutrientModalOpen] = useState(false);
  const [mealPlanModalOpen, setMealPlanModalOpen] = useState(false);

  const currentMacros = getServingAmountsMacros(allServingAmounts);
  const mealPlanMacros = mealPlan && getMacroProtocolMacros(mealPlan.macroProtocol, mealPlan.athlete.birthDate);

  const macroProgressViews = useMacroProgressViews();

  const hasSharedMealPlans = athleteMealPlans.some(mealPlan => mealPlan.isShared);

  const editMealPlanTooltipText = hasSharedMealPlans ? "Change Meal Plan" : "Create Meal Plan";

  const editMealPlanOnClick = hasSharedMealPlans
    ? () => setMealPlanModalOpen(true)
    : () => navigate(getNavOrgAthleteMealPlansMealPlan(athleteId, mealPlan?.id || ""));

  const [showMacros, setShowMacros] = useState(true);

  const {
    palette: {
      greyscale,
      accents: { yellow },
    },
  } = useTheme();

  return (
    <Card sx={{ border: `1px solid ${greyscale[100]}` }}>
      <Box
        sx={{
          py: 1.5,
          px: 2,
          backgroundColor: greyscale[100],
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TimelineDateColumnSelect selectedDate={date} onChangeSelectedDate={onChangeDate} />
        <IconButton size="small" onClick={() => onChangeDate(null)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          px: 2,
          pt: 2,
          pb: 1,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "top", justifyContent: "space-between" }}>
          {mealPlan ? (
            <Typography noWrap>{mealPlan.name}</Typography>
          ) : (
            <Typography>
              {loading
                ? null
                : hasSharedMealPlans
                ? "No Meal Plan on Date"
                : "Athlete has no shared Meal Plans. They will not see Macro Progress Rings in the App. Go to Meal Plan tab to add a Meal Plan"}
            </Typography>
          )}

          {loading ? (
            <Loading progressSize="xs" />
          ) : (
            <Tooltip title={editMealPlanTooltipText}>
              <IconButton
                sx={{ width: "20px", height: "20px" }}
                size="small"
                onClick={editMealPlanOnClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {macroProgressViews !== "none" && showMacros && targetDailyMacros && (
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <MacrosProgress
                currentMacros={currentMacros}
                targetMacros={mealPlanMacros || targetDailyMacros}
                variant="md"
                macroProgressViews={macroProgressViews}
              />
              <Tooltip title="View Nutrients">
                <IconButton
                  sx={{ width: "20px", height: "20px", color: yellow[400] }}
                  onClick={() => setNutrientModalOpen(true)}
                  size="large"
                >
                  <BarChartIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        )}
        {macroProgressViews !== "none" && (
          <Button
            variant="text"
            size="small"
            fullWidth
            onClick={() => setShowMacros(!showMacros)}>
            {showMacros ? "Hide Macros" : "Show Macros"}
          </Button>
        )}
      </Box>
      {nutrientModalOpen && (
        <NutrientAmountDialog
          age={age}
          sex={sex}
          kcal={kcal}
          open={nutrientModalOpen}
          onClose={() => setNutrientModalOpen(false)}
          servingAmounts={allServingAmounts}
        />
      )}
      {mealPlanModalOpen && (
        <ChangeMealPlanDialog
          open={mealPlanModalOpen}
          onClose={() => setMealPlanModalOpen(false)}
          athleteBirthdate={athleteBirthdate}
          athleteMealPlans={athleteMealPlans}
          date={date}
          currentMealPlanId={mealPlan && mealPlan.id}
          onEdit={onEditMealPlanDate}
          onRemove={onRemoveMealPlanDate}
        />
      )}
    </Card>
  );
};
