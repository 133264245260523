import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, List, ListItem, ListItemText, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import { sortByKey } from "@notemeal/utils-sort";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indent: {
      marginLeft: theme.spacing(3),
    },
  })
);

interface MissingOrdersListProps {
  team: { name: string };
  athletes: Array<{
    id: string;
    firstName: string;
    lastName: string;
  }>;
}

const MissingOrdersList = ({ team, athletes }: MissingOrdersListProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const sortedAthletes = sortByKey(athletes, "lastName");

  const Row = (props: ListChildComponentProps) => {
    const { index, style } = props;
    return (
      <ListItem style={style} key={index}>
        <ListItemText primary={`${sortedAthletes[index].lastName}, ${sortedAthletes[index].firstName}`} />
      </ListItem>
    );
  };

  const containerHeight = 600;
  const itemSize = 46;
  const itemCount = athletes.length;

  return (
    <List>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemText primary={team.name} secondary={`${athletes.length} athletes without orders `} />
        {athletes.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit>
        <FixedSizeList
          className={classes.indent}
          height={Math.min(containerHeight, itemSize * itemCount)}
          width="100%"
          itemSize={itemSize}
          itemCount={itemCount}
        >
          {Row}
        </FixedSizeList>
      </Collapse>
    </List>
  );
};

export default MissingOrdersList;
