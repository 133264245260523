import { Grid } from "@mui/material";
import { FoodDataSource } from "@notemeal/graphql-types";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { UnitWithConversionsFragment } from "../../../../types";
import { LocaleMultiSelect } from "../../../Locale/LocaleMultiSelect";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import { BaseFoodFormType } from "../../FoodFormSchema/FoodFormSchema";
import { FoodDataSourceSelect } from "./FoodDataSourceSelect";
import { UsdaSearchBar } from "./UsdaSearchBar";

interface FoodDataSourceSectionProps {
  form: UseFormReturn<BaseFoodFormType>;
  setIsLoading: (isLoading: boolean) => void;
  unitsData: readonly UnitWithConversionsFragment[];
  gramUnit: UnitWithConversionsFragment;
  disabled?: boolean;
}

export const FoodDataSourceSection = ({ form, unitsData, gramUnit, setIsLoading, disabled }: FoodDataSourceSectionProps) => {
  const {
    watch,
    control,
    formState: { errors },
  } = form;
  const [watchedId, watchedMode, watchedSource] = watch(["id", "mode", "source"]);
  const { setMessage } = useSnackbar();

  const isOrgMode = watchedMode === "org";
  const isStaffMode = watchedMode === "staff";

  const showUsda = isOrgMode || watchedSource === FoodDataSource.usda;
  const showManual = isStaffMode && watchedSource === FoodDataSource.manual;

  return (
    <>
      {isStaffMode && (
        <Grid
          item
          container
          spacing={2}>
          <Grid
            item
            xs={6}
            sx={{ py: 1.5 }}>
            <Controller
              name={"source"}
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <FoodDataSourceSelect
                  source={field.value as FoodDataSource}
                  isEdit={!!watchedId}
                  error={Boolean(errors.source)}
                  helperText={errors.source?.message}
                  onChange={source => {
                    if (source === FoodDataSource.mccance) {
                      setMessage("error", "Cannot change existing food to McCance");
                      return;
                    }
                    onChange(source);
                  }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            {showManual && (
              <Controller
                name={"locales"}
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <LocaleMultiSelect
                    values={field.value || []}
                    error={Boolean(errors.locales)}
                    helperText={errors.locales?.message}
                    onChange={onChange}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      )}
      {/* TODO add mccance */}

      {showUsda && (
        <Grid
          item
          xs={12}
          mb={1.5}>
          <UsdaSearchBar
            disabled={disabled}
            form={form}
            unitsData={unitsData}
            gramUnit={gramUnit}
            setIsLoading={setIsLoading} />
        </Grid>
      )}
    </>
  );
};
