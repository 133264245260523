import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Button, Chip, Dialog, DialogActions, DialogContent, Tooltip, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import { MealMenuIdentityFragment } from "../../types";

interface MealMenuIdentityChipProps {
  mealMenuIdentity: MealMenuIdentityFragment;
  size?: "small" | "inherit";
  className?: string;
  onOverride?: () => void;
}

const MealMenuIdentityChip = ({ mealMenuIdentity, className, size = "inherit", onOverride }: MealMenuIdentityChipProps) => {
  const icon = mealMenuIdentity.isOverridden ? <LinkOffIcon fontSize={size} /> : <LinkIcon fontSize={size} />;
  const [overrideOpen, setOverrideOpen] = useState(false);

  let tooltipTitle: ReactNode;
  if (mealMenuIdentity.isOverridden) {
    tooltipTitle = "Menu has been edited and will not receive updates on re-import";
  } else {
    tooltipTitle = "Menu will receive updates on re-import";
    if (onOverride) {
      tooltipTitle = (
        <>
          {tooltipTitle}
          <br />
          <br />
          Click to override menu and enable edits
        </>
      );
    }
  }

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <Chip
          className={className}
          avatar={icon}
          label={getLabel(mealMenuIdentity)}
          onClick={!mealMenuIdentity.isOverridden && onOverride ? () => setOverrideOpen(true) : undefined}
        />
      </Tooltip>
      {onOverride && overrideOpen && (
        <Dialog
          open={overrideOpen}
          onClose={() => setOverrideOpen(false)}
          maxWidth="xs"
          fullWidth>
          <DialogContent>
            <Typography variant="h3">You cannot undo this operation!</Typography>
            <Typography color="textSecondary">
              <br />
              Are you sure you'd like to override this imported menu to enable edits and stop receiving updates?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setOverrideOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onOverride();
                setOverrideOpen(false);
              }}
            >
              Override
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default MealMenuIdentityChip;

const getLabel = (identity: MealMenuIdentityFragment) => {
  switch (identity.__typename) {
    case "BonAppetitMenuIdentity":
      return "Bon Appetit";
    case "CbordMenuIdentity":
      return "CBORD";
    case "CompassMenuIdentity":
      return "Compass";
  }
  return null;
};
