import { MenuItem, TextField, Typography } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { MenuBuilderWeek } from "../../../MenuBuilder/Builder/utils";

export interface PlannedMenuWeekMultiSelectProps {
  onChange: (selectedWeeks: MenuBuilderWeek[]) => void;
  allWeeks: MenuBuilderWeek[];
  selectedWeeks: MenuBuilderWeek[];
  onClose: () => void;
  fullWidth?: boolean;
}

const PlannedMenuWeekMultiSelect = ({ selectedWeeks, onChange, onClose, allWeeks, fullWidth }: PlannedMenuWeekMultiSelectProps) => {
  const { formatYearlessDateWithLocale } = useDateFormatting();

  const handleChange = (weekIds: string[]) => {
    onChange(allWeeks.filter(t => weekIds.includes(t.id)));
  };

  return (
    <TextField
      select
      SelectProps={{
        multiple: true,
        renderValue: value => {
          const weekIds = value as string[];
          return <Typography variant="body1">{weekIds.length} weeks selected</Typography>;
        },
        onClose,
      }}
      label={"Weeks"}
      margin="none"
      value={selectedWeeks.map(t => t.id)}
      sx={{
        width: "240px",
      }}
      fullWidth={fullWidth}
      onChange={e => handleChange(e.target.value as unknown as string[])}
    >
      {sortByKey(allWeeks, "weekNumber").map((week, index) => (
        <MenuItem
          key={week.id}
          value={week.id}
          disabled={week.endDate < new Date()}
          sx={selectedWeeks.includes(week) ? { fontWeight: "bold" } : undefined}
        >
          {`Week ${index + 1} (${formatYearlessDateWithLocale(week.startDate)} - ${formatYearlessDateWithLocale(week.endDate)})`}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default PlannedMenuWeekMultiSelect;
