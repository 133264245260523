import { mealPlanCommonColors } from "@notemeal/palette";
import { sortByKey } from "@notemeal/utils-sort";
import React, { createContext, ReactNode, useCallback, useContext } from "react";

type IMealPlanColorsContext = {
  getMealPlanColor: (mealPlanId: string) => string;
  getNewMealPlanColor: () => string;
};

const MealPlanColorsContext = createContext<IMealPlanColorsContext>({
  getMealPlanColor: () => "",
  getNewMealPlanColor: () => "",
});

interface MealPlanColorsContextProviderProps {
  children: ReactNode;
  mealPlans: readonly { id: string; createdAt: string }[];
}

export const MealPlanColorsContextProvider = ({ children, mealPlans }: MealPlanColorsContextProviderProps) => {
  const getNewMealPlanColor = useCallback(() => {
    return mealPlanCommonColors[mealPlans.length % mealPlanCommonColors.length];
  }, [mealPlans]);

  const getMealPlanColor = useCallback(
    (mealPlanId: string) => {
      const mealPlanIndex = sortByKey(mealPlans, "createdAt").findIndex(mp => mp.id === mealPlanId);
      if (mealPlanIndex === -1) {
        // Shouldn't get here (probably a cache bug), but fallback to new meal plan color
        return getNewMealPlanColor();
      }
      return mealPlanCommonColors[mealPlanIndex % mealPlanCommonColors.length];
    },
    [mealPlans, getNewMealPlanColor]
  );

  return (
    <MealPlanColorsContext.Provider
      value={{
        getMealPlanColor,
        getNewMealPlanColor,
      }}
    >
      {children}
    </MealPlanColorsContext.Provider>
  );
};

export const useMealPlanColorsContext = () => useContext(MealPlanColorsContext);
