import { inputToNumber } from "@notemeal/shared-ui";
import { AnthropometryAction, AnthropometryFormFieldsState } from "./anthropometryReducer";
import { CM_PER_INCH, KG_PER_LB, measurementConversionToMetric } from "@notemeal/shared-utils-macro-protocol";

// TODO: fix this for metric vs not metric
export const getDefaultAnthropometryFormFields = (isMetric: boolean): AnthropometryFormFieldsState => ({
  __typename: "FormFields",
  formFields: ["weight"],
  sampleWeight: isMetric ? 100 : 200 * KG_PER_LB,
  sampleWeightInput: isMetric ? "100" : "200",
  samplePercentBodyFat: 15,
  samplePercentBodyFatInput: "15",
  sampleHeight: isMetric ? 180 : 70 * CM_PER_INCH,
  sampleHeightInput: isMetric ? "180" : "70",
  sampleAge: 20,
  sampleAgeInput: "20",
  sampleSex: "male",
});

export const anthropometryFormFieldsReducer = (
  state: AnthropometryFormFieldsState,
  action: AnthropometryAction
): AnthropometryFormFieldsState => {
  switch (action.type) {
    case "EDIT_SAMPLE_WEIGHT":
      let thisSampleWeight = inputToNumber(action.payload);

      if (action.isMetricLocale !== undefined && action.isMetricLocale === false) {
        thisSampleWeight = measurementConversionToMetric(false, thisSampleWeight, "weight");
      }

      return {
        ...state,
        sampleWeight: thisSampleWeight ?? state.sampleWeight,
        sampleWeightInput: action.payload,
      };
    case "EDIT_FORM_FIELDS":
      return {
        ...state,
        formFields: action.payload,
      };
    case "EDIT_SAMPLE_HEIGHT":
      let thisSampleHeight = inputToNumber(action.payload);

      if (action.isMetricLocale !== undefined && action.isMetricLocale === false) {
        thisSampleHeight = measurementConversionToMetric(false, thisSampleHeight, "length");
      }
      return {
        ...state,
        sampleHeight: thisSampleHeight,
        sampleHeightInput: action.payload,
      };
    case "EDIT_SAMPLE_PERCENT_BODY_FAT":
      return {
        ...state,
        samplePercentBodyFat: inputToNumber(action.payload),
        samplePercentBodyFatInput: action.payload,
      };
    case "EDIT_SAMPLE_SEX":
      return {
        ...state,
        sampleSex: action.payload,
      };
    case "EDIT_SAMPLE_AGE":
      return {
        ...state,
        sampleAge: inputToNumber(action.payload),
        sampleAgeInput: action.payload,
      };
    default:
      return state;
  }
};
