import { invalidAge } from "@notemeal/shared-ui";
import ActionDialog from "apps/web/src/componentLibrary/ActionDialog/Dialog";
import MacroProtocolMissingDataDialog from "apps/web/src/components/MacroProtocol/MissingDataDialog";
import LoadingBackdrop from "apps/web/src/components/universal/LoadingBackdrop";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { useEffect, useState } from "react";
import { useAthleteMealPlansQuery, useExchangeSetsQuery, useGoalTypesQuery, useSchedulesDefaultAndTeamQuery } from "../../../../types";
import Content from "./Content";
import { activeModal } from "./utils";

interface CreateMealPlanDialogLoaderProps {
  athleteId: string;
  teamId: string;
  open: boolean;
  onClose: () => void;
}

const CreateMealPlanDialogLoader = ({ athleteId, teamId, onClose, open }: CreateMealPlanDialogLoaderProps) => {
  const [missingDataDialogOpen, setMissingDataDialogOpen] = useState(false);
  const ampResult = useAthleteMealPlansQuery({
    variables: { athleteId },
  });
  const schedulesResult = useSchedulesDefaultAndTeamQuery({
    variables: { teamId },
  });
  const goalTypesResult = useGoalTypesQuery();
  const exchangeSetsResult = useExchangeSetsQuery();

  const onDialogClose = (step?: number) => {
    step && trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Cancel", { modalStep: activeModal[step], athleteId, teamId });

    const confirmed = window.confirm("Discard Unsaved Changes? All unsaved changes will be lost.");

    if (confirmed) {
      onClose();
    }
  };

  useEffect(() => {
    if (!ampResult.loading && ampResult.data) {
      const { mostRecentAnthropometryEntry, birthDate } = ampResult.data.athlete;
      if (
        !mostRecentAnthropometryEntry ||
        !(mostRecentAnthropometryEntry.leanBodyMass || mostRecentAnthropometryEntry.percentBodyFat) ||
        !mostRecentAnthropometryEntry.height ||
        !birthDate
      ) {
        setMissingDataDialogOpen(true);
      }
    }
  }, [ampResult.loading, ampResult.data]);

  if (
    ampResult.loading ||
    !ampResult.data ||
    schedulesResult.loading ||
    !schedulesResult.data ||
    goalTypesResult.loading ||
    !goalTypesResult.data ||
    exchangeSetsResult.loading ||
    !exchangeSetsResult.data
  ) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  const schedules = schedulesResult.data.schedules;
  const teamSchedules = schedulesResult.data.schedulesForTeam;
  const goalTypes = goalTypesResult.data.goalTypes;
  const sportName = ampResult.data.athlete.team.sport?.name ?? "";

  const { mostRecentAnthropometryEntry, currentGoal, birthDate } = ampResult.data.athlete;

  const missingDataDialog = (
    <MacroProtocolMissingDataDialog
      athleteId={athleteId}
      open={missingDataDialogOpen}
      onContinue={() => setMissingDataDialogOpen(false)}
      onClose={onDialogClose}
      noAnthropometry={mostRecentAnthropometryEntry === null}
      noBirthdate={birthDate === null}
      invalidAge={invalidAge(birthDate)}
      incompleteAnthropometry={Boolean(
        mostRecentAnthropometryEntry && (mostRecentAnthropometryEntry.leanBodyMass === null || mostRecentAnthropometryEntry.height === null)
      )}
    />
  );

  // TODO: Do correct buttons show up in this case?
  if (!mostRecentAnthropometryEntry) {
    return missingDataDialog;
  }

  return (
    <ActionDialog open={open}>
      <Content
        athleteId={athleteId}
        schedules={schedules}
        teamSchedules={teamSchedules}
        currentGoal={currentGoal}
        mostRecentAnthropometryEntry={mostRecentAnthropometryEntry}
        athleteBirthdate={birthDate}
        goalTypes={goalTypes}
        sportName={sportName}
        onClose={onDialogClose}
        onCompleted={onClose}
        teamId={teamId}
        exchangeSets={exchangeSetsResult.data.exchangeSets}
      />
      {missingDataDialog}
    </ActionDialog>
  );
};

export default CreateMealPlanDialogLoader;
