import { Box, Divider, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import { useStreamChat } from "apps/web/src/contexts/StreamChatContext";
import { useState } from "react";
import { Channel, ChannelList, Chat, MessageInput, MessageList, Thread, Window } from "stream-chat-react";
import { ChannelHeader } from "./ChannelHeader";
import { ChannelListContainer } from "./ChannelListContainer";
import { ChannelPreview } from "./ChannelPreview";
import { CreateChannelModal } from "./CreateChannelModal";
import { EmptyChannelList } from "./EmptyChannelList";
import { MessageDateSeparator } from "./MessageDateSeparator";
import { MessageTimestamp } from "./MessageTimestamp";

const useStyles = makeStyles(({ palette: { greyscaleLight, greyscale, primary, common, divider, info }, shape, spacing }: Theme) =>
  createStyles({
    chat: {
      height: "calc(100% - 65px)",
      "--primary-color": greyscale[300],
      "--avatar-background-color": greyscaleLight[900],
      "--active-primary-color": primary.main,
      "--surface-color": primary.main,
      "--secondary-surface-color": primary.main,
      "--primary-surface-color": primary.main,
      "--primary-surface-color-low-emphasis": primary.main,
      "--border-radius-circle": shape.borderRadius,
      "--xl-p": spacing(1),
      background: greyscale[50],
    },
    "@global .str-chat__main-panel": {
      padding: 0 + " !important",
    },

    "@global .str-chat__channel-list": {
      boxShadow: "none",
    },
    "@global .str-chat__ul": {
      padding: spacing(0, 1),
    },
    "@global .str-chat__avatar-fallback": {
      fontWeight: 600,
      backgroundColor: greyscaleLight[400],
    },
    "@global .str-chat__list": {
      padding: spacing(0, 3, 0, 1) + " !important",
    },
    "@global .str-chat__message-simple-name": {
      display: "none !important",
    },
    "@global .str-chat__message-simple-status>.str-chat__avatar": {
      display: "none !important",
    },
    "@global .str-chat__input-flat": {
      background: common.white,
      borderTop: `1px solid ${divider}`,
    },
    "@global .str-chat__input-flat .str-chat__textarea>textarea": {
      padding: "20px 50px 20px 20px !important",
      background: common.white,
      border: `1.25px solid ${greyscaleLight[300]} !important`,
      fontFamily: `"InterVariable", "sans-serif"`,
    },
    "@global .str-chat__input-flat .str-chat__textarea>textarea:focus": {
      outline: "none",
      boxShadow: `none`,
      borderColor: info.main + " !important",
    },
    "@global .str-chat__input-flat .rfu-file-upload-button": {
      top: "calc(100% - 46px) !important",
    },
  })
);

export const MessagePage = () => {
  const {
    palette: { text: textColors },
  } = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const { isInitialized, client, channelFilters, channel } = useStreamChat();
  const styles = useStyles();

  if (!isInitialized || !client) {
    return <Loading />;
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", pb: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Typography variant={"h1"}>Messaging</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 0.5 }}>
            <Typography variant={"body2"} sx={{ color: textColors.secondary }}>
              Powered by
            </Typography>
            <img src="/img/hub.svg" alt="Teamworks Hub" />
          </Box>
        </Box>
      </Box>
      <Divider />
      {!channel && (
        <Box sx={{ display: "flex", marginTop: -10, justifyContent: "center", height: "100%" }}>
          <Box sx={{ maxWidth: "380px" }}>
            <EmptyChannelList onCreateMessage={() => setModalOpen(true)} />;
          </Box>
        </Box>
      )}
      {channel && (
        <Chat client={client} customClasses={{ chat: styles.chat }}>
          <ChannelList
            EmptyStateIndicator={() => <EmptyChannelList onCreateMessage={() => setModalOpen(true)} />}
            Preview={ChannelPreview}
            List={props => <ChannelListContainer {...props} onCreateChannel={() => setModalOpen(true)} />}
            filters={channelFilters}
            sort={{ last_message_at: -1 }}
          />
          <Channel
            channel={channel}
            MessageTimestamp={MessageTimestamp}
            DateSeparator={MessageDateSeparator}
            UnreadMessagesSeparator={() => null}
          >
            <Window>
              <ChannelHeader />
              <MessageList messageActions={["react"]} />
              <MessageInput />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      )}
      {modalOpen && <CreateChannelModal open={modalOpen} onClose={() => setModalOpen(false)} />}
    </>
  );
};
