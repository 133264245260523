import BackIcon from "@mui/icons-material/ArrowBack";
import { Dialog, DialogContent, DialogTitle, Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { reduceStatuses } from "@notemeal/shared-ui";
import { OrderStatusIcon } from "apps/web/src/components/MenuOrder/OrderStatusIcon";
import { KdsTicketMealMenuPlateOrderFragment, KdsTicketMenuOrderFragment, MenuOrderItemStatus } from "../../../types";
import { MakeHandleAirPrintFn, PrintMethod, WebPrinterTicketOrder } from "../types";
import {
    MarkCancelButton,
    MarkCookButton,
    MarkDoneButton,
    MarkNewButton,
    PrintAndCookButton,
    PrintAndDoneButton,
    PrintButton,
} from "./ActionButtons";
import Button from "./Button";

interface ChangeStatusDialogProps {
  order: WebPrinterTicketOrder;
  onClose: () => void;
  makeHandleAirPrintFn: MakeHandleAirPrintFn;
  printMethod: PrintMethod;
  handleCancelOrder: (order: KdsTicketMenuOrderFragment) => void;
  handleCookOrder: (order: KdsTicketMenuOrderFragment) => void;
  handleCompleteOrder: (order: KdsTicketMenuOrderFragment) => void;
  handleMarkOrderNew: (order: KdsTicketMenuOrderFragment) => void;
  handleCancelPlateOrder: (order: KdsTicketMealMenuPlateOrderFragment) => void;
  handleCookPlateOrder: (order: KdsTicketMealMenuPlateOrderFragment) => void;
  handleCompletePlateOrder: (order: KdsTicketMealMenuPlateOrderFragment) => void;
  handleMarkPlateOrderNew: (order: KdsTicketMealMenuPlateOrderFragment) => void;
  handleWebPrintOrder?: (webPrinterOrder: WebPrinterTicketOrder) => Promise<void>;
}

const useStyles = makeStyles(({ palette: { grey } }: Theme) =>
  createStyles({
    // Needed to print labels!
    noprint: {
      "@media print": {
        display: "none !important",
      },
      "@media screen": {
        display: "block",
      },
    },
    content: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    icon: {
      fontSize: 96,
    },
    grey: { color: grey[500] },

    square: {
      height: "40vh",
      display: "flex",
    },
    flexCenterColumn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    dialogTitleRoot: {
      paddingBottom: 0,
      "@media print": {
        display: "none !important",
      },
      "@media screen": {
        display: "block",
      },
    },
  })
);

const ChangeStatusDialog = ({ ...props }: ChangeStatusDialogProps) => {
  const {
    onClose,
    order,
    makeHandleAirPrintFn,
    handleWebPrintOrder,
    printMethod,
    handleCompleteOrder,
    handleCookOrder,
    handleMarkOrderNew,
    handleCancelOrder,
    handleCancelPlateOrder,
    handleCookPlateOrder,
    handleCompletePlateOrder,
    handleMarkPlateOrderNew,
  } = props;
  const classes = useStyles();
  const conditionalPrint = async () => {
    if (printMethod === "WebPrint" && handleWebPrintOrder) {
      await handleWebPrintOrder(order);
    } else {
      makeHandleAirPrintFn(() => `printable-order-${order.id}`)();
    }
  };
  const printAndComplete = async () => {
    order.__typename === "MenuOrder" ? handleCompleteOrder(order) : handleCompletePlateOrder(order);
    await conditionalPrint();
  };
  const printAndCook = async () => {
    order.__typename === "MenuOrder" ? handleCookOrder(order) : handleCookPlateOrder(order);
    await conditionalPrint();
  };
  const status = reduceStatuses(order.itemsByDiningStation.map(i => i.status));

  const Print = <PrintButton
    key="print-button"
    onClose={onClose}
    action={async () => await conditionalPrint()} />;

  const PrintAndDone = <PrintAndDoneButton
    key="print-done-button"
    onClose={onClose}
    action={() => printAndComplete()} />;

  const PrintAndCook = <PrintAndCookButton
    key="print-cook-button"
    onClose={onClose}
    action={() => printAndCook()} />;

  const MarkDone = (
    <MarkDoneButton
      key="mark-done-button"
      onClose={onClose}
      action={() => (order.__typename === "MenuOrder" ? handleCompleteOrder(order) : handleCompletePlateOrder(order))}
    />
  );

  const MarkCook = (
    <MarkCookButton
      key="mark-cook-button"
      onClose={onClose}
      action={() => (order.__typename === "MenuOrder" ? handleCookOrder(order) : handleCookPlateOrder(order))}
    />
  );

  const MarkNew = (
    <MarkNewButton
      key="mark-new-button"
      onClose={onClose}
      action={() => (order.__typename === "MenuOrder" ? handleMarkOrderNew(order) : handleMarkPlateOrderNew(order))}
    />
  );

  const MarkCancel = (
    <MarkCancelButton
      key="mark-cancel-button"
      onClose={onClose}
      action={() => (order.__typename === "MenuOrder" ? handleCancelOrder(order) : handleCancelPlateOrder(order))}
    />
  );

  const Blank = <></>;

  const renderButtonsFromStatus = (currentStatus: MenuOrderItemStatus) => {
    switch (currentStatus) {
      case "new":
        return [PrintAndDone, Print, MarkCancel, PrintAndCook, MarkCook];
      case "cooking":
        return [PrintAndDone, Print, MarkCancel, MarkNew, MarkDone];
      case "done":
        return [Print, MarkCook];
      case "cancelled":
        return [Print, MarkNew];
      default:
        return Blank;
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen
      classes={{ root: classes.noprint }}>
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <div className={classes.flexCenter}>
          <OrderStatusIcon fontSize="large" orderStatus={status} />
          <Typography variant="h4">
            Order #{order.code} is {status}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Button
            onClose={onClose}
            onClick={() => onClose()}
            label="Back">
            <BackIcon className={classes.grey} classes={{ root: classes.icon }} />
          </Button>
          {renderButtonsFromStatus(status)}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeStatusDialog;
