import { getPreviewImage } from "@notemeal/shared-ui";
import { FoodCategoryType, MenuItemFormFragment, MenuItemSuggestionCategory, ScoringSystemFragment } from "../../types";
import { FullServingAmountFragment, MenuItemChoiceFormFragment } from "../../types";

export interface MenuItemState {
  menuItem: MenuItemFormFragment;
  availableForOrder: boolean;
  allowSpecialRequests: boolean;
  maxAmount: number | null;
  edited: boolean;
}

export type MenuItemAction =
  | MenuItemChangeNameAction
  | MenuItemChangeServingNameAction
  | MenuItemChangeDescriptionAction
  | MenuItemChangeServingAmountsAction
  | MenuItemChangeChoicesAction
  | MenuItemChangeImageAction
  | MenuItemChangeSuggestionCategoryAction
  | MenuItemChangeMaxAmountAction
  | MenuItemChangeAvailableForOrderAction
  | MenuItemChangeAllowSpecialRequestsAction
  | MenuItemChangeScoreAction
  | MenuItemChangeFoodCategoryAction;

interface MenuItemChangeNameAction {
  type: "MenuItemChangeNameAction";
  payload: {
    name: string;
  };
}

interface MenuItemChangeServingNameAction {
  type: "MenuItemChangeServingNameAction";
  payload: {
    servingName: string;
  };
}

interface MenuItemChangeDescriptionAction {
  type: "MenuItemChangeDescriptionAction";
  payload: {
    description: string | null;
  };
}

export interface MenuItemChangeImageAction {
  type: "MenuItemChangeImageAction";
  payload: {
    imageUrl: string | null;
  };
}

interface MenuItemChangeSuggestionCategoryAction {
  type: "MenuItemChangeSuggestionCategoryAction";
  payload: {
    suggestionCategory: MenuItemSuggestionCategory | null;
  };
}

export interface MenuItemChangeServingAmountsAction {
  type: "MenuItemChangeServingAmountsAction";
  payload: {
    servingAmounts: readonly FullServingAmountFragment[];
  };
}

export interface MenuItemChangeChoicesAction {
  type: "MenuItemChangeChoicesAction";
  payload: {
    choices: readonly MenuItemChoiceFormFragment[];
  };
}

interface MenuItemChangeMaxAmountAction {
  type: "MenuItemChangeMaxAmountAction";
  payload: {
    maxAmount: number | null;
  };
}
interface MenuItemChangeAvailableForOrderAction {
  type: "MenuItemChangeAvailableForOrderAction";
  payload: {
    availableForOrder: boolean;
  };
}

interface MenuItemChangeAllowSpecialRequestsAction {
  type: "MenuItemChangeAllowSpecialRequestsAction";
  payload: {
    allowSpecialRequests: boolean;
  };
}

export interface MenuItemChangeScoreAction {
  type: "MenuItemChangeScoreAction";
  payload: {
    scoreValue: number | null;
    scoringSystem: ScoringSystemFragment | null;
  };
}

export interface MenuItemChangeFoodCategoryAction {
  type: "MenuItemChangeFoodCategoryAction";
  payload: {
    foodCategory: FoodCategoryType | null;
  };
}

export const menuItemReducer = (state: MenuItemState, action: MenuItemAction): MenuItemState => {
  switch (action.type) {
    case "MenuItemChangeNameAction":
      return {
        ...state,
        edited: true,
        menuItem: {
          ...state.menuItem,
          name: action.payload.name,
        },
      };
    case "MenuItemChangeServingNameAction":
      return {
        ...state,
        edited: true,
        menuItem: {
          ...state.menuItem,
          servingName: action.payload.servingName,
        },
      };
    case "MenuItemChangeDescriptionAction":
      return {
        ...state,
        edited: true,
        menuItem: {
          ...state.menuItem,
          description: action.payload.description,
        },
      };
    case "MenuItemChangeImageAction":
      return {
        ...state,
        edited: true,
        menuItem: {
          ...state.menuItem,
          imageUrl: action.payload.imageUrl,
        },
      };
    case "MenuItemChangeSuggestionCategoryAction":
      return {
        ...state,
        edited: true,
        menuItem: {
          ...state.menuItem,
          suggestionCategory: action.payload.suggestionCategory,
        },
      };
    case "MenuItemChangeServingAmountsAction":
      const noneToOneServingAmounts = state.menuItem.servingAmounts.length === 0 && action.payload.servingAmounts.length === 1;
      if (noneToOneServingAmounts && action.payload.servingAmounts[0].serving.foodOrRecipe.__typename === "Recipe") {
        const servingName = action.payload.servingAmounts[0].serving.units;
        const name = action.payload.servingAmounts[0].serving.foodOrRecipe.name;
        const imageUrl = getPreviewImage(action.payload.servingAmounts[0].serving.foodOrRecipe.images);
        return {
          ...state,
          edited: true,
          menuItem: {
            ...state.menuItem,
            servingAmounts: action.payload.servingAmounts,
            name: state.menuItem.name === "" ? name : state.menuItem.name,
            servingName: state.menuItem.servingName === "" ? servingName : state.menuItem.servingName,
            imageUrl: state.menuItem.imageUrl ? state.menuItem.imageUrl : imageUrl?.url || null,
          },
        };
      } else {
        return {
          ...state,
          edited: true,
          menuItem: {
            ...state.menuItem,
            servingAmounts: action.payload.servingAmounts,
          },
        };
      }
    case "MenuItemChangeChoicesAction":
      return {
        ...state,
        edited: true,
        menuItem: {
          ...state.menuItem,
          choices: action.payload.choices,
        },
      };
    case "MenuItemChangeAvailableForOrderAction":
      return {
        ...state,
        availableForOrder: action.payload.availableForOrder,
      };
    case "MenuItemChangeAllowSpecialRequestsAction":
      return {
        ...state,
        allowSpecialRequests: action.payload.allowSpecialRequests,
      };
    case "MenuItemChangeMaxAmountAction":
      return {
        ...state,
        maxAmount: action.payload.maxAmount,
      };
    case "MenuItemChangeScoreAction":
      return {
        ...state,
        menuItem: {
          ...state.menuItem,
          score:
            action.payload.scoreValue !== null && action.payload.scoringSystem !== null
              ? { value: action.payload.scoreValue, scoringSystem: action.payload.scoringSystem }
              : null,
        },
      };
    case "MenuItemChangeFoodCategoryAction":
      return {
        ...state,
        menuItem: {
          ...state.menuItem,
          foodCategory: action.payload.foodCategory !== null ? { category: action.payload.foodCategory } : null,
        },
      };
  }
};
