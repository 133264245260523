import { QueryHookOptions } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  AutocompleteFreeSoloValueMapping,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  AutocompleteValue,
  CircularProgress,
  InputAdornment,
  AutocompleteProps as MUIAutocompleteProps,
  TextField,
  TextFieldProps,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading, useDebounce } from "@notemeal/shared-ui";
import React, { useCallback, useEffect, useState } from "react";
import { OmittedAutocompleteProps } from "../BaseAsyncAutocomplete";
import IntersectRow from "./IntersectRow";
import useInfiniteCursorConnectionScroll, {
  CursorConnectionQueryResults,
  CursorPaginationQueryVariables,
  GetQueryVariablesFromPaginationFnArgs,
  UseInfiniteCursorConnectionScrollArgs,
} from "./useInfiniteCursorConnectionScroll";

const useStyles = (disableRootPadding: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: disableRootPadding ? undefined : theme.spacing(1, 0),
        width: "100%",
        borderRadius: "4px",
      },
      adornment: {},
      paper: {
        boxShadow: `0px 0px 5px 3px ${theme.palette.grey[500]}`,
      },
      loadingMore: {
        height: "unset",
        marginTop: theme.spacing(1),
      },
    })
  );

export interface NonGenericAutoCompleteProps {
  inputPlaceholder?: string;
  autoFocus?: boolean;
  inputRef?: React.Ref<any>;
  inputVariant?: TextFieldProps["variant"];
  useExample?: boolean;
  ariaLabel?: string;
  showStartAdornment?: boolean;
  disableRootPadding?: boolean;
  textInputLabel?: string;
  InputProps?: TextFieldProps["InputProps"];
}

type BaseInfiniteScrollAutoCompleteProps<
  OptionsType,
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = NonGenericAutoCompleteProps &
  Omit<OmittedAutocompleteProps<OptionsType, Multiple, FreeSolo>, "getOptionLabel"> & {
    size?: MUIAutocompleteProps<OptionsType, Multiple, false, FreeSolo>["size"];
    blurOnSelect?: MUIAutocompleteProps<OptionsType, Multiple, false, FreeSolo>["blurOnSelect"];
    disableCloseOnSelect?: MUIAutocompleteProps<OptionsType, Multiple, false, FreeSolo>["disableCloseOnSelect"];
  };

interface AutoCompleteProps<EdgesType, OptionsType, Multiple extends boolean | undefined, FreeSolo extends boolean | undefined>
  extends BaseInfiniteScrollAutoCompleteProps<OptionsType, Multiple, FreeSolo> {
  multiple?: Multiple;
  value: AutocompleteValue<OptionsType, Multiple, false, FreeSolo>;
  handleChange: (inputValue: string, options: AutocompleteValue<OptionsType, Multiple, false, FreeSolo>) => void;
  transformAndFilterOptions: (options: EdgesType[] | undefined) => OptionsType[] | undefined;
  renderOption: (option: OptionsType, state: AutocompleteRenderOptionState) => React.ReactNode;
  getOptionSelected?: (option: OptionsType, value: OptionsType) => boolean;
  getOptionLabel?: (option: OptionsType | AutocompleteFreeSoloValueMapping<FreeSolo>, inputValue: string) => string;
  getCustomOptionKey: (option: OptionsType | AutocompleteFreeSoloValueMapping<FreeSolo>, state: AutocompleteRenderOptionState) => string;
  renderTags?: (option: OptionsType[], getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode;
  groupBy?: (option: OptionsType) => string;
  freeSolo?: FreeSolo;
  disableClearable?: boolean;
  handleInputChange?: (value: string) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  renderInput?: (params: AutocompleteRenderInputParams) => JSX.Element;
  clearOnClose?: boolean;
}

type InfiniteScrollProps<K extends string, EdgesType, QueryVariables extends CursorPaginationQueryVariables> = Omit<
  UseInfiniteCursorConnectionScrollArgs<K, EdgesType, QueryVariables>,
  "getQueryVariablesFromPagination"
>;

export type GetQueryVariablesFromPaginationAndInputArgs = GetQueryVariablesFromPaginationFnArgs & {
  input: string;
};

type GetQueryVariablesFromPaginationAndInputFn<K extends string, EdgesType, QueryVariables extends CursorPaginationQueryVariables> = ({
  cursor,
  limit,
  input,
}: GetQueryVariablesFromPaginationAndInputArgs) => QueryHookOptions<CursorConnectionQueryResults<K, EdgesType>, QueryVariables>;

export type InfiniteScrollAutocompleteProps<
  K extends string,
  EdgesType,
  QueryVariables extends CursorPaginationQueryVariables,
  OptionsType,
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = {
  getQueryVariablesFromPaginationAndInput: GetQueryVariablesFromPaginationAndInputFn<K, EdgesType, QueryVariables>;
} & AutoCompleteProps<EdgesType, OptionsType, Multiple, FreeSolo> &
  InfiniteScrollProps<K, EdgesType, QueryVariables>;

const InfiniteScrollAutocomplete = <
  K extends string,
  EdgesType,
  QueryVariables extends CursorPaginationQueryVariables,
  OptionsType,
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  sx,
  inputPlaceholder: _inputPlaceholder,
  handleChange,
  transformAndFilterOptions,
  autoFocus,
  inputRef,
  inputVariant = "outlined",
  limit: _limit,
  startCursor = null,
  useCursorConnectionQuery,
  getQueryVariablesFromPaginationAndInput, // To optimize please use useCallback
  queryKey,
  renderOption: _renderOption,
  getOptionLabel,
  getCustomOptionKey: getOptionKey,
  renderTags,
  edgesAreEqual,
  groupBy,
  useExample = false,
  ariaLabel = "",
  blurOnSelect = true,
  disableCloseOnSelect,
  showStartAdornment = true,
  disableRootPadding = false,
  handleInputChange = () => {},
  getOptionSelected,
  onClose,
  textInputLabel,
  disableClearable,
  ListboxProps,
  onKeyUp,
  renderInput,
  clearOnClose = true,
  ...restProps
}: InfiniteScrollAutocompleteProps<K, EdgesType, QueryVariables, OptionsType, Multiple, FreeSolo>) => {
  const baseClasses = useStyles(disableRootPadding)();
  const [inputValue, setInputValue] = useState("");
  const debouncedValue = useDebounce(inputValue, 200);

  const [randLabel, setRandomLabel] = useState<string | null>(null);

  const rawInfiniteScrollResults = useInfiniteCursorConnectionScroll({
    limit: _limit,
    useCursorConnectionQuery,
    startCursor,
    queryKey,
    getQueryVariablesFromPagination: useCallback(
      ({ cursor, limit }) =>
        getQueryVariablesFromPaginationAndInput({
          cursor,
          limit,
          input: debouncedValue,
        }),
      [getQueryVariablesFromPaginationAndInput, debouncedValue]
    ),
    edgesAreEqual,
  });

  const { edges, loading, next } = rawInfiniteScrollResults;
  const isLoading = loading;
  const baseOptions = transformAndFilterOptions(edges ? [...edges] : undefined);

  useEffect(() => {
    if (useExample && !randLabel && baseOptions && baseOptions.length) {
      const randomIndex = Math.floor(Math.random() * baseOptions.length);
      const randItem = baseOptions[randomIndex];
      getOptionLabel && setRandomLabel(`e.g. ${getOptionLabel(randItem, "")}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseOptions && baseOptions.length]);

  const options = baseOptions ?? [];

  const inputPlaceholder = useExample && randLabel ? randLabel : _inputPlaceholder;

  const { InputProps, ...otherProps } = restProps;

  const defaultRenderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        sx={{
          borderBottom: "none",
          "&:hover": {
            borderBottom: 0,
          },
          decoration: { border: { borderSide: "none", borderRadius: 0 } },
          ...sx,
        }}
        autoFocus={autoFocus}
        inputRef={inputRef}
        {...params}
        InputProps={{
          ...params.InputProps,
          ...restProps.InputProps,
          startAdornment: (
            <>
              {showStartAdornment && (
                <InputAdornment position="start">{isLoading ? <CircularProgress size={24} /> : <SearchIcon />}</InputAdornment>
              )}
              {params.InputProps.startAdornment}
            </>
          ),
          endAdornment: params.InputProps.endAdornment,
          disableUnderline: true,
        }}
        inputProps={{
          ...params.inputProps,
          "aria-label": ariaLabel,
        }}
        placeholder={inputPlaceholder}
        fullWidth
        variant={inputVariant}
        label={textInputLabel}
        onKeyUp={onKeyUp}
      />
    );
  };

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: OptionsType, state: AutocompleteRenderOptionState) => {
    const index = options?.indexOf(option);
    if (edges && options && index && index === options.length - 1) {
      return (
        <li {...props} key={getOptionKey(option, state)}>
          <IntersectRow
            key={`intersect-row-for-index-${index}`}
            next={next}
            renderChild={childRef => (
              <div ref={childRef}>
                {_renderOption(option, state)}
                {isLoading && <Loading progressSize="xs" classes={{ root: baseClasses.loadingMore }} />}
              </div>
            )}
          />
        </li>
      );
    }
    return (
      <li {...props} key={getOptionKey(option, state)}>
        {_renderOption(option, state)}
      </li>
    );
  };

  return (
    <Autocomplete
      sx={sx}
      inputValue={inputValue}
      onInputChange={(_, value, reason) => {
        if (disableCloseOnSelect && blurOnSelect === false) {
          if (reason !== "reset") {
            setInputValue(value);
            handleInputChange(value);
          }
        } else {
          setInputValue(value);
          handleInputChange(value);
        }
      }}
      options={options ?? []}
      renderInput={renderInput ?? defaultRenderInput}
      blurOnSelect={blurOnSelect}
      onChange={(e, newOption) => {
        handleChange(inputValue, newOption);
      }}
      onClose={(e, reason) => {
        if (onClose) {
          onClose(e, reason);
        }
        if (clearOnClose) {
          setInputValue("");
          handleInputChange("");
        }
      }}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel ? option => getOptionLabel(option, inputValue) : undefined}
      renderTags={renderTags}
      disableCloseOnSelect={disableCloseOnSelect}
      groupBy={groupBy}
      isOptionEqualToValue={getOptionSelected}
      disableClearable={disableClearable}
      ListboxProps={ListboxProps}
      {...otherProps}
    />
  );
};

export default InfiniteScrollAutocomplete;
