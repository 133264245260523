import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, Typography } from "@mui/material";
import { SelfServiceResponse } from "@notemeal/shared-ui";
type ResultProps = {
  data: SelfServiceResponse;
};
enum ErrorCodes {
  "AthleteNotFound",
  "AthleteAlreadyActivated",
  "UnableToSendActivationLink",
  "MoreThanOneAthleteFound",
}
type ErrorText = {
  code: string;
  title: string;
  subtext: string;
};
export const SelfServiceAthletesResult = ({ data: { success, error } }: ResultProps) => {
  const errors: ErrorText[] = [];
  for (const k in ErrorCodes) {
    switch (ErrorCodes[k]) {
      case "AthleteNotFound":
        errors.push({
          code: ErrorCodes[k],
          title: "No Matching Account",
          subtext: "We could not match your account. If this is incorrect, please contact support@teamworks.com.",
        });
        break;
      case "AthleteAlreadyActivated":
        errors.push({
          code: ErrorCodes[k],
          title: "Existing Account",
          subtext: "Your account has already been activated. If this is incorrect, please contact support@teamworks.com.",
        });
        break;
      case "UnableToSendActivationLink":
        errors.push({
          code: ErrorCodes[k],
          title: "Unable to Send Activation Link",
          subtext: "We were unable to send the activation link. Please contact support@teamworks.com.",
        });
        break;
      case "MoreThanOneAthleteFound":
        errors.push({
          code: ErrorCodes[k],
          title: "More Than One Athlete Found",
          subtext: "We were unable to send the activation link. Please contact support@teamworks.com.",
        });
        break;
    }
  }
  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Box
        sx={({ spacing }) => ({
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: 414,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          gap: spacing(2),
          padding: spacing(2),
        })}
      >
        {success ? (
          <>
            <DoneRoundedIcon sx={{ color: "#0A893C", width: 40, height: 40 }} />
            <Typography component="span" variant="h3">
              Activation Link Sent
            </Typography>
            <Typography variant="body2" color="mediumEmphasisText">
              Please check your email for the activation link.
            </Typography>
          </>
        ) : (
          <>
            <WarningRoundedIcon sx={{ color: "#EF6820", width: 40, height: 40 }} />
            <Typography component="span" variant="h3">
              {errors.filter(({ code }) => code === error).pop()?.title}
            </Typography>
            <Typography variant="body2" color="mediumEmphasisText">
              {errors.filter(({ code }) => code === error).pop()?.subtext}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
