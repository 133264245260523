import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Card, CardActionArea, CardContent, IconButton, Tooltip, Typography } from "@mui/material";
import { sortByKey } from "@notemeal/utils-sort";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import React, { memo, useState } from "react";
import MenuItemAppearanceGroupControlLabel from "../../../components/MenuItemAppearance/GroupControlLabel";
import { MenuDialogAction } from "../reducer";
import RestaurantMenuLinkLocationDetailsDialog from "./LocationInfo";
import AddPlateDialog from "./Plates/Dialog/Add";
import PlatesForm from "./Plates/Form";
import SectionForm from "./SectionForm";
import { RestaurantMenuLinkState } from "./types";

interface RestauranMenuLinkFormProps {
  restaurantMenuLink: RestaurantMenuLinkState;
  dispatch: (action: MenuDialogAction) => void;
}

const RestaurantMenuLinkForm = ({ restaurantMenuLink, dispatch }: RestauranMenuLinkFormProps) => {
  const { allowCustomOrders, plates, deliveryLocation } = restaurantMenuLink;
  const hasPlates = plates.length > 0;
  const PLATES = "Plates";
  const CUSTOM_ORDERS = "Custom Orders";
  const [selected, setSelected] = useState(allowCustomOrders && !hasPlates ? CUSTOM_ORDERS : PLATES);
  const tabs = [PLATES, CUSTOM_ORDERS];
  const [addPlateDialogOpen, setAddPlateDialogOpen] = useState(false);
  const [locationInfoDialogOpen, setLocationInfoDialogOpen] = useState(false);
  const [disableCustomOrdersDialogOpen, setDisableCustomOrdersDialogOpen] = useState<boolean>(false);
  const [disablePlateOrdersDialogOpen, setDisablePlateOrdersDialogOpen] = useState<boolean>(false);

  const sectionsContent = !allowCustomOrders ? (
    <Card sx={{ maxWidth: "350px" }}>
      <CardActionArea
        onClick={() =>
          dispatch({
            type: "RestaurantMenuLink_ToggleAllowCustomOrders",
            payload: {
              restaurantMenuLinkId: restaurantMenuLink.id,
              allowCustomOrders: true,
            },
          })
        }
      >
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="h3"> Enable Custom Orders </Typography>
          <Typography variant="body2" color="textSecondary">
            Control which items from this restaurant's menu are included for athletes
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  ) : (
    <>
      {sortByKey(restaurantMenuLink.restaurantMenu.sections, "position").map(section => (
        <SectionForm
          key={section.id}
          restaurantMenuSectionLink={section}
          onChangeMaxAmount={maxAmount =>
            dispatch({
              type: "RestaurantMenuLink_EditSectionMaxAmountAction",
              payload: {
                restaurantMenuLinkId: restaurantMenuLink.id,
                restaurantMenuSectionId: section.id,
                maxAmount,
              },
            })
          }
          onEditMenuItemAppearance={mia =>
            dispatch({
              type: "RestaurantMenuLink_EditMenuItemAppearanceAction",
              payload: {
                restaurantMenuLinkId: restaurantMenuLink.id,
                restaurantMenuSectionId: section.id,
                menuItemId: mia.menuItem.id,
                included: mia.included,
                allowSpecialRequests: mia.allowSpecialRequests,
                availableForOrder: mia.availableForOrder,
                maxAmount: mia.maxAmount,
              },
            })
          }
          onChangeIncluded={included =>
            dispatch({
              type: "RestaurantMenuLink_ToggleSectionIncludedAction",
              payload: {
                restaurantMenuLinkId: restaurantMenuLink.id,
                restaurantMenuSectionId: section.id,
                included,
              },
            })
          }
        />
      ))}
      <Box sx={{ height: `calc(100vh - 280px)` }} />
    </>
  );

  const sectionsHeader = (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
      {restaurantMenuLink.allowCustomOrders && (
        <>
          <MenuItemAppearanceGroupControlLabel
            menuItemAppearances={restaurantMenuLink.restaurantMenu.sections.flatMap(s => s.menuItemAppearances)}
            field="allowSpecialRequests"
            text="Allow Special Requests"
            onChange={allowSpecialRequests =>
              dispatch({
                type: "RestaurantMenuLink_AllItemsAllowSpecialRequestsAction",
                payload: {
                  restaurantMenuLinkId: restaurantMenuLink.id,
                  allowSpecialRequests,
                },
              })
            }
            disabled={!restaurantMenuLink.restaurantMenu.sections.flatMap(s => s.menuItemAppearances).every(mia => mia.availableForOrder)}
          />
          <Button onClick={() => setDisableCustomOrdersDialogOpen(true)}>Disable Custom Ordering</Button>
        </>
      )}
    </Box>
  );

  const platesContent = !hasPlates ? (
    <Card sx={{ maxWidth: "350px" }}>
      <CardActionArea onClick={() => setAddPlateDialogOpen(true)}>
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="h3"> Add Plate </Typography>
          <Typography variant="body2" color="textSecondary">
            Build plates to order from this restaurant and/or let athletes order from pre-built selections
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  ) : (
    <PlatesForm restaurantMenuLink={restaurantMenuLink} dispatch={dispatch} />
  );

  const platesHeader = (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
      <Button onClick={() => setAddPlateDialogOpen(true)}> Add Plate </Button>
      {restaurantMenuLink.plates.length === 0 ? null : restaurantMenuLink.allowPlateOrders ? (
        <Button onClick={() => setDisablePlateOrdersDialogOpen(true)}>Disable Plate Ordering</Button>
      ) : (
        <Button
          onClick={() =>
            dispatch({
              type: "RestaurantMenuLink_ToggleAllowPlateOrders",
              payload: {
                restaurantMenuLinkId: restaurantMenuLink.id,
                allowPlateOrders: true,
              },
            })
          }
        >
          Enable Plate Ordering
        </Button>
      )}
    </Box>
  );

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h3">{restaurantMenuLink.restaurant.name}</Typography>
        {deliveryLocation && (
          <Tooltip title="Location details">
            <IconButton onClick={() => setLocationInfoDialogOpen(true)}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TWTabGroup tabs={tabs} onSelected={setSelected} />
        {selected === PLATES ? platesHeader : sectionsHeader}
      </Box>
      {selected === PLATES ? platesContent : sectionsContent}

      {addPlateDialogOpen && (
        <AddPlateDialog
          open={addPlateDialogOpen}
          onClose={() => setAddPlateDialogOpen(false)}
          restaurantMenu={restaurantMenuLink.restaurantMenu}
          onAddPlate={items =>
            dispatch({
              type: "RestaurantMenuLink_AddPlate",
              payload: {
                restaurantMenuLinkId: restaurantMenuLink.id,
                items,
              },
            })
          }
        />
      )}

      {disableCustomOrdersDialogOpen && (
        <ConfirmationDialog
          open={disableCustomOrdersDialogOpen}
          title="Disable custom orders?"
          message="Athletes will not be able to place custom orders."
          onCancel={() => setDisableCustomOrdersDialogOpen(false)}
          onConfirm={() => {
            dispatch({
              type: "RestaurantMenuLink_ToggleAllowCustomOrders",
              payload: {
                restaurantMenuLinkId: restaurantMenuLink.id,
                allowCustomOrders: false,
              },
            });
            setDisableCustomOrdersDialogOpen(false);
          }}
          confirmLabel="Disable"
        />
      )}
      {disablePlateOrdersDialogOpen && (
        <ConfirmationDialog
          open={disablePlateOrdersDialogOpen}
          title="Disable plate orders?"
          message="Athletes will not be able to place plate orders."
          onCancel={() => setDisablePlateOrdersDialogOpen(false)}
          onConfirm={() => {
            dispatch({
              type: "RestaurantMenuLink_ToggleAllowPlateOrders",
              payload: {
                restaurantMenuLinkId: restaurantMenuLink.id,
                allowPlateOrders: false,
              },
            });
            setDisablePlateOrdersDialogOpen(false);
          }}
          confirmLabel="Disable"
        />
      )}
      {locationInfoDialogOpen && (
        <RestaurantMenuLinkLocationDetailsDialog
          deliveryLocation={restaurantMenuLink.deliveryLocation}
          restaurantLocation={restaurantMenuLink.restaurantLocation}
          open={locationInfoDialogOpen}
          onClose={() => setLocationInfoDialogOpen(false)}
        />
      )}
    </Box>
  );
};

export default memo(RestaurantMenuLinkForm);
