import { Button, Dialog, DialogActions, DialogContent, Step, StepLabel, Stepper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import { serializeDate } from "@notemeal/utils-date-time";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { startOfWeek } from "date-fns";
import { useState } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import {
    ImportBonAppetitMenusInput,
    TeamMealMenuPreviewFragment,
    useBonAppetitCafesQuery,
    useBonAppetitMenusPreviewQuery,
} from "../../../../types";
import OtherSettings from "../Common/OtherSettings";
import { ImportMenuOptionsState, getImportMenuOptionsInput } from "../Common/utils";
import { getInitImportMenuOptionsState } from "./BonAppetitUtils";
import SelectCafe from "./SelectBonAppetitCafe";
import SelectBonAppetitMenus from "./SelectBonAppetitMenus";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "calc(100% - 64px)",
    },
    content: {
      overflow: "auto",
    },
    menuForm: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    savingDiv: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
  })
);

const STEPS = ["Select Cafe", "Select Cafe Menus", "Edit Menu Details"];

interface BonAppetitDialogProps {
  open: boolean;
  onClose: () => void;
  onImport: (input: ImportBonAppetitMenusInput) => void;
  clientTimezone: string;
  allTeams: readonly TeamMealMenuPreviewFragment[];
  saving: boolean;
}

const BonAppetitDialog = ({ open, onClose, onImport, clientTimezone, allTeams, saving }: BonAppetitDialogProps) => {
  const classes = useStyles();
  const { data: cafesData } = useBonAppetitCafesQuery();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCafeId, setSelectedCafeId] = useState<string>("");
  const [importMenuOptionsState, setImportMenuOptionsState] = useState<ImportMenuOptionsState | null>(null);
  const [startOfWeekDate, setStartOfWeekDate] = useState(startOfWeek(new Date()));
  const { data: menusPreviewData, loading } = useBonAppetitMenusPreviewQuery({
    variables: { cafeId: selectedCafeId ?? "", date: serializeDate(startOfWeekDate) },
    skip: selectedCafeId === null,
  });
  const menusPreview = menusPreviewData?.bonAppetitMenusPreview;
  const [selectedMenuIds, setSelectedMenuIds] = useState<readonly string[]>([]);

  const onToggleMenu = (menuId: string) => {
    if (selectedMenuIds.includes(menuId)) {
      setSelectedMenuIds(selectedMenuIds.filter(id => id !== menuId));
    } else {
      setSelectedMenuIds([...selectedMenuIds, menuId]);
    }
  };

  const onSelectAllMenus = () => {
    const days = menusPreview?.days || [];
    setSelectedMenuIds(days.flatMap(({ date, dayParts }) => dayParts.map(({ id }) => `${date} ${id}`)));
  };

  const onChangeStartOfWeekDate = (startOfWeekDate: Date) => {
    setStartOfWeekDate(startOfWeekDate);
    // Clear selected menus if changing week
    setSelectedMenuIds([]);
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const getCanSaveTooltipItems = (): string[] => {
    switch (activeStep) {
      case 0:
        return !selectedCafeId ? ["Select cafe to look up menus"] : [];
      case 1:
        return selectedMenuIds.length === 0 ? ["Select at least one menu to import"] : [];
      default:
        return [];
    }
  };

  const canSaveTooltips = getCanSaveTooltipItems();

  const handleNext = async () => {
    if (canSaveTooltips.length) {
      return;
    }
    if (activeStep === 1) {
      setActiveStep(activeStep + 1);
      if (menusPreview) {
        setImportMenuOptionsState(getInitImportMenuOptionsState(selectedMenuIds, menusPreview, clientTimezone));
      }
    } else if (activeStep === 2) {
      if (selectedCafeId && importMenuOptionsState) {
        setActiveStep(activeStep + 1);
        onImport({
          options: getImportMenuOptionsInput(importMenuOptionsState),
          cafeId: selectedCafeId,
          menus: selectedMenuIds.map(menuId => {
            const [date, mealId] = menuId.split(" ");
            return { date, mealId };
          }),
        });
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      classes={{ paper: classes.paper }}>
      <DialogTitle title="Import Bon Appetit Menus" onClose={onClose} />
      <DialogContent>
        <Stepper sx={{ width: "80%", alignSelf: "center" }} activeStep={activeStep}>
          {STEPS.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.content}>
          {activeStep === 0 && cafesData ? (
            <SelectCafe
              cafes={cafesData.bonAppetitCafes}
              selectedCafeId={selectedCafeId}
              onSelectCafe={setSelectedCafeId} />
          ) : activeStep === 1 && menusPreview && selectedCafeId ? (
            <SelectBonAppetitMenus
              loading={loading}
              menusPreview={menusPreview}
              selectedMenuIds={selectedMenuIds}
              onToggleMenu={onToggleMenu}
              onSelectAllMenus={onSelectAllMenus}
              startOfWeekDate={startOfWeekDate}
              onChangeStartOfWeekDate={onChangeStartOfWeekDate}
            />
          ) : activeStep === 2 && importMenuOptionsState ? (
            <div className={classes.menuForm}>
              <OtherSettings
                state={importMenuOptionsState}
                readOnlyTimes={true}
                onChange={setImportMenuOptionsState}
                allTeams={allTeams} />
            </div>
          ) : activeStep === 3 && saving ? (
            <div className={classes.savingDiv}>
              <Loading progressSize="md" />
              <Typography variant="h3">Importing Menus - this may take a moment...</Typography>
            </div>
          ) : (
            <Loading progressSize="md" />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={activeStep === STEPS.length || activeStep === 0}
          onClick={handleBack}>
          Back
        </Button>
        {activeStep >= STEPS.length - 1 && (
          <TWItemizedTooltip title="Fix the following before finishing" items={canSaveTooltips}>
            <Button onClick={handleNext} disabled={activeStep > STEPS.length - 1}>
              Finish
            </Button>
          </TWItemizedTooltip>
        )}
        {activeStep < STEPS.length - 1 && (
          <TWItemizedTooltip title="Fix the following before advancing" items={canSaveTooltips}>
            <Button onClick={handleNext}>Next</Button>
          </TWItemizedTooltip>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BonAppetitDialog;
