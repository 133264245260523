import { Box, Button, MenuItem, TextField } from "@mui/material";
import { capitalize } from "@notemeal/shared-ui";
import { TeamMealMenuPreviewFragment } from "apps/web/src/types";
import MenusTeamMultiSelect from "../../Menus/SelectComponents/TeamMultiSelect";

interface ViewAttendanceTableToolbarProps {
  mealTypes: string[];
  setSelectedMealType: (newMealType: string) => void;
  teams: { id: string; name: string }[];
  selectedTeams: readonly TeamMealMenuPreviewFragment[];
  onChangeTeams: (selectedTeams: readonly TeamMealMenuPreviewFragment[]) => void;
  onChangeHasAccess: (newHasAccess: boolean | null) => void;
  exportData: () => void;
}

export const ViewAttendanceTableToolbar = ({
  mealTypes,
  setSelectedMealType,
  onChangeHasAccess,
  exportData,
  teams,
  selectedTeams,
  onChangeTeams,
}: ViewAttendanceTableToolbarProps) => {
  const handleHasAccessOnChange = (option: string) => {
    if (option === "all") {
      onChangeHasAccess(null);
    } else if (option === "has-access") {
      onChangeHasAccess(true);
    } else {
      onChangeHasAccess(false);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
          <MenusTeamMultiSelect
            selectedTeams={selectedTeams}
            onChange={onChangeTeams}
            sx={{ width: 300 }}
            displayedTeamsCount={1}
            allTeams={[...teams.map(t => ({ ...t, athletes: [] }))]}
          />
          <TextField
            sx={{ width: 250 }}
            select
            defaultValue="all"
            label="Meal Type"
            onChange={e => setSelectedMealType(e.target.value)}>
            <MenuItem value="all">View All</MenuItem>
            {mealTypes.map(type => (
              <MenuItem value={type}>{capitalize(type)}</MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ width: 250 }}
            select
            defaultValue="all"
            label="Access"
            onChange={e => handleHasAccessOnChange(e.target.value)}>
            <MenuItem value="all">View All</MenuItem>
            <MenuItem value="has-access">Has Access</MenuItem>
            <MenuItem value="no-access">No Access</MenuItem>
          </TextField>
        </Box>
        <Button onClick={exportData}>Export</Button>
      </Box>
    </>
  );
};
