import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Checkbox, Collapse, TableCell, TableRow, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useDateFormatting } from "@notemeal/shared-ui";
import { parseDateTime } from "@notemeal/utils-date-time";
import React, { useState } from "react";
import TeamAthletesMealPlansDetailPanel from "./DetailPanel";
import { TeamAthletesMealPlanRow } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
    },
    cell: { borderBottom: "none" },
    headerCell: { padding: theme.spacing(0.5) },
  })
);

interface TableRowProps {
  row: TeamAthletesMealPlanRow;
  selected: boolean;
  onSelectRow: (id: string) => void;
  handleClickCopy: (mealPlanRow: TeamAthletesMealPlanRow) => void;
  handleNavigateTo: (mealPlanRow: TeamAthletesMealPlanRow) => void;
}

const MealPlanTableRow = ({ row, selected, onSelectRow, handleClickCopy, handleNavigateTo }: TableRowProps) => {
  const classes = useStyles();
  const { formatDateWithLocale, formatEventDatetimeWithLocale } = useDateFormatting();
  const [expanded, setExpanded] = useState<boolean>(false);
  const {
    id,
    athleteName,
    name,
    isShared,
    lastViewedDate,
    numberOfViews,
    wasCreatedFromMealPlanTemplate,
    goalType,
    createdAtString,
    type,
  } = row;
  return (
    <>
      <TableRow
        key={row.id}
        hover
        className={classes.row}
        onClick={() => setExpanded(!expanded)}>
        <TableCell className={classes.cell} align="center">
          {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </TableCell>
        <TableCell className={classes.cell} align="left">
          <Checkbox
            checked={selected}
            onClick={e => {
              e.stopPropagation();
              onSelectRow(id);
            }}
            inputProps={{
              "aria-label": "Select plan",
            }}
          />
        </TableCell>
        <TableCell>{athleteName}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{goalType}</TableCell>
        <TableCell className={classes.cell}>{isShared && <CheckIcon />}</TableCell>
        <TableCell>{lastViewedDate && `${formatEventDatetimeWithLocale(lastViewedDate, { formatLong: true })}`}</TableCell>
        <TableCell>{numberOfViews > 0 && numberOfViews}</TableCell>
        <TableCell className={classes.cell}>{wasCreatedFromMealPlanTemplate && <CheckIcon />}</TableCell>
        <TableCell>{formatDateWithLocale(parseDateTime(createdAtString))}</TableCell>
        <TableCell>{type}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding={"none"} colSpan={12}>
          <Collapse in={expanded} timeout={100}>
            <TeamAthletesMealPlansDetailPanel
              mealPlanRow={row}
              handleClickCopy={handleClickCopy}
              handleNavigateTo={handleNavigateTo} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default MealPlanTableRow;

interface MealPlanTableHeaderRowProps {
  children: React.ReactNode;
}

export const MealPlanTableHeaderRow = ({ children }: MealPlanTableHeaderRowProps) => (
  <TableRow>
    <TableCell />
    <TableCell>{children}</TableCell>
    <TableCell>Athlete</TableCell>
    <TableCell>Name</TableCell>
    <TableCell>Weight Target Type</TableCell>
    <TableCell>Shared?</TableCell>
    <TableCell>Last View</TableCell>
    <TableCell># Views</TableCell>
    <TableCell>From Template?</TableCell>
    <TableCell>Date Created</TableCell>
    <TableCell>Type</TableCell>
  </TableRow>
);
