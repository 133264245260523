import { Box } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { serializeDate } from "@notemeal/utils-date-time";
import CalendarMonth, { CalendarMonthRendDayProps } from "apps/web/src/components/Calendar/Month";
import { MealPlanDateAssignmentPreviewFragment } from "apps/web/src/types";
import classnames from "classnames";
import { addWeeks, endOfMonth, startOfMonth } from "date-fns";
import React, { Dispatch } from "react";
import { useMealPlanCalendarContext } from "../../../../contexts/Calendar";
import { useMealPlanColorsContext } from "../../../../contexts/Colors";
import { MealPlanDateAssignmentAction } from "../reducer";
import { MealPlanDateAssignment } from "../types";
import { getMealPlanAssignedDatesInRange } from "../utils";

const useStyles = makeStyles(() =>
  createStyles({
    day: {
      position: "relative",
    },
    baseDot: {
      position: "absolute",
      height: 6,
      width: 6,
      borderRadius: "50%",
      bottom: 2,
    },
    singleDot: {
      left: "calc(50% - 3px)",
    },
    doubleDot1: {
      left: "calc(50% - 7.5px)",
    },
    doubleDot2: {
      left: "calc(50% + 1.5px)",
    },
  })
);

interface MealPlanDateAssignmentCalendarProps {
  state: MealPlanDateAssignment;
  otherMealPlanDateAssignments: readonly MealPlanDateAssignmentPreviewFragment[];
  mealPlanColor: string;
  dispatch: Dispatch<MealPlanDateAssignmentAction>;
  onClickCalendarDate: (date: string) => void;
  isLoading?: (startOfMonthDate: Date) => boolean;
}

const MealPlanDateAssignmentCalendar = ({
  state,
  otherMealPlanDateAssignments,
  mealPlanColor,
  dispatch,
  onClickCalendarDate,
  isLoading,
}: MealPlanDateAssignmentCalendarProps) => {
  const classes = useStyles();
  const { startOfMonthDate, onChangeStartOfMonthDate } = useMealPlanCalendarContext();
  const { getMealPlanColor } = useMealPlanColorsContext();

  // Add / subtract extra week to handle days not part of the month still shown on calendar
  const startDate = serializeDate(addWeeks(startOfMonth(startOfMonthDate), -1));
  const endDate = serializeDate(addWeeks(endOfMonth(startOfMonthDate), 1));

  const assignedDates = getMealPlanAssignedDatesInRange(state, startDate, endDate);

  const renderDay = (props: PickersDayProps<Date> & CalendarMonthRendDayProps) => {
    const { isoDate, dayText, isCurrentMonth, ...other } = props;
    const selected = assignedDates.includes(isoDate);
    const otherDateAssignment = otherMealPlanDateAssignments.find(a => a.date === isoDate);
    const backgroundColor = selected ? mealPlanColor : undefined;
    if (state.mode === "individual") {
      return (
        <Box
          className={classes.day}
          onClick={() => {
            dispatch({
              type: "ToggleIndividualDateAssignment",
              payload: {
                date: isoDate,
              },
            });
            onClickCalendarDate(isoDate);
          }}
        >
          <PickersDay
            {...other}
            selected={false}
            sx={{
              backgroundColor,
              ":hover": {
                backgroundColor,
              },
              ":focus": {
                backgroundColor,
              },
            }}
          >
            {dayText}
          </PickersDay>
          {otherDateAssignment && (
            <Box
              className={classnames(classes.baseDot, classes.singleDot)}
              sx={{
                backgroundColor: getMealPlanColor(otherDateAssignment.mealPlan.id),
              }}
            />
          )}
        </Box>
      );
    } else {
      return (
        <Box
          className={classes.day}
          onClick={() => {
            onClickCalendarDate(isoDate);
          }}
        >
          <PickersDay {...other} selected={false}>
            {dayText}
          </PickersDay>
          {selected && (
            <Box
              className={classnames(classes.baseDot, {
                [classes.singleDot]: !otherDateAssignment,
                [classes.doubleDot1]: otherDateAssignment,
              })}
              sx={{ backgroundColor }}
            />
          )}
          {otherDateAssignment && (
            <Box
              className={classnames(classes.baseDot, {
                [classes.singleDot]: !selected,
                [classes.doubleDot2]: selected,
              })}
              sx={{
                backgroundColor: getMealPlanColor(otherDateAssignment.mealPlan.id),
              }}
            />
          )}
        </Box>
      );
    }
  };

  return (
    <CalendarMonth
      renderDay={renderDay}
      loading={isLoading?.(startOfMonthDate)}
      calendarDate={startOfMonthDate}
      onChangeCalendarDate={onChangeStartOfMonthDate}
    />
  );
};

export default MealPlanDateAssignmentCalendar;
