import { useDraggable } from "@dnd-kit/core";
import { PaperProps } from "@mui/material";
import { dateToIsoInTz } from "@notemeal/utils-date-time";
import React, { useEffect, useState } from "react";
import { CalendarEvent, CalendarEventWithSlot, RenderCalendarEvent } from "../utils";
import { useBasePaperProps } from "./utils";

interface CalendarEventContainerProps<E extends CalendarEvent> {
  hourHeight: number;
  date: string;
  clientTimezone: string;
  hasAllDayCell: boolean;
  eventWithSlot: CalendarEventWithSlot<E>;
  renderEvent: RenderCalendarEvent<E>;
  canDragEvent?: (event: E) => boolean;
  doneDraggingData: { newStart: Date } | null;
  onResolveDragging: () => void;
}

const CalendarEventContainer = <E extends CalendarEvent>({
  hourHeight,
  date,
  clientTimezone,
  hasAllDayCell,
  eventWithSlot,
  renderEvent: { renderPaper, renderPopover },
  canDragEvent,
  doneDraggingData,
  onResolveDragging,
}: CalendarEventContainerProps<E>) => {
  const { event, slot, maxSlot } = eventWithSlot;
  const dateStart = new Date(dateToIsoInTz(date, clientTimezone));

  // events in the past shouldn't be draggable
  if (event.start < new Date()) {
    canDragEvent = undefined;
  }

  // Wanted to use Omit<RenderCalendarPopoverArgs, "onClose"> but Omits + discriminated unions do not play well together
  const [popoverInfo, setPopoverInfo] = useState<
    ({ anchorEl: HTMLElement; action: "click" } | { anchorEl: HTMLElement; action: "drag"; newStart: Date }) | null
  >(null);
  const selected = popoverInfo !== null;

  const { attributes, listeners, setNodeRef, isDragging, node } = useDraggable({
    id: event.id,
    disabled: !canDragEvent || !canDragEvent(event),
  });

  useEffect(() => {
    if (!popoverInfo && doneDraggingData && node.current) {
      setPopoverInfo({
        anchorEl: node.current,
        newStart: doneDraggingData.newStart,
        action: "drag",
      });
    }
  }, [doneDraggingData, popoverInfo, node]);

  const basePaperProps = useBasePaperProps({
    event,
    slot,
    maxSlot,
    hourHeight,
    dateStart,
    selected,
    hasAllDayCell,
  });

  const PaperProps: PaperProps = {
    ...basePaperProps,
    style: {
      ...basePaperProps.style,
      opacity: isDragging ? 0.5 : 1,
    },
    onClick: e => {
      e.stopPropagation();
      setPopoverInfo({ anchorEl: e.currentTarget, action: "click" });
    },
    ref: setNodeRef,
    ...attributes,
    ...listeners,
  };

  return (
    <>
      {renderPaper(event, {
        PaperProps,
        isAbbreviated: event.isAllDay || event.durationInMinutes < 60,
        slot,
      })}
      {popoverInfo &&
        renderPopover(event, {
          ...popoverInfo,
          onClose: () => {
            onResolveDragging();
            setPopoverInfo(null);
          },
        })}
    </>
  );
};

export default CalendarEventContainer;
