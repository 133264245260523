import { dateAndTimeToIsoInTz } from "@notemeal/utils-date-time";
import { getLastOrderTimeBeforeEndInMinutes, getNoItemsAvailableForOrder } from "./utils";
import { MenuDialogState, NewMenuDialogState, ShareMealMenuState, StandaloneMenuDialogState } from "./types";

import {
  useCreateMealMenusMutation,
  useEditMealMenuMutation,
  useBulkEditMealMenusMutation,
  useDeleteMealMenuMutation,
  useBulkDeleteMealMenusMutation,
  MealMenusInDateRangeDocument,
  MealMenusInDateRangeQuery,
  MealMenusInDateRangeQueryVariables,
  useImportCompassMenusMutation,
  MenuOrderRateLimitInput,
  MenuOrderRateLimitFragment,
  BulkEditMealMenusInput,
  useBulkCopyMealMenusMutation,
  CopyMealMenusInput,
  useImportCbordMenusMutation,
  ImportCbordMenusInput,
  ImportCompassMenusInput,
  useEditMealMenuWithIdentityMutation,
  AdvancedSelectionInput,
  ImportBonAppetitMenusInput,
  useImportBonAppetitMenusMutation,
  ImportSodexoMenusInput,
  useImportSodexoMenusMutation,
} from "../../types";
import { OnBulkEditArgs } from "./BulkEditDialog/types";
import { RestaurantMenuLinkState } from "./RestaurantMenu/types";
import { MealMenuDiningStationState } from "./DiningStation/types";
import { getCreateMealMenuDiningStationInputs, getEditMealMenuDiningStationInputs } from "./DiningStation/utils";
import { getCreateRestaurantMenuLinkInputs, getEditRestaurantMenuLinkInputs } from "./RestaurantMenu/utils";
import { useNavigate } from "react-router-dom-v5-compat";
import { buildAdvancedSelectionInput } from "../Tags/reducers/advancedSelectionReducers";
import { getNavOrgKitchenMenuScheduleDate } from "../../pages/Auth/Org/Kitchen/KitchenPaths";
import { useSnackbar } from "../../components/Snackbar/SnackbarContext";

export interface onSaveMenuArgs {
  state: MenuDialogState;
  initialDiningStations: readonly MealMenuDiningStationState[];
  initialRestaurantMenuLinks: readonly RestaurantMenuLinkState[];
}

export interface onEditMenuArgs {
  state: StandaloneMenuDialogState;
  initialDiningStations: readonly MealMenuDiningStationState[];
  initialRestaurantMenuLinks: readonly RestaurantMenuLinkState[];
}

interface useOnSaveDeleteMenuPayload {
  saving: boolean;
  onCreate: (state: NewMenuDialogState) => Promise<void>;
  onEdit: (args: onEditMenuArgs) => Promise<void>;
  onImportCompass: (input: ImportCompassMenusInput) => Promise<void>;
  onImportCbord: (input: ImportCbordMenusInput) => Promise<void>;
  onImportBonAppetit: (input: ImportBonAppetitMenusInput) => Promise<void>;
  onImportSodexo: (input: ImportSodexoMenusInput) => Promise<void>;
  onDelete: (state: MenuDialogState) => Promise<void>;
}

export const useOnSaveDeleteMenu = (variables: MealMenusInDateRangeQueryVariables): useOnSaveDeleteMenuPayload => {
  const navigate = useNavigate();
  const { setMessage } = useSnackbar();
  const [createMealMenus, { loading: savingCreate }] = useCreateMealMenusMutation({
    update: (cache, { data }) => {
      const cacheQuery = cache.readQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>({
        variables,
        query: MealMenusInDateRangeDocument,
      });
      if (cacheQuery && data) {
        const newMealMenus = data.createMealMenus.mealMenus.map(mm => {
          const rmls = mm.restaurantMenuLinks.map(rml => {
            const plates = rml.plates.map(p => {
              return { ...p, orders: [] };
            });
            return { ...rml, orders: [], plates };
          });
          return { ...mm, restaurantMenuLinks: rmls };
        });

        // Update cache
        cache.writeQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>({
          variables,
          query: MealMenusInDateRangeDocument,
          data: {
            ...cacheQuery,
            mealMenusInDateRange: [...cacheQuery.mealMenusInDateRange, ...newMealMenus],
          },
        });
      }
    },
    onCompleted: data => {
      const oneMade = data.createMealMenus.mealMenus.length === 1;
      if (oneMade) {
        const menu = data.createMealMenus.mealMenus[0];
        const name = menu.name;
        setMessage("success", `Successfully created Meal Menu: ${name}`);
      } else {
        setMessage("success", `Successfully created ${data.createMealMenus.mealMenus.length} Meal Menus`);
      }
    },
    onError: () => {
      setMessage("error", "Error occurred while creating Meal Menu(s)");
    },
  });

  const [importCompassMenu, { loading: savingImportCompass }] = useImportCompassMenusMutation({
    update: (cache, { data }) => {
      const cacheQuery = cache.readQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>({
        variables,
        query: MealMenusInDateRangeDocument,
      });
      if (cacheQuery && data) {
        cache.writeQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>({
          variables,
          query: MealMenusInDateRangeDocument,
          data: {
            ...cacheQuery,
            mealMenusInDateRange: [...cacheQuery.mealMenusInDateRange, ...data.importCompassMenus.mealMenus],
          },
        });
      }
    },
    onError: () => setMessage("error", `Error importing menu. Contact support for help.`),
    onCompleted: data => {
      const oneMade = data.importCompassMenus.mealMenus.length === 1;
      if (oneMade) {
        const menu = data.importCompassMenus.mealMenus[0];
        const name = menu.name;
        setMessage("success", `Successfully imported Meal Menu: ${name}`);
      } else {
        setMessage("success", `Successfully imported ${data.importCompassMenus.mealMenus.length} Meal Menus`);
      }
    },
  });

  const [importCbordMenu, { loading: savingImportCbord }] = useImportCbordMenusMutation({
    update: (cache, { data }) => {
      const cacheQuery = cache.readQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>({
        variables,
        query: MealMenusInDateRangeDocument,
      });
      if (cacheQuery && data) {
        cache.writeQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>({
          variables,
          query: MealMenusInDateRangeDocument,
          data: {
            ...cacheQuery,
            mealMenusInDateRange: [...cacheQuery.mealMenusInDateRange, ...data.importCbordMenus.mealMenus],
          },
        });
      }
    },
    onError: () => setMessage("error", `Error importing menu. Contact support for help.`),
    onCompleted: data => {
      const oneMade = data.importCbordMenus.mealMenus.length === 1;
      if (oneMade) {
        const menu = data.importCbordMenus.mealMenus[0];
        const name = menu.name;
        setMessage("success", `Successfully imported Meal Menu: ${name}`);
      } else {
        setMessage("success", `Successfully imported ${data.importCbordMenus.mealMenus.length} Meal Menus`);
      }
      const firstMenuStart = data.importCbordMenus.mealMenus.reduce<string | null>(
        (firstStart, { start }) => (!firstStart ? start : firstStart > start ? start : firstStart),
        null
      );
      if (firstMenuStart) {
        const firstMenuStartDate = firstMenuStart.split("T")[0];
        navigate(getNavOrgKitchenMenuScheduleDate(firstMenuStartDate));
      }
    },
  });

  const [importBonAppetitMenus, { loading: savingImportBonAppetit }] = useImportBonAppetitMenusMutation({
    update: (cache, { data }) => {
      const cacheQuery = cache.readQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>({
        variables,
        query: MealMenusInDateRangeDocument,
      });
      if (cacheQuery && data) {
        cache.writeQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>({
          variables,
          query: MealMenusInDateRangeDocument,
          data: {
            ...cacheQuery,
            mealMenusInDateRange: [...cacheQuery.mealMenusInDateRange, ...data.importBonAppetitMenus.mealMenus],
          },
        });
      }
    },
    onError: () => setMessage("error", `Error importing menus. Contact support for help.`),
    onCompleted: data => {
      const oneMade = data.importBonAppetitMenus.mealMenus.length === 1;
      if (oneMade) {
        const menu = data.importBonAppetitMenus.mealMenus[0];
        const name = menu.name;
        setMessage("success", `Successfully imported Meal Menu: ${name}`);
      } else {
        setMessage("success", `Successfully imported ${data.importBonAppetitMenus.mealMenus.length} Meal Menus`);
      }
      const firstMenuStart = data.importBonAppetitMenus.mealMenus.reduce<string | null>(
        (firstStart, { start }) => (!firstStart ? start : firstStart > start ? start : firstStart),
        null
      );
      if (firstMenuStart) {
        const firstMenuStartDate = firstMenuStart.split("T")[0];
        navigate(getNavOrgKitchenMenuScheduleDate(firstMenuStartDate));
      }
    },
  });

  // TODO - the result of the import is the same for all food services, need to remove the duplication.
  const [importSodexoMenus, { loading: savingImportSodexo }] = useImportSodexoMenusMutation({
    update: (cache, { data }) => {
      const cacheQuery = cache.readQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>({
        variables,
        query: MealMenusInDateRangeDocument,
      });
      if (cacheQuery && data) {
        cache.writeQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>({
          variables,
          query: MealMenusInDateRangeDocument,
          data: {
            ...cacheQuery,
            mealMenusInDateRange: [...cacheQuery.mealMenusInDateRange, ...data.importSodexoMenus.mealMenus],
          },
        });
      }
    },
    onError: () => setMessage("error", `Error importing menus. Contact support for help.`),
    onCompleted: data => {
      const oneMade = data.importSodexoMenus.mealMenus.length === 1;
      if (oneMade) {
        const menu = data.importSodexoMenus.mealMenus[0];
        const name = menu.name;
        setMessage("success", `Successfully imported Meal Menu: ${name}`);
      } else {
        setMessage("success", `Successfully imported ${data.importSodexoMenus.mealMenus.length} Meal Menus`);
      }
      const firstMenuStart = data.importSodexoMenus.mealMenus.reduce<string | null>(
        (firstStart, { start }) => (!firstStart ? start : firstStart > start ? start : firstStart),
        null
      );
      if (firstMenuStart) {
        const firstMenuStartDate = firstMenuStart.split("T")[0];
        navigate(getNavOrgKitchenMenuScheduleDate(firstMenuStartDate));
      }
    },
  });

  const [editMealMenu, { loading: savingEdit }] = useEditMealMenuMutation({
    onError: () => setMessage("error", `Error occurred while editing Meal Menu`),
    onCompleted: data => {
      const menu = data.editMealMenu.mealMenu;
      const name = menu.name;
      setMessage("success", `Successfully edited Meal Menu: ${name}`);
    },
  });

  const [editMealMenuWithIdentity, { loading: savingEditWithIdentity }] = useEditMealMenuWithIdentityMutation({
    onError: () => setMessage("error", `Error occurred while editing Meal Menu`),
    onCompleted: data => {
      const menu = data.editMealMenuWithIdentity.mealMenu;
      const name = menu.name;
      setMessage("success", `Successfully edited Meal Menu: ${name}`);
    },
  });

  const [deleteMealMenu, { loading: savingDelete }] = useDeleteMealMenuMutation({
    update: (cache, { data }) => {
      if (data) {
        const normID = cache.identify({
          id: data.deleteMealMenu.mealMenuId,
          __typename: "StandaloneMealMenu",
        });
        cache.evict({ id: normID });
        cache.gc();
      }
    },
    onError: () => setMessage("error", `Error occurred while deleting Meal Menu`),
    onCompleted: () => {
      setMessage("success", `Successfully deleted Meal Menu`);
    },
  });

  const saving =
    savingCreate ||
    savingEdit ||
    savingEditWithIdentity ||
    savingDelete ||
    savingImportCompass ||
    savingImportCbord ||
    savingImportBonAppetit ||
    savingImportSodexo;

  const handleImportCompass = async (input: ImportCompassMenusInput): Promise<void> => {
    await importCompassMenu({
      variables: {
        input,
      },
    });
  };

  const handleImportCbord = async (input: ImportCbordMenusInput) => {
    await importCbordMenu({
      variables: {
        input,
      },
    });
  };

  const handleImportBonAppetit = async (input: ImportBonAppetitMenusInput) => {
    await importBonAppetitMenus({
      variables: {
        input,
      },
    });
  };

  const handleImportSodexo = async (input: ImportSodexoMenusInput) => {
    await importSodexoMenus({
      variables: {
        input,
      },
    });
  };

  const getShareStateInputs = (
    shareState: ShareMealMenuState
  ): {
    teamIds: string[] | null;
    advancedSelectionInput: AdvancedSelectionInput | null;
  } => ({
    teamIds: shareState.__typename === "Teams" ? shareState.teams.map(t => t.id) : null,
    advancedSelectionInput: shareState.__typename === "Tags" ? buildAdvancedSelectionInput(shareState.advancedSelectionState) : null,
  });

  const handleCreate = (state: NewMenuDialogState): Promise<any> => {
    const lastOrderTimeBeforeEndInMinutes = getLastOrderTimeBeforeEndInMinutes({
      startTime: state.startTime,
      durationInMinutes: state.durationInMinutes,
      lastOrderDaysBefore: state.lastOrderDaysBefore,
      lastOrderTime: state.lastOrderTime,
    });
    const starts = state.startDates.map(startDate => {
      return dateAndTimeToIsoInTz({ date: startDate, time: state.startTime }, state.timezone);
    });
    const noItemsAvailableForOrder = state.mealMenuDiningStations
      .flatMap(mmds => mmds.menuItemAppearances)
      .every(mia => !mia.availableForOrder);
    const notificationSentBeforeOrderDueInMinutes = noItemsAvailableForOrder ? null : state.notificationSentBeforeOrderDueInMinutes;
    return createMealMenus({
      variables: {
        input: {
          name: state.name,
          type: state.type,
          prepTimeInMinutes: state.prepTimeInMinutes,
          timezone: state.timezone,
          durationInMinutes: state.durationInMinutes,
          lastOrderTimeBeforeEndInMinutes,
          starts,
          orderRateLimit: getOrderRateLimitInput(state.orderRateLimit),
          userOrderLimit: state.userOrderLimit,
          isHubCheckInEnabled: state.isHubCheckInEnabled,
          isOrderAndLogRestricted: state.isOrderAndLogRestricted,
          notificationSentBeforeOrderDueInMinutes,
          ...getCreateMealMenuDiningStationInputs(state.mealMenuDiningStations),
          ...getCreateRestaurantMenuLinkInputs(state.restaurantMenuLinks),
          ...getShareStateInputs(state.shareState),
          theme: state.theme,
        },
      },
    });
  };

  const handleEdit = ({ state, initialDiningStations, initialRestaurantMenuLinks }: onEditMenuArgs): Promise<any> => {
    const lastOrderTimeBeforeEndInMinutes = getLastOrderTimeBeforeEndInMinutes({
      startTime: state.startTime,
      durationInMinutes: state.durationInMinutes,
      lastOrderDaysBefore: state.lastOrderDaysBefore,
      lastOrderTime: state.lastOrderTime,
    });
    const start = dateAndTimeToIsoInTz({ date: state.startDate, time: state.startTime }, state.timezone);
    const noItemsAvailableForOrder = getNoItemsAvailableForOrder(state);
    const notificationSentBeforeOrderDueInMinutes = noItemsAvailableForOrder ? null : state.notificationSentBeforeOrderDueInMinutes;

    const commonInput = {
      mealMenuId: state.id,
      name: state.name,
      type: state.type,
      durationInMinutes: state.durationInMinutes,
      start,
      lastOrderTimeBeforeEndInMinutes,
      prepTimeInMinutes: state.prepTimeInMinutes,
      timezone: state.timezone,
      notificationSentBeforeOrderDueInMinutes,
      orderRateLimit: getOrderRateLimitInput(state.orderRateLimit),
      userOrderLimit: state.userOrderLimit,
      isHubCheckInEnabled: state.isHubCheckInEnabled,
      isOrderAndLogRestricted: state.isOrderAndLogRestricted,
      ...getShareStateInputs(state.shareState),
      theme: state.theme,
    };

    if (state.identity && !state.identity.isOverridden) {
      const menuItemAppearances = state.mealMenuDiningStations.flatMap(ds => ds.menuItemAppearances);
      const availableForOrder = menuItemAppearances.every(mia => mia.availableForOrder);
      const allowSpecialRequests = availableForOrder && menuItemAppearances.every(mia => mia.allowSpecialRequests);
      return editMealMenuWithIdentity({
        variables: {
          input: {
            ...commonInput,
            availableForOrder,
            allowSpecialRequests,
          },
        },
      });
    }
    return editMealMenu({
      variables: {
        input: {
          ...commonInput,
          ...getEditMealMenuDiningStationInputs({
            initial: initialDiningStations,
            final: state.mealMenuDiningStations,
          }),
          ...getEditRestaurantMenuLinkInputs({
            initial: initialRestaurantMenuLinks,
            final: state.restaurantMenuLinks,
          }),
        },
      },
    });
  };

  const handleDelete = async (state: MenuDialogState) => {
    if (state.__typename === "New") {
      // Cannot delete new menu
    } else {
      await deleteMealMenu({
        variables: {
          input: {
            mealMenuId: state.id,
          },
        },
      });
    }
  };
  return {
    saving,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
    onImportCompass: handleImportCompass,
    onImportCbord: handleImportCbord,
    onImportBonAppetit: handleImportBonAppetit,
    onImportSodexo: handleImportSodexo,
  };
};

export const getOrderRateLimitInput = (orderRateLimit: MenuOrderRateLimitFragment | null): MenuOrderRateLimitInput | null => {
  if (!orderRateLimit) {
    return null;
  }
  return {
    limit: orderRateLimit.limit,
    // Remove team ids from excludedTeamIds that are not on the menu anymore
    excludedTeamIds: orderRateLimit.excludedTeamIds,
  };
};

interface useOnSaveBulkMutationsPayload {
  saving: boolean;
  onBulkEdit: (args: OnBulkEditArgs) => Promise<void>;
  onBulkDelete: (mealMenuIds: string[]) => Promise<void>;
  onBulkCopy: (input: CopyMealMenusInput) => Promise<void>;
}

export const getBulkEditPayload = (args: OnBulkEditArgs): BulkEditMealMenusInput => {
  const { actionType, timingState } = args;

  const actionInfo = {
    hasTimingEdit: actionType === "timing",
    hasNotificationEdit: actionType === "notification",
    hasSharingEdit: actionType === "sharing",
  };

  const basePayload = {
    mealMenuIds: args.mealMenuIds,
    ...actionInfo,
    teamIds: null,
    advancedSelectionInput: null,
    notificationSentBeforeOrderDueInMinutes: null,
    startTime: null,
    timezone: null,
    durationInMinutes: null,
    lastOrderTimeBeforeEndInMinutes: null,
    prepTimeInMinutes: null,
  };

  if (actionInfo.hasTimingEdit && timingState) {
    const lastOrderTimeBeforeEndInMinutes = getLastOrderTimeBeforeEndInMinutes({
      startTime: timingState.startTime,
      durationInMinutes: timingState.durationInMinutes,
      lastOrderDaysBefore: timingState.lastOrderDaysBefore,
      lastOrderTime: timingState.lastOrderTime,
    });

    return {
      ...basePayload,
      startTime: timingState.startTime,
      timezone: timingState.timezone,
      durationInMinutes: timingState.durationInMinutes,
      lastOrderTimeBeforeEndInMinutes,
      prepTimeInMinutes: timingState.prepTimeInMinutes,
    };
  }

  if (actionInfo.hasNotificationEdit) {
    return {
      ...basePayload,
      notificationSentBeforeOrderDueInMinutes: args.notificationSentBeforeOrderDueInMinutes,
    };
  }

  if (actionInfo.hasSharingEdit) {
    const advancedSelectionInput = args.sharingState?.advancedSelectionState
      ? buildAdvancedSelectionInput(args.sharingState.advancedSelectionState)
      : null;
    return {
      ...basePayload,
      advancedSelectionInput,
      teamIds: args.sharingState?.teamIds ?? null,
    };
  }

  return basePayload;
};

export const useOnSaveBulkMutations = (): useOnSaveBulkMutationsPayload => {
  const { setMessage } = useSnackbar();
  const [bulkEditMealMenus, { loading: savingBulkEdit }] = useBulkEditMealMenusMutation({
    onCompleted: data => {
      const EditCount = data.bulkEditMealMenus.mealMenus.length;
      setMessage("success", `Successfully Edited ${EditCount} Meal Menus`);
    },
    onError: () => {
      setMessage("error", "Error occurred while Editing Meal Menus");
    },
  });

  const [bulkDeleteMealMenus, { loading: savingBulkDelete }] = useBulkDeleteMealMenusMutation({
    update: (cache, { data }) => {
      if (data) {
        data.bulkDeleteMealMenus.mealMenuIds.forEach(mealMenuId => {
          const normID = cache.identify({
            id: mealMenuId,
            __typename: "StandaloneMealMenu",
          });
          cache.evict({ id: normID });
        });
        cache.gc();
      }
    },
    onCompleted: data => {
      const deleteCount = data.bulkDeleteMealMenus.mealMenuIds.length;
      setMessage("success", `Successfully Deleted ${deleteCount} Meal Menus`);
    },
    onError: () => {
      //TODO: Should this be more discriptive/include the Apollo error
      setMessage("error", "Error occurred while deleteing Meal Menus");
    },
  });

  const [bulkCopyMealMenus, { loading: savingBulkCopy }] = useBulkCopyMealMenusMutation({
    onCompleted: data => {
      const CopyCount = data.copyMealMenus.newMealMenus.length;
      setMessage("success", `Successfully Copied ${CopyCount} Meal Menus`);
    },
    onError: () => {
      //TODO: Should this be more discriptive/include the Apollo error
      setMessage("error", "Error occurred while Editing Meal Menus");
    },
    // Forces query refetch so cache will reset
    refetchQueries: ["MealMenusInDateRange"],
  });

  const saving = savingBulkEdit || savingBulkDelete || savingBulkCopy;

  const onBulkEdit = async (args: OnBulkEditArgs): Promise<any> => {
    const { actionType } = args;
    if (actionType === "delete") {
      return;
    }
    const bulkEditPayload = getBulkEditPayload(args);

    return bulkEditMealMenus({
      variables: {
        input: bulkEditPayload,
      },
    });
  };

  const onBulkDelete = async (mealMenuIds: string[]) => {
    await bulkDeleteMealMenus({
      variables: {
        input: {
          mealMenuIds,
        },
      },
    });
  };

  const onBulkCopy = async (input: CopyMealMenusInput) => {
    await bulkCopyMealMenus({
      variables: { input },
    });
  };

  return {
    saving,
    onBulkEdit,
    onBulkDelete,
    onBulkCopy,
  };
};
