import { Loading } from "@notemeal/shared-ui";
import { serializeDate } from "@notemeal/utils-date-time";
import { useParams } from "react-router-dom-v5-compat";
import TeamAnthropometryContainer from "../../../../components/views/TeamAnthropometry/Container";
import { useTeamAnthropometryQuery } from "../../../../types";

export const TeamAnthropometryPage = () => {
  const { teamId: maybeTeamId } = useParams();
  const teamId = maybeTeamId || "";

  const { data, loading } = useTeamAnthropometryQuery({
    variables: { id: teamId },
  });

  if (loading || !data || !Object.keys(data).length) {
    return <Loading progressSize="lg" />;
  }
  const team = data.team;
  const athletes = team.athletes;
  const positions = team.sport ? team.sport.positions : [];

  const dt = serializeDate(new Date());
  const exportFilename = `${team.name}_BodyComp_${dt}.xlsx`;

  return (
    <TeamAnthropometryContainer
      exportFilename={exportFilename}
      teamId={teamId}
      positions={positions}
      athletes={athletes}
      athleteSex={team.gender === "Men's" ? "male" : "female"}
    />
  );
};
