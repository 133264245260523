import { Dialog } from "@mui/material";
import { invalidAge } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useState } from "react";
import { AnthropometryEntryFragment, FullGoalFragment, FullMacroProtocolFragment, GoalTypeFragment } from "../../../types";
import MissingDataDialog from "../MissingDataDialog";
import { MacroProtocolEditModalContent } from "./Content";

interface MacroProtocolEditModalProps {
  athleteId: string;
  initialMacroProtocol: FullMacroProtocolFragment;
  mostRecentAnthropometryEntry: AnthropometryEntryFragment | null;
  currentGoal: FullGoalFragment | null;
  athleteBirthdate: string | null;
  goalTypes: readonly GoalTypeFragment[];
  open: boolean;
  onClose: () => void;
}

export const MacroProtocolEditModal = ({
  athleteId,
  initialMacroProtocol,
  mostRecentAnthropometryEntry,
  currentGoal,
  athleteBirthdate,
  goalTypes,
  open,
  onClose,
}: MacroProtocolEditModalProps) => {
  const { leanBodyMass, percentBodyFat, height } = mostRecentAnthropometryEntry || {};
  const incompleteAnthropometry = !(leanBodyMass || percentBodyFat) || !height;
  const [dialogOpen, setDialogOpen] = useState(incompleteAnthropometry || !athleteBirthdate);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Macronutrient Protocol" onClose={onClose} />
      {mostRecentAnthropometryEntry && (
        <MacroProtocolEditModalContent
          currentGoal={currentGoal}
          mostRecentAnthropometryEntry={mostRecentAnthropometryEntry}
          initialMacroProtocol={initialMacroProtocol}
          goalTypes={goalTypes}
          athleteBirthdate={athleteBirthdate}
          onClose={onClose}
        />
      )}
      <MissingDataDialog
        athleteId={athleteId}
        open={dialogOpen}
        onContinue={() => setDialogOpen(false)}
        onClose={onClose}
        noAnthropometry={mostRecentAnthropometryEntry === null}
        noBirthdate={athleteBirthdate === null}
        invalidAge={invalidAge(athleteBirthdate)}
        incompleteAnthropometry={incompleteAnthropometry}
      />
    </Dialog>
  );
};
