import CheckIcon from "@mui/icons-material/Check";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Fab, IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import { ZERO_MACROS, macrosAreEqual } from "@notemeal/shared-utils-macro-protocol";
import classnames from "classnames";
import RecipeCreateDialog from "../../Recipe/RecipeCreateDialog";
import ServingAmountsEditChipList from "../../ServingAmounts/Edit/ChipList";
import { MealOptionMode, MealOptionModeComponentProps } from "./mode";
import { useSuggestions } from "./useSuggestions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: theme.spacing(1.5),
    },
    done: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
    },
  })
);

const MealOptionSuggestionModeComponent = ({
  renderTotals,
  contentClassName,
  totalsClassName,
  onEditServingAmounts,
  targetMacros,
  mealType,
  recipeDialogOpen,
  onCloseRecipeDialog,
  onCreateRecipe,
  toSearchMode,
}: MealOptionModeComponentProps) => {
  const classes = useStyles();
  const unsetMacros = macrosAreEqual(targetMacros, ZERO_MACROS);
  const { suggestedServingAmounts, onNext, onPrevious, isDone } = useSuggestions({
    macros: targetMacros,
    mealType,
    skip: unsetMacros,
  });

  return (
    <>
      <div className={totalsClassName}>{suggestedServingAmounts && renderTotals(suggestedServingAmounts)}</div>

      <div className={classnames(classes.root, contentClassName)}>
        <div className={classes.header}>
          <IconButton onClick={onPrevious} size="small">
            <NavigateBeforeIcon />
          </IconButton>
          <Fab
            size="small"
            onClick={() => {
              if (suggestedServingAmounts) {
                onEditServingAmounts(suggestedServingAmounts);
                toSearchMode();
              }
            }}
            disabled={!suggestedServingAmounts}
          >
            <CheckIcon />
          </Fab>
          <IconButton onClick={onNext} size="small">
            <NavigateNextIcon />
          </IconButton>
        </div>
        {suggestedServingAmounts ? (
          <ServingAmountsEditChipList
            servingAmounts={suggestedServingAmounts}
            onChange={() => {}}
            recipeIngredient={false}
            sx={{ flexGrow: 1, overflowY: "auto" }}
          />
        ) : isDone ? (
          <div className={classes.done}>
            <Typography variant="h3">No More Suggestions</Typography>
          </div>
        ) : unsetMacros ? (
          <div className={classes.done}>
            <Typography variant="h3">Set Macros & Try Again</Typography>
          </div>
        ) : (
          <Loading />
        )}
      </div>
      {recipeDialogOpen && suggestedServingAmounts && (
        <RecipeCreateDialog
          open={recipeDialogOpen}
          onClose={onCloseRecipeDialog}
          onCreate={onCreateRecipe}
          initialIngredients={suggestedServingAmounts}
        />
      )}
    </>
  );
};

export const SuggestionMode: MealOptionMode = {
  name: "Suggestions",
  icon: <EmojiObjectsIcon />,
  component: MealOptionSuggestionModeComponent,
};
