import { Button, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MenuSelectionItemWithAppearance, useMenuSelectionItemEditForm } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { ReactNode } from "react";
import { useSnackbar } from "../Snackbar/SnackbarContext";
import ItemAmountButtons from "../universal/ItemAmountButtons";
import ChoiceCheckboxList from "./ChoiceCheckboxList";
import ChoiceRadioList from "./ChoiceRadioList";
import SpecialRequestsInput from "./SpecialRequests/Input";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "hidden",
    },
    choices: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
    spacer: {
      flexGrow: 1,
    },
    amount: {
      padding: theme.spacing(0, 1),
    },
    footer: {
      padding: theme.spacing(2, 1, 1, 1),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    header: {
      paddingBottom: theme.spacing(),
    },
    buttons: { padding: theme.spacing(2) },
    specialRequests: { padding: theme.spacing(1, 2, 0, 0) },
  })
);

interface MenuSelectionItemEditFormProps {
  maxAmount: number | null; // Result of getMenuItemMaxAmount function, will change based on amount in diningStation
  availableForOrder: boolean;
  allowSpecialRequests: boolean;
  onEdit: (menuSelectionItem: MenuSelectionItemWithAppearance) => void;
  onDone: () => void;
  menuSelectionItem: MenuSelectionItemWithAppearance;
  children?: ReactNode;
}

const MenuSelectionItemEditForm = ({
  onEdit,
  onDone,
  menuSelectionItem,
  availableForOrder,
  allowSpecialRequests,
  maxAmount,
  children,
}: MenuSelectionItemEditFormProps) => {
  const classes = useStyles();
  const { setMessage } = useSnackbar();

  const { menuItem, onIncrement, onDecrement, getMenuItemChoiceOptionCallbacks, onChangeSpecialRequests } = useMenuSelectionItemEditForm({
    menuSelectionItem,
    availableForOrder,
    allowSpecialRequests,
    maxAmount,
    onEdit,
    onError: errors => setMessage("error", errors.join(", ")),
  });

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h3">
          <strong>{menuItem.name}</strong>
        </Typography>
        {menuItem.description && <Typography color="textSecondary">{menuItem.description}</Typography>}
      </div>

      <div className={classes.choices}>
        {sortByKey(menuItem.choices, "position").map(mic => {
          const callbacks = getMenuItemChoiceOptionCallbacks(mic);
          if (callbacks.type === "radio_button") {
            return (
              <ChoiceRadioList
                key={mic.id}
                menuItemChoice={mic}
                onChangeOption={callbacks.onChangeOption}
                onIncrementOption={callbacks.onIncrementOption}
                onDecrementOption={callbacks.onDecrementOption}
              />
            );
          } else {
            return (
              <ChoiceCheckboxList
                key={mic.id}
                menuItemChoice={mic}
                onCheckOption={callbacks.onCheckOption}
                onIncrementOption={callbacks.onIncrementOption}
                onDecrementOption={callbacks.onDecrementOption}
              />
            );
          }
        })}
        {allowSpecialRequests && (
          <SpecialRequestsInput specialRequests={menuSelectionItem.specialRequests} onChange={onChangeSpecialRequests} />
        )}
        {children}
      </div>

      <div className={classes.spacer} />

      <div className={classes.footer}>
        <ItemAmountButtons
          amount={menuSelectionItem.amount}
          minAmount={1}
          maxAmount={maxAmount}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          text={` ${menuItem.servingName}`}
          className={classes.buttons}
        />
        <Button onClick={onDone}>Done</Button>
      </div>
    </div>
  );
};

export default MenuSelectionItemEditForm;
