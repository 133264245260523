import ChevronRight from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment, List, styled, TextField, Typography, useTheme } from "@mui/material";
import { Loading, useDebounce } from "@notemeal/shared-ui";
import { sortByFn } from "@notemeal/utils-sort";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import { Dispatch, useCallback, useMemo, useState } from "react";
import useInfiniteCursorConnectionScroll from "../../../../../components/universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import {
  AthleteForCustomTagFragment,
  NamedTagForSelectionFragment,
  TeamPreviewFragment,
  useAthletesForCustomTagCreateQuery,
  useSelectedAthletesForCustomTagCreateQuery,
} from "../../../../../types";
import { CustomTagAction, CustomTagState } from "../../../reducers/customTagReducers";
import NamedTagSelector from "../../../Selectors/NamedTagSelector";
import TeamSelector from "../../../Selectors/TeamSelector";
import AthleteInfiniteScroll from "./AthleteInfiniteScroll";
import AthleteListItem from "./AthleteListItem";

const ListHeader = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 16px 0px 16px",
}));

const AthleteContainer = styled("div")(
  ({
    theme: {
      spacing,
      palette: { grey },
    },
  }) => ({
    flex: 1,
    height: "calc(100vh - 650px)",
    minHeight: "450px",
    border: `1px solid ${grey[500]}`,
    borderRadius: "5px",
    marginBottom: spacing(2),
  })
);

const athleteListStyle = { overflowY: "auto", height: "calc(100% - 40px)" };
const listItemStyle = { width: `calc(100% - ${1})` };

interface CustomTagContentProps {
  state: CustomTagState;
  dispatch: Dispatch<CustomTagAction>;
}

const CustomTagContent = ({ state, dispatch }: CustomTagContentProps) => {
  const {
    palette: { grey },
  } = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedQuery = useDebounce(searchQuery, 200);
  const [teamsFilter, setTeamsFilter] = useState<TeamPreviewFragment[]>([]);
  const [tagsFilter, setTagsFilter] = useState<NamedTagForSelectionFragment[]>([]);

  const hasFilter = searchQuery !== "" || teamsFilter.length > 0 || tagsFilter.length > 0;
  const resetFilter = () => {
    setSearchQuery("");
    setTeamsFilter([]);
    setTagsFilter([]);
  };

  const tagFilterIds = useMemo(() => {
    return tagsFilter.map(namedTag => namedTag.tag.id);
  }, [tagsFilter]);

  const teamFilterIds = useMemo(() => {
    return teamsFilter.map(({ id }) => id);
  }, [teamsFilter]);

  const selectedAthleteIds = useMemo(() => {
    return state.athletes.map(({ id }) => id);
  }, [state.athletes]);

  const { data, loading } = useSelectedAthletesForCustomTagCreateQuery({
    variables: {
      query: debouncedQuery,
      tagIds: tagFilterIds,
      teamIds: teamFilterIds,
    },
  });

  const searchResults = useInfiniteCursorConnectionScroll({
    useCursorConnectionQuery: useAthletesForCustomTagCreateQuery,
    getQueryVariablesFromPagination: useCallback(
      ({ cursor, limit }) => ({
        variables: {
          pagination: { cursor, limit },
          query: debouncedQuery,
          tagIds: tagFilterIds,
          teamIds: teamFilterIds,
        },
      }),
      [debouncedQuery, tagFilterIds, teamFilterIds]
    ),
    queryKey: "athletesForCustomTagCreationCursorConnection",
    edgesAreEqual: useCallback((athlete1: AthleteForCustomTagFragment, athlete2: AthleteForCustomTagFragment) => {
      return athlete1.id === athlete2.id;
    }, []),
    limit: 25,
  });

  const onClickItem = useCallback(
    (payload: AthleteForCustomTagFragment) => {
      return dispatch({ type: "ADD_ATHLETE_ACTION", payload });
    },
    [dispatch]
  );

  const filteredRHSAthletes = useMemo(() => {
    if (!hasFilter) {
      return sortByFn(state.athletes, ath => `${ath.lastName}, ${ath.firstName}`);
    }
    if (!data || loading) {
      return undefined;
    }
    const possibleAthleteIds = data.athletesForCustomTagCreation.map(({ id }) => id);
    return state.athletes.filter(({ id }) => possibleAthleteIds.includes(id));
  }, [data, loading, hasFilter, state.athletes]);

  const onSelectedAthleteClick = useCallback(
    (payload: AthleteForCustomTagFragment) => dispatch({ type: "REMOVE_ATHLETE_ACTION", payload }),
    [dispatch]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <SectionHeader header="Search for Athletes" />
      <TextField
        sx={{ mt: 1, width: 400 }}
        placeholder="Search for athletes"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
      />
      <Box sx={{ display: "flex", alignItems: "flex-end", my: 2, mx: 0, gap: 2 }}>
        <TeamSelector
          sx={{ mt: 0, width: 280 }}
          selectedTeams={teamsFilter}
          onChange={teams => setTeamsFilter(teams)}
          inputPlaceholder="Teams"
          limitTags={2}
        />
        <NamedTagSelector
          sx={{ mt: 0, width: 280 }}
          selectedNamedTags={tagsFilter}
          onChange={tags => setTagsFilter(tags)}
          limitTags={2} />

        {hasFilter && <Button onClick={() => resetFilter()}>Clear Filters</Button>}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <AthleteContainer>
          <ListHeader>
            <Typography variant="h4">Available Athletes</Typography>
          </ListHeader>
          <AthleteInfiniteScroll
            searchResults={searchResults}
            onClickItem={onClickItem}
            selectedAthleteIds={selectedAthleteIds}
            sx={athleteListStyle}
          />
        </AthleteContainer>
        <ChevronRight fontSize="large" />
        <AthleteContainer>
          <ListHeader sx={{ gap: 1 }}>
            <Typography variant="h4">Selected Athletes ({state.athletes.length})</Typography>
            <Typography variant="body1Medium" sx={{ color: grey[500] }}>
              ({filteredRHSAthletes ? filteredRHSAthletes.length : 0} Results Shown)
            </Typography>
          </ListHeader>

          <List disablePadding sx={athleteListStyle}>
            {filteredRHSAthletes === undefined ? (
              <Loading />
            ) : (
              filteredRHSAthletes.map(athlete => {
                return (
                  <AthleteListItem
                    sx={listItemStyle}
                    athlete={athlete}
                    key={athlete.id}
                    onClick={onSelectedAthleteClick}
                    showRemove />
                );
              })
            )}
          </List>
        </AthleteContainer>
      </Box>
    </Box>
  );
};

export default CustomTagContent;
