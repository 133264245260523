import { ScheduleContainer } from "@notemeal/shared-ui";
import { Activity } from "apps/web/src/components/MealTemplate/Acitivity";
import { EditFullMealPlanFragment } from "apps/web/src/types";
import { EditExchangeMealOptions } from "../../../../components/MealTemplate/Edit/Options/EditExchangeMealOptions";
import { EditMacroMealOptions } from "../../../../components/MealTemplate/Edit/Options/EditMacroMealOptions";
import { useMealPlanSideEffects } from "./utils";

interface MealPlanOptionsProps {
  mealPlan: EditFullMealPlanFragment;
}

export const MealPlanOptions = ({ mealPlan }: MealPlanOptionsProps) => {
  useMealPlanSideEffects(mealPlan, "options");

  const { exchangeSet } = mealPlan;
  if (mealPlan.type === "exchange" && exchangeSet) {
    const mealTemplates = mealPlan.mealTemplates.flatMap(mt => (mt.__typename === "ExchangeMealTemplate" ? mt : []));
    return (
      <ScheduleContainer
        meals={mealTemplates}
        activities={mealPlan.activityTemplates}
        mealToStart={mt => mt.meal.start}
        activityToStart={at => at.activity.start}
        renderMeal={mt => <EditExchangeMealOptions mealTemplate={mt} exchangeSet={exchangeSet} />}
        renderActivity={at => <Activity activity={at.activity} showTime={false} />}
      />
    );
  } else if (mealPlan.type === "macro") {
    const mealTemplates = mealPlan.mealTemplates.flatMap(mt => (mt.__typename === "MacroMealTemplate" ? mt : []));
    return (
      <ScheduleContainer
        meals={mealTemplates}
        activities={mealPlan.activityTemplates}
        mealToStart={mt => mt.meal.start}
        activityToStart={at => at.activity.start}
        renderMeal={mt => <EditMacroMealOptions mealTemplate={mt} />}
        renderActivity={at => <Activity activity={at.activity} showTime={false} />}
      />
    );
  }

  return null;
};
