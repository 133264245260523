import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { formatEventTime } from "@notemeal/utils-date-time";
import { sortByKey } from "@notemeal/utils-sort";
import React from "react";
import { MealPlanCalendarState } from "./types";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    schedule: {
      borderRadius: spacing(0.5),
      padding: spacing(1, 2),
      backgroundColor: grey[100],
      margin: spacing(1, 0),
    },
    scheduleRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: spacing(0.5),
    },
  })
);

interface MealPlanCalendarFormSchedulePreviewProps {
  state: MealPlanCalendarState;
  onEditSchedule: () => void;
}

const MealPlanCalendarFormSchedulePreview = ({ state, onEditSchedule }: MealPlanCalendarFormSchedulePreviewProps) => {
  const classes = useStyles();
  const schedulePreviewTimes: readonly { id: string; name: string; start: string; end: string }[] = sortByKey(
    [...state.mealTemplates.map(mt => mt.meal), ...state.activityTemplates.map(at => at.activity)],
    "start"
  );

  return (
    <div className={classes.schedule}>
      <div className={classes.scheduleRow}>
        <Typography>Meal Plan Schedule</Typography>
        <IconButton size="small" onClick={onEditSchedule}>
          <EditIcon />
        </IconButton>
      </div>
      {schedulePreviewTimes.map(({ id, name, start, end }) => (
        <div key={id} className={classes.scheduleRow}>
          <Typography variant="body2">{name}</Typography>
          <Typography variant="body2">{formatEventTime({ start, end })}</Typography>
        </div>
      ))}
    </div>
  );
};

export default MealPlanCalendarFormSchedulePreview;
