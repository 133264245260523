import { uploadPhotoToAWS } from "@notemeal/shared-ui";
import { environment } from "apps/web/src/environment";
import axios from "axios";
import { Buffer } from "buffer";
import { fileTypeFromBuffer } from "file-type";
import { useState } from "react";
import { TranscribeError } from "../../Recipe/GPT/Transcriber/utils";
import { useSnackbar } from "../../Snackbar/SnackbarContext";

const ERROR_MESSAGE_UPLOADER_INIT =
  "There is an issue with the image uploader. Please refresh the page and try again. If the issue persists, contact customer support.";
const ERROR_MESSAGE_UPLOADING = "There was an issue uploading the photo. Please contact customer support if the issue persists.";

interface useUploadPhotoProps {
  onComplete: (imageUrl: string) => any | Promise<any>;
}

export const useUploadPhoto = ({ onComplete }: useUploadPhotoProps): [(url: string) => Promise<void>, boolean] => {
  const { setMessage } = useSnackbar();
  const uploadUrl = `${environment.VITE_URL_SCHEME}${environment.VITE_SERVER_DOMAIN}/images`;
  if (!uploadUrl) {
    setMessage("error", ERROR_MESSAGE_UPLOADER_INIT);
  }
  const [imageUploading, setImageUploading] = useState(false);
  const uploadPhoto = async (imageUrl: string) => {
    setImageUploading(true);
    try {
      const res = await axios.get(uploadUrl, {
        withCredentials: true,
      });
      const toUpload = await uploadPhotoToAWS({ resData: res.data, imageUrl });

      await onComplete(toUpload);
    } catch (e) {
      console.error({
        type: "misc",
        message: (e as Error).message,
      });
      setMessage("error", "Something went wrong!");
    }
    setImageUploading(false);
  };

  return [uploadPhoto, imageUploading];
};

export const useUploadPhotoForAiQuery = ({ onComplete }: useUploadPhotoProps): [(url: string) => Promise<void>, boolean] => {
  const { setMessage } = useSnackbar();
  const [imageUploading, setImageUploading] = useState(false);

  const uploadUrl = `${environment.VITE_URL_SCHEME}${environment.VITE_SERVER_DOMAIN}/upload-image-for-ai-query`;
  if (!uploadUrl) {
    setMessage("error", ERROR_MESSAGE_UPLOADER_INIT);
  }

  const uploadPhoto = async (imageUrl: string) => {
    setImageUploading(true);

    try {
      // support PNG and JPEG, so we need to do extra work to determine
      // file extensions
      const base64Data = Buffer.from(imageUrl.replace(/^data:image\/\w+;base64,/, ""), "base64");
      const fileType = await fileTypeFromBuffer(base64Data);
      if (!fileType) {
        return;
      }

      const res = await axios.get(uploadUrl, {
        params: {
          imageExt: fileType.ext,
          imageMime: fileType.mime,
        },
        withCredentials: true,
      });
      const toUpload = await uploadPhotoToAWS({ resData: res.data, imageUrl, imageType: fileType });

      await onComplete(toUpload);
      setImageUploading(false);
    } catch (e) {
      console.error({
        type: "misc",
        message: (e as Error).message,
      });
      setImageUploading(false);
      throw new TranscribeError({ name: "UPLOAD_ERROR", message: ERROR_MESSAGE_UPLOADING, cause: e });
    }
  };

  return [uploadPhoto, imageUploading];
};
