import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  AddMenuOrderPayload,
  MenuOrderItemWithAppearance, PickupTime, ServingAmountViewChipList, getAddMenuOrderInputsOrErrors,
  getEditMenuOrderInputsOrErrors, getMealTemplateMacroTargets, getMenuOrderItemsWithAppearance, getPreviewImage, isMealMenuClosed, newId, reduceStatuses, usePickupTimeState
} from "@notemeal/shared-ui";
import MealMacrosAndNutrients from "apps/web/src/components/Macros/MealMacrosAndNutrients";
import MenuOrderForm from "apps/web/src/components/MenuOrder/Form";
import { useContentStyles } from "apps/web/src/components/MenuOrder/useContentStyles";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import classNames from "classnames";
import { Fragment, useState } from "react";
import MenuOrderFormItems from "../../../../components/MenuOrder/Form/Items";
import MenuOrderItemListItem from "../../../../components/MenuOrderItem/ListItem";
import {
  MealMenuDiningStationWithItemPreviewsFragment,
  MealMenuTimelineFragment,
  MealMenuWithTimesFragment,
  MenuOrderTimelineFragment,
  useAddMenuOrderKioskMutation,
  useEditMenuOrderMutation,
  useMealMenuQuery,
  useRemoveMenuOrderMutation,
} from "../../../../types";
import ImageModal from "../../FoodLogs/Images/ImageModal";
import FoodLogPreviewImage from "../../FoodLogs/Images/PreviewImage";
import { useCalendarContext } from "../DateColumn/CalendarContext";
import { addTimelineMealIfNotPresent } from "../cache";
import { useFoodLogAthlete } from "../contexts/useFoodLogAthlete";
import { TimelineMealForModal, getTimelineMealInput, getTimelineMealOverviewCounts, getTimelineMealServingAmounts } from "../utils";
import {
  TimelineMealCart_MenuOrder_Existing,
  TimelineMealCart_MenuOrder_New,
  matchesMenuOrderCart_Existing,
  useTimelineMealModalContext,
} from "./TimelineMealModalContext";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) => {
  const BORDER = `1px solid ${grey[300]}`;
  return createStyles({
    card: { margin: spacing(1), border: BORDER },
    root: {
      padding: spacing(2, 2, 0, 2),
    },
    chipList: {
      overflowY: "auto",
      maxHeight: 152,
    },
    chipListNoImage: {
      overflowY: "auto",
      maxHeight: 228,
      marginBottom: spacing(),
    },
    image: {
      minWidth: "100%",
      maxWidth: "100%",
      maxHeight: 175,
      objectFit: "cover",
      overflow: "hidden",
      margin: spacing(1, 0),
      borderRadius: spacing(1),
      flexShrink: 0,
      cursor: "pointer",
    },

    footerButton: {
      "&:hover": {
        backgroundColor: grey[200],
      },
      transition: "0.3s",
      maxWidth: "100%",
    },
    footerBadge: {
      top: 3,
    },
    actionArea: { padding: spacing(0, 2, 2, 2) },
    list: { padding: spacing(0) },
  });
});

interface TimelineMealOverviewProps {
  timelineMeal: TimelineMealForModal;
  date: string;
  timezone: string;
  onClose: () => void;
  onChangePickupTime: (pickupTime: string) => void;
  onRemoveServingAmount: (id: string) => void;
  onRemoveMealMenuLogItem: (id: string) => void;
}

const TimelineMealOverview = ({
  timelineMeal,
  onClose,
  onChangePickupTime,
  onRemoveServingAmount,
  date,
  timezone,
  onRemoveMealMenuLogItem,
}: TimelineMealOverviewProps) => {
  const classes = useStyles();

  const {
    cart,
    handleSetLogMenuState: setLogMenuState,
    cartFns: { onRemoveMenuOrderItem, changeCart, getAllItems },
    menuState,
    logMenuState,
  } = useTimelineMealModalContext();

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const previewImage = getPreviewImage(timelineMeal.images);

  const counts = getTimelineMealOverviewCounts(timelineMeal);
  // TODO: we could potentially make the number of menus fetched be smaller if we filter for only menus with any orders or logs.
  const { data } = useMealMenuQuery({
    variables: { mealMenuId: cart?.mealMenuId ?? "" },
    skip: cart?.mealMenuId === undefined,
  });

  const cartMealMenu = data?.mealMenu ?? null;

  const allRestaurantMenuLinks = timelineMeal.mealMenus.flatMap(({ restaurantMenuLinks }) => restaurantMenuLinks);

  const findRestaurantMenuLink = (restaurantMenuLinkId: string) => {
    return allRestaurantMenuLinks.find(rml => rml.id === restaurantMenuLinkId);
  };

  const orderList =
    counts.orderCount > 0 ? (
      <List className={classes.list}>
        {timelineMeal.restaurantMenuLinkOrders.map((rmlo, index) => {
          const restaurantMenuLink = findRestaurantMenuLink(rmlo.restaurantMenuLink.id);
          const rmloItems = rmlo.items;

          return (
            rmloItems.length > 0 &&
            restaurantMenuLink && (
              <Fragment key={`${rmlo.id}_${index}`}>
                <Typography>{restaurantMenuLink.restaurant.name}</Typography>
                <List disablePadding>
                  {rmloItems.map((i, index) => (
                    <MenuOrderItemListItem
                      key={i.id}
                      menuOrderItem={i}
                      ListItemProps={{
                        divider: index !== rmloItems.length - 1,
                        disabled: true,
                      }}
                      hidePercentConsumed
                    />
                  ))}
                </List>
              </Fragment>
            )
          );
        })}
        {timelineMeal.restaurantMenuLinkPlateOrders.map((plateOrder, index) => {
          const restaurantMenuLink = findRestaurantMenuLink(plateOrder.restaurantMenuLink.id);
          const rmlpoItems = plateOrder.plate.items;
          return (
            rmlpoItems.length > 0 &&
            restaurantMenuLink && (
              <Fragment key={`${plateOrder.id}_${index}`}>
                <Typography>{restaurantMenuLink.restaurant.name}</Typography>
                <List dense disablePadding>
                  {rmlpoItems.map((i, index) => (
                    <MenuOrderItemListItem
                      key={i.id}
                      menuOrderItem={i}
                      ListItemProps={{
                        divider: index !== rmlpoItems.length - 1,
                        disabled: true,
                      }}
                      hidePercentConsumed
                    />
                  ))}
                </List>
              </Fragment>
            )
          );
        })}
      </List>
    ) : (
      <></>
    );

  const logList = (
    <List>
      <Typography variant="body1">
        <b>Logged Items</b>
      </Typography>
      {timelineMeal.mealMenuLogs.map(
        log =>
          log.items.length > 0 && (
            <Fragment key={log.id}>
              <Typography>{log.mealMenu.name}</Typography>
              <MenuOrderFormItems
                selectedItemId={null}
                onSelectItem={item => setLogMenuState({ type: "editMenuItem", menuOrderItem: item })}
                onRemoveItem={onRemoveMealMenuLogItem}
                menuOrderItems={log.items.map(i => {
                  return {
                    ...i,
                    specialRequests: null,
                    forOrder: false,
                    status: "new",
                    rating: null,
                    menuItemAppearance: { id: newId(), maxAmount: null, availableForOrder: false, allowSpecialRequests: false },
                  };
                })}
                disabled={false}
              />
            </Fragment>
          )
      )}
      {timelineMeal.restaurantMenuLogs.map(log => (
        <List dense disablePadding>
          <Typography>{log.restaurant.name}</Typography>
          {log.items.map((i, index) => (
            <MenuOrderItemListItem
              key={i.id}
              menuOrderItem={{ ...i, specialRequests: null }}
              ListItemProps={{
                divider: index !== log.items.length - 1,
                disabled: true, // needed to make it not behave like a button
                //   className: classes.notDisabledButton, // needed to make it not behave like a button
              }}
              hidePercentConsumed
            />
          ))}
        </List>
      ))}
      {timelineMeal.servingAmounts.length > 0 && (
        <List dense disablePadding>
          <ServingAmountViewChipList servingAmounts={timelineMeal.servingAmounts} onRemoveServing={onRemoveServingAmount} />
        </List>
      )}
    </List>
  );
  const targetMacros = timelineMeal.mealTemplate && getMealTemplateMacroTargets(timelineMeal.mealTemplate);

  const renderNewOrder = () => {
    const isNewOrder = cart?.type === "menuOrder" && cart.orderStatus === "New";
    if (!isNewOrder) {
      return null;
    }
    if (!cartMealMenu) {
      return null;
    }
    const mealMenuDiningStations = cartMealMenu.mealMenuDiningStations;
    return (
      <NewMenuOrder
        key="new-cart"
        timelineMeal={timelineMeal}
        mealMenu={cartMealMenu}
        mealMenuDiningStations={mealMenuDiningStations}
        cart={cart}
        onRemoveItem={itemId => onRemoveMenuOrderItem({ itemId, mealMenuId: cartMealMenu.id, order: null })}
        onClose={onClose}
        date={date}
        timezone={timezone}
      />
    );
  };

  const menuOrderList = timelineMeal.menuOrders.flatMap(existingMenuOrder => {
    const isCartOrder = matchesMenuOrderCart_Existing(existingMenuOrder.mealMenu.id, existingMenuOrder.id, cart);
    // Loading state will default to view only cart
    if (isCartOrder && cartMealMenu) {
      return (
        <ExistingCartMenuOrder
          key={cartMealMenu.id}
          menuOrder={existingMenuOrder}
          mealMenu={cartMealMenu}
          mealMenuDiningStations={cartMealMenu.mealMenuDiningStations}
          cart={cart}
          onChangePickupTime={onChangePickupTime}
          onClose={onClose}
          onRemoveItem={itemId => onRemoveMenuOrderItem({ itemId, mealMenuId: cartMealMenu.id, order: existingMenuOrder })}
        />
      );
    } else {
      const mealMenu = timelineMeal.mealMenus.find(mm => mm.id === existingMenuOrder.mealMenu.id);
      if (!mealMenu) {
        return [];
      }
      return (
        <ExistingMenuOrder
          key={mealMenu.id}
          menuOrder={existingMenuOrder}
          mealMenu={mealMenu}
          changeCart={(mealMenuId, menuOrder) => changeCart(mealMenuId, menuOrder)}
        />
      );
    }
  });

  const menuOrders = timelineMeal.menuOrders.map(order =>
    cart?.orderStatus === "Existing" && cart.order.id === order.id ? { ...order, items: getAllItems() } : order
  );
  const newCartItems = cart?.orderStatus === "New" ? getAllItems() : [];
  const newOrderItem = menuState?.type === "newMenuItem" && menuState.newMenuOrderItem ? [menuState.newMenuOrderItem] : [];
  const newLogItem = logMenuState?.type === "newMenuItem" && logMenuState.newMenuOrderItem ? [logMenuState.newMenuOrderItem] : [];

  const additionalOrderItems = [...newCartItems, ...newOrderItem, ...newLogItem];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ flex: { flexGrow: 1 }, width: "100%" }}>
        {previewImage && (
          <FoodLogPreviewImage
            imageCount={timelineMeal.images.length}
            previewImageUrl={previewImage.url}
            imageClassName={classes.image}
            onClick={() => setImageModalOpen(true)}
          ></FoodLogPreviewImage>
        )}
        {renderNewOrder()}
        {menuOrderList}
        {counts.orderCount > 0 && orderList}
        {counts.logCount > 0 && logList}
      </Box>
      <Divider sx={{ width: "100%" }} />
      <MealMacrosAndNutrients
        mealServingAmounts={getTimelineMealServingAmounts(
          {
            ...timelineMeal,
            menuOrders,
          },
          additionalOrderItems
        )}
        targetMacros={targetMacros}
      />
      {imageModalOpen && <ImageModal
        open={imageModalOpen}
        onClose={() => setImageModalOpen(false)}
        images={timelineMeal.images} />}
    </Box>
  );
};

export default TimelineMealOverview;

interface NewMenuOrderProps {
  timelineMeal: TimelineMealForModal;
  mealMenu: MealMenuWithTimesFragment;
  mealMenuDiningStations: readonly MealMenuDiningStationWithItemPreviewsFragment[];
  onClose: () => void;
  date: string;
  timezone: string;
  cart: TimelineMealCart_MenuOrder_New;
  onRemoveItem: (id: string) => void;
}

const NewMenuOrder = ({
  mealMenuDiningStations,
  mealMenu,
  date,
  timezone,
  onClose,
  cart,
  onRemoveItem,
  timelineMeal,
}: NewMenuOrderProps) => {
  const { menuState, setMenuState } = useTimelineMealModalContext();
  const menuOrderItems = cart.items;
  const contentClasses = useContentStyles();
  const { setMessage } = useSnackbar();
  const athlete = useFoodLogAthlete();

  const allMenuItemAppearances = mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances)!;
  const canOrderItems = allMenuItemAppearances.some(mia => mia.availableForOrder);
  const { onEditDate } = useCalendarContext();
  const [addMenuOrder] = useAddMenuOrderKioskMutation({
    onCompleted: () => setMessage("success", `Order placed!`),
    onError: () => setMessage("error", `Something went wrong!`),
    update: (cache, { data }) => {
      if (data && data.addMenuOrderForAthlete.menuOrder.timelineMeal) {
        addTimelineMealIfNotPresent({
          athleteId: athlete.id,
          date,
          timezone,
          cache,
          timelineMeal: data.addMenuOrderForAthlete.menuOrder.timelineMeal,
        });
        onEditDate();
      }
    },
  });

  // pickup time stuff
  const { pickupTime, setPickupTime, orderPickupTimes, logPickupTimes } = usePickupTimeState({
    mealMenuId: mealMenu.id,
    teamId: athlete.team.id,
    mealMenuStart: mealMenu.start,
    mealMenuDurationInMinutes: mealMenu.durationInMinutes,
    currentMenuOrderPickupTime: null,
    mealMenuPrepTimeInMinutes: mealMenu.prepTimeInMinutes,
  });

  const [doneOnPickupChange, setDoneOnPickupChange] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState<boolean | undefined>(undefined);

  const [fixOrderItemsAlertOpen, setFixOrderItemsAlertOpen] = useState(false);

  const handleAddMenuOrder = (
    { pickupTime, logItems, orderItems }: AddMenuOrderPayload,
    mealMenuId: string,
    mealTemplateId: string | null
  ) => {
    addMenuOrder({
      variables: {
        input: {
          athleteId: athlete.id,
          pickupTime,
          logItems,
          orderItems,
          mealMenuId,
          mealTemplateId,
          items: null,
          images: null,
          timelineMeal: getTimelineMealInput(timelineMeal, pickupTime),
          expectedOrderCount: null,
        },
      },
    });
    onClose();
  };

  const handleSetPickupTime = (pickupTime: PickupTime) => {
    setPickupTime(pickupTime);

    if (doneOnPickupChange) {
      handleDoneWithPickupTime(pickupTime);
    }
  };

  const handleRemoveItem = (id: string) => {
    onRemoveItem(id);
  };

  const handleDone = () => {
    if (pickupTime === null) {
      setOpenTimePicker(!openTimePicker);
      setDoneOnPickupChange(true);
      return;
    }
    handleDoneWithPickupTime(pickupTime);
  };

  const handleDoneWithPickupTime = (pickupTime: PickupTime) => {
    const result = getAddMenuOrderInputsOrErrors(menuOrderItems, mealMenuDiningStations, null, pickupTime);
    if (result.type === "inputs") {
      handleAddMenuOrder(
        {
          pickupTime: result.pickupTime,
          logItems: result.logItems,
          orderItems: result.orderItems,
          images: result.images,
        },
        mealMenu.id,
        timelineMeal.mealTemplate?.id || null
      );
    } else {
      if (result.invalidOrder) {
        setFixOrderItemsAlertOpen(true);
      } else {
        setMessage("error", result.error || "");
      }
    }
  };

  return (
    <div className={contentClasses.order}>
      <MenuOrderForm
        className={contentClasses.orderForm}
        menuOrderItems={menuOrderItems}
        canOrderItems={canOrderItems}
        canLogItems={false}
        onSelectItem={menuOrderItem =>
          setMenuState({
            type: "editMenuItem",
            menuOrderItem,
          })
        }
        selectedItemId={menuState?.type === "editMenuItem" ? menuState.menuOrderItem.id : null}
        onRemoveItem={handleRemoveItem}
        pickupTime={pickupTime}
        onChangePickupTime={handleSetPickupTime}
        orderPickupTimes={orderPickupTimes}
        logPickupTimes={logPickupTimes}
        mealMenuTimezone={mealMenu.timezone}
        menuOrderingClosed={isMealMenuClosed(mealMenu)}
        openTimePicker={openTimePicker}
      />

      {!isMealMenuClosed(mealMenu) && menuOrderItems.length > 0 && (
        <div className={contentClasses.orderButtons}>
          <Button onClick={handleDone} className={contentClasses.orderButton}>
            Place Order
          </Button>
        </div>
      )}

      <Dialog open={fixOrderItemsAlertOpen} onClose={() => setFixOrderItemsAlertOpen(false)}>
        <DialogTitle>Invalid Order</DialogTitle>
        <DialogContent>
          <DialogContentText>Fix the menu order items highlighted in red to proceed</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFixOrderItemsAlertOpen(false)}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

interface ExistingMenuOrderProps {
  menuOrder: MenuOrderTimelineFragment;
  mealMenu: MealMenuTimelineFragment;
  changeCart: (mealMenuId: string, menuOrder: MenuOrderTimelineFragment, existingItems: readonly MenuOrderItemWithAppearance[]) => void;
}

const ExistingMenuOrder = ({ menuOrder, mealMenu, changeCart }: ExistingMenuOrderProps) => {
  const contentClasses = useContentStyles();
  const orderStatus = reduceStatuses(menuOrder.items.map(i => i.status));

  const pickupTime: PickupTime = {
    value: menuOrder.pickupTime,
    type: "Time",
    remaining: null,
  };
  const menuOrderItems = getMenuOrderItemsWithAppearance(menuOrder.items, []);

  const menuOrderingClosed = isMealMenuClosed(mealMenu);

  return (
    <div className={contentClasses.order}>
      {/* TODO: Refactor Can make a better presentation only version of this */}
      <MenuOrderForm
        className={contentClasses.orderForm}
        menuOrderItems={menuOrderItems}
        canOrderItems={false}
        canLogItems={false}
        orderCode={menuOrder.code}
        orderStatus={orderStatus}
        pickupTime={pickupTime}
        orderPickupTimes={[]}
        logPickupTimes={[]}
        mealMenuTimezone={mealMenu.timezone}
        menuOrderingClosed={menuOrderingClosed}
        disableOrderItems
        onSelectItem={() => {}}
        selectedItemId={null}
        onRemoveItem={() => {}}
        onChangePickupTime={() => {}}
      />
      {!menuOrderingClosed && (
        <Button
          variant="text"
          className={classNames(contentClasses.orderButton)}
          onClick={() => {
            changeCart(mealMenu.id, menuOrder, menuOrderItems);
          }}
        >
          Switch to edit order
        </Button>
      )}
    </div>
  );
};

interface ExistingCartMenuOrderProps {
  menuOrder: MenuOrderTimelineFragment;
  mealMenu: MealMenuWithTimesFragment;
  mealMenuDiningStations: readonly MealMenuDiningStationWithItemPreviewsFragment[];
  onChangePickupTime: (pickupTime: string) => void;
  onClose: () => void;
  cart: TimelineMealCart_MenuOrder_Existing;
  onRemoveItem: (id: string) => void;
}

const ExistingCartMenuOrder = ({
  menuOrder,
  mealMenu,
  cart,
  onRemoveItem,
  mealMenuDiningStations,
  onClose,
  onChangePickupTime,
}: ExistingCartMenuOrderProps) => {
  const contentClasses = useContentStyles();
  const {
    cartFns: { getAllItemsWithAppearance, cartHasChanges },
    menuState,
    setMenuState,
    pickupTimeChanged,
    setPickupTimeChanged,
  } = useTimelineMealModalContext();
  const orderChanged = cartHasChanges();
  const { setMessage } = useSnackbar();
  const athlete = useFoodLogAthlete();

  const allMenuItemAppearances = mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances)!;
  const canOrderItems = allMenuItemAppearances.some(mia => mia.availableForOrder);
  const orderStatus = reduceStatuses(menuOrder.items.map(i => i.status));
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);

  const { pickupTime, setPickupTime, orderPickupTimes, logPickupTimes } = usePickupTimeState({
    mealMenuId: mealMenu.id,
    teamId: athlete.team.id,
    mealMenuStart: mealMenu.start,
    mealMenuDurationInMinutes: mealMenu.durationInMinutes,
    currentMenuOrderPickupTime: menuOrder.pickupTime,
    mealMenuPrepTimeInMinutes: mealMenu.prepTimeInMinutes,
  });

  const [fixOrderItemsAlertOpen, setFixOrderItemsAlertOpen] = useState(false);

  const [editMenuOrder] = useEditMenuOrderMutation({
    onCompleted: () => {
      onClose();
      setMessage("success", "Success!");
    },
    onError: () => setMessage("error", "Fail to update your order"),
  });

  const handleClickUpdate = () => {
    const result = getEditMenuOrderInputsOrErrors(
      menuOrder.items,
      getAllItemsWithAppearance(allMenuItemAppearances),
      mealMenuDiningStations,
      cart.editedItems.map(({ id }) => id),
      pickupTime
    );
    if (result.type === "inputs") {
      editMenuOrder({
        variables: {
          input: {
            menuOrderId: menuOrder.id,
            items: null,
            pickupTime: result.pickupTime,
            addOrderItems: result.addOrderItems,
            addLogItems: result.addLogItems, // TODO: clean up LogItems here after a forced update
            editItems: result.editItems,
            removeItemIds: result.removeItemIds,
          },
        },
      });
      onChangePickupTime(result.pickupTime);
    } else {
      if (result.invalidOrder) {
        setFixOrderItemsAlertOpen(true);
      } else {
        setMessage("error", result.error || "");
      }
    }
  };

  const [removeMenuOrder] = useRemoveMenuOrderMutation({
    onCompleted: () => {
      onClose();
      setMessage("success", "Success!");
    },
    onError: () => setMessage("error", "Fail to remove your order"),
    update: (cache, { data }) => {
      if (data) {
        cache.evict({
          id: `MenuOrder:${data.removeMenuOrder.menuOrderId}`,
          broadcast: true,
        });
      }
    },
  });

  const handleRemoveMenuOrder = () => {
    removeMenuOrder({ variables: { input: { menuOrderId: menuOrder.id } } });
  };

  const handleSetPickupTime = (pickupTime: PickupTime) => {
    setPickupTime(pickupTime);
    setPickupTimeChanged(true);
  };

  const handleClickCancel = () => {
    if (orderStatus !== "new") {
      setMessage("error", `Can't cancel. Order is being prepared`);
    } else {
      setCancelOpen(true);
    }
  };

  const handleRemoveItem = (id: string) => {
    // if pre order
    onRemoveItem(id);
  };

  return (
    <div className={contentClasses.order}>
      <MenuOrderForm
        className={contentClasses.orderForm}
        menuOrderItems={getAllItemsWithAppearance(allMenuItemAppearances)}
        canOrderItems={canOrderItems}
        canLogItems={false}
        orderCode={menuOrder.code}
        orderStatus={orderStatus}
        onSelectItem={menuOrderItem =>
          setMenuState({
            type: "editMenuItem",
            menuOrderItem,
          })
        }
        selectedItemId={menuState?.type === "editMenuItem" ? menuState.menuOrderItem.id : null}
        onRemoveItem={handleRemoveItem}
        pickupTime={pickupTime}
        onChangePickupTime={handleSetPickupTime}
        orderPickupTimes={orderPickupTimes}
        logPickupTimes={logPickupTimes}
        mealMenuTimezone={mealMenu.timezone}
        menuOrderingClosed={isMealMenuClosed(mealMenu)}
        forceOrderTitle
      />
      {orderChanged || pickupTimeChanged ? (
        <div className={contentClasses.orderButtons}>
          <Button
            variant="containedDestructive"
            className={contentClasses.orderButtonHalf}
            onClick={handleClickCancel}>
            Cancel
          </Button>
          <Button className={contentClasses.orderButtonHalf} onClick={handleClickUpdate}>
            Update
          </Button>
        </div>
      ) : (
        <Button
          variant="containedDestructive"
          className={contentClasses.orderButton}
          onClick={() => setCancelOpen(true)}>
          Cancel
        </Button>
      )}

      <Dialog open={cancelOpen} onClose={() => setCancelOpen(false)}>
        <DialogTitle>Cancel Order?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setCancelOpen(false)}>
            No
          </Button>
          <Button onClick={() => handleRemoveMenuOrder()}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={fixOrderItemsAlertOpen} onClose={() => setFixOrderItemsAlertOpen(false)}>
        <DialogTitle>Invalid Order</DialogTitle>
        <DialogContent>
          <DialogContentText>Fix the menu order items highlighted in red to proceed</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFixOrderItemsAlertOpen(false)}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
