import { MenuItem, TextField } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import React from "react";
import { CURRENT_RESOURCES } from "../../pages/Auth/Org/EducationResources/utils";

interface UploadedOrArchivedBySelectProps {
  uniqueDates: readonly string[];
  selectedDate: string;
  setSelectedDate: (uploadDate: string) => void;
  viewMode: string;
  handleChangeSelectedDate: (newSelectedDate: string) => void;
}

export const UploadOrArchiveDateSelect = ({
  uniqueDates,
  selectedDate,
  setSelectedDate,
  viewMode,
  handleChangeSelectedDate,
}: UploadedOrArchivedBySelectProps) => {
  const label = viewMode === CURRENT_RESOURCES ? "Uploaded Date" : "Archived Date";

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
    handleChangeSelectedDate(selectedDate);
  };

  const { formatDateWithLocale } = useDateFormatting();

  return (
    <TextField
      sx={{ minWidth: 130 }}
      select
      inputProps={{
        "aria-label": label,
      }}
      value={selectedDate}
      onChange={handleChange}
      label={label}
    >
      <MenuItem value="">No Filter</MenuItem>

      {uniqueDates.map(date => (
        <MenuItem key={date} value={date}>
          {formatDateWithLocale(date)}
        </MenuItem>
      ))}
    </TextField>
  );
};
