import { inputToNumber } from "@notemeal/shared-ui";
import { CalorieBudget } from "@notemeal/shared-utils-macro-protocol";
import { RmrMethod } from "../../../../types";
import { MealPlanTemplateGoalState } from "../Goals/goalsReducer";

export interface CalorieBudgetState {
  rmrMethod: RmrMethod;
  activityFactor: number;
  activityFactorInput: string;
  goals: MealPlanTemplateGoalState[];
  selectedPreviewGoal: MealPlanTemplateGoalState | null;
}

interface EditRMRMethod {
  type: "EDIT_RMR_METHOD";
  payload: RmrMethod;
}

interface EditActivityFactor {
  type: "EDIT_ACTIVITY_FACTOR";
  payload: string;
}

interface OverwriteGoals {
  type: "OVERWRITE_GOALS";
  payload: MealPlanTemplateGoalState[];
}

interface SetSelectedPreviewGoal {
  type: "SET_SELECTED_PREVIEW_GOAL";
  payload: MealPlanTemplateGoalState | null;
}

export type CalorieBudgetAction = EditRMRMethod | EditActivityFactor | SetSelectedPreviewGoal | OverwriteGoals;

export const calorieBudgetReducer = (state: CalorieBudgetState, action: CalorieBudgetAction): CalorieBudgetState => {
  switch (action.type) {
    case "EDIT_RMR_METHOD":
      return { ...state, rmrMethod: action.payload };
    case "EDIT_ACTIVITY_FACTOR":
      return {
        ...state,
        activityFactorInput: action.payload,
        activityFactor: inputToNumber(action.payload) ?? state.activityFactor,
      };
    case "OVERWRITE_GOALS": {
      const newSelectedGoal = action.payload.find(({ id }) => id === state.selectedPreviewGoal?.id);
      return {
        ...state,
        goals: action.payload,
        selectedPreviewGoal: newSelectedGoal ?? null,
      };
    }
    case "SET_SELECTED_PREVIEW_GOAL":
      return {
        ...state,
        selectedPreviewGoal: action.payload,
      };
    default:
      return state;
  }
};

export const getCalorieBudgetFromState = (calorieBudgetState: CalorieBudgetState) => {
  const { rmrMethod, activityFactor, selectedPreviewGoal } = calorieBudgetState;
  const goalKcalOffset = selectedPreviewGoal?.kcalOffset ?? 0;
  const calorieBudget: CalorieBudget = {
    rmrMethod,
    activityFactor,
    goalSnapshot: { kcalOffset: goalKcalOffset },
    kcalOffset: 0,
    rmrCalories: null,
  };

  return calorieBudget;
};
