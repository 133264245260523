import { wsClient } from "./client/apiLink";
import { useState } from "react";
import { useInterval } from "@notemeal/shared-ui";

export enum WsClientStatus {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

const useWsReadyState = () => {
  const [state, setState] = useState<WsClientStatus>(wsClient.status);
  useInterval(() => {
    setState(wsClient.status);
  }, 2500);
  return state;
};

export default useWsReadyState;
