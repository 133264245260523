import { useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom-v5-compat";
import { parseAccessToken, refresh as baseRefresh } from "@notemeal/shared-ui";
import { useCookieContext } from "../contexts/Cookie";
import { clearCookies, getCookie } from "./cookies";
import { NAV_LOGIN } from "../pages/NoAuth/NoAuthPaths";
import { clearReportingUser } from "../reporting/reporting";
import { useStreamChat } from "../contexts/StreamChatContext";
import { useOktaAuth } from "@okta/okta-react";

const ACCESS_TOKEN_SIGNATURE_COOKIE = "accessTokenSignature";
const ACCESS_TOKEN_PAYLOAD_AND_HEADER_COOKIE = "accessTokenPayloadAndHeader";
const REFRESH_TOKEN_SIGNATURE_COOKIE = "refreshTokenSignature";
export const REFRESH_TOKEN_PAYLOAD_AND_HEADER_COOKIE = "refreshTokenPayloadAndHeader";

export const getUser = () => {
  const cookieStr = getCookie(ACCESS_TOKEN_PAYLOAD_AND_HEADER_COOKIE);
  return parseAccessToken(cookieStr);
};

export const useUser = () => {
  const { getCookieValue } = useCookieContext();
  return parseAccessToken(getCookieValue(ACCESS_TOKEN_PAYLOAD_AND_HEADER_COOKIE));
};

export const useLogout = (): (() => void) => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { forceRefresh } = useCookieContext();
  const { client: streamChatClient } = useStreamChat();
  const { oktaAuth } = useOktaAuth();

  return async () => {
    clearReportingUser();
    clearTokenCookies();
    oktaAuth.tokenManager.clear();
    await oktaAuth.signOut();
    forceRefresh();
    client.stop();
    await streamChatClient?.disconnectUser();
    await client.clearStore();
    navigate(NAV_LOGIN);
  };
};

export const refresh = async (orgId?: string) => {
  return (
    baseRefresh({ orgId })
      // Cookies get set automatically, so no extra steps required here
      .catch(e => {
        clearTokenCookies();
        throw e;
      })
  );
};

export const refreshWithAccessTokenCookie = async () => {
  const accessToken = getCookie(ACCESS_TOKEN_PAYLOAD_AND_HEADER_COOKIE);
  const accessTokenPayload = parseAccessToken(accessToken);
  if (accessTokenPayload) {
    return refresh(accessTokenPayload.orgMembership?.org.id);
  }
  return;
};

const clearTokenCookies = () => {
  clearCookies([
    ACCESS_TOKEN_PAYLOAD_AND_HEADER_COOKIE,
    ACCESS_TOKEN_SIGNATURE_COOKIE,
    REFRESH_TOKEN_PAYLOAD_AND_HEADER_COOKIE,
    REFRESH_TOKEN_SIGNATURE_COOKIE,
  ]);
};
