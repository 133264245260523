import { useEffect, useState } from "react";

import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import { parseDate, serializeDate } from "@notemeal/utils-date-time";
import { addDays } from "date-fns";
import { useParams } from "react-router-dom-v5-compat";
import { useKdsPageMealMenusInDateRangeQuery } from "../../../../types";
import KdsPageDialog from "../../../../views/KDS/Dialog";
import { DiningStations } from "../../../../views/KDS/DiningStations";
import MealMenus from "../../../../views/KDS/MealMenus";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export const KDSPage = () => {
  const { date: dateParam } = useParams();
  const today = serializeDate(new Date());
  const date = dateParam || today;
  const classes = useStyles();
  const [timezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const dateObj = parseDate(date);

  const before = serializeDate(addDays(dateObj, 1));
  const after = serializeDate(dateObj);
  const { data, loading } = useKdsPageMealMenusInDateRangeQuery({
    variables: { after, before, timezone },
    pollInterval: 10000,
  });

  const [selectedDiningStationIds, setSelectedDiningStationIds] = useState<string[]>([]);

  //updated selected dining station ids when a dining station is removed from menu
  useEffect(() => {
    const existingDiningStationIds = data?.mealMenusInDateRange.flatMap(mm => mm.mealMenuDiningStations.map(ds => ds.id));
    if (existingDiningStationIds && selectedDiningStationIds.filter(ds => !existingDiningStationIds.includes(ds))) {
      setSelectedDiningStationIds(selectedDiningStationIds.filter(ds => existingDiningStationIds.includes(ds)));
    }
  }, [data]);

  const [selectedMealMenuIds, setSelectedMealMenuIds] = useState<string[]>([]);

  const [screen, setScreen] = useState<"Menus" | "DiningStations" | "KDS">("Menus");

  const onClose = () => {
    setScreen("DiningStations");
  };

  const selectedMealMenus = data?.mealMenusInDateRange.filter(mm => selectedMealMenuIds.includes(mm.id));

  return (
    <div className={classes.root}>
      {!data || loading ? (
        <Loading progressSize="lg" />
      ) : screen === "Menus" ? (
        <MealMenus
          onDone={() => setScreen("DiningStations")}
          date={date}
          mealMenus={data.mealMenusInDateRange}
          selectedMealMenuIds={selectedMealMenuIds}
          setSelectedMealMenuIds={setSelectedMealMenuIds}
        />
      ) : (
        <></>
      )}
      {screen === "DiningStations" && selectedMealMenus && selectedMealMenuIds.length > 0 && (
        <DiningStations
          onBack={() => {
            setSelectedMealMenuIds([]);
            setScreen("Menus");
          }}
          onDone={() => setScreen("KDS")}
          diningStations={selectedMealMenus.flatMap(smm => smm.mealMenuDiningStations)}
          mealMenus={selectedMealMenus}
          setSelectedDiningStationIds={setSelectedDiningStationIds}
          selectedDiningStationIds={selectedDiningStationIds}
        />
      )}
      {screen === "KDS" &&
        selectedMealMenus &&
        selectedMealMenus.length > 0 &&
        (!data || loading ? (
          <Loading progressSize="lg" />
        ) : (
          <KdsPageDialog
            selectedMealMenus={selectedMealMenus}
            selectedDiningStationIds={selectedDiningStationIds}
            mealMenuDiningStations={data.mealMenusInDateRange.flatMap(mm =>
              mm.mealMenuDiningStations.map(ds => ({
                ...ds,
                mealMenuId: mm.id,
              }))
            )}
            onClose={onClose}
          />
        ))}
    </div>
  );
};
