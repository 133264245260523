import { Box, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { ScoreBadge } from "@notemeal/shared-ui";
import { sortByFn } from "@notemeal/utils-sort";
import { ComponentProps, ReactNode } from "react";
import { MenuSelectionItemFormFragment } from "../../types";
import { PercentConsumedDial } from "./PercentConsumedDial";

interface MenuSelectionItemListItemProps {
  menuSelectionItem: MenuSelectionItemFormFragment;
  hidePercentConsumed?: boolean;
  ListItemProps?: ComponentProps<typeof ListItem>;
  children?: ReactNode;
  textChildren?: ReactNode;
}

const MenuSelectionItemListItem = ({
  menuSelectionItem,
  children,
  textChildren,
  ListItemProps: _ListItemProps,
  hidePercentConsumed,
}: MenuSelectionItemListItemProps) => {
  const ListItemProps = _ListItemProps || ({} as ComponentProps<typeof ListItem>);
  const { amount, menuItem } = menuSelectionItem;
  const { name, score, servingName } = menuItem;

  return (
    <ListItem {...ListItemProps}>
      <ListItemIcon sx={{ minWidth: 0, ml: -1, mr: 1.5 }}>
        <Typography color="textPrimary">{amount}</Typography>
      </ListItemIcon>
      <ListItemText disableTypography>
        <Box sx={{ display: "flex", lineHeight: "1em", mb: 0.2 }}>
          <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {name}
          </Typography>
          <ScoreBadge score={score} sx={{ ml: 0.5 }} />
        </Box>
        {servingName && (
          <Typography variant="subtitle1" component="p">
            {servingName}
          </Typography>
        )}

        <Typography
          variant="subtitle1"
          component="p"
          color="textSecondary">
          {sortByFn(menuSelectionItem.options, o => o.menuItemChoiceOption.position)
            .map(o => `${o.menuItemChoiceOption.name}${o.amount === 1 ? "" : ` x${o.amount}`}`)
            .join(", ")}
        </Typography>
        {textChildren}
      </ListItemText>
      {!hidePercentConsumed && (
        <ListItemSecondaryAction>
          <PercentConsumedDial menuSelectionItem={menuSelectionItem} readonly={true} />
        </ListItemSecondaryAction>
      )}
      {children}
    </ListItem>
  );
};

export default MenuSelectionItemListItem;
