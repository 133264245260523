import { formatTimeRangeInTimezone } from "@notemeal/utils-date-time";
import { MenuAttendanceNavDocument, useMenuAttendanceNavQuery } from "apps/web/src/types";
import { addMinutes } from "date-fns";
import React from "react";
import { Route, Routes, useParams } from "react-router-dom-v5-compat";
import { getLink } from "../../../NavUtils";
import { RoleRoute } from "../../ProtectedRoutes";
import { NavLink } from "../../SecondaryNav/BreadCrumbs";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { kitchenLink, menuScheduleLink } from "../Kitchen/KitchenPaths";
import { MenuAttendancePage } from "./MenuAttendancePage";

export const getMenuAttendancePath = (mealMenuId: string) => `/org/menu-attendance/${mealMenuId}`;
export const getMenuAttendanceLink = (mealMenuId: string) => getLink("View Attendance Report", getMenuAttendancePath(mealMenuId));

const getSecondaryNavLayoutProps = (current: NavLink): SecondaryNavLayoutProps => ({
  name: "",
  current,
  breadCrumbs: [kitchenLink, menuScheduleLink],
  tabs: [],
  enableAthleteSearch: false,
});

export const MenuAttendanceRouter = () => {
  const { mealMenuId: maybeMealMenuId } = useParams();
  const mealMenuId = maybeMealMenuId || "";
  let mealMenuName = "";
  let mealMenuFormattedDuration = "";

  const [clientTimezone] = React.useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const { data, loading } = useMenuAttendanceNavQuery({ variables: { mealMenuId } });
  const refetchQueries = [{ query: MenuAttendanceNavDocument, variables: { mealMenuId } }];

  if (!loading && data) {
    const {
      mealMenu: { name, start, durationInMinutes },
    } = data;
    mealMenuFormattedDuration = formatTimeRangeInTimezone(start, durationInMinutes, clientTimezone, {
      excludeTimezoneSuffix: true,
      alwaysShowMinutes: true,
    });
    mealMenuName = name;
  }

  const current = { ...getMenuAttendanceLink(mealMenuId), pageHeader: `${mealMenuName} (${mealMenuFormattedDuration})` };
  const secondaryNavLayoutProps = getSecondaryNavLayoutProps(current);

  const currentTime = new Date();
  const mealMenuEnd = data ? addMinutes(new Date(data.mealMenu.start), data.mealMenu.durationInMinutes) : new Date();
  const mealMenuStart = data ? data.mealMenu.start : new Date();
  const isLive = currentTime.toISOString() < mealMenuEnd.toISOString() && currentTime.toISOString() > mealMenuStart;

  return (
    <Routes>
      <Route element={mealMenuName && mealMenuFormattedDuration && <SecondaryNavLayout {...secondaryNavLayoutProps} />}>
        <Route element={<RoleRoute roles={["dietitian", "foodmanager", "chef", "nonRdAdmin"]} />}>
          <Route
            index
            element={<MenuAttendancePage
              refetchQueries={refetchQueries}
              mealMenuName={mealMenuName}
              isLive={isLive} />} />
        </Route>
      </Route>
    </Routes>
  );
};
