import { Box, Typography } from "@mui/material";
import React from "react";

import { sortByKey } from "@notemeal/utils-sort";
import MenuItemAppearanceGroupControlLabel from "apps/web/src/components/MenuItemAppearance/GroupControlLabel";
import MaxAmountInput from "../../../components/MenuItem/OrderDetails/Input/MaxAmountInput";
import { RestaurantMenuItemAppearancePreview } from "./RestaurantMenuItemAppearance/RestaurantMenuItemAppearancePreview";
import { RestaurantMenuItemAppearanceState, RestaurantMenuSectionLinkState } from "./types";

interface RestauranMenuSectionLinkFormProps {
  restaurantMenuSectionLink: RestaurantMenuSectionLinkState;
  onChangeIncluded: (included: boolean) => void;
  onChangeMaxAmount: (maxAmount: number | null) => void;
  onEditMenuItemAppearance: (args: RestaurantMenuItemAppearanceState) => void;
}

const RestauranMenuSectionLinkForm = ({
  restaurantMenuSectionLink,
  onChangeIncluded,
  onChangeMaxAmount,
  onEditMenuItemAppearance,
}: RestauranMenuSectionLinkFormProps) => {
  const sectionExcluded = restaurantMenuSectionLink.menuItemAppearances.every(mia => !mia.included);

  return (
    <>
      <Box sx={{ py: 2, px: 1, display: "flex", alignItems: "center" }}>
        <Typography variant="h3" sx={sectionExcluded ? { textDecoration: "line-through" } : {}}>
          {restaurantMenuSectionLink.name}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <MaxAmountInput value={restaurantMenuSectionLink.maxAmount} onChange={onChangeMaxAmount} />
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MenuItemAppearanceGroupControlLabel
            menuItemAppearances={restaurantMenuSectionLink.menuItemAppearances}
            field="included"
            text="Included on Menu"
            onChange={onChangeIncluded}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {sortByKey(restaurantMenuSectionLink.menuItemAppearances, "position").map(mia => (
          <RestaurantMenuItemAppearancePreview
            key={mia.menuItem.id}
            sx={{ width: "calc(50% - 16px)", m: 1 }}
            menuItemAppearance={mia}
            onEdit={onEditMenuItemAppearance}
          />
        ))}
      </Box>
    </>
  );
};

export default RestauranMenuSectionLinkForm;
