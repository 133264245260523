import { List, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { serializeDate } from "@notemeal/utils-date-time";
import { genericSort } from "@notemeal/utils-sort";
import React, { useState } from "react";
import { IMatchResult, MatchType } from "../../../../utils/import/match";
import { Entity } from "../../../universal/SearchBar/type";
import { ImportResultListItem } from "./ImportResultListItem";
import ViewImportRowDialog from "./ViewMatchedRow";

interface ImportResultListProps<T, A> {
  matches: IMatchResult<T, A>[];
  selectedMatchIndices: boolean[];
  selectMatchIndex: (idx: number) => void;
  onMatch?: (match: IMatchResult<T, A>, idx: number) => void;
  existingRows?: (T & Entity)[];
  onChange?: (row: T, type: MatchType, newRow: T, idx: number) => void;
  allowShowImportRow?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "inherit",
      // overflowY: "auto",
    },
    wideResults: {
      width: 300,
      height: 400,
    },
    itemWrapper: {
      display: "flex",
    },
  })
);

const valToText = (val: unknown) => {
  if (!!(val as Date) && (val as Date).getMonth && typeof (val as Date).getMonth === "function") {
    return serializeDate(val as Date);
  } else {
    return val;
  }
};

export const matchFieldsToText = <T extends object>(matchFields: (keyof T)[], row: T): string => {
  return matchFields.reduce((curr, field) => `${curr}${valToText(row[field])}, `, "").slice(0, -2);
};

const ImportResultList = <T extends object, A extends object>({
  existingRows,
  matches,
  onMatch,
  selectedMatchIndices,
  selectMatchIndex,
  onChange,
  allowShowImportRow,
}: ImportResultListProps<T, A>) => {
  const classes = useStyles();
  const sortMatches = (a: IMatchResult<T, A>, b: IMatchResult<T, A>) =>
    genericSort(matchFieldsToText(a.matchFields, b.row), matchFieldsToText(b.matchFields, b.row));
  const [matchForPeekImportRow, setMatchForPeekImportRow] = useState<[IMatchResult<T, A>, number] | null>(null);

  const handleChange = (row: T, type: MatchType, newRow: T) => {
    onChange && matchForPeekImportRow && onChange(row, type, newRow, matchForPeekImportRow[1]);
    setMatchForPeekImportRow(null);
  };

  return (
    <>
      <List className={classes.root}>
        {matches.sort(sortMatches).map((match, index) => (
          <ImportResultListItem
            key={index}
            index={index}
            match={match}
            selected={selectedMatchIndices[index] || false}
            selectMatchIndex={() => selectMatchIndex(index)}
            onMatch={onMatch ? (newMatch, i) => onMatch(newMatch, i) : undefined}
            existingRows={existingRows}
            allowShowImportRow={allowShowImportRow}
            onMatchForPeekImportRow={() => setMatchForPeekImportRow([match, index])}
          />
        ))}
      </List>
      {matchForPeekImportRow && (
        <ViewImportRowDialog
          open={!!matchForPeekImportRow}
          onClose={() => setMatchForPeekImportRow(null)}
          match={matchForPeekImportRow[0]}
          onChange={handleChange}
        ></ViewImportRowDialog>
      )}
    </>
  );
};

export default ImportResultList;
