import { ClientTimezoneContextProvider } from "@notemeal/shared-ui";
import { Route, Routes } from "react-router-dom-v5-compat";
import { useUser } from "../../../../utils/tokens";
import { getOrgUserDetails } from "../../OrgUtils";
import { RoleRoute } from "../../ProtectedRoutes";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { DiningStationTemplatesPage } from "./DiningStationTemplatesPage";
import { KDSPage } from "./KdsPage";
import { KioskPage } from "./KioskPage";
import {
    NAV_KITCHEN_DINING_STATION_TEMPLATES,
    NAV_KITCHEN_KDS,
    NAV_KITCHEN_KIOSK,
    NAV_KITCHEN_MENU_BUILDER,
    NAV_KITCHEN_MENU_ITEMS,
    NAV_KITCHEN_MENU_SCHEDULE,
    kitchenLink,
} from "./KitchenPaths";
import { MenuItemsPage } from "./MenuItemsPage";
import { MenuSchedulePage } from "./MenuSchedulePage";
import { PlannedMenuPage } from "./PlannedMenuPage";

const getSecondaryNavLayoutProps = (): SecondaryNavLayoutProps => ({
  current: kitchenLink,
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Menu Schedule",
      to: NAV_KITCHEN_MENU_SCHEDULE,
      allowedRoles: ["chef"],
    },
    {
      name: "Menu Builder",
      to: NAV_KITCHEN_MENU_BUILDER,
      allowedFeatureFlag: "performanceKitchen",
      allowedRoles: ["chef"],
    },
    {
      name: "Menu Items",
      to: NAV_KITCHEN_MENU_ITEMS,
      allowedRoles: ["chef"],
    },
    {
      name: "Dining Station Templates",
      to: NAV_KITCHEN_DINING_STATION_TEMPLATES,
      allowedRoles: ["chef"],
    },
    {
      name: "KDS",
      to: NAV_KITCHEN_KDS,
      allowedRoles: ["chef"],
    },
    {
      name: "Kiosk Configuration",
      to: NAV_KITCHEN_KIOSK,
      allowedRoles: ["dietitian", "kiosk", "nonRdAdmin"],
    },
  ],
});

export const KitchenRouter = () => {
  const { hasRoleDietitian, hasRoleChef, hasRoleKiosk } = getOrgUserDetails(useUser());
  const onlyKiosk = !hasRoleChef && (hasRoleDietitian || hasRoleKiosk);

  return (
    <ClientTimezoneContextProvider>
      <Routes>
        <Route element={<SecondaryNavLayout {...getSecondaryNavLayoutProps()} />}>
          <Route element={<RoleRoute roles={["dietitian", "chef", "kiosk", "nonRdAdmin"]} />}>
            <Route index element={onlyKiosk ? <KioskPage /> : <MenuSchedulePage />} />
            <Route path={`${NAV_KITCHEN_MENU_SCHEDULE}/:date?`} element={<MenuSchedulePage />} />
            <Route path={`${NAV_KITCHEN_MENU_SCHEDULE}/:date?/:importId?`} element={<MenuSchedulePage />} />
            <Route path={NAV_KITCHEN_MENU_BUILDER} element={<PlannedMenuPage />} />
            <Route path={NAV_KITCHEN_MENU_ITEMS} element={<MenuItemsPage />} />
            <Route path={NAV_KITCHEN_DINING_STATION_TEMPLATES} element={<DiningStationTemplatesPage />} />
            <Route path={`${NAV_KITCHEN_KDS}/:date?`} element={<KDSPage />} />
            <Route path={`${NAV_KITCHEN_KIOSK}/:date?`} element={<KioskPage />} />
            <Route path={`${NAV_KITCHEN_KIOSK}/:date/:menuIds`} element={<KioskPage />} />
          </Route>
        </Route>
      </Routes>
    </ClientTimezoneContextProvider>
  );
};
