import { useState } from "react";
import {
    KdsPageMealMenuPlateOrderPreviewFragment,
    KdsPageMenuOrderPreviewFragment,
    KdsTicketMealMenuPlateOrderFragment,
    KdsTicketMenuOrderFragment,
    MenuOrderItemStatus,
} from "../../types";
//import ReactList from 'react-list';
import AnalyzeDialog from "./AnalyzeDialog";
import ChangeStatusDialog from "./ChangeStatusDialog";
import PrintAllDialog from "./PrintAllDialog";

import { reduceStatuses } from "@notemeal/shared-ui";
import ListSlider from "../../components/universal/ListSlider";
import BulkSelectDialog from "./BulkSelectDialog";
import KdsTicket from "./Ticket";
import PlateOrderKDSTicket from "./Ticket/PlateOrderKDSTicket";
import KdsTicketSummary from "./Ticket/Summary";
import { GenericOrderPreview } from "./Ticket/types";
import { MakeHandleAirPrintFn, PrintMethod, WebPrinterTicketOrder } from "./types";
import { sortMenuOrders } from "./utils";

interface KdsDialogContentProps {
  updateOrderStatus: (order: KdsTicketMenuOrderFragment, status: MenuOrderItemStatus, update?: () => void) => void;
  updatePlateOrderStatus: (plateOrder: KdsTicketMealMenuPlateOrderFragment, status: MenuOrderItemStatus, update?: () => void) => void;
  updateMultipleOrderOrPlateOrderStatuses: (
    orders: KdsTicketMenuOrderFragment[],
    plateOrders: KdsTicketMealMenuPlateOrderFragment[],
    status: MenuOrderItemStatus,
    update?: () => void
  ) => void;
  diningStationIds: string[];
  mealMenuIds: string[];
  menuOrders: readonly KdsPageMenuOrderPreviewFragment[];
  unfilteredMenuOrders: readonly KdsPageMenuOrderPreviewFragment[];
  mealMenuPlateOrders: readonly KdsPageMealMenuPlateOrderPreviewFragment[];
  unfilteredMealMenuPlateOrders: readonly KdsPageMealMenuPlateOrderPreviewFragment[];
  menuOrderItemIdsToDiningStationName: { [menuOrderItemId: string]: string };
  makeHandleAirPrintFn: MakeHandleAirPrintFn;
  printMethod: PrintMethod;
  handleWebPrintOrder?: (ticketOrder: WebPrinterTicketOrder) => Promise<void>;
}

const KdsDialogContent = ({
  makeHandleAirPrintFn,
  handleWebPrintOrder,
  printMethod,
  updateOrderStatus,
  updatePlateOrderStatus,
  updateMultipleOrderOrPlateOrderStatuses,
  mealMenuIds,
  diningStationIds,
  menuOrders,
  unfilteredMenuOrders,
  mealMenuPlateOrders,
  unfilteredMealMenuPlateOrders,
  menuOrderItemIdsToDiningStationName,
}: KdsDialogContentProps) => {
  //const classes = useStyles();
  const [selectedWebPrinterTicketOrder, setSelectedWebPrinterTicketOrder] = useState<null | WebPrinterTicketOrder>(null);
  const [printAll, setPrintAll] = useState(false);
  const [displayMenuItemDialog, setDisplayMenuItemDialog] = useState(false);

  const [isBulkSelect, setIsBulkSelect] = useState<boolean>(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState<Set<string>>(new Set([]));

  const [selectedPlateOrderIds, setSelectedPlateOrderIds] = useState<Set<string>>(new Set([]));

  const [isBulkSelectDialogOpen, setIsBulkSelectDialogOpen] = useState<boolean>(false);

  const [statusWhitelist, setStatusWhitelist] = useState<MenuOrderItemStatus[]>(["new", "cooking"]);
  const handleCancelOrder = (order: KdsTicketMenuOrderFragment) => updateOrderStatus(order, "cancelled");
  const handleCompleteOrder = (order: KdsTicketMenuOrderFragment) => updateOrderStatus(order, "done");
  const handleCookOrder = (order: KdsTicketMenuOrderFragment) => updateOrderStatus(order, "cooking");
  const handleMarkOrderNew = (order: KdsTicketMenuOrderFragment) => updateOrderStatus(order, "new");

  const handleCancelPlateOrder = (order: KdsTicketMealMenuPlateOrderFragment) => updatePlateOrderStatus(order, "cancelled");
  const handleCompletePlateOrder = (order: KdsTicketMealMenuPlateOrderFragment) => updatePlateOrderStatus(order, "done");
  const handleCookPlateOrder = (order: KdsTicketMealMenuPlateOrderFragment) => updatePlateOrderStatus(order, "cooking");
  const handleMarkPlateOrderNew = (order: KdsTicketMealMenuPlateOrderFragment) => updatePlateOrderStatus(order, "new");

  //BulkSelectFunctions
  const handleCompleteOrders = (orders: KdsTicketMenuOrderFragment[], plateOrders: KdsTicketMealMenuPlateOrderFragment[]) =>
    updateMultipleOrderOrPlateOrderStatuses(orders, plateOrders, "done");
  const handleCookOrders = (orders: KdsTicketMenuOrderFragment[], plateOrders: KdsTicketMealMenuPlateOrderFragment[]) =>
    updateMultipleOrderOrPlateOrderStatuses(orders, plateOrders, "cooking");
  const handleMarkOrdersNew = (orders: KdsTicketMenuOrderFragment[], plateOrders: KdsTicketMealMenuPlateOrderFragment[]) =>
    updateMultipleOrderOrPlateOrderStatuses(orders, plateOrders, "new");
  const handleCancelOrders = (orders: KdsTicketMenuOrderFragment[], plateOrders: KdsTicketMealMenuPlateOrderFragment[]) =>
    updateMultipleOrderOrPlateOrderStatuses(orders, plateOrders, "cancelled");

  const handleActivateBulkSelect = () => {
    setIsBulkSelect(true);
    setSelectedOrderIds(new Set([]));
    setSelectedPlateOrderIds(new Set([]));
  };

  const handleCancelBulkSelect = () => {
    setIsBulkSelect(false);
    setSelectedOrderIds(new Set([]));
    setSelectedPlateOrderIds(new Set([]));
  };

  const handleProceedBulkSelect = () => {
    setIsBulkSelectDialogOpen(true);
  };

  const handleDeselectAll = () => {
    setSelectedOrderIds(new Set([]));
    setSelectedPlateOrderIds(new Set([]));
  };

  const handleSelectForBulkSelect = (orderId: string, value: boolean) => {
    if (value) {
      setSelectedOrderIds(new Set([...selectedOrderIds, orderId]));
    } else {
      setSelectedOrderIds(new Set([...selectedOrderIds].filter(id => id !== orderId)));
    }
  };
  const handleSelectPlateForBulkSelect = (plateOrderId: string, value: boolean) => {
    if (value) {
      setSelectedPlateOrderIds(new Set([...selectedPlateOrderIds, plateOrderId]));
    } else {
      setSelectedPlateOrderIds(new Set([...selectedPlateOrderIds].filter(id => id !== plateOrderId)));
    }
  };

  const handlePrintOrders = async (sortedOrders: WebPrinterTicketOrder[]) => {
    if (handleWebPrintOrder && printMethod === "WebPrint") {
      sortedOrders.forEach(async o => {
        await handleWebPrintOrder(o);
      });
    }
  };

  const sortedOrders = sortMenuOrders([...menuOrders, ...mealMenuPlateOrders]);
  const sortedAndFilteredOrders = sortedOrders.filter(o =>
    statusWhitelist.includes(reduceStatuses(o.itemsByDiningStation.map(oo => oo.status)))
  );

  const handleSelectAllShownOrders = () => {
    const shownOrderIds = sortedAndFilteredOrders.filter(o => o.__typename === "MenuOrder").map(order => order.id);
    const shownPlateOrderIds = sortedAndFilteredOrders.filter(o => o.__typename === "MealMenuPlateOrder").map(order => order.id);
    setSelectedOrderIds(new Set([...selectedOrderIds, ...shownOrderIds]));
    setSelectedPlateOrderIds(new Set([...selectedPlateOrderIds, ...shownPlateOrderIds]));
  };

  const renderItem = (order: GenericOrderPreview, index: number) => {
    if (index === 0) {
      return (
        <KdsTicketSummary
          key="first-slide"
          statusWhitelist={statusWhitelist}
          setStatusWhitelist={setStatusWhitelist}
          sortedOrders={[...sortedOrders]}
          setPrintAll={setPrintAll}
          setDisplayMenuItemDialog={setDisplayMenuItemDialog}
          inBulkSelect={isBulkSelect}
          activateBulkSelect={handleActivateBulkSelect}
          proceedBulkSelect={handleProceedBulkSelect}
          selectedOrderIds={[...selectedOrderIds]}
          selectedPlateOrderIds={[...selectedPlateOrderIds]}
          cancelBulkSelect={handleCancelBulkSelect}
          selectAllShownOrders={handleSelectAllShownOrders}
          deselectAll={handleDeselectAll}
        />
      );
    }
    if (order.__typename === "MenuOrder") {
      return (
        <KdsTicket
          key={order.id}
          orderPreview={order}
          diningStationIds={diningStationIds}
          setSelectedWebPrinterTicketOrder={setSelectedWebPrinterTicketOrder}
          menuOrderItemIdsToDiningStationName={menuOrderItemIdsToDiningStationName}
          inBulkSelect={isBulkSelect}
          selectForBulkSelect={handleSelectForBulkSelect}
          isSelected={selectedOrderIds.has(order.id)}
        />
      );
    }

    return (
      <PlateOrderKDSTicket
        key={order.id}
        orderPreview={order}
        diningStationIds={diningStationIds}
        setSelectedWebPrinterTicketOrder={setSelectedWebPrinterTicketOrder}
        menuOrderItemIdsToDiningStationName={menuOrderItemIdsToDiningStationName}
        inBulkSelect={isBulkSelect}
        selectForBulkSelect={handleSelectPlateForBulkSelect}
        isSelected={selectedPlateOrderIds.has(order.id)}
      />
    );
  };
  return (
    <>
      <ListSlider
        items={[sortedAndFilteredOrders[0], ...sortedAndFilteredOrders]}
        renderItem={renderItem}
        initialIndex={0} />
      {isBulkSelectDialogOpen && (
        <BulkSelectDialog
          menuOrderItemIdsToDiningStationName={menuOrderItemIdsToDiningStationName}
          sortedOrderPreviews={[...sortMenuOrders(unfilteredMenuOrders.filter(order => selectedOrderIds.has(order.id)))]}
          sortedPlateOrderPreviews={[
            ...sortMenuOrders(unfilteredMealMenuPlateOrders.filter(plateOrder => selectedPlateOrderIds.has(plateOrder.id))),
          ]}
          diningStationIds={diningStationIds}
          onClose={() => setIsBulkSelectDialogOpen(false)}
          handleCompleteOrders={handleCompleteOrders}
          handleCookOrders={handleCookOrders}
          handleMarkOrdersNew={handleMarkOrdersNew}
          handleCancelOrders={handleCancelOrders}
          handleWebPrintMany={handlePrintOrders}
          makeHandleAirPrintMany={makeHandleAirPrintFn}
          printMethod={printMethod}
        />
      )}
      {selectedWebPrinterTicketOrder && (
        <ChangeStatusDialog
          order={selectedWebPrinterTicketOrder}
          onClose={() => setSelectedWebPrinterTicketOrder(null)}
          handleWebPrintOrder={handleWebPrintOrder}
          makeHandleAirPrintFn={makeHandleAirPrintFn}
          printMethod={printMethod}
          handleCancelOrder={handleCancelOrder}
          handleCookOrder={handleCookOrder}
          handleCompleteOrder={handleCompleteOrder}
          handleMarkOrderNew={handleMarkOrderNew}
          handleCancelPlateOrder={handleCancelPlateOrder}
          handleCompletePlateOrder={handleCompletePlateOrder}
          handleCookPlateOrder={handleCookPlateOrder}
          handleMarkPlateOrderNew={handleMarkPlateOrderNew}
        />
      )}
      {printAll && (
        <PrintAllDialog
          menuOrderItemIdsToDiningStationName={menuOrderItemIdsToDiningStationName}
          handleWebPrintMany={handlePrintOrders}
          makeHandleAirPrintMany={makeHandleAirPrintFn}
          printMethod={printMethod}
          diningStationIds={diningStationIds}
          mealMenuIds={mealMenuIds}
          sortedAndFilteredOrders={sortedAndFilteredOrders}
          onClose={() => setPrintAll(false)}
          open={printAll}
        />
      )}
      {displayMenuItemDialog && (
        <AnalyzeDialog
          diningStationIds={diningStationIds}
          mealMenuIds={mealMenuIds}
          sortedAndFilteredOrders={sortedAndFilteredOrders}
          onClose={() => setDisplayMenuItemDialog(false)}
          open={displayMenuItemDialog}
        />
      )}
    </>
  );
};

export default KdsDialogContent;
