import { Box, Typography } from "@mui/material";

import { ExchangeSummaryLabel, ExportedMealItemContainer, ExportedSnackItemContainer, MacrosSummaryLabel } from "@notemeal/shared-ui";
import ExportMealOption from "./Option";

import { formatEventTime } from "@notemeal/utils-date-time";
import { isExchange, isMacro, Orientation } from "../utils";

import { getExchangeAmountsMacros } from "@notemeal/shared-ui";
import { initMacros } from "@notemeal/shared-utils-macro-protocol";
import { FullMealTemplateFragment, RecipeWithServingsFragment } from "../../../../types";

export interface ExportMealRowProps {
  mealTemplate: FullMealTemplateFragment;
  displayMacros: boolean;
  displayMealOptions: boolean;
  displayExchanges: boolean;
  expandRecipes: boolean;
  orientation: Orientation;
  displayMealOptionMacros: boolean;
  recipes: readonly RecipeWithServingsFragment[];
}

const ExportMealRow = ({
  orientation,
  mealTemplate,
  expandRecipes,
  displayMealOptions,
  displayMacros,
  displayExchanges,
  displayMealOptionMacros,
  recipes,
}: ExportMealRowProps) => {
  const mealOptions = displayMealOptions ? mealTemplate.mealOptions : [];
  const Container = mealTemplate.meal.type === "snack" ? ExportedSnackItemContainer : ExportedMealItemContainer;
  const macros =
    mealTemplate && isMacro(mealTemplate) ? initMacros(mealTemplate.cho || 0, mealTemplate.pro || 0, mealTemplate.fat || 0) : null;

  const showMacroSummary = displayMacros && macros;
  const showExchangeSummary = displayExchanges && isExchange(mealTemplate);
  const showExchangeMacroSummary = displayMacros && isExchange(mealTemplate) && mealTemplate.exchangeTargets;

  return (
    <Container>
      <Box sx={{ width: "100%", lineHeight: 0.6, overflow: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography
            sx={{ lineHeight: 1 }}
            variant="body2Medium"
            color="inherit">
            {mealTemplate.meal.name || ""}
          </Typography>
          <Typography variant="subtitle1" sx={{ pl: 1, lineHeight: 1 }}>
            ({formatEventTime(mealTemplate.meal)})
          </Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", lineHeight: 0.8 }}>
          {showExchangeSummary && (
            <ExchangeSummaryLabel
              consolidatePro
              variant="xs"
              targetExchangeAmounts={mealTemplate.exchangeTargets || []} />
          )}
          {showExchangeSummary && (showMacroSummary || showExchangeMacroSummary) && (
            <Typography sx={{ px: 1, fontSize: ".7rem" }}>{" | "}</Typography>
          )}
          {showMacroSummary && <MacrosSummaryLabel
            variant="xs"
            macros={macros}
            kcalPrefix />}
          {showExchangeMacroSummary && (
            <MacrosSummaryLabel
              kcalPrefix
              variant="xs"
              macros={getExchangeAmountsMacros(mealTemplate.exchangeTargets)} />
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexFlow: "row wrap" }}>
        {mealOptions
          .filter(mealOption => mealOption.servingAmounts.length > 0)
          .map((mealOption, idx) => (
            <Box key={mealOption.id} sx={{ justifySelf: "center", width: orientation === "portrait" ? "33.33%" : "20%" }}>
              <ExportMealOption
                index={idx}
                displayMacros={displayMealOptionMacros}
                recipes={recipes}
                mealOption={mealOption}
                expandRecipes={expandRecipes}
              />
            </Box>
          ))}
      </Box>
    </Container>
  );
};

export default ExportMealRow;
