import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    horizontalContainer: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: spacing(1),
    },
    editButton: {
      marginLeft: spacing(1),
    },
  })
);

interface EditSectionHeaderProps {
  title: string;
  showEdit: boolean;
  icon?: JSX.Element;
  onEdit: () => void;
}

export const EditSectionHeader = ({ title, showEdit, icon, onEdit }: EditSectionHeaderProps) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.horizontalContainer}>
        <Typography variant="h4">
          {title}
          {icon}
        </Typography>
        {showEdit && (
          <IconButton
            className={classes.editButton}
            onClick={onEdit}
            size="small">
            <EditIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};
