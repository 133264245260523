import { Card, TextField } from "@mui/material";
import { inputToNumber, sortExchanges } from "@notemeal/shared-ui";
import { memo } from "react";
import {
    EditExchangeAmountFragment,
    EditFullExchangeMealTemplateFragment,
    FullExchangeSetFragment,
    useChangeExchangeTargetTotalMutation,
} from "../../../../types";
import { makeExchangeTargetsById } from "./utils";

interface MealPlanEditExchangeTargetsProps {
  mealPlanId: string;
  exchangeSet: FullExchangeSetFragment;
  exchangeTargets: readonly EditExchangeAmountFragment[] | null;
  mealTemplates: readonly EditFullExchangeMealTemplateFragment[];
}

export const MealPlanEditExchangeTargets = memo(
  ({ mealPlanId, exchangeSet, exchangeTargets, mealTemplates }: MealPlanEditExchangeTargetsProps) => {
    const exchanges = exchangeSet.exchanges;
    const exchangeTargetsById = makeExchangeTargetsById(exchanges, exchangeTargets);
    const [changeExchangeTargetTotal] = useChangeExchangeTargetTotalMutation();

    return (
      <Card
        sx={{
          pl: 3,
          pr: 11,
          backgroundColor: "greyscale.25",
          borderColor: "greyscale.50",
          minHeight: "96px",
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        {sortExchanges(exchanges).map(exchange => {
          const { amountInput } = exchangeTargetsById[exchange.id];
          const mealTemplatesAmount = mealTemplates.reduce((amount: number, mt) => {
            const matchingExchangeTarget = mt.exchangeTargets && mt.exchangeTargets.find(ea => ea.exchange.id === exchange.id);
            return amount + (matchingExchangeTarget ? matchingExchangeTarget.amount : 0);
          }, 0);
          return (
            <TextField
              sx={{ flex: 1 }}
              key={exchange.id}
              type="number"
              label={exchange.shortName}
              value={amountInput}
              helperText={`Current: ${mealTemplatesAmount}/${amountInput}`}
              error={inputToNumber(amountInput) !== mealTemplatesAmount}
              inputProps={{ style: { textAlign: "center" }, min: 0, step: 0.5 }}
              onChange={e =>
                changeExchangeTargetTotal({
                  variables: {
                    mealPlanId,
                    exchangeId: exchange.id,
                    target: e.target.value,
                  },
                })
              }
            />
          );
        })}
      </Card>
    );
  }
);
