import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { formatAmbiguousServingAmountWithTotalWeight, formatServing } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { getServingAmountsWithConversions } from "../../../components/Serving/getServingAmountsWithConversions";
import { groupServingAmountsWithConversions } from "../../../components/Serving/groupServingAmountsWithConversions";
import { ServingAmountForConversionFragment, UnitConversionFullFragment } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

interface MenuIngredientsAnalyzerGroupedServingAmountListProps {
  servingAmounts: readonly ServingAmountForConversionFragment[];
  unitConversions: readonly UnitConversionFullFragment[];
}

const MenuIngredientsAnalyzerGroupedServingAmountList = ({
  servingAmounts,
  unitConversions,
}: MenuIngredientsAnalyzerGroupedServingAmountListProps) => {
  const classes = useStyles();
  const groupedServingAmounts = groupServingAmountsWithConversions(
    getServingAmountsWithConversions({
      servingAmounts,
      unitConversions,
    })
  );

  return (
    <>
      {sortByKey(groupedServingAmounts, "position").map(sa =>
        sa.__typename === "ServingAmount" ? (
          <Typography key={sa.id} variant="body2">
            {formatAmbiguousServingAmountWithTotalWeight(sa)} {sa.serving.foodOrRecipe.name}
          </Typography>
        ) : (
          <Typography
            key={sa.servingId}
            className={classes.root}
            variant="body2">
            {sa.conversionAmounts
              // TODO: No weight on conversionAmounts not sure what this is
              .map(csa => formatServing(csa.amount, `${sa.unitPrefix || ""}${csa.toUnit.name}${sa.unitSuffix || ""}`))
              .join(", ")}{" "}
            {sa.foodOrRecipe.name}
          </Typography>
        )
      )}
    </>
  );
};

export default MenuIngredientsAnalyzerGroupedServingAmountList;
