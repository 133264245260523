import { Reference, ApolloCache } from "@apollo/client";
import { useEffect } from "react";
import { MarkNotificationsAsViewedMutation, useMarkNotificationsAsViewedMutation } from "../../types";

export const onCompleteNotifications = (cache: ApolloCache<object>, data: MarkNotificationsAsViewedMutation | null | undefined) => {
  if (!data) {
    return;
  }
  const removeNotificationRefs = data.markNotificationsAsViewed.notifications.map(n => `Notification:${n.id}`);
  const removeNotificationRefsAndIds = removeNotificationRefs.concat(data.markNotificationsAsViewed.notifications.map(n => n.id));
  const modifyNotifications = (notifications: { id: string; __ref: string }[]) => {
    return notifications.filter(({ id, __ref }) => {
      const idOrRef = id || (__ref && __ref.split(":").length === 2 ? __ref.split(":")[1] : "");
      return !(removeNotificationRefsAndIds.includes(idOrRef) || removeNotificationRefsAndIds.includes(__ref));
    });
  };

  cache.modify({
    fields: {
      teams: (teams: Reference[]): Reference[] => {
        return teams.map(team => {
          cache.modify({
            id: team.__ref,
            fields: {
              notifications: modifyNotifications,
              recentNotifications: modifyNotifications,
            },
          });
          return team;
        });
      },
      team: (team: Reference) => {
        cache.modify({
          id: team.__ref,
          fields: {
            athletes: (athletes: Reference[]): Reference[] => {
              return athletes.map(athlete => {
                cache.modify({
                  id: athlete.__ref,
                  fields: {
                    timelineMealNotifications: modifyNotifications,
                    mealPlanNotifications: modifyNotifications,
                  },
                });
                return athlete;
              });
            },
            recentNotifications: modifyNotifications,
          },
        });
        return team;
      },
      athlete: (athlete: Reference): Reference => {
        cache.modify({
          id: athlete.__ref,
          fields: {
            timelineMealNotifications: modifyNotifications,
            mealPlanNotifications: modifyNotifications,
            timelineItemsInRange: (timelineItemsInRange: Reference[]): Reference[] => {
              return timelineItemsInRange.map(item => {
                cache.modify({
                  id: item.__ref,
                  fields: {
                    notifications: modifyNotifications,
                  },
                });
                return item;
              });
            },
          },
        });
        return athlete;
      },
    },
  });
};

//delayInMilliseconds should be removed once we have a better solution for displaying and clearing editTimelineMeal notifications
export const useMarkNotificationsAsViewed = (notificationIds: readonly string[], delayInMilliseconds?: number) => {
  const [markNotificationsAsViewed] = useMarkNotificationsAsViewedMutation();
  useEffect(() => {
    if (notificationIds.length === 0) {
      return;
    }
    const markNotifications = () =>
      markNotificationsAsViewed({
        variables: { input: { notificationIds } },
        update: (cache, { data }) => onCompleteNotifications(cache, data),
      });
    if (delayInMilliseconds) {
      const timer = setTimeout(() => markNotifications(), delayInMilliseconds);
      return () => clearTimeout(timer);
    } else {
      markNotifications();
    }
    return;
  }, [[...notificationIds].sort().join(",")]);
};
