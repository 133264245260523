import { Box, Typography } from "@mui/material";
import { sortFnByKey } from "@notemeal/utils-sort";
import React from "react";
import { ExchangeType, Food } from "../../../../types";

interface ExchangeBoxProps {
  foods: Pick<Food, "id" | "name">[];
  name: ExchangeType;
}

const ExchangeBox = ({ foods, name }: ExchangeBoxProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      <Typography variant="body2Semibold">
        {name[0].toUpperCase()}
        {name.slice(1)}
      </Typography>
      <Box sx={{ display: "flex", flexFlow: "column wrap", overflowX: "hidden" }}>
        {foods.sort(sortFnByKey("name")).map(f => (
          <Typography key={f.id} variant="subtitle1">
            {f.name}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default ExchangeBox;
