import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, ListItemButton, SxProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AthleteForMealPlanTemplateAssignmentFragment } from "apps/web/src/types";
import NamedTagChipList from "apps/web/src/views/Tags/NamedTagChipList";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      marginRight: theme.spacing(1),
    },
    innerContainer: {
      display: "flex",
      flex: 1,
    },
    outerContainer: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    close: {
      color: theme.palette.grey[500],
    },
    chip: {
      cursor: "pointer",
    },
  })
);

interface BulkAssignMealPlanTemplateAthleteListItemProps {
  athlete: AthleteForMealPlanTemplateAssignmentFragment;
  onClick: (athlete: AthleteForMealPlanTemplateAssignmentFragment) => void;
  sx?: SxProps;
  showRemove?: boolean;
}

export const AthleteItemContent = ({ athlete }: { athlete: AthleteForMealPlanTemplateAssignmentFragment }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.name}>{`${athlete.lastName}, ${athlete.firstName} `}</Typography>
      <NamedTagChipList
        namedTags={athlete.myNamedTagsOffsetConnection.edges}
        namedTagsCount={athlete.myNamedTagsOffsetConnection.pageInfo.total}
        chipClassName={classes.chip}
      />
    </>
  );
};

export const BulkAssignMealPlanTemplateAthleteListItem = ({
  athlete,
  onClick,
  sx,
  showRemove,
}: BulkAssignMealPlanTemplateAthleteListItemProps) => {
  return (
    <TWTooltip
      title={athlete.mostRecentAnthropometryEntryForRmrMethod ? "" : "Anthropometry data insufficent for this template"}
      subcopy=""
      placement="left"
    >
      <div>
        <ListItemButton
          disabled={!athlete.mostRecentAnthropometryEntryForRmrMethod}
          onClick={() => onClick(athlete)}
          sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer", ...sx }}
        >
          <Box sx={{ display: "flex", flex: 1 }}>
            <AthleteItemContent athlete={athlete} />
          </Box>
          {showRemove && <CloseIcon sx={{ color: "grey.dark" }} />}
        </ListItemButton>
      </div>
    </TWTooltip>
  );
};
