import { Box } from "@mui/material";
import { getAthleteListName } from "@notemeal/shared-utils-macro-protocol";
import { formatTimeInTimezone } from "@notemeal/utils-date-time";
import React from "react";
import { Snackbar } from "../../components/Snackbar/Snackbar";
import { KdsPageMealMenuPlateOrderPreviewFragment, KdsPageMenuOrderPreviewFragment } from "../../types";

export interface NewOrderSnackbarProps {
  newestOrder: KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment;
  setNewestOrder: (o: KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment | null) => void;
  handlePrintOrder: (menuOrderId: string) => void;
}

// TODO: how does handle Print Order work with plates?
const NewOrderSnackbar = ({ newestOrder, setNewestOrder, handlePrintOrder }: NewOrderSnackbarProps) => {
  const athleteName = newestOrder?.athlete ? getAthleteListName(newestOrder.athlete) : "";
  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const pickupTime = newestOrder.__typename === "MenuOrder" ? newestOrder.pickupTime : newestOrder.parentBulkOrder.pickupTime;
  const pickupTimeForDisplay = formatTimeInTimezone(pickupTime, clientTimezone, { excludeTimezoneSuffix: true });
  return (
    <Box
      style={{ cursor: "pointer" }}
      onClick={() => {
        handlePrintOrder(newestOrder.id);
        setNewestOrder(null);
      }}
    >
      <Snackbar
        variant="success"
        message={`New order: ${athleteName} @ ${pickupTimeForDisplay}. Tap to print ticket`}
        setMessage={() => setNewestOrder(null)}
      />
    </Box>
  );
};

export default NewOrderSnackbar;
