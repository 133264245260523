import ChevronRight from "@mui/icons-material/ChevronRight";
import { Card, CardActionArea, CardActions, CardContent, Icon, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useHasFeature } from "@notemeal/shared-ui";
import { Macros } from "@notemeal/shared-utils-macro-protocol";
import { MenuOrderSuggestionsAndRecentOrdersQuery, MenuSelectionFormFragment } from "../../../types";
import MenuOrderQuickAddTabs from "../QuickAdd/Tabs";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    grow: { flexGrow: 1 },
    actionArea: { width: theme.spacing(4), margin: theme.spacing(3) },
    icon: { color: theme.palette.grey[600] },
  });
});

interface MenuOrderMenuCardsProps {
  className: string;
  cardClassName: string;
  menuOrderingClosed: boolean;
  canOrderItems: boolean;
  canLogItems: boolean;
  onClickOrder: () => void;
  onClickLog: () => void;
  onChooseMenuOrder: (selection: MenuSelectionFormFragment) => void;
  targetMacros: Macros | null;
  mealMenuId: string;
  athleteUserId?: string;
  menuOrderSuggestionsAndRecentOrdersData?: MenuOrderSuggestionsAndRecentOrdersQuery;
  menuOrderSuggestionsAndRecentOrdersLoading: boolean;
}

//Duplicate-ish component until we re-design kiosk to match timeline meal style
const MenuOrderMenuCards = ({
  className,
  cardClassName,
  menuOrderingClosed,
  canLogItems,
  canOrderItems,
  onClickLog,
  onClickOrder,
  onChooseMenuOrder,
  targetMacros,
  athleteUserId,
  mealMenuId,
  menuOrderSuggestionsAndRecentOrdersData,
  menuOrderSuggestionsAndRecentOrdersLoading,
}: MenuOrderMenuCardsProps) => {
  const classes = useStyles();
  const hasAthleteMenuSuggestions = useHasFeature("athleteMenuSuggestions");

  const suggestions =
    (menuOrderSuggestionsAndRecentOrdersData &&
      hasAthleteMenuSuggestions &&
      menuOrderSuggestionsAndRecentOrdersData.menuOrderSuggestions) ||
    [];
  const recentOrders = menuOrderSuggestionsAndRecentOrdersData
    ? menuOrderSuggestionsAndRecentOrdersData.menuOrderCursorConnectionForItemsForAthlete.edges
    : [];

  return (
    <div className={className}>
      <div>
        {canOrderItems && (
          <Card className={cardClassName} onClick={onClickOrder}>
            <div className={classes.grow}>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h3"
                  component="h2">
                  <b>Order from Menu</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p">
                  Build your order
                  <br />
                  Send to kitchen for preparation
                  <br />
                  Pick-up later at your convenience
                </Typography>
                {menuOrderingClosed && (
                  <Typography
                    variant="body2"
                    color="error"
                    component="p">
                    <br />
                    Ordering closed
                  </Typography>
                )}
              </CardContent>
            </div>
            <CardActionArea
              onClick={onClickOrder}
              disabled={menuOrderingClosed}
              className={classes.actionArea}>
              <CardActions>
                <Icon className={classes.icon}>
                  <ChevronRight color="inherit"></ChevronRight>
                </Icon>
              </CardActions>
            </CardActionArea>
          </Card>
        )}

        {canLogItems && (
          <Card className={cardClassName} onClick={onClickLog}>
            <div className={classes.grow}>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h3"
                  component="h2">
                  <b> Log from Menu</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p">
                  View entire menu
                  <br />
                  Log the plate you built
                  <br />
                  See nutrient breakdown
                </Typography>
              </CardContent>
            </div>
            <CardActionArea
              onClick={onClickLog}
              disabled={menuOrderingClosed}
              className={classes.actionArea}>
              <CardActions>
                <Icon className={classes.icon}>
                  <ChevronRight color="inherit"></ChevronRight>
                </Icon>{" "}
              </CardActions>
            </CardActionArea>
          </Card>
        )}
      </div>

      <MenuOrderQuickAddTabs
        suggestions={suggestions}
        recentOrders={recentOrders}
        onChoose={onChooseMenuOrder}
        targetMacros={targetMacros}
        athleteUserId={athleteUserId}
        mealMenuId={mealMenuId}
        loading={menuOrderSuggestionsAndRecentOrdersLoading}
      />
    </div>
  );
};

export default MenuOrderMenuCards;
