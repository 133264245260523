import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { isMealMenuClosed } from "@notemeal/shared-ui";
import { TimelineMealForModal } from "../utils";
import { TimelineMealButton } from "./TimelineMealButton";
import { useTimelineMealModalContext } from "./TimelineMealModalContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      marginBottom: theme.spacing(4),
    },
  })
);

interface TimelineMealMenuOrderButtonsProps {
  selectedMenuId: string | null;
  timelineMeal: TimelineMealForModal;
}

const TimelineMealMenuOrderButtons = ({ selectedMenuId, timelineMeal }: TimelineMealMenuOrderButtonsProps) => {
  const classes = useStyles();
  const {
    cartFns: { changeCart },
  } = useTimelineMealModalContext();

  return (
    <div className={classes.container}>
      {timelineMeal.mealMenus.map(mm => {
        const isMMClosed = isMealMenuClosed(mm);
        return (
          <TimelineMealButton
            key={mm.id}
            selected={mm.id === selectedMenuId}
            name={mm.name}
            disabled={isMMClosed}
            onClick={() => {
              if (!isMMClosed) {
                // TODO: Fix for multiple menuOrders on same menu
                const existingOrder = timelineMeal.menuOrders.find(o => o.mealMenu.id === mm.id) ?? null;
                changeCart(mm.id, existingOrder);
              }
            }}
          />
        );
      })}
    </div>
  );
};

export default TimelineMealMenuOrderButtons;
