import { Box, FormControlLabel, Radio, Typography } from "@mui/material";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";
import { ORDERED_DAYS_OF_WEEK } from "@notemeal/utils-date-time";
import { DayOfWeekSchema } from "@notemeal/validators";
import { isPast } from "date-fns";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import DayOfWeekSelector from "../../../MealPlans/Content/Calendar/Form/DayOfWeekSelector";
import { GoalConfigurationFormType } from "../GoalConfigurationSchema";

const datePickerStyle = {
  maxWidth: "220px",
};

interface GoalConfigurationDateAssignmentModeFormProps {
  form: UseFormReturn<GoalConfigurationFormType>;
  mode: "create" | "edit" | "duplicate";
}

export const GoalConfigurationDateAssignmentModeForm = ({ form, mode }: GoalConfigurationDateAssignmentModeFormProps) => {
  const { control, watch, setValue } = form;

  const { error: dateError } = form.getFieldState("assignments.dates");
  const { error: dayOfWeekError } = form.getFieldState("assignments.daysOfWeek");

  const startDate = watch("assignments.startDate");

  const startDateProps: Partial<DesktopDatePickerProps<Date>> = {
    disablePast: true,
  };

  const assignmentType = watch("assignments.type");
  const daysOfWeek = watch("assignments.daysOfWeek");

  const hasError = dateError || dayOfWeekError;

  return (
    <>
      {hasError &&
        (assignmentType === "individualDates" ? (
          <Typography variant="body2" sx={{ mb: 2, color: theme => theme.palette.error.main, height: "18px" }}>
            {(dateError && dateError.message) ?? " "}
          </Typography>
        ) : (
          <Typography variant="body2" sx={{ mb: 2, color: theme => theme.palette.error.main, height: "18px" }}>
            {(dayOfWeekError && dayOfWeekError.message) ?? " "}
          </Typography>
        ))}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Controller
          name={"assignments.type"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormControlLabel
              control={
                <Radio
                  size="small"
                  checked={field.value === "weekly"}
                  onChange={() => {
                    field.onChange("weekly");
                    if (daysOfWeek === undefined) {
                      setValue("assignments.daysOfWeek", [...ORDERED_DAYS_OF_WEEK]);
                    }
                  }}
                />
              }
              sx={{ pl: 1.5 }}
              label="Weekly"
              labelPlacement="end"
            />
          )}
        />

        {assignmentType === "weekly" && (
          <Box sx={{ display: "flex", flexDirection: "column", pl: 4, gap: 2 }}>
            <Controller
              name="assignments.daysOfWeek"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <DayOfWeekSelector
                  daysOfWeek={field.value ?? []}
                  onToggleDayOfWeek={dayOfWeek => {
                    const parsedDayOfWeek = DayOfWeekSchema.parse(dayOfWeek);
                    if (field.value) {
                      if (field.value.includes(parsedDayOfWeek)) {
                        field.onChange(field.value.filter(d => d !== parsedDayOfWeek));
                      } else {
                        field.onChange([...field.value, parsedDayOfWeek]);
                      }
                    }
                  }}
                  sx={{ width: "100%", justifyContent: "left", pb: 2 }}
                />
              )}
            />
            <Box sx={{ display: "flex", gap: 2 }}>
              <Controller
                name={"assignments.startDate"}
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <DesktopDatePicker
                    disabled={isPast(startDate) && mode === "edit"}
                    label="Start Date"
                    onChange={date => {
                      field.onChange(date);
                    }}
                    slotProps={{
                      textField: {
                        helperText: fieldState.error?.message ? fieldState.error.message : null,
                        error: fieldState.error ? true : false,
                      },
                    }}
                    sx={{ pb: 1, ...datePickerStyle }}
                    value={field.value}
                    {...startDateProps}
                  />
                )}
              />
              <Controller
                name={"assignments.endDate"}
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <DesktopDatePicker
                    label="End Date"
                    onChange={date => {
                      field.onChange(date);
                    }}
                    sx={datePickerStyle}
                    minDate={startDate}
                    slotProps={{
                      textField: {
                        helperText: fieldState.error?.message ? fieldState.error.message : null,
                        error: fieldState.error ? true : false,
                      },
                    }}
                    value={field.value}
                  />
                )}
              />
            </Box>
          </Box>
        )}
        <Controller
          name={"assignments.type"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormControlLabel
              control={
                <Radio
                  size="small"
                  checked={field.value === "individualDates"}
                  onChange={() => field.onChange("individualDates")} />
              }
              sx={{ pl: 1.5 }}
              label="Individual Dates"
              labelPlacement="end"
            />
          )}
        />
      </Box>
    </>
  );
};
