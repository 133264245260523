import { Box, Typography } from "@mui/material";

import { ExchangeSummaryLabel, MacrosSummaryLabel } from "@notemeal/shared-ui";

import { getMacroProtocolMacros } from "@notemeal/shared-ui";
import { FullMealPlanFragment } from "../../../types";

const denseTitleStyle = { lineHeight: 0.8 };

interface MealPlanExportHeaderProps {
  athleteName: string;
  athleteBirthDate: string | null;
  title: string;
  mealPlan: FullMealPlanFragment;
  displayTitle: boolean;
  displayHeaderMacros: boolean;
  displayHeaderExchanges: boolean;
}

const MealPlanExportHeader = ({ athleteName, athleteBirthDate, title, mealPlan, ...state }: MealPlanExportHeaderProps) => {
  const macros = getMacroProtocolMacros(mealPlan.macroProtocol, athleteBirthDate);
  return (
    <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", lineHeight: 0.5 }}>
      <Box sx={{ display: "flex", flexBasis: "55%", flexDirection: "column", justifyContent: "flex-start" }}>
        <Typography
          sx={denseTitleStyle}
          variant="h3"
          id="modal-title">
          {athleteName}
        </Typography>
        {state.displayTitle && (
          <Typography variant="subtitle1" id="modal-title">
            {title}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", flexBasis: "45%", flexDirection: "column" }}>
        {state.displayHeaderMacros && (
          <Box sx={{ textAlign: "right" }}>
            <Typography sx={denseTitleStyle} variant="h4">
              {macros.kcal} kcal
            </Typography>
            <MacrosSummaryLabel variant="xs" macros={macros} />
          </Box>
        )}
        {state.displayHeaderExchanges && (
          <Box sx={{ textAlign: "right" }}>
            <ExchangeSummaryLabel
              consolidatePro={true}
              variant="xs"
              targetExchangeAmounts={mealPlan.exchangeTargets || []} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MealPlanExportHeader;
