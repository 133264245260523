import { useState } from "react";
import { useDebounce } from "@notemeal/shared-ui";
import { TablePaginationProps as MuiTablePaginationProps } from "@mui/material";

const DEFAULT_LIMIT = 25;

export interface PaginationHooks {
  defaultLimit: number;
  limit: number;
  onChangeLimit: (value: number) => void;
  offset: number;
  resetOffset: () => void;
  page: number;
  onChangePage: (value: number) => void;
  query: string;
  queryText: string;
  onChangeQueryText: (value: string) => void;
}

export const useOffsetPagination = (defaultLimit?: number): PaginationHooks => {
  const [queryText, setQueryText] = useState("");
  const query = useDebounce(queryText, 250);
  const [limit, setLimit] = useState(defaultLimit || DEFAULT_LIMIT);
  const [offset, setOffset] = useState(0);
  const page = offset / limit;

  const onChangeLimit = (value: number) => {
    setLimit(value);
    setOffset(0);
  };

  // TODO: Offset should only be reset to zero when query changes, not queryText
  const onChangeQueryText = (value: string) => {
    setQueryText(value);
    setOffset(0);
  };

  const resetOffset = () => setOffset(0);

  const onChangePage = (page: number) => setOffset(page * limit);

  return {
    defaultLimit: defaultLimit || DEFAULT_LIMIT,
    limit,
    onChangeLimit,
    offset,
    resetOffset,
    query,
    queryText,
    onChangeQueryText,
    page,
    onChangePage,
  };
};

export const getMuiTablePaginationProps = (paginationHooks: PaginationHooks, total: number): MuiTablePaginationProps => {
  const { defaultLimit, limit, onChangeLimit, page, onChangePage } = paginationHooks;

  return {
    rowsPerPageOptions: [defaultLimit, 2 * defaultLimit, 4 * defaultLimit],
    rowsPerPage: limit,
    count: total,
    page: page,
    onPageChange: (e, page) => onChangePage(page),
    onRowsPerPageChange: event => onChangeLimit(parseInt(event.target.value)),
  };
};

interface getRowsForTablePageProps<T> {
  rows: readonly T[];
  limit: number;
  page: number;
}

export const getRowsForTablePage = <T>({ rows, limit, page }: getRowsForTablePageProps<T>) => {
  //page is zero based, don't want to change what the server expects so we add one here
  const lastIndex = (page + 1) * limit;
  const firstIndex = lastIndex - limit;
  return rows.slice(firstIndex, lastIndex);
};
