import { Box, Typography } from "@mui/material";
import {
    AnthropometrySnapshot,
    MacroTarget,
    macroGPerKgCaloriesMetricTotalRounded,
    macroTargetPercentTotal,
} from "@notemeal/shared-utils-macro-protocol";
import React from "react";
import { GRAM_WIDTH, PERCENT_WIDTH } from "./MacroTargets";

interface MacroTotalsProps {
  cho: MacroTarget;
  pro: MacroTarget;
  fat: MacroTarget;
  anthropometrySnapshot: AnthropometrySnapshot;
  gPerKgOnly: boolean;
}

export const MacroTotals = ({ cho, pro, fat, anthropometrySnapshot, gPerKgOnly }: MacroTotalsProps) => {
  const { weightInKg } = anthropometrySnapshot;
  const totalCalories = macroGPerKgCaloriesMetricTotalRounded(cho, pro, fat, weightInKg);
  const totalPercent = macroTargetPercentTotal(cho, pro, fat);

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Typography variant="body1Semibold" sx={{ width: 261 }}>
        TOTAL
      </Typography>
      {!gPerKgOnly && <Typography sx={{ width: PERCENT_WIDTH }} variant="body1Semibold">{`${totalPercent} %`}</Typography>}
      {gPerKgOnly && <Box sx={{ width: PERCENT_WIDTH }} />}
      <Box sx={{ width: 115 }} />
      <Typography sx={{ width: GRAM_WIDTH }} variant="body1Semibold">{`${totalCalories} kcal`}</Typography>
    </Box>
  );
};
