import { EditImperialMacroProtocolState, EditMetricMacroProtocolState } from "@notemeal/shared-ui";
import {
  AnthropometrySnapshot,
  applyMetricWeightTarget,
  applyWeightTarget,
  getTotalCalories,
  getTotalMetricCalories,
  macroGPerKgCaloriesMetricTotal,
  macroGPerKgCaloriesTotal,
} from "@notemeal/shared-utils-macro-protocol";
import { AddActivityInput, AddMealInput, FullGoalFragment, FullMacroProtocolFragment } from "../../types";

export const DEFAULT_MEALS: AddMealInput[] = [
  {
    name: "Breakfast",
    type: "breakfast",
    start: "07:00:00",
    end: "08:00:00",
  },
  {
    name: "Morning Snack",
    type: "snack",
    start: "10:30:00",
    end: "11:00:00",
  },
  {
    name: "Lunch",
    type: "lunch",
    start: "12:00:00",
    end: "13:00:00",
  },
  {
    name: "Afternoon Snack",
    type: "snack",
    start: "15:00:00",
    end: "15:30:00",
  },
  {
    name: "Dinner",
    type: "dinner",
    start: "18:00:00",
    end: "19:00:00",
  },
];

export const DEFAULT_ACTIVITIES: AddActivityInput[] = [];

export const DEFAULT_ACTIVITY_FACTOR = 1.5;

export const DEFAULT_SCHEDULE_NAME = "Default Schedule";

const config = {
  name: DEFAULT_SCHEDULE_NAME,
  meals: DEFAULT_MEALS,
  activities: DEFAULT_ACTIVITIES,
  activityFactor: DEFAULT_ACTIVITY_FACTOR,
};

export default config;

// NOTE: separate measurement flows for first phase of measurement system
// TODO: metric update run phase? commonize and or combine separate measurement system flows
export const getInitialMealPlanName = (
  macroProtocol: FullMacroProtocolFragment | EditImperialMacroProtocolState,
  anthropometrySnapshot: AnthropometrySnapshot,
  goalSnapshot: FullGoalFragment | null,
  sportName: string
) => {
  let totalCalories;
  const targetAnthroSnapshot = applyWeightTarget(anthropometrySnapshot, macroProtocol.weightTarget);
  if (macroProtocol.calorieBudget) {
    const calorieBudget = {
      ...macroProtocol.calorieBudget,
      goalSnapshot: { kcalOffset: goalSnapshot ? goalSnapshot.kcalOffset : 0 },
    };
    totalCalories = getTotalCalories(targetAnthroSnapshot, calorieBudget);
  } else {
    const { weight } = targetAnthroSnapshot;
    const { cho, pro, fat } = macroProtocol;
    totalCalories = macroGPerKgCaloriesTotal(cho, pro, fat, weight);
  }

  const goalTypeName = goalSnapshot ? goalSnapshot.type.name : "";
  return `${Math.round(totalCalories / 100) * 100}kcal ${goalTypeName} - ${sportName}`;
};

export const getInitialMetricMealPlanName = (
  macroProtocol: FullMacroProtocolFragment | EditMetricMacroProtocolState,
  anthropometrySnapshot: AnthropometrySnapshot,
  goalSnapshot: FullGoalFragment | null,
  sportName: string
) => {
  let totalCalories;
  const targetAnthroSnapshot = applyMetricWeightTarget(anthropometrySnapshot, macroProtocol.weightTargetInKg);
  if (macroProtocol.calorieBudget) {
    const calorieBudget = {
      ...macroProtocol.calorieBudget,
      goalSnapshot: { kcalOffset: goalSnapshot ? goalSnapshot.kcalOffset : 0 },
    };
    totalCalories = getTotalMetricCalories(targetAnthroSnapshot, calorieBudget);
  } else {
    const { weightInKg } = targetAnthroSnapshot;
    const { cho, pro, fat } = macroProtocol;
    totalCalories = macroGPerKgCaloriesMetricTotal(cho, pro, fat, weightInKg);
  }

  const goalTypeName = goalSnapshot ? goalSnapshot.type.name : "";
  return `${Math.round(totalCalories / 100) * 100}kcal ${goalTypeName} - ${sportName}`;
};
