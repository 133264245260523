import { Box, Divider, Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Score } from "@notemeal/shared-ui";
import { RecipeWithServingsFragment, useMyScoringSystemQuery } from "../../../../types";
import { PrintRecipeDirections } from "./PrintRecipeDirections";
import { PrintRecipeIngredients } from "./PrintRecipeIngredients";
import { PrintRecipeMacros } from "./PrintRecipeMacros";
import { PrintRecipeSummaryLine } from "./PrintRecipeSummaryLine";

interface PrintableRecipeProps {
  recipe: RecipeWithServingsFragment;
  includeImage: boolean;
}

const useStyles = makeStyles(({ spacing, shape }: Theme) =>
  createStyles({
    printedPage: {
      width: "8.5in",
      padding: spacing(3),
      backgroundColor: "white",
      borderRadius: shape.borderRadius,
      overflowWrap: "anywhere",
      overflow: "auto",
      "@media print": {
        height: "100%",
      },
      "@media screen": {
        height: "11in",
      },
    },
    image: {
      width: "100%",
      maxHeight: 180,
      objectFit: "cover",
      borderRadius: shape.borderRadius,
    },
  })
);

export function PrintableRecipe({ recipe, includeImage }: PrintableRecipeProps) {
  const classes = useStyles();
  const { data: scoringSystemData } = useMyScoringSystemQuery();
  const scoringSystem = scoringSystemData?.myScoringSystem;

  return (
    <div className={classes.printedPage}>
      <Grid container spacing={3}>
        {includeImage && recipe.images && recipe.images.length > 0 && (
          <Grid
            item
            alignSelf={"center"}
            justifySelf={"center"}
            xs={4}>
            <img
              src={recipe.images[0].url}
              className={classes.image}
              alt="" />
          </Grid>
        )}
        <Grid item xs={8}>
          <Typography variant="h3" display="inline-block">
            {recipe.name}
          </Typography>
          <Box display="inline-block" padding={1}>
            {scoringSystem && recipe.score && <Score scoreValue={recipe.score.value} scoringSystem={scoringSystem} />}
          </Box>

          <PrintRecipeMacros recipe={recipe} />
          <PrintRecipeSummaryLine recipe={recipe} />
        </Grid>
      </Grid>

      <Divider variant="middle" sx={theme => ({ borderWidth: "1px", margin: theme.spacing(3) })} />

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PrintRecipeIngredients recipe={recipe} />
        </Grid>
        <Grid item xs={8}>
          <PrintRecipeDirections recipe={recipe} />
        </Grid>
      </Grid>
    </div>
  );
}
