import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Card, Fab, Typography } from "@mui/material";
import { MacrosSummaryLabel } from "@notemeal/shared-ui";
import { Macros } from "@notemeal/shared-utils-macro-protocol";
import { formatTimeRange } from "@notemeal/utils-date-time";
import Carousel, { CarouselProps } from "nuka-carousel";
import { Dispatch, FC, PropsWithChildren, useState } from "react";
import { v4 as uuid } from "uuid";
import { FullServingAmountFragment } from "../../../types";
import { AddNewMealOption } from "../../MealOption/Edit/AddNewMealOption";
import { MEAL_OPTION_SLIDE_WIDTH } from "../../MealOption/Edit/EditMealOption";
import { EditMealOption } from "./EditMealOption";
import { MealInputWithId, ScheduleAction } from "./scheduleReducer";

// TODO: update nuka-carousel to latest version and reformat with new props
type CarouselWithChildrenProps<CarouselProps> = PropsWithChildren<CarouselProps>;
const CarouselWithChildren: FC<CarouselWithChildrenProps<CarouselProps>> = ({ children, ...props }) => (
  <Carousel {...props}>{children}</Carousel>
);

interface EditMealOptionsProps {
  meal: MealInputWithId;
  viewOnly?: boolean;
  isShared: boolean;
  dispatch: Dispatch<ScheduleAction>;
  macros: Macros;
}

export const EditMealOptions = ({ meal, viewOnly, isShared, dispatch, macros }: EditMealOptionsProps) => {
  const [mealOptionIndex, setMealOptionIndex] = useState(0);
  const [selectedMealOptionIndex, setSelectedMealOptionIndex] = useState(0);
  const { mealOptions: _mealOptions } = meal;
  const mealOptionsToShow = 2;
  const sortedMealOptions = _mealOptions
    .concat()
    .sort(({ position: posA }, { position: posB }) => (posA < posB ? -1 : posA > posB ? 1 : 0));

  const handleAddNewMealOption = () => {
    const position = meal.mealOptions.length;
    const mealOptions = meal.mealOptions.concat({ id: uuid(), servingAmounts: [], position, note: "", name: "" });
    const newState = { ...meal, mealOptions };
    dispatch({
      type: "EDIT_MEAL",
      payload: newState,
    });
    setMealOptionIndex(position);
    setSelectedMealOptionIndex(position);
  };

  const handleDeleteMealOption = (mealOptionId: string) => {
    dispatch({
      type: "EDIT_MEAL",
      payload: { ...meal, mealOptions: sortedMealOptions.filter(mealOption => mealOption.id !== mealOptionId) },
    });
  };

  const handleEditServingAmounts = (mealOptionId: string, servingAmounts: readonly FullServingAmountFragment[]) => {
    dispatch({
      type: "EDIT_MEAL",
      payload: {
        ...meal,
        mealOptions: sortedMealOptions.map(mealOption =>
          mealOption.id === mealOptionId
            ? {
                ...mealOption,
                servingAmounts: servingAmounts.map(({ amount, position, serving: { id: servingId } }) => ({ servingId, amount, position })),
              }
            : mealOption
        ),
      },
    });
  };

  const handleEditNote = (mealOptionId: string, note: string) => {
    dispatch({
      type: "EDIT_MEAL",
      payload: {
        ...meal,
        mealOptions: sortedMealOptions.map(mealOption => (mealOption.id === mealOptionId ? { ...mealOption, note } : mealOption)),
      },
    });
  };

  const handleChangeMealOptionName = (mealOptionId: string, name: string) => {
    const newMealOptions = sortedMealOptions.map(mealOption => (mealOption.id === mealOptionId ? { ...mealOption, name } : mealOption));

    dispatch({
      type: "EDIT_MEAL",
      payload: { ...meal, mealOptions: newMealOptions },
    });
  };

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
      }}
    >
      <Box sx={{ flex: 1, display: "flex", gap: 2 }}>
        <Box sx={{ mr: "2px", height: "100%", width: "6px", backgroundColor: "greyscale.400" }} />
        <Box sx={{ pr: 2, flex: 1, display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ pt: 1, display: "flex", gap: 2, alignItems: "center" }}>
              <RestaurantIcon fontSize="small" sx={{ color: "greyscale.400" }} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1Medium">{meal.meal.name}</Typography>
                <Typography sx={{ color: "mediumEmphasisText" }} variant="body2">
                  {formatTimeRange(meal.meal.start, meal.meal.end)}
                </Typography>
              </Box>
            </Box>
            <MacrosSummaryLabel macros={macros} variant="lg" />
          </Box>
          <Box
            sx={{
              display: "grid",
              // Hard coded to Fab h/w of 40px and margin of 8
              gridTemplateColumns: `${40 / 2 - 8}px ${40 / 2 + 8}px 1fr ${56 - 40}px ${40 / 2 + 8}px ${40 / 2 - 8}px`,
              gridTemplateRows: "auto 40px auto 56px",
            }}
          >
            <Box sx={{ display: "flex", overflow: "hidden", gridColumnStart: 2, gridColumnEnd: 6, gridRowStart: 1, gridRowEnd: 5 }}>
              <CarouselWithChildren
                slideIndex={mealOptionIndex}
                afterSlide={slideIndex => setMealOptionIndex(slideIndex)}
                heightMode="max"
                transitionMode="scroll"
                speed={750}
                slidesToShow={mealOptionsToShow}
                slideWidth={MEAL_OPTION_SLIDE_WIDTH}
                withoutControls
                dragging={false}
              >
                {sortedMealOptions.length > 0 ? (
                  sortedMealOptions.map((mealOption, index) => (
                    <EditMealOption
                      index={index}
                      key={mealOption.id}
                      mealOption={mealOption}
                      viewOnly={viewOnly}
                      isShared={isShared}
                      targetMacros={macros}
                      mealType={meal.meal.type}
                      selected={index === selectedMealOptionIndex}
                      onDelete={() => handleDeleteMealOption(mealOption.id)}
                      onSelect={() => setSelectedMealOptionIndex(index)}
                      onEditServingAmounts={servingAmounts => handleEditServingAmounts(mealOption.id, servingAmounts)}
                      onEditNote={note => handleEditNote(mealOption.id, note)}
                      onChangeMealOptionName={name => handleChangeMealOptionName(mealOption.id, name)}
                    />
                  ))
                ) : (
                  <>{!viewOnly && <AddNewMealOption onClick={handleAddNewMealOption} />}</>
                )}
              </CarouselWithChildren>
            </Box>
            {mealOptionIndex > 0 && (
              <Fab
                sx={
                  mealOptionIndex > 0
                    ? {
                        gridColumnStart: 1,
                        gridColumnEnd: 3,
                        gridRowStart: 2,
                        backgroundColor: "greyscale.500",
                        "&:hover": {
                          backgroundColor: "greyscale.600",
                        },
                      }
                    : { display: "none" }
                }
                onClick={() => setMealOptionIndex(mealOptionIndex - 1)}
                size="small"
              >
                <KeyboardArrowLeftIcon />
              </Fab>
            )}
            {mealOptionIndex < sortedMealOptions.length - 1 && (
              <Fab
                sx={{
                  gridColumnStart: 5,
                  gridColumnEnd: 7,
                  gridRowStart: 2,
                  backgroundColor: "greyscale.500",
                  "&:hover": {
                    backgroundColor: "greyscale.600",
                  },
                }}
                onClick={() => setMealOptionIndex(mealOptionIndex + 1)}
                size="small"
              >
                <KeyboardArrowRightIcon />
              </Fab>
            )}
            {sortedMealOptions.length > 0 && !viewOnly && (
              <Fab
                sx={{ gridColumnStart: 4, gridColumnEnd: 7, gridRowStart: 4 }}
                onClick={handleAddNewMealOption}
                size="large">
                <AddIcon fontSize="large" />
              </Fab>
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
