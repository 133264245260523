import { datadogRum } from "@datadog/browser-rum";
import { Box, Button } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import { usePlannedMenuGridQuery } from "apps/web/src/types";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { MenuBuilderMeal } from "./Meal/MenuBuilderMeal";
import { MenuBuilderType, getPlannedMenuMealFormValues } from "./Meal/MenuBuilderMealSchema";
import { useMenuBuilderContext } from "./MenuBuilderProvider";

interface MenuBuilderMealsProps {
  weekFormIndex: number;
  weekId: string;
  form: UseFormReturn<MenuBuilderType>;
}

export const MenuBuilderWeek = ({ weekFormIndex, form, weekId }: MenuBuilderMealsProps) => {
  const { selectedWeekIndex, mealGridWidth, mealsToDelete, setMealsToDelete, menuId, isEditable, handleDeleteMealSideEffects } =
    useMenuBuilderContext();
  const mealsFieldArray = useFieldArray<MenuBuilderType>({
    control: form.control,
    name: `weeks.${weekFormIndex}.meals`,
  });

  const { loading } = usePlannedMenuGridQuery({
    variables: { plannedMenuWeekId: weekId },
    // use "no-cache" to always fetch latest menu data in order to prevent edits being made on outdated cached data
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const meals = data.plannedMenuMealsForWeek.map(meal => getPlannedMenuMealFormValues(weekId, meal));
      // reset the form so this becomes the new default state. This lets rhf correctly identify when the form is dirtied
      const currentForm = form.getValues();
      currentForm.weeks[weekFormIndex].meals = meals.length > 0 ? meals : [getPlannedMenuMealFormValues(weekId)];
      form.reset(currentForm);
    },
    // forces a reload when this query is refetched by the save button
    notifyOnNetworkStatusChange: true,
  });

  const addMeal = () => {
    datadogRum.addAction("menu_builder.added_meal", { menuId });
    mealsFieldArray.append(getPlannedMenuMealFormValues(weekId));
  };

  const deleteMeal = (mealFormIndex: number) => {
    datadogRum.addAction("menu_builder.deleted_meal", { menuId });
    // only delete meals that previously existed
    const mealId = form.getValues(`weeks.${weekFormIndex}.meals.${mealFormIndex}`).id;
    if (mealId) {
      setMealsToDelete([...mealsToDelete, mealId]);
    }

    handleDeleteMealSideEffects(mealFormIndex);
    mealsFieldArray.remove(mealFormIndex);
  };

  if (selectedWeekIndex !== weekFormIndex) {
    return null;
  }

  if (loading) {
    return (
      <Box sx={{ height: "100%" }}>
        <Loading progressSize="lg" />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 3,
      }}
    >
      {mealsFieldArray.fields.map((meal, index) => (
        <MenuBuilderMeal
          key={meal.id}
          mealFormIndex={index}
          weekFormIndex={weekFormIndex}
          form={form}
          deleteMeal={() => deleteMeal(index)}
        />
      ))}
      {isEditable && (
        <Button sx={{ mt: 2, mb: 2, width: mealGridWidth }} onClick={addMeal}>
          Add Meal
        </Button>
      )}
    </Box>
  );
};
