import { jsDateToDateAndTimeInTz, parseDate, serializeDate, serializeTime } from "@notemeal/utils-date-time";
import {
  ActivityTemplateTimeFragment,
  MealPlanDateModificationInput,
  DayOfWeek,
  MealPlanDayOfWeekModificationInput,
  ScheduleBasicFragment,
  MealTemplateTimeFragment,
  MealPlanDateModificationsFragment,
  MealPlanDayOfWeekAssignmentFragment,
  MealTemplateScheduleFragment,
  ActivityTemplateScheduleFragment,
  MealPlanCalendarDateAssignmentFragment,
} from "apps/web/src/types";
import { addMinutes, addMonths, isValid } from "date-fns";
import { v4 } from "uuid";
import { getDayOfWeek } from "../utils";
import {
  BaseMealPlanCalendarState,
  CreateMealPlanCalendarState,
  EditMealPlanCalendarState,
  MealPlanCalendarActivityTemplate,
  MealPlanCalendarMealTemplate,
  MealPlanCalendarState,
  MealPlanDateAssignment,
  MealPlanDateAssignmentMode,
  MealPlanSuggestions,
} from "./types";
import { ORDERED_DAYS_OF_WEEK } from "./utils";
import { SuggestedMealTemplateCalendarEvent } from "../types";
import { getKeyForSuggestion } from "../Suggestions/utils";

export type MealPlanDateAssignmentAction =
  | EditDateAssignmentMode
  | ToggleIndividualDateAssignment
  | EditStartDate
  | EditEndDate
  | ToggleEndDate
  | ToggleDayOfWeekAssignment;

export type MealPlanCalendarAction = TemplateAction | MealPlanDateAssignmentAction | SuggestionsAction | SuggestionTemplateAction;

type TemplateAction = EditSchedule | EditEventTime | RemoveAllModifications;

type SuggestionTemplateAction = AddAcceptedSuggestionsAsModifications;

export type SuggestionsAction = ToggleAutoSuggestions | AddAcceptedSuggestions | AddRejectedSuggestions | RevertSuggestions;

export type CreateMealPlanCalendarAction = MealPlanCalendarAction | SelectSchedule | LoadCreateCalendarByMonth;

export type EditMealPlanCalendarAction = MealPlanCalendarAction | LoadEditCalendarByMonth;

interface SelectSchedule {
  type: "SelectSchedule";
  payload: {
    schedule: ScheduleBasicFragment;
  };
}

export interface EditSchedule {
  type: "EditSchedule";
  payload: {
    mealTemplates: readonly MealTemplateTimeFragment[];
    resetMealTemplateIds: readonly string[];
    activityTemplates: readonly ActivityTemplateTimeFragment[];
    resetActivityTemplateIds: readonly string[];
  };
}

interface EditDateAssignmentMode {
  type: "EditDateAssignmentMode";
  payload: {
    mode: MealPlanDateAssignmentMode;
  };
}

interface ToggleIndividualDateAssignment {
  type: "ToggleIndividualDateAssignment";
  payload: {
    date: string;
  };
}

interface ToggleDayOfWeekAssignment {
  type: "ToggleDayOfWeekAssignment";
  payload: {
    dayOfWeek: DayOfWeek;
  };
}

interface EditStartDate {
  type: "EditStartDate";
  payload: {
    date: Date | null;
  };
}

interface EditEndDate {
  type: "EditEndDate";
  payload: {
    date: Date | null;
  };
}

interface ToggleEndDate {
  type: "ToggleEndDate";
  payload: {
    disableEndDate: boolean;
  };
}

interface RemoveAllModifications {
  type: "RemoveAllModifications";
}

interface ToggleAutoSuggestions {
  type: "ToggleAutoSuggestions";
  payload: {
    isAutoSuggestionsEnabled: boolean;
  };
}

interface AddAcceptedSuggestions {
  type: "AddAcceptedSuggestions";
  payload: {
    suggestions: SuggestedMealTemplateCalendarEvent[];
  };
}

interface AddRejectedSuggestions {
  type: "AddRejectedSuggestions";
  payload: {
    suggestions: SuggestedMealTemplateCalendarEvent[];
  };
}

interface RevertSuggestions {
  type: "RevertSuggestions";
  payload: {
    suggestionKeys: string[];
  };
}

interface AddAcceptedSuggestionsAsModifications {
  type: "AddAcceptedSuggestionsAsModifications";
}

export type EventModificationScope = "one" | "dayOfWeek" | "every";

export interface EditEventTime {
  type: "EditEventTime";
  payload: {
    eventId: string;
    date: string;
    start: string;
    end: string;
    scope: EventModificationScope;
  };
}

export interface LoadEditCalendarByMonth {
  type: "LoadEditCalendarByMonth";
  payload: {
    startOfMonth: string;
    individualDatesInRange: readonly string[];
    modificationsInRange: readonly MealPlanDateModificationsFragment[];
    mealPlanDateAssignments: readonly MealPlanCalendarDateAssignmentFragment[];
    timelineMeals: readonly { id: string; start: string }[];
  };
}

export interface LoadCreateCalendarByMonth {
  type: "LoadCreateCalendarByMonth";
  payload: {
    startOfMonth: string;
    mealPlanDateAssignments: readonly MealPlanCalendarDateAssignmentFragment[];
    timelineMeals: readonly { id: string; start: string }[];
  };
}

export const createMealPlanCalendarReducer = (
  state: CreateMealPlanCalendarState,
  action: CreateMealPlanCalendarAction
): CreateMealPlanCalendarState => {
  switch (action.type) {
    case "SelectSchedule":
      return {
        ...state,
        selectedScheduleId: action.payload.schedule.id,
        mealTemplates: action.payload.schedule.meals.map(meal => ({
          id: v4(),
          meal,
          resetModifications: false,
          hasDateModifications: false,
          dateModifications: [],
          dayOfWeekModifications: [],
        })),
        activityTemplates: action.payload.schedule.activities.map(activity => ({
          id: v4(),
          activity,
          resetModifications: false,
          hasDateModifications: false,
          dateModifications: [],
          dayOfWeekModifications: [],
        })),
        suggestionsState: {
          ...state.suggestionsState,
          acceptedSuggestions: [],
          rejectedSuggestions: [],
        },
      };
    case "LoadCreateCalendarByMonth": {
      if (state.loadedStartOfMonths.includes(action.payload.startOfMonth)) {
        // Don't load the same month twice
        return state;
      }

      const newLockedDates = new Set(
        action.payload.timelineMeals.map(m => jsDateToDateAndTimeInTz(new Date(m.start), state.clientTimezone).date)
      );

      return {
        ...state,
        lockedDates: [...state.lockedDates, ...newLockedDates],
        loadedStartOfMonths: [...state.loadedStartOfMonths, action.payload.startOfMonth],
        otherMealPlanDateAssignments: [...state.otherMealPlanDateAssignments, ...action.payload.mealPlanDateAssignments],
      };
    }
    case "ToggleAutoSuggestions":
    case "AddAcceptedSuggestions":
    case "AddRejectedSuggestions":
    case "RevertSuggestions": {
      return { ...state, suggestionsState: suggestionsReducer(state.suggestionsState, action) };
    }
    case "ToggleDayOfWeekAssignment":
    case "EditDateAssignmentMode":
    case "ToggleIndividualDateAssignment":
    case "EditStartDate":
    case "EditEndDate":
    case "ToggleEndDate":
      return {
        ...state,
        dateAssignment: dateAssignmentReducer(state.dateAssignment, action),
      };
    case "AddAcceptedSuggestionsAsModifications": {
      return { ...state, ...acceptedSuggestionsReducerHelper(state) };
    }
  }
  return {
    ...state,
    mealTemplates: mealTemplateReducerHelper(state, action),
    activityTemplates: activityTemplateReducerHelper(state, action),
  };
};

export const editMealPlanCalendarReducer = (
  state: EditMealPlanCalendarState,
  action: EditMealPlanCalendarAction
): EditMealPlanCalendarState => {
  switch (action.type) {
    case "LoadEditCalendarByMonth": {
      if (state.loadedStartOfMonths.includes(action.payload.startOfMonth)) {
        // Don't load the same month twice
        return state;
      }

      const newIndividualDates = action.payload.individualDatesInRange.filter(d => !state.dateAssignment.individualDates.includes(d));
      const newLockedDates = new Set(
        action.payload.timelineMeals.map(m => jsDateToDateAndTimeInTz(new Date(m.start), state.clientTimezone).date)
      );

      return {
        ...state,
        lockedDates: [...state.lockedDates, ...newLockedDates],
        loadedStartOfMonths: [...state.loadedStartOfMonths, action.payload.startOfMonth],
        loadedIndividualDates: [...state.loadedIndividualDates, ...action.payload.individualDatesInRange],
        otherMealPlanDateAssignments: [
          ...state.otherMealPlanDateAssignments,
          ...action.payload.mealPlanDateAssignments.filter(m => m.mealPlan.id !== state.mealPlanId),
        ],
        dateAssignment: {
          ...state.dateAssignment,
          individualDates: [...state.dateAssignment.individualDates, ...newIndividualDates],
        },
        mealTemplates: state.mealTemplates.map(mealTemplate => {
          if (mealTemplate.resetModifications) {
            return mealTemplate;
          }
          const datesWithModifications = mealTemplate.dateModifications.map(m => m.date);
          const newDateModifications = action.payload.modificationsInRange.flatMap(({ date, mealTemplateModifications }) =>
            mealTemplateModifications
              .flatMap(m => (m.mealTemplate.id === mealTemplate.id ? { date, start: m.start, end: m.end } : []))
              .filter(m => !datesWithModifications.includes(m.date))
          );
          return {
            ...mealTemplate,
            dateModifications: [...mealTemplate.dateModifications, ...newDateModifications],
          };
        }),
        activityTemplates: state.activityTemplates.map(activityTemplate => {
          if (activityTemplate.resetModifications) {
            return activityTemplate;
          }
          const datesWithModifications = activityTemplate.dateModifications.map(m => m.date);
          const newDateModifications = action.payload.modificationsInRange.flatMap(({ date, activityTemplateModifications }) =>
            activityTemplateModifications
              .flatMap(m => (m.activityTemplate.id === activityTemplate.id ? { date, start: m.start, end: m.end } : []))
              .filter(m => !datesWithModifications.includes(m.date))
          );
          return {
            ...activityTemplate,
            dateModifications: [...activityTemplate.dateModifications, ...newDateModifications],
          };
        }),
      };
    }
    case "ToggleAutoSuggestions":
    case "AddAcceptedSuggestions":
    case "AddRejectedSuggestions":
    case "RevertSuggestions":
      return { ...state, suggestionsState: suggestionsReducer(state.suggestionsState, action) };
    case "ToggleDayOfWeekAssignment":
    case "EditDateAssignmentMode":
    case "ToggleIndividualDateAssignment":
    case "EditStartDate":
    case "EditEndDate":
    case "ToggleEndDate":
      return {
        ...state,
        dateAssignment: dateAssignmentReducer(state.dateAssignment, action),
      };
    case "AddAcceptedSuggestionsAsModifications": {
      return { ...state, ...acceptedSuggestionsReducerHelper(state) };
    }
  }

  return {
    ...state,
    mealTemplates: mealTemplateReducerHelper(state, action),
    activityTemplates: activityTemplateReducerHelper(state, action),
  };
};

export const suggestionsReducer = (state: MealPlanSuggestions, action: SuggestionsAction): MealPlanSuggestions => {
  switch (action.type) {
    case "ToggleAutoSuggestions":
      return {
        ...state,
        isAutoSuggestionsEnabled: action.payload.isAutoSuggestionsEnabled,
        acceptedSuggestions: [],
        rejectedSuggestions: [],
      };
    case "AddAcceptedSuggestions":
      return {
        ...state,
        acceptedSuggestions: [...state.acceptedSuggestions, ...action.payload.suggestions],
      };
    case "AddRejectedSuggestions":
      return {
        ...state,
        rejectedSuggestions: [...state.rejectedSuggestions, ...action.payload.suggestions],
      };
    case "RevertSuggestions":
      return {
        ...state,
        acceptedSuggestions: state.acceptedSuggestions.filter(s => !action.payload.suggestionKeys.includes(getKeyForSuggestion(s))),
        rejectedSuggestions: state.rejectedSuggestions.filter(s => !action.payload.suggestionKeys.includes(getKeyForSuggestion(s))),
      };
  }
};

/**
 * Turn accepted suggestions into modifications of meal times
 */
const acceptedSuggestionsReducerHelper = (state: BaseMealPlanCalendarState): BaseMealPlanCalendarState => {
  let mealTemplates = state.mealTemplates;
  for (const suggestion of state.suggestionsState.acceptedSuggestions) {
    const end = addMinutes(suggestion.start, suggestion.durationInMinutes);
    const editEventPayload: EditEventTime["payload"] = {
      eventId: suggestion.oldId,
      start: serializeTime(suggestion.start),
      end: serializeTime(end),
      scope: "one",
      date: serializeDate(suggestion.start),
    };

    mealTemplates = mealTemplates.map(mt => {
      if (mt.id !== suggestion.oldId) {
        return mt;
      }
      return {
        ...mt,
        dateModifications: mergeDateModifications(editEventPayload, mt.dateModifications),
      };
    });
  }
  return {
    ...state,
    mealTemplates,
    suggestionsState: { ...state.suggestionsState, acceptedSuggestions: [] },
  };
};

const activityTemplateReducerHelper = (
  state: BaseMealPlanCalendarState,
  action: TemplateAction
): readonly MealPlanCalendarActivityTemplate[] => {
  switch (action.type) {
    case "EditSchedule":
      return action.payload.activityTemplates.map(activityTemplate => {
        if (action.payload.resetActivityTemplateIds.includes(activityTemplate.id)) {
          return {
            ...activityTemplate,
            resetModifications: true,
            hasDateModifications: false,
            dateModifications: [],
            dayOfWeekModifications: [],
          };
        }
        const matchingActivityTemplate = state.activityTemplates.find(mt => mt.id === activityTemplate.id);
        return {
          ...activityTemplate,
          hasDateModifications: matchingActivityTemplate?.hasDateModifications ?? false,
          resetModifications: matchingActivityTemplate?.resetModifications ?? false,
          dateModifications: matchingActivityTemplate?.dateModifications ?? [],
          dayOfWeekModifications: matchingActivityTemplate?.dayOfWeekModifications ?? [],
        };
      });
    case "EditEventTime": {
      if (action.payload.scope === "one") {
        return state.activityTemplates.map(at => {
          if (at.id !== action.payload.eventId) {
            return at;
          }
          return {
            ...at,
            dateModifications: mergeDateModifications(action.payload, at.dateModifications),
          };
        });
      }

      if (action.payload.scope === "every") {
        return state.activityTemplates.map(at => {
          if (at.id !== action.payload.eventId) {
            return at;
          }
          return {
            ...at,
            resetModifications: true,
            activity: {
              ...at.activity,
              start: action.payload.start,
              end: action.payload.end,
            },
            dateModifications: [],
            dayOfWeekModifications: [],
          };
        });
      }

      // "dayOfWeek" modification scope only relevant for "weekly" mode
      if (state.dateAssignment.mode === "weekly") {
        return state.activityTemplates.map(at => {
          if (at.id !== action.payload.eventId) {
            return at;
          }
          return {
            ...at,
            ...mergeDayOfWeekModifications(action.payload, at.dayOfWeekModifications, at.dateModifications),
          };
        });
      }

      return state.activityTemplates;
    }
    case "RemoveAllModifications": {
      return state.activityTemplates.map(at => {
        return {
          ...at,
          hasDateModifications: false,
          resetModifications: true,
          dateModifications: [],
          dayOfWeekModifications: [],
        };
      });
    }
  }
};

const mealTemplateReducerHelper = (state: BaseMealPlanCalendarState, action: TemplateAction): readonly MealPlanCalendarMealTemplate[] => {
  switch (action.type) {
    case "EditSchedule": {
      return action.payload.mealTemplates.map(mealTemplate => {
        if (action.payload.resetMealTemplateIds.includes(mealTemplate.id)) {
          return {
            ...mealTemplate,
            resetModifications: true,
            hasDateModifications: false,
            dateModifications: [],
            dayOfWeekModifications: [],
          };
        }
        const matchingMealTemplate = state.mealTemplates.find(mt => mt.id === mealTemplate.id);
        return {
          ...mealTemplate,
          hasDateModifications: matchingMealTemplate?.hasDateModifications ?? false,
          resetModifications: matchingMealTemplate?.resetModifications ?? false,
          dateModifications: matchingMealTemplate?.dateModifications ?? [],
          dayOfWeekModifications: matchingMealTemplate?.dayOfWeekModifications ?? [],
        };
      });
    }
    case "RemoveAllModifications": {
      return state.mealTemplates.map(mt => {
        return {
          ...mt,
          hasDateModifications: false,
          resetModifications: true,
          dateModifications: [],
          dayOfWeekModifications: [],
        };
      });
    }
    case "EditEventTime": {
      if (action.payload.scope === "one") {
        return state.mealTemplates.map(mt => {
          if (mt.id !== action.payload.eventId) {
            return mt;
          }
          return {
            ...mt,
            dateModifications: mergeDateModifications(action.payload, mt.dateModifications),
          };
        });
      }

      if (action.payload.scope === "every") {
        return state.mealTemplates.map(mt => {
          if (mt.id !== action.payload.eventId) {
            return mt;
          }
          return {
            ...mt,
            resetModifications: true,
            meal: {
              ...mt.meal,
              start: action.payload.start,
              end: action.payload.end,
            },
            dateModifications: [],
            dayOfWeekModifications: [],
          };
        });
      }

      // "dayOfWeek" modification scope only relevant for "weekly" mode
      if (state.dateAssignment.mode === "weekly") {
        return state.mealTemplates.map(mt => {
          if (mt.id !== action.payload.eventId) {
            return mt;
          }
          return {
            ...mt,
            ...mergeDayOfWeekModifications(action.payload, mt.dayOfWeekModifications, mt.dateModifications),
          };
        });
      }

      return state.mealTemplates;
    }
  }
};

export const dateAssignmentReducer = (state: MealPlanDateAssignment, action: MealPlanDateAssignmentAction): MealPlanDateAssignment => {
  switch (action.type) {
    case "ToggleDayOfWeekAssignment": {
      if (state.mode !== "weekly") {
        return state;
      }
      let dayOfWeekPriorities = state.dayOfWeekPriorities;
      const matchingDayOfWeek = state.dayOfWeekPriorities.find(d => d.dayOfWeek === action.payload.dayOfWeek);
      if (matchingDayOfWeek) {
        dayOfWeekPriorities = dayOfWeekPriorities.filter(d => d !== matchingDayOfWeek);
      } else {
        dayOfWeekPriorities = [...dayOfWeekPriorities, { dayOfWeek: action.payload.dayOfWeek, priority: DEFAULT_PRIORITY }];
      }

      return {
        ...state,
        dayOfWeekPriorities,
      };
    }
    case "EditDateAssignmentMode":
      return {
        ...state,
        mode: action.payload.mode,
      };
    case "ToggleIndividualDateAssignment": {
      if (state.mode !== "individual") {
        return state;
      }

      let individualDates = state.individualDates;
      if (state.individualDates.includes(action.payload.date)) {
        individualDates = individualDates.filter(d => d !== action.payload.date);
      } else {
        individualDates = individualDates.concat(action.payload.date);
      }

      return {
        ...state,
        individualDates,
      };
    }
    case "EditStartDate":
      const startDate =
        action.payload.date === null
          ? state.startDate
          : isValid(action.payload.date)
          ? serializeDate(action.payload.date)
          : state.startDate;
      return {
        ...state,
        startDate,
        startDateRaw: action.payload.date,
      };
    case "EditEndDate":
      const endDate =
        action.payload.date === null
          ? action.payload.date
          : isValid(action.payload.date)
          ? serializeDate(action.payload.date)
          : state.startDate;
      return {
        ...state,
        endDate,
        endDateRaw: action.payload.date,
      };
    case "ToggleEndDate":
      const disableEndDate = action.payload.disableEndDate;
      const newEndDate = !disableEndDate ? addMonths(new Date(state.startDate), 1) : null;

      return {
        ...state,
        disableEndDate,
        endDate: newEndDate ? serializeDate(newEndDate) : null,
        endDateRaw: newEndDate,
      };
  }
};

const mergeDateModifications = (
  { start, end, date }: EditEventTime["payload"],
  dateModifications: MealPlanDateModificationInput[]
): MealPlanDateModificationInput[] => {
  return dateModifications
    .filter(dm => dm.date !== date)
    .concat({
      start,
      end,
      date,
    });
};

const mergeDayOfWeekModifications = (
  { start, end, date }: EditEventTime["payload"],
  dayOfWeekModifications: MealPlanDayOfWeekModificationInput[],
  dateModifications: MealPlanDateModificationInput[]
): {
  dayOfWeekModifications: MealPlanDayOfWeekModificationInput[];
  dateModifications: MealPlanDateModificationInput[];
} => {
  const dayOfWeek = getDayOfWeek(date);
  const dayOfWeekResult = dayOfWeekModifications.filter(dm => dm.dayOfWeek !== dayOfWeek).concat({ start, end, dayOfWeek });

  // Edge case: when making a day of week modification to a date, remove any date modifications
  // (user expects to see the change on the event always take place, not have the edit hidden by override logic)
  // Also we do not currently support fine-grained date modification removals via mutation, so need to override with a new date modification
  const dateResult = dateModifications.map(dm => {
    if (dm.date === date) {
      return {
        start,
        end,
        date: dm.date,
      };
    }
    return dm;
  });

  return {
    dateModifications: dateResult,
    dayOfWeekModifications: dayOfWeekResult,
  };
};

const DEFAULT_PRIORITY = 0;

export const initCreateMealPlanCalendarState = ({
  athleteId,
  clientTimezone,
  schedules,
  teamSchedules,
}: {
  athleteId: string;
  clientTimezone: string;
  schedules: readonly ScheduleBasicFragment[];
  teamSchedules: readonly ScheduleBasicFragment[];
}): CreateMealPlanCalendarState => {
  return {
    type: "Create",
    athleteId,
    selectedScheduleId: null,
    schedules,
    teamSchedules,
    dateAssignment: initMealPlanDateAssignmentState(clientTimezone),
    mealTemplates: [],
    activityTemplates: [],
    loadedStartOfMonths: [],
    lockedDates: [],
    clientTimezone,
    otherMealPlanDateAssignments: [],
    suggestionsState: {
      isAutoSuggestionsEnabled: false,
      acceptedSuggestions: [],
      rejectedSuggestions: [],
    },
  };
};

export const initMealPlanDateAssignmentState = (clientTimezone: string): MealPlanCalendarState["dateAssignment"] => {
  const { date: startDate } = jsDateToDateAndTimeInTz(new Date(), clientTimezone);
  return {
    mode: "weekly",
    dayOfWeekPriorities: ORDERED_DAYS_OF_WEEK.map(dayOfWeek => ({ dayOfWeek, priority: DEFAULT_PRIORITY })),
    startDate: startDate,
    startDateRaw: parseDate(startDate),
    endDate: null,
    endDateRaw: null,
    disableEndDate: true,
    individualDates: [],
  };
};

export const initEditMealPlanCalendarState = ({
  athleteId,
  schedule,
  mealTemplates,
  activityTemplates,
  startDate,
  endDate,
  mealPlanId,
  dayOfWeekAssignments,
  clientTimezone,
  isAutoSuggestionsEnabled,
  mealPlanName,
}: {
  athleteId: string;
  mealPlanId: string;
  mealPlanName: string;
  clientTimezone: string;
  isAutoSuggestionsEnabled: boolean;
  schedule: ScheduleBasicFragment | null;
  mealTemplates: readonly MealTemplateScheduleFragment[];
  activityTemplates: readonly ActivityTemplateScheduleFragment[];
  startDate: string | null;
  endDate: string | null;
  dayOfWeekAssignments: readonly MealPlanDayOfWeekAssignmentFragment[];
}): EditMealPlanCalendarState => {
  let dateAssignment: MealPlanCalendarState["dateAssignment"];
  if (startDate) {
    dateAssignment = {
      mode: "weekly",
      dayOfWeekPriorities: dayOfWeekAssignments.map(({ dayOfWeekPriority }) => dayOfWeekPriority),
      startDate: startDate,
      startDateRaw: parseDate(startDate),
      endDate,
      endDateRaw: endDate !== null ? parseDate(endDate) : null,
      disableEndDate: endDate === null,
      individualDates: [],
    };
  } else {
    dateAssignment = {
      ...initMealPlanDateAssignmentState(clientTimezone),
      mode: "individual",
      individualDates: [],
    };
  }

  return {
    type: "Edit",
    athleteId,
    mealPlanId,
    mealPlanName,
    schedule,
    dateAssignment,
    loadedStartOfMonths: [],
    loadedIndividualDates: [],
    lockedDates: [],
    clientTimezone,
    otherMealPlanDateAssignments: [],
    suggestionsState: {
      isAutoSuggestionsEnabled,
      acceptedSuggestions: [],
      rejectedSuggestions: [],
    },
    mealTemplates: mealTemplates.map(mealTemplate => {
      const dayOfWeekModifications = dayOfWeekAssignments.flatMap(({ dayOfWeekPriority: { dayOfWeek }, mealTemplateModifications }) =>
        mealTemplateModifications.flatMap(m => (m.mealTemplate.id === mealTemplate.id ? { dayOfWeek, start: m.start, end: m.end } : []))
      );
      return {
        ...mealTemplate,
        resetModifications: false,
        dateModifications: [],
        dayOfWeekModifications,
      };
    }),
    activityTemplates: activityTemplates.map(activityTemplate => {
      const dayOfWeekModifications = dayOfWeekAssignments.flatMap(({ dayOfWeekPriority: { dayOfWeek }, activityTemplateModifications }) =>
        activityTemplateModifications.flatMap(a =>
          a.activityTemplate.id === activityTemplate.id ? { dayOfWeek, start: a.start, end: a.end } : []
        )
      );
      return {
        ...activityTemplate,
        resetModifications: false,
        dateModifications: [],
        dayOfWeekModifications,
      };
    }),
  };
};

export const initDateAssignmentState = (timezone: string, disableEndDate?: boolean): MealPlanDateAssignment => {
  const { date: startDate } = jsDateToDateAndTimeInTz(new Date(), timezone);
  return {
    mode: "weekly",
    dayOfWeekPriorities: ORDERED_DAYS_OF_WEEK.map(dayOfWeek => ({ dayOfWeek, priority: DEFAULT_PRIORITY })),
    startDate: startDate,
    startDateRaw: parseDate(startDate),
    endDate: null,
    endDateRaw: null,
    disableEndDate: disableEndDate ?? true,
    individualDates: [],
  };
};

export const initSuggestionsState = (isAutoSuggestionsEnabled: boolean): MealPlanSuggestions => {
  return {
    isAutoSuggestionsEnabled: isAutoSuggestionsEnabled,
    acceptedSuggestions: [],
    rejectedSuggestions: [],
  };
};
