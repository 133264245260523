import {
  ScheduleModalEditorState,
  ScheduleModalEditorAction,
  ChangeNameAction,
  ChangeStartAction,
  ChangeEndAction,
  AddMealAction,
  RemoveMealAction,
  ChangeMealTypeAction,
  MealPayload,
  AddActivityAction,
  RemoveActivityAction,
  ChangeActivityTypeAction,
  ActivityPaylod,
  ChangeScheduleNameAction,
} from "../../../reducers/ScheduleModalEditor";
import { Omit } from "../../../types";
import { Dispatch, createContext, useContext } from "react";
import { canSaveDate } from "@notemeal/utils-date-time";

export interface ScheduleModalEditorContextValue {
  state: ScheduleModalEditorState;
  onChangeScheduleName: (args: onChangeScheduleNameArgs) => void;
  // Meal functions
  onAddMeal: (args: onAddMealArgs) => void;
  onRemoveMeal: (args: onRemoveMealArgs) => void;
  onChangeMealName: (args: onChangeMealNameArgs) => void;
  onChangeMealStart: (args: onChangeMealStartArgs) => void;
  onChangeMealEnd: (args: onChangeMealEndArgs) => void;
  onChangeMealType: (args: onChangeMealTypeArgs) => void;
  // Activity function
  onAddActivity: (args: onAddActivityArgs) => void;
  onRemoveActivity: (args: onRemoveActivityArgs) => void;
  onChangeActivityName: (args: onChangeActivityNameArgs) => void;
  onChangeActivityStart: (args: onChangeActivityStartArgs) => void;
  onChangeActivityEnd: (args: onChangeActivityEndArgs) => void;
  onChangeActivityType: (args: onChangeActivityTypeArgs) => void;
}

export const ScheduleModalEditorContext = createContext<ScheduleModalEditorContextValue | null>(null);

type onChangeScheduleNameArgs = ChangeScheduleNameAction["payload"];

type onAddMealArgs = AddMealAction["payload"];
type onRemoveMealArgs = RemoveMealAction["payload"];
type onChangeMealNameArgs = ChangeNameAction["payload"] & MealPayload;
type onChangeMealStartArgs = ChangeStartAction["payload"] & MealPayload;
type onChangeMealEndArgs = ChangeEndAction["payload"] & MealPayload;
type onChangeMealTypeArgs = ChangeMealTypeAction["payload"];

type onAddActivityArgs = AddActivityAction["payload"];
type onRemoveActivityArgs = RemoveActivityAction["payload"];
type onChangeActivityNameArgs = ChangeNameAction["payload"] & ActivityPaylod;
type onChangeActivityStartArgs = ChangeStartAction["payload"] & ActivityPaylod;
type onChangeActivityEndArgs = ChangeEndAction["payload"] & ActivityPaylod;
type onChangeActivityTypeArgs = ChangeActivityTypeAction["payload"];

export const dispatchToCallbacks = (dispatch: Dispatch<ScheduleModalEditorAction>): Omit<ScheduleModalEditorContextValue, "state"> => ({
  onChangeScheduleName: (payload: onChangeScheduleNameArgs) => dispatch({ type: "CHANGE_SCHEDULE_NAME", payload }),
  // Meal functions
  onAddMeal: (payload: onAddMealArgs) => dispatch({ type: "ADD_MEAL", payload }),
  onRemoveMeal: (payload: onRemoveMealArgs) => dispatch({ type: "REMOVE_MEAL", payload }),
  onChangeMealName: (payload: onChangeMealNameArgs) => dispatch({ type: "CHANGE_NAME", payload }),
  onChangeMealStart: (payload: onChangeMealStartArgs) => dispatch({ type: "CHANGE_START", payload }),
  onChangeMealEnd: (payload: onChangeMealEndArgs) => dispatch({ type: "CHANGE_END", payload }),
  onChangeMealType: (payload: onChangeMealTypeArgs) => dispatch({ type: "CHANGE_MEAL_TYPE", payload }),
  // Activity functions
  onAddActivity: (payload: onAddActivityArgs) => dispatch({ type: "ADD_ACTIVITY", payload }),
  onRemoveActivity: (payload: onRemoveActivityArgs) => dispatch({ type: "REMOVE_ACTIVITY", payload }),
  onChangeActivityName: (payload: onChangeActivityNameArgs) => dispatch({ type: "CHANGE_NAME", payload }),
  onChangeActivityStart: (payload: onChangeActivityStartArgs) => dispatch({ type: "CHANGE_START", payload }),
  onChangeActivityEnd: (payload: onChangeActivityEndArgs) => dispatch({ type: "CHANGE_END", payload }),
  onChangeActivityType: (payload: onChangeActivityTypeArgs) => dispatch({ type: "CHANGE_ACTIVITY_TYPE", payload }),
});

export const scheduleFormToSaveTooltips = (state: ScheduleModalEditorState, editMode: boolean = false): string[] => {
  let tooltips = [];
  const { meals, activities, scheduleName } = state;
  const hasIncompleteMeals = ![...meals].every(e =>
    e.name && e.start && canSaveDate(e.startValue) && e.end && canSaveDate(e.endValue) && e.type ? true : false
  );
  if (hasIncompleteMeals) {
    tooltips.push("Some meals are incomplete");
  }
  const hasIncompleteActivities = ![...activities].every(e =>
    e.name && e.start && canSaveDate(e.startValue) && e.end && canSaveDate(e.endValue) && e.type ? true : false
  );
  if (hasIncompleteActivities) {
    tooltips.push("Some activities are incomplete");
  }
  const isMissingName = scheduleName === "";
  if (isMissingName && !editMode) {
    tooltips.push("Schedule name is required");
  }
  return tooltips;
};

export const useScheduleModalEditorContext = (): ScheduleModalEditorContextValue => {
  const value = useContext(ScheduleModalEditorContext);
  if (value === null) {
    throw Error("Missing ScheduleModalEditorContextProvider!");
  }
  return value;
};
