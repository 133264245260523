import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { NutritionColorIcon, TeamworksBadgeIcon } from "@notemeal/shared-ui";
import { TWTabGroup, TabType } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { useEffect, useState } from "react";

export const ThemeViewerTabGroup = () => {
  const [iconChecked, setIconChecked] = useState(false);
  const [customChecked, setCustomChecked] = useState(false);
  const [scrollChecked, setScrollChecked] = useState(false);
  const [selected, setSelected] = useState<string>();
  const [tabs, setTabs] = useState<TabType[]>([]);

  useEffect(() => {
    const TAB1 = "tab1";
    const TAB2 = "tab2";
    const TAB3 = "tab3";
    const TAB4 = "tab4";
    const TAB5 = "tab5";
    const TAB6 = "tab6";
    const TAB7 = "tab7";
    const TAB8 = "tab8";
    const TAB9 = "medium text";
    const TAB10 = "some long text";
    const ICON_TAB = {
      label: "Templates",
      icon: <NutritionColorIcon />,
    };
    const KIOSK_CHECKIN = "Kiosk Check-in";
    const CUSTOM_TAB = {
      label: KIOSK_CHECKIN,
      element: (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <TeamworksBadgeIcon />
          x
          <NutritionColorIcon />
          {KIOSK_CHECKIN}
        </Box>
      ),
    };
    const iconTabs = [ICON_TAB];
    const customTabs = [CUSTOM_TAB];
    const standardTabs = [TAB1, TAB2, TAB3];
    const extraTabs = [TAB4, TAB5, TAB6, TAB7, TAB8, TAB9, TAB10];

    setTabs([...(iconChecked ? iconTabs : []), ...(customChecked ? customTabs : []), ...standardTabs, ...(scrollChecked ? extraTabs : [])]);
  }, [iconChecked, customChecked, scrollChecked]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h2">TabGroup</Typography>
      <Box sx={{ alignItems: "flex-start", display: "flex", gap: 2 }}>
        <FormControlLabel control={<Checkbox checked={iconChecked} onChange={() => setIconChecked(!iconChecked)} />} label="Icon" />
        <FormControlLabel control={<Checkbox checked={customChecked} onChange={() => setCustomChecked(!customChecked)} />} label="Custom" />
        <FormControlLabel control={<Checkbox checked={scrollChecked} onChange={() => setScrollChecked(!scrollChecked)} />} label="Scroll" />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h3">New</Typography>
        <TWTabGroup
          sx={scrollChecked ? { width: 525 } : {}}
          tabs={tabs}
          onSelected={selected => {
            setSelected(selected);
          }}
        />
        <Typography>{`Selected tab: ${selected}`}</Typography>
      </Box>
    </Box>
  );
};
