import { inputToNumber } from "@notemeal/shared-ui";
import { v4 as uuid } from "uuid";

export interface AddGoalState {
  __typename: "AddGoalState";
  goalTypeId: string | null;
  id: string;
  kcalOffset: number | null;
  kcalOffsetInput: string | null;
  name: string | null;
}

export interface MealPlanTemplateGoalState {
  goalTypeId: string;
  id: string;
  kcalOffset: number;
  kcalOffsetInput: string;
  name: string;
  __typename: "MealPlanTemplateGoalState";
}

export type CombinedGoalState = MealPlanTemplateGoalState | AddGoalState;

export interface GoalsState {
  addedGoals: AddGoalState[];
  editedGoals: MealPlanTemplateGoalState[];
}

interface AddGoal {
  type: "ADD_GOAL";
  payload: AddGoalState;
}

interface RemoveGoal {
  type: "REMOVE_GOAL";
  payload: string;
}

interface EditGoal {
  type: "EDIT_GOAL";
  payload: CombinedGoalState;
}

export type GoalsAction = AddGoal | RemoveGoal | EditGoal;

export const goalsReducer = (state: GoalsState, action: GoalsAction): GoalsState => {
  switch (action.type) {
    case "ADD_GOAL":
      return {
        ...state,
        addedGoals: [...state.addedGoals, action.payload],
      };
    case "EDIT_GOAL":
      const editedGoal = action.payload;
      if (editedGoal.__typename === "AddGoalState") {
        const addedGoals = state.addedGoals.map(goal => (goal.id === editedGoal.id ? editedGoal : goal));
        return {
          ...state,
          addedGoals,
        };
      } else {
        const editedGoals = state.editedGoals.map(goal => (goal.id === editedGoal.id ? editedGoal : goal));

        return {
          ...state,
          editedGoals,
        };
      }
    case "REMOVE_GOAL":
      const filterFunc = (goal: CombinedGoalState) => goal.id !== action.payload;
      return {
        addedGoals: state.addedGoals.filter(filterFunc),
        editedGoals: state.editedGoals.filter(filterFunc),
      };
    default:
      break;
  }
  return state;
};

export const getCanSaveGoalState = (state: GoalsState) => {
  const tooltips = [];
  const { addedGoals, editedGoals } = state;

  if (addedGoals.length === 0 && editedGoals.length === 0) {
    tooltips.push("At least one weight target must be defined.");
  } else {
    const hasValidAddedGoals = [...addedGoals, ...editedGoals].every(goal => {
      const { goalTypeId, kcalOffset, kcalOffsetInput, name } = goal;
      const hasValidType = goalTypeId !== null;
      const hasValidKcalOffset = kcalOffset !== null && kcalOffsetInput !== null && kcalOffset === inputToNumber(kcalOffsetInput);
      const hasValidName = name !== null && name !== "";
      return hasValidType && hasValidKcalOffset && hasValidName;
    });

    if (!hasValidAddedGoals) {
      tooltips.push("All weight targets must have a type, name, and KcalOffset");
    }
  }

  return tooltips;
};

export const convertEditorStateToSaveState = (state: GoalsState): MealPlanTemplateGoalState[] => {
  const convertedAddGoals: MealPlanTemplateGoalState[] = state.addedGoals.flatMap(goal => {
    const { goalTypeId, kcalOffset, kcalOffsetInput, name } = goal;

    if (goalTypeId === null || kcalOffset === null || kcalOffsetInput === null || name === null) {
      return [];
    }
    return {
      ...goal,
      goalTypeId,
      kcalOffset,
      kcalOffsetInput,
      name,
      __typename: "MealPlanTemplateGoalState",
    };
  });
  return [...state.editedGoals, ...convertedAddGoals];
};

export const getInitialGoalsState = (goals: MealPlanTemplateGoalState[]): GoalsState => ({
  editedGoals: goals,
  addedGoals: [],
});

export const getBlankAddGoal = (): AddGoalState => ({
  __typename: "AddGoalState",
  goalTypeId: null,
  id: uuid(),
  kcalOffset: null,
  kcalOffsetInput: null,
  name: null,
});
