import { invalidAge } from "@notemeal/shared-ui";
import { maybeAthleteBirthDateToAge } from "@notemeal/shared-utils-macro-protocol";
import ActionDialog from "apps/web/src/componentLibrary/ActionDialog/Dialog";
import MacroProtocolMissingDataDialog from "apps/web/src/components/MacroProtocol/MissingDataDialog";
import LoadingBackdrop from "apps/web/src/components/universal/LoadingBackdrop";
import { useEffect, useState } from "react";
import { ExchangeSetFragment, useAthleteMealPlansQuery, useAthletesMealPlansQuery, useMealPlanBulkCopyQuery } from "../../../../types";
import Content from "./Content";

interface CopyMealPlanDialogLoaderProps {
  athleteId: string;
  exchangeSets: readonly ExchangeSetFragment[];
  open: boolean;
  onClose: () => void;
}

const CopyMealPlanDialogLoader = ({ athleteId, onClose, open }: CopyMealPlanDialogLoaderProps) => {
  const teamsResult = useMealPlanBulkCopyQuery();
  const athleteResult = useAthleteMealPlansQuery({ variables: { athleteId } });
  const athletesResult = useAthletesMealPlansQuery();
  const [missingMacroDialogOpen, setMissingMacroDialogOpen] = useState(false);

  useEffect(() => {
    if (!athleteResult.loading && athleteResult.data) {
      const { mostRecentAnthropometryEntry, birthDate, currentGoal } = athleteResult.data.athlete;
      if (
        !mostRecentAnthropometryEntry ||
        !currentGoal ||
        !(mostRecentAnthropometryEntry.leanBodyMass || mostRecentAnthropometryEntry.percentBodyFat) ||
        !mostRecentAnthropometryEntry.height ||
        !birthDate
      ) {
        setMissingMacroDialogOpen(true);
      }
    }
  }, [setMissingMacroDialogOpen, athleteResult]);

  if (
    teamsResult.loading ||
    !teamsResult.data ||
    athleteResult.loading ||
    !athleteResult.data ||
    athletesResult.loading ||
    !athletesResult.data
  ) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  const {
    mostRecentAnthropometryEntry,
    currentGoal,
    birthDate,
    team: { sport, name: teamName },
  } = athleteResult.data.athlete;

  const exchangeSets = teamsResult.data.exchangeSets;

  return (
    <ActionDialog open={open} onClose={onClose}>
      {mostRecentAnthropometryEntry && (
        <Content
          athleteId={athleteId}
          anthropometrySnapshot={{
            ...mostRecentAnthropometryEntry,
            age: maybeAthleteBirthDateToAge(birthDate),
          }}
          sportName={teamName || (sport ? sport.name : "")}
          currentGoal={currentGoal}
          exchangeSets={exchangeSets}
          onClose={onClose}
        />
      )}
      <MacroProtocolMissingDataDialog
        athleteId={athleteId}
        open={missingMacroDialogOpen}
        onContinue={() => setMissingMacroDialogOpen(false)}
        onClose={onClose}
        noAnthropometry={mostRecentAnthropometryEntry === null}
        noBirthdate={birthDate === null}
        invalidAge={invalidAge(birthDate)}
        noGoal={currentGoal === null}
        incompleteAnthropometry={Boolean(
          mostRecentAnthropometryEntry &&
            (mostRecentAnthropometryEntry.leanBodyMass === null || mostRecentAnthropometryEntry.height === null)
        )}
      />
    </ActionDialog>
  );
};

export default CopyMealPlanDialogLoader;
