import { Button, Divider, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  getMenuSelectionItemServingAmounts, getNextPosition, MenuSelectionItemWithAppearance,
  newId,
  RESTAURANT_MENU_LINK_MENU_ITEM_APPEARANCE,
  RestaurantMenuLinkPlateItemWithAppearance
} from "@notemeal/shared-ui";
import MealMacrosAndNutrients from "../../../../../components/Macros/MealMacrosAndNutrients";
import MenuSelectionItemEdit from "../../../../../components/MenuSelectionItem/Edit";
import MenuSelectionItemNew from "../../../../../components/MenuSelectionItem/New";
import { RestaurantMenuSectionPreviewFragment } from "../../../../../types";
import PlateItemForm from "./Item/Form";
import SectionList from "./Section/List";
import { usePlateDialogState } from "./usePlateDialogState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
    },
    menu: {
      flexGrow: 1,
      overflowY: "auto",
      minHeight: "100%",
    },
    items: {
      marginLeft: theme.spacing(),
      minWidth: 250,
      maxWidth: 250,
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
    },
    itemsForm: {
      height: "100%",
      overflowY: "auto",
    },
    saveButton: {
      margin: theme.spacing(2, 0),
    },
    spacer: {
      flexGrow: 1,
    },
  })
);

interface RestaurantMenuLinkPlateDialogContentProps {
  plateItems: readonly RestaurantMenuLinkPlateItemWithAppearance[];
  onChangePlateItems: (plateItems: readonly RestaurantMenuLinkPlateItemWithAppearance[], editedItemId?: string) => void;
  restaurantMenuSections: readonly RestaurantMenuSectionPreviewFragment[];
  onSave: () => void;
}

const RestaurantMenuLinkPlateDialogContent = ({
  plateItems,
  onChangePlateItems,
  restaurantMenuSections,
  onSave,
}: RestaurantMenuLinkPlateDialogContentProps) => {
  const classes = useStyles();

  const [state, setState] = usePlateDialogState();

  const handleAddItem = (plateItem: MenuSelectionItemWithAppearance) => {
    onChangePlateItems([...plateItems, { ...plateItem, position: getNextPosition(plateItems) }]);
    setState({
      type: "menuSections",
    });
  };

  const handleEditItem = (plateItem: RestaurantMenuLinkPlateItemWithAppearance) => {
    onChangePlateItems(
      plateItems.map(pi => (pi.id === plateItem.id ? { ...pi, ...plateItem } : pi)),
      plateItem.id
    );
    setState({
      type: "editMenuItem",
      plateItem,
    });
  };

  const handleRemoveItem = (id: string) => {
    onChangePlateItems(plateItems.filter(i => i.id !== id));
    setState({ type: "menuSections" });
  };

  const plateItemsForMacros = state?.type === "newMenuItem" && state.newPlateItem ? [...plateItems, state.newPlateItem] : plateItems;

  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        {state.type === "menuSections" ? (
          <SectionList
            restaurantMenuSections={restaurantMenuSections}
            onSelectItem={menuItemAppearance =>
              setState({
                type: "newMenuItem",
                // TODO: Remove this when we override the MenuItemAppearance.{availableForOrder,allowSpecialRequests,maxAmount} on server
                // For RestaurantMenuSectionMenuItemAppearances
                menuItemAppearance: {
                  ...menuItemAppearance,
                  ...RESTAURANT_MENU_LINK_MENU_ITEM_APPEARANCE,
                },
                newPlateItem: null,
              })
            }
          />
        ) : state.type === "newMenuItem" ? (
          <MenuSelectionItemNew
            menuSelectionItemId={state.newPlateItem?.id || newId()}
            selectionType="Plate"
            onChange={newPlateItem => {
              setState({
                ...state,
                newPlateItem: {
                  ...newPlateItem,
                  position: getNextPosition(plateItems),
                },
              });
            }}
            maxAmount={null}
            availableForOrder={state.menuItemAppearance.availableForOrder}
            allowSpecialRequests={state.menuItemAppearance.allowSpecialRequests}
            menuItem={state.menuItemAppearance.menuItem}
            onDone={handleAddItem}
            onBack={() =>
              setState({
                type: "menuSections",
              })
            }
          />
        ) : state.type === "editMenuItem" ? (
          <MenuSelectionItemEdit
            menuSelectionItem={state.plateItem}
            menuSections={restaurantMenuSections}
            onEdit={item =>
              handleEditItem({
                ...item,
                position: state.plateItem.position,
              })
            }
            onBack={() =>
              setState({
                type: "menuSections",
              })
            }
            // Max amount constraints not considered for plates
            currentAmountForMenuItem={0}
            menuSectionAmountsForSelection={{}}
          />
        ) : null}
      </div>
      <Divider orientation="vertical" flexItem />
      <div className={classes.items}>
        <PlateItemForm
          className={classes.itemsForm}
          plateItems={plateItems}
          onSelectItem={plateItem =>
            setState({
              type: "editMenuItem",
              plateItem,
            })
          }
          selectedItemId={state.type === "editMenuItem" ? state.plateItem.id : null}
          onRemoveItem={handleRemoveItem}
        />

        <div className={classes.spacer} />

        <Button
          onClick={onSave}
          className={classes.saveButton}
          disabled={plateItems.length === 0 || state.type !== "menuSections"}>
          Save
        </Button>

        <MealMacrosAndNutrients mealServingAmounts={plateItemsForMacros.flatMap(getMenuSelectionItemServingAmounts)} targetMacros={null} />
      </div>
    </div>
  );
};

export default RestaurantMenuLinkPlateDialogContent;
