import { Box, Divider, InputAdornment, SxProps, TextField, Typography } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import { useMyScoringSystemQuery } from "apps/web/src/types";
import { Dispatch, useState } from "react";
import NutrientAmountModal from "../../NutrientAmount/Modal";
import Details from "./Details";
import Images from "./Images/Images";
import { Ingredients } from "./Ingredients";
import { Instructions } from "./Instructions";
import RecipeFormServing from "./Serving";
import { UnmatchedIngredientsWarning } from "./UnmatchedIngredientsWarning";
import { RecipeFormAction, RecipeFormState } from "./utils";

interface RecipeFormProps {
  state: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  sx?: SxProps;
  disabled?: boolean;
  forStaff?: boolean;
  onAiGenerateClicked: null | (() => void);
  onAiTranscribeClicked?: () => void;
  openScaleRecipe: () => void;
}

const RecipeForm = ({
  state,
  dispatch,
  sx,
  disabled,
  forStaff,
  onAiGenerateClicked,
  onAiTranscribeClicked,
  openScaleRecipe,
}: RecipeFormProps) => {
  const [nutrientsOpen, setNutrientsOpen] = useState(false);

  const { data: scoringSystemData, loading: loadingScoringSystem } = useMyScoringSystemQuery();
  const scoringSystem = scoringSystemData?.myScoringSystem;

  return (
    <Box sx={{ gap: 3, display: "flex", flexDirection: "column" }}>
      {(state.unmatchedIngredients.unmatchedName.length > 0 || state.unmatchedIngredients.unmatchedServings.length > 0) && (
        <UnmatchedIngredientsWarning unmatchedIngredients={state.unmatchedIngredients} />
      )}

      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "stretch",
          textOverflow: "auto",
          ...sx,
        }}
      >
        {/** Left panel */}
        <Box sx={{ flex: 3 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {loadingScoringSystem ? (
              <Loading />
            ) : (
              <Details
                forStaff={forStaff}
                disabled={disabled}
                state={state}
                dispatch={dispatch}
                scoringSystem={scoringSystem} />
            )}
            <RecipeFormServing
              disabled={disabled}
              state={state}
              dispatch={dispatch}
              openScaleRecipe={openScaleRecipe}
              forStaff={forStaff}
            />
          </Box>
        </Box>

        {/** Right panel */}
        <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }}>
          <Images
            disabled={disabled}
            dispatch={dispatch}
            state={state} />
        </Box>
      </Box>

      <Divider />

      {/** Ingredients and Directions/Notes */}
      <Box sx={{ display: "flex", flexDirection: "row", gap: 6 }}>
        <Box sx={{ flex: 1 }}>
          <Ingredients
            disabled={disabled}
            state={state}
            dispatch={dispatch}
            setNutrientsOpen={setNutrientsOpen}
            onAiGenerateClicked={onAiGenerateClicked}
            onAiTranscribeClicked={onAiTranscribeClicked}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Instructions
            disabled={disabled}
            steps={state.steps}
            dispatch={dispatch} />
          <Typography variant="h4" sx={{ pt: 4 }}>
            Notes
          </Typography>
          <TextField
            disabled={disabled}
            fullWidth
            value={state.note}
            onChange={e =>
              dispatch({
                type: "CHANGE_NOTE",
                value: e.target.value,
              })
            }
            placeholder="Add a note to the recipe"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" disablePointerEvents>
                  <Typography fontWeight="bold" color={disabled ? "text.disabled" : "inherit"}>
                    *
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>

      {nutrientsOpen && (
        <NutrientAmountModal
          open={nutrientsOpen}
          onClose={() => setNutrientsOpen(false)}
          servingAmounts={state.ingredients.map(sa => ({
            ...sa,
            amount: sa.amount / state.serving.perRecipeYield,
          }))}
        />
      )}
    </Box>
  );
};

export default RecipeForm;
