import { Box, Button, Dialog, DialogActions, DialogContent, Slider, TextField, Typography, useTheme } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { getDefaultEndTime, jsDateToDateAndTimeInTz, parseTime, safeSerializeTime } from "@notemeal/utils-date-time";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import ActivityTypeSelect from "apps/web/src/components/Activity/TypeSelect";
import { addMinutes } from "date-fns";
import React, { useState } from "react";
import { ActivityType, useEditFoodLogActivityRpeMutation, useEditFoodLogActivityTimeWithTimezoneMutation } from "../../../../../types";
import { FoodLogActivityTimelineItem } from "../../utils";
import { rpeToColor } from "../FoodLogActivityUtils";

interface FoodLogActivityEditModalProps {
  onClose: () => void;
  open: boolean;
  foodLogActivity: FoodLogActivityTimelineItem;
}

const FoodLogActivityEditModal = ({ onClose, open, foodLogActivity }: FoodLogActivityEditModalProps) => {
  const theme = useTheme();
  const {
    palette: { grey },
  } = theme;
  const { time: initStart } = jsDateToDateAndTimeInTz(new Date(foodLogActivity.start), foodLogActivity.timezone);
  const { time: initEnd } = jsDateToDateAndTimeInTz(
    addMinutes(new Date(foodLogActivity.start), foodLogActivity.durationInMinutes),
    foodLogActivity.timezone
  );
  const [startTime, setStart] = useState<Date | null>(parseTime(initStart));
  const [endTime, setEnd] = useState<Date | null>(parseTime(initEnd));

  const [name, setName] = useState(foodLogActivity.name);
  const [type, setType] = useState<ActivityType>(foodLogActivity.activityType);
  const [rpe, setRpe] = useState<number | null>(foodLogActivity.rpe);

  const handleSetStart = (startTime: Date | null) => {
    setStart(startTime);
    const newStart = safeSerializeTime(startTime);
    if (newStart) {
      setEnd(parseTime(getDefaultEndTime(newStart)));
    }
  };

  const handleSetEnd = (endTime: Date | null) => {
    setEnd(endTime);
  };

  const [editFoodLogActivityRpe] = useEditFoodLogActivityRpeMutation();
  const [editFoodLogActivityTimeWithTimezone] = useEditFoodLogActivityTimeWithTimezoneMutation();

  const handleSave = async () => {
    const start = safeSerializeTime(startTime);
    const end = safeSerializeTime(endTime);
    if (start && end) {
      const primaryPromise = editFoodLogActivityTimeWithTimezone({
        variables: {
          input: {
            foodLogActivityId: foodLogActivity.id,
            activity: {
              start,
              end,
              name,
              type,
            },
            timezone: foodLogActivity.timezone,
          },
        },
      });
      if (rpe !== null) {
        await Promise.all([
          primaryPromise,
          editFoodLogActivityRpe({
            variables: {
              input: {
                foodLogActivityId: foodLogActivity.id,
                rpe,
              },
            },
          }),
        ]);
      } else {
        await primaryPromise;
      }
      onClose();
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Activity" onClose={onClose} />
      <DialogContent sx={{ pt: 0, display: "flex", flexDirection: "column", gap: 4 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            sx={{ flex: 1 }}
            label="Name"
            value={name}
            onChange={e => setName(e.target.value)}
            margin="dense" />
          <ActivityTypeSelect
            sx={{ flex: 1 }}
            value={type}
            onChange={type => setType(type)} />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <DesktopTimePicker
            sx={{ flex: 1 }}
            label="Start"
            value={startTime}
            onChange={handleSetStart} />
          <DesktopTimePicker
            sx={{ flex: 1 }}
            label="End"
            value={endTime}
            onChange={handleSetEnd} />
        </Box>
        <Box sx={{ pt: 2, px: 2, display: "flex", gap: 2, alignItems: "center" }}>
          <Typography variant="body2Medium">RPE</Typography>
          <Slider
            sx={{ color: rpe ? rpeToColor(theme, rpe) : grey[400] }}
            valueLabelDisplay="on"
            value={rpe || 0}
            step={1}
            marks
            onChange={(e, value) => setRpe(value as number)}
            min={0}
            max={10}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FoodLogActivityEditModal;
