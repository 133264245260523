import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { inputToNumber } from "@notemeal/shared-ui";
import { Semibold } from "apps/web/src/pages/Theme/Common/TypographyCommon";
import { Dispatch } from "react";
import { GoalTypeFragment } from "../../../../types";
import { CombinedGoalState, GoalsAction, GoalsState, getBlankAddGoal } from "./goalsReducer";

interface GoalsProps {
  state: GoalsState;
  dispatch: Dispatch<GoalsAction>;
  goalTypes: readonly GoalTypeFragment[];
}

const Goals = ({ state, dispatch, goalTypes }: GoalsProps) => {
  const combinedGoals = [...state.editedGoals, ...state.addedGoals];
  const onEditGoal = (goal: CombinedGoalState) => dispatch({ type: "EDIT_GOAL", payload: goal });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography>Create weight targets that athlete will choose between when using meal plan template.</Typography>
      <Box sx={{ p: 1, backgroundColor: "grey.200", borderRadius: "5px", alignSelf: "start" }}>
        <Typography variant="body2">
          * Athletes will not need to pick between weight targets if only one is present, so only specify more than one if athlete weight
          target selection is desired.
        </Typography>
      </Box>
      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
        <TableHead>
          <TableRow sx={{ p: 2, fontWeight: Semibold, backgroundColor: "greyscale.200" }}>
            <TableCell sx={{ width: 180 }}>Type</TableCell>
            <TableCell sx={{ width: "auto" }}>Name</TableCell>
            <TableCell sx={{ width: 120 }}>KCal Offset</TableCell>
            <TableCell sx={{ width: 80 }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {combinedGoals.map(goal => (
            <TableRow sx={{ borderBottom: "1px solid" }} key={goal.id}>
              <TableCell padding="none" sx={{ p: 1 }}>
                <Select
                  sx={{ width: "100%" }}
                  autoFocus
                  inputProps={{ width: 80 }}
                  displayEmpty
                  renderValue={
                    goal.goalTypeId
                      ? undefined
                      : () => (
                          <Typography variant="body2" sx={{ py: "2px", color: "text.secondary" }}>
                            Weight Target Type
                          </Typography>
                        )
                  }
                  value={goal.goalTypeId}
                  label="Weight Target Type"
                  onChange={({ target: { value } }) => {
                    const { name, defaultKcalOffset: kcalOffset } = goalTypes.find(goalType => goalType.id === value) || {
                      name: "",
                      defaultKcalOffset: 0,
                    };
                    const kcalOffsetInput = String(kcalOffset);
                    return onEditGoal({
                      ...goal,
                      goalTypeId: value as string,
                      name,
                      kcalOffsetInput,
                      kcalOffset,
                    });
                  }}
                >
                  {goalTypes.map(gt => (
                    <MenuItem key={gt.id} value={gt.id}>
                      {gt.name}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell padding="none" sx={{ p: 1 }}>
                <TextField
                  sx={{ mt: 0, width: "100%" }}
                  placeholder="Name"
                  value={goal.name}
                  onChange={event => onEditGoal({ ...goal, name: event.target.value })}
                />
              </TableCell>
              <TableCell padding="none" sx={{ p: 1 }}>
                <TextField
                  sx={{ mt: 0, width: "100%" }}
                  placeholder="###"
                  value={goal.kcalOffsetInput}
                  onChange={event => {
                    const newValue = event.target.value;
                    onEditGoal({ ...goal, kcalOffsetInput: newValue, kcalOffset: inputToNumber(newValue) ?? goal.kcalOffset ?? 0 });
                  }}
                />
              </TableCell>
              <TableCell padding="none" sx={{ p: 1 }}>
                <IconButton onClick={() => dispatch({ type: "REMOVE_GOAL", payload: goal.id })} size="large">
                  <DeleteIcon color="error" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell padding="none" sx={{ p: 1 }}>
              <Button
                autoFocus
                variant="text"
                startIcon={<AddIcon />}
                onClick={() => dispatch({ type: "ADD_GOAL", payload: getBlankAddGoal() })}
              >
                <Typography>Add New Weight Target</Typography>
              </Button>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default Goals;
