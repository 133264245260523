import { List, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MenuItemChoiceForModal } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import ChoiceRadioItem from "./ChoiceRadioItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: theme.spacing(2),
    },
    amountButtons: {
      padding: theme.spacing(),
    },
  })
);

interface MenuItemChoiceRadioListProps {
  menuItemChoice: MenuItemChoiceForModal;
  onChangeOption: (menuItemChoiceOptionId: string) => void;
  onIncrementOption: (menuItemChoiceOptionId: string) => void;
  onDecrementOption: (menuItemChoiceOptionId: string) => void;
}

const MenuItemChoiceRadioList = ({
  menuItemChoice,
  onChangeOption,
  onDecrementOption,
  onIncrementOption,
}: MenuItemChoiceRadioListProps) => {
  const classes = useStyles();

  const selectedOption = menuItemChoice.options.find(o => o.menuSelectionItemOption !== null);
  const selectedOptionId = selectedOption ? selectedOption.id : "";

  return (
    <div>
      <div className={classes.label}>
        <div>
          <Typography>
            <strong>{menuItemChoice.name}</strong>
          </Typography>
          <Typography>Select 1</Typography>
        </div>
        <Typography>
          <strong>Required</strong>
        </Typography>
      </div>

      <List dense>
        {sortByKey(menuItemChoice.options, "position").map(mico => (
          <ChoiceRadioItem
            key={mico.id}
            onChangeOption={onChangeOption}
            onIncrementOption={onIncrementOption}
            onDecrementOption={onDecrementOption}
            mico={mico}
            selectedOptionId={selectedOptionId}
          />
        ))}
      </List>
    </div>
  );
};

export default MenuItemChoiceRadioList;
