import { Box, Button, Typography } from "@mui/material";
import { MenuItemForModal, MenuSelectionItemWithAppearance, ScoreBadge, getNewMenuSelectionItemWithAppearance, useAthleteHighlightFromMenuItemPreview, useMenuSelectionItemForm } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { ReactNode } from "react";
import { useSnackbar } from "../Snackbar/SnackbarContext";
import ItemAmountButtons from "../universal/ItemAmountButtons";
import ChoiceCheckboxList from "./ChoiceCheckboxList";
import ChoiceRadioList from "./ChoiceRadioList";
import SpecialRequestsInput from "./SpecialRequests/Input";

interface MenuSelectionItemNewFormProps {
  menuSelectionItemId: string;
  menuItem: MenuItemForModal;
  maxAmount: number | null; // Result of getMenuItemMaxAmount function, will change based on amount in diningStation
  availableForOrder: boolean;
  allowSpecialRequests: boolean;
  selectionType: string;
  onDone: (menuSelectionItem: MenuSelectionItemWithAppearance) => void;
  onChange: (menuSelectionItem: MenuSelectionItemWithAppearance) => void;
  children?: ReactNode;
}

const MenuSelectionItemNewForm = ({
  menuSelectionItemId,
  menuItem: initMenuItem,
  onDone,
  selectionType,
  maxAmount,
  availableForOrder,
  allowSpecialRequests,
  onChange,
  children,
}: MenuSelectionItemNewFormProps) => {
  const { setMessage } = useSnackbar();

  const {
    amount,
    menuItem,
    onIncrement,
    onDecrement,
    onSubmit,
    getMenuItemChoiceOptionCallbacks,
    specialRequests,
    onChangeSpecialRequests,
  } = useMenuSelectionItemForm({
    menuSelectionItemId,
    initMenuItem,
    maxAmount,
    availableForOrder,
    allowSpecialRequests,
    onDone,
    onChange: (menuItem, amount, specialRequests) =>
      onChange(
        getNewMenuSelectionItemWithAppearance({
          menuSelectionItemId,
          menuItem,
          availableForOrder,
          maxAmount,
          allowSpecialRequests,
          amount,
          specialRequests,
          percentConsumed: null,
        })
      ),
    onError: errors => setMessage("error", errors.join(", ")),
  });

  const { highlightType: highlightColor } = useAthleteHighlightFromMenuItemPreview(menuItem);
  const { name, description, choices, servingName, score } = menuItem;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ pb: 1, display: "flex" }}>
        <>
          <Typography
            variant="h3"
            sx={
              highlightColor === "Red"
                ? {
                    color: "error.main",
                    "&:focus": {
                      color: "error.light",
                    },
                  }
                : {}
            }
          >
            {name}
          </Typography>
          <ScoreBadge score={score} sx={{ ml: 0.5 }} />
        </>

        {description && <Typography color="textSecondary">{description}</Typography>}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }}>
        {sortByKey(choices, "position").map(mic => {
          const callbacks = getMenuItemChoiceOptionCallbacks(mic);
          if (callbacks.type === "radio_button") {
            return (
              <ChoiceRadioList
                key={mic.id}
                menuItemChoice={mic}
                onChangeOption={callbacks.onChangeOption}
                onIncrementOption={callbacks.onIncrementOption}
                onDecrementOption={callbacks.onDecrementOption}
              />
            );
          } else {
            return (
              <ChoiceCheckboxList
                key={mic.id}
                menuItemChoice={mic}
                onCheckOption={callbacks.onCheckOption}
                onIncrementOption={callbacks.onIncrementOption}
                onDecrementOption={callbacks.onDecrementOption}
              />
            );
          }
        })}
        {allowSpecialRequests && <SpecialRequestsInput specialRequests={specialRequests} onChange={onChangeSpecialRequests} />}
        {children}
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ pt: 2, px: 1, pb: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <ItemAmountButtons
          amount={amount}
          minAmount={1}
          maxAmount={maxAmount}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          text={` ${servingName}`}
          sx={{ p: 2 }}
        />
        <Button onClick={() => onSubmit()}>Add to {selectionType}</Button>
      </Box>
    </Box>
  );
};

export default MenuSelectionItemNewForm;
