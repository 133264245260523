import {
  EditMacroProtocolAction,
  EditImperialMacroProtocolState,
  EditMetricMacroProtocolState,
  editMacroProtocolReducer,
  editMetricMacroProtocolReducer,
} from "@notemeal/shared-ui";
import { Macros } from "@notemeal/shared-utils-macro-protocol";
import { Dispatch, useReducer } from "react";
import { AnthropometryEntryFragment, FullGoalFragment, FullMacroProtocolFragment, GoalTypeFragment, RmrMethod } from "../../types";
import {
  macroProtocolToEditState,
  metricMacroProtocolToEditMetricState,
  newMacroProtocolEditState,
  newMetricMacroProtocolEditMetricState,
} from "../../utils/macroProtocol";

export type FullMacroProtocolFragmentWithMacros = FullMacroProtocolFragment & Macros;

interface useMacroProtocolReducerArgs {
  mostRecentAnthropometryEntry: AnthropometryEntryFragment;
  athleteAge: number | null;
  goalTypes: readonly GoalTypeFragment[];
  currentGoal: FullGoalFragment | null;
  initialMacroProtocol?: FullMacroProtocolFragment;
}

export const useImperialMacroProtocolReducer = ({
  initialMacroProtocol,
  goalTypes,
  currentGoal,
  mostRecentAnthropometryEntry,
  athleteAge,
}: useMacroProtocolReducerArgs): [EditImperialMacroProtocolState, Dispatch<EditMacroProtocolAction>] => {
  const initialState = initialMacroProtocol
    ? macroProtocolToEditState(initialMacroProtocol, mostRecentAnthropometryEntry, athleteAge, goalTypes, currentGoal || undefined)
    : newMacroProtocolEditState(mostRecentAnthropometryEntry, athleteAge, goalTypes, currentGoal || undefined);
  return useReducer(editMacroProtocolReducer, initialState);
};

export const useMetricMacroProtocolReducer = ({
  initialMacroProtocol,
  goalTypes,
  currentGoal,
  mostRecentAnthropometryEntry,
  athleteAge,
}: useMacroProtocolReducerArgs): [EditMetricMacroProtocolState, Dispatch<EditMacroProtocolAction>] => {
  const initialState = initialMacroProtocol
    ? metricMacroProtocolToEditMetricState(
        initialMacroProtocol,
        mostRecentAnthropometryEntry,
        athleteAge,
        goalTypes,
        currentGoal || undefined
      )
    : newMetricMacroProtocolEditMetricState(mostRecentAnthropometryEntry, athleteAge, goalTypes, currentGoal || undefined);

  return useReducer(editMetricMacroProtocolReducer, initialState);
};

export const RMR_METHOD_ORDER: RmrMethod[] = ["cunningham", "mifflin", "harrisBenedict", "average"];
