import { Typography } from "@mui/material";
import { sortByKey } from "@notemeal/utils-sort";
import React from "react";
import { MenuItemAppearancePreviewFragment, RestaurantMenuSectionPreviewFragment } from "../../../../../../types";
import Section from "./RestaurantMenuSection";

interface RestaurantMenuSectionListProps {
  restaurantMenuSections: readonly RestaurantMenuSectionPreviewFragment[];
  onSelectItem: (appearance: MenuItemAppearancePreviewFragment) => void;
}

const RestaurantMenuSectionList = ({ onSelectItem, restaurantMenuSections }: RestaurantMenuSectionListProps) => {
  return (
    <>
      <Typography variant="h3" sx={{ display: "flex", alignItems: "center", pb: 1 }}>
        Add Item to Plate
      </Typography>
      {sortByKey(restaurantMenuSections, "position").map(restaurantMenuSection => (
        <Section
          key={restaurantMenuSection.id}
          restaurantMenuSection={restaurantMenuSection}
          onSelectItem={onSelectItem} />
      ))}
    </>
  );
};

export default RestaurantMenuSectionList;
