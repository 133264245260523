import { MenuItem, SxProps, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { HOURS, formatTime } from "@notemeal/utils-date-time";
import React from "react";
import { useMenusStyles } from "../styles";

const START_OPTIONS = HOURS.flatMap(h => [`${h}:00:00`, `${h}:15:00`, `${h}:30:00`, `${h}:45:00`]);

const useStyles = makeStyles(({ palette: { warning } }: Theme) =>
  createStyles({
    warning: {
      "& fieldset": {
        borderColor: warning.main,
        color: warning.main,
      },
    },
  })
);

interface MenuStartTimeSelectProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
  error?: boolean;
  warning?: boolean;
  sx?: SxProps;
}

const MenuStartTimeSelect = ({ onChange, value, label, error, warning, sx }: MenuStartTimeSelectProps) => {
  const menuClasses = useMenusStyles();
  const classes = useStyles();
  return (
    <TextField
      select
      label={label}
      sx={sx}
      classes={
        warning
          ? {
              root: classes.warning,
            }
          : {}
      }
      error={error}
      value={value}
      onChange={e => onChange(e.target.value)}
      SelectProps={{
        classes: {
          select: menuClasses.selectRoot,
        },
      }}
    >
      {START_OPTIONS.map(s => {
        return (
          <MenuItem key={s} value={s}>
            {formatTime(s, { alwaysShowMinutes: true })}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default MenuStartTimeSelect;
