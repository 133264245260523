import { Box, MenuItem, SxProps, TextField } from "@mui/material";
import { ORDERED_EXCHANGE_TYPES } from "@notemeal/shared-ui";
import { ExchangeType } from "../../types";

interface ExchangeTypeMultiSelectProps {
  handleChangeExchangeTypes: (exchangeTypes: ExchangeType[]) => void;
  selectedExchangeTypes: readonly ExchangeType[] | null;
  label: string;
  sx?: SxProps;
  fullWidth?: boolean;
  margin?: "none" | "dense" | "normal";
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  inputSx?: SxProps;
}

const ExchangeTypeMultiSelect = ({
  sx,
  disabled,
  selectedExchangeTypes,
  error,
  helperText,
  margin,
  label,
  fullWidth,
  handleChangeExchangeTypes,
  inputSx,
}: ExchangeTypeMultiSelectProps) => {
  return (
    <Box sx={sx}>
      <TextField
        disabled={disabled || selectedExchangeTypes === null}
        select
        error={error}
        helperText={helperText}
        margin={margin || "normal"}
        label={label}
        value={selectedExchangeTypes || []}
        SelectProps={{
          multiple: true,
        }}
        fullWidth={fullWidth}
        onChange={e => handleChangeExchangeTypes(e.target.value as unknown as ExchangeType[])}
        sx={inputSx}
      >
        {ORDERED_EXCHANGE_TYPES.map(et => {
          const label = String(et).slice(0, 1).toUpperCase() + String(et).slice(1);
          const isSelected = (selectedExchangeTypes || []).includes(et);
          return (
            <MenuItem
              key={et}
              value={et}
              sx={isSelected ? { fontWeight: "bold" } : {}}>
              {label}
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
};
export default ExchangeTypeMultiSelect;
