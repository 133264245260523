import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classnames from "classnames";
import { useState } from "react";

import { ChipList, useServingAmountCallbacks } from "@notemeal/shared-ui";
import ServingAmountsChip from "./Edit/Chip";
import FoodOnlySearchBar from "./FoodOnlySearchBar";

import { FullServingAmountFragment } from "../../types";
import { OrgFoodRequestModal } from "../Food/Org/OrgFoodRequestModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    chipList: {
      overflowY: "auto",
      overflowX: "visible",
      flexGrow: 1,
      display: "flex",
      flexFlow: "row wrap",
      alignContent: "flex-start",
    },
    chip: {
      width: `calc(33% - ${theme.spacing(1)})`,
      margin: theme.spacing(0.5),
    },
    searchBarContainer: {
      width: 400,
    },
  })
);

// TODO: Add overrides for sub-classnames via a classes arg (material-ui patterm)
interface ServingAmountsMultiColumnChipListProps {
  selectedServingAmounts: readonly FullServingAmountFragment[];
  onChange: (servingAmounts: readonly FullServingAmountFragment[]) => void;
  noCache?: boolean;
  className?: string;
}

const ServingAmountsMultiColumnChipList = ({
  selectedServingAmounts,
  onChange,
  noCache = false,
  className,
}: ServingAmountsMultiColumnChipListProps) => {
  const classes = useStyles();
  const [foodModalOpen, setFoodModalOpen] = useState(false);

  const { onDecrementByDefaultAmount, onDelete, onSetAmount, onIncrementByDefaultAmount, onReplaceServing, sortedServingAmounts } =
    useServingAmountCallbacks({
      servingAmounts: selectedServingAmounts,
      onChange,
    });

  const handleAdd = (servingAmount: FullServingAmountFragment) => {
    // Prevent duplicate foods from being added
    if (selectedServingAmounts.map(sa => sa.serving.foodOrRecipe.id).includes(servingAmount.serving.foodOrRecipe.id)) {
      return;
    }
    onChange([...selectedServingAmounts, servingAmount]);
  };

  const handleOnClickAddFood = () => setFoodModalOpen(true);

  return (
    <div className={classnames(classes.root, className)}>
      <div className={classes.searchBarContainer}>
        <FoodOnlySearchBar
          onClickAddFood={handleOnClickAddFood}
          selectedServingAmounts={selectedServingAmounts}
          onAdd={handleAdd}
          noCache={noCache}
        />
      </div>
      <ChipList<FullServingAmountFragment>
        objects={[...sortedServingAmounts]}
        objectToName={sa => sa.serving.foodOrRecipe.name}
        renderChip={sa => (
          <ServingAmountsChip
            recipeIngredient={false}
            key={sa.serving.id}
            servingAmount={sa}
            className={classes.chip}
            onIncrement={() => onIncrementByDefaultAmount(sa)}
            onDecrement={() => onDecrementByDefaultAmount(sa)}
            onDelete={() => onDelete(sa)}
            onReplaceServing={serving => onReplaceServing(sa, serving)}
            onSetAmount={amount => onSetAmount(sa, amount)}
            expanded={false}
            onChangeExpanded={() => {}}
            onLoadIngredients={() => {}}
          />
        )}
        className={classes.chipList}
      />
      {foodModalOpen && <OrgFoodRequestModal open={foodModalOpen} onClose={() => setFoodModalOpen(false)} />}
    </div>
  );
};

export default ServingAmountsMultiColumnChipList;
