import {
  ProfileSyncRuleWithEntities,
  LinkedProfile,
  NotemealProfile,
  TeamworksTeam,
  TeamworksProfile,
  teamworksProfileHasRequiredFields,
} from "@notemeal/profile-sync";
import { invalidPhoneNumber, standardizePhoneNumber, standardizePhoneNumberOrNull } from "@notemeal/utils-phone-number";
import { NotemealAccountType } from "../../../../../types";
import { invalidEmail } from "apps/web/src/utils/invalidEmail";

interface UnusedNotemealTeamNamesArgs {
  linkedTeamworksTeams: readonly TeamworksTeam[];
  syncRulesWithProfiles: readonly ProfileSyncRuleWithEntities[];
}

export const NOTEMEAL_ACCOUNT_TYPES: NotemealAccountType[] = ["athlete", "dietitian", "chef"];

export const comparePhoneNumbers = (_phoneNumber1: string | null, _phoneNumber2: string | null): boolean => {
  if (!_phoneNumber1 || !_phoneNumber2) {
    return false;
  }
  const phoneNumber1 = standardizePhoneNumberOrNull(_phoneNumber1);
  const phoneNumber2 = standardizePhoneNumberOrNull(_phoneNumber2);
  if (!phoneNumber1 || !phoneNumber2) {
    return false;
  }
  return phoneNumber1 === phoneNumber2;
};

export const validateEmail = (
  newEmail: string,
  currentEmail: string | null,
  isUnique: boolean,
  setEmailError: (e: string | null) => void
) => {
  if (newEmail) {
    if (invalidEmail(newEmail)) {
      setEmailError("Invalid email");
    } else if (newEmail === currentEmail) {
      setEmailError("Email must be different than current email");
    } else if (!isUnique) {
      setEmailError("Email already in use");
    } else {
      setEmailError(null);
    }
    return;
  }
  setEmailError(null);
};

export const validatePhone = (
  newPhone: string,
  currentPhone: string | null,
  isUnique: boolean,
  setPhoneError: (e: string | null) => void
) => {
  if (newPhone) {
    if (invalidPhoneNumber(newPhone)) {
      setPhoneError("Invalid phone number");
    } else if (standardizePhoneNumber(newPhone) === currentPhone) {
      setPhoneError("Phone number must be different than current phone number");
    } else if (!isUnique) {
      setPhoneError("Phone number already in use");
    } else {
      setPhoneError(null);
    }
    return;
  }
  setPhoneError(null);
};

export const compareStrings = (string1: string | null, string2: string | null): boolean => {
  return !!string1 && !!string2 && string1.trim().toLowerCase() === string2.trim().toLowerCase();
};

interface CompareProfilesResult {
  nameMatch: boolean;
  fullMatch: boolean;
}

const buildCompareProfilesResult = (nameMatch: boolean, fullMatch: boolean): CompareProfilesResult => ({ nameMatch, fullMatch });

export const compareProfilesForAutoLink = (notemealProfile: NotemealProfile, teamworksProfile: TeamworksProfile): CompareProfilesResult => {
  // Get values to compare
  const {
    firstName: notemealFirstName,
    lastName: notemealLastName,
    email: notemealEmail,
    phoneNumber: notemealPhoneNumber,
    birthDate: notemealBirthDate,
    team: { teamworksId: notemealTeamworksTeamId },
  } = notemealProfile.__typename === "Athlete"
    ? notemealProfile
    : {
        ...notemealProfile.user,
        birthDate: null,
        team: { teamworksId: null },
      };

  const {
    firstName: teamworksFirstName,
    lastName: teamworksLastName,
    email: teamworksEmail,
    cellPhone: teamworksPhoneNumber,
    birthDate: teamworksBirthDate,
    memberships: teamworksMemberships,
  } = teamworksProfile;

  const firstNameMatch = compareStrings(notemealFirstName, teamworksFirstName);
  const lastNameMatch = compareStrings(notemealLastName, teamworksLastName);

  const emailMatch = compareStrings(notemealEmail, teamworksEmail);
  const phoneNumberMatch =
    !!notemealPhoneNumber && !!teamworksPhoneNumber && comparePhoneNumbers(notemealPhoneNumber, teamworksPhoneNumber);

  const nameMatch = lastNameMatch && (phoneNumberMatch || emailMatch || firstNameMatch);

  // return false if lastName and (first name, email, or phonenumber) do not match
  if (!nameMatch) {
    return buildCompareProfilesResult(false, false);
  }
  // Both date strings are in format YYYY-MM-DD so only need string compare.
  const birthdayMatch = compareStrings(notemealBirthDate, teamworksBirthDate);

  let teamMatch = false;
  if (notemealTeamworksTeamId !== null) {
    const teamworksTeamIds = teamworksMemberships?.map(membership => membership.teamId) ?? [];
    teamMatch = teamworksTeamIds.includes(notemealTeamworksTeamId);
  }

  const fullMatchWithoutRequiredFieldsCheck = emailMatch || phoneNumberMatch || birthdayMatch || teamMatch;
  const requiredFieldsCheck = teamworksProfileHasRequiredFields(teamworksProfile);

  // if either email or phone match return linked profile
  return buildCompareProfilesResult(true, fullMatchWithoutRequiredFieldsCheck && requiredFieldsCheck);
};

export const buildPendingProfile = (notemeal: NotemealProfile, teamworks: TeamworksProfile): LinkedProfile => ({
  notemeal,
  teamworks,
  isPending: true,
});

export const autoLinkProfiles = (
  notemealProfiles: readonly NotemealProfile[],
  teamworksProfiles: readonly TeamworksProfile[]
): LinkedProfile[] => {
  const matchedTeamworksProfileIds: Set<number> = new Set([]);
  // Ids of Teamworks profiles that have matched more than one Notmeal profile
  const collidedIdsTeamworksProfileIds: Set<number> = new Set([]);
  const unfilteredLinkedProfiles = notemealProfiles.flatMap((notemealProfile): LinkedProfile[] => {
    let matchingTeamworksProfile: TeamworksProfile | null = null;
    // used to see if notemeal profile matches multiple teamworks profiles even if
    // that teamworks profile matches multiple notemeal profiles
    let hasMatch = false;
    // Loop over each teamworksProfile
    for (const teamworksProfile of teamworksProfiles) {
      // check to see if they match
      const { nameMatch, fullMatch } = compareProfilesForAutoLink(notemealProfile, teamworksProfile);
      // if no nameMatch continue
      if (!nameMatch) {
        continue;
      }

      // Handle duplicates on nameMatch without needing a full match
      // handle case where notemeal profile matches multiple teamworks profiles
      if (hasMatch) {
        return [];
      }
      hasMatch = true;
      // check to see if this profile has been used before
      if (matchedTeamworksProfileIds.has(teamworksProfile.id)) {
        //If it has mark it as collided and continue
        collidedIdsTeamworksProfileIds.add(teamworksProfile.id);
        continue;
      }

      // This is the first time both profiles have matched and it is more than a name match
      if (fullMatch) {
        matchingTeamworksProfile = teamworksProfile;
      }
    }
    // If we have found a match
    if (matchingTeamworksProfile) {
      matchedTeamworksProfileIds.add(matchingTeamworksProfile.id);
      return [buildPendingProfile(notemealProfile, matchingTeamworksProfile)];
    }
    return [];
  });

  // filter out all profiles with collided teamworks profiles
  return unfilteredLinkedProfiles.filter(({ teamworks }) => !collidedIdsTeamworksProfileIds.has(teamworks.id));
};

export const getUnusedNotemealTeamNames = ({ linkedTeamworksTeams, syncRulesWithProfiles }: UnusedNotemealTeamNamesArgs): string[] => {
  // Any team is a way to "Select all teams"
  const hasAnyTeam = syncRulesWithProfiles.some(rule => !rule.matchOnProfiles && rule.teams.length === 0);

  // No need to dedupe because it is a 1:M relationship
  const potentialUnusedNotemealTeams = linkedTeamworksTeams.flatMap(teamworksTeam => {
    // if has any team only include ambiguous teams because all unambiguous teams are covered by any team
    if (hasAnyTeam) {
      const hasAmbiguousTeams = teamworksTeam.notemealTeams.length > 1;
      return hasAmbiguousTeams ? teamworksTeam.notemealTeams : [];
    }
    return teamworksTeam.notemealTeams;
  });

  // initialize set to with all explicitly used ambiguious matches
  const usedNotemealTeamIds: Set<string> = new Set(syncRulesWithProfiles.flatMap(rule => rule.matchNotemealTeamIds));

  // If there is not an any team add the used unambiguous team ids.
  // They only will be included in potentialUnusedNotemealTeams if hasAnyTeam is false
  if (!hasAnyTeam) {
    syncRulesWithProfiles.forEach(rule => {
      rule.teams.forEach(team => {
        if (team.notemealTeams.length === 1) {
          usedNotemealTeamIds.add(team.notemealTeams[0].id);
        }
      });
    });
  }

  const unusedNotemealTeams = potentialUnusedNotemealTeams.filter(({ id }) => !usedNotemealTeamIds.has(id));

  return unusedNotemealTeams.map(({ name }) => name);
};

interface filterProfileForSearchTermProps {
  firstName: string;
  lastName: string;
  searchTerm: string;
}

export const filterProfileForSearchTerm = ({ firstName, lastName, searchTerm }: filterProfileForSearchTermProps) => {
  const _firstName = firstName.toLocaleLowerCase();
  const _lastName = lastName.toLocaleLowerCase();
  const formattedName1 = `${_firstName} ${_lastName}`;
  const formattedName2 = `${_lastName}, ${_firstName}`;
  const _searchTerm = searchTerm.toLocaleLowerCase();
  return formattedName1.startsWith(_searchTerm) || formattedName2.startsWith(_searchTerm);
};

// Util function for filtering Notemeal profiles for both display and building a rule.
// Handles logic s.t. when onlyNotemealProfilesOnSelectedTeams is false, all Notemeal profiles display, and
// When onlyNotemealProfilesOnSelectedTeams is true, only athletes on matched Notemeal teams display.

// Rules for account type == 'athlete' should show all notemeal profiles,
// But other rules should only show OrgMemberships
// TODO: test
export const filterNotemealProfilesForRule = (profile: NotemealProfile, profileSyncRule: ProfileSyncRuleWithEntities): boolean => {
  if (profileSyncRule.matchOnProfiles) {
    return true;
  }

  const oneToOneNotemealTeamIds = profileSyncRule.teams.flatMap(t => {
    if (t.notemealTeams.length === 1) {
      return t.notemealTeams[0].id;
    } else {
      return [];
    }
  });

  const allMatchedNotemealTeamIds = [...oneToOneNotemealTeamIds, ...profileSyncRule.matchNotemealTeamIds];

  if (profileSyncRule.onlyNotemealProfilesOnSelectedTeams) {
    if (profile.__typename === "Athlete") {
      return allMatchedNotemealTeamIds.includes(profile.team.id);
    } else {
      return false;
    }
  } else {
    return profileSyncRule.notemealAccountType === "athlete" || profile.__typename === "OrgMembership";
  }
};

export const getItemsDescriptionWithLimit = <T extends any>(items: readonly T[], key: keyof T, limit = 3): string => {
  const displayItems = items.slice(0, limit);
  const hiddenItemsCount = items.slice(limit).length;
  const hiddenItemsText = hiddenItemsCount > 0 ? ` (+${hiddenItemsCount})` : "";
  return `${displayItems.map(i => i[key]).join(", ")}${hiddenItemsText}`;
};

export const getNonObjectItemsDescriptionWithLimit = <T extends string>(items: readonly T[], limit = 3): string => {
  const displayItems = items.slice(0, limit);
  const hiddenItemsCount = items.slice(limit).length;
  const hiddenItemsText = hiddenItemsCount > 0 ? ` (+${hiddenItemsCount})` : "";
  return `${displayItems.join(", ")}${hiddenItemsText}`;
};
