import { Box, Checkbox, FormControlLabel, FormGroup, Switch, SxProps, Typography } from "@mui/material";
import { ExchangeMealPlanLegacyDisplaySettings } from "@notemeal/shared-ui";
import { ReactNode } from "react";
import { FormRow } from "./utils";

interface MealPlanDisplaySettingsExchangeFormProps {
  displaySettings: ExchangeMealPlanLegacyDisplaySettings;
  sx?: SxProps;
  onChange: (displaySettings: ExchangeMealPlanLegacyDisplaySettings) => void;
  extraExchangeSetting?: ReactNode;
}

const MealPlanDisplaySettingsExchangeForm = ({
  displaySettings,
  sx,
  onChange,
  extraExchangeSetting,
}: MealPlanDisplaySettingsExchangeFormProps) => {
  const handleChange = (key: keyof ExchangeMealPlanLegacyDisplaySettings, value: boolean) => {
    onChange({
      ...displaySettings,
      [key]: value,
    });
  };

  return (
    <FormGroup sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", ...sx }} row>
      <Typography variant="body1Medium">Macros</Typography>

      <FormRow sx={{ justifyContent: "flex-start" }}>
        <FormControlLabel
          label="Daily"
          control={
            <Checkbox checked={displaySettings.displayDailyMacros} onChange={e => handleChange("displayDailyMacros", e.target.checked)} />
          }
        />
        <FormControlLabel
          label="Meals"
          control={
            <Checkbox checked={displaySettings.displayMealMacros} onChange={e => handleChange("displayMealMacros", e.target.checked)} />
          }
        />
      </FormRow>
      <FormRow sx={{ justifyContent: "flex-start" }}>
        <Box sx={{ ml: 0.5, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          <FormControlLabel
            control={
              <Switch
                sx={{ ml: 0.5, mr: 1 }}
                size="small"
                checked={displaySettings.displayMealOptions}
                onChange={e => handleChange("displayMealOptions", e.target.checked)}
              />
            }
            label={"Meal Options"}
            labelPlacement="end"
          />
          <FormControlLabel
            control={
              <Switch
                sx={{ ml: 0.5, mr: 1 }}
                size="small"
                checked={displaySettings.displayMealOptionMacros}
                onChange={e => handleChange("displayMealOptionMacros", e.target.checked)}
              />
            }
            sx={{ pt: 1 }}
            label="Meal Option Macros"
            labelPlacement="end"
          />
        </Box>
      </FormRow>
      <Typography variant="body1Medium" sx={{ pt: 4 }}>
        Exchanges
      </Typography>
      <FormRow sx={{ justifyContent: "flex-start" }}>
        <FormControlLabel
          label="Daily"
          control={
            <Checkbox
              checked={displaySettings.displayDailyExchanges}
              onChange={e => handleChange("displayDailyExchanges", e.target.checked)}
            />
          }
        />
        <FormControlLabel
          label="Meals"
          control={
            <Checkbox
              checked={displaySettings.displayMealExchanges}
              onChange={e => handleChange("displayMealExchanges", e.target.checked)}
            />
          }
        />
      </FormRow>
      <Box sx={{ display: "flex", flexDirection: "column", ml: 0.5 }}>
        <FormControlLabel
          control={
            <Switch
              sx={{ ml: 0.5, mr: 1 }}
              size="small"
              checked={displaySettings.displayExchangeLists}
              onChange={e => handleChange("displayExchangeLists", e.target.checked)}
            />
          }
          label="Exchange Lists"
          labelPlacement="end"
        />
        {extraExchangeSetting}
      </Box>
    </FormGroup>
  );
};

export default MealPlanDisplaySettingsExchangeForm;
