import { IFileImport } from "../../../../universal/Import/Button";
import { loadCsv } from "../../../../../utils/import/load";
import { parseDelimitedFile } from "../../../../../utils/import/parse";
import { ImportableAnthroEntry } from "../types";
import { subYears } from "date-fns";
import { SexType } from "../../../../../types";
import { sortByKey } from "@notemeal/utils-sort";
import { AnthropometryEntryType } from "../../../../../types";
interface GeneralFileImportFields {
  "Last Name": string;
  "First Name": string;
  Team: string;
  Date: string;
  Height: string;
  Weight: number;
  "Fat Mass": number;
  "Lean Mass": number;
  "Percent Body Fat": number;
  Comment: string;
  Sex: SexType;
  Type: string;
  // Bone stuff
  "Bone Mass": number | number | null;
  "Bone Area": number | null;
  "Bone Mineral Content": number | null;
  "Bone Mineral Density": number | null;
  "Bone Mineral Density T-score": number | null;
  "Bone Mineral Density Z-score": number | null;
}

type GeneralFileImportFieldsRaw = {
  [key in keyof GeneralFileImportFields]: string;
};

export const MatchFields: (keyof ImportableAnthroEntry)[] = ["firstName" as const, "lastName" as const, "datetime" as const];

const BIOELECTRICAL_IMPEDANCE = "bioelectricalImpedance";
const BODPOD = "bodpod";
const DEXA = "dexa";
const ESTIMATE = "estimate";
const WEIGHT_IN = "weighIn";

type I = ImportableAnthroEntry;
export const msg = `
Imports Anthropometry from a CSV file. Provide column 'Type' as either '${BODPOD}', '${BIOELECTRICAL_IMPEDANCE}', '${DEXA}', '${ESTIMATE}', or '${WEIGHT_IN}'.
`;
const numberFields = [
  {
    fields: ["Fat Mass" as const],
    required: false,
    importableField: "bodyFatMass" as const,
  },
  {
    fields: ["Lean Mass" as const],
    required: false,
    importableField: "leanBodyMass" as const,
  },
  {
    fields: ["Percent Body Fat" as const],
    required: false,
    importableField: "percentBodyFat" as const,
  },
  {
    fields: ["Height" as const],
    required: false,
    importableField: "height" as const,
  },
  {
    fields: ["Weight" as const],
    required: true,
    importableField: "weight" as const,
  },
  // Nullable
  {
    fields: ["Bone Area" as const],
    required: false,
    importableField: "boneArea" as const,
  },
  {
    fields: ["Bone Mass" as const],
    required: false,
    importableField: "boneMass" as const,
  },
  {
    fields: ["Bone Mineral Content" as const],
    required: false,
    importableField: "boneMineralContent" as const,
  },
  {
    fields: ["Bone Mineral Density" as const],
    required: false,
    importableField: "boneMineralDensity" as const,
  },
  {
    fields: ["Bone Mineral Density T-score" as const],
    required: false,
    importableField: "boneMineralDensityTScore" as const,
  },
  {
    fields: ["Bone Mineral Density Z-score" as const],
    required: false,
    importableField: "boneMineralDensityZScore" as const,
  },
];
const stringFields = [
  {
    fields: ["Last Name" as const],
    required: true,
    importableField: "lastName" as const,
  },
  {
    fields: ["First Name" as const],
    required: true,
    importableField: "firstName" as const,
  },
  {
    fields: ["Type" as const],
    required: true,
    importableField: "type" as const,
  },
  {
    fields: ["Team" as const],
    required: false,
    importableField: "teamName" as const,
  },
  {
    fields: ["Comment" as const],
    required: false,
    importableField: "comment" as const,
  },
  // {field: 'Dept ID' as const, importableField: 'teamName' as const},
]; // More on 'as const': https://stackoverflow.com/a/55387357/3923962

const dateFields = [
  {
    fields: ["Date" as const],
    required: true,
    importableField: "datetime" as const,
    showDateFormat: true,
  },
];
export const fields = sortByKey([...numberFields, ...stringFields, ...dateFields], "required", { reverse: true });

const importableAnthropometryEntryTypes: AnthropometryEntryType[] = [BIOELECTRICAL_IMPEDANCE, BODPOD, DEXA, ESTIMATE, WEIGHT_IN];

export const loadAnthroEntries = async ({ file, onError, locale }: IFileImport): Promise<I[] | null> => {
  // type T = GeneralFileImportFields;
  type F = GeneralFileImportFieldsRaw;
  const rows = await loadCsv<F>({ file, onError });
  if (rows === null) {
    return null;
  }
  const parsedRows = parseDelimitedFile<F, I>({
    rows,
    numberFields,
    dateFields,
    stringFields,
    onError,
    locale,
  });

  const mappedRows = parsedRows
    .map(row => {
      if (!importableAnthropometryEntryTypes.includes(row.type)) {
        onError(
          `Found ${row.type} in Type column. It should contain one of the following: '${BODPOD}', '${BIOELECTRICAL_IMPEDANCE}', '${DEXA}' or '${ESTIMATE}'?`
        );
      }
      return row;
    })
    .filter(row => new Date(row.datetime).toISOString() > subYears(new Date(), 5).toISOString())
    .map(({ boneMass, bodyFatMass, leanBodyMass, weight, ...rest }) => {
      if (!(weight || (boneMass && bodyFatMass && leanBodyMass))) {
        throw new Error("Need to provide 'weight', or 'boneMass + bodyFatMass + leanBodyMass'");
      }
      return {
        bodyFatMass,
        leanBodyMass,
        boneMass,
        weight: weight || (boneMass as number) + (bodyFatMass as number) + (leanBodyMass as number),
        ...rest,
      };
    });

  return mappedRows;
};
