import Check from "@mui/icons-material/Check";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Button, Collapse, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import { useEffect, useState } from "react";
import ImportResultList from ".";
import { IMatchResult, MatchType } from "../../../../utils/import/match";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import { Entity } from "../../../universal/SearchBar/type";
import { ICrudHandler } from "../types";
interface ResultListGroupProps<T, A> {
  matches: IMatchResult<T, A>[];
  setMatches: (matches: IMatchResult<T, A>[]) => void;
  name?: string;
  actionButtonName: string;
  onAction: (props: ICrudHandler<T, A>) => void;
  onMatch?: (match: IMatchResult<T, A>, idx: number) => void;
  existingRows?: (Entity & T)[];
  onChange?: (row: T, type: MatchType, newRow: T) => void;
  allowShowImportRow?: boolean;
  // if provided, this component always removes records from the group before calling onMatch()
  disabled?: boolean;
  focused: boolean;
  onFocusNext: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexFlow: "column nowrap",
      // maxHeight: "100%",
      // overflowY: "auto",
      borderTop: `1px solid ${theme.palette.divider}`,
      paddingBottom: theme.spacing(1),
      //NOTE: To get rid of "sticky" insert button and double scroll, comment out maxHeight and overflow

      "&:last-of-type": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    title: {
      fontWeight: 600,
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
  })
);

type ResolutionState = "unresolved" | "loading" | "resolved" | "partiallyResolved";

const ResultListGroup = <T extends object, A extends object>({
  existingRows,
  matches,
  setMatches,
  name,
  actionButtonName,
  onAction,
  onMatch,
  onChange,
  allowShowImportRow,
  disabled,
  focused,
  onFocusNext,
}: ResultListGroupProps<T, A>) => {
  const classes = useStyles();
  const { setMessage } = useSnackbar();
  const [resolutionState, setResolutionState] = useState<ResolutionState>("unresolved");
  const [selectedMatchIndices, setSelectedMatchIndices] = useState<boolean[]>([]);
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
  const selectMatchIndex = (idx: number) => {
    setSelectedMatchIndices([...selectedMatchIndices.slice(0, idx), !selectedMatchIndices[idx], ...selectedMatchIndices.slice(idx + 1)]);
  };
  const removeAndHandleMatch = (match: IMatchResult<T, A>, i: number) => {
    if (onMatch) {
      setSelectedMatchIndices([...selectedMatchIndices.slice(0, i), ...selectedMatchIndices.slice(i + 1)]);
      onMatch(match, i);
    }
  };

  useEffect(() => {
    const newIndices = matches.length - selectedMatchIndices.length;
    setSelectedMatchIndices([...selectedMatchIndices, ...new Array(newIndices).fill(true)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  useEffect(() => setIsOpen(focused), [focused]);

  const handleChange = (row: T, type: MatchType, newRow: T, idx: number) => {
    onChange?.(row, type, newRow);
    setSelectedMatchIndices([...selectedMatchIndices.slice(0, idx), ...selectedMatchIndices.slice(idx + 1)]);
  };
  const listOrSuccess =
    resolutionState !== "resolved" ? (
      <>
        <ImportResultList
          existingRows={existingRows}
          matches={matches}
          onMatch={onMatch ? removeAndHandleMatch : undefined}
          selectMatchIndex={selectMatchIndex}
          selectedMatchIndices={selectedMatchIndices}
          onChange={handleChange}
          allowShowImportRow={allowShowImportRow}
        />
        <Tooltip title={disabled ? "Action not permitted for this importer" : ""}>
          <div>
            <Button
              className={classes.button}
              disabled={disabled}
              fullWidth
              onClick={() => {
                try {
                  setResolutionState("loading");
                  onAction({
                    matches: selectedMatchIndices.flatMap((selected, idx) => (selected ? [matches[idx]] : [])),
                    onCacheUpdate: () => {
                      const numSelected = selectedMatchIndices.filter(m => m === true).length;
                      const isUpdate = matches.some(m => m.matchedRows.length > 0);

                      if (numSelected > 0) {
                        setMessage(
                          "success",
                          `${isUpdate ? `Updated ${numSelected}` : `Imported ${numSelected} new`} record${numSelected > 1 ? "s" : ""}${
                            name ? ` for ${name}` : ""
                          }.`
                        );
                        setMatches([]);
                      }

                      setResolutionState(matches.length === numSelected ? "resolved" : "unresolved");
                      onFocusNext();
                      setIsOpen(false);
                    },
                  });
                } catch (e) {
                  if (e instanceof Error) {
                    setMessage("error", e.message);
                  } else {
                    setMessage("error", "Something went wrong!");
                  }
                  setResolutionState("unresolved");
                }
              }}
            >
              {actionButtonName}
              {resolutionState === "loading" ? <Loading progressSize="xs" /> : null}
            </Button>
          </div>
        </Tooltip>
      </>
    ) : null;

  return (
    <div className={classes.root}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginTop={1}>
        {name && (
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {name} ({matches.length})
          </Typography>
        )}
        <IconButton disabled={resolutionState === "resolved"} onClick={() => setIsOpen(!isOpen)}>
          {resolutionState !== "resolved" && (isOpen ? <ExpandLess /> : <ExpandMore />)}
          {resolutionState === "resolved" && <Check />}
        </IconButton>
      </Box>
      <Collapse in={isOpen}>{listOrSuccess}</Collapse>
    </div>
  );
};

export default ResultListGroup;
