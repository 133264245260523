import { Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getCommentsUnviewedNotificationIds } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { useEffect, useRef } from "react";
import { CommentTimelineFragment } from "../../../types";
import { useMarkNotificationsAsViewed } from "../../../utils/notifications";
import { useUser } from "../../../utils/tokens";

const useStyles = makeStyles(({ spacing, palette: { info, grey } }: Theme) =>
  createStyles({
    comments: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      overflowX: "hidden",
      flexGrow: 1,
    },
    commentDiv: {
      padding: spacing(0.5),
    },
    myComment: {
      padding: spacing(),
      marginLeft: "20%",
      backgroundColor: info.lighter,
    },
    notMyComment: {
      padding: spacing(),
      marginRight: "20%",
      backgroundColor: grey[200],
    },
    fromText: {
      fontWeight: "bold",
    },
    deliveredText: {
      textAlign: "end",
      width: "100%",
      paddingRight: spacing(0.5),
    },
    noMessagesText: {
      justifySelf: "center",
      alignSelf: "center",
    },
  })
);

export interface NewComment {
  id: string;
  text: string;
}

interface AthleteFoodLogsCommentsProps {
  comments: readonly CommentTimelineFragment[];
  newComments: NewComment[];
}

const AthleteFoodLogsComments = ({ comments, newComments }: AthleteFoodLogsCommentsProps) => {
  const classes = useStyles();
  const user = useUser();
  const userId = user?.id;
  const notificationIds = getCommentsUnviewedNotificationIds(comments);

  const commentsElRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (commentsElRef.current) {
      commentsElRef.current.scrollTo({
        top: commentsElRef.current.scrollHeight,
      });
    }
  }, [commentsElRef.current, comments.length, newComments.length]);
  useMarkNotificationsAsViewed(notificationIds);

  const sortedComments = sortByKey(comments, "at");
  const lastDeliveredCommentId = [...sortedComments].reverse().find(c => c.by.id === userId)?.id;

  return (
    <div className={classes.comments} ref={commentsElRef}>
      {sortedComments.map(c => (
        <div key={c.id} className={classes.commentDiv}>
          <Paper className={c.by.id === userId ? classes.myComment : classes.notMyComment}>
            {c.by.id !== userId && (
              <Typography variant="body2" className={classes.fromText}>
                {c.by.firstName} {c.by.lastName}
              </Typography>
            )}
            <Typography variant="body2">{c.text}</Typography>
          </Paper>
          {c.id === lastDeliveredCommentId && (
            <Typography
              component="div"
              variant="subtitle1"
              className={classes.deliveredText}>
              Delivered
            </Typography>
          )}
        </div>
      ))}
      {newComments.map(c => (
        <div key={c.id} className={classes.commentDiv}>
          <Paper className={classes.myComment}>
            <Typography variant="body2">{c.text}</Typography>
          </Paper>
        </div>
      ))}
    </div>
  );
};

export default AthleteFoodLogsComments;
