import { Button, DialogActions, DialogContent } from "@mui/material";
import { useLocaleContext } from "@notemeal/shared-ui";
import { maybeAthleteBirthDateToAge } from "@notemeal/shared-utils-macro-protocol";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { useRefetchQueriesContext } from "../../../contexts/RefetchQueries";
import {
    AnthropometryEntryFragment,
    FullGoalFragment,
    FullMacroProtocolFragment,
    GoalTypeFragment,
    useEditMetricMacroProtocolMutation,
} from "../../../types";
import { editImperialStateToMetricMacroProtocolInput, editMetricStateToMetricMacroProtocolInput } from "../../../utils/macroProtocol";
import { ImperialMacroProtocolForm } from "../Form/ImperialMacroProtocolForm";
import { MetricMacroProtocolForm } from "../Form/MetricMacroProtocolForm";
import { macroProtocolStateToSaveTooltips, metricMacroProtocolStateToSaveTooltips } from "../Form/utils";
import { useImperialMacroProtocolReducer, useMetricMacroProtocolReducer } from "../utils";

interface MacroProtocolEditModalContentProps {
  currentGoal: FullGoalFragment | null;
  mostRecentAnthropometryEntry: AnthropometryEntryFragment;
  initialMacroProtocol: FullMacroProtocolFragment;
  athleteBirthdate: string | null;
  goalTypes: readonly GoalTypeFragment[];
  onClose: () => void;
}

export const MacroProtocolEditModalContent = ({
  currentGoal,
  mostRecentAnthropometryEntry,
  initialMacroProtocol,
  goalTypes,
  onClose,
  athleteBirthdate,
}: MacroProtocolEditModalContentProps) => {
  const { isMetricLocale } = useLocaleContext();
  const athleteAge = maybeAthleteBirthDateToAge(athleteBirthdate);

  const [imperialState, imperialDispatch] = useImperialMacroProtocolReducer({
    currentGoal,
    mostRecentAnthropometryEntry,
    initialMacroProtocol,
    athleteAge,
    goalTypes,
  });

  // TODO: metric update - merge resolvers....? separate flows are easier to read
  const [metricState, metricDispatch] = useMetricMacroProtocolReducer({
    currentGoal,
    mostRecentAnthropometryEntry,
    initialMacroProtocol,
    athleteAge,
    goalTypes,
  });

  const refetchQueries = useRefetchQueriesContext();

  const [editMetricMacroProtocol] = useEditMetricMacroProtocolMutation({ refetchQueries });

  const canSaveTooltips = isMetricLocale
    ? metricMacroProtocolStateToSaveTooltips(metricState)
    : macroProtocolStateToSaveTooltips(imperialState);

  const handleMetricSave = () => {
    if (canSaveTooltips.length) {
      return;
    }

    const macroProtocolInput = editMetricStateToMetricMacroProtocolInput(metricState, currentGoal);

    if (macroProtocolInput) {
      editMetricMacroProtocol({
        variables: {
          input: {
            macroProtocolId: initialMacroProtocol.id,
            macroProtocol: macroProtocolInput,
          },
        },
      });
    }
    onClose();
  };

  const handleImperialSave = () => {
    if (canSaveTooltips.length) {
      return;
    }

    const macroProtocolInput = editImperialStateToMetricMacroProtocolInput(imperialState, currentGoal);

    if (macroProtocolInput) {
      editMetricMacroProtocol({
        variables: {
          input: {
            macroProtocolId: initialMacroProtocol.id,
            macroProtocol: macroProtocolInput,
          },
        },
      });
    }
    onClose();
  };

  const handleSave = () => (isMetricLocale ? handleMetricSave() : handleImperialSave());

  return (
    <>
      <DialogContent>
        {isMetricLocale ? (
          <MetricMacroProtocolForm
            state={metricState}
            dispatch={metricDispatch}
            goalTypes={goalTypes} />
        ) : (
          <ImperialMacroProtocolForm
            state={imperialState}
            dispatch={imperialDispatch}
            goalTypes={goalTypes} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
          <Button onClick={handleSave} disabled={canSaveTooltips.length > 0}>
            Save
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
    </>
  );
};
