import ClearIcon from "@mui/icons-material/Clear";
import { Box, FormControlLabel, Grid, IconButton, Radio, TextField } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import { initMacros, maybeMacrosToMacros, scaleMacros } from "@notemeal/shared-utils-macro-protocol";
import { Controller, UseFieldArrayRemove, UseFormReturn, useFieldArray } from "react-hook-form";
import { Brand, FullServingAmountFragment, Macros, UnitWithConversionsFragment } from "../../../types";
import ServingUnitsInput from "../../Serving/Units/Input";
import { getGramValueOrNullForUnit } from "../../Serving/Units/utils";
import { getServingUnits } from "../../Serving/utils";
import ServingAmountsEditChip from "../../ServingAmounts/Edit/Chip";
import { BaseFoodFormType } from "../FoodFormSchema/FoodFormSchema";
import { FoodServingType } from "../FoodFormSchema/FoodServingSchema";
import {
  IndexedServing,
  changeUnitsForServing,
  decrementServingDefaultAmount,
  getNewValuesForMacro,
  getValuesForNewDefaultAmount,
  getValuesForNewWeight,
  incrementServingDefaultAmount,
} from "../FoodFormSchema/foodServingsUtils";
import { roundFoodMacro } from "./utils";

interface FoodDefaultServingsProps {
  form: UseFormReturn<BaseFoodFormType>;
  unitsData: readonly UnitWithConversionsFragment[];
  gramUnit: UnitWithConversionsFragment;
  isLoading: boolean;
  disabled?: boolean;
}

export const FoodDefaultServings = ({ form, unitsData, gramUnit, isLoading, disabled }: FoodDefaultServingsProps) => {
  const { control, watch } = form;
  const watchedServings = watch("servings");

  const { fields, remove } = useFieldArray({
    control,
    name: "servings",
    rules: { minLength: 1 },
  });
  const indexedFields = watchedServings.map((serving, index) => ({ ...serving, index }));

  return (
    <>
      {isLoading ? (
        <Loading progressSize="lg" />
      ) : (
        <Grid container direction="column">
          {fields.map((field, index) => {
            const serving = watchedServings[index];
            const { isDefault, isNewServing } = serving;
            const isDeletable = watchedServings.length > 1 && isNewServing && !isDefault;

            return (
              <ServingRow
                key={index}
                index={index}
                indexedServings={indexedFields}
                disabled={disabled}
                serving={serving}
                foodForm={form}
                unitsData={unitsData}
                gramUnit={gramUnit}
                isDeletable={isDeletable}
                remove={remove}
              />
            );
          })}
        </Grid>
      )}
    </>
  );
};

const DELETE_BUTTON_WIDTH = 35;

interface ServingRowProps {
  foodForm: UseFormReturn<BaseFoodFormType>;
  isDeletable: boolean;
  unitsData: readonly UnitWithConversionsFragment[];
  gramUnit: UnitWithConversionsFragment;
  serving: FoodServingType;
  index: number;
  indexedServings: IndexedServing[];
  remove: UseFieldArrayRemove;
  disabled?: boolean;
}

const ServingRow = ({ foodForm, isDeletable, unitsData, gramUnit, serving, index, remove, indexedServings, disabled }: ServingRowProps) => {
  const { control, setValue, getValues } = foodForm;
  const { units, id: servingId, isDefault } = serving;
  const { unit } = units;

  const isWeightUnit = !!(unit && getGramValueOrNullForUnit(unitsData, unit.id, gramUnit));
  const disableEditMacros = disabled || !isDefault;

  const handleChangeDefaultAmount = (newInput: string) => {
    const { newDefaultAmount, newNutrientAmounts, newCho, newPro, newFat } = getValuesForNewDefaultAmount({
      serving,
      foodForm: getValues(),
      newInput,
    });

    setValue(`servings.${index}.defaultAmount`, newDefaultAmount);
    setValue(`servings.${index}.nutrientAmounts`, newNutrientAmounts);
    setValue(`servings.${index}.cho`, newCho);
    setValue(`servings.${index}.pro`, newPro);
    setValue(`servings.${index}.fat`, newFat);
  };

  return (
    <Grid
      item
      key={servingId}
      xs={12}
      sx={{ display: "flex", flexDirection: "row", gap: 3, justifyContent: "space-between" }}>
      <>
        <Box>
          <Controller
            name={`servings.${index}.isDefault`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                disabled={disabled}
                sx={{ m: 0, pt: 0, minWidth: 60 }}
                onClick={() => {
                  indexedServings.forEach(curServing => setValue(`servings.${curServing.index}.isDefault`, curServing.id === servingId));
                }}
                control={<Radio checked={field.value} />}
                label={field.value && "Default"}
                labelPlacement="top"
              />
            )}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 3 }}>
            <Controller
              name={`servings.${index}.defaultAmount`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  disabled={disabled}
                  label="Default Amount"
                  type="number"
                  error={Boolean(error)}
                  helperText={error?.message}
                  SelectProps={{
                    native: true,
                    inputProps: { maxlength: 3 },
                  }}
                  margin="dense"
                  onChange={e => handleChangeDefaultAmount(e.target.value)}
                  value={field.value ?? ""}
                />
              )}
            />

            <Controller
              name={`servings.${index}.units`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <ServingUnitsInput
                  error={Boolean(error)}
                  disabled={disabled}
                  value={field.value}
                  onChange={units => {
                    const { newUnits, newUnitWeight } = changeUnitsForServing({ units, unitsData, gramUnit });
                    setValue(`servings.${index}.units`, newUnits, { shouldValidate: true });
                    if (newUnitWeight) {
                      setValue(`servings.${index}.weight`, newUnitWeight);
                    }
                  }}
                />
              )}
            />
          </Box>

          {isDefault && (
            <DefaultServingsEditChip
              foodForm={foodForm}
              disabled={disabled}
              serving={serving}
              index={index}
              handleChangeDefaultAmount={handleChangeDefaultAmount}
            />
          )}
        </Box>
        <Controller
          name={`servings.${index}.weight`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              disabled={disabled || isWeightUnit}
              error={Boolean(error)}
              helperText={error?.message}
              margin="dense"
              label="Wgt (g)"
              InputProps={{ inputProps: { min: 1 } }}
              type="number"
              onChange={e => {
                const { newWeight, newNutrientAmounts, newCho, newPro, newFat } = getValuesForNewWeight({
                  foodForm: getValues(),
                  serving,
                  newInput: e.target.value,
                });
                setValue(`servings.${index}.weight`, newWeight, { shouldValidate: true });
                setValue(`servings.${index}.nutrientAmounts`, newNutrientAmounts);
                setValue(`servings.${index}.cho`, newCho);
                setValue(`servings.${index}.pro`, newPro);
                setValue(`servings.${index}.fat`, newFat);
              }}
              value={field.value ?? ""}
            />
          )}
        />

        <Controller
          name={`servings.${index}.cho`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <MacroServingAmount
              disabled={disableEditMacros}
              macroAmount={field.value}
              macro="cho"
              error={Boolean(error)}
              helperText={error?.message}
              changeMacroServing={value => {
                const { macroValuePer100g, newMacros } = getNewValuesForMacro({ macro: "cho", serving, newInput: value, indexedServings });
                setValue("choPer100g", macroValuePer100g);
                newMacros.map(m => setValue(`servings.${m.index}.cho`, m.cho, { shouldValidate: true }));
              }}
            />
          )}
        />

        <Controller
          name={`servings.${index}.pro`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <MacroServingAmount
              disabled={disableEditMacros}
              macroAmount={field.value}
              macro="pro"
              error={Boolean(error)}
              helperText={error?.message}
              changeMacroServing={value => {
                const { macroValuePer100g, newMacros } = getNewValuesForMacro({ macro: "pro", serving, newInput: value, indexedServings });
                setValue("proPer100g", macroValuePer100g);
                newMacros.map(m => setValue(`servings.${m.index}.pro`, m.pro, { shouldValidate: true }));
              }}
            />
          )}
        />

        <Controller
          name={`servings.${index}.fat`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <MacroServingAmount
              disabled={disableEditMacros}
              macroAmount={field.value}
              macro="fat"
              error={Boolean(error)}
              helperText={error?.message}
              changeMacroServing={value => {
                const { macroValuePer100g, newMacros } = getNewValuesForMacro({ macro: "fat", serving, newInput: value, indexedServings });
                setValue("fatPer100g", macroValuePer100g);
                newMacros.map(m => setValue(`servings.${m.index}.fat`, m.fat, { shouldValidate: true }));
              }}
            />
          )}
        />
      </>
      {isDeletable ? (
        <IconButton
          disabled={disabled}
          sx={{
            width: DELETE_BUTTON_WIDTH,
          }}
          onClick={() => remove(index)}
          size="medium"
        >
          <ClearIcon />
        </IconButton>
      ) : (
        <Box sx={{ minWidth: DELETE_BUTTON_WIDTH }} />
      )}
    </Grid>
  );
};

interface DefaultServingsEditChipProps {
  serving: FoodServingType;
  foodForm: UseFormReturn<BaseFoodFormType>;
  index: number;
  disabled?: boolean;
  handleChangeDefaultAmount: (newInput: string) => void;
}

const DefaultServingsEditChip = ({ serving, foodForm, index, disabled, handleChangeDefaultAmount }: DefaultServingsEditChipProps) => {
  const { setValue, getValues } = foodForm;
  const { name, brand } = getValues();

  // Will eventually add kcal to this form. Until then we'll get kcal from macros.
  const { cho, pro, fat } = maybeMacrosToMacros(serving);
  const macros = initMacros(cho, pro, fat);
  const defaultAmount = serving.defaultAmount || 1;
  const scaledMacros = scaleMacros(macros, 1 / defaultAmount);

  return (
    <ServingAmountsEditChip
      disabled={disabled}
      sx={{ maxWidth: 300 }}
      recipeIngredient={false}
      servingAmount={getServingAmount(serving, scaledMacros, defaultAmount, name, brand)}
      onIncrement={() => setValue(`servings.${index}.defaultAmount`, incrementServingDefaultAmount(serving))}
      onDecrement={() => setValue(`servings.${index}.defaultAmount`, decrementServingDefaultAmount(serving))}
      onDelete={() => {}}
      onReplaceServing={() => {}}
      onSetAmount={amount => handleChangeDefaultAmount(amount.toString())}
      expanded={false}
      onChangeExpanded={() => {}}
      onLoadIngredients={() => {}}
      servingsMenuDisabled
    />
  );
};

interface MacrosServingAmount {
  macro: "cho" | "pro" | "fat";
  macroAmount: number | null;
  changeMacroServing: (value: string) => void;
  disabled: boolean;
  error: boolean;
  helperText?: string;
}

const MacroServingAmount = ({ macro, macroAmount, changeMacroServing, disabled, error, helperText }: MacrosServingAmount) => {
  return (
    <TextField
      disabled={disabled}
      margin="dense"
      error={error}
      helperText={helperText}
      onChange={e => changeMacroServing(e.target.value)}
      label={macro.toUpperCase()}
      type="number"
      value={macroAmount !== null ? roundFoodMacro(macroAmount) : ""}
    />
  );
};

const getServingAmount = (
  serving: FoodServingType,
  scaledMacros: Macros,
  defaultAmount: number,
  name: string | null,
  brand: Pick<Brand, "id" | "name" | "usdaManufacturerName"> | null
): FullServingAmountFragment => ({
  id: "",
  __typename: "ServingAmount",
  position: 1,
  amount: serving.defaultAmount || 1,
  serving: {
    id: "",
    unit: null,
    __typename: "Serving" as const,
    hasOwnNutrients: false,
    foodOrRecipe: {
      __typename: "BrandedFood" as "BrandedFood",
      id: "",
      brand: brand && {
        id: brand.id,
        name: brand.name,
      },
      nixItemId: null,
      nixTagId: null,
      name: name || "<Food Name>",
      exchangeTypes: [],
      usdaFdcDescription: null,
      thumbnailUrl: "",
      hasFullAccess: true,
      org: null,
    },
    units: getServingUnits(serving.units) || '<Enter "units">',
    weight: serving.weight || 1,
    macros: scaledMacros,
    defaultAmount,
    perRecipeYield: null,
    isDefault: false,
  },
});
