import { Box, Checkbox, FormControlLabel, Radio, Typography } from "@mui/material";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";
import { useClientTimezone } from "@notemeal/shared-ui";
import { jsDateToDateAndTimeInTz, serializeDate, today } from "@notemeal/utils-date-time";
import { useState } from "react";
import { StartDateInPastBehavior } from "../../../views/Athlete/MealPlans/Content/Calendar/Form/DateAssignment";
import DayOfWeekSelector from "../../../views/Athlete/MealPlans/Content/Calendar/Form/DayOfWeekSelector";
import { MealPlanDateAssignment } from "../../../views/Athlete/MealPlans/Content/Calendar/Form/types";

interface BulkAssignMealPlanTemplateDateAssignmentFormProps {
  dateAssignment: MealPlanDateAssignment;
  setDateAssignment: (dateAssignment: MealPlanDateAssignment) => void;
  calendarLocation: "below" | "on the right";
  startDateInPastBehavior: StartDateInPastBehavior;
  size?: "small" | "medium";
}

export const BulkAssignMealPlanTemplateDateAssignmentForm = ({
  dateAssignment,
  setDateAssignment,
  startDateInPastBehavior,
  calendarLocation,
  size,
}: BulkAssignMealPlanTemplateDateAssignmentFormProps) => {
  const clientTimezone = useClientTimezone();
  const [startDateErrorMessage, setStartDateErrorMessage] = useState<string | null>(null);
  const [endDateErrorMessage, setEndDateErrorMessage] = useState<string | null>(null);

  const startDateProps: Partial<DesktopDatePickerProps<Date>> =
    startDateInPastBehavior === "error"
      ? {
          disablePast: true,
        }
      : {
          disabled: true,
        };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant={size === "small" ? "body2" : "body1"} color="textSecondary">
        {dateAssignment.mode === "weekly"
          ? "Select the days from the options listed below"
          : `Select dates from the calendar displayed ${calendarLocation}`}
      </Typography>
      {dateAssignment.mode === "weekly" && !dateAssignment.dayOfWeekPriorities.length && (
        <Typography variant={size === "small" ? "body2" : "body1"} color="error">
          Please select at least one day of the week below
        </Typography>
      )}
      {dateAssignment.mode === "individual" && !dateAssignment.individualDates.length && (
        <Typography variant={size === "small" ? "body2" : "body1"} color="error">
          Please select at least one day of the week below
        </Typography>
      )}
      <FormControlLabel
        control={
          <Radio
            size="medium"
            checked={dateAssignment.mode === "weekly"}
            onChange={() => setDateAssignment({ ...dateAssignment, mode: "weekly" })}
          />
        }
        label="Weekly"
        labelPlacement="end"
      />
      {dateAssignment.mode === "weekly" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, pl: 4, pb: 1 }}>
          <DayOfWeekSelector
            daysOfWeek={dateAssignment.dayOfWeekPriorities.map(d => d.dayOfWeek)}
            onToggleDayOfWeek={dayOfWeek => {
              let dayOfWeekPriorities = dateAssignment.dayOfWeekPriorities;
              const matchingDayOfWeek = dateAssignment.dayOfWeekPriorities.find(d => d.dayOfWeek === dayOfWeek);
              if (matchingDayOfWeek) {
                dayOfWeekPriorities = dayOfWeekPriorities.filter(d => d !== matchingDayOfWeek);
              } else {
                dayOfWeekPriorities = [...dayOfWeekPriorities, { dayOfWeek: dayOfWeek, priority: 0 }];
              }

              setDateAssignment({ ...dateAssignment, dayOfWeekPriorities });
            }}
            sx={{ width: "100%", justifyContent: "left" }}
          />
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <DesktopDatePicker
              sx={{ mt: 3, width: 220 }}
              label="Start Date"
              onChange={date => {
                if (!date) {
                  setStartDateErrorMessage("No start date selected");
                } else if (startDateInPastBehavior === "error" && date < today) {
                  setStartDateErrorMessage("Start Date cannot be in the past");
                } else {
                  setStartDateErrorMessage(null);
                }
                setDateAssignment({
                  ...dateAssignment,
                  startDate: date ? jsDateToDateAndTimeInTz(date, clientTimezone).date : dateAssignment.startDate,
                  startDateRaw: date ?? dateAssignment.startDateRaw,
                });
              }}
              slotProps={{
                textField: {
                  helperText: startDateErrorMessage,
                  error: !!startDateErrorMessage,
                },
              }}
              value={dateAssignment.startDateRaw}
              {...startDateProps}
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <DesktopDatePicker
                sx={{ mt: 3, width: 220 }}
                label="End Date"
                onChange={date => {
                  if (!date) {
                    setEndDateErrorMessage("No end date selected");
                  } else if (date < today) {
                    setEndDateErrorMessage("End Date cannot be in the past");
                  } else if (serializeDate(date) < dateAssignment.startDate) {
                    setEndDateErrorMessage("End Date cannot not be before Start Date");
                  } else {
                    setEndDateErrorMessage(null);
                  }
                  setDateAssignment({
                    ...dateAssignment,
                    endDate: date ? jsDateToDateAndTimeInTz(date, clientTimezone).date : null,
                    endDateRaw: date,
                  });
                }}
                minDate={dateAssignment.startDateRaw}
                slotProps={{
                  textField: {
                    helperText: endDateErrorMessage,
                    error: !!endDateErrorMessage,
                  },
                }}
                disabled={dateAssignment.disableEndDate}
                value={dateAssignment.endDateRaw}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dateAssignment.disableEndDate}
                    onChange={() => {
                      setDateAssignment({ ...dateAssignment, disableEndDate: !dateAssignment.disableEndDate });
                      setEndDateErrorMessage(null);
                    }}
                  />
                }
                label="No end date"
              />
            </Box>
          </Box>
        </Box>
      )}
      <FormControlLabel
        control={
          <Radio
            size="medium"
            checked={dateAssignment.mode === "individual"}
            onChange={() => setDateAssignment({ ...dateAssignment, mode: "individual" })}
          />
        }
        label="Individual Dates"
        labelPlacement="end"
      />
    </Box>
  );
};
