import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { sortByKey } from "@notemeal/utils-sort";
import React, { useState } from "react";
import DialogTitle from "../../../componentLibrary/DialogTitle";
import { MenuItemChoiceFormFragment } from "../../../types";
import OptionListItem from "../OptionListItem";
import { OptionListWrapper } from "../utils";
import ReadonlyMenuItemChoiceOptionForm from "./ReadonlyOptionForm";

interface MenuItemChoiceEditIngredientsDialogProps {
  open: boolean;
  onClose: () => void;
  menuItemChoice: MenuItemChoiceFormFragment;
}

const MenuItemChoiceIngredientsDialog = ({ open, onClose, menuItemChoice }: MenuItemChoiceEditIngredientsDialogProps) => {
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(
    menuItemChoice.options.length === 0 ? null : sortByKey(menuItemChoice.options, "position")[menuItemChoice.options.length - 1].id
  );
  const selectedOption = menuItemChoice.options.find(o => o.id === selectedOptionId);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md">
      <DialogTitle title={menuItemChoice.name} onClose={onClose} />
      <DialogContent sx={{ display: "flex" }}>
        <OptionListWrapper>
          <Typography variant="h3">Choices</Typography>
          {sortByKey(menuItemChoice.options, "position").map(o => (
            <OptionListItem
              key={o.id}
              name={o.name}
              exclusive={menuItemChoice.required && menuItemChoice.maxOptionsCount === 1}
              servingAmounts={o.servingAmounts}
              selected={o.id === selectedOptionId}
              onSelect={() => setSelectedOptionId(o.id)}
            />
          ))}
        </OptionListWrapper>
        <ReadonlyMenuItemChoiceOptionForm option={selectedOption} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MenuItemChoiceIngredientsDialog;
