import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { MineralNutrientGroup } from "@notemeal/shared-ui";
import NutrientTreeItem from "./NutrientTreeItem";
import { CloseSquare, MinusSquare, PlusSquare } from "./StyledTreeItem";
import { generateTreeStyles } from "./styles";

const useStyles = makeStyles((theme: Theme) => createStyles(generateTreeStyles(theme.palette.accents.violet[50], theme)));

export interface MineralTreeProps {
  minerals: MineralNutrientGroup;
  displayDri: boolean;
}

const MineralTree = ({ minerals, displayDri }: MineralTreeProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h3">
        Minerals
      </Typography>
      <div className={classes.content}>
        <TreeView
          className={classes.tree}
          defaultExpanded={["1"]}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
        >
          <NutrientTreeItem
            isRoot={true}
            nodeId="1"
            displayDri={displayDri}
            nutrientAmount={minerals["Calcium"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="2"
            displayDri={displayDri}
            nutrientAmount={minerals["Iron"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="3"
            displayDri={displayDri}
            nutrientAmount={minerals["Magnesium"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="4"
            displayDri={displayDri}
            nutrientAmount={minerals["Phosphorus"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="5"
            displayDri={displayDri}
            nutrientAmount={minerals["Potassium"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="6"
            displayDri={displayDri}
            nutrientAmount={minerals["Sodium"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="7"
            displayDri={displayDri}
            nutrientAmount={minerals["Zinc"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="8"
            displayDri={displayDri}
            nutrientAmount={minerals["Copper"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="9"
            displayDri={displayDri}
            nutrientAmount={minerals["Manganese"]} />
          <NutrientTreeItem
            isRoot={true}
            nodeId="10"
            displayDri={displayDri}
            nutrientAmount={minerals["Selenium"]} />
        </TreeView>
      </div>
    </div>
  );
};

export default MineralTree;
