import { formatDateRange, jsDateToDateAndTimeInTz, parseTime, serializeDate } from "@notemeal/utils-date-time";
import {
  format,
  differenceInDays,
  startOfDay,
  addDays,
  isBefore,
  add,
  min,
  max,
  intervalToDuration,
  areIntervalsOverlapping,
} from "date-fns";
import { CopyMealMenuInput, CopyMealMenusInput } from "../../../types";
import { MealMenuInstance } from "../types";

interface GroupedMealMenuInstances {
  mealMenuInstances: readonly MealMenuInstance[];
  displayDate: string; // in format "eeee MMMM d" Monday April 10
  daysAfterAnchor: number;
}

export type GroupedMealMenuMap = Map<string, GroupedMealMenuInstances>;

const getValueOrDefault = (
  mapped: GroupedMealMenuMap,
  key: string,
  displayDate: string,
  daysAfterAnchor: number
): GroupedMealMenuInstances => {
  return (
    mapped.get(key) ?? {
      mealMenuInstances: [],
      displayDate,
      daysAfterAnchor,
    }
  );
};

const getAnchorInterval = (mealMenuInstances: readonly MealMenuInstance[], anchorDate: Date) => {
  const duration = intervalToDuration({
    start: min(mealMenuInstances.map(mmI => mmI.start)),
    end: max(mealMenuInstances.map(mmI => mmI.start)),
  });
  return { start: anchorDate, end: add(anchorDate, duration) };
};

export const getFormattedAnchorInterval = (anchorDate: Date, mealMenuInstances: readonly MealMenuInstance[]): string => {
  const { start, end } = getAnchorInterval(mealMenuInstances, anchorDate);
  return formatDateRange(start, end);
};

export const addAnchorDate = (newAnchorDate: Date, anchorDates: Date[], mealMenuInstances: readonly MealMenuInstance[]): Date[] => [
  ...anchorDates.filter(
    existingAnchorDate =>
      !areIntervalsOverlapping(
        getAnchorInterval(mealMenuInstances, newAnchorDate),
        getAnchorInterval(mealMenuInstances, existingAnchorDate),
        { inclusive: true }
      )
  ),
  newAnchorDate,
];

export const sortAndGroupByDate = (mealMenuInstances: readonly MealMenuInstance[]): GroupedMealMenuMap => {
  const groupedMap: GroupedMealMenuMap = new Map();
  if (mealMenuInstances.length < 1) {
    return groupedMap;
  }
  const sorted = [...mealMenuInstances].sort((a, b) => a.start.getTime() - b.start.getTime());
  const anchorDate = sorted[0].start;
  sorted.forEach(mm => {
    const dateString = serializeDate(mm.start);
    const displayDate = format(mm.start, "eeee (MMMM d)");
    const daysAfterAnchor = differenceInDays(startOfDay(mm.start), startOfDay(anchorDate));
    const existing = getValueOrDefault(groupedMap, dateString, displayDate, daysAfterAnchor);
    groupedMap.set(dateString, {
      ...existing,
      mealMenuInstances: [...existing.mealMenuInstances, mm],
    });
  });
  return groupedMap;
};

export const groupedDatesToBulkCopyInput = (groupedDates: GroupedMealMenuMap, anchorDates: Date[]): CopyMealMenusInput => {
  const mealMenuInputs = anchorDates
    .map(anchorDate => startOfDay(anchorDate))
    .flatMap(startOfAnchor => {
      const mealMenuInputs: CopyMealMenuInput[] = [];
      groupedDates.forEach(groupedInstance => {
        const newStartDayDate = addDays(startOfAnchor, groupedInstance.daysAfterAnchor);
        groupedInstance.mealMenuInstances.forEach(mealMenuInstance => {
          mealMenuInputs.push({
            mealMenuId: mealMenuInstance.id,
            newStartDay: serializeDate(newStartDayDate),
          });
        });
      });
      return mealMenuInputs;
    });

  return { mealMenuInputs };
};

export const timeIsBeforeFirstSelectedTime = (compareDate: Date, groupedDates: GroupedMealMenuMap, clientTimezone: string): boolean => {
  const values = [...groupedDates.values()];
  if (values.length === 0) {
    return true;
  }
  const { mealMenuInstances } = values[0];
  if (mealMenuInstances.length === 0) {
    return true;
  }

  const mealMenuInstance = mealMenuInstances[0];

  const start = mealMenuInstance.start;

  const { time: startTime } = jsDateToDateAndTimeInTz(start, clientTimezone);
  const { time: compareTime } = jsDateToDateAndTimeInTz(compareDate, clientTimezone);

  const normalizedStart = parseTime(startTime);

  const normalizedCompare = parseTime(compareTime);

  return isBefore(normalizedStart, normalizedCompare);
};
