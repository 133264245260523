import { MenuItem, SxProps, TextField, TextFieldProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortByKey } from "@notemeal/utils-sort";
import React from "react";
import { TeamMealMenuPreviewFragment } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectedTeam: {
      fontWeight: "bold",
    },
  })
);

export interface MenusTeamMultiSelectProps {
  onChange: (selectedTeams: readonly TeamMealMenuPreviewFragment[]) => void;
  allTeams: readonly TeamMealMenuPreviewFragment[];
  selectedTeams: readonly TeamMealMenuPreviewFragment[];
  className?: string;
  sx?: SxProps;
  disabled?: boolean;
  fullWidth?: boolean;
  displayedTeamsCount?: number;
  props?: Partial<TextFieldProps>;
}

const MenusTeamMultiSelect = ({
  selectedTeams,
  onChange,
  allTeams,
  className,
  sx,
  disabled,
  fullWidth,
  displayedTeamsCount,
  props,
}: MenusTeamMultiSelectProps) => {
  const classes = useStyles();
  const handleChange = (teamIds: string[]) => {
    onChange(allTeams.filter(t => teamIds.includes(t.id)));
  };

  const selectedTeamIds = selectedTeams.map(t => t.id);

  return (
    <TextField
      select
      SelectProps={{
        multiple: true,
        renderValue: value => {
          const teamIds = value as string[];
          const displayTeamIds = teamIds.slice(0, displayedTeamsCount || 3);
          const displayTeamNames = displayTeamIds.map(tId => {
            const matchingTeam = allTeams.find(t => t.id === tId);
            return matchingTeam ? matchingTeam.name : "";
          });
          const hiddenTeamCount = teamIds.slice(displayedTeamsCount || 3).length;
          return (
            <div>
              {displayTeamNames.join(", ")} {hiddenTeamCount > 0 ? `(+${hiddenTeamCount})` : ""}
            </div>
          );
        },
      }}
      label="Teams"
      inputProps={{
        "aria-label": "Teams",
      }}
      value={selectedTeams.map(t => t.id)}
      className={className}
      sx={sx}
      disabled={disabled}
      fullWidth={fullWidth}
      onChange={e => handleChange(e.target.value as unknown as string[])}
      {...props}
    >
      {sortByKey(allTeams, "name").map(t => (
        <MenuItem
          key={t.id}
          value={t.id}
          className={selectedTeamIds.includes(t.id) ? classes.selectedTeam : undefined}>
          {t.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default MenusTeamMultiSelect;
