import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { newServingAmount, useHasFeature } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useBrowserBackAndRefreshWarning } from "apps/web/src/hooks/useBrowserBackAndRefreshWarning";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { GptRecipeFragment, RecipeFullFragment } from "apps/web/src/types";
import React, { useReducer, useState } from "react";
import { newRecipeFormState, newRecipeFormStateFromGPTRecipe, recipeFormReducer } from "../../Recipe/Form/utils";
import { GenerateWithGPTDialog } from "../../Recipe/GPT/Generator/GenerateWithGPTDialog";
import { TranscribeWithGPTDialog } from "../../Recipe/GPT/Transcriber/TranscribeWithGPTDialog";
import { TranscribeError, getUserFriendlyMessage } from "../../Recipe/GPT/Transcriber/utils";
import RecipeCreateDialog from "../../Recipe/RecipeCreateDialog";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import DiscardChangesDialog from "../../universal/DiscardChangesDialog";
import { MenuItemFullEditForm } from "../Form/Form";
import { MenuItemState, menuItemReducer } from "../reducer";
import { newMenuItemState } from "../utils";

interface MenuItemDialogContentProps {
  open: boolean;
  onClose: () => void;
  onSave: (state: MenuItemState) => void;
  title: string;
  variant: "MenuItem" | "MenuItemAppearance";
  initState?: MenuItemState;
}

const MenuItemDialogContent = ({ open, onClose, onSave, title, initState, variant }: MenuItemDialogContentProps) => {
  const [state, dispatch] = useReducer(menuItemReducer, initState || newMenuItemState("", false));
  const [initialRecipeState, initialRecipeDispatch] = useReducer(
    recipeFormReducer,
    undefined || newRecipeFormState(initState?.menuItem.name, initState?.menuItem.servingAmounts)
  );
  const { setMessage } = useSnackbar();
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);
  const [showGenerateRecipeModal, setShowGenerateRecipeModal] = useState(false);
  const [showTranscribeRecipeModal, setShowTranscribeRecipeModal] = useState(false);
  const [transcriberError, setTranscriberError] = useState<TranscribeError | null>(null);
  const [showCreateRecipeModal, setShowCreateRecipeModal] = useState(false);

  const showAiRecipes = useHasFeature("performanceKitchen");

  const onAiGenerateClicked = () => {
    trackEvent("Nutrition | Food Management | Menu item | Enter AI Generate Flow", {});
    setShowGenerateRecipeModal(true);
  };
  const onAiTranscribeClicked = () => {
    trackEvent("Nutrition | Food Management | Menu item | Enter AI Transcription Flow", {});
    setShowTranscribeRecipeModal(true);
  };
  const { setBrowserBackAndRefreshWarningEnabled } = useBrowserBackAndRefreshWarning();

  React.useEffect(() => {
    setBrowserBackAndRefreshWarningEnabled(state.edited);
  }, [state.edited, setBrowserBackAndRefreshWarningEnabled]);

  const handleClose = () => {
    if (state.edited) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  const onGenerateError = (error: any) => {
    const userFriendlyMessage = getUserFriendlyMessage(error);
    setMessage("error", userFriendlyMessage);
  };

  const onSaveAiRecipe = (recipe: RecipeFullFragment) => {
    setMessage("success", `${recipe.name} has been created.`);
    const newRecipeServing = recipe.servings[0];
    const newRecipeServingAmounts = [
      newServingAmount(
        {
          ...newRecipeServing,
          foodOrRecipe: recipe,
        },
        1
      ),
    ];
    dispatch({
      type: "MenuItemChangeServingAmountsAction",
      payload: {
        servingAmounts: newRecipeServingAmounts,
      },
    });
  };

  const onGPTRecipeComplete = (gptRecipe: GptRecipeFragment, closeModal: () => void) => {
    const newState = newRecipeFormStateFromGPTRecipe(gptRecipe);
    initialRecipeDispatch({
      type: "REPLACE_STATE",
      payload: newState,
    });
    closeModal();
    setShowCreateRecipeModal && setShowCreateRecipeModal(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      PaperProps={{
        sx: { minHeight: "calc(100% - 20px)" },
      }}
      fullWidth
    >
      <DialogTitle title={title} onClose={handleClose} />
      <DialogContent>
        <MenuItemFullEditForm
          state={state}
          dispatch={dispatch}
          variant={variant}
          onAiGenerateClicked={showAiRecipes ? onAiGenerateClicked : null}
          onAiTranscribeClicked={showAiRecipes ? onAiTranscribeClicked : undefined}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button onClick={() => onSave(state)}>{variant === "MenuItemAppearance" ? "Done" : "Save"}</Button>
      </DialogActions>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}
      {showGenerateRecipeModal && (
        <GenerateWithGPTDialog
          open={showGenerateRecipeModal}
          onClose={() => setShowGenerateRecipeModal(false)}
          onGenerateComplete={onGPTRecipeComplete}
          onError={onGenerateError}
          initialName={state.menuItem.name}
        />
      )}
      {showTranscribeRecipeModal && (
        <TranscribeWithGPTDialog
          open={showTranscribeRecipeModal}
          onClose={() => setShowTranscribeRecipeModal(false)}
          onTranscribeComplete={onGPTRecipeComplete}
          transcriberError={transcriberError}
          setTranscriberError={setTranscriberError}
          onError={onGenerateError}
        />
      )}
      {showCreateRecipeModal && (
        <RecipeCreateDialog
          open={showCreateRecipeModal}
          onClose={() => setShowCreateRecipeModal(false)}
          onCreate={recipe => onSaveAiRecipe(recipe)}
          initialRecipeState={initialRecipeState}
        />
      )}
    </Dialog>
  );
};

export default MenuItemDialogContent;
