import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { NutritionColorIcon, TeamworksBadgeIcon } from "@notemeal/shared-ui";
import { ButtonType, TWButtonGroup } from "apps/web/src/componentLibrary/TWButtonGroup/TWButtonGroup";
import { useEffect, useState } from "react";

export const ThemeViewerButtonGroup = () => {
  const [iconChecked, setIconChecked] = useState(false);
  const [customChecked, setCustomChecked] = useState(false);
  const [scrollChecked, setScrollChecked] = useState(false);
  const [selected, setSelected] = useState<string>();
  const [buttons, setButtons] = useState<ButtonType[]>([]);

  useEffect(() => {
    const BUTTON1 = "button1";
    const BUTTON2 = "button2";
    const BUTTON3 = "button3";
    const BUTTON4 = "button4";
    const BUTTON5 = "button5";
    const BUTTON6 = "button6";
    const BUTTON7 = "button7";
    const BUTTON8 = "button8";
    const BUTTON9 = "medium text";
    const BUTTON10 = "some long text";
    const ICON_BUTTON = {
      label: "Templates",
      icon: <NutritionColorIcon />,
    };
    const KIOSK_CHECKIN = "Kiosk Check-in";
    const CUSTOM_BUTTON = {
      label: KIOSK_CHECKIN,
      element: (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <TeamworksBadgeIcon />
          x
          <NutritionColorIcon />
          {KIOSK_CHECKIN}
        </Box>
      ),
    };
    const iconButtons = [ICON_BUTTON];
    const customButtons = [CUSTOM_BUTTON];
    const standardButtons = [BUTTON1, BUTTON2, BUTTON3];
    const extraButtons = [BUTTON4, BUTTON5, BUTTON6, BUTTON7, BUTTON8, BUTTON9, BUTTON10];

    setButtons([
      ...(iconChecked ? iconButtons : []),
      ...(customChecked ? customButtons : []),
      ...standardButtons,
      ...(scrollChecked ? extraButtons : []),
    ]);
  }, [iconChecked, customChecked, scrollChecked]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h2">ButtonGroup</Typography>
      <Box sx={{ alignItems: "flex-start", display: "flex", gap: 2 }}>
        <FormControlLabel control={<Checkbox checked={iconChecked} onChange={() => setIconChecked(!iconChecked)} />} label="Icon" />
        <FormControlLabel control={<Checkbox checked={customChecked} onChange={() => setCustomChecked(!customChecked)} />} label="Custom" />
        <FormControlLabel control={<Checkbox checked={scrollChecked} onChange={() => setScrollChecked(!scrollChecked)} />} label="Scroll" />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h3">New</Typography>
        <TWButtonGroup
          sx={scrollChecked ? { width: 525 } : {}}
          buttons={buttons}
          onSelected={selected => {
            setSelected(selected);
          }}
        />
        <Typography>{`Selected button: ${selected}`}</Typography>
      </Box>
    </Box>
  );
};
