import { Autocomplete, SxProps, TextField } from "@mui/material";
import moment from "moment-timezone";
import React from "react";

const zones = new Set<string>();
const countries = moment.tz.countries();

for (const country of countries) {
  moment.tz.zonesForCountry(country).reduce((set, zone) => set.add(zone), zones);
}

const TIMEZONE_NAMES = [...zones].sort();

export interface MenuTimezoneSelectProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  sx?: SxProps;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

const MenuTimezoneSelect = ({ value, onChange, className, sx, error, helperText, disabled }: MenuTimezoneSelectProps) => (
  <Autocomplete
    options={TIMEZONE_NAMES}
    disabled={disabled}
    value={value}
    onChange={(_, val) => {
      if (val) {
        onChange(val);
      }
    }}
    disableClearable
    renderInput={params => (
      <TextField
        {...{
          ...params,
          inputProps: {
            ...params.inputProps,
            "aria-label": "Timezone",
          },
        }}
        sx={sx}
        className={className}
        style={{ minWidth: "250px" }}
        label={"Timezone"}
        error={error}
        helperText={helperText}
      />
    )}
  />
);

export default MenuTimezoneSelect;
