import { AddMenuOrderPayload, AthleteFoodPreferenceContextProvider, getDefaultMealPlan, MealPlanFoodPreferenceContextProvider, useClientTimezone } from "@notemeal/shared-ui";
import { sortByFn, sortByKey } from "@notemeal/utils-sort";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useEffect } from "react";
import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";
import {
    KioskMealMenuPreviewFragment,
    OrderKioskAthletePreviewFragment,
    useAddMenuOrderKioskMutation,
    useEditMealPlanDateMutation,
    useKioskAthleteQuery,
    useRemoveMenuOrderMutation,
} from "../../../../types";
import { addTimelineMealIfNotPresent, evictAthleteTimelineInCache } from "../../../Athlete/FoodLogs/cache";
import { MacroProgressViewsContextProvider } from "../../../Athlete/FoodLogs/contexts/useMacroProgressViews";
import { getTimelineMealInputForKiosk } from "../../utils";
import { AthleteOrderStateAction, OrderFormAthleteOrderState } from "../orderStateReducer";
import KioskMealMenuDialog from "./Add";
import KioskMenuOrderEditDialog from "./Edit";
interface OrderFormProps {
  athlete: OrderKioskAthletePreviewFragment;
  mealMenu: KioskMealMenuPreviewFragment;
  open: boolean;
  onClose: () => void;
  date: string;
  athleteOrderState: OrderFormAthleteOrderState;
  athleteOrderStateDispatch: React.Dispatch<AthleteOrderStateAction>;
}

const OrderForm = ({ athlete, mealMenu, open, onClose, date, athleteOrderState, athleteOrderStateDispatch }: OrderFormProps) => {
  const clientTimezone = useClientTimezone();
  const athleteId = athlete.id;
  const mealMenuId = mealMenu.id;
  const athleteName = `${athlete.firstName} ${athlete.lastName}`;

  const { data } = useKioskAthleteQuery({
    variables: {
      athleteId,
      mealMenuId,
      date,
      timezone: clientTimezone,
    },
    fetchPolicy: "network-only",
  });

  const menuOrders = data?.athlete.ordersForMenu
    ? sortByKey(data.athlete.ordersForMenu, "createdAt", {
        reverse: true,
      })
    : [];

  const athleteMealPlans = data?.athlete.mealPlans;
  const timelineDate = data?.athlete.timelineDate;
  const mealPlan = timelineDate?.mealPlan ?? null;
  const meals = timelineDate?.meals ?? [];

  const [editMealPlanDate] = useEditMealPlanDateMutation({
    update: (cache, { data }) => {
      if (data) {
        evictAthleteTimelineInCache({
          cache,
          date,
          timezone: clientTimezone,
          athleteId,
        });
      }
    },
  });

  useEffect(() => {
    const handleEditMealPlanDate = (mealPlanId: string) => {
      editMealPlanDate({
        variables: {
          input: {
            date,
            mealPlanId,
            athleteId,
            timezone: clientTimezone,
          },
        },
      });
    };

    if (!mealPlan && athleteMealPlans) {
      const defaultMealPlan = getDefaultMealPlan(athleteMealPlans);
      defaultMealPlan && handleEditMealPlanDate(defaultMealPlan.id);
    }
  }, [mealPlan, athleteMealPlans, athleteId, clientTimezone, date, editMealPlanDate]);

  // TODO: finding matchingTimelineMealOrTemplate is used in apps/mobile/src/modes/Athlete/Today/shared/TodayModalLoaderContent.tsx can likely be made into shared code
  // Need to handle multiple order case
  const matchingTimelineMealOrTemplates = meals.filter(meal => {
    return meal.mealMenus.map(mm => mm.id).includes(mealMenu.id);
  });

  const matchingTimelineMealOrTemplate = sortByFn(matchingTimelineMealOrTemplates, meal => {
    if (meal.__typename === "TimelineMeal") {
      const hasMenuOrderOnMenu = meal.menuOrders.some(mo => mo.mealMenu.id === mealMenu.id);
      const hasRestaurantMenuLinkOrderOnMenu = meal.restaurantMenuLinkOrders.some(mo => mo.mealMenu.id === mealMenu.id);
      const hasRestaurantMenuLinkPlateOrderOnMenu = meal.restaurantMenuLinkPlateOrders.some(mo => mo.mealMenu.id === mealMenu.id);

      if (hasMenuOrderOnMenu || hasRestaurantMenuLinkOrderOnMenu || hasRestaurantMenuLinkPlateOrderOnMenu) {
        return -1;
      }
    }

    const sharesMealTypeWithMenu =
      meal.__typename === "TimelineMeal"
        ? meal.mealTemplate?.meal.type === mealMenu.mealType
        : meal.template.meal.type === mealMenu.mealType;

    if (sharesMealTypeWithMenu) {
      return 1;
    }

    return 2;
  }).find(() => true);

  const mealTemplate =
    matchingTimelineMealOrTemplate?.__typename === "TimelineMeal"
      ? matchingTimelineMealOrTemplate.mealTemplate
      : matchingTimelineMealOrTemplate?.__typename === "TimelineMealTemplate"
      ? matchingTimelineMealOrTemplate.template
      : null;

  const { setMessage } = useSnackbar();

  const [addMenuOrder] = useAddMenuOrderKioskMutation({
    onCompleted: data =>
      setMessage("success", `Order ${data.addMenuOrderForAthlete.menuOrder.items.length > 0 ? "placed" : "logged"} for ${athleteName}`),
    onError: () => setMessage("error", `Something went wrong!`),
    refetchQueries: ["KioskAthlete", "OrderKioskAthletes"],
    update: (cache, { data }) => {
      // Athlete TimelineMeal Cache Update
      if (data && data.addMenuOrderForAthlete.menuOrder.timelineMeal) {
        addTimelineMealIfNotPresent({
          athleteId,
          date,
          timezone: clientTimezone,
          cache,
          timelineMeal: data.addMenuOrderForAthlete.menuOrder.timelineMeal,
        });
      }
    },
  });

  const handleAddMenuOrder = (
    { pickupTime, logItems, orderItems }: AddMenuOrderPayload,
    mealMenuId: string,
    mealTemplateId: string | null
  ) => {
    const timelineMealInput = getTimelineMealInputForKiosk(matchingTimelineMealOrTemplate);
    addMenuOrder({
      variables: {
        input: {
          athleteId,
          pickupTime,
          logItems,
          orderItems,
          mealMenuId,
          mealTemplateId,
          items: null,
          images: null,
          expectedOrderCount: menuOrders?.length || null,
          timelineMeal: timelineMealInput,
        },
      },
    });
  };

  const [removeMenuOrder] = useRemoveMenuOrderMutation({
    refetchQueries: ["KioskAthlete", "OrderKioskAthletes"],
  });

  const handleRemoveMenuOrder = (menuOrderId: string) => {
    onClose();
    removeMenuOrder({ variables: { input: { menuOrderId } } });
  };

  if (!data) {
    return <LoadingBackdrop open={!data} onClose={onClose} />;
  }

  const avoidFoodGroupIds = [
    ...data.athlete.dislikedFoodGroups.map(fg => fg.id),
    ...(mealPlan ? mealPlan.avoidedFoodGroups.map(fg => fg.id) : []),
  ];

  const getContent = () => {
    const stateType = athleteOrderState.type;
    if (stateType === "edit") {
      if (data.athlete.ordersForMenu.length === 0) {
        // TODO: What do we do if this empty
        return null;
      }
      const activeMenuOrder = data.athlete.ordersForMenu.find(mo => mo.id === athleteOrderState.menuOrderId);
      if (activeMenuOrder) {
        return (
          <KioskMenuOrderEditDialog
            activeMenuOrder={activeMenuOrder}
            onRemoveMenuOrder={handleRemoveMenuOrder}
            menuOrders={data.athlete.ordersForMenu}
            onClose={onClose}
            open={open}
            teamId={athlete.team.id}
            athleteId={athlete.id}
            athleteUserId={athlete.user.id}
            athleteName={athleteName}
            avoidFoodGroupIds={avoidFoodGroupIds}
            athleteOrderState={athleteOrderState}
            athleteOrderStateDispatch={athleteOrderStateDispatch}
          />
        );
      } else {
        // TODO: What do we do if this does not match
        return null;
      }
    } else {
      return (
        <KioskMealMenuDialog
          viewOnly={stateType === "viewOnly"}
          onAddMenuOrder={handleAddMenuOrder}
          mealMenu={mealMenu}
          mealTemplate={mealTemplate}
          onClose={onClose}
          open={open}
          teamId={athlete.team.id}
          athleteId={athleteId}
          athleteUserId={athlete.user.id}
          athleteName={athleteName}
          avoidFoodGroupIds={avoidFoodGroupIds}
          menuOrders={menuOrders}
          athleteOrderState={athleteOrderState}
          athleteOrderStateDispatch={athleteOrderStateDispatch}
        />
      );
    }
  };

  return (
    <AthleteFoodPreferenceContextProvider
      likedFoods={[...data.athlete.likedFoods]}
      dislikedFoods={[...data.athlete.dislikedFoods]}
      likedFoodGroups={[...data.athlete.likedFoodGroups]}
      dislikedFoodGroups={[...data.athlete.dislikedFoodGroups]}
    >
      <MealPlanFoodPreferenceContextProvider
        promotedFoods={mealPlan?.promotedFoods || []}
        avoidedFoods={mealPlan?.avoidedFoods || []}
        promotedFoodGroups={mealPlan?.promotedFoodGroups || []}
        avoidedFoodGroups={mealPlan?.avoidedFoodGroups || []}
      >
        <MacroProgressViewsContextProvider macrosProgressViews={data.athlete.macroDisplaySettings.mealProgressViews}>
          {/* TODO: should this always be an orderable thing if we are in the edit state / what do we do if the ordersForMenu are not matching the selected Id */}
          {getContent()}
        </MacroProgressViewsContextProvider>
      </MealPlanFoodPreferenceContextProvider>
    </AthleteFoodPreferenceContextProvider>
  );
};

export default OrderForm;
