import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FormControl, IconButton, Menu, MenuItem, Switch, TableCell, TableRow, Tooltip } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import { useNotemealRecipesTableQuery } from "apps/web/src/types";
import { useEffect } from "react";
import { useRecipesPage } from "../useRecipesPage";

export const NotemealRecipeRows = () => {
  const {
    paginationHooks,
    handleEditIsShared,
    createdById,
    selectedMealTypes,
    onEditRecipe,
    setMoreInfo,
    moreInfo,
    onDuplicate,
    setDeleteableRecipe,
    setPrintRecipeId,
    sortDirection,
    setTotalRows,
    setRowsLoading,
  } = useRecipesPage();
  const { limit, offset, query } = paginationHooks;
  const { formatDateWithLocale } = useDateFormatting();

  const { data: recipeData, loading: recipeLoading } = useNotemealRecipesTableQuery({
    variables: {
      input: { limit, offset },
      sort: { key: "createdAt", direction: sortDirection },
      query,
      createdById,
      mealTypes: selectedMealTypes.length > 0 ? selectedMealTypes : null,
      orgId: null,
    },
    onCompleted: data => {
      if (data) {
        setTotalRows(data.recipeOffsetConnection.pageInfo.total);
      }
    },
  });

  useEffect(() => {
    setRowsLoading(recipeLoading);
  }, [recipeLoading, setRowsLoading]);

  const rows = recipeData?.recipeOffsetConnection.edges || [];

  return (
    <>
      {rows.map(row => {
        const { id, name, isShared, createdBy, mealTypes, servings, createdAt } = row;
        return (
          <TableRow
            key={id}
            id={id}
            hover
            sx={{ cursor: "pointer" }}
            onClick={() => onEditRecipe(row)}>
            <TableCell>
              <Tooltip title={"Share recipe with athletes"}>
                <FormControl>
                  <Switch
                    size="medium"
                    inputProps={{
                      "aria-label": "Share recipe with athletes",
                    }}
                    checked={isShared}
                    onClick={e => e.stopPropagation()}
                    onChange={e => {
                      handleEditIsShared(id, e.target.checked);
                    }}
                  />
                </FormControl>
              </Tooltip>
            </TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>
              {createdBy.firstName} {createdBy.lastName}
            </TableCell>
            <TableCell>
              {mealTypes &&
                mealTypes
                  .map(mt => {
                    const mealTypeString = mt.toString();
                    return mealTypeString.charAt(0).toUpperCase() + mealTypeString.slice(1);
                  })
                  .sort()
                  .join(", ")}
            </TableCell>
            <TableCell>{servings.map(s => `${s.perRecipeYield} ${s.units}`).join(", ")}</TableCell>
            <TableCell>{formatDateWithLocale(new Date(createdAt))}</TableCell>
            <TableCell>
              <Tooltip title="Duplicate recipe to your org">
                <IconButton
                  size="small"
                  onClick={e => {
                    e.stopPropagation();
                    onDuplicate(id);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
      <Menu
        anchorEl={moreInfo?.moreAnchorElement}
        open={!!moreInfo}
        onClose={() => {
          setMoreInfo(null);
        }}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {moreInfo && [
          <MenuItem
            dense
            onClick={() => {
              onDuplicate(moreInfo.recipe.id);
              setMoreInfo(null);
            }}
          >
            Duplicate
          </MenuItem>,
          <MenuItem
            dense
            onClick={() => {
              setDeleteableRecipe(moreInfo.recipe);
              setMoreInfo(null);
            }}
          >
            Delete
          </MenuItem>,
          <MenuItem
            dense
            onClick={() => {
              setPrintRecipeId(moreInfo.recipe.id);
              setMoreInfo(null);
            }}
          >
            Print
          </MenuItem>,
        ]}
      </Menu>
    </>
  );
};
