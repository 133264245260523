import { BulkImportFoodFragment, CreateFoodNutrientAmountFormInput, NutrientAmountFragment, UnitFragment } from "../../../../types";
import { IMatchResult } from "../../../../utils/import/match";
import { getServingUnits } from "../../../Serving/utils";
import { ImportableFoodEntry } from "./types";
import { round } from "@notemeal/shared-ui";
import { convertVitaminDToIU, convertVitaminDToMcg } from "@notemeal/shared-ui";
import { FORM_NUTRIENTS } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { LocaleType } from "@notemeal/locale-utils";

export const GRAMS = "gram(s)";

export const areEqual = (val1: string | number | null | undefined, val2: string | number | null | undefined) => {
  if (!val1 && !val2) {
    return true;
  } else if (!val1 || !val2) {
    return false;
  } else if (isNaN(Number(val1)) && isNaN(Number(val2))) {
    return val1 === val2;
  } else if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
    return Math.abs(Number(val1) - Number(val2)) < 0.0001;
  } else {
    return false;
  }
};

export const equalsExistingEntry = (match: IMatchResult<ImportableFoodEntry, {}>): boolean => {
  if (match.matchedRows.length === 0) {
    return false;
  }

  const servingName = match.row["servingName"];
  const firstMatch = match.matchedRows[0];

  if (!!servingName && servingName !== firstMatch["servingName"]) {
    return false;
  } else if (!areEqual(match.row["weight"] * (match.row["defaultAmount"] || 1), firstMatch.defaultAmount || 1 * firstMatch.weight)) {
    return false;
  }
  return Object.keys(match.row).every(keyString => {
    const key = keyString as keyof ImportableFoodEntry;
    if (key === "weight" || key === "servingName" || key === "defaultAmount") {
      //these are covered above
      return true;
    }
    return areEqual(match.row[key], firstMatch[key]);
  });
};

export const getServingForImportedFood = (
  servingName: string | null | undefined,
  units: readonly UnitFragment[],
  weight: number,
  defaultAmount: number | null | undefined
) => {
  const partialServing = servingName
    ? {
        units: getUnitFromServingName(servingName, units),
        weight: weight,
        defaultAmount: defaultAmount || 1,
      }
    : {
        units: getUnitFromServingName(null, units),
        weight: 1,
        defaultAmount: weight,
      };
  return {
    ...partialServing,
    usdaWeightSeq: null,
    isDefault: true,
    isRecipeServingOnly: false,
  };
};

export const getUnitFromServingName = (servingName: string | null, units: readonly UnitFragment[]) => {
  if (!servingName) {
    const maybeUnit = units.find(unit => unit.name.startsWith(GRAMS));
    return {
      customUnits: null,
      unitSuffix: null,
      unitPrefix: null,
      unitId: maybeUnit?.id || null,
    };
  }
  const lowerCaseServingName = servingName.toLowerCase();
  const containingUnitIndex = units.findIndex(u => !!lowerCaseServingName.includes(u.name.toLowerCase()));
  if (containingUnitIndex === -1) {
    return {
      customUnits: servingName,
      unitSuffix: null,
      unitPrefix: null,
      unitId: null,
    };
  } else {
    const baseServing = units[containingUnitIndex];
    const baseServingIndex = lowerCaseServingName.indexOf(baseServing.name.toLowerCase());
    const prefix = lowerCaseServingName.substring(0, baseServingIndex);
    const suffix = lowerCaseServingName.substring(prefix.length + baseServing.name.length);
    return {
      customUnits: null,
      unitSuffix: suffix,
      unitPrefix: prefix,
      unitId: baseServing.id,
    };
  }
};

export type NutrientForEntry = {
  [name: string]: number;
};

const initialNutrientForEntry = {
  saturatedFat: 0,
  transFat: 0,
  cholesterol: 0,
  sodium: 0,
  fiber: 0,
  totalSugars: 0,
  addedSugars: 0,
  vitaminDmcg: 0,
  calcium: 0,
  iron: 0,
  potassium: 0,
};

export const getEntryFromNutrientAmounts = (
  nutrientAmounts: readonly NutrientAmountFragment[],
  defaultAmount: number,
  weight: number
): NutrientForEntry => {
  let entryReturn: NutrientForEntry = initialNutrientForEntry;
  nutrientAmounts.forEach(na => {
    const scaledAmount = round((na.amount * defaultAmount * weight) / 100);
    switch (na.nutrient.name) {
      case "Calcium, Ca":
        return (entryReturn = { ...entryReturn, calcium: scaledAmount });
      case "Cholesterol":
        return (entryReturn = { ...entryReturn, cholesterol: scaledAmount });
      case "Fatty acids, total saturated":
        return (entryReturn = { ...entryReturn, saturatedFat: scaledAmount });
      case "Fatty acids, total trans":
        return (entryReturn = { ...entryReturn, transFat: scaledAmount });
      case "Fiber, total dietary":
        return (entryReturn = { ...entryReturn, fiber: scaledAmount });
      case "Iron, Fe":
        return (entryReturn = { ...entryReturn, iron: scaledAmount });
      case "Potassium, K":
        return (entryReturn = { ...entryReturn, potassium: scaledAmount });
      case "Sodium, Na":
        return (entryReturn = { ...entryReturn, sodium: scaledAmount });
      case "Vitamin D (D2 + D3), International Units":
        return (entryReturn = {
          ...entryReturn,
          vitaminDmcg: convertVitaminDToMcg(scaledAmount),
        });
      case "Sugars, added":
        return (entryReturn = { ...entryReturn, addedSugars: scaledAmount });
      case "Sugars, total including NLEA":
        return (entryReturn = { ...entryReturn, totalSugars: scaledAmount });
    }
    return
  });
  return entryReturn;
};

export interface AdditionalFoodInfo {
  additionalServings: string;
  photo: string;
}

export const getImportableExistingFoodsFromFoods = (
  foods: readonly BulkImportFoodFragment[] | undefined
):
  | (ImportableFoodEntry &
      AdditionalFoodInfo & {
        id: string;
      })[]
  | undefined => {
  return foods?.map(food => {
    const { name, choPer100g, proPer100g, fatPer100g, nutrientAmounts, defaultServing, servings, thumbnailUrl, id, groceryListCategory } =
      food;

    return {
      id,
      name: name,
      servingName: getServingUnits(defaultServing) || GRAMS,
      weight: defaultServing.defaultAmount * (defaultServing.weight || 1),
      carbohydrate: round((choPer100g * (defaultServing.defaultAmount * (defaultServing.weight || 1) || 100)) / 100),
      protein: round((proPer100g * (defaultServing.defaultAmount * (defaultServing.weight || 1) || 100)) / 100),
      fat: round((fatPer100g * (defaultServing.defaultAmount * (defaultServing.weight || 1) || 100)) / 100),
      ...getEntryFromNutrientAmounts(nutrientAmounts, defaultServing.defaultAmount, defaultServing.weight || 1),
      additionalServings: servings.length > 1 ? "Yes" : "No",
      photo: !!thumbnailUrl ? "Yes" : "None uploaded",
      thumbnailUrl,
      groceryListCategoryId: groceryListCategory?.id || null,
    };
  });
};

const getFoodNutrientValueFromImport = (nutrient: (typeof FORM_NUTRIENTS)[number], entry: ImportableFoodEntry): number => {
  switch (nutrient) {
    case "Calcium, Ca":
      return entry?.calcium || 0;
    case "Cholesterol":
      return entry?.cholesterol || 0;
    case "Fatty acids, total saturated":
      return entry.saturatedFat || 0;
    case "Fatty acids, total trans":
      return entry.transFat || 0;
    case "Fiber, total dietary":
      return entry.fiber || 0;
    case "Iron, Fe":
      return entry.iron || 0;
    case "Potassium, K":
      return entry.potassium || 0;
    case "Sodium, Na":
      return entry.sodium || 0;
    case "Vitamin D (D2 + D3), International Units":
      return entry.vitaminDmcg ? convertVitaminDToIU(entry.vitaminDmcg) : 0;
    case "Sugars, added":
      return entry.addedSugars || 0;
    case "Sugars, total including NLEA":
      return entry.totalSugars || 0;
    case "Carbohydrate, by difference":
      return entry.carbohydrate || 0;
    case "Total lipid (fat)":
      return entry.fat || 0;
    case "Protein":
      return entry.fat || 0;
    default:
      return 0;
  }
};

export const importedNutrientsForInput = (entry: ImportableFoodEntry): CreateFoodNutrientAmountFormInput[] => {
  return FORM_NUTRIENTS.flatMap(nutrient => {
    return {
      nutrientName: nutrient,
      amount: (getFoodNutrientValueFromImport(nutrient, entry) * 100) / entry.weight,
    };
  });
};

export const convertMatchToBaseFoodInput = (
  match: IMatchResult<ImportableFoodEntry, AdditionalFoodInfo>,
  units: readonly UnitFragment[]
) => {
  const { name, weight, carbohydrate, protein, fat, defaultAmount, servingName, thumbnailUrl, groceryListCategoryId } = match.row;

  const serving = getServingForImportedFood(servingName, units, weight, defaultAmount);

  return {
    food: {
      usdaFdcId: null,
      usdaFdcDescription: null,
      usdaFdcFoodCategoryId: null,
      usdaFdcDataType: null,
      mccanceId: null,
      mccanceName: null,
      isRecipeIngredientOnly: false,
      excludeFromSuggestions: false,
      name,
      thumbnailUrl,
      groceryListCategoryId,
      choPer100g: (carbohydrate * 100) / weight,
      proPer100g: (protein * 100) / weight,
      fatPer100g: (fat * 100) / weight,
      exchangeTypes: [],
      locales: [],
    },
    servings: [serving],
    nutrientAmounts: [],
    nutrientAmountsManualEntry: importedNutrientsForInput(match.row),
  };
};

export const getOrgFoodsInsertInput = (
  matches: IMatchResult<ImportableFoodEntry, AdditionalFoodInfo>[],
  units: readonly UnitFragment[],
  defaultLocale: LocaleType
) => {
  return matches.map(match => {
    const input = convertMatchToBaseFoodInput(match, units);
    return {
      ...input,
      food: {
        ...input.food,
        source: "manual" as const,
        locales: [defaultLocale],
        foodGroupIds: [],
      },
    };
  });
};

export const getRestaurantFoodsInsertInput = (
  matches: IMatchResult<ImportableFoodEntry, AdditionalFoodInfo>[],
  units: readonly UnitFragment[],
  restaurantId: string
) => {
  return matches.map(match => {
    const input = convertMatchToBaseFoodInput(match, units);
    return {
      ...input,
      restaurantId,
      food: {
        ...input.food,
        brandId: null,
        source: "restaurant" as const,
        foodGroupIds: [],
      },
    };
  });
};

const getMostRecentMatch = (
  match: IMatchResult<ImportableFoodEntry, AdditionalFoodInfo>,
  foods: readonly BulkImportFoodFragment[] | undefined
) => {
  return (
    foods &&
    sortByKey(
      foods.filter(food => match.matchedRows.map(match => match.id).includes(food.id)),
      "updatedAt"
    )[0]
  );
};

const baseGetFoodsUpdateInput = (
  matches: IMatchResult<ImportableFoodEntry, AdditionalFoodInfo>[],
  units: readonly UnitFragment[],
  foods: readonly BulkImportFoodFragment[] | undefined
) => {
  if (!foods || matches.length === 0) {
    return [];
  }
  return matches
    .filter(match => {
      if (!!match) {
        return !equalsExistingEntry(match);
      }
      return false;
    })
    .flatMap(match => {
      const mostRecentMatch = getMostRecentMatch(match, foods);
      if (match.matchedRows.length === 0 || !mostRecentMatch) {
        return [];
      }
      return {
        id: mostRecentMatch.id,
        ...convertMatchToBaseFoodInput(match, units),
      };
    });
};

export const getOrgFoodsUpdateInput = (
  matches: IMatchResult<ImportableFoodEntry, AdditionalFoodInfo>[],
  units: readonly UnitFragment[],
  foods: readonly BulkImportFoodFragment[] | undefined
) => {
  return baseGetFoodsUpdateInput(matches, units, foods).map(input => ({
    ...input,
    food: { ...input.food, source: "manual" as const, foodGroupIds: [] },
  }));
};

export const getRestaurantFoodsUpdateInput = (
  matches: IMatchResult<ImportableFoodEntry, AdditionalFoodInfo>[],
  units: readonly UnitFragment[],
  foods: readonly BulkImportFoodFragment[] | undefined,
  restaurantId: string
) => {
  return baseGetFoodsUpdateInput(matches, units, foods).map(input => ({
    ...input,
    food: {
      ...input.food,
      brandId: null,
      source: "restaurant" as const,
      foodGroupIds: [],
    },
    restaurantId,
  }));
};
