import RestaurantIcon from "@mui/icons-material/Restaurant";
import { ListItemIcon } from "@mui/material";
import LoadingBackdrop from "apps/web/src/components/universal/LoadingBackdrop";
import { useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { getNavOrgKitchenKioskDate } from "../../../pages/Auth/Org/Kitchen/KitchenPaths";
import { KioskMealMenuPreviewFragment, OrderKioskAthletePreviewFragment, useOrderKioskAthletesQuery } from "../../../types";
import Kiosk from "../KioskDialog";
import MenuDialog from "../MenuDialog";
import AthleteSelector from "./AthleteNavigation";
import OrderForm from "./Dialog";
import SelectNewOrEditDialog from "./SelectNewOrEditDialog";
import { athleteOrderStateReducer } from "./orderStateReducer";

interface AthleteNavigationProps {
  open: boolean;
  onClose: () => void;
  mealMenus: readonly KioskMealMenuPreviewFragment[];
  date: string;
}

const AthleteNavigation = ({ open, onClose, mealMenus, date }: AthleteNavigationProps) => {
  const navigate = useNavigate();
  const mealMenuIds = mealMenus.map(mm => mm.id);
  const [searchText, setSearchText] = useState("");
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const [selectedAthleteId, setSelectedAthleteId] = useState<string | null>(null);

  const {
    data: orderModeData,
    loading: orderModeLoading,
    error: orderModeError,
    refetch: refetchOrderMode,
  } = useOrderKioskAthletesQuery({
    fetchPolicy: "network-only",
    variables: { mealMenuIds },
    pollInterval: 30000,
  });

  // catch query error and redirects kiosk starting component
  // url change is picked up by useParam hook in KioskPage component
  // does not throw errors if mealMenuIds.length === 0
  useEffect(() => {
    if (orderModeError !== undefined) {
      navigate(getNavOrgKitchenKioskDate(date), { replace: true });
    }
  }, [orderModeError, date]);

  const selectedAthlete = useMemo(
    () => orderModeData?.athletesForMealMenus.find(a => a.id === selectedAthleteId),
    [orderModeData, selectedAthleteId]
  );

  const [athleteOrderState, athleteOrderStateDispatch] = useReducer(athleteOrderStateReducer, null);

  const renderAthleteListItemDecoration = (athlete: OrderKioskAthletePreviewFragment) => {
    const hasOrder = athlete.kioskPreviewOrderInfo.reduce((acc, info) => acc + info.menuOrderCount, 0) > 0;
    if (hasOrder) {
      return (
        <ListItemIcon>
          <RestaurantIcon />
        </ListItemIcon>
      );
    }
    return null;
  };

  const handleSelectAthlete = async (athleteId: string) => {
    setSelectedAthleteId(athleteId);
    const { data } = await refetchOrderMode({ mealMenuIds });
    const menus = menusForAthlete(mealMenus, athleteId);
    const selectedAthlete = data?.athletesForMealMenus.find(a => a.id === athleteId);
    if (menus.length === 1 && menus[0] && selectedAthlete) {
      athleteOrderStateDispatch({ type: "EditMealMenu", payload: { selectedMealMenu: menus[0], selectedAthlete } });
    }
  };

  const handleCloseOrderForm = () => {
    setSelectedAthleteId(null);
    setSearchText("");
    setSelectedTeamId(null);
    athleteOrderStateDispatch({ type: "CloseOrderForm" });
  };

  return (
    <Kiosk open={open} onClose={onClose}>
      {!orderModeData || orderModeLoading ? (
        <LoadingBackdrop open={open} onClose={onClose} />
      ) : (
        <>
          <AthleteSelector
            setSelectedAthleteId={handleSelectAthlete}
            athletes={orderModeData.athletesForMealMenus}
            renderAthleteListItemDecoration={renderAthleteListItemDecoration}
            searchText={searchText}
            setSearchText={setSearchText}
            selectedTeamId={selectedTeamId}
            setSelectedTeamId={setSelectedTeamId}
            mealMenus={mealMenus}
          />
          {selectedAthlete &&
            (athleteOrderState ? (
              athleteOrderState.type === "selectNewOrEdit" ? (
                <SelectNewOrEditDialog
                  open={!!selectedAthleteId}
                  onClose={() => {
                    setSelectedAthleteId(null);
                    athleteOrderStateDispatch({ type: "CloseOrderForm" });
                  }}
                  athleteOrderState={athleteOrderState}
                  athleteOrderStateDispatch={athleteOrderStateDispatch}
                />
              ) : (
                <OrderForm
                  open={open}
                  athlete={selectedAthlete}
                  mealMenu={athleteOrderState.selectedMealMenu}
                  onClose={handleCloseOrderForm}
                  date={date}
                  athleteOrderState={athleteOrderState}
                  athleteOrderStateDispatch={athleteOrderStateDispatch}
                />
              )
            ) : (
              <MenuDialog
                open={selectedAthleteId !== null}
                onClose={() => setSelectedAthleteId(null)}
                menus={menusForAthlete(mealMenus, selectedAthlete.id)}
                onSelectMenu={mealMenu =>
                  athleteOrderStateDispatch({ type: "EditMealMenu", payload: { selectedMealMenu: mealMenu, selectedAthlete } })
                }
                kioskPreviewOrderInfo={selectedAthlete?.kioskPreviewOrderInfo}
              />
            ))}
        </>
      )}
    </Kiosk>
  );
};

export default AthleteNavigation;

const menusForAthlete = (mealMenus: readonly KioskMealMenuPreviewFragment[], athleteId: string) => {
  return mealMenus.filter(m => m.athletes.map(a => a.id).includes(athleteId));
};
