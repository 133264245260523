import {newId} from "@notemeal/shared-ui";

import {
  CreateExchangeSetInput,
  EditExchangeSetInput,
  ExchangeInput,
  ExchangeType,
  CreateExchangeInput,
  ExchangeServingListInput,
  FullServingAmountFragment,
} from "../../../types";
import { FullExchangeSetFragment, FullExchangeFragment } from "../../../types";
import { inputToNumber } from "@notemeal/shared-ui";
import { getExchangeTypeAnchoredMacro } from "@notemeal/shared-ui";

export interface ExchangeServingListState {
  id: string;
  name: string;
  servingAmounts: readonly FullServingAmountFragment[];
}

export interface ExchangeSetFormState {
  id?: string;
  name: string;
  exchanges: ExchangeState[];
  initialExchangeIds: string[];
  selectedExchangeId: string | null;
}

export interface ExchangeState extends ExchangeInput {
  id: string;
  choInput: string;
  proInput: string;
  fatInput: string;
  exchangeServingList: Omit<ExchangeServingListState, "id">;
  servingListChanged: boolean;
}

interface ChangeNameAction {
  type: "CHANGE_NAME";
  payload: string;
}

interface SelectExchangeByIdAction {
  type: "SELECT_EXCHANGE_BY_ID";
  payload: string;
}

interface AddExchangeByTypeAction {
  type: "ADD_EXCHANGE_BY_TYPE";
  payload: ExchangeType;
}

interface RemoveExchangeByIdAction {
  type: "REMOVE_EXCHANGE_BY_ID";
  payload: string;
}

interface EditExchangeNameAction {
  type: "EDIT_EXCHANGE_NAME";
  payload: {
    id: string;
    name: string;
  };
}

interface EditExchangeShortNameAction {
  type: "EDIT_EXCHANGE_SHORT_NAME";
  payload: {
    id: string;
    shortName: string;
  };
}

interface EditExchangeMacroAction {
  type: "EDIT_EXCHANGE_MACRO";
  payload: {
    id: string;
    macro: "cho" | "pro" | "fat";
    input: string;
  };
}

interface EditExchangeAddServingListAction {
  type: "EDIT_EXCHANGE_ADD_SERVING_LIST";
  payload: {
    id: string;
  };
}

interface EditExchangeMasterServingListAction {
  type: "EDIT_EXCHANGE_MASTER_SERVING_LIST_SERVING_AMOUNTS";
  payload: {
    id: string;
    servingAmounts: readonly FullServingAmountFragment[];
  };
}

interface EditExchangeServingListNameAction {
  type: "EDIT_EXCHANGE_SERVING_LIST_NAME";
  payload: {
    id: string;
    servingListId: string;
    name: string;
  };
}

interface EditExchangeServingListServingAmountsAction {
  type: "EDIT_EXCHANGE_SERVING_LIST_SERVING_AMOUNTS";
  payload: {
    id: string;
    servingListId: string;
    servingAmounts: readonly FullServingAmountFragment[];
  };
}

interface EditExchangeAutoscaleMasterServingListAction {
  type: "EDIT_EXCHANGE_AUTOSCALE_MASTER_SERVING_LIST";
  payload: {
    id: string;
  };
}

type ExchangeAction =
  | EditExchangeNameAction
  | EditExchangeShortNameAction
  | EditExchangeMacroAction
  | EditExchangeMasterServingListAction
  | EditExchangeServingListNameAction
  | EditExchangeServingListServingAmountsAction
  | EditExchangeAddServingListAction
  | EditExchangeAutoscaleMasterServingListAction;

export type ExchangeSetFormAction =
  | ChangeNameAction
  | AddExchangeByTypeAction
  | RemoveExchangeByIdAction
  | ExchangeAction
  | SelectExchangeByIdAction;

export const exchangeSetFormReducer = (state: ExchangeSetFormState, action: ExchangeSetFormAction): ExchangeSetFormState => {
  switch (action.type) {
    case "CHANGE_NAME":
      return { ...state, name: action.payload };
    case "SELECT_EXCHANGE_BY_ID":
      return { ...state, selectedExchangeId: action.payload };
    case "ADD_EXCHANGE_BY_TYPE":
      const newExchange = newExchangeState(action.payload);
      return {
        ...state,
        exchanges: state.exchanges.concat(newExchange),
        selectedExchangeId: newExchange.id,
      };
    case "REMOVE_EXCHANGE_BY_ID":
      return {
        ...state,
        exchanges: state.exchanges.filter(ex => ex.id !== action.payload),
        selectedExchangeId: state.selectedExchangeId === action.payload ? null : state.selectedExchangeId,
      };
    case "EDIT_EXCHANGE_NAME":
    case "EDIT_EXCHANGE_SHORT_NAME":
    case "EDIT_EXCHANGE_MACRO":
    case "EDIT_EXCHANGE_ADD_SERVING_LIST":
    case "EDIT_EXCHANGE_SERVING_LIST_NAME":
    case "EDIT_EXCHANGE_SERVING_LIST_SERVING_AMOUNTS":
    case "EDIT_EXCHANGE_MASTER_SERVING_LIST_SERVING_AMOUNTS":
    case "EDIT_EXCHANGE_AUTOSCALE_MASTER_SERVING_LIST":
      return {
        ...state,
        exchanges: state.exchanges.map(ex => exchangeReducer(ex, action)),
      };
  }
};

const exchangeReducer = (state: ExchangeState, action: ExchangeAction): ExchangeState => {
  if (state.id !== action.payload.id) {
    return state;
  }
  switch (action.type) {
    case "EDIT_EXCHANGE_NAME":
      return { ...state, name: action.payload.name };
    case "EDIT_EXCHANGE_SHORT_NAME":
      return { ...state, shortName: action.payload.shortName };
    case "EDIT_EXCHANGE_MACRO":
      const macroKey = action.payload.macro;
      const macroInputKey = `${macroKey}Input`;
      const macroNumber = inputToNumber(action.payload.input);
      return {
        ...state,
        [macroInputKey]: action.payload.input,
        [macroKey]: macroNumber !== null ? macroNumber : state[macroKey],
      };
    case "EDIT_EXCHANGE_MASTER_SERVING_LIST_SERVING_AMOUNTS":
      return {
        ...state,
        exchangeServingList: {
          ...state.exchangeServingList,
          servingAmounts: action.payload.servingAmounts,
        },
        servingListChanged: true,
      };
    case "EDIT_EXCHANGE_AUTOSCALE_MASTER_SERVING_LIST":
      return {
        ...state,
        exchangeServingList: {
          ...state.exchangeServingList,
          servingAmounts: getAutoscaledServingAmounts(state, state.exchangeServingList.servingAmounts),
        },
        servingListChanged: true,
      };
    default:
      return state;
  }
};

const getAutoscaledServingAmounts = (
  exchange: ExchangeState,
  servingAmounts: readonly FullServingAmountFragment[]
): readonly FullServingAmountFragment[] => {
  const { cho, pro, fat } = exchange;
  const EXCHANGE_KCAL_AUTOSCALE_PERCENT_CAP = 1.1;
  const CALORIE_CALC = cho * 4 + pro * 4 + fat * 9;

  const anchoredMacro = getExchangeTypeAnchoredMacro(exchange.type);
  if (!anchoredMacro) {
    return servingAmounts;
  }
  const exchangeMacroValue = exchange[anchoredMacro];
  return servingAmounts.map(sa => {
    const servingMacroValue = sa.serving.macros[anchoredMacro];

    const scaledMacroAmount = Math.max(0.25, Number((Math.round((exchangeMacroValue / servingMacroValue) * 2) / 2).toFixed(2)));
    // ^ Nearest 1/2 serving, min of 1/4

    const scaledKcalAmount = (CALORIE_CALC * EXCHANGE_KCAL_AUTOSCALE_PERCENT_CAP) / sa.serving.macros.kcal;

    const scaledAmount = scaledKcalAmount <= scaledMacroAmount ? scaledKcalAmount : scaledMacroAmount;

    // rounding to nearest tenth decimal
    return { ...sa, amount: Math.round(scaledAmount * 10) / 10 };
  });
};

export const capitalizeExchangeType = (type: ExchangeType) => `${type.slice(0, 1).toUpperCase()}${type.slice(1)}`;

// TODO: Could use default exchanges here? Would make "unwanted" types less of a burden
const newExchangeState = (type: ExchangeType): ExchangeState => ({
  id: newId(),
  cho: 0,
  choInput: "0",
  pro: 0,
  proInput: "0",
  fat: 0,
  fatInput: "0",
  type,
  name: capitalizeExchangeType(type),
  shortName: type.slice(0, 3).toUpperCase(),
  exchangeServingList: {
    name: `Default`,
    servingAmounts: [],
  },
  servingListChanged: false,
});

const exchangeToState = ({ exchangeServingList, ...exchange }: FullExchangeFragment): ExchangeState => ({
  ...exchange,
  choInput: String(exchange.cho),
  proInput: String(exchange.pro),
  fatInput: String(exchange.fat),
  servingListChanged: false,
  exchangeServingList: exchangeServingList,
});

// const exchangeServingListToState = (exchangeServingList: FullExchangeServingListFragment): ExchangeServingListState => ({
//   ...exchangeServingList, servingAmounts: exchangeServingList.servingAmounts.map(servingAmountWithMacros)
// });

export const newExchangeSetFormState = (): ExchangeSetFormState => ({
  name: "New Exchange Set",
  exchanges: [
    newExchangeState("protein"),
    newExchangeState("fat"),
    newExchangeState("starch"),
    newExchangeState("fruit"),
    newExchangeState("vegetable"),
    newExchangeState("dairy"),
  ],
  initialExchangeIds: [],
  selectedExchangeId: null,
});

export const exchangeSetToFormState = ({ id, name, exchanges }: FullExchangeSetFragment): ExchangeSetFormState => ({
  id,
  name,
  exchanges: exchanges.map(exchangeToState),
  initialExchangeIds: exchanges.map(ex => ex.id),
  selectedExchangeId: exchanges[0] ? exchanges[0].id : null,
});

const exchangeStateToInput = ({
  name,
  shortName,
  type,
  cho,
  pro,
  fat,
  exchangeServingList,
  servingListChanged,
}: ExchangeState): CreateExchangeInput => ({
  exchange: { name, shortName, type, cho, pro, fat },
  exchangeServingList: exchangeServingListToInput(exchangeServingList),
});

const exchangeServingListToInput = ({ name, servingAmounts }: Omit<ExchangeServingListState, "id">): ExchangeServingListInput => ({
  name,
  servingAmounts: servingAmounts.map(({ serving, amount, position }) => ({
    servingId: serving.id,
    amount,
    position,
  })),
});

export const exchangeSetFormToCreateInput = ({ name, exchanges }: ExchangeSetFormState): CreateExchangeSetInput => ({
  name,
  exchanges: exchanges.map(exchangeStateToInput),
});

export const exchangeSetFormToEditInput = ({
  id,
  name,
  exchanges,
  initialExchangeIds,
}: ExchangeSetFormState & { id: string }): EditExchangeSetInput => {
  const exchangeIds = exchanges.map(ex => ex.id);
  return {
    id,
    name,
    addExchanges: exchanges.filter(ex => !initialExchangeIds.includes(ex.id)).map(exchangeStateToInput),
    editExchanges: exchanges
      .filter(ex => initialExchangeIds.includes(ex.id))
      .map(ex => ({
        id: ex.id,
        exchange: exchangeStateToInput(ex).exchange,
        exchangeServingList: ex.servingListChanged ? exchangeServingListToInput(ex.exchangeServingList) : null,
      })),
    removeExchanges: initialExchangeIds.filter(eId => !exchangeIds.includes(eId)),
  };
};

const REQUIRED_UNIQUE_EXCHANGE_TYPES = 6;

const choExchanges: ExchangeType[] = ["dairy", "fruit", "starch", "vegetable"];
const CHO_MINIMUM_GRAMS = 4;

export const exchangeSetFormCantSaveTooltips = (state: ExchangeSetFormState): string[] => {
  const tooltips: string[] = [];
  let uniqueExchangeTypes: ExchangeType[] = [];
  let nonNumberMacros = false;
  let negativeMacros = false;
  let allZeroMacros = false;
  let emptyNames = false;
  let minimumCho = false;
  state.exchanges.forEach(({ name, shortName, type, cho, pro, fat, choInput, proInput, fatInput }) => {
    if (!uniqueExchangeTypes.includes(type)) {
      uniqueExchangeTypes.push(type);
    }
    nonNumberMacros =
      nonNumberMacros || inputToNumber(choInput) !== cho || inputToNumber(proInput) !== pro || inputToNumber(fatInput) !== fat;
    negativeMacros = negativeMacros || cho < 0 || pro < 0 || fat < 0;
    allZeroMacros = allZeroMacros || (cho === 0 && pro === 0 && fat === 0);
    emptyNames = emptyNames || !name || !shortName;
    if (choExchanges.includes(type) && cho < CHO_MINIMUM_GRAMS) {
      minimumCho = true;
    }
  });
  if (state.exchanges) {
    if (uniqueExchangeTypes.length !== REQUIRED_UNIQUE_EXCHANGE_TYPES) {
      tooltips.push("One exchange of each type is required");
    }
  }
  if (nonNumberMacros) {
    tooltips.push("Exchange macro values must be numbers");
  }
  if (negativeMacros) {
    tooltips.push("Exchange macro values must be positive");
  }
  if (allZeroMacros) {
    tooltips.push("Exchange macro values cannot all be zero");
  }
  if (emptyNames) {
    tooltips.push("Exchange cannot have empty name / short name");
  }
  if (minimumCho) {
    tooltips.push(`Carb-based exchanges (${choExchanges.join(", ")}) must each have at least 4g of Carbs`);
  }

  return tooltips;
};
