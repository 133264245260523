import { useApolloClient } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import React, { useEffect, useReducer, useState } from "react";
import { SectionHeader } from "../../componentLibrary/SectionHeader";
import TWItemizedTooltip from "../../componentLibrary/TWTooltip/TWItemizedTooltip";
import MealTypeMultiSelect from "../../components/Meal/TypeMultiSelect";
import MaxAmountInput from "../../components/MenuItem/OrderDetails/Input/MaxAmountInput";
import MenuItemAppearanceEditCards from "../../components/MenuItemAppearance/EditCards";
import MenuItemAppearanceGroupControlLabel from "../../components/MenuItemAppearance/GroupControlLabel";
import MenuItemAppearanceReorderDialog from "../../components/MenuItemAppearance/ReorderDialog";
import DiscardChangesDialog from "../../components/universal/DiscardChangesDialog";
import { useBrowserBackAndRefreshWarning } from "../../hooks/useBrowserBackAndRefreshWarning";
import { DiningStationTemplateModalState, diningStationTemplateReducer } from "./reducer";

interface DiningStationModalProps {
  open: boolean;
  type: "create" | "edit";
  onClose: () => void;
  initialDiningStationTemplate: DiningStationTemplateModalState;
  onSave: (diningStationTemplate: DiningStationTemplateModalState) => void;
  saving: boolean;
}

// TODO: use saving, validate form state before saving
const DiningStationModal = ({ initialDiningStationTemplate, type, open, onClose, onSave, saving }: DiningStationModalProps) => {
  const apolloClient = useApolloClient();
  const [state, dispatch] = useReducer(diningStationTemplateReducer, initialDiningStationTemplate);
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);
  const [reordering, setReordering] = useState(false);
  const { setBrowserBackAndRefreshWarningEnabled } = useBrowserBackAndRefreshWarning();

  const tooltipItems = state.defaultMaxAmount !== null && isNaN(state.defaultMaxAmount) ? ["Max per order must be a number"] : [];

  const handleSave = () => {
    // TODO: validate form state before saving
    if (tooltipItems.length) {
      return;
    }
    onSave(state);
  };

  const handleClose = () => {
    // handle accidental work loss on close button
    if (state.edited) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  React.useEffect(() => {
    setBrowserBackAndRefreshWarningEnabled(state.edited);
  }, [state.edited, setBrowserBackAndRefreshWarningEnabled]);

  const usedMenuItemIds = state.menuItemAppearances.map(mia => mia.menuItem.id);

  const toggleAllMenuItemsAvailableForOrder = (availableForOrder: boolean) => {
    dispatch({
      type: "AllMenuItemsAvailableForOrderAction",
      payload: { availableForOrder },
    });
  };

  const toggleAllMenuItemsAllowSpecialRequests = (allowSpecialRequests: boolean) => {
    dispatch({
      type: "AllMenuItemsAllowSpecialRequestsAction",
      payload: { allowSpecialRequests },
    });
  };

  useEffect(() => {
    apolloClient.cache.evict({
      fieldName: "menuItemCursorConnection",
      broadcast: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h3">{type === "create" ? "Create" : "Edit"} Dining Station Template</Typography>
        </Box>
        <TWItemizedTooltip title="Fix the following:" items={tooltipItems}>
          <Box sx={{ display: "flex" }}>
            {saving && <Loading progressSize="sm" />}
            <Button disabled={saving || tooltipItems.length !== 0} onClick={handleSave}>
              Save
            </Button>
          </Box>
        </TWItemizedTooltip>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", mx: 6 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <SectionHeader header="Details" />
          <Box sx={{ display: "flex", gap: 1 }}>
            <TextField
              sx={{ minWidth: "40%" }}
              label="Template Name"
              autoFocus={state.name === ""}
              value={state.name}
              onChange={e =>
                dispatch({
                  type: "DiningStationChangeNameAction",
                  payload: {
                    name: e.target.value,
                  },
                })
              }
            />
            <MealTypeMultiSelect
              value={state.mealTypes}
              sx={{ minWidth: "30%" }}
              onChange={mealTypes =>
                dispatch({
                  type: "DiningStationChangeMealTypesAction",
                  payload: {
                    mealTypes,
                  },
                })
              }
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <SectionHeader
            header="Default Menu Items"
            subcopy="These items will be added to a menu's dining station when this template is chosen."
          />
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <MaxAmountInput
              defaultLabel
              value={state.defaultMaxAmount}
              onChange={(newMax: number | null) =>
                dispatch({
                  type: "DiningStationChangeDefaultMaxAmountAction",
                  payload: {
                    defaultMaxAmount: newMax,
                  },
                })
              }
            />
            <MenuItemAppearanceGroupControlLabel
              field="availableForOrder"
              text="Available For Order"
              menuItemAppearances={state.menuItemAppearances}
              onChange={toggleAllMenuItemsAvailableForOrder}
            />
            <MenuItemAppearanceGroupControlLabel
              field="allowSpecialRequests"
              text="Allow Special Requests"
              menuItemAppearances={state.menuItemAppearances}
              disabled={!state.menuItemAppearances.every(mia => mia.availableForOrder)}
              onChange={toggleAllMenuItemsAllowSpecialRequests}
            />
            <Tooltip title="Re-order menu items">
              <IconButton onClick={() => setReordering(true)} size="large">
                <LowPriorityIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <MenuItemAppearanceEditCards
          usedMenuItemIds={usedMenuItemIds}
          variant="DiningStationTemplate"
          state={state.menuItemAppearances}
          dispatch={dispatch}
        />
      </DialogContent>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}
      {reordering && (
        <MenuItemAppearanceReorderDialog
          open={reordering}
          onClose={() => setReordering(false)}
          menuItemAppearances={state.menuItemAppearances}
          onDone={menuItemAppearances =>
            dispatch({
              type: "ReorderMenuItemsAction",
              payload: { menuItemAppearances },
            })
          }
        />
      )}
    </Dialog>
  );
};

export default DiningStationModal;
