import {
  FullServingAmountFragment,
  FullServingAmountGroupFragment,
  MealOptionSuggestionCursorConnectionDocument,
  MealOptionSuggestionCursorConnectionQuery,
  MealOptionSuggestionCursorConnectionQueryVariables,
  MealType,
} from "../../../types";

import { useApolloClient } from "@apollo/client";
import { useAthleteFoodPreferenceContext } from "@notemeal/shared-ui";
import { useLocaleContext } from "@notemeal/shared-ui";
import { useMealPlanFoodPreferenceContext } from "@notemeal/shared-ui";
import { Macros } from "@notemeal/shared-utils-macro-protocol";
import { useCallback, useEffect, useRef, useState } from "react";

const ERROR_PERCENTAGES = [10, 20, 30];

interface useSuggestionsArgs {
  macros: Macros;
  mealType: MealType;
  skip: boolean;
}

interface useSuggestionsPayload {
  suggestedServingAmounts: readonly FullServingAmountFragment[] | null;
  onPrevious: () => void;
  onNext: () => void;
  isDone: boolean;
}

export const useSuggestions = ({ macros, mealType, skip }: useSuggestionsArgs): useSuggestionsPayload => {
  const apolloClient = useApolloClient();
  const { locale } = useLocaleContext();
  const [errorPercentIndex, setErrorPercentIndex] = useState(0);
  const [cursor, setCursor] = useState<string | null>(null);
  const hasStarted = useRef(false);
  const isDone = errorPercentIndex === ERROR_PERCENTAGES.length && cursor === null;

  const [servingAmountGroups, setServingAmountGroups] = useState<readonly FullServingAmountGroupFragment[]>([]);
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
  const { dislikedFoods, dislikedFoodGroups } = useAthleteFoodPreferenceContext();
  const { avoidedFoodGroups, avoidedFoods } = useMealPlanFoodPreferenceContext();

  const fetchMoreResults = useCallback(async () => {
    if (isDone) {
      return;
    }

    const { data } = await apolloClient.query<
      MealOptionSuggestionCursorConnectionQuery,
      MealOptionSuggestionCursorConnectionQueryVariables
    >({
      query: MealOptionSuggestionCursorConnectionDocument,
      variables: {
        macros: {
          cho: macros.cho,
          pro: macros.pro,
          fat: macros.fat,
        },
        mealType,
        maxErrorPercent: ERROR_PERCENTAGES[errorPercentIndex],
        minErrorPercent: ERROR_PERCENTAGES[errorPercentIndex - 1],
        avoidFoodGroupIds: [...dislikedFoodGroups.map(fg => fg.id), ...avoidedFoodGroups.map(fg => fg.id)],
        avoidFoodIds: [...dislikedFoods.map(f => f.id), ...avoidedFoods.map(f => f.id)],
        pagination: {
          cursor,
          limit: null,
        },
        localeCode: locale,
      },
      fetchPolicy: "network-only",
    });

    if (data) {
      const newServingAmountGroups = data.mealOptionSuggestionCursorConnection.edges;

      setServingAmountGroups(servingAmountGroups => [...servingAmountGroups, ...newServingAmountGroups]);
      if (data.mealOptionSuggestionCursorConnection.pageInfo.hasNextPage) {
        setCursor(data.mealOptionSuggestionCursorConnection.pageInfo.endCursor);
      } else {
        setCursor(null);
        setErrorPercentIndex(errorPercentIndex + 1);
      }
    }
  }, [apolloClient, cursor, errorPercentIndex, isDone]);

  // Fetches more results when out of results in the current error range
  // Note: this useEffect must be before the next useEffect so that hasStarted.current is not set to true
  //   before this runs for the first time.
  useEffect(() => {
    if (hasStarted.current && currentSuggestionIndex === servingAmountGroups.length && !isDone) {
      fetchMoreResults();
    }
  }, [currentSuggestionIndex, errorPercentIndex, cursor]);

  // Makes initial request
  useEffect(() => {
    if (!hasStarted.current && !skip) {
      fetchMoreResults();
      hasStarted.current = true;
    }
  }, [skip, fetchMoreResults]);

  const onNext = () => {
    const nextSuggestionIndex = Math.min(servingAmountGroups.length, currentSuggestionIndex + 1);
    setCurrentSuggestionIndex(nextSuggestionIndex);
  };

  const onPrevious = () => {
    setCurrentSuggestionIndex(Math.max(0, currentSuggestionIndex - 1));
  };

  const currentSuggestion = servingAmountGroups[currentSuggestionIndex];

  return {
    suggestedServingAmounts: currentSuggestion ? currentSuggestion.servingAmounts : null,
    onNext,
    onPrevious,
    isDone,
  };
};
