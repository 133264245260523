import { sortByKey } from "@notemeal/utils-sort";
import { MenuBuilderMealRowType, MenuBuilderType } from "./MenuBuilderMealSchema";
import { UseFormReturn } from "react-hook-form";
import { format, parseISO } from "date-fns";
import { AthletesBirthdaysFragment, Team, Athlete } from "apps/web/src/types";

export const getUniqueDiningStationNames = (form: UseFormReturn<MenuBuilderType>) => {
  const diningStationNames = sortByKey(
    form
      .getValues("weeks")
      .flatMap(week => week.meals)
      .flatMap(meal => meal.rows)
      // only autocomplete dining stations that are not associated with a template
      .filter(row => row.diningStationName.length > 0 && row.includeInAutoComplete),
    "diningStationName"
  ).map(row => row.diningStationName);
  return Array.from(new Set(diningStationNames));
};

export const findAthleteBirthdaysForDayByTeam = (athleteBirthdays: readonly AthletesBirthdaysFragment[], birthDayFormat: string) => {
  return athleteBirthdays.filter(athlete => {
    const formattedBirthDate = format(parseISO(String(athlete.birthDate)), "MM-dd");
    return formattedBirthDate === birthDayFormat;
  });
};

export const groupAthletesByTeam = (athletes: AthletesBirthdaysFragment[]) => {
  const teamsMap = new Map<
    string,
    Pick<Team, "id" | "name"> & { athletes: Array<Pick<Athlete, "id" | "birthDate" | "firstName" | "lastName">> }
  >();

  athletes.forEach(athlete => {
    const { id, name } = athlete.team;

    if (!teamsMap.has(id)) {
      teamsMap.set(id, {
        id,
        name,
        athletes: [],
      });
    }

    const teamGroup = teamsMap.get(id);
    if (teamGroup) {
      teamGroup.athletes.push({
        id: athlete.id,
        birthDate: athlete.birthDate,
        firstName: athlete.firstName,
        lastName: athlete.lastName,
      });
    }
  });

  return Array.from(teamsMap.values());
};

export const updatePositionsAfterDelete = (rows: MenuBuilderMealRowType[], deleteIndex: number) => {
  rows.splice(deleteIndex, 1);
  return rows.map((item, index) => ({
    ...item,
    position: index + 1,
  }));
};
