import { Grid, Paper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import { useParams } from "react-router-dom-v5-compat";
import { useAthleteSummaryQuery } from "../../../../types";
import AccountManagement from "../../../../views/Athlete/Summary/AccountManagement";
import Compliance from "../../../../views/Athlete/Summary/Compliance";
import CurrentGoal from "../../../../views/Athlete/Summary/CurrentGoal";
import DisplaySettings from "../../../../views/Athlete/Summary/DisplaySettings";
import FoodPreferences from "../../../../views/Athlete/Summary/FoodPreferences";
import { AthleteSummaryGeneral } from "../../../../views/Athlete/Summary/General";
import Notifications from "../../../../views/Athlete/Summary/Notifications";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      justifyContent: "space-between",
      boxSizing: "border-box",
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
    },
    container: {
      display: "flex",
      flexFlow: "row wrap",
      height: "100%",
      padding: theme.spacing(1),
    },
    summaryCard: {},
    complianceCard: {},
    goalCard: {},
    mealPlanSettingsCard: {},
    accountManagementCard: {},
    notificationsCard: {},
    foodPreferencesCard: {},
  })
);

export const AthleteSummaryPage = () => {
  const { athleteId: maybeAthleteId } = useParams();
  const athleteId = maybeAthleteId || "";
  const classes = useStyles();
  const getAthleteSummaryVars = { id: athleteId };
  const { data, loading } = useAthleteSummaryQuery({
    variables: getAthleteSummaryVars,
  });

  if (loading || !data) {
    return <Loading progressSize="lg" />;
  }

  const athlete = data.athlete;
  const teams = data.teams;

  return (
    <Grid
      className={classes.container}
      spacing={2}
      container>
      <Grid
        md={4}
        item
        className={classes.summaryCard}>
        <Paper className={classes.paper}>
          <AthleteSummaryGeneral athlete={athlete} teams={[...teams]} />
        </Paper>
      </Grid>
      <Grid
        md={4}
        item
        className={classes.complianceCard}>
        <Paper className={classes.paper}>
          <Compliance athlete={athlete} />
        </Paper>
      </Grid>
      <Grid
        md={4}
        item
        className={classes.goalCard}>
        <Paper className={classes.paper}>
          <CurrentGoal athleteId={athleteId} goal={athlete.currentGoal} />
        </Paper>
      </Grid>
      <Grid
        md={4}
        item
        className={classes.mealPlanSettingsCard}>
        <Paper className={classes.paper}>
          <DisplaySettings athlete={athlete} />
        </Paper>
      </Grid>
      <Grid
        md={4}
        item
        className={classes.accountManagementCard}>
        <Paper className={classes.paper}>
          <AccountManagement athlete={athlete} />
        </Paper>
      </Grid>
      <Grid
        md={4}
        item
        className={classes.notificationsCard}>
        <Paper className={classes.paper}>
          {/* {athlete.profileId ? ( */}
          <Notifications
            athleteId={athlete.id}
            athleteNotificationSettings={athlete.foodLogNotificationSettings}
            teamNotificationSettings={athlete.team.foodLogNotificationSettings}
          />
          {/* ) : (
            <Typography color="textSecondary" variant="h3">
              Mobile account not active
            </Typography>
          )} */}
        </Paper>
      </Grid>
      <Grid
        md={12}
        item
        className={classes.foodPreferencesCard}>
        <Paper className={classes.paper}>
          <FoodPreferences athlete={athlete} />
        </Paper>
      </Grid>
    </Grid>
  );
};
