import { Box, Button } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { useState } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { RestaurantFoodDashboard } from "../../../../components/Food/Restaurant/RestaurantFoodDashboard";
import { useRestaurantQuery } from "../../../../types";
import LocationTabContent from "../../../../views/Staff/Restaurant/Locations";
import RestaurantMenuDialog from "../../../../views/Staff/Restaurant/MenuBuilder/RestaurantMenuDialog";
import { useCreateEditDeleteMenu } from "../../../../views/Staff/Restaurant/MenuBuilder/useCreateEditDeleteMenu";
import { RestaurantMenuDialogState } from "../../../../views/Staff/Restaurant/types";
import { getRestaurantMenuDialogStateFromRestaurant } from "../../../../views/Staff/Restaurant/utils";

export const RestaurantPage = () => {
  const { restaurantId } = useParams();
  const id = restaurantId || "";
  const [menuDialogState, setMenuDialogState] = useState<RestaurantMenuDialogState | null>(null);

  const LOCATIONS = "Locations";
  const FOODS = "Foods";
  const [selected, setSelected] = useState(LOCATIONS);

  const { data: data_restaurant, loading: loading_restaurant } = useRestaurantQuery({
    variables: { id },
  });

  const { saving, handleCreate: handleCreateMenu, handleEdit: handleEditMenu } = useCreateEditDeleteMenu();

  const handleCloseMenu = () => {
    setMenuDialogState(null);
  };

  const onSaveMenu = async (state: RestaurantMenuDialogState) => {
    if (restaurant.menu && state.__typename === "Edit") {
      await handleEditMenu({
        restaurantId: restaurant.id,
        state,
        initialSections: restaurant.menu.sections,
      });
      handleCloseMenu();
    } else if (!restaurant.menu && state.__typename === "New") {
      await handleCreateMenu({ state, restaurantId: restaurant.id });
      handleCloseMenu();
    } else {
      console.error("Invalid __typename for RestaurantMenuDialogState");
    }
  };

  if (loading_restaurant || !data_restaurant) {
    return <Loading progressSize="lg" />;
  }
  const restaurant = data_restaurant.restaurant;

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <TWTabGroup tabs={[LOCATIONS, FOODS]} onSelected={selected => setSelected(selected)} />
        <Button onClick={() => setMenuDialogState(getRestaurantMenuDialogStateFromRestaurant(restaurant))} sx={{ ml: 3 }}>
          {!!restaurant.menu ? "Edit Menu" : "Create Menu"}
        </Button>
      </Box>
      {selected === LOCATIONS && <LocationTabContent restaurant={restaurant} />}
      {selected === FOODS && <RestaurantFoodDashboard restaurantId={restaurant.id} />}
      {menuDialogState !== null && (
        <RestaurantMenuDialog
          open={menuDialogState !== null}
          onClose={handleCloseMenu}
          initialState={menuDialogState}
          onSave={onSaveMenu}
          saving={saving}
          nutritionixRestaurantId={restaurant.nutritionixId ?? null}
          restaurantName={restaurant.name}
          restaurantId={restaurant.id}
        />
      )}
    </Box>
  );
};
