import { ApolloCache } from "@apollo/client";
import { TimelineMealModalIntent } from "@notemeal/shared-ui";
import { EditTimelineMealDetailsInput, TimelineMealFragment } from "apps/web/src/types";
import { TimelineMealForModal } from "../utils";
import TimelineMealModal from "./Modal";
import { TimelineMealModalContextProvider } from "./TimelineMealModalContext";

interface TimelineMealModalLoaderProps {
  open: boolean;
  onClose: () => void;
  timelineMeal: TimelineMealForModal;
  intent: TimelineMealModalIntent | null;
  onChangeDetails: (details: Omit<EditTimelineMealDetailsInput, "timelineMealId">) => void;
  athleteId: string;
  date: string;
  updateMealInCacheFn: (timelineMeal: TimelineMealFragment, cache: ApolloCache<any>) => void;
}

const TimelineMealModalLoader = ({
  open,
  onClose,
  timelineMeal,
  intent,
  athleteId,
  onChangeDetails,
  date,
  updateMealInCacheFn,
}: TimelineMealModalLoaderProps) => {
  return (
    <TimelineMealModalContextProvider timelineMeal={timelineMeal} intent={intent}>
      <TimelineMealModal
        open={open}
        onClose={onClose}
        timelineMeal={timelineMeal}
        athleteId={athleteId}
        onChangeDetails={onChangeDetails}
        date={date}
        updateMealInCacheFn={updateMealInCacheFn}
      />
    </TimelineMealModalContextProvider>
  );
};

export default TimelineMealModalLoader;
