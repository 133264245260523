import { useProgressViews } from "@notemeal/shared-ui";
import { Macros } from "@notemeal/shared-utils-macro-protocol";
import { memo } from "react";
import { FullServingAmountFragment, MealType } from "../../../types";
import { EditMealOption, MealOptionPreview } from "./EditMealOption";
import { MealOptionMacrosProgress } from "./MealOptionMacrosProgress";

interface EditMacroMealOptionProps {
  mealTemplateId: string;
  mealOption: MealOptionPreview;
  selected: boolean;
  isNew: boolean;
  mealType: MealType;
  targetMacros: Macros;
}

export const EditMacroMealOption = memo(
  ({ mealTemplateId, mealOption, targetMacros, selected, isNew, mealType }: EditMacroMealOptionProps) => {
    const { mode } = useProgressViews({
      macroProgressViews: "all",
      hasTargets: true,
    });

    const renderTotals = (servingAmounts: readonly FullServingAmountFragment[]) => {
      return <MealOptionMacrosProgress
        servingAmounts={servingAmounts}
        targetMacros={targetMacros}
        mode={mode} />;
    };

    return (
      <EditMealOption
        renderTotals={renderTotals}
        mealType={mealType}
        targetMacros={targetMacros}
        mealTemplateId={mealTemplateId}
        mealTemplateTypename="MacroMealTemplate"
        mealOption={mealOption}
        selected={selected}
        isNew={isNew}
      />
    );
  }
);
