import { inputToNumber } from "@notemeal/shared-ui";
import { AnthropometryAction, AnthropometryTemplateState } from "./anthropometryReducer";
import { KG_PER_LB, measurementConversionToMetric } from "@notemeal/shared-utils-macro-protocol";

export const getDefaultAnthropometryTemplate = (isMetric: boolean): AnthropometryTemplateState => ({
  __typename: "Template",
  weight: isMetric ? 100 : 200 * KG_PER_LB,
  weightInput: isMetric ? "100" : "200",
  height: null,
  heightInput: "",
  percentBodyFat: null,
  percentBodyFatInput: "",
  sampleAge: 20,
  sampleAgeInput: "20",
  sampleSex: "male",
});

export const anthropometryTemplateReducer = (
  state: AnthropometryTemplateState,
  action: AnthropometryAction
): AnthropometryTemplateState => {
  switch (action.type) {
    case "EDIT_HEIGHT":
      let thisHeight = inputToNumber(action.payload);

      if (action.isMetricLocale !== undefined && action.isMetricLocale === false) {
        thisHeight = measurementConversionToMetric(false, thisHeight, "length");
      }
      return {
        ...state,
        heightInput: action.payload,
        height: thisHeight,
      };
    case "EDIT_PERCENT_BODY_FAT":
      return {
        ...state,
        percentBodyFatInput: action.payload,
        percentBodyFat: inputToNumber(action.payload),
      };
    case "EDIT_WEIGHT":
      let thisWeight = inputToNumber(action.payload);

      if (action.isMetricLocale !== undefined && action.isMetricLocale === false) {
        thisWeight = measurementConversionToMetric(false, thisWeight, "weight");
      }
      return {
        ...state,
        weightInput: action.payload,
        weight: thisWeight ?? state.weight,
      };
    case "EDIT_SAMPLE_SEX":
      return {
        ...state,
        sampleSex: action.payload,
      };
    case "EDIT_SAMPLE_AGE":
      return {
        ...state,
        sampleAgeInput: action.payload,
        sampleAge: inputToNumber(action.payload),
      };
    default:
      return state;
  }
};
