import { useHasFeature } from "@notemeal/shared-ui";
import { RecipeMoreInfo } from "apps/web/src/pages/Auth/Org/FoodManagement/RecipesPage";
import { ORGANIZATION_RECIPES } from "apps/web/src/pages/Auth/Org/FoodManagement/Utils";
import {
    MealType,
    OrgGroup,
    RecipeOwnerPreviewFragment,
    RecipeSortDirection,
    ScoringSystem,
    TableRecipeFragment,
    useMyScoringSystemQuery,
    useResourceSharingOrgGroupsQuery,
} from "apps/web/src/types";
import { PaginationHooks } from "apps/web/src/utils/pagination";
import React, { createContext, useContext, useState } from "react";

type RecipesPagePassthroughProps = {
  paginationHooks: PaginationHooks;
  onCreateRecipe: () => void;
  resetTable: () => void;
  handleEditIsShared: (id: string, isShared: boolean) => void;
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  onEditRecipe: (row: TableRecipeFragment) => void;
  onDuplicate: (id: string) => void;
  deleteableRecipe: TableRecipeFragment | null;
  setDeleteableRecipe: React.Dispatch<React.SetStateAction<TableRecipeFragment | null>>;
  printRecipeId: string | null;
  setPrintRecipeId: React.Dispatch<React.SetStateAction<string | null>>;
  moveToOrgGroupRecipe: TableRecipeFragment | null;
  setMoveToOrgGroupRecipe: React.Dispatch<React.SetStateAction<TableRecipeFragment | null>>;
};

type RecipesPageContextValue = RecipesPagePassthroughProps & {
  pageDepsLoading: boolean;
  hasOrgGroupResourceSharing: boolean;
  recipeSharingOrgGroups: readonly Pick<OrgGroup, "id" | "name">[];
  scoringSystem: ScoringSystem | null;
  moreInfo: RecipeMoreInfo | null;
  setMoreInfo: React.Dispatch<React.SetStateAction<RecipeMoreInfo | null>>;
  sortDirection: RecipeSortDirection;
  setSortDirection: React.Dispatch<React.SetStateAction<RecipeSortDirection>>;
  createdById: string | null;
  setCreatedById: React.Dispatch<React.SetStateAction<string | null>>;
  selectedMealTypes: MealType[];
  setSelectedMealTypes: React.Dispatch<React.SetStateAction<MealType[]>>;
  totalRows: number;
  setTotalRows: React.Dispatch<React.SetStateAction<number>>;
  rowsLoading: boolean;
  setRowsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOwners: RecipeOwnerPreviewFragment[];
  setSelectedOwners: React.Dispatch<React.SetStateAction<RecipeOwnerPreviewFragment[]>>;
};

const RecipesPageContext = createContext<RecipesPageContextValue>({
  // passthrough props
  paginationHooks: {} as PaginationHooks,
  onCreateRecipe: () => {},
  resetTable: () => {},
  handleEditIsShared: () => {},
  onEditRecipe: () => {},
  onDuplicate: () => {},
  deleteableRecipe: null,
  setDeleteableRecipe: () => {},
  printRecipeId: null,
  setPrintRecipeId: () => {},
  moveToOrgGroupRecipe: null,
  setMoveToOrgGroupRecipe: () => {},

  // state
  pageDepsLoading: true,
  hasOrgGroupResourceSharing: false,
  recipeSharingOrgGroups: [],
  scoringSystem: null,
  tab: ORGANIZATION_RECIPES,
  setTab: () => {},
  moreInfo: null,
  setMoreInfo: () => {},
  sortDirection: "DESC",
  setSortDirection: () => {},
  createdById: null,
  setCreatedById: () => {},
  selectedMealTypes: [],
  setSelectedMealTypes: () => {},
  totalRows: 0,
  setTotalRows: () => {},
  rowsLoading: true,
  setRowsLoading: () => {},
  selectedOwners: [],
  setSelectedOwners: () => {},
});

export const RecipesPageProvider: React.FC<RecipesPagePassthroughProps & { children: React.ReactNode }> = ({
  children,
  paginationHooks,
  resetTable,
  onCreateRecipe,
  handleEditIsShared,
  tab,
  setTab,
  onEditRecipe,
  onDuplicate,
  deleteableRecipe,
  setDeleteableRecipe,
  printRecipeId,
  setPrintRecipeId,
  moveToOrgGroupRecipe,
  setMoveToOrgGroupRecipe,
}) => {
  const { data: resourceSharingData, loading: resourceSharingLoading } = useResourceSharingOrgGroupsQuery();
  const recipeSharingOrgGroups = resourceSharingData?.resourceSharingOrgGroups ?? [];
  const orgGroupRecipesEnabled = useHasFeature("orgGroupRecipes");
  const hasOrgGroupResourceSharing = orgGroupRecipesEnabled && recipeSharingOrgGroups.length > 0;

  const { data: scoringSystemData, loading: scoringSystemLoading } = useMyScoringSystemQuery();

  const [moreInfo, setMoreInfo] = useState<RecipeMoreInfo | null>(null);
  const [sortDirection, setSortDirection] = useState<RecipeSortDirection>("DESC");
  const [createdById, setCreatedById] = useState<string | null>(null);
  const [selectedMealTypes, setSelectedMealTypes] = useState<MealType[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsLoading, setRowsLoading] = useState(true);
  const [selectedOwners, setSelectedOwners] = useState<RecipeOwnerPreviewFragment[]>([]);

  return (
    <RecipesPageContext.Provider
      value={{
        // passthrough props to remove prop drilling
        paginationHooks,
        resetTable,
        onCreateRecipe,
        handleEditIsShared,
        onEditRecipe,
        onDuplicate,
        deleteableRecipe,
        setDeleteableRecipe,
        printRecipeId,
        setPrintRecipeId,
        moveToOrgGroupRecipe,
        setMoveToOrgGroupRecipe,

        // state
        pageDepsLoading: resourceSharingLoading || scoringSystemLoading,
        hasOrgGroupResourceSharing,
        recipeSharingOrgGroups,
        scoringSystem: scoringSystemData?.myScoringSystem ?? null,
        tab,
        setTab,
        moreInfo,
        setMoreInfo,
        sortDirection,
        setSortDirection,
        createdById,
        setCreatedById,
        selectedMealTypes,
        setSelectedMealTypes,
        totalRows,
        setTotalRows,
        rowsLoading,
        setRowsLoading,
        selectedOwners,
        setSelectedOwners,
      }}
    >
      {children}
    </RecipesPageContext.Provider>
  );
};

export const useRecipesPage = () => {
  const state = useContext(RecipesPageContext);
  return state;
};
