import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

import { getPercentileLabel, getPercentileBand, applyFillToCell, Aggregations } from "../utils";
import { AnthropometryEntryTableProps } from "../Table";
import { exportColumns, Rank } from "../types";
import { round } from "@notemeal/shared-ui";
import { addRowToWorksheet } from "./utils";

interface FooterRowCol {
  metric: string;
  avg: keyof Aggregations;
  stdev: keyof Aggregations;
}

export const exportToExcel = async ({
  anthropometryEntries,
  mostRecentAnthropometryEntries,
  teamAggregations,
  filteredAggregations,
  exportFilename,
  theme,
  highlight,
}: Omit<AnthropometryEntryTableProps, "rows">) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();

  worksheet.views = [{ state: "frozen", xSplit: undefined, ySplit: 1 }];
  worksheet.columns = exportColumns;
  worksheet.getRow(1).alignment = { horizontal: "center", vertical: "middle" };

  const rowsForExport = anthropometryEntries.flatMap(anthro => {
    const athleteIdx = mostRecentAnthropometryEntries.findIndex(ath => ath.id === anthro.parentAthleteId);
    if (athleteIdx === -1) {
      return [];
    }
    const athlete = mostRecentAnthropometryEntries[athleteIdx];

    let rank: Rank;
    const isMostRecent =
      mostRecentAnthropometryEntries.find(mostRec => mostRec.anthropometryEntryId === anthro.anthropometryEntryId) !== undefined;
    if (!isMostRecent) {
      rank = "";
    } else {
      const percentile = getPercentileBand(mostRecentAnthropometryEntries, anthro, "percentBodyFat");
      if (percentile === null) {
        rank = "";
      } else {
        rank =
          getPercentileLabel({
            percentile,
            positiveIsGood: false,
            classes: {},
          }) || "";
      }
    }

    const { firstName, lastName } = athlete;
    const position = athlete.position || "";
    const {
      id,
      BMI,
      height,
      anthropometryEntryId,
      datetime,
      weight,
      weightChange,
      percentBodyFat,
      percentBodyFatChange,
      leanBodyMass,
      leanBodyMassChange,
      bodyFatMass,
      bodyFatMassChange,
      comment,
      boneMineralDensityZScore,
      type,
      boneMineralContent,
      dryLeanMass,
      dryLeanMassChange,
      visceralFatArea,
      visceralFatAreaChange,
      trunkFat,
      trunkFatChange,
      skeletalMuscleMass,
      skeletalMuscleMassChange,
    } = anthro;
    const row = {
      id,
      anthropometryEntryId,
      rank,
      datetime,
      height,
      BMI,
      weight,
      weightChange,
      percentBodyFat,
      percentBodyFatChange,
      bodyFatMass,
      bodyFatMassChange,
      leanBodyMass,
      leanBodyMassChange,
      firstName,
      lastName,
      position,
      comment,
      type,
      boneMineralDensityZScore,
      boneMineralContent,
      dryLeanMass,
      dryLeanMassChange,
      visceralFatArea,
      visceralFatAreaChange,
      trunkFat,
      trunkFatChange,
      skeletalMuscleMass,
      skeletalMuscleMassChange,
      //Not used in export
      athleteIdx,
      isMostRecent,
    };
    return [row];
  });

  // Preserve original sort
  let makeGrey = false;
  rowsForExport.forEach((wholeRow, i) => {
    const { firstName, lastName, position, ...row } = wholeRow;
    // alternate colors by athlete
    if (rowsForExport[i - 1]?.id !== row.id) {
      makeGrey = !makeGrey;
    }
    addRowToWorksheet({
      theme,
      worksheet,
      row: {
        ...row,
        firstName: firstName || "",
        lastName: lastName || "",
        position,
        makeGrey,
      },
      filteredAggregations,
      highlight,
    });
  });

  const aggregationCols: FooterRowCol[] = [
    { metric: "bodyFatMass", avg: "bodyFatMassAvg", stdev: "bodyFatMassStdev" },
    {
      metric: "bodyFatMassChange",
      avg: "bodyFatMassChangeAvg",
      stdev: "bodyFatMassChangeStdev",
    },
    {
      metric: "leanBodyMassChange",
      avg: "leanBodyMassChangeAvg",
      stdev: "leanBodyMassChangeStdev",
    },
    {
      metric: "leanBodyMass",
      avg: "leanBodyMassAvg",
      stdev: "leanBodyMassStdev",
    },
    {
      metric: "leanBodyMassChange",
      avg: "leanBodyMassChangeAvg",
      stdev: "leanBodyMassChangeStdev",
    },
    {
      metric: "percentBodyFat",
      avg: "percentBodyFatAvg",
      stdev: "percentBodyFatStdev",
    },
    {
      metric: "percentBodyFatChange",
      avg: "percentBodyFatChangeAvg",
      stdev: "percentBodyFatChangeStdev",
    },
    { metric: "weight", avg: "weightAvg", stdev: "weightStdev" },
    {
      metric: "weightChange",
      avg: "weightChangeAvg",
      stdev: "weightChangeStdev",
    },
    {
      metric: "trunkFat",
      avg: "trunkFatAvg",
      stdev: "trunkFatStdev",
    },
    {
      metric: "trunkFatChange",
      avg: "trunkFatChangeAvg",
      stdev: "trunkFatChangeStdev",
    },
    {
      metric: "visceralFatArea",
      avg: "visceralFatAreaAvg",
      stdev: "visceralFatAreaStdev",
    },
    {
      metric: "visceralFatAreaChange",
      avg: "visceralFatAreaChangeAvg",
      stdev: "visceralFatAreaChangeStdev",
    },

    {
      metric: "skeletalMuscleMass",
      avg: "skeletalMuscleMassAvg",
      stdev: "skeletalMuscleMassStdev",
    },
    {
      metric: "skeletalMuscleMassChange",
      avg: "skeletalMuscleMassChangeAvg",
      stdev: "skeletalMuscleMassChangeStdev",
    },
    {
      metric: "dryLeanMass",
      avg: "dryLeanMassAvg",
      stdev: "dryLeanMassStdev",
    },
    {
      metric: "dryLeanMassChange",
      avg: "dryLeanMassChangeAvg",
      stdev: "dryLeanMassChangeStdev",
    },
    { metric: "weight", avg: "weightAvg", stdev: "weightStdev" },
    {
      metric: "weightChange",
      avg: "weightChangeAvg",
      stdev: "weightChangeStdev",
    },
  ];
  worksheet.addRow({});
  // Hack: we uwe 'datetime' as the "title" of the aggregatin (i.e. 'Avg:')
  const avgRowObj = aggregationCols.reduce(
    (row, { metric, avg }) => ({
      ...row,
      [metric]: round(filteredAggregations[avg]),
    }),
    { datetime: "Avg:" }
  );
  const avgRow = worksheet.addRow(avgRowObj);
  const stdRowObj = aggregationCols.reduce(
    (row, { metric, stdev }) => ({
      ...row,
      [metric]: round(filteredAggregations[stdev]),
    }),
    { datetime: "Std:" }
  );
  const stdRow = worksheet.addRow(stdRowObj);

  const teamAggRowObj = {
    datetime: "Team Avg:",
    leanBodyMassChange: round(teamAggregations.leanBodyMassChangeAvg),
    percentBodyFatChange: round(teamAggregations.percentBodyFatChangeAvg),
  };
  const teamRow = worksheet.addRow(teamAggRowObj);

  const aggRows = [avgRow, stdRow, teamRow];
  aggRows.forEach(row => {
    row.getCell("datetime").style = { font: { bold: true } };
    row.eachCell({ includeEmpty: true }, cell => applyFillToCell(theme, cell, "grey"));
  });

  saveAs(new Blob([await workbook.xlsx.writeBuffer()]), exportFilename);
};
