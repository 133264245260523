import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, TableCell, TableRow } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { useRefetchQueriesContext } from "apps/web/src/contexts/RefetchQueries";
import { getNavOrgAthleteGoalCompliance } from "apps/web/src/pages/Auth/Org/Athlete/AthletePaths";
import {
    GoalConfigurationForTableFragment,
    useArchiveGoalConfigurationMutation,
    useDeleteGoalConfigurationMutation,
    useEditGoalConfigurationWithDaysOfWeekMutation,
    useEditGoalConfigurationWithIndividualDatesMutation,
} from "apps/web/src/types";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { GoalConfigurationEditModal } from "./GoalConfiguration/GoalConfigurationEditModal";
import { EditGoalConfigurationInputType } from "./GoalConfiguration/GoalConfigurationSchema";

export interface MoreInfo {
  moreAnchorElement: HTMLElement;
  goal: GoalConfigurationForTableFragment;
}

interface AthleteGoalTableRowsProps {
  rows: readonly GoalConfigurationForTableFragment[];
}

export const AthleteGoalTableRows = ({ rows }: AthleteGoalTableRowsProps) => {
  const { formatDateWithLocale } = useDateFormatting();
  const { athleteId: maybeAthleteId } = useParams();
  const athleteId = maybeAthleteId || "";
  const navigate = useNavigate();

  const refetchQueries = useRefetchQueriesContext();
  const { setMessage } = useSnackbar();

  const [archiveGoalConfiguration] = useArchiveGoalConfigurationMutation({
    refetchQueries,
    onCompleted: () => {
      onArchived(archivableGoal?.name ?? "Task");
      setArchivableGoal(null);
    },
  });
  const [deleteGoalConfiguration] = useDeleteGoalConfigurationMutation({
    refetchQueries,
    onCompleted: () => {
      onDeleted(deletableGoal?.name ?? "Task");
      setDeletableGoal(null);
    },
  });
  const [editGoalConfigurationWithIndividualDates] = useEditGoalConfigurationWithIndividualDatesMutation({
    refetchQueries,
    onCompleted: data => {
      setEditableGoal(null);
      onEdited(data.editGoalConfigurationWithIndividualDates.goalConfiguration.name);
    },
  });
  const [editGoalConfigurationWithDaysOfWeek] = useEditGoalConfigurationWithDaysOfWeekMutation({
    refetchQueries,
    onCompleted: data => {
      setEditableGoal(null);
      onEdited(data.editGoalConfigurationWithDaysOfWeek.goalConfiguration.name);
    },
  });

  const onEdited = (name: string) => setMessage("success", `${name} has been updated`);
  const onArchived = (name: string) => setMessage("success", `${name} has been archived and moved to Previous Goals`);
  const onDeleted = (name: string) => setMessage("success", `${name} has been deleted`);

  const [editableGoal, setEditableGoal] = useState<GoalConfigurationForTableFragment | null>(null);
  const [deletableGoal, setDeletableGoal] = useState<GoalConfigurationForTableFragment | null>(null);
  const [archivableGoal, setArchivableGoal] = useState<GoalConfigurationForTableFragment | null>(null);
  const [moreInfo, setMoreInfo] = useState<MoreInfo | null>(null);

  const handleEditGoalConfiguration = (input: EditGoalConfigurationInputType) => {
    if ("dayOfWeekAssignments" in input) {
      editGoalConfigurationWithDaysOfWeek({ variables: { input } });
    } else {
      editGoalConfigurationWithIndividualDates({ variables: { input } });
    }
  };

  return (
    <>
      {rows.map(row => {
        const { id, name, category, startDate, endDate, emoji, target, totalGoalCount, completedGoalCount, isArchived } = row;
        return (
          <TableRow
            id={id}
            key={id}
            hover
            onClick={() => (!isArchived ? setEditableGoal(row) : undefined)}
            sx={{ cursor: "pointer" }}>
            <TableCell>{emoji}</TableCell>
            <TableCell>{name}</TableCell>

            <TableCell>{category.name}</TableCell>
            <TableCell>{target ? "Update progress" : "Mark as complete"}</TableCell>
            <TableCell>{startDate ? formatDateWithLocale(startDate) : "None"}</TableCell>
            <TableCell>{endDate ? formatDateWithLocale(endDate) : "None"}</TableCell>
            <TableCell>{`${completedGoalCount}/${totalGoalCount}`}</TableCell>

            <TableCell>
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  setMoreInfo({ moreAnchorElement: e.currentTarget, goal: row });
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
      {archivableGoal && (
        <ConfirmationDialog
          open={archivableGoal !== null}
          title="Archive Goal"
          confirmLabel="Archive"
          message="This will hide the goal from the athlete's view and display now in previous goals. Are you sure you want to archive this goal?"
          onCancel={() => setArchivableGoal(null)}
          onConfirm={() => archiveGoalConfiguration({ variables: { input: { id: archivableGoal.id } } })}
        />
      )}
      {deletableGoal && (
        <ConfirmationDialog
          open={deletableGoal !== null}
          title="Delete Goal"
          message="This will delete the goal from the athlete's view, but will not show in previous goals. To maintain record, archive the goal instead. Are you sure you want to delete this goal?"
          onCancel={() => setDeletableGoal(null)}
          onConfirm={() => deleteGoalConfiguration({ variables: { input: { id: deletableGoal.id } } })}
        />
      )}
      {editableGoal && (
        <GoalConfigurationEditModal
          open={editableGoal !== null}
          onClose={() => setEditableGoal(null)}
          goalConfiguration={editableGoal}
          onEditGoalConfiguration={handleEditGoalConfiguration}
        />
      )}
      <Menu
        anchorEl={moreInfo?.moreAnchorElement}
        open={!!moreInfo}
        onClose={() => {
          setMoreInfo(null);
        }}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {moreInfo && (
          <>
            {!moreInfo.goal.isArchived && (
              <MenuItem
                dense
                onClick={() => {
                  setArchivableGoal(moreInfo.goal);
                  setMoreInfo(null);
                }}
              >
                Archive
              </MenuItem>
            )}

            <MenuItem
              dense
              onClick={() => {
                navigate(getNavOrgAthleteGoalCompliance(athleteId, moreInfo.goal.id));
                setMoreInfo(null);
              }}
            >
              View Compliance
            </MenuItem>
            <MenuItem
              dense
              onClick={() => {
                setDeletableGoal(moreInfo.goal);
                setMoreInfo(null);
              }}
            >
              Delete
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};
