import { removeAllTeamsAndTransformProfiles } from "@notemeal/profile-sync";
import { Loading } from "@notemeal/shared-ui";
import { useState } from "react";
import {
    EditOrgTeamworksSkippedProfile,
    OrgForLinkingFragment,
    SportWithPositionMappingsFragment,
    TeamworksGroupFragment,
    TeamworksOrgFragment,
    TeamworksPositionLinkFragment,
    TeamworksProfileFragment,
} from "../../../../../types";
import ProfilesLinkerContent from "../Profiles/Content";
import TeamsLinkerContent from "../Teams/TeamsLinkerContent";
import { buildInitialAddAndLinkTeamsState, buildPartialAddAndLinkTeamsState } from "../Teams/reducer";

export interface OrgLinkerDialogContentProps {
  org: OrgForLinkingFragment;
  teamworksOrg: TeamworksOrgFragment;
  teamworksUserTypes: readonly TeamworksGroupFragment[];
  teamworksAthleteStatuses: readonly TeamworksGroupFragment[];
  allTeamworksProfiles: readonly TeamworksProfileFragment[];
  teamworksPositionLinks: readonly TeamworksPositionLinkFragment[];
  sports: readonly SportWithPositionMappingsFragment[];
  teamworksPositions: readonly TeamworksGroupFragment[];
  disabledProfileIds: readonly number[] | null;
  onClose: () => void;
  onSuccess: (skippedProfiles: readonly EditOrgTeamworksSkippedProfile[]) => void;
}

const OrgLinkerDialogContent = ({
  org,
  sports,
  teamworksOrg,
  teamworksUserTypes,
  teamworksAthleteStatuses,
  allTeamworksProfiles: _allTeamworksProfiles,
  teamworksPositionLinks,
  teamworksPositions,
  disabledProfileIds,
  onClose,
  onSuccess,
}: OrgLinkerDialogContentProps) => {
  const [step, setStep] = useState<"teams" | "profiles" | "loading">("teams");

  const { teamworksTeams, allTeamworksProfiles } = removeAllTeamsAndTransformProfiles(
    teamworksOrg.teams,
    _allTeamworksProfiles,
    disabledProfileIds
  );

  return (
    <>
      {step === "teams" ? (
        <TeamsLinkerContent
          initialTeamsState={buildInitialAddAndLinkTeamsState(buildPartialAddAndLinkTeamsState(teamworksOrg, org), org.teams)}
          sports={sports}
          teamworksPositionLinks={teamworksPositionLinks}
          onClose={onClose}
          onNext={() => setStep("profiles")}
          orgId={org.id}
        />
      ) : step === "profiles" ? (
        <ProfilesLinkerContent
          org={org}
          teamworksTeams={teamworksTeams}
          teamworksUserTypes={teamworksUserTypes}
          teamworksAthleteStatuses={teamworksAthleteStatuses}
          allTeamworksProfiles={allTeamworksProfiles}
          teamworksPositionLinks={teamworksPositionLinks}
          teamworksPositions={teamworksPositions}
          onNext={onSuccess}
          onClose={onClose}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default OrgLinkerDialogContent;
