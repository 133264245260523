import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Card, CardContent, IconButton, List, Menu, MenuItem, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getMealTemplateMacroTargets, getMenuSelectionItemServingAmounts, getServingAmountsMacros, Loading, newId } from "@notemeal/shared-ui";
import { ZERO_MACROS_INPUT } from "@notemeal/shared-utils-macro-protocol";
import { MacrosProgress } from "apps/web/src/components/MacrosProgress/MacrosProgress";
import classnames from "classnames";
import { useState } from "react";
import MenuOrderItemListItem from "../../../components/MenuOrderItem/ListItem";
import LabeledSelect from "../../../components/universal/LabeledSelect";
import { AthleteMenuSuggestionsPreviewFragment, MealType, useMenuOrderSuggestionsQuery } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      flexGrow: 1,
    },
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "flex",
      justifyContent: "space-betweeen",
      alignItems: "center",
      marginBottom: theme.spacing(),
    },
    foodGroups: {
      flexShrink: 0,
      marginLeft: theme.spacing(3),
    },
    foodGroupsButton: {
      marginLeft: theme.spacing(),
    },
    name: {
      flexShrink: 0,
      paddingRight: theme.spacing(3),
    },
    content: {
      flexGrow: 1,
      flexShrink: 1,
      overflow: "auto",
      display: "flex",
      justifyContent: "center",
    },
    contentText: {
      alignSelf: "center",
    },
    suggestion: {
      margin: theme.spacing(2),
      width: `calc(33% - ${theme.spacing(4)})`,
    },
    itemsList: {
      flexGrow: 1,
      overflowY: "auto",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    optionTypography: {
      paddingLeft: theme.spacing(),
    },
  })
);

interface MenuSuggestionsPreviewAthleteProps {
  mealMenuId: string;
  mealMenuType: MealType;
  athlete: AthleteMenuSuggestionsPreviewFragment;
  className?: string;
}

const SUGGESTIONS_LIMIT = 3;

const MenuSuggestionsPreviewAthlete = ({ mealMenuId, mealMenuType, athlete, className }: MenuSuggestionsPreviewAthleteProps) => {
  const classes = useStyles();
  const [foodGroupsAnchorEl, setFoodGroupsAnchorEl] = useState<HTMLElement | null>(null);

  // TODO: Start with one selected. Could choose first one, or one based on date.
  const [selectedMealPlan, setSelectedMealPlan] = useState(() => athlete.mealPlans[0] || null);
  // TODO: Any easy way to re-use more robust mealTemplate matching from Timeline?
  const matchingMealTemplate = selectedMealPlan?.mealTemplates.find(mt => mt.meal.type === mealMenuType);
  const targetMacros = matchingMealTemplate && getMealTemplateMacroTargets(matchingMealTemplate);

  const avoidFoodGroups = [...athlete.dislikedFoodGroups, ...(selectedMealPlan?.avoidedFoodGroups || [])];

  const { data, loading } = useMenuOrderSuggestionsQuery({
    variables: {
      mealMenuId,
      limit: SUGGESTIONS_LIMIT,
      targetMacros: targetMacros
        ? {
            cho: targetMacros.cho,
            pro: targetMacros.pro,
            fat: targetMacros.fat,
          }
        : ZERO_MACROS_INPUT,
      avoidFoodGroupIds: avoidFoodGroups.map(fg => fg.id),
    },
    skip: !targetMacros,
  });

  const hasSuggestions = Boolean(data?.menuOrderSuggestions?.length);

  return (
    <div className={classnames(classes.root, className)}>
      <div className={classes.header}>
        <Typography variant="h3" className={classes.name}>
          {athlete.firstName} {athlete.lastName}
        </Typography>
        <LabeledSelect
          placeholder="Selected Meal Plan"
          selectedOption={selectedMealPlan}
          options={[...athlete.mealPlans]}
          optionToName={mp => mp.name}
          onChange={setSelectedMealPlan}
        />
        <Typography className={classes.foodGroups}>Avoided / Disliked Food Groups: {avoidFoodGroups.length}</Typography>

        {avoidFoodGroups.length > 0 && (
          <IconButton
            className={classes.foodGroupsButton}
            onClick={e => setFoodGroupsAnchorEl(e.currentTarget)}
            size="large">
            <MoreVertIcon />
          </IconButton>
        )}

        <Menu
          anchorEl={foodGroupsAnchorEl}
          open={Boolean(foodGroupsAnchorEl)}
          onClose={() => setFoodGroupsAnchorEl(null)}
          keepMounted
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {avoidFoodGroups.map(fg => (
            <MenuItem
              dense
              key={fg.id}
              onClick={() => setFoodGroupsAnchorEl(null)}>
              {fg.name}
            </MenuItem>
          ))}
        </Menu>
      </div>

      <div className={classes.content}>
        {loading ? (
          <Loading />
        ) : data?.menuOrderSuggestions?.length && targetMacros ? (
          data.menuOrderSuggestions.map((suggestion, index) => (
            <Card
              raised
              key={index}
              className={classes.suggestion}>
              <CardContent>
                <MacrosProgress
                  variant="sm"
                  currentMacros={getServingAmountsMacros(suggestion.items.flatMap(getMenuSelectionItemServingAmounts))}
                  targetMacros={targetMacros}
                  macroProgressViews={"all"}
                />
                <List dense className={classes.itemsList}>
                  {suggestion.items.map(item => {
                    const id = newId();
                    return (
                      <MenuOrderItemListItem
                        key={id}
                        menuOrderItem={{
                          ...item,
                          specialRequests: null,
                        }}
                        hidePercentConsumed
                      />
                    );
                  })}
                </List>
              </CardContent>
            </Card>
          ))
        ) : !selectedMealPlan ? (
          <Typography variant="h3" className={classes.contentText}>
            No Meal Plan Selected
          </Typography>
        ) : !targetMacros ? (
          <Typography variant="h3" className={classes.contentText}>
            No Meal on Plan Matches Menu
          </Typography>
        ) : !hasSuggestions ? (
          <Typography variant="h3" className={classes.contentText}>
            No Suggestions Found
          </Typography>
        ) : null}
      </div>
    </div>
  );
};

export default MenuSuggestionsPreviewAthlete;
