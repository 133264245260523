import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ReactNode } from "react";

import { getMenuItemMaxAmount, getMenuSectionAmountsForMenuItem, MenuSection, MenuSelectionItemWithAppearance } from "@notemeal/shared-ui";
import EditForm from "./EditForm";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "flex",
      alignItems: "center",
      paddingBottom: theme.spacing(),
    },
    back: {
      marginRight: theme.spacing(),
    },
  })
);

interface MenuSelectionItemEditProps {
  menuSelectionItem: MenuSelectionItemWithAppearance;
  menuSections: readonly MenuSection[];
  currentAmountForMenuItem: number;
  menuSectionAmountsForSelection: Record<string, number | undefined>;
  onEdit: (menuSelectionItem: MenuSelectionItemWithAppearance) => void;
  onDone?: () => void;
  onBack: () => void;
  children?: ReactNode;
}

const MenuSelectionItemEdit = ({
  menuSelectionItem,
  currentAmountForMenuItem,
  menuSectionAmountsForSelection,
  menuSections,
  onEdit,
  onDone,
  onBack,
  children,
}: MenuSelectionItemEditProps) => {
  const classes = useStyles();
  const { currentAmountForMenuSection, maxAmountForMenuSection } = getMenuSectionAmountsForMenuItem(
    menuSelectionItem.menuItem.id,
    menuSections,
    menuSectionAmountsForSelection
  );

  const maxAmount = getMenuItemMaxAmount({
    currentAmountForMenuItem,
    maxAmountForMenuItem: menuSelectionItem.menuItemAppearance.maxAmount,
    currentAmountForMenuSection,
    maxAmountForMenuSection,
    menuSelectionItemAmount: menuSelectionItem.amount,
  });

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <IconButton
          size="small"
          onClick={onBack}
          className={classes.back}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h3">
          <strong>Update Menu Item</strong>
        </Typography>
      </div>
      <EditForm
        availableForOrder={menuSelectionItem.menuItemAppearance.availableForOrder}
        allowSpecialRequests={menuSelectionItem.menuItemAppearance.allowSpecialRequests}
        maxAmount={maxAmount}
        onEdit={onEdit}
        menuSelectionItem={menuSelectionItem}
        onDone={() => {
          onDone && onDone();
          onBack();
        }}
        children={children}
      />
    </div>
  );
};

export default MenuSelectionItemEdit;
