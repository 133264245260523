import InfoIcon from "@mui/icons-material/Info";
import { Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FatProgressBar, getTopReportingFoodOrRecipes, LISTED_FOOD_OR_RECIPE_COUNT, NutrientAmountWithFoodOrRecipes, round } from "@notemeal/shared-ui";
import React from "react";
import StyledTreeItem from "./StyledTreeItem";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    nutrientLabel: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
      justifyContent: "space-between",
    },
    infoIcon: {
      color: grey.A400,
      paddingLeft: spacing(1),
    },
    progressPlaceholder: {
      width: 90,
      paddingLeft: spacing(),
    },
    treeRoot: {
      width: "100%",
    },
    boldFont: {
      fontWeight: "bold",
    },
    spacer: {
      flexGrow: 1,
    },
  })
);

export interface NutrientTreeItemProps {
  children?: React.ReactNode;
  isRoot?: boolean;
  nodeId: string;
  displayDri: boolean;
  nutrientAmount: NutrientAmountWithFoodOrRecipes;
}

const NutrientTreeItem = ({ children, isRoot, nodeId, nutrientAmount, displayDri }: NutrientTreeItemProps) => {
  const classes = useStyles();
  const { nutrient, amount, reportingFoodOrRecipes, nonReportingFoodOrRecipes, totalFoodOrRecipesCount } = nutrientAmount;
  const topReportingFoodsOrRecipes = getTopReportingFoodOrRecipes(nutrientAmount);
  const hasMoreFoodsOrRecipes = nutrientAmount.reportingFoodOrRecipes.length - LISTED_FOOD_OR_RECIPE_COUNT;
  return (
    <StyledTreeItem
      className={isRoot ? classes.treeRoot : ""}
      nodeId={nodeId}
      label={
        <div className={classes.nutrientLabel}>
          <Typography component="span">{nutrientAmount.nutrient.commonName}</Typography>
          {reportingFoodOrRecipes.length < totalFoodOrRecipesCount && (
            <Tooltip
              title={
                <>
                  <Typography
                    variant="subtitle1"
                    classes={{ root: classes.boldFont }}
                    component="p">
                    Food(s) / Recipe(s) with 0 (or unreported) {nutrient.commonName}
                  </Typography>
                  {nonReportingFoodOrRecipes.map(f => (
                    <Typography
                      key={f.id}
                      variant="subtitle1"
                      component="p">
                      {f.name}
                    </Typography>
                  ))}
                </>
              }
            >
              <InfoIcon classes={{ root: classes.infoIcon }} />
            </Tooltip>
          )}
          <div className={classes.spacer} />
          <Typography component="span">
            {round(amount, 1)} {nutrient.unitName.toLowerCase()}
          </Typography>
          {nutrient.rdiAmount && displayDri ? (
            <FatProgressBar
              error={amount > nutrient.rdiAmount && !!nutrient.rdiType && ["dga", "ul"].includes(nutrient.rdiType)}
              current={amount}
              target={nutrient.rdiAmount}
              usePercent
              unit={nutrient.unitName}
            />
          ) : (
            <div className={classes.progressPlaceholder} />
          )}
        </div>
      }
    >
      {!children ? (
        <>
          {topReportingFoodsOrRecipes.map(({ id, name, nutrientAmount }, idx) => (
            <StyledTreeItem
              className={""}
              key={`node-key-${idx}`}
              nodeId={`leaf${nodeId}-${idx}`}
              label={
                <div className={classes.nutrientLabel}>
                  <Typography component="span">{name}</Typography>
                  <div className={classes.spacer} />
                  <Typography component="span">
                    {round(nutrientAmount, 1)} {nutrient.unitName.toLowerCase()}
                  </Typography>
                  <div className={classes.progressPlaceholder} />
                </div>
              }
            />
          ))}
          {hasMoreFoodsOrRecipes > 0 && (
            <Typography variant="subtitle1" component="span">{`*${hasMoreFoodsOrRecipes} more foods contain this nutrient`}</Typography>
          )}
        </>
      ) : (
        children
      )}
    </StyledTreeItem>
  );
};

export default NutrientTreeItem;
