import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, TableCell, TableRow, capitalize } from "@mui/material";
import { NutrientAmountState, useHasFeature } from "@notemeal/shared-ui";
import { useUser } from "apps/web/src/utils/tokens";
import { useState } from "react";
import { Brand, ExchangeType, FoodDataSource, FoodGroup, GroceryListCategory } from "../../../types";
import { toTitleCase } from "../../../utils/titleCase";
import { FoodServingType } from "../FoodFormSchema/FoodServingSchema";
import { FoodTableMode } from "./utils";

export interface IFoodRow {
  id: string;
  name: string;
  createdByString: string;
  createdBy: { firstName: string; lastName: string } | null;
  choPer100g: number;
  proPer100g: number;
  fatPer100g: number;
  usdaFdcId: number | null;
  usdaFdcDescription: string | null;
  usdaFdcFoodCategoryId: number | null;
  usdaFdcDataType: string | null;
  exchangeTypes: readonly ExchangeType[] | null;
  isRecipeIngredientOnly: boolean;
  excludeFromSuggestions: boolean;
  servings: FoodServingType[];
  foodGroups: readonly Pick<FoodGroup, "id" | "name">[];
  brand: Pick<Brand, "id" | "name" | "usdaManufacturerName"> | null;
  type: "GenericFood" | "BrandedFood";
  nutrientAmounts: NutrientAmountState;
  thumbnailUrl: string | null;
  groceryListCategory: Pick<GroceryListCategory, "id" | "name"> | null;
  source: FoodDataSource;
  locales: readonly string[];
  // READ-ONLY
  defaultServing: { amount: number; units: string };
  managedByString: string | null;
  ownerId: string | null;
  lastEditedString: string | null;
  orgName: string | null;
  orgGroupName: string | null;
  orgGroupId: string | null;
}

export const TableHeaderRow = ({ tableMode }: { tableMode: FoodTableMode }) => {
  if (tableMode === "org-group") {
    return (
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Org Group</TableCell>
        <TableCell>Managed by</TableCell>
        <TableCell>Grocery Category</TableCell>
        <TableCell>Exchanges</TableCell>
        <TableCell>Last Edited</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>Name</TableCell>
      {tableMode === "org" && <TableCell>Created by</TableCell>}
      <TableCell>Grocery Category</TableCell>
      <TableCell>Exchanges</TableCell>
      {tableMode === "staff" && <TableCell>Food Source</TableCell>}
      {tableMode !== "staff" && <TableCell>Default Serving</TableCell>}
      {tableMode !== "staff" && <TableCell>Serving Name</TableCell>}
      <TableCell>Image?</TableCell>
      <TableCell>Ingredient?</TableCell>
      <TableCell>Actions</TableCell>
    </TableRow>
  );
};

interface FoodTableRowProps {
  foodRow: IFoodRow;
  onClick: (foodRow: IFoodRow) => void;
  onDeleteFood: (foodRow: IFoodRow) => void;
  onMoveOrgFoodToOrgGroup: (foodRow: IFoodRow) => void;
  onMoveOrgGroupFoodToMyOrg: (foodRow: IFoodRow) => void;
  tableMode: FoodTableMode;
}

const FoodTableRow = ({
  foodRow,
  onClick,
  onDeleteFood,
  onMoveOrgFoodToOrgGroup,
  onMoveOrgGroupFoodToMyOrg,
  tableMode,
}: FoodTableRowProps) => {
  const user = useUser();
  const [actionsMenu, setActionsMenu] = useState<HTMLElement | null>(null);
  const orgGroupFoodsEnabled = useHasFeature("orgGroupFoods");

  return (
    <>
      <TableRow
        hover
        onClick={() => onClick(foodRow)}
        key={foodRow.id}>
        {tableMode === "org-group" && (
          <>
            <TableCell>{foodRow.name}</TableCell>
            <TableCell>{foodRow.orgGroupName}</TableCell>
            <TableCell>{foodRow.managedByString}</TableCell>
            <TableCell>{foodRow.groceryListCategory?.name || "Alternative/Miscellaneous"}</TableCell>
            <TableCell>{(foodRow.exchangeTypes || []).map(et => toTitleCase(et)).join(", ")}</TableCell>
            <TableCell>{foodRow.lastEditedString}</TableCell>
            <TableCell scope="row">
              <IconButton
                size="small"
                disabled={foodRow.ownerId !== user?.id}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setActionsMenu(e.currentTarget);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </TableCell>
          </>
        )}
        {tableMode !== "org-group" && (
          <>
            <TableCell>{foodRow.name}</TableCell>
            {tableMode === "org" && <TableCell>{foodRow.createdByString}</TableCell>}
            <TableCell>{foodRow.groceryListCategory?.name || "Alternative/Miscellaneous"}</TableCell>
            <TableCell>{(foodRow.exchangeTypes || []).map(et => toTitleCase(et)).join(", ")}</TableCell>
            {tableMode === "staff" && <TableCell>{capitalize(foodRow.source)}</TableCell>}
            {tableMode !== "staff" && <TableCell>{foodRow.defaultServing.amount}</TableCell>}
            {tableMode !== "staff" && <TableCell>{foodRow.defaultServing.units}</TableCell>}
            <TableCell>{foodRow.thumbnailUrl ? "✓" : ""}</TableCell>
            <TableCell>{foodRow.isRecipeIngredientOnly ? "✓" : ""}</TableCell>
            <TableCell scope="row">
              <IconButton
                size="small"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setActionsMenu(e.currentTarget);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </TableCell>
          </>
        )}
      </TableRow>
      <Menu
        anchorEl={actionsMenu}
        open={!!actionsMenu}
        onClose={() => {
          setActionsMenu(null);
        }}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          dense
          disabled={Boolean(foodRow.orgGroupId) && foodRow.ownerId !== user?.id}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setActionsMenu(null);
            onDeleteFood(foodRow);
          }}
        >
          Delete
        </MenuItem>
        {orgGroupFoodsEnabled && foodRow.orgGroupId === null && (
          <MenuItem
            dense
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setActionsMenu(null);
              onMoveOrgFoodToOrgGroup(foodRow);
            }}
          >
            Move to Org Group
          </MenuItem>
        )}
        {orgGroupFoodsEnabled && foodRow.orgGroupId !== null && (
          <MenuItem
            dense
            disabled={foodRow.ownerId !== user?.id}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setActionsMenu(null);
              onMoveOrgGroupFoodToMyOrg(foodRow);
            }}
          >
            Move to My Org
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default FoodTableRow;
