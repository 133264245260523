import { standardizePhoneNumberOrNull } from "@notemeal/utils-phone-number";
import { invalidEmail } from "apps/web/src/utils/invalidEmail";
import { AccountVerificationMethod, Role } from "../../../../types";
import { AdminOrgMembershipTableRow } from "../Table";

export interface OrgMembershipFormState {
  isAdmin: boolean;
  isActive: boolean;
  roles: Role[];
  orgId: string;
  accountActivated: boolean;
  email: string | null;
  phoneNumber: string | null;
}

export interface CreateOrgMembershipFormState extends OrgMembershipFormState {
  method: AccountVerificationMethod;
  firstName: string;
  lastName: string;
}

interface ChangeIsActive {
  type: "CHANGE_IS_ACTIVE";
  payload: boolean;
}

interface ChangeIsAdmin {
  type: "CHANGE_IS_ADMIN";
  payload: boolean;
}

interface ChangeRoles {
  type: "CHANGE_ROLES";
  payload: Role[];
}
/* Create OrgMembership Actions */
interface ChangeEmail {
  type: "CHANGE_EMAIL";
  payload: string;
}
interface ChangePhoneNumber {
  type: "CHANGE_PHONE_NUMBER";
  payload: string;
}
interface ChangeFirstName {
  type: "CHANGE_FIRST_NAME";
  payload: string;
}
interface ChangeLastName {
  type: "CHANGE_LAST_NAME";
  payload: string;
}
interface ChangeMethod {
  type: "CHANGE_METHOD";
  payload: AccountVerificationMethod;
}

export type EditOrgMembershipFormAction = ChangeRoles | ChangeIsActive | ChangeIsAdmin | ChangeEmail | ChangePhoneNumber;

export type CreateOrgMembershipFormAction = EditOrgMembershipFormAction | ChangeFirstName | ChangeLastName | ChangeMethod;

export function editOrgMembershipFormReducer<T extends OrgMembershipFormState>(state: T, action: EditOrgMembershipFormAction): T {
  switch (action.type) {
    case "CHANGE_IS_ACTIVE":
      return { ...state, isActive: action.payload };
    case "CHANGE_IS_ADMIN":
      return { ...state, isAdmin: action.payload };
    case "CHANGE_ROLES":
      return { ...state, roles: action.payload };
    case "CHANGE_EMAIL":
      return { ...state, email: action.payload };
    case "CHANGE_PHONE_NUMBER":
      return { ...state, phoneNumber: action.payload };
    default:
      return state;
  }
}
export function createOrgMembershipFormReducer<T extends CreateOrgMembershipFormState>(state: T, action: CreateOrgMembershipFormAction): T {
  switch (action.type) {
    case "CHANGE_IS_ADMIN":
      return { ...state, isAdmin: action.payload };
    case "CHANGE_IS_ACTIVE":
      return { ...state, isActive: action.payload };
    case "CHANGE_ROLES":
      return { ...state, roles: action.payload };
    case "CHANGE_EMAIL":
      return { ...state, email: action.payload };
    case "CHANGE_PHONE_NUMBER":
      return { ...state, phoneNumber: action.payload };
    case "CHANGE_FIRST_NAME":
      return { ...state, firstName: action.payload };
    case "CHANGE_LAST_NAME":
      return { ...state, lastName: action.payload };
    case "CHANGE_METHOD":
      return { ...state, method: action.payload };
    default:
      return state;
  }
}

export const createOrgMembershipFormToSaveTooltips = (state: CreateOrgMembershipFormState): string[] => {
  const tooltips: string[] = [];
  if (state.accountActivated && state.isActive) {
    tooltips.push("Can't set isActive to true - account not activated!");
  }
  if (state.roles.length === 0) {
    tooltips.push("At least 1 'role' is required");
  }
  if (!state?.email?.trim()) {
    tooltips.push("'Email' is required");
  }
  if (state.method === "txt" && !state?.phoneNumber?.trim()) {
    tooltips.push("'phoneNumber' required if activating by TXT");
  }
  if (!state.firstName) {
    tooltips.push("'First Name' required.");
  }
  if (!state.lastName) {
    tooltips.push("'Last Name' required.");
  }
  return tooltips;
};
export const editOrgMembershipFormToSaveTooltips = (
  state: OrgMembershipFormState,
  { email, phoneNumber }: AdminOrgMembershipTableRow
): string[] => {
  const tooltips: string[] = [];
  if (state.roles.length === 0) {
    tooltips.push("At least 1 'role' is required");
  }
  if (state.email && invalidEmail(state.email)) {
    tooltips.push("Please input a valid email");
  }
  if (state.phoneNumber && !standardizePhoneNumberOrNull(state.phoneNumber)) {
    tooltips.push("Please input a valid phoneNumber");
  }
  return tooltips;
};

export const initialCreateOrgMembershipFormState = (orgId: string): CreateOrgMembershipFormState => ({
  method: "txt",
  firstName: "",
  lastName: "",
  accountActivated: false,
  email: "",
  phoneNumber: "",
  isAdmin: false,
  isActive: false,
  orgId,
  roles: [],
});

export const initialEditOrgMembershipFormState = (
  { roles, lastRefresh, isActive, isAdmin, phoneNumber, email }: AdminOrgMembershipTableRow,
  orgId: string
): OrgMembershipFormState => ({
  roles: [...roles],
  accountActivated: !!lastRefresh,
  isAdmin,
  isActive,
  orgId,
  phoneNumber,
  email,
});
