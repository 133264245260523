import { Box, Table, TableBody, TableContainer, TableHead, TablePagination, TableProps } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import { ReactNode } from "react";
import { PaginationHooks, getMuiTablePaginationProps } from "../../../utils/pagination";

interface TablePageProps {
  header?: ReactNode;
  tableHeaderRow: ReactNode;
  tableBodyRows: ReactNode;
  loading: boolean;
  total: number;
  paginationHooks: PaginationHooks;
  size?: TableProps["size"];
}

const TablePage = ({ paginationHooks, loading, total, tableHeaderRow, tableBodyRows, header, size }: TablePageProps) => (
  <Box sx={{ flex: 1, overflow: "hidden", display: "flex", flexDirection: "column", gap: 2 }}>
    {header && <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end", justifyContent: "flex-end" }}>{header}</Box>}
    <TableContainer sx={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
      <Table size={size ?? "small"}>
        <TableHead>{tableHeaderRow}</TableHead>
        <TableBody>{tableBodyRows}</TableBody>
      </Table>
    </TableContainer>
    {loading && (
      <Box
        sx={{
          zIndex: 1,
          minHeight: 0,
          overflowY: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading progressSize="md" />
      </Box>
    )}
    <TablePagination
      component="div"
      sx={{ ".MuiTablePagination-actions": { mr: 8 } }}
      {...getMuiTablePaginationProps(paginationHooks, total)}
    />
  </Box>
);

export default TablePage;
