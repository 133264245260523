import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Grid, TextField, Typography, useTheme } from "@mui/material";
import { inputToNumber } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { useEditRecipeFullMutation, useEditRecipePartialMutation } from "apps/web/src/types";
import React, { Dispatch, useState } from "react";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import LoadingBackdrop from "../../universal/LoadingBackdrop";
import { editRecipeStateToInput } from "../EditDialog/utils";
import { RecipeFormAction, RecipeFormState, scaleTheRecipeWithYield } from "../Form/utils";
import { RecipeUnsavedChangesDialog } from "./RecipeUnsavedChangesDialog";
interface RecipeScaleDialogProps {
  state: RecipeFormState;
  initialFormState: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  openScaleChangeDialog: boolean;
  setOpenScaleChangeDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openRecipeUnsavedChangesDialog: boolean;
  setOpenRecipeUnsavedChangesDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  openScaleRecipe: (value: boolean) => void;
  onScaleAndCreateNewRecipe?: (state: RecipeFormState) => void;
}

export const RecipeScaleDialog: React.FC<RecipeScaleDialogProps> = ({
  state,
  initialFormState,
  dispatch,
  openScaleChangeDialog,
  setOpenScaleChangeDialog,
  openRecipeUnsavedChangesDialog,
  setOpenRecipeUnsavedChangesDialog,
  onSuccess,
  openScaleRecipe,
  onScaleAndCreateNewRecipe,
}) => {
  const [recipeYieldInput, setRecipeYieldInput] = useState<{
    value: string;
    touched: boolean;
    error: boolean;
  }>({
    value: "",
    touched: false,
    error: false,
  });
  const { palette, spacing } = useTheme();
  const { setMessage } = useSnackbar();
  const [editRecipePartial, { loading: savingPartial }] = useEditRecipePartialMutation();
  const [editRecipeFull, { loading: savingFull }] = useEditRecipeFullMutation();

  const isRecipeYieldInputValid = !recipeYieldInput.error && recipeYieldInput.touched && inputToNumber(recipeYieldInput.value) !== null;

  const onSave = () => {
    if (isRecipeYieldInputValid) {
      dispatch({
        type: "CHANGE_RECIPE_SCALE",
        payload: scaleTheRecipeWithYield(inputToNumber(recipeYieldInput.value) || 0, state),
      });
      handleClose();
      onSuccess(true);
      trackEvent("Nutrition | Team | Recipe | Update Recipe to Scale Recipe", {});
    } else {
      setRecipeYieldInput(prevState => ({ ...prevState, error: true, touched: true }));
    }
  };

  const createNewRecipe = () => {
    if (isRecipeYieldInputValid) {
      handleClose();
      onScaleAndCreateNewRecipe?.(scaleTheRecipeWithYield(inputToNumber(recipeYieldInput.value) || 0, state));
      trackEvent("Nutrition | Team | Recipe | Create Scaled Recipe Copy", {});
    } else {
      setRecipeYieldInput(prevState => ({ ...prevState, error: true, touched: true }));
    }
  };

  const getScaledAmount = (value: number) => {
    const newValue = inputToNumber(recipeYieldInput.value);
    const perYieldValue = inputToNumber(state.serving.perRecipeYieldInput) || 1;
    if (newValue == null) {
      return value;
    }

    return value * (newValue / perYieldValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = inputToNumber(e.target.value);

    setRecipeYieldInput(prevState => ({
      ...prevState,
      value: e.target.value,
      error: value == null || value <= 0,
    }));
  };

  const handleInputBlur = () =>
    setRecipeYieldInput(prevState => ({
      ...prevState,
      touched: true,
    }));

  const handleClose = () => {
    setOpenScaleChangeDialog(false);
    openScaleRecipe(false);
    setRecipeYieldInput({
      value: "",
      touched: false,
      error: false,
    });
  };

  const discardUnsavedChanges = () => {
    setOpenRecipeUnsavedChangesDialog(false);
    dispatch({
      type: "CHANGE_RECIPE_SCALE",
      payload: initialFormState,
    });
    setOpenScaleChangeDialog(true);
  };

  const saveEditRecipe = async (id: string) => {
    const maybeInput = editRecipeStateToInput({
      id,
      isRevision: false,
      pendingRevisionAction: null,
      form: state,
    });

    if (maybeInput?.type === "full") {
      await editRecipeFull({ variables: { input: maybeInput.input } });
    } else if (maybeInput?.type === "partial") {
      await editRecipePartial({ variables: { input: maybeInput.input } });
    }

    dispatch({
      type: "CHANGE_EDITED_STATE",
      payload: false,
    });
    setOpenRecipeUnsavedChangesDialog(false);
    setOpenScaleChangeDialog(true);
    setMessage("success", "Recipe details have been saved");
  };

  const onConfirmUnsavedChanges = async () => {
    if (state.id) {
      saveEditRecipe(state.id);
    } else {
      setOpenRecipeUnsavedChangesDialog(false);
      setOpenScaleChangeDialog(true);
    }
  };

  return savingPartial || savingFull ? (
    <LoadingBackdrop open={savingPartial || savingFull} onClose={() => {}} />
  ) : (
    <>
      <RecipeUnsavedChangesDialog
        open={openRecipeUnsavedChangesDialog}
        title="Unsaved Changes"
        message={`The recipe has unsaved changes. Would you like to save or discard the changes before proceeding?`}
        onCancel={() => {
          setOpenScaleChangeDialog(true);
          setOpenRecipeUnsavedChangesDialog(false);
        }}
        onDiscard={() => discardUnsavedChanges()}
        onConfirm={() => onConfirmUnsavedChanges()}
      />
      <Dialog
        onClose={handleClose}
        open={openScaleChangeDialog}
        maxWidth="sm"
        fullWidth>
        <DialogTitle title="Scale Recipe" onClose={handleClose} />
        <DialogContent sx={{ pt: 0 }}>
          <Box>
            <Typography variant="body1Semibold">New Recipe Yield</Typography>
            <Typography variant="body2" color={"mediumEmphasisTextLight"}>
              Enter the new yield (total number of servings) below. Only the ingredients will be scaled. Other details such as cook time,
              prep time, and instructions will not be scaled.
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                disabled
                label="Original Yield"
                fullWidth
                value={state.serving.perRecipeYieldInput} />
            </Grid>
            <NavigateNextIcon fontSize="large" sx={{ marginTop: 4.5, marginLeft: 1 }} />
            <Grid item xs={3}>
              <TextField
                label="New Yield"
                fullWidth
                placeholder="#"
                value={recipeYieldInput.value}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                error={recipeYieldInput.touched && recipeYieldInput.error}
                required
                helperText={recipeYieldInput.touched && recipeYieldInput.error ? "Required" : <Box sx={{ height: "14px" }} />}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box>
            <Typography variant="body1Semibold">Ingredients</Typography>
            <Typography variant="body2">Below is a preview of the scaled ingredient amounts based on the new yield.</Typography>
          </Box>
          <Box>
            {state.ingredients.map(ingredient => (
              <Box
                key={ingredient.serving.foodOrRecipe.name}
                sx={{
                  borderRadius: spacing(0.5),
                  padding: "4px 12px",
                  mb: 1,
                  bgcolor: palette.greyscaleLight[100],
                }}
              >
                <Typography variant="body2Semibold" component={"p"}>
                  {ingredient.serving.foodOrRecipe.name}
                </Typography>
                <Typography variant="subtitle1" component={"p"}>
                  {getScaledAmount(ingredient.amount)} {ingredient.serving.units}
                </Typography>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
          {!!onScaleAndCreateNewRecipe && (
            <Button variant="outlined" onClick={() => createNewRecipe()}>
              Save as New Recipe
            </Button>
          )}
          <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end", gap: 1 }}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={() => onSave()}>Save</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
