import {
  CreateGenericFoodInput,
  CreateBrandedFoodInput,
  CreateFoodServingsInput,
  EditGenericFoodInput,
  EditBrandedFoodInput,
  EditFoodServingInput,
  FoodServingInput,
} from "../../../types";
import { CreateFoodNutrientAmountFormInput } from "../../../types";
import { convertVitaminDToIU } from "@notemeal/shared-ui";
import { inputToNumber } from "@notemeal/shared-ui";
import { FORM_NUTRIENTS, NutrientAmountState } from "@notemeal/shared-ui";
import { BaseFoodFormType } from "./FoodFormSchema";

export const toCreateGenericFoodInput = (state: BaseFoodFormType): CreateGenericFoodInput | null => {
  const {
    name,
    nutrientAmounts,
    usdaFdcDescription,
    usdaFdcId,
    usdaFdcFoodCategoryId,
    usdaFdcDataType,
    isRecipeIngredientOnly,
    excludeFromSuggestions,
    choPer100g,
    proPer100g,
    fatPer100g,
    exchangeTypes,
    nutrientAmountsManualEntry,
    thumbnailUrl,
    groceryListCategoryId,
    source,
    id,
    type,
    locales,
    foodGroups,
  } = state;
  const servings = toFoodServingInputs(state);
  if (!!id || type !== "GenericFood" || !servings) {
    return null;
  }
  return {
    food: {
      usdaFdcId: usdaFdcId || null,
      usdaFdcDescription: usdaFdcDescription || null,
      usdaFdcFoodCategoryId: usdaFdcFoodCategoryId || null,
      usdaFdcDataType: usdaFdcDataType || null,
      mccanceId: null,
      mccanceName: null,
      isRecipeIngredientOnly,
      excludeFromSuggestions,
      name,
      thumbnailUrl,
      groceryListCategoryId,
      choPer100g,
      proPer100g,
      fatPer100g,
      exchangeTypes: exchangeTypes || [],
      source,
      locales: locales || [],
      foodGroupIds: foodGroups.flatMap(fg => fg.id),
    },
    servings,
    nutrientAmounts: nutrientAmounts || [],
    nutrientAmountsManualEntry:
      !nutrientAmounts && nutrientAmountsManualEntry
        ? getNutrientAmountsForInput({
            nutrientAmountState: nutrientAmountsManualEntry,
            choPer100g,
            proPer100g,
            fatPer100g,
          })
        : [],
  };
};

export const toCreateBrandedFoodInput = (state: BaseFoodFormType): CreateBrandedFoodInput | null => {
  const {
    brand,
    name,
    nutrientAmounts,
    usdaFdcDescription,
    usdaFdcId,
    usdaFdcFoodCategoryId,
    usdaFdcDataType,
    isRecipeIngredientOnly,
    excludeFromSuggestions,
    choPer100g,
    proPer100g,
    fatPer100g,
    exchangeTypes,
    nutrientAmountsManualEntry,
    thumbnailUrl,
    groceryListCategoryId,
    source,
    type,
    id,
    locales,
    foodGroups,
  } = state;
  const servings = toFoodServingInputs(state);
  if (!!id || type !== "BrandedFood" || !servings) {
    return null;
  }

  return {
    food: {
      usdaFdcId: usdaFdcId || null,
      usdaFdcDescription: usdaFdcDescription || null,
      usdaFdcFoodCategoryId: usdaFdcFoodCategoryId || null,
      usdaFdcDataType: usdaFdcDataType || null,
      isRecipeIngredientOnly,
      excludeFromSuggestions,
      name,
      choPer100g,
      proPer100g,
      fatPer100g,
      exchangeTypes: exchangeTypes || [],
      brandId: brand ? brand.id : null,
      thumbnailUrl,
      groceryListCategoryId,
      source,
      locales: locales || [],
      foodGroupIds: foodGroups.flatMap(fg => fg.id),
      mccanceId: null,
      mccanceName: null,
    },
    servings,
    nutrientAmounts: nutrientAmounts || [],
    nutrientAmountsManualEntry:
      !nutrientAmounts && nutrientAmountsManualEntry
        ? getNutrientAmountsForInput({
            nutrientAmountState: nutrientAmountsManualEntry,
            choPer100g,
            proPer100g,
            fatPer100g,
          })
        : [],
  };
};

export const toEditGenericFoodInput = (state: BaseFoodFormType): EditGenericFoodInput | null => {
  const {
    nutrientAmounts,
    id,
    usdaFdcId,
    usdaFdcDescription,
    usdaFdcDataType,
    usdaFdcFoodCategoryId,
    name,
    choPer100g,
    proPer100g,
    fatPer100g,
    exchangeTypes,
    isRecipeIngredientOnly,
    excludeFromSuggestions,
    nutrientAmountsManualEntry,
    thumbnailUrl,
    groceryListCategoryId,
    source,
    type,
    locales,
    foodGroups,
  } = state;

  if (type !== "GenericFood" || !id) {
    return null;
  }

  return {
    food: {
      usdaFdcId,
      usdaFdcDescription,
      usdaFdcDataType,
      usdaFdcFoodCategoryId,
      mccanceId: null,
      mccanceName: null,
      name,
      choPer100g,
      proPer100g,
      fatPer100g,
      exchangeTypes: exchangeTypes || [],
      isRecipeIngredientOnly,
      excludeFromSuggestions,
      thumbnailUrl,
      groceryListCategoryId,
      source,
      locales: locales || [],
      foodGroupIds: foodGroups.flatMap(fg => fg.id),
    },
    nutrientAmounts: nutrientAmounts || [],
    id,
    nutrientAmountsManualEntry:
      !nutrientAmounts && nutrientAmountsManualEntry
        ? getNutrientAmountsForInput({
            nutrientAmountState: nutrientAmountsManualEntry,
            choPer100g,
            proPer100g,
            fatPer100g,
          })
        : [],
  };
};

export const toEditBrandedFoodInput = (state: BaseFoodFormType): EditBrandedFoodInput | null => {
  const {
    brand,
    nutrientAmounts,
    id,
    usdaFdcId,
    usdaFdcDescription,
    usdaFdcDataType,
    usdaFdcFoodCategoryId,
    name,
    choPer100g,
    proPer100g,
    fatPer100g,
    exchangeTypes,
    isRecipeIngredientOnly,
    excludeFromSuggestions,
    nutrientAmountsManualEntry,
    thumbnailUrl,
    groceryListCategoryId,
    source,
    type,
    locales,
    foodGroups,
  } = state;

  if (type !== "BrandedFood" || !id) {
    return null;
  }

  return {
    food: {
      usdaFdcId,
      usdaFdcDescription,
      usdaFdcDataType,
      usdaFdcFoodCategoryId,
      name,
      choPer100g,
      proPer100g,
      fatPer100g,
      exchangeTypes: exchangeTypes || [],
      isRecipeIngredientOnly,
      excludeFromSuggestions,
      brandId: brand ? brand.id : null,
      thumbnailUrl,
      groceryListCategoryId,
      source,
      locales: locales || [],
      foodGroupIds: foodGroups.flatMap(fg => fg.id),
      mccanceId: null,
      mccanceName: null,
    },
    nutrientAmounts: nutrientAmounts || [],
    nutrientAmountsManualEntry:
      !nutrientAmounts && nutrientAmountsManualEntry
        ? getNutrientAmountsForInput({
            nutrientAmountState: nutrientAmountsManualEntry,
            choPer100g,
            proPer100g,
            fatPer100g,
          })
        : [],
    id,
  };
};

export const toEditFoodServingInputs = (state: BaseFoodFormType): EditFoodServingInput[] | null => {
  const { servings } = state;

  return servings
    .filter(s => !s.isNewServing)
    .reduce<EditFoodServingInput[] | null>((editableServings, srv) => {
      if (!editableServings) {
        return null;
      }

      const {
        id,
        usdaWeightSeq,
        units: { customUnits, unitSuffix, unitPrefix, unit },
        weight,
        isDefault,
        isRecipeServingOnly,
        defaultAmount,
      } = srv;
      if (!id || !defaultAmount || !weight) {
        return null;
      }
      const servingInput: EditFoodServingInput = {
        id,
        serving: {
          usdaWeightSeq,
          units: {
            customUnits,
            unitSuffix,
            unitPrefix,
            unitId: unit?.id || null,
          },
          weight,
          isDefault,
          isRecipeServingOnly,
          defaultAmount,
        },
      };

      return [...editableServings, servingInput];
    }, []);
};

export const toCreateFoodServingsInput = (state: BaseFoodFormType): CreateFoodServingsInput | null => {
  const { id } = state;
  const servings = toFoodServingInputs(state);
  if (!id || !servings) {
    return null;
  }

  return {
    foodId: id,
    servings,
  };
};

const toFoodServingInputs = (state: BaseFoodFormType): FoodServingInput[] | null => {
  const { servings } = state;

  return servings
    .filter(s => s.isNewServing)
    .reduce<FoodServingInput[] | null>((accServings, srv) => {
      if (!accServings) {
        return null;
      }

      const {
        usdaWeightSeq,
        units: { customUnits, unitSuffix, unitPrefix, unit },
        weight,
        isDefault,
        isRecipeServingOnly,
        defaultAmount,
      } = srv;
      if (!defaultAmount || !weight) {
        return null;
      }
      const servingInput: FoodServingInput = {
        usdaWeightSeq,
        units: {
          customUnits,
          unitSuffix,
          unitPrefix,
          unitId: unit?.id || null,
        },
        weight,
        isDefault,
        isRecipeServingOnly,
        defaultAmount,
      };

      return [...accServings, servingInput];
    }, []);
};

interface getNutrientAmountsForInputProps {
  nutrientAmountState: NutrientAmountState;
  choPer100g: number;
  proPer100g: number;
  fatPer100g: number;
}

export const getNutrientAmountsForInput = ({
  nutrientAmountState,
  choPer100g,
  proPer100g,
  fatPer100g,
}: getNutrientAmountsForInputProps): readonly CreateFoodNutrientAmountFormInput[] | null => {
  return FORM_NUTRIENTS.map(nutrient => {
    if (nutrient === "Carbohydrate, by difference") {
      return {
        nutrientName: nutrient,
        amount: choPer100g,
      };
    } else if (nutrient === "Protein") {
      return {
        nutrientName: nutrient,
        amount: proPer100g,
      };
    } else if (nutrient === "Total lipid (fat)") {
      return {
        nutrientName: nutrient,
        amount: fatPer100g,
      };
    }
    const value = inputToNumber(nutrientAmountState[nutrient]) || 0;
    const convertedValue = nutrient.startsWith("Vitamin D (D2 + D3)") ? convertVitaminDToIU(value) : value;
    return {
      nutrientName: nutrient,
      amount: convertedValue,
    };
  }).filter(na => na.amount !== null && na.amount !== 0);
};
