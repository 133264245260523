import { alpha, Color, Paper, useTheme } from "@mui/material";
import { notemealSecondary, notemealTertiary } from "@notemeal/palette";
import { useClientTimezone } from "@notemeal/shared-ui";
import CalendarEventLabel from "apps/web/src/components/Calendar/Week/EventLabel";
import { RenderCalendarEventArgs } from "apps/web/src/components/Calendar/Week/utils";
import { useMealPlanColorsContext } from "../../../contexts/Colors";
import { MealPlanCalendarEvent } from "../types";
import { getNameForMealPlanCalendarEvent } from "../utils";

interface MealPlanCalendarMealTemplateEventProps {
  event: MealPlanCalendarEvent;
  args: RenderCalendarEventArgs;
}

const MealPlanCalendarEventPaper = ({ event, args }: MealPlanCalendarMealTemplateEventProps) => {
  const {
    palette: { grey, teamworksCalendarEvent },
  } = useTheme();
  const { PaperProps, isAbbreviated, eventStyle } = args;
  const clientTimezone = useClientTimezone();
  const { getMealPlanColor, getNewMealPlanColor } = useMealPlanColorsContext();

  const name = getNameForMealPlanCalendarEvent(event);
  let backgroundColor: string;

  if (event.type === "Teamworks") {
    backgroundColor = alpha(teamworksCalendarEvent, 0.8);
  } else if (event.type === "SuggestedMealTemplate") {
    backgroundColor = notemealTertiary;
  } else {
    backgroundColor = event.mealPlanId ? getMealPlanColor(event.mealPlanId) : getNewMealPlanColor();
  }

  return (
    <Paper
      {...PaperProps}
      style={{
        ...PaperProps.style,
        backgroundColor,
      }}
      sx={{
        ...(event.type === "SuggestedMealTemplate" ? getSuggestedMealEventStyle(grey) : {}),
      }}
    >
      <CalendarEventLabel
        style={eventStyle}
        primary={name}
        isAllDay={event.isAllDay}
        isAbbreviated={isAbbreviated}
        start={event.start}
        durationInMinutes={event.durationInMinutes}
        clientTimezone={clientTimezone}
      />
    </Paper>
  );
};

const getSuggestedMealEventStyle = (grey: Color) => {
  return {
    /* Dashed border */
    backgroundImage: `repeating-linear-gradient(0deg, ${grey[500]}, ${grey[500]} 8px, transparent 8px, transparent 18px, ${grey[500]} 18px), repeating-linear-gradient(90deg, ${grey[500]}, ${grey[500]} 8px, transparent 8px, transparent 18px, ${grey[500]} 18px), repeating-linear-gradient(180deg, ${grey[500]}, ${grey[500]} 8px, transparent 8px, transparent 18px, ${grey[500]} 18px), repeating-linear-gradient(270deg, ${grey[500]}, ${grey[500]} 8px, transparent 8px, transparent 18px, ${grey[500]} 18px)`,
    backgroundSize: "2px 100%, 100% 2px, 2px 100% , 100% 2px",
    backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
    backgroundRepeat: "no-repeat",

    /* Pulse Animation */
    "@keyframes animate": {
      "0%": {
        backgroundColor: notemealTertiary,
      },
      "50%": {
        backgroundColor: notemealSecondary,
      },
      "100%": {
        backgroundColor: notemealTertiary,
      },
    },
    animation: `animate 3s infinite`,
  };
};

export default MealPlanCalendarEventPaper;
