import { Dispatch, useEffect, useMemo } from "react";
import { sortByKey } from "@notemeal/utils-sort";
import { IndividualSportState, LinkedPositionState, PendingPosition, SportBuilderAction } from "./utils";
import { TeamworksPositionLinkFragment, PositionWithMappingsFragment, TeamworksPositionFragment } from "../../../../../types";

interface MergedPositionsPayload {
  notemealPositions?: readonly PositionWithMappingsFragment[];
  teamworksPositions: readonly TeamworksPositionFragment[];
  pendingPositions: readonly PendingPosition[];
  previouslyLinkedPositions?: readonly PositionWithMappingsFragment[];
}

const staticEmpty: readonly LinkedPositionState[] = [];
export const staticEmptyPositions: readonly PositionWithMappingsFragment[] = [];

export const useMergePositions = (
  indySportState: IndividualSportState,
  unfilteredTeamorksPositions: readonly TeamworksPositionFragment[],
  teamworksPositionLinks: readonly TeamworksPositionLinkFragment[]
): MergedPositionsPayload => {
  const linkedPositions = indySportState.__type === "Link" ? indySportState.linkedPositions : staticEmpty;
  const potentialNotemealPositions = indySportState.__type === "Link" ? indySportState.selectedSport.positions : staticEmptyPositions;
  const pendingPositions = useMemo(
    () => sortByKey([...indySportState.newPositions, ...linkedPositions], "name"),
    [indySportState.newPositions, linkedPositions]
  );
  const usedPositionTeamworksIds = useMemo(
    () => [...pendingPositions.map(p => p.teamworksId), ...teamworksPositionLinks.map(p => p.teamworksId)],
    [pendingPositions, teamworksPositionLinks]
  );

  const teamworksPositions = useMemo(
    () =>
      sortByKey(
        unfilteredTeamorksPositions.filter(p => !usedPositionTeamworksIds.includes(p.id)),
        "label"
      ),
    [usedPositionTeamworksIds, unfilteredTeamorksPositions]
  );

  const usedPositionsNotemealIds = useMemo(
    () => [...linkedPositions.map(p => p.positionId), ...teamworksPositionLinks.map(p => p.position.id)],
    [linkedPositions, teamworksPositionLinks]
  );

  const notemealPositions = useMemo(
    () =>
      sortByKey(
        potentialNotemealPositions.filter(p => !usedPositionsNotemealIds.includes(p.id)),
        "name"
      ),
    [usedPositionsNotemealIds, potentialNotemealPositions]
  );

  const previouslyLinkedPositions = useMemo(
    () =>
      teamworksPositionLinks.length > 0
        ? sortByKey(
            teamworksPositionLinks.map(p => p.position),
            "name"
          )
        : undefined,
    [teamworksPositionLinks]
  );
  return {
    pendingPositions,
    teamworksPositions,
    notemealPositions,
    previouslyLinkedPositions,
  };
};

interface UseAutoLinkPositionsArgs {
  dispatch: Dispatch<SportBuilderAction>;
  unfilteredTeamorksPositions: readonly TeamworksPositionFragment[];
  unfilteredNotemealPositions: readonly PositionWithMappingsFragment[];
}

export const useAutoLinkPositions = ({ dispatch, unfilteredTeamorksPositions, unfilteredNotemealPositions }: UseAutoLinkPositionsArgs) => {
  useEffect(() => {
    if (unfilteredTeamorksPositions.length > 0 && unfilteredNotemealPositions.length > 0) {
      dispatch({
        type: "AUTO_LINK_POSITIONS",
        payload: {
          teamworksPositions: unfilteredTeamorksPositions,
          notemealPositions: unfilteredNotemealPositions,
        },
      });
    }
  }, [dispatch, unfilteredTeamorksPositions, unfilteredNotemealPositions]);
};
