import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useLocaleContext } from "@notemeal/shared-ui";
import { useReducer, useState } from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";
import TWItemizedTooltip from "../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { CreateMacroMealPlanTemplateInput, GoalTypeFragment, ScheduleWithTeamFragment } from "../../types";
import FoodPreferenceMealPlanForm from "../FoodPreference/MealPlan/Form";
import { foodPreferenceFormToSaveTooltips, foodPreferenceReducer, initialFoodPreferenceState } from "../FoodPreference/MealPlan/reducer";
import Anthropometry from "./MacroProtocol/Anthropometry/Anthropometry";
import SampleDataForm from "./MacroProtocol/Anthropometry/SampleDataForm";
import Goals from "./MacroProtocol/Goals/Goals";
import { convertEditorStateToSaveState, getCanSaveGoalState, getInitialGoalsState, goalsReducer } from "./MacroProtocol/Goals/goalsReducer";
import MacroProtocolForm from "./MacroProtocol/MacroProtocolForm";
import { getBlankMacroProtocolState, macroProtocolReducer } from "./MacroProtocol/reducer/macroProtocolReducer";
import {
    getCanSaveAnthropometryToolTips,
    getCanSaveMacroProtocolToolTips,
    getCanSaveSampleDataToolTips,
} from "./MacroProtocol/utils/macroProtocolUtils";
import MealPlanTemplateNameAndDescription from "./MealPlanTemplateNameAndDescription";
import ScheduleSelector from "./Schedule/ScheduleSelector";
import { makeMeal } from "./Schedule/scheduleUtils";
import { buildCreateOrPublishInput } from "./mealPlanTemplateUtils";

enum Steps {
  Information,
  Goals,
  Anthropometry,
  SampleData,
  MacroProtocol,
  FoodPreference,
}

const stepLabels = ["Information", "Weight Targets", "Anthropometry", "Sample Data", "Macro Protocol", "Food Preferences"];

interface CreateMealPlanTemplateStepperProps {
  open: boolean;
  onClose: () => void;
  createMealPlanTemplate: (input: CreateMacroMealPlanTemplateInput) => void;
  createLoading: boolean;
  goalTypes: readonly GoalTypeFragment[];
  standardSchedules: readonly ScheduleWithTeamFragment[];
  teamSchedules: readonly ScheduleWithTeamFragment[];
}

const CreateMealPlanTemplateStepper = ({
  open,
  onClose,
  createMealPlanTemplate,
  createLoading,
  goalTypes,
  standardSchedules,
  teamSchedules,
}: CreateMealPlanTemplateStepperProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleWithTeamFragment>(standardSchedules[0]);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string | null>(null);
  const { isMetricLocale } = useLocaleContext();

  const [macroProtocolState, macroProtocolDispatch] = useReducer(macroProtocolReducer, getBlankMacroProtocolState(isMetricLocale));
  const [goalsState, goalsDispatch] = useReducer(goalsReducer, getInitialGoalsState(macroProtocolState.calorieBudget.goals));
  const [foodPreferenceState, foodPreferenceDispatch] = useReducer(foodPreferenceReducer, initialFoodPreferenceState({}));

  const getCanSaveTooltipItems = (): string[] => {
    switch (activeStep) {
      case Steps.Information:
        return name === "" ? ["A meal plan template must have a name"] : [];
      case Steps.Goals:
        return getCanSaveGoalState(goalsState);
      case Steps.Anthropometry:
        return getCanSaveAnthropometryToolTips(macroProtocolState.anthropometry);
      case Steps.SampleData:
        return getCanSaveSampleDataToolTips(macroProtocolState.anthropometry);
      case Steps.MacroProtocol:
        return getCanSaveMacroProtocolToolTips(macroProtocolState);
      case Steps.FoodPreference:
        return foodPreferenceFormToSaveTooltips(foodPreferenceState);
      default:
        return [];
    }
  };

  const canSaveTooltips = getCanSaveTooltipItems();

  const firstStep = 0;
  const lastStep = Object.values(Steps)[Object.values(Steps).length - 1];
  const getPrevLabel = () => (activeStep === firstStep ? "Cancel" : "Back");
  const getNextLabel = () => (activeStep === lastStep ? "Finish" : "Next");

  const onPrev = () => {
    if (activeStep === firstStep) {
      onClose();
    } else {
      setActiveStep(activeStep - 1);
    }
  };
  const onNext = () => {
    if (canSaveTooltips.length) {
      return;
    }

    if (activeStep === lastStep) {
      createMealPlanTemplate(
        buildCreateOrPublishInput(
          {
            name,
            description,
            macroProtocolState,
            foodPreferenceFormState: foodPreferenceState,
            meals: selectedSchedule.meals.map(makeMeal),
            activities: selectedSchedule.activities.concat(),
          },
          isMetricLocale
        )
      );

      return;
    }

    if (activeStep === Steps.Goals) {
      const goals = convertEditorStateToSaveState(goalsState);
      macroProtocolDispatch({ type: "OVERWRITE_GOALS", payload: goals });
      const selectedGoal = macroProtocolState.calorieBudget.selectedPreviewGoal;
      if (!(selectedGoal && goals.includes(selectedGoal))) {
        macroProtocolDispatch({ type: "SET_SELECTED_PREVIEW_GOAL", payload: goals[0] });
      }
    }

    setActiveStep(activeStep + 1);
  };

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}>
      <DialogTitle title="Create Meal Plan Template" onClose={onClose} />
      <DialogContent sx={{ width: 1060, minWidth: 1060, height: 720 }}>
        <Stepper
          sx={{ p: 2, mb: 3, justifyContent: "center" }}
          activeStep={activeStep}
          connector={<div />}>
          {stepLabels.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ px: 2 }}>
          {activeStep === Steps.Information && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography>
                A Meal Plan Template is used to create Meal Plans for athletes via filling out a customized form in the mobile app without
                direct intervention by a dietitian. In the following steps, you will have many options to control both what sorts of info is
                collected from the athlete and what the final Meal Plan will be.
              </Typography>
              <Box sx={{ display: "flex" }}>
                <MealPlanTemplateNameAndDescription
                  name={name}
                  onNameChange={setName}
                  description={description}
                  onDescriptionChange={setDescription}
                />
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderRight: 1, color: "grey.400", mx: 2 }} />
                <ScheduleSelector
                  onChange={setSelectedSchedule}
                  selectedSchedule={selectedSchedule}
                  standardSchedules={standardSchedules}
                  teamSchedules={teamSchedules}
                />
              </Box>
            </Box>
          )}
          {activeStep === Steps.Goals && <Goals
            state={goalsState}
            dispatch={goalsDispatch}
            goalTypes={goalTypes} />}
          {activeStep === Steps.Anthropometry && <Anthropometry state={macroProtocolState} dispatch={macroProtocolDispatch} />}
          {activeStep === Steps.SampleData && <SampleDataForm state={macroProtocolState} dispatch={macroProtocolDispatch} />}
          {activeStep === Steps.MacroProtocol && <MacroProtocolForm state={macroProtocolState} dispatch={macroProtocolDispatch} />}
          {activeStep === Steps.FoodPreference && (
            <FoodPreferenceMealPlanForm state={foodPreferenceState} dispatch={foodPreferenceDispatch} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={createLoading}
          onClick={onPrev}>
          {getPrevLabel()}
        </Button>
        <TWItemizedTooltip title="Fix the following before advancing" items={canSaveTooltips}>
          <Button onClick={onNext} disabled={createLoading}>
            {getNextLabel()}
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMealPlanTemplateStepper;
