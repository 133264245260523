import { parseTime, serializeDateTime } from "@notemeal/utils-date-time";
import {
  PlannedMenuMealForImportFragment,
  PlannedMenuMealRowItemForImportFragment,
  PlannedMenuOccurrence,
  ImportPlannedMenuInput,
  DayOfWeek,
  PlannedMenuMealRowForImportFragment,
} from "apps/web/src/types";
import { addDays, differenceInMinutes, format, isFuture } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { getDays } from "../../../MenuBuilder/Builder/utils";
import { buildAdvancedSelectionInput } from "../../../Tags/reducers/advancedSelectionReducers";
import { ShareMealMenuState } from "../../types";
import { getLastOrderTimeBeforeEndInMinutes } from "../../utils";
import { ImportPlannedMenuMealType, ImportPlannedMenuWeeksType } from "./ImportMenuSchema";
import { PlannedMenuWeekForImport } from "./ImportPlannedMenuContext";
import { getOrderRateLimitInput } from "../../hooks";

export const buildImportPlannedMenuInput = (
  form: ImportPlannedMenuWeeksType,
  weeks: readonly PlannedMenuWeekForImport[],
  plannedMenu: { occurrence: PlannedMenuOccurrence; timezone: string },
  shareState: ShareMealMenuState
): ImportPlannedMenuInput => {
  const days = getDays(plannedMenu.occurrence);
  return {
    teamIds: shareState.__typename === "Teams" ? shareState.teams.map(({ id }) => id) : null,
    advancedSelectionInput: shareState.__typename === "Tags" ? buildAdvancedSelectionInput(shareState.advancedSelectionState) : null,
    meals: weeks.flatMap(week => {
      const formForWeek = form.weeks.find(w => w.id === week.id);
      const meals = formForWeek?.meals ?? [];
      return getMeals(days, week.meals, meals, plannedMenu, week.weekStartDate);
    }),
  };
};

const getMeals = (
  days: DayOfWeek[],
  meals: readonly PlannedMenuMealForImportFragment[],
  mealForms: ImportPlannedMenuMealType[],
  plannedMenu: { occurrence: PlannedMenuOccurrence; timezone: string },
  weekStartDate: Date
): ImportPlannedMenuInput["meals"] =>
  days.flatMap((dayName, index) => {
    const dayOfWeek = index + 1;
    return (
      meals
        .filter(meal => {
          const mealForm = mealForms.find(form => form.id === meal.id);

          const startDateTime = getStartDateTime(meal.startTime, weekStartDate, dayOfWeek, plannedMenu.timezone);
          // filter out meals that have already started or are missing a form
          return isFuture(startDateTime) && mealForm;
        })
        .map(meal => {
          const durationInMinutes = differenceInMinutes(parseTime(meal.endTime), parseTime(meal.startTime));
          const startDateTime = getStartDateTime(meal.startTime, weekStartDate, dayOfWeek, plannedMenu.timezone);
          const mealForm = mealForms.find(form => form.id === meal.id)!;
          const {
            notificationSentBeforeOrderDueInMinutes,
            lastOrderDueDaysBefore,
            lastOrderDueTime,
            userOrderLimit,
            prepTimeInMinutes,
            orderRateLimit,
          } = mealForm;
          return {
            name: meal.name,
            timezone: plannedMenu.timezone,
            startDateTime: serializeDateTime(startDateTime),
            durationInMinutes,
            prepTimeInMinutes: prepTimeInMinutes ?? 0,
            lastOrderTimeBeforeEndInMinutes: getLastOrderTimeBeforeEndInMinutes({
              startTime: meal.startTime,
              durationInMinutes,
              lastOrderDaysBefore: lastOrderDueDaysBefore,
              lastOrderTime: lastOrderDueTime,
            }),
            type: meal.type,
            notificationSentBeforeOrderDueInMinutes,
            orderRateLimit: getOrderRateLimitInput(orderRateLimit),
            userOrderLimit,
            isHubCheckInEnabled: mealForm.isHubCheckInEnabled,
            diningStations: getDiningStationsFromMealRows(meal.plannedMenuMealRows).map(station => {
              return {
                name: station.name,
                position: station.position,
                menuItems: getMenuItems(station.name, station.items, dayName, mealForm),
              };
            }),
            // assign the theme based on the corresponding day of the week (0=Monday...6=Sunday)
            theme: mealForm.themes[index],
          };
        })
        // don't publish a meal if it has no menu items in it
        .filter(meal => meal.diningStations.some(station => station.menuItems.length > 0))
    );
  });

const getStartDateTime = (startTime: string, weekStartDate: Date, dayOfWeek: number, menuTimezone: string) => {
  // create a Date at the meal's date and time, then convert to the menu timezone
  const formattedTime = `${format(weekStartDate, "yyyy-MM-dd")}T${startTime}`;
  const dateAtTime = zonedTimeToUtc(formattedTime, menuTimezone);
  // adjust to the day of the week, and our weeks start on Monday
  return addDays(dateAtTime, dayOfWeek - 1);
  // adjust to the day of the week, and our weeks start on Monday
};

const getMenuItems = (
  diningStationName: string,
  mealRowItems: readonly PlannedMenuMealRowItemForImportFragment[],
  dayOfWeek: DayOfWeek,
  mealForm: ImportPlannedMenuMealType
) =>
  mealRowItems
    .filter(mi => mi.dayOfWeek === dayOfWeek)
    .map(item => {
      const { allowSpecialRequests, availableForOrder } = mealForm.diningStations.find(ds => ds.name === diningStationName)!;
      return {
        menuItemId: item.menuItem.id,
        position: item.position,
        availableForOrder: availableForOrder === "some" ? item.menuItem.defaultAvailableForOrder : availableForOrder === "all",
        allowSpecialRequests: allowSpecialRequests === "some" ? item.menuItem.defaultAllowSpecialRequests : allowSpecialRequests === "all",
        maxAmount: null,
      };
    });

type ReducedMealRows = {
  id: string;
  name: string;
  position: number;
  items: readonly PlannedMenuMealRowItemForImportFragment[];
};

export const getDiningStationsFromMealRows = (plannedMenuMealRows: readonly PlannedMenuMealRowForImportFragment[]) => {
  return plannedMenuMealRows.reduce<ReducedMealRows[]>((stations, current, index) => {
    const existing = stations.find(station => station.name === current.diningStationName);
    if (existing) {
      return [
        ...stations.filter(station => station.name !== current.diningStationName),
        {
          ...existing,
          items: [
            ...existing.items,
            ...current.plannedMenuMealRowItems.map(item => {
              return { ...item, position: item.position + existing.items.length - 1 };
            }),
          ],
        },
      ];
    } else {
      return [
        ...stations,
        {
          id: current.id,
          name: current.diningStationName,
          position: index, //using index here to handle skips since we are deduplicating
          items: current.plannedMenuMealRowItems,
        },
      ];
    }
  }, []);
};
