import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

import { sortByKey } from "@notemeal/utils-sort";
import { MenuDialogAction } from "../../reducer";
import { RestaurantMenuLinkState } from "../types";
import PlateCard from "./Card";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(2, 1),
      display: "flex",
      alignItems: "center",
    },
    spacer: {
      flexGrow: 1,
    },
    cards: {
      display: "flex",
    },
    card: {
      width: `calc(33% - ${theme.spacing(4)})`,
      margin: theme.spacing(2),
    },
  })
);

interface RestaurantMenuLinkPlatesFormProps {
  restaurantMenuLink: RestaurantMenuLinkState;
  dispatch: (action: MenuDialogAction) => void;
}

const RestaurantMenuLinkPlatesForm = ({ restaurantMenuLink, dispatch }: RestaurantMenuLinkPlatesFormProps) => {
  const classes = useStyles();

  return (
    <div className={classes.cards}>
      {sortByKey(restaurantMenuLink.plates, "position").map(plate => (
        <PlateCard
          key={plate.id}
          allowPlateOrders={restaurantMenuLink.allowPlateOrders}
          restaurantMenuLinkPlate={plate}
          restaurantMenu={restaurantMenuLink.restaurantMenu}
          className={classes.card}
          onRemove={() =>
            dispatch({
              type: "RestaurantMenuLink_RemovePlate",
              payload: {
                restaurantMenuLinkId: restaurantMenuLink.id,
                restaurantMenuLinkPlateId: plate.id,
              },
            })
          }
          onChangeBulkOrderAmount={bulkOrderAmountInput =>
            dispatch({
              type: "RestaurantMenuLink_EditPlateAmount",
              payload: {
                restaurantMenuLinkId: restaurantMenuLink.id,
                restaurantMenuLinkPlateId: plate.id,
                bulkOrderAmountInput,
              },
            })
          }
          onChangeItems={(items, editedItemIds) =>
            dispatch({
              type: "RestaurantMenuLink_EditPlateItems",
              payload: {
                restaurantMenuLinkId: restaurantMenuLink.id,
                restaurantMenuLinkPlateId: plate.id,
                editedItemIds,
                items,
              },
            })
          }
          onSetDefault={checkboxValue =>
            dispatch({
              type: "RestaurantMenuLink_SetDefaultPlate",
              payload: {
                restaurantMenuLinkId: restaurantMenuLink.id,
                restaurantMenuLinkPlateId: plate.id,
                restaurantMenuLinkPlateDefaultValue: checkboxValue,
              },
            })
          }
        />
      ))}
    </div>
  );
};

export default RestaurantMenuLinkPlatesForm;
