import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { Loading, newId, ScheduleContainer } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useState } from "react";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import {
  ScheduleModalEditorActivityState,
  ScheduleModalEditorMealState,
  ScheduleModalEditorState,
} from "../../../reducers/ScheduleModalEditor";
import { scheduleModalEditorStateToEditInput } from "../../../utils/ScheduleModalEditor";
import ActivityItem from "./ActivityItem";
import Confirmation from "./Confirmation";
import MealItem from "./MealItem";
import { scheduleFormToSaveTooltips, useScheduleModalEditorContext } from "./utils";

interface ScheduleModalEditorProps {
  open: boolean;
  onClose: () => void;
  onSave: (state: ScheduleModalEditorState) => void;
  title: "Edit Schedule" | "New Schedule";
  numberMealPlans: number;
  saveText?: string;
  confirmTitle?: string;
  showScheduleName?: boolean;
}

const ScheduleModalEditor = ({
  open,
  onClose,
  onSave,
  title,
  numberMealPlans,
  confirmTitle,
  saveText = "Save",
  showScheduleName = true,
}: ScheduleModalEditorProps) => {
  const { state, onAddMeal, onAddActivity, onChangeScheduleName } = useScheduleModalEditorContext();
  const [saving, setSaving] = useState(false);
  const [confirmationMessages, setConfirmationMessages] = useState<string[]>([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const canSaveTooltips = scheduleFormToSaveTooltips(state, title === "Edit Schedule" && !state.canEditScheduleName);
  const displayConfirmModal = () => {
    if (canSaveTooltips.length) {
      return;
    }
    const editInput = scheduleModalEditorStateToEditInput(state);
    const messages = [
      editInput.addMeals.length > 0 ? `Adds ${editInput.addMeals.length} meal(s)` : undefined,
      editInput.addActivities.length > 0 ? `Adds ${editInput.addActivities.length} activity(s)` : undefined,
      editInput.removeMeals.length > 0 ? `Removes ${editInput.removeMeals.length} meal(s)` : undefined,
      editInput.removeActivities.length > 0 ? `Removes ${editInput.removeActivities.length} activity(s)` : undefined,
      editInput.editMeals.length > 0 ? `Edits ${editInput.editMeals.length} meal(s)` : undefined,
      editInput.editActivities.length > 0 ? `Edits ${editInput.editActivities.length} activity(s)` : undefined,
    ].filter(m => m !== undefined) as string[];
    setConfirmationMessages(messages);
    setConfirmationModalOpen(true);
  };
  const handleSave = () => {
    setSaving(true);
    onSave(state);
  };

  const tryToSave = () => {
    if (canSaveTooltips.length > 0) {
      return;
    }
    if (numberMealPlans > 0 && title === "Edit Schedule") {
      displayConfirmModal();
    } else {
      handleSave();
    }
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={saving ? () => {} : onClose}>
      <DialogTitle title={title} onClose={onClose} />
      <DialogContent sx={{ overflowY: "hidden", display: "flex", flexDirection: "column", gap: 2, pt: 0 }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {showScheduleName && (
            <TextField
              sx={{ width: 300 }}
              required
              label="Schedule name"
              value={state.scheduleName}
              onChange={e =>
                onChangeScheduleName({
                  value: e.target.value,
                  mealId: null,
                  activityId: null,
                })
              }
              margin="dense"
              disabled={!state.canEditScheduleName}
            />
          )}
          <Box sx={{ width: "100%", display: "flex", gap: 2, justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              startIcon={<RestaurantIcon />}
              disabled={saving}
              onClick={() => onAddMeal({ mealId: newId(), activityId: null })}
            >
              Add Meal
            </Button>
            <Button
              variant="outlined"
              startIcon={<DirectionsRunIcon />}
              disabled={saving}
              onClick={() => onAddActivity({ mealId: null, activityId: newId() })}
            >
              Add Activity
            </Button>
          </Box>
        </Box>
        <Box sx={{ overflowY: "scroll", height: 400 }}>
          {saving ? (
            <Loading progressSize="md" />
          ) : (
            <ScheduleContainer<ScheduleModalEditorMealState, ScheduleModalEditorActivityState>
              meals={state.meals}
              mealToStart={m => m.start}
              activities={state.activities}
              activityToStart={a => a.start}
              renderMeal={meal => <MealItem meal={meal} />}
              renderActivity={activity => <ActivityItem activity={activity} />}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={saving}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
          <Button onClick={tryToSave} disabled={saving || canSaveTooltips.length > 0}>
            {saveText}
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
      {confirmationModalOpen && (
        <Confirmation
          onClose={() => {
            setConfirmationMessages([]);
            setConfirmationModalOpen(false);
          }}
          open={confirmationModalOpen}
          onConfirm={handleSave}
          messages={confirmationMessages}
          numberMealPlans={numberMealPlans}
          title={confirmTitle}
        />
      )}
    </Dialog>
  );
};

export default ScheduleModalEditor;
