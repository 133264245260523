import { Button, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading, formatHeight, useLocaleContext } from "@notemeal/shared-ui";
import { athleteBirthDateToAge, roundToHundredthsFloor } from "@notemeal/shared-utils-macro-protocol";
import SyncedAthleteEditModal from "apps/web/src/components/Athlete/EditModal/SyncedAthleteEditModal";
import { useState } from "react";
import AthleteEditModal from "../../../components/Athlete/EditModal";
import { AthleteSummaryAthleteFragment, TeamWithSportFragment, useMyOrgFullySyncedQuery } from "../../../types";
import NamedTagChipList from "../../Tags/NamedTagChipList";

const useStyles = (isArchived: boolean) =>
  makeStyles(({ spacing }: Theme) =>
    createStyles({
      header: {
        display: "flex",
        justifyContent: "space-between",
      },
      root: {
        display: "grid",
        gridTemplateColumns: `1fr 1fr 1fr`,
        gridTemplateRows: "1fr 1fr 1fr",
        flexGrow: 1,
        gap: spacing(2),
        alignItems: "center",
        justifyItems: "start",
      },
      age: {
        gridRowStart: 1,
        gridColumnStart: 1,
      },
      height: {
        gridRowStart: 1,
        gridColumnStart: 2,
      },
      weight: {
        gridRowStart: 1,
        gridColumnStart: 3,
      },
      team: {
        gridRowStart: 2,
        gridColumnStart: 1,
      },
      position: {
        gridRowStart: 2,
        gridColumnStart: 2,
      },
      jerseyNumber: {
        gridRowStart: 2,
        gridColumnStart: 3,
      },
      tags: {
        gridRowStart: 3,
        gridColumnStart: 1,
        gridColumnEnd: `span ${isArchived ? 2 : 3}`,
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: -spacing(1),
      },
      tagLabel: {
        marginRight: spacing(1),
        marginTop: spacing(1),
      },
      tagChip: {
        marginTop: spacing(1),
      },
      archiveStatus: {
        gridRowStart: 3,
        gridColumnStart: 3,
        textAlign: "right",
        justifySelf: "end",
      },
    })
  );

interface AthleteSummaryGeneralProps {
  athlete: AthleteSummaryAthleteFragment;
  teams: TeamWithSportFragment[];
}

export const AthleteSummaryGeneral = ({ athlete, teams }: AthleteSummaryGeneralProps) => {
  const classes = useStyles(athlete.isArchived)();
  const [editOpen, setEditOpen] = useState(false);
  const { isMetricLocale } = useLocaleContext();
  const { data: orgData, loading: orgLoading } = useMyOrgFullySyncedQuery();

  const anthro = athlete.mostRecentAnthropometryEntry;

  const metricHeightDisplay = anthro && anthro.heightInCm ? `${roundToHundredthsFloor(anthro.heightInCm)}cm` : "N/A";
  const imperialHeightDisplay = anthro && anthro.height ? formatHeight(roundToHundredthsFloor(anthro.height)) : "N/A";
  const height = isMetricLocale ? metricHeightDisplay : imperialHeightDisplay;

  const heightIn = anthro && anthro.height ? roundToHundredthsFloor(anthro.height) : "N/A";

  const position = athlete.position ? athlete.position.name : "N/A";
  const jersey = athlete.jerseyNumber ? athlete.jerseyNumber : "N/A";
  const team = athlete.team.name;

  const metricWeightDisplay = anthro ? `${roundToHundredthsFloor(anthro.weightInKg)}kg` : "N/A";
  const imperialWeightDisplay = anthro ? `${roundToHundredthsFloor(anthro.weight)}lbs` : "N/A";
  const weight = isMetricLocale ? metricWeightDisplay : imperialWeightDisplay;

  if (orgLoading || !orgData) {
    return <Loading />;
  }
  const isNotemealLinked = orgData.myOrg.isNotemealLinked;
  return (
    <>
      <div className={classes.header}>
        <Typography variant="h3">General</Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setEditOpen(true)}>
          Edit
        </Button>
      </div>
      <div className={classes.root}>
        <div className={classes.age}>
          <Typography variant="body1">
            <b>Age:</b> {athlete.birthDate ? athleteBirthDateToAge(athlete.birthDate) : "N/A"}
          </Typography>
        </div>
        <div className={classes.height}>
          <Typography variant="body1">
            <b>Height:</b> {height} {!isMetricLocale && <em>({heightIn}")</em>}
          </Typography>
        </div>
        <div className={classes.weight}>
          <Typography variant="body1">
            <b>Weight:</b> {weight}
          </Typography>
        </div>
        <div className={classes.team}>
          <Typography variant="body1">
            <b>Team:</b> {team}
          </Typography>
        </div>
        <div className={classes.position}>
          <Typography variant="body1">
            <b>Position:</b> {position}
          </Typography>
        </div>
        <div className={classes.jerseyNumber}>
          <Typography variant="body1">
            <b>Jersey:</b> {jersey}
          </Typography>
        </div>
        <div className={classes.tags}>
          <>
            <Typography variant="body1" className={classes.tagLabel}>
              <b>Tags:</b> {athlete.allOrderedNamedTags.length === 0 ? "no tags assigned" : null}
            </Typography>
            {athlete.allOrderedNamedTags.length !== 0 && (
              <NamedTagChipList
                namedTags={athlete.allOrderedNamedTags}
                namedTagsCount={athlete.allOrderedNamedTags.length}
                hideAfter={athlete.allOrderedNamedTags.length}
                chipClassName={classes.tagChip}
              />
            )}
          </>
        </div>
        {athlete.isArchived && (
          <div className={classes.archiveStatus}>
            <Typography variant="subtitle1">
              <em>Archived</em>
            </Typography>
          </div>
        )}
      </div>
      {editOpen &&
        (isNotemealLinked && !athlete.isProfileNotemealOnly ? (
          <SyncedAthleteEditModal
            athlete={athlete}
            teams={teams}
            open={editOpen}
            onClose={() => setEditOpen(false)} />
        ) : (
          <AthleteEditModal
            teams={teams}
            athlete={athlete}
            open={editOpen}
            onClose={() => setEditOpen(false)}
            isNotemealLinked={orgData.myOrg.isNotemealLinked}
          />
        ))}
    </>
  );
};
