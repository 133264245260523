import { DateCalendar, PickersDay } from "@mui/x-date-pickers";
import { serializeDate } from "@notemeal/utils-date-time";
import { addDays } from "date-fns";
import React from "react";

interface MultiSelectCalendarProps {
  dates: string[];
  onChange: (dates: string[]) => void;
  minDate?: Date;
  maxDate?: Date;
}

const MultiSelectCalendar = ({ dates, onChange, minDate, maxDate }: MultiSelectCalendarProps) => {
  const calendarMinDate = minDate || addDays(new Date(), -365);
  const calendarMaxDate = maxDate || addDays(new Date(), 365);

  const onChangeDate = (date: Date | null) => {
    if (date) {
      const isoDate = serializeDate(date);
      onChange(dates.includes(isoDate) ? dates.filter(d => d !== isoDate) : [...dates, isoDate]);
    }
  };

  return (
    <DateCalendar
      showDaysOutsideCurrentMonth
      disableHighlightToday
      views={["day"]}
      onChange={onChangeDate}
      slots={{ day: props => <PickersDay {...props} selected={dates.includes(serializeDate(props.day))} /> }}
      minDate={calendarMinDate}
      maxDate={calendarMaxDate}
    />
  );
};

export default MultiSelectCalendar;
