import { LinearProgress, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import { AutomationStrategy } from "apps/web/src/components/MealPlan/CopyOptionsForm/utils";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { getNavOrgAthleteMealPlans } from "apps/web/src/pages/Auth/Org/Athlete/AthletePaths";
import { MealPlanType } from "apps/web/src/types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    finishDiv: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    progress: {
      width: "80%",
      marginTop: theme.spacing(3),
    },
    copyDiv: {
      display: "flex",
      alignItems: "center",
    },
    copyTypography: {
      marginRight: theme.spacing(2),
    },
  })
);

export type FinalCopyStep = "Copying" | "Calculating" | "Distributing" | "Filtering";
interface FinishCopyFormProps {
  activeStep: FinalCopyStep;
  setActiveStep: (step: FinalCopyStep) => void;
  mealPlanType: MealPlanType;
  athleteIds: string[];
  automationStrategy: AutomationStrategy;
  mealPlanProgressValue: number;
  mealPlanProgressTarget: number;
  onClose: () => void;
}

export const FinishCopyForm = ({
  activeStep,
  setActiveStep,
  mealPlanType,
  automationStrategy,
  athleteIds,
  mealPlanProgressValue,
  mealPlanProgressTarget,
  onClose,
}: FinishCopyFormProps) => {
  const classes = useStyles();
  const { setMessage } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (mealPlanProgressTarget > 0) {
      if (mealPlanProgressValue === mealPlanProgressTarget) {
        const timer = setTimeout(() => {
          onClose();
          setMessage("success", `Succesfully created ${athleteIds.length} meal plans via copy`);
          if (athleteIds.length === 1) {
            navigate(getNavOrgAthleteMealPlans(athleteIds[0]));
          }
        }, 500);
        return () => clearTimeout(timer);
      } else if (mealPlanProgressValue > 0) {
        const percent = mealPlanProgressValue / mealPlanProgressTarget;
        if (mealPlanType === "exchange") {
          if (percent < 1 / 3) {
            setActiveStep("Calculating");
          } else if (percent < 2 / 3) {
            setActiveStep("Distributing");
          } else {
            setActiveStep("Filtering");
          }
        } else {
          setActiveStep("Distributing");
        }
      }
    }
    return () => {};
  }, [athleteIds, mealPlanProgressValue, mealPlanProgressTarget, setMessage, activeStep, setActiveStep, mealPlanType, onClose, navigate]);

  const renderCopyComponent = () => {
    switch (activeStep) {
      case "Copying":
        return (
          <div className={classes.copyDiv}>
            <Typography variant="h3" className={classes.copyTypography}>
              Copying Meal Plans to Athletes
            </Typography>
            <Loading progressSize="sm" />
          </div>
        );
      case "Calculating":
        return <Typography variant="h3">Auto-Calculating Exchange Targets for each Athlete</Typography>;
      case "Distributing":
        return <Typography variant="h3">Auto-Distributing {mealPlanType === "exchange" ? "Exchanges" : "Macros"} on Meal Plans</Typography>;
      case "Filtering":
        return <Typography variant="h3">Filtering Exchange Pick Lists using Athlete Food Preferences</Typography>;
    }
  };

  return (
    <>
      {automationStrategy !== "COPY" ? (
        <div className={classes.finishDiv}>
          {renderCopyComponent()}
          <LinearProgress
            variant="determinate"
            className={classes.progress}
            value={(mealPlanProgressValue / mealPlanProgressTarget) * 100}
          />
        </div>
      ) : (
        <Loading progressSize="md" />
      )}
    </>
  );
};
