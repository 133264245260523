import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useLocaleContext } from "@notemeal/shared-ui";
import {
    SexType,
    getLeanBodyMass,
    measurementConversionToImperial,
    roundToHundredthsFloor,
    sexToText,
} from "@notemeal/shared-utils-macro-protocol";
import { MealPlanTemplateGoalState } from "../Goals/goalsReducer";
import { AnthropometryState } from "../reducer/anthropometryReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalContainer: {
      display: "flex",
      flexDirection: "column",
    },
    horizontalContainer: {
      display: "flex",
      alignItems: "center",
    },
    editButton: {
      marginLeft: theme.spacing(),
    },
  })
);

interface SampleDataSummaryProps {
  state: AnthropometryState;
  sampleGoal?: MealPlanTemplateGoalState | null;
  usePrefix: boolean;
}

interface SampleSummary {
  ageText: string | null;
  sexText: string;
  heightText: string | null;
  weightText: string;
  percentBodyFatText: string | null;
  leanBodyMassText: string | null;
  goalText: string | null;
}

interface SampleStateInput {
  sampleAge: number | null;
  height: number | null;
  percentBodyFat: number | null;
  sampleSex: SexType;
  weight: number;
  sampleGoal: MealPlanTemplateGoalState | null;
}

const getSampleSummaryTextFromValues = (
  { sampleAge, height, percentBodyFat, sampleSex, weight, sampleGoal }: SampleStateInput,
  usePrefix: boolean,
  __typename: AnthropometryState["__typename"],
  isMetricLocale: boolean
): SampleSummary => {
  const measurementSystemHeight = isMetricLocale ? height : measurementConversionToImperial(true, height, "length");
  const measurementSystemWeight = isMetricLocale ? weight : measurementConversionToImperial(true, weight, "weight")!;

  const measurementSystemHeightUnit = isMetricLocale ? " cm" : " in";
  const measurementSystemWeightUnit = isMetricLocale ? " kg" : " lb";

  const samplePrefix = usePrefix ? "Sample " : "";
  const prefix = usePrefix ? (__typename === "FormFields" ? "Sample " : "Default ") : "";
  const ageText = sampleAge !== null ? `${samplePrefix}Age: ${sampleAge} yrs` : null;
  const sexText = `${samplePrefix}Sex: ${sexToText(sampleSex)}`;
  const heightText =
    measurementSystemHeight !== null
      ? `${prefix}Height: ${roundToHundredthsFloor(measurementSystemHeight)} ${measurementSystemHeightUnit}`
      : null;
  const weightText = `${prefix}Weight: ${roundToHundredthsFloor(measurementSystemWeight)} ${measurementSystemWeightUnit}`;
  const percentBodyFatText = percentBodyFat !== null ? `${prefix}Percent Body Fat: ${percentBodyFat}%` : null;
  const leanBodyMassText =
    percentBodyFat !== null
      ? `${prefix}Lean Body Mass: ${roundToHundredthsFloor(
          getLeanBodyMass(measurementSystemWeight, percentBodyFat)!
        )} ${measurementSystemWeightUnit}`
      : null;
  const goalText = sampleGoal ? `${prefix}Goal: ${sampleGoal.name}` : null;

  return {
    ageText,
    sexText,
    heightText,
    weightText,
    percentBodyFatText,
    leanBodyMassText,
    goalText,
  };
};

const getAnthroSummaryText = (
  state: AnthropometryState,
  sampleGoal: MealPlanTemplateGoalState | null,
  usePrefix: boolean,
  isMetricLocale: boolean
): SampleSummary => {
  const { __typename, sampleAge, sampleSex } = state;
  if (__typename === "FormFields") {
    const { formFields, sampleHeight: height, sampleWeight: weight, samplePercentBodyFat: percentBodyFat } = state;

    return getSampleSummaryTextFromValues(
      {
        sampleAge,
        sampleSex,
        height: formFields.includes("height") ? height : null,
        weight,
        percentBodyFat: formFields.includes("percentBodyFat") ? percentBodyFat : null,
        sampleGoal,
      },
      usePrefix,
      __typename,
      isMetricLocale
    );
  } else {
    const { height, weight, percentBodyFat } = state;

    return getSampleSummaryTextFromValues(
      {
        sampleAge,
        sampleSex,
        height,
        weight,
        percentBodyFat,
        sampleGoal,
      },
      usePrefix,
      __typename,
      isMetricLocale
    );
  }
};

const SampleDataSummary = ({ state, usePrefix, sampleGoal = null }: SampleDataSummaryProps) => {
  const classes = useStyles();
  const { isMetricLocale } = useLocaleContext();
  const { ageText, sexText, heightText, weightText, percentBodyFatText, leanBodyMassText, goalText } = getAnthroSummaryText(
    state,
    sampleGoal,
    usePrefix,
    isMetricLocale
  );
  return (
    <div className={classes.verticalContainer}>
      <div className={classes.verticalContainer}>
        <Typography>{ageText}</Typography>
        <Typography>{sexText}</Typography>
        <Typography>{heightText}</Typography>
        <Typography>{weightText}</Typography>
        <Typography>{percentBodyFatText}</Typography>
        <Typography>{leanBodyMassText}</Typography>
        <Typography>{goalText}</Typography>
      </div>
    </div>
  );
};

export default SampleDataSummary;
