import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogContentTextProps } from "@mui/material";
import React, { ReactNode } from "react";
import DialogTitle from "../DialogTitle";

interface InformationDialogProps {
  title: string;
  message: string | ReactNode;
  open: boolean;
  onClose: () => void;
  buttonLabel?: string;
  variant?: ButtonProps["variant"];
  messageSx?: DialogContentTextProps["sx"];
}

export const InformationDialog = ({
  title,
  message,
  open,
  onClose = () => {},
  buttonLabel = "Okay",
  variant = "contained",
  messageSx,
}: InformationDialogProps) => (
  <Dialog
    maxWidth="xs"
    fullWidth
    open={open}
    onClose={(_: Object, reason: string) => {
      if (reason !== "backdropClick") {
        onClose();
      }
    }}
  >
    <DialogTitle title={title} onClose={onClose} />
    <DialogContent>
      <DialogContentText sx={messageSx}>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        autoFocus
        onClick={onClose}
        variant={variant}>
        {buttonLabel}
      </Button>
    </DialogActions>
  </Dialog>
);
