import React from "react";
import { useHistory } from "react-router-dom";

export const useBrowserBackAndRefreshWarning = () => {
  const [browserBackAndRefreshWarningEnabled, setBrowserBackAndRefreshWarningEnabled] = React.useState(false);
  const history = useHistory();

  const refreshWarning = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };

  React.useEffect(() => {
    if (browserBackAndRefreshWarningEnabled) {
      window.addEventListener("beforeunload", refreshWarning);
      const unblock = history.block("Discard Unsaved Changes? All unsaved changes will be lost.");

      return () => {
        window.removeEventListener("beforeunload", refreshWarning);
        unblock();
      };
    }
    return;
  }, [browserBackAndRefreshWarningEnabled, history]);

  return { setBrowserBackAndRefreshWarningEnabled };
};
