import { TimelineMealModalIntent } from "@notemeal/shared-ui";

interface getInitModalTabProps {
  hasOrderableMealMenu: boolean;
  intent: TimelineMealModalIntent | null;
}

export const getInitModalTab = ({ hasOrderableMealMenu, intent }: getInitModalTabProps) => {
  if (intent && intent.type === "Comments") {
    return COMMENT_TAB;
  } else if (hasOrderableMealMenu) {
    return ORDER_TAB;
  } else {
    return LOG_TAB;
  }
};

//Tabs

export const ORDER_CLOSED_TAB = "Ordering Closed";
export const ORDER_TAB = "Order";
export const LOG_TAB = "Log";
export const COMMENT_TAB = "Comment";

export type TimelineMealTab = typeof ORDER_TAB | typeof LOG_TAB | typeof COMMENT_TAB | typeof ORDER_CLOSED_TAB;

const MENU_LOG_TAB = "menu";
const FOOD_LOG_TAB = "food";

export type LogType = typeof MENU_LOG_TAB | typeof FOOD_LOG_TAB;
