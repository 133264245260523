import { Button, Dialog, DialogContent, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import { EditOrgTeamworksSkippedProfile } from "../../../../../types";
import { useAllTeamworksOrgData } from "../useAllTeamworksOrgData";
import OrgDialogContent from "./DialogContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingFooter: {
      display: "flex",
      paddingBottom: theme.spacing(4),
    },
    cancelLoadingButton: {
      marginLeft: theme.spacing(4),
    },
  })
);

export interface TeamworksOrgLinkDialogProps {
  open: boolean;
  onClose: () => void;
  teamworksOrgId: number;
  orgId: string;
  onSuccess: (skippedProfiles: readonly EditOrgTeamworksSkippedProfile[]) => void;
}

const TeamworksOrgLinkDialog = ({ open, onClose, teamworksOrgId, orgId, onSuccess }: TeamworksOrgLinkDialogProps) => {
  const classes = useStyles();
  const result = useAllTeamworksOrgData({
    orgId,
    teamworksOrgId,
  });

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}>
      {result.__type === "loading" ? (
        <DialogContent className={classes.content}>
          <Loading progressSize="lg" />
          <div className={classes.loadingFooter}>
            <Typography variant="h3">Loading Teamworks / Nutrition Org Data, this could take up to a minute.</Typography>
            <Button className={classes.cancelLoadingButton} onClick={onClose}>
              Cancel
            </Button>
          </div>
        </DialogContent>
      ) : (
        <OrgDialogContent
          org={result.org}
          sports={result.sports}
          teamworksOrg={result.teamworksOrg}
          teamworksUserTypes={result.teamworksUserTypes}
          teamworksAthleteStatuses={result.teamworksAthleteStatuses}
          allTeamworksProfiles={result.allTeamworksProfiles}
          teamworksPositionLinks={result.teamworksPositionLinks}
          teamworksPositions={result.teamworksPositions}
          disabledProfileIds={result.disabledProfileIds}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
    </Dialog>
  );
};

export default TeamworksOrgLinkDialog;
