import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Card, CardContent, CardHeader, CardMedia, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading, getServingAmountsMacros, round, useNutrientAmountGroups } from "@notemeal/shared-ui";
import { roundMacros } from "@notemeal/shared-utils-macro-protocol";
import classnames from "classnames";
import { PrintableNutrientInfoMenuItemFragment } from "../../../types";
import { getMenuItemPrintWarnings } from "./utils";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    spacer: {
      height: ".5in",
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      paddingBosttom: spacing(),
    },
    cardHeader: {
      paddingBottom: 0,
    },
    topRow: {
      display: "flex",
    },
    imageCard: {
      minWidth: "calc(100% / 3)",
      maxWidth: "calc(100% / 3)",
      display: "flex",
      margin: spacing(0.25),
    },
    imageCardWithoutImage: {
      backgroundColor: grey[200],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    noImageIcon: {
      height: 50,
      width: 50,
    },
    nutrientsDiv: {
      minWidth: 0,
      flexGrow: 1,
      display: "grid",
      gridTemplateRows: "1fr 1fr",
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    nutrientCard: {
      textAlign: "center",
      margin: spacing(0.25),
    },
    nutrientCardHeader: {
      padding: spacing(1, 1, 0),
    },
    nutrientCardContent: {
      padding: spacing(),
      "&:last-child": {
        paddingBottom: spacing(),
      },
    },
    notemealLogo: {
      height: 36,
    },
    macrosDiv: {
      display: "flex",
    },
    macrosCard: {
      flex: 1,
      textAlign: "center",
      margin: spacing(0.25),
    },
  })
);

interface MenuItemPrintableNutrientInfoProps {
  menuItem: PrintableNutrientInfoMenuItemFragment;
}

const MenuItemPrintableNutrientInfo = ({ menuItem }: MenuItemPrintableNutrientInfoProps) => {
  const classes = useStyles();
  const { loading, allNutrientGroups } = useNutrientAmountGroups({
    servingAmounts: menuItem.servingAmounts,
    age: 21,
    sex: "male",
    kcal: 4000,
  });
  const { carbs, minerals } = allNutrientGroups;

  const macros = roundMacros(getServingAmountsMacros(menuItem.servingAmounts));

  const { hasNoIngredients, hasAddOnNutrients } = getMenuItemPrintWarnings(menuItem);

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div>
          <Typography variant="h4">{menuItem.name}</Typography>
          <Typography variant="h3">per {menuItem.servingName}</Typography>
        </div>

        <div>
          {hasNoIngredients && (
            <Typography variant="h3" color="error">
              No Ingredients!
            </Typography>
          )}
          {hasAddOnNutrients && (
            <Typography variant="h3" color="error">
              Add-ons Nutrients are not counted!
            </Typography>
          )}
        </div>
      </div>

      <div className={classes.topRow}>
        <Card
          variant="outlined"
          className={classnames(classes.imageCard, {
            [classes.imageCardWithoutImage]: !menuItem.imageUrl,
          })}
        >
          {menuItem.imageUrl ? <CardMedia component="img" src={menuItem.imageUrl} /> : <RestaurantIcon className={classes.noImageIcon} />}
        </Card>

        <div className={classes.nutrientsDiv}>
          <Card variant="outlined" className={classes.nutrientCard}>
            <CardHeader
              title="Sodium"
              className={classes.nutrientCardHeader}
              titleTypographyProps={{ variant: "body1" }} />
            <CardContent className={classes.nutrientCardContent}>
              <Typography variant="h3">{loading ? <Loading progressSize="xs" /> : round(minerals.Sodium.amount, 0) + "mg"}</Typography>
            </CardContent>
          </Card>
          <Card variant="outlined" className={classes.nutrientCard}>
            <CardHeader
              title="Fiber"
              className={classes.nutrientCardHeader}
              titleTypographyProps={{ variant: "body1" }} />
            <CardContent className={classes.nutrientCardContent}>
              <Typography variant="h3">{loading ? <Loading progressSize="xs" /> : round(carbs.Fiber.amount, 0) + "g"}</Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      <div className={classes.macrosDiv}>
        <Card variant="outlined" className={classes.macrosCard}>
          <CardHeader title="Calories" className={classes.cardHeader} />
          <CardContent>
            <Typography variant="h3">{macros.kcal}</Typography>
          </CardContent>
        </Card>
        <Card variant="outlined" className={classes.macrosCard}>
          <CardHeader title="Carbs" className={classes.cardHeader} />
          <CardContent>
            <Typography variant="h3">{macros.cho + "g"}</Typography>
          </CardContent>
        </Card>
        <Card variant="outlined" className={classes.macrosCard}>
          <CardHeader title="Protein" className={classes.cardHeader} />
          <CardContent>
            <Typography variant="h3">{macros.pro + "g"}</Typography>
          </CardContent>
        </Card>
        <Card variant="outlined" className={classes.macrosCard}>
          <CardHeader title="Fat" className={classes.cardHeader} />
          <CardContent>
            <Typography variant="h3">{macros.fat + "g"}</Typography>
          </CardContent>
        </Card>
      </div>

      <div className={classes.spacer} />

      <CardMedia
        sx={{ width: "80px", height: "23px" }}
        component="img"
        image="/img/performance_logo_name_black.svg" />
    </div>
  );
};

export default MenuItemPrintableNutrientInfo;
