import { Button, DialogActions, DialogContent } from "@mui/material";
import { Loading, useClientTimezone } from "@notemeal/shared-ui";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import {
  ActivityTemplateScheduleFragment,
  EditMealPlanCalendarInput,
  MealPlanConflictsQuery,
  MealPlanDayOfWeekAssignmentFragment,
  MealTemplateScheduleFragment,
  ScheduleBasicFragment,
  useMealPlanConflictsQuery,
} from "apps/web/src/types";
import { useReducer, useState } from "react";
import ConflictDialog from "../Form/ConflictDialog";
import { MealPlanCalendarEditForm } from "../Form/MealPlanCalendarEditForm";
import { editMealPlanCalendarReducer, initEditMealPlanCalendarState } from "../Form/reducer";
import {
  MealPlanDateAssignmentConflictResolution,
  getDateAssignmentConflicts,
  getEditMealPlanCalendarInput,
  getMealPlanConflictsQueryVariables,
  mealPlanCalendarStateToSaveTooltips,
} from "../Form/utils";
import { useEditMealPlanCalendarInRange } from "../loaders";

interface EditMealPlanCalendarDialogContentProps {
  athleteId: string;
  mealPlanId: string;
  mealPlanName: string;
  isAutoSuggestionsEnabled: boolean;
  startDate: string | null;
  endDate: string | null;
  schedule: ScheduleBasicFragment | null;
  mealTemplates: readonly MealTemplateScheduleFragment[];
  activityTemplates: readonly ActivityTemplateScheduleFragment[];
  dayOfWeekAssignments: readonly MealPlanDayOfWeekAssignmentFragment[];
  onDone: (input: EditMealPlanCalendarInput) => void;
  onClose: () => void;
  saving: boolean;
  promptToSetDefaultDates?: boolean;
}

export const EditMealPlanCalendarDialogContent = ({
  athleteId,
  mealPlanId,
  mealPlanName,
  isAutoSuggestionsEnabled,
  schedule,
  startDate,
  endDate,
  dayOfWeekAssignments,
  activityTemplates,
  mealTemplates,
  onClose,
  onDone,
  saving,
  promptToSetDefaultDates,
}: EditMealPlanCalendarDialogContentProps) => {
  const clientTimezone = useClientTimezone();
  const [conflictDialogOpen, setConflictDialogOpen] = useState(false);
  const [defaultDatesPromptOpen, setDefaultDatesOpen] = useState(promptToSetDefaultDates ?? false);

  const [state, dispatch] = useReducer(
    editMealPlanCalendarReducer,
    initEditMealPlanCalendarState({
      athleteId,
      mealPlanId,
      mealPlanName,
      schedule,
      clientTimezone,
      isAutoSuggestionsEnabled,
      startDate,
      endDate,
      dayOfWeekAssignments,
      activityTemplates,
      mealTemplates,
    })
  );

  const { data: conflictsData, variables: conflictsVariables } = useMealPlanConflictsQuery(
    getMealPlanConflictsQueryVariables(athleteId, state)
  );

  const canSaveTooltipItems = mealPlanCalendarStateToSaveTooltips(state);

  const setDefaultDateAssignments = () => {
    setDefaultDatesOpen(false);
    dispatch({
      type: "EditDateAssignmentMode",
      payload: {
        mode: "weekly",
      },
    });
    dispatch({
      type: "EditEndDate",
      payload: {
        date: null,
      },
    });
  };

  const onSave = () => {
    if (saving) {
      return;
    }
    if (canSaveTooltipItems.length > 0) {
      return;
    }
    if (!conflictsData) {
      // TODO: useEffect to trigger next steps after loading is finished?
      return;
    }
    onSaveWithConflictsData(conflictsData);
  };

  const onSaveWithConflictsData = (data: MealPlanConflictsQuery) => {
    const conflicts = getDateAssignmentConflicts(state, data);
    if (conflicts.length === 0) {
      onSaveWithConflictResolutions([]);
    } else {
      setConflictDialogOpen(true);
    }
  };

  const onSaveWithConflictResolutions = (conflictResolutions: readonly MealPlanDateAssignmentConflictResolution[]) => {
    const input = getEditMealPlanCalendarInput(state, mealTemplates, activityTemplates, conflictResolutions);
    onDone(input);
  };

  useEditMealPlanCalendarInRange(athleteId, mealPlanId, dispatch);

  const disabled = saving || canSaveTooltipItems.length > 0;

  return (
    <>
      <DialogTitle title="Edit Meal Plan Calendar" onClose={onClose} />
      <DialogContent>
        {saving ? <Loading progressSize="md" /> : <MealPlanCalendarEditForm state={state} dispatch={dispatch} />}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          onClick={onClose}
          disabled={saving}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before saving" items={canSaveTooltipItems}>
          <Button
            onClick={onSave}
            disabled={disabled}
            size="large">
            Save
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
      {conflictDialogOpen && conflictsData && conflictsVariables && (
        <ConflictDialog
          state={state}
          conflictsData={conflictsData}
          conflictsVariables={conflictsVariables}
          open={conflictDialogOpen}
          onClose={() => setConflictDialogOpen(false)}
          onDone={onSaveWithConflictResolutions}
        />
      )}
      {promptToSetDefaultDates && (
        <ConfirmationDialog
          title="No Date Assignments"
          message="The athlete does not have any days assigned for this meal plan. Would you like to assign this meal plan to a weekly, no end date assignment?"
          onCancel={() => setDefaultDatesOpen(false)}
          onConfirm={setDefaultDateAssignments}
          open={defaultDatesPromptOpen}
          confirmLabel="Yes"
          cancelLabel="No"
        />
      )}
    </>
  );
};
