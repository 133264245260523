import { Loading } from "@notemeal/shared-ui";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { useState } from "react";
import { useUnitsQuery } from "../../../../types";
import RestaurantFoodImportTabPanel, { RestaurantFoodImportTabPanelProps } from "./RestaurantFoodTabPanel";
import { fields, loadFoodEntriesFromCSV, loadFoodEntriesFromExcel } from "./loadFile";

interface RestaurantFoodImporterProps {
  restaurantId: string;
  resetTable: () => void;
}

interface FileTypeProps {
  loadAndParse: RestaurantFoodImportTabPanelProps["loadAndParse"];
  description: RestaurantFoodImportTabPanelProps["description"];
}

const xlsxProps: FileTypeProps = {
  loadAndParse: loadFoodEntriesFromExcel,
  description: "Imports Restaurant foods from an excel file.",
};

const csvProps: FileTypeProps = {
  loadAndParse: loadFoodEntriesFromCSV,
  description: "Imports Restaurant foods from a CSV file.",
};

const RestaurantFoodImporter = ({ restaurantId, resetTable }: RestaurantFoodImporterProps) => {
  const EXCEL = "General (.xlsx)";
  const CSV = "General (.csv)";
  const [selected, setSelected] = useState(EXCEL);
  const tabs = [EXCEL, CSV];

  const { data: unitsData } = useUnitsQuery();
  const fileTypeProps = selected === EXCEL ? xlsxProps : csvProps;

  return !unitsData ? (
    <Loading />
  ) : (
    <>
      <TWTabGroup tabs={tabs} onSelected={selected => setSelected(selected)} />
      <RestaurantFoodImportTabPanel
        matchFields={["name"]}
        fields={fields}
        units={unitsData.units}
        restaurantId={restaurantId}
        resetTable={resetTable}
        {...fileTypeProps}
      />
    </>
  );
};

export default RestaurantFoodImporter;
