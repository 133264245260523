import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import DiscardChangesDialog from "apps/web/src/components/universal/DiscardChangesDialog";
import { GoalConfigurationForTableFragment } from "apps/web/src/types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MealPlanCalendarContextProvider } from "../../MealPlans/contexts/Calendar";
import { GoalConfigurationForm } from "./GoalConfigurationForm";
import {
  EditGoalConfigurationInputType,
  GoalConfigurationFormSchema,
  GoalConfigurationFormType,
  goalConfigurationFormDefaultValues,
  goalConfigurationFormToEditInput,
} from "./GoalConfigurationSchema";

interface GoalConfigurationEditModalProps {
  open: boolean;
  onClose: () => void;
  onEditGoalConfiguration: (input: EditGoalConfigurationInputType) => void;
  goalConfiguration: GoalConfigurationForTableFragment;
}

export const GoalConfigurationEditModal = ({
  open,
  onClose,
  onEditGoalConfiguration,
  goalConfiguration,
}: GoalConfigurationEditModalProps) => {
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);

  const form = useForm<GoalConfigurationFormType>({
    defaultValues: goalConfigurationFormDefaultValues(goalConfiguration),
    resolver: zodResolver(GoalConfigurationFormSchema),
    mode: "onChange",
  });

  const { isDirty } = form.formState;

  const handleClose = () => {
    if (isDirty) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  const submit = (goal: GoalConfigurationFormType) => {
    onEditGoalConfiguration(goalConfigurationFormToEditInput(goalConfiguration, goal, [...goalConfiguration.individualDates]));
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        sx: { width: "900px", height: "650px" },
      }}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle title="Edit Goal" onClose={handleClose} />
      <DialogContent sx={{ pt: 0 }}>
        <MealPlanCalendarContextProvider>
          <GoalConfigurationForm form={form} mode="edit" />
        </MealPlanCalendarContextProvider>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={form.handleSubmit(submit)}>Save</Button>
        </Box>
      </DialogActions>
      {discardChangesOpen && (
        <DiscardChangesDialog open={discardChangesOpen} onClose={() => setDiscardChangesOpen(false)} onDiscard={onClose} />
      )}
    </Dialog>
  );
};
