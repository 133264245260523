import { Box, Typography } from "@mui/material";
import { ExchangeSummaryLabel, useProgressViews } from "@notemeal/shared-ui";
import { initMacros } from "@notemeal/shared-utils-macro-protocol";
import { ExchangeProgressViews, MacroProgressViews } from "../../types";
import { MacrosBar } from "../MacrosProgress/Bars/MacrosBar";
import { MacrosProgress } from "../MacrosProgress/MacrosProgress";
import { MacrosProgressBarAndDials } from "../MacrosProgress/MacrosProgressBarAndDials";
import { MACROS_PROGRESS_SMALL_HEIGHT, MACROS_PROGRESS_SMALL_WIDTH } from "../MacrosProgress/MacrosProgressUtils";
import { targetExchangeAmounts } from "./DisplaySettingsPreviewUtils";

interface MacrosDisplaySettingsPreviewProps {
  macroProgressViews: MacroProgressViews;
  disableTargets?: boolean;
}

const currentMacros = initMacros(5, 10, 3);
const targetMacros = initMacros(15, 20, 7);

export const MacrosDisplaySettingsPreview = ({ macroProgressViews, disableTargets = false }: MacrosDisplaySettingsPreviewProps) => {
  return <MacrosProgress
    variant="sm"
    currentMacros={currentMacros}
    targetMacros={targetMacros}
    macroProgressViews={macroProgressViews} />;
};

interface MacrosAndExchangesDisplaySettingsPreviewProps {
  macroProgressViews: MacroProgressViews;
  exchangeProgressViews: ExchangeProgressViews | null;
}

export const MacrosAndExchangesDisplaySettingsPreview = ({
  macroProgressViews,
  exchangeProgressViews,
}: MacrosAndExchangesDisplaySettingsPreviewProps) => {
  const { mode, onChangeMode } = useProgressViews({
    macroProgressViews,
    hasTargets: !!targetMacros,
    additionalModes: exchangeProgressViews === "all" ? ["ExchangeTargets"] : [],
  });

  if (macroProgressViews === "none" && exchangeProgressViews === "none") {
    return <Typography sx={{ height: "90px", display: "flex", alignItems: "center", justifyContent: "center" }}>None</Typography>;
  }

  return (
    <>
      {mode !== "ExchangeTargets" && (
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <MacrosProgressBarAndDials
            mode={mode}
            onChangeMode={onChangeMode}
            variant="sm"
            currentMacros={currentMacros}
            targetMacros={targetMacros}
          />
        </Box>
      )}
      {mode === "ExchangeTargets" && (
        <Box
          sx={{
            fill: 1,
            width: MACROS_PROGRESS_SMALL_WIDTH + 50,
            height: MACROS_PROGRESS_SMALL_HEIGHT,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            justifyContent: "flex-start",
            "&:hover": { cursor: "pointer" },
          }}
          onClick={onChangeMode}
        >
          <MacrosBar
            mode="HasTargets_ShowNumbers"
            macros={currentMacros}
            targetMacros={targetMacros || undefined} />
          <ExchangeSummaryLabel
            targetExchangeAmounts={targetExchangeAmounts}
            variant="sm"
            consolidatePro={false} />
        </Box>
      )}
    </>
  );
};
