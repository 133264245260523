import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import { Dispatch, useMemo } from "react";
import { useAthletesForPendingAdvancedSelectionQuery } from "../../../../../../types";
import {
    AddExcludeAthleteAction,
    AdvancedSelectionState,
    buildAdvancedSelectionInput,
} from "../../../../reducers/advancedSelectionReducers";
import ViewSelectedAthletesContent from "./ViewSelectedAthletesContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })
);

interface ViewSelectedAthletesDialogProps {
  state: AdvancedSelectionState;
  open: boolean;
  onClose: () => void;
  dispatch: Dispatch<AddExcludeAthleteAction>;
}

const ViewSelectedAthletesDialog = ({ state, open, onClose, dispatch }: ViewSelectedAthletesDialogProps) => {
  const classes = useStyles();
  const input = useMemo(() => buildAdvancedSelectionInput(state), [state]);

  const { data } = useAthletesForPendingAdvancedSelectionQuery({ variables: { input } });

  const athleteCount = data?.athletesForPendingAdvancedSelection ? data.athletesForPendingAdvancedSelection.length : "-";
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <div className={classes.title}>
          <Typography variant="h3">Selected Athletes ({athleteCount})</Typography>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      {data ? (
        <ViewSelectedAthletesContent athletes={data.athletesForPendingAdvancedSelection} dispatch={dispatch} />
      ) : (
        <DialogContent>
          <Loading />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewSelectedAthletesDialog;
