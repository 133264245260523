import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import RightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, IconButton, styled, SxProps, useTheme } from "@mui/material";
import { ImageWithLockedAspectRatio, Loading } from "@notemeal/shared-ui";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { useState } from "react";
import ImageFileDragAndDrop from "../../../universal/Image/ImageFileDragAndDrop";
import { Image } from "../utils";

const InsetButton = styled(IconButton)(() => ({
  color: "white",
  backgroundColor: "black",
  opacity: 0.6,
  "&:hover": {
    color: "white",
    backgroundColor: "black",
  },
}));
InsetButton.defaultProps = {
  size: "small",
};

interface UploadImageProps {
  handleDrop: (url: string) => void;
  disabled?: boolean;
}

export const UploadImage = ({ handleDrop, disabled }: UploadImageProps) => {
  return (
    <ImageFileDragAndDrop
      disabled={disabled}
      onUpload={handleDrop}
      overrideContainerStyle={true}>
      <Button
        disabled={disabled}
        startIcon={<AddIcon />}
        variant="outlined"
        sx={{ width: "100%" }}>
        Add Image
      </Button>
    </ImageFileDragAndDrop>
  );
};

interface ImageStackPreviewProps {
  imageUploading: boolean;
  images: Image[];
  sx?: SxProps;
  onChangeImages: (images: Image[]) => void;
  disabled?: boolean;
}

export const ImageStackPreview = ({ images, sx, onChangeImages, imageUploading, disabled }: ImageStackPreviewProps) => {
  const [selected, setSelected] = useState(0);
  const [deleteImageId, setDeleteImageId] = useState<undefined | string>(undefined);
  const { spacing } = useTheme();

  const deleteImage = (id: string) => {
    const remainingImages = images.filter(image => image.id !== id);
    const imagesWithUpdatedPositions = remainingImages.map((image, index) => {
      return { url: image.url, position: index + 1, id: image.id } as Image;
    });
    onChangeImages(imagesWithUpdatedPositions);
  };

  return (
    <Box sx={{ height: "100%", width: "100%", backgroundColor: "greyscale.50", mb: 2, ...sx, position: "relative" }}>
      {!imageUploading && (
        <ImageWithLockedAspectRatio
          imageUrl={images[selected].url}
          borderRadius={spacing(0.5)}
          sx={{ height: "100%", width: "100%" }} />
      )}
      {imageUploading && <Loading progressSize="xs" />}
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          top: spacing(1),
          right: spacing(1),
          left: spacing(1),
          bottom: spacing(1),
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ position: "absolute", display: "flex", top: 0, right: 0 }}>
          <InsetButton disabled={disabled} onClick={() => setDeleteImageId(images[selected].id)}>
            <CloseIcon fontSize="small" />
          </InsetButton>
        </Box>
        <InsetButton disabled={selected === 0} onClick={() => setSelected(prevState => prevState - 1)}>
          <LeftIcon fontSize="small" />
        </InsetButton>
        <InsetButton disabled={selected === images.length - 1} onClick={() => setSelected(prevState => prevState + 1)}>
          <RightIcon fontSize="small" />
        </InsetButton>
      </Box>
      <ConfirmationDialog
        open={deleteImageId !== undefined}
        title={"Delete Image"}
        message={"Are you sure you want to delete the image? This action cannot be undone."}
        onCancel={() => setDeleteImageId(undefined)}
        onConfirm={() => {
          if (deleteImageId) {
            deleteImage(deleteImageId);
          }
          setDeleteImageId(undefined);
        }}
        confirmLabel="Delete"
        variant="containedDestructive"
      />
    </Box>
  );
};
