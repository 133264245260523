import { Box, Button, Dialog, DialogActions, DialogContent, MenuItem, TextField, Tooltip } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSportsQuery } from "apps/web/src/types";
import React, { useEffect, useState } from "react";

export interface EditTeam {
  teamId: string;
  teamName: string;
  gender: string;
  sportId: string;
}

interface Sport {
  id: string;
  name: string;
}

interface TeamEditModalProps {
  open: boolean;
  onClose: () => void;
  editTeam: EditTeam;
  onSave: (saveEditTeam: EditTeam) => void;
  saving: boolean;
}

export const NONE = "None";

export const TeamEditModal = ({ open, onClose, editTeam: { teamId, teamName, gender, sportId }, onSave, saving }: TeamEditModalProps) => {
  const [editTeamName, setEditTeamName] = useState(teamName);
  const [editGender, setEditGender] = useState(gender || NONE);
  const [editSportName, setEditSportName] = useState("");

  const { data, loading } = useSportsQuery();

  const [sports, setSports] = useState<Sport[]>([]);
  useEffect(() => {
    const none = [{ id: NONE, name: NONE }];
    const sports = sortByKey(data?.sports || [], "name").map(sport => ({ id: sport.id, name: sport.name }));
    setSports(none.concat(sports));
  }, [data?.sports]);

  useEffect(() => {
    const initialSport = sports.find(sport => sport.id === sportId);
    setEditSportName(initialSport ? initialSport.name : NONE);
  }, [sports, sportId]);

  const genders = [NONE, "Men's", "Women's"];

  const canSave = !!editTeamName;
  const tooltip = canSave ? "" : "'Team Name' is required!";

  const generateTeamName = (gender: string, sportName: string) => `${gender !== NONE ? gender : ""} ${sportName !== NONE ? sportName : ""}`;

  const handleGenderChange = ({ target: { value: gender } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEditGender(gender);
    setEditTeamName(generateTeamName(gender, editSportName));
  };

  const handleSportChange = ({ target: { value: sportName } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEditSportName(sportName);
    setEditTeamName(generateTeamName(editGender, sportName));
  };

  const handleSave = () => {
    const sport = sports.find(sport => sport.name === editSportName);
    const editSportId = sport ? sport.id : sportId;
    return onSave({ teamId, teamName: editTeamName, gender: editGender, sportId: editSportId });
  };

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Team" onClose={onClose} />
      <DialogContent sx={{ width: 500, display: "flex", flexDirection: "column", gap: 2 }}>
        {loading || saving ? (
          <Loading progressSize="lg" />
        ) : (
          <>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                select
                label={"Gender"}
                fullWidth={true}
                margin="dense"
                value={editGender}
                onChange={handleGenderChange}>
                {genders.map(gender => (
                  <MenuItem key={gender} value={gender}>
                    {gender}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label={"Sport"}
                fullWidth={true}
                margin="dense"
                value={editSportName}
                onChange={handleSportChange}>
                {sports.map(({ id: sportId, name: sportName }: Sport) => (
                  <MenuItem key={sportId} value={sportName}>
                    {sportName}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <TextField
              label="Team Name"
              value={editTeamName}
              fullWidth
              margin="dense"
              onChange={e => setEditTeamName(e.target.value)} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip title={tooltip}>
          <span>
            <Button disabled={!canSave} onClick={() => handleSave()}>
              Save
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
