import { MenuOrderItemWithAppearance, PickupTime } from "@notemeal/shared-ui";
import KioskMenuOrderFormForOrder from "./KioskForOrder";

interface MenuOrderFormProps {
  className?: string;
  menuOrderItems: readonly MenuOrderItemWithAppearance[];
  selectedItemId: string | null;
  onSelectItem: (item: MenuOrderItemWithAppearance) => void;
  onRemoveItem: (id: string) => void;
  pickupTime: PickupTime | null;
  orderPickupTimes: PickupTime[];
  onChangePickupTime: (pickupTime: PickupTime) => void;
  mealMenuTimezone: string;
  menuOrderingClosed: boolean;
  openTimePicker?: boolean;
  disableOrderItems?: boolean;
  forceOrderTitle?: boolean;
  mealMenuName: string;
}

export const KioskMenuOrderForm = ({
  className,
  onRemoveItem,
  onSelectItem,
  selectedItemId,
  menuOrderItems,
  menuOrderingClosed,
  onChangePickupTime,
  pickupTime,
  orderPickupTimes,
  mealMenuTimezone,
  openTimePicker,
  disableOrderItems,
  forceOrderTitle,
  mealMenuName,
}: MenuOrderFormProps) => {
  const orderItems = menuOrderItems.filter(moi => moi.forOrder);

  if (!(forceOrderTitle || !!orderItems.length)) {
    return null;
  }

  return (
    <div className={className}>
      <KioskMenuOrderFormForOrder
        mealMenuName={mealMenuName}
        menuOrderItems={menuOrderItems}
        onSelectItem={onSelectItem}
        selectedItemId={selectedItemId}
        onRemoveItem={onRemoveItem}
        menuOrderingClosed={menuOrderingClosed}
        onChangePickupTime={onChangePickupTime}
        pickupTime={pickupTime}
        orderPickupTimes={orderPickupTimes}
        mealMenuTimezone={mealMenuTimezone}
        openTimePicker={openTimePicker}
        disabled={disableOrderItems || orderItems.length === 0}
      />
    </div>
  );
};
