import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import { Box, Button, FormControlLabel, Radio, RadioGroup, Switch, TextField, Typography, useTheme } from "@mui/material";
import { formatKcalOffset } from "@notemeal/shared-ui";
import { TWStepper } from "apps/web/src/componentLibrary/TWStepper/TWStepper";
import { greyscaleLight } from "apps/web/src/pages/Theme/Colors/Greyscale";
import { BulkAssignMealPlanTemplateAthleteSelect } from "./BulkAssignMealPlanTemplateAthleteSelect";
import { useBulkAssignMealPlanTemplate } from "./BulkAssignMealPlanTemplateContext";
import { BulkAssignMealPlanTemplateDateAssignmentCalendar } from "./BulkAssignMealPlanTemplateDateAssignmentCalendar";
import { BulkAssignMealPlanTemplateDateAssignmentForm } from "./BulkAssignMealPlanTemplateDateAssignmentForm";

export enum AssignMealPlanTemplateStep {
  NameAndType,
  DateAssignment,
  ChooseAthletes,
}

const stepLabels = ["Name and Type", "Date Assignment", "Choose Athletes"];

export const BulkAssignMealPlanTemplateContent = () => {
  const {
    step,
    onNext,
    onBack,
    name,
    setName,
    notes,
    setNotes,
    weightTargetTypeId,
    setWeightTargetTypeId,
    dateAssignment,
    setDateAssignment,
    isAutoSuggestionsEnabled,
    setIsAutoSuggestionsEnabled,
    athletes,
    setAthletes,
    goals,
  } = useBulkAssignMealPlanTemplate();

  const {
    palette: { info },
  } = useTheme();

  if (!goals) {
    return <Typography>Template goals not found</Typography>;
  }

  return (
    <Box sx={{ display: "flex", height: "100%", borderTop: "1px solid", borderColor: greyscaleLight[300] }}>
      <Box sx={{ borderRight: "1px solid", borderColor: greyscaleLight[300], flex: 0, p: 3 }}>
        <TWStepper stepLabels={stepLabels} activeStep={step} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, pl: 3, pt: 3 }}>
        <Box sx={{ flexGrow: 1, minHeight: 0, overflowY: "auto" }}>
          {step === AssignMealPlanTemplateStep.NameAndType && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flexGrow: 1 }}>
                <Box>
                  <Typography variant="h4" sx={{ mb: 0.5 }}>
                    Template Details
                  </Typography>
                  <Typography variant="body2" sx={({ palette }) => ({ color: palette.text.secondary })}>
                    Edit the name and add notes as needed
                  </Typography>
                </Box>
                <Box sx={{ maxWidth: 360 }}>
                  <TextField
                    fullWidth
                    autoFocus
                    label="Name"
                    inputProps={{ maxLength: 50 }}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    error={!name}
                  />
                </Box>
                <Box sx={{ maxWidth: 720 }}>
                  <TextField
                    fullWidth
                    sx={{ flexGrow: 1 }}
                    label="Notes (optional)"
                    placeholder="Add notes here"
                    inputProps={{ maxLength: 2000 }}
                    value={notes ?? ""}
                    onChange={e => setNotes(e.target.value || null)}
                  />
                </Box>
              </Box>
              {goals.length > 0 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box>
                    <Typography variant="h4" sx={{ mb: 0.5 }}>
                      Weight Target Type
                    </Typography>
                    <Typography variant="body2" sx={({ palette }) => ({ color: palette.text.secondary })}>
                      Select the weight target type to assign the athlete(s) to. The athlete's current type will get overridden if it
                      differs from the selected type.
                    </Typography>
                  </Box>
                  <RadioGroup
                    value={weightTargetTypeId}
                    onChange={e => setWeightTargetTypeId(e.target.value)}
                    sx={{ display: "flex", gap: 2 }}
                  >
                    {goals.map(goal => (
                      <FormControlLabel
                        value={goal.id}
                        label={
                          <Box>
                            <Typography variant="body1">{goal.name}</Typography>
                            <Typography variant="body2" sx={({ palette }) => ({ color: palette.text.secondary })}>
                              {goal.goalType.name} | {formatKcalOffset(goal.kcalOffset)}
                            </Typography>
                          </Box>
                        }
                        control={<Radio size="medium" />}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              )}
            </Box>
          )}
          {step === AssignMealPlanTemplateStep.DateAssignment && (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Box>
                  <Typography variant="h4" sx={{ mb: 0.5 }}>
                    Date Assignment
                  </Typography>
                </Box>
                <BulkAssignMealPlanTemplateDateAssignmentForm
                  calendarLocation="on the right"
                  startDateInPastBehavior="error"
                  dateAssignment={dateAssignment}
                  setDateAssignment={setDateAssignment}
                />

                <Box sx={{ mt: 3, mb: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1, pb: 1 }}>
                    <AutoFixHighOutlinedIcon sx={{ width: 20, height: 20 }} />
                    <Typography variant="body1Medium">Time Suggestions</Typography>
                  </Box>
                  <Typography variant="body2" sx={({ palette }) => ({ color: palette.text.secondary })}>
                    Enable this to allow auto-accepting of all suggestions
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: 1,
                    backgroundColor: "grey.100",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                    <Typography variant="body1Semibold">Auto-accept all suggestions</Typography>
                    <Typography variant="subtitle1">For meals on all applicable days/weeks</Typography>
                  </Box>
                  <Switch checked={isAutoSuggestionsEnabled} onChange={() => setIsAutoSuggestionsEnabled(!isAutoSuggestionsEnabled)} />
                </Box>
              </Box>
              <Box sx={{ py: 2 }}>
                <BulkAssignMealPlanTemplateDateAssignmentCalendar
                  dateAssignment={dateAssignment}
                  setDateAssignment={setDateAssignment}
                  mealPlanColor={info.main}
                  onClickCalendarDate={() => {}}
                  otherMealPlanDateAssignments={[]}
                />
              </Box>
            </Box>
          )}
          {step === AssignMealPlanTemplateStep.ChooseAthletes && (
            <BulkAssignMealPlanTemplateAthleteSelect athletes={athletes} setAthletes={setAthletes} />
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 1, flexDirection: "row", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={onBack}>
            {step === AssignMealPlanTemplateStep.NameAndType ? "Cancel" : "Back"}
          </Button>
          <Button color="primary" onClick={onNext}>
            {step === AssignMealPlanTemplateStep.ChooseAthletes ? "Submit" : "Next"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
