import { initMacros } from "@notemeal/shared-utils-macro-protocol";
import { Exchange, ExchangeAmountFragment } from "../../types";

const macros = initMacros(5, 2, 0);
const exchange1: Exchange = {
  ...macros,
  shortName: "Veg",
  type: "vegetable",
  exchangeServingList: { id: "0", name: "Master", servingAmounts: [] },
  name: "Vegetable",
  id: "1",
};
const exchange2: Exchange = {
  ...macros,
  shortName: "Fru",
  type: "fruit",
  exchangeServingList: { id: "0", name: "Master", servingAmounts: [] },
  name: "Fruit",
  id: "2",
};
const exchange3: Exchange = {
  ...macros,
  shortName: "Star",
  type: "starch",
  exchangeServingList: { id: "0", name: "Master", servingAmounts: [] },
  name: "Starch",
  id: "3",
};
const exchange4: Exchange = {
  ...macros,
  shortName: "Fat",
  type: "fat",
  exchangeServingList: { id: "0", name: "Master", servingAmounts: [] },
  name: "Fat",
  id: "4",
};
const exchange5: Exchange = {
  ...macros,
  shortName: "Pro",
  type: "protein",
  exchangeServingList: { id: "0", name: "Master", servingAmounts: [] },
  name: "Protein",
  id: "5",
};
const exchangeAmount1: ExchangeAmountFragment = {
  id: "6",
  __typename: "ExchangeAmount",
  amount: 2,
  pickListServingIds: null,
  exchange: { __typename: "Exchange", ...exchange1 },
};
const exchangeAmount2: ExchangeAmountFragment = {
  id: "7",
  __typename: "ExchangeAmount",
  amount: 1,
  pickListServingIds: null,
  exchange: { __typename: "Exchange", ...exchange2 },
};
const exchangeAmount3: ExchangeAmountFragment = {
  id: "8",
  __typename: "ExchangeAmount",
  amount: 1,
  pickListServingIds: null,
  exchange: { __typename: "Exchange", ...exchange3 },
};
const exchangeAmount4: ExchangeAmountFragment = {
  id: "9",
  __typename: "ExchangeAmount",
  amount: 1,
  pickListServingIds: null,
  exchange: { __typename: "Exchange", ...exchange4 },
};
const exchangeAmount5: ExchangeAmountFragment = {
  id: "10",
  __typename: "ExchangeAmount",
  amount: 1,
  pickListServingIds: null,
  exchange: { __typename: "Exchange", ...exchange5 },
};
export const exchangeAmounts: ExchangeAmountFragment[] = [exchangeAmount1, exchangeAmount2, exchangeAmount3, exchangeAmount4];
export const targetExchangeAmounts: ExchangeAmountFragment[] = [
  exchangeAmount1,
  exchangeAmount2,
  exchangeAmount3,
  exchangeAmount4,
  exchangeAmount5,
];
