import { Box, Typography } from "@mui/material";
import { getNextPosition } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { useState } from "react";
import { MenuItemChoiceFormFragment } from "../../../types";
import MenuItemChoiceFullEditDialog from "../../MenuItemAddOns/FullEdit/FullEditDialog";
import MenuItemChoiceList from "../../MenuItemAddOns/FullEdit/List";
import MenuItemChoiceIngredientsDialog from "../../MenuItemAddOns/Readonly/ReadonlyIngredientsDialog";
import MenuItemChoiceReadonlyList from "../../MenuItemAddOns/Readonly/ReadonlyList";
import { newMenuItemChoice } from "../../MenuItemAddOns/utils";
import { MenuItemChangeChoicesAction } from "../reducer";

interface AddOnsProps {
  choices: readonly MenuItemChoiceFormFragment[];
  onChangeChoices: (args: MenuItemChangeChoicesAction["payload"]) => void;
}

export const AddOns = ({ choices, onChangeChoices }: AddOnsProps) => {
  const [selectedChoice, setSelectedChoice] = useState<MenuItemChoiceFormFragment | null>(null);

  const handleEditChoice = (menuItemChoice: MenuItemChoiceFormFragment) => {
    const choiceIds = choices.map(mic => mic.id);
    let newChoices = [];

    if (choiceIds.includes(menuItemChoice.id)) {
      newChoices = choices.map(mic => (mic.id === menuItemChoice.id ? menuItemChoice : mic));
    } else {
      newChoices = [...choices, menuItemChoice];
    }

    onChangeChoices({ choices: newChoices });
  };

  const handleAddChoice = () => {
    setSelectedChoice(newMenuItemChoice(getNextPosition(choices)));
  };

  const handleSelectChoice = (choice: MenuItemChoiceFormFragment) => {
    setSelectedChoice(choice);
  };

  const handleRemoveChoice = (menuItemChoiceId: string) => {
    const newChoices = choices.filter(mic => mic.id !== menuItemChoiceId);
    onChangeChoices({ choices: newChoices });
  };

  const handleChangeOrder = (menuItemChoiceIds: string[]) => {
    const reorderedMenuItemChoices = menuItemChoiceIds.flatMap((micId, index) => {
      const matchingMenuItemChoice = choices.find(mic => mic.id === micId);
      return matchingMenuItemChoice
        ? [
            {
              ...matchingMenuItemChoice,
              position: index + 1,
            },
          ]
        : [];
    });

    onChangeChoices({ choices: reorderedMenuItemChoices });
  };

  return (
    <Box>
      <AddOnSectionHeader />
      <MenuItemChoiceList
        key={sortByKey(choices, "position")
          .map(c => c.id)
          .join(",")}
        choices={choices}
        onAddChoice={handleAddChoice}
        onSelectChoice={handleSelectChoice}
        onRemoveChoice={handleRemoveChoice}
        onChangeOrder={handleChangeOrder}
      />
      {selectedChoice !== null && (
        <MenuItemChoiceFullEditDialog
          open={selectedChoice !== null}
          onClose={() => setSelectedChoice(null)}
          menuItemChoice={selectedChoice}
          onDone={handleEditChoice}
        />
      )}
    </Box>
  );
};

interface ReadonlyAddOnsProps {
  choices: readonly MenuItemChoiceFormFragment[];
}

export const ReadonlyAddOns = ({ choices }: ReadonlyAddOnsProps) => {
  const [selectedChoice, setSelectedChoice] = useState<MenuItemChoiceFormFragment | null>(null);

  return (
    <Box>
      <AddOnSectionHeader />
      <MenuItemChoiceReadonlyList choices={choices} setSelectedChoice={setSelectedChoice} />
      {selectedChoice !== null && (
        <MenuItemChoiceIngredientsDialog
          open={selectedChoice !== null}
          onClose={() => setSelectedChoice(null)}
          menuItemChoice={selectedChoice}
        />
      )}
    </Box>
  );
};

const AddOnSectionHeader = () => {
  return (
    <>
      <Typography variant="h3" color="textSecondary">
        Add-ons
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ my: 1 }}>
        Allow athletes to choose add-ons (e.g. “add peppers”), extras (e.g. “2 extra eggs), or cooking styles (e.g. “medium-rare”)
      </Typography>
    </>
  );
};
