import { Button, Dialog, DialogActions, DialogContent, Table, TableCell, TableRow } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useProfileUsersQuery } from "apps/web/src/types";
import React from "react";

interface OverwriteProfilesDialogProps {
  open: boolean;
  onCancel: () => void;
  selectedId: string | null;
  onClose: () => void;
  conflictedUserIds: string[];
}

export const OverwriteProfilesDialog = ({ open, onClose, onCancel, conflictedUserIds }: OverwriteProfilesDialogProps) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);

  const { data: conflictedUsersData, loading: conflictedUsersLoading } = useProfileUsersQuery({
    variables: {
      ids: conflictedUserIds,
    },
  });

  if (conflictedUsersLoading) {
    return <Loading />;
  }

  const handleDone = () => {
    setOpenConfirmationDialog(true);
  };

  const handleConfirm = () => {
    setOpenConfirmationDialog(false);
    onClose();
  };

  return (
    conflictedUsersData && (
      <>
        <Dialog open={open} onClose={onCancel}>
          <DialogTitle title="Merge all users?" onClose={onCancel} />
          <DialogContent>
            <Table>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Org</TableCell>
              </TableRow>
              {conflictedUsersData.profileUsers.map(u => (
                <TableRow>
                  <TableCell>{u.email}</TableCell>
                  <TableCell>{u.firstName}</TableCell>
                  <TableCell>{u.lastName}</TableCell>
                  <TableCell>{u.orgInfo.map(oi => oi.org.name).join(", ")}</TableCell>
                </TableRow>
              ))}
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={() => handleDone()}>Force Merge</Button>
          </DialogActions>
        </Dialog>
        {openConfirmationDialog && (
          <ConfirmationDialog
            title="Confirm Forced Merge"
            message="Are you certain you want to force merge this user?"
            open={openConfirmationDialog}
            onCancel={() => setOpenConfirmationDialog(false)}
            onConfirm={handleConfirm}
          />
        )}
      </>
    )
  );
};
