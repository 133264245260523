import { Box, Checkbox, TextField, Typography } from "@mui/material";
import { useHasFeature } from "@notemeal/shared-ui";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { Dispatch, useState } from "react";
import { CombinedTagAction, CombinedTagState, isEmptyTagState } from "../../../reducers/combinedTagReducers";
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from "../../../reducers/tagReducers";
import CustomTagContent from "./CustomTagContent";
import SmartTagContent from "./SmartTagContent";

interface BaseMutationTabsProps {
  state: CombinedTagState;
  dispatch: Dispatch<CombinedTagAction>;
  isTagNameUnique: boolean;
  tagId: string | null;
}

const CustomTagHint = () => (
  <Typography>
    * Custom Tags enable you to <strong>manually</strong> assign a tag to selected athletes (e.g., I want to assign a “Training” tag to Mike
    and Troy)
  </Typography>
);

const SmartTagHint = () => (
  <Typography>
    * Smart Tags enable you to <strong>set logic</strong> based on athlete information and Custom Tags. The Smart Tag will automatically
    update as athlete information changes (e.g., assign the Smart Tag “Allergy” for athletes who have the Custom Tag “Peanuts” or
    “Shellfish”).
  </Typography>
);

const BaseMutationTabs = ({ state, dispatch, isTagNameUnique, tagId }: BaseMutationTabsProps) => {
  const CUSTOM_TAG = "Custom Tag";
  const SMART_TAG = "Smart Tag";
  const [selected, setSelected] = useState(state.__typeName === "Custom" ? CUSTOM_TAG : SMART_TAG);
  const tabs = [CUSTOM_TAG, SMART_TAG];

  const [warnTabChangeOpen, setWarnTabChangeOpen] = useState(false);
  const showIsPrintable = useHasFeature("printableTags");

  const changeTab = () => {
    dispatch({ type: "SWITCH_TAG_STATE_ACTION" });
    setWarnTabChangeOpen(false);
    setSelected(selected === CUSTOM_TAG ? SMART_TAG : CUSTOM_TAG);
  };

  const handleChangeSelection = (newSelected: string) => {
    if ((newSelected === CUSTOM_TAG && state.__typeName === "Custom") || (newSelected === SMART_TAG && state.__typeName === "Smart")) {
      return;
    }

    if (isEmptyTagState(state)) {
      changeTab();
    } else {
      setWarnTabChangeOpen(true);
    }
  };

  const baseNameHelperText = state.name.length === MAX_NAME_LENGTH ? `Name can only be ${MAX_NAME_LENGTH} characters long.` : " ";
  const baseDesctiptionHelperText =
    state.description.length === MAX_DESCRIPTION_LENGTH ? `Description can only be ${MAX_DESCRIPTION_LENGTH} characters long.` : " ";

  return (
    <>
      <TWTabGroup
        tabs={tabs}
        onSelected={handleChangeSelection}
        outsideSelected={selected} />
      <Box sx={{ p: 2, mb: 1, backgroundColor: "greyscale.100", borderRadius: "5px" }}>
        {state.__typeName === "Custom" ? <CustomTagHint /> : <SmartTagHint />}
      </Box>
      <Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            sx={{ flex: 1 }}
            label="Tag Name"
            value={state.name}
            onChange={e =>
              dispatch({
                type: "EDIT_NAME_ACTION",
                payload: e.target.value,
              })
            }
            error={!isTagNameUnique}
            helperText={isTagNameUnique ? baseNameHelperText : "This tag name is already in use. Please create a unique tag name."}
          />
          <TextField
            sx={{ flex: 2 }}
            label="Description"
            value={state.description}
            onChange={e =>
              dispatch({
                type: "EDIT_DESCRIPTION_ACTION",
                payload: e.target.value,
              })
            }
            helperText={baseDesctiptionHelperText}
          />
        </Box>
        {showIsPrintable && state.isPrintable !== null && (
          <Box sx={{ flex: 1, display: "flex", alignItems: "start" }}>
            <Checkbox
              size="medium"
              sx={{ mt: "-9px", ml: "-9px" }}
              inputProps={{
                "aria-label": "tag is printable",
              }}
              checked={state.isPrintable}
              onClick={e => e.stopPropagation()}
              onChange={e => dispatch({ type: "EDIT_IS_PRINTABLE_ACTION", payload: e.target.checked })}
            />
            <Box sx={{ mb: 1 }}>
              <Typography variant="body1Medium">Include tag on athlete menu orders</Typography>
              <Typography variant="body2" color="textSecondary">
                By enabling this, the tag will be{" "}
                <Typography variant="body2Semibold">viewable on athlete orders and athlete printed orders</Typography>
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ minHeight: 470 }}>
        {state.__typeName === "Custom" && <CustomTagContent state={state.customState} dispatch={dispatch} />}
        {state.__typeName === "Smart" && (
          <SmartTagContent
            state={state.smartState}
            dispatch={dispatch}
            tagName={state.name}
            tagId={tagId} />
        )}
      </Box>
      {warnTabChangeOpen && (
        <ConfirmationDialog
          open={warnTabChangeOpen}
          title="Switch Tags?"
          message="Switching over to the other tag will get rid of the work that you have done for the current tag. Are you sure you would like to
          proceed?"
          onCancel={() => setWarnTabChangeOpen(false)}
          onConfirm={changeTab}
        />
      )}
    </>
  );
};

export default BaseMutationTabs;
