import { useDateFormatting } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { Column, Workbook } from "exceljs";
import saveAs from "file-saver";
import { BulkOrderFragment, MealMenuPlateItemFragment, MenuItemFormFragment, useBulkOrdersForMealMenuLazyQuery } from "../../../types";

type Header = "Order" | "Order Count" | "Team Name" | "Orderer" | "Pickup Time" | "Status";

interface SerializedExportRow {
  menuItemAndOptions: string;
  orderCount: number;
  teamName: string;
  orderer: string;
  pickupTime: string;
  status: string;
}

interface ExportColumnDef {
  header: Header;
  key: keyof SerializedExportRow;
  width: number;
  style?: Column["style"];
}

const exportColumns: ExportColumnDef[] = [
  { header: "Order", key: "menuItemAndOptions", width: 40 },
  { header: "Order Count", key: "orderCount", width: 10 },
  { header: "Team Name", key: "teamName", width: 15 },
  { header: "Orderer", key: "orderer", width: 15 },
  { header: "Pickup Time", key: "pickupTime", width: 15 },
  { header: "Status", key: "status", width: 10 },
];

interface ExportToExcelProps {
  mealMenuName: string;
  orderItems: BulkOrderFragment[];
  exportFilename: string;
}

const sortOptionsByChoicePos = (plateItem: MealMenuPlateItemFragment) => {
  const choices = sortByKey(plateItem.menuItem.choices, "position");
  const choicesToItemOptMap: { [choiceName: string]: { amount: number; name: string }[] } = {};
  for (let choice of choices) {
    choicesToItemOptMap[choice.name] = [];
  }

  for (let option of plateItem.options) {
    const matchedChoice = choices.find(choice => choice.options.find(o => o.name === option.menuItemChoiceOption.name));
    if (matchedChoice) {
      choicesToItemOptMap[matchedChoice.name].push({
        amount: option.amount,
        name: option.menuItemChoiceOption.name,
      });
    }
  }
  return choicesToItemOptMap;
};

const formatChoiceOptions = (options: { amount: number; name: string }[], choiceName: string) => {
  return `\n    ${choiceName}${options.map(o => `\n        ${o.amount}x ${o.name}`)}`;
};

const formatItemWithOptions = (
  menuItem: MenuItemFormFragment,
  itemAmount: number,
  choiceToOptMap: { [choiceName: string]: { amount: number; name: string }[] }
) => {
  return `\
  ${itemAmount}x ${menuItem.name}\
  ${Object.keys(choiceToOptMap).map(choiceName => formatChoiceOptions(choiceToOptMap[choiceName], choiceName))}\
  `;
};

const serializeRows = (orders: BulkOrderFragment[]): SerializedExportRow[] => {
  return orders.flatMap(o => {
    const plates = o.childMealMenuPlates;
    return plates.flatMap(p => {
      return {
        orderCount: p.mealMenuPlateOrders.length,
        menuItemAndOptions: p.plateItems
          .map(pi => {
            const menuItem = pi.menuItem;
            const amount = pi.amount;
            const sortedOptions = sortOptionsByChoicePos(pi);
            if (Object.values(sortedOptions).flat().length === 0) {
              return menuItem.name;
            } else {
              return formatItemWithOptions(menuItem, amount, sortedOptions);
            }
          })
          .join("\r\n"),
        teamName: o.team.name,
        orderer: o.createdBy.lastName.concat(", ", o.createdBy.firstName),
        pickupTime: o.pickupTime,
        status: o.status,
      };
    });
  });
};

export const exportToExcel = async ({ mealMenuName, orderItems, exportFilename }: ExportToExcelProps) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(mealMenuName);
  worksheet.columns = exportColumns;
  worksheet.getRow(1).alignment = { horizontal: "center", vertical: "middle" };
  worksheet.getRow(1).font = { bold: true };
  const rows = serializeRows(orderItems);
  worksheet.addRows(rows);
  saveAs(new Blob([await workbook.xlsx.writeBuffer()]), exportFilename);
  return true;
};

export const useBulkOrderExport = (
  mealMenuName: string,
  mealMenuTimezone: string,
  startDate: Date
): { onExport: (mealMenuId: string) => Promise<void>; loading: boolean } => {
  const { formatDateInTimezoneWithLocale } = useDateFormatting();
  const formattedStart = formatDateInTimezoneWithLocale(startDate, mealMenuTimezone, { format: "yyyy-MM-dd" });
  const [getBulkOrders, { loading }] = useBulkOrdersForMealMenuLazyQuery({
    onCompleted: data => {
      // convert utc pickup times to mealmenu timezone time
      const bulkOrdersWithFormattedPickupTime = data.bulkOrdersForMealMenu.map(bo => {
        return {
          ...bo,
          pickupTime: formatDateInTimezoneWithLocale(bo.pickupTime, mealMenuTimezone, { format: "yyyy-MM-dd HH:mm" }),
        };
      });
      exportToExcel({
        mealMenuName,
        orderItems: bulkOrdersWithFormattedPickupTime,
        exportFilename: `bulk-order-export-${mealMenuName}-${formattedStart}.xlsx`,
      });
    },
    fetchPolicy: "network-only",
  });

  const onExport = async (mealMenuId: string) => {
    getBulkOrders({ variables: { mealMenuId } });
  };

  return { onExport, loading };
};
