import AssignmentIcon from "@mui/icons-material/Assignment";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PeopleIcon from "@mui/icons-material/People";
import { Box, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { AthleteFoodPreferenceContextProvider, Loading, useDateFormatting, useHasFeature } from "@notemeal/shared-ui";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { TWIconButton } from "apps/web/src/componentLibrary/TWIconButton/TWIconButton";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { EDSAStatus } from "apps/web/src/views/Athlete/MealPlans/EDSAStatus";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { MacroProtocolPreview } from "../../../../components/MacroProtocol/Preview/MacroProtocolPreview";
import { MacroProtocolPreviewPlaceholder } from "../../../../components/MacroProtocol/Preview/MacroProtocolPreviewPlaceholder";
import MealPlanSelect from "../../../../components/MealPlan/Select";
import { CurrentMacrosContextProvider } from "../../../../contexts/CurrentMacros";
import { RefetchQueriesContextProvider } from "../../../../contexts/RefetchQueries";
import { MealOptionPreviewWithMealTemplateId, SelectedMealOptionsContext } from "../../../../contexts/SelectedMealOptions";
import { getNavOrgAthleteMealPlans, getNavOrgAthleteMealPlansMealPlan } from "../../../../pages/Auth/Org/Athlete/AthletePaths";
import {
  AthleteMealPlansDocument,
  useAthleteMealPlansQuery,
  useDeleteMealPlanMutation,
  useExchangeSetsQuery,
  useGoalTypesQuery,
} from "../../../../types";
import AssignFromMealPlanTemplate from "../../../../views/Athlete/MealPlans/AssignFromMealPlanTemplate/Dialog";
import BulkCopyMealPlanDialogLoader from "../../../../views/Athlete/MealPlans/BulkCopyDialog/Loader";
import MealPlanCalendarPreview from "../../../../views/Athlete/MealPlans/Content/Calendar/Preview";
import { evictMealPlanCalendarQueriesForAthlete } from "../../../../views/Athlete/MealPlans/Content/Calendar/cache";
import { MealPlanContent } from "../../../../views/Athlete/MealPlans/Content/MealPlanContent";
import CopyMealPlanDialogLoader from "../../../../views/Athlete/MealPlans/CopyDialog/Loader";
import CreateMealPlanDialogLoader from "../../../../views/Athlete/MealPlans/CreateDialog/Loader";
import EditMealPlanDialogLoader from "../../../../views/Athlete/MealPlans/EditDialog";
import MealPlanPlaceholder from "../../../../views/Athlete/MealPlans/Placeholder";
import { MealPlanCalendarContextProvider } from "../../../../views/Athlete/MealPlans/contexts/Calendar";
import { MealPlanColorsContextProvider } from "../../../../views/Athlete/MealPlans/contexts/Colors";

export const AthleteMealPlansPage = () => {
  const { athleteId: maybeAthleteId, mealPlanId } = useParams();
  const athleteId = maybeAthleteId || "";
  const navigate = useNavigate();
  const { formatEventDatetimeWithLocale } = useDateFormatting();
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [assignFromTemplateModalOpen, setAssignFromTemplateModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const moreOpen = !!anchorEl;

  const [bulkCopyModalOpen, setBulkCopyModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { setMessage } = useSnackbar();

  const getAthleteMealPlansOptions = {
    query: AthleteMealPlansDocument,
    variables: { athleteId },
  };

  const refetchQueries = [getAthleteMealPlansOptions];

  const { data: ampData, loading: ampLoading } = useAthleteMealPlansQuery({
    variables: { athleteId },
  });
  const { data: esData, loading: esLoading } = useExchangeSetsQuery();
  const { data: gtData, loading: gtLoading } = useGoalTypesQuery();

  const [selectedMealOptions, setSelectedMealOptions] = useState<readonly MealOptionPreviewWithMealTemplateId[]>([]);

  const [deleteMealPlan] = useDeleteMealPlanMutation({
    update: (cache, { data }) => {
      if (data) {
        evictMealPlanCalendarQueriesForAthlete({
          athleteId,
          cache,
          type: "Delete",
          mealPlanId: data.deleteMealPlan.mealPlanId,
        });
      }
    },
    onCompleted: () => navigate(getNavOrgAthleteMealPlans(athleteId)),
    onError: () => setMessage("error", "Something went wrong"),
  });

  const hasEDSA = useHasFeature("eatingDisorderScreens");

  if (ampLoading || !ampData || esLoading || !esData || gtLoading || !gtData) {
    return <Loading progressSize="lg" />;
  }

  const { firstName, lastName, isEdsaEnabled, isEdsaPending, lastEdsaResult } = ampData.athlete;
  const showEdsa = hasEDSA && isEdsaEnabled;

  const { team, likedFoods, dislikedFoods, likedFoodGroups, dislikedFoodGroups, mealPlans } = ampData.athlete;
  const mealPlan = mealPlans.find(mpt => mpt.id === mealPlanId) || null;

  const currentGoal = ampData.athlete.currentGoal;
  const exchangeSets = esData.exchangeSets;
  const goalTypes = gtData.goalTypes;
  const mostRecentComplianceEvent = mealPlan ? mealPlan.mostRecentEvent : null;

  return (
    <MealPlanCalendarContextProvider>
      <MealPlanColorsContextProvider mealPlans={mealPlans}>
        {/* TODO: Keep refetchQueries ? */}
        <RefetchQueriesContextProvider refetchQueries={refetchQueries}>
          <SelectedMealOptionsContext.Provider value={[selectedMealOptions, setSelectedMealOptions]}>
            <CurrentMacrosContextProvider>
              <AthleteFoodPreferenceContextProvider
                likedFoods={likedFoods}
                likedFoodGroups={likedFoodGroups}
                dislikedFoods={dislikedFoods}
                dislikedFoodGroups={dislikedFoodGroups}
              >
                <Box sx={{ overflow: "hidden", display: "flex", gap: 3 }}>
                  <Box
                    sx={{
                      minWidth: 350,
                      maxWidth: 400,
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      overflowY: "auto",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3">Meal Plans</Typography>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            onClick={() => setEditModalOpen(true)}
                            disabled={mealPlan === null}
                            variant="outlined"
                            size="small">
                            Edit
                          </Button>
                          <Button
                            onClick={() => {
                              setNewModalOpen(true);
                              trackEvent("Nutrition | Team | Athlete | Create Meal Plan", {
                                athleteId,
                                teamId: team.id,
                                teamName: team.name,
                                sport: team.sport,
                              });
                            }}
                            variant="contained"
                            size="small"
                          >
                            Create
                          </Button>
                          <TWIconButton
                            icon={<MoreVertIcon />}
                            size="small"
                            variant="outlined"
                            onClick={e => setAnchorEl(e.currentTarget)}
                          />
                        </Box>
                      </Box>
                      <MealPlanSelect
                        mealPlans={mealPlans}
                        selectedMealPlan={mealPlan}
                        onChange={mealPlanId => navigate(getNavOrgAthleteMealPlansMealPlan(athleteId, mealPlanId))}
                        onNew={() => setNewModalOpen(true)}
                        onAssignFromMPT={() => setAssignFromTemplateModalOpen(true)}
                        onCopy={() => setCopyModalOpen(true)}
                        onBulkCopy={() => setBulkCopyModalOpen(true)}
                      />
                      {!(mealPlan && mealPlan.isShared) ? null : mostRecentComplianceEvent ? (
                        <Typography variant="caption" color="textSecondary">
                          <strong>Last viewed: </strong>
                          {formatEventDatetimeWithLocale(mostRecentComplianceEvent.datetime, { formatLong: true })}
                        </Typography>
                      ) : (
                        <Typography variant="caption">Meal plan has not yet been viewed by the athlete.</Typography>
                      )}
                    </Box>
                    {mealPlan ? (
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <MacroProtocolPreview
                          athlete={ampData.athlete}
                          currentGoal={currentGoal}
                          macroProtocol={mealPlan.macroProtocol}
                          goalTypes={goalTypes}
                        />
                        <MealPlanCalendarPreview athleteId={athleteId} />
                      </Box>
                    ) : (
                      <MacroProtocolPreviewPlaceholder />
                    )}
                  </Box>
                  <Divider orientation="vertical" />
                  <Box sx={{ overflowY: "auto", width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                    {showEdsa && (
                      <EDSAStatus
                        isEdsaPending={isEdsaPending ?? false}
                        athleteName={`${firstName} ${lastName}`}
                        score={lastEdsaResult?.score}
                        date={lastEdsaResult?.createdAt}
                      />
                    )}
                    {mealPlanId ? (
                      <MealPlanContent
                        athleteId={athleteId}
                        macroDisplaySettings={ampData.athlete.macroDisplaySettings}
                        exchangeDisplaySettings={ampData.athlete.exchangeDisplaySettings}
                        mealPlanId={mealPlanId}
                      />
                    ) : (
                      <MealPlanPlaceholder
                        athleteId={athleteId}
                        teamId={team.id}
                        teamName={team.name}
                        sport={team.sport}
                        mealPlans={mealPlans}
                        onNew={() => setNewModalOpen(true)}
                        onCopy={() => setCopyModalOpen(true)}
                      />
                    )}
                  </Box>
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={moreOpen}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAssignFromTemplateModalOpen(true);
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText>Assign From Meal Plan Template</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setCopyModalOpen(true);
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <FileCopyIcon />
                    </ListItemIcon>
                    <ListItemText>Copy From Existing Meal Plan</ListItemText>
                  </MenuItem>
                  <MenuItem
                    disabled={mealPlan === null}
                    onClick={() => {
                      setBulkCopyModalOpen(true);
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText>Copy Meal Plan To Athletes</ListItemText>
                  </MenuItem>
                  <MenuItem
                    disabled={mealPlan === null}
                    onClick={() => {
                      setDeleteModalOpen(true);
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </Menu>
                {copyModalOpen && (
                  <CopyMealPlanDialogLoader
                    athleteId={athleteId}
                    open={copyModalOpen}
                    onClose={() => setCopyModalOpen(false)}
                    exchangeSets={exchangeSets}
                  />
                )}
                {bulkCopyModalOpen && mealPlan && (
                  <BulkCopyMealPlanDialogLoader
                    mealPlan={mealPlan}
                    teamId={team.id}
                    open={bulkCopyModalOpen}
                    onClose={() => setBulkCopyModalOpen(false)}
                  />
                )}
                {assignFromTemplateModalOpen && (
                  <AssignFromMealPlanTemplate
                    athlete={ampData.athlete}
                    open={assignFromTemplateModalOpen}
                    onClose={() => setAssignFromTemplateModalOpen(false)}
                  />
                )}
                {newModalOpen && (
                  <CreateMealPlanDialogLoader
                    athleteId={athleteId}
                    teamId={team.id}
                    open={newModalOpen}
                    onClose={() => setNewModalOpen(false)}
                  />
                )}
                {mealPlan && editModalOpen && (
                  <EditMealPlanDialogLoader
                    athleteId={athleteId}
                    mealPlan={mealPlan}
                    open={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    exchangeSets={exchangeSets}
                  />
                )}
                {deleteModalOpen && mealPlanId && (
                  <ConfirmationDialog
                    open={deleteModalOpen}
                    title={"Delete Meal Plan?"}
                    message={"This action can not be undone! All meal options and schedule info will also be deleted."}
                    onCancel={() => setDeleteModalOpen(false)}
                    onConfirm={() => {
                      deleteMealPlan({
                        variables: { input: { mealPlanId: mealPlanId, createdById: null } },
                      });
                      setDeleteModalOpen(false);
                    }}
                    variant="containedDestructive"
                  />
                )}
              </AthleteFoodPreferenceContextProvider>
            </CurrentMacrosContextProvider>
          </SelectedMealOptionsContext.Provider>
        </RefetchQueriesContextProvider>
      </MealPlanColorsContextProvider>
    </MealPlanCalendarContextProvider>
  );
};
