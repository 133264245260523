/// <reference types="vite/client" />
import { z } from "zod";

const envVariablesSchema = z.object({
  VITE_URL_SCHEME: z.string(),
  VITE_WEB_DOMAIN: z.string(),
  VITE_WEB_DOMAIN_COOKIE: z.string(),
  VITE_SERVER_DOMAIN: z.string(),
  VITE_CLIENT_ERROR_SLACK_API_URL: z.string(),
  VITE_USDA_API_KEY: z.string(),
  VITE_WEB_SCHEMA_VERSION: z.string(),
  VITE_GAINSIGHT_PX_KEY_TAG: z.string(),
  VITE_COMMIT_HASH: z.string(),
  VITE_DATADOG_ENV: z.string(),
  VITE_DATADOG_RUM_ENABLED: z.string(),
  VITE_STREAM_CHAT_API_KEY: z.string(),
  VITE_TW_OKTA_DOMAIN: z.string(),
  VITE_TW_OKTA_WEB_CLIENT_ID: z.string(),
});

export const environment = envVariablesSchema.parse(import.meta.env);
