import React from "react";
import { Chip } from "@mui/material";
import { blueDark, green, greyBlue, orangeDark, red } from "../../pages/Theme/Colors/Accents";
export enum TWChipStyles {
  "green",
  "blue",
  "orange",
  "red",
  "gray",
}

type TWChipProps = {
  label: string;
  chipStyle: TWChipStyles;
};

export const TWChip = ({ label, chipStyle }: TWChipProps) => {
  const getStyleColors = (style: TWChipStyles) => {
    switch (style) {
      case TWChipStyles.green:
        return { background: green[50], color: green[700] };
      case TWChipStyles.blue:
        return { background: blueDark[50], color: blueDark[700] };
      case TWChipStyles.orange:
        return { background: orangeDark[50], color: orangeDark[500] };
      case TWChipStyles.red:
        return { background: red[25], color: red[600] };
      case TWChipStyles.gray:
      default:
        return { background: greyBlue[100], color: greyBlue[700] };
    }
  };
  const { background, color } = getStyleColors(chipStyle);

  return (
    <Chip
      sx={{
        background,
        padding: "3px 8px",
        "& .MuiChip-label": {
          color,
          fontWeight: 600,
          fontSize: 14,
          padding: 0,
        },
      }}
      variant="filled"
      size="small"
      label={label}
    />
  );
};
