import { Box, Button, IconButton, Typography } from "@mui/material";
import { AppleAppStoreIcon, appleAppStoreLink, GooglePlayStoreIcon, googlePlayStoreLink } from "@notemeal/shared-ui";
import { useLogout } from "../../utils/tokens";

interface LoginErrorContentProps {
  title: string;
  subtext: string;
  onBack: () => void;
  includeButtons?: boolean;
}

export const LoginErrorContent = ({ title, subtext, onBack, includeButtons }: LoginErrorContentProps) => {
  const logout = useLogout();

  const handleBack = () => {
    logout();
    onBack();
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h1">{title}</Typography>
        <Typography variant="subtitle1">{subtext}</Typography>
      </Box>
      <Button onClick={handleBack}>Back to Login</Button>
      {includeButtons && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton href={appleAppStoreLink}>
            <AppleAppStoreIcon />
          </IconButton>
          <IconButton href={googlePlayStoreLink}>
            <GooglePlayStoreIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};
