import { Avatar, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortByKey } from "@notemeal/utils-sort";
import React from "react";
import { MenuOrderItemSummary } from "./types";

interface MenuItemDialogListItemProps {
  choices: MenuOrderItemSummary["choices"];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
    },
    listSection: {
      backgroundColor: "inherit",
      paddingLeft: theme.spacing(2),
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0,
    },
  })
);

const MenuItemDialogListItem = ({ choices }: MenuItemDialogListItemProps) => {
  const classes = useStyles();

  return (
    <List className={classes.root} subheader={<li />}>
      {choices.map(({ id, name, options }) => (
        <li key={`section-${id}`} className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader>{name}</ListSubheader>
            {sortByKey(options, "amount", { reverse: true }).map(({ id, name, amount }) => (
              <ListItem key={`item-${id}-${name}`}>
                <ListItemIcon>
                  <Avatar sx={{ width: 32, height: 32, backgroundColor: "common.black" }}>{amount}</Avatar>
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
};

export default MenuItemDialogListItem;
