import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Checkbox, FormControlLabel, MenuItem, TextField, Tooltip, Typography, styled } from "@mui/material";
import { round } from "@notemeal/shared-ui";
import { formatTime, parseTime } from "@notemeal/utils-date-time";
import UserOrderLimit from "apps/web/src/views/Menus/SelectComponents/UserOrderLimit";
import { addMinutes } from "date-fns";
import { useState } from "react";
import { ImportMenuTimeInput, MealType, TeamMealMenuPreviewFragment } from "../../../../types";
import AdvancedSelectionDialog from "../../../Tags/Dialogs/Mutation/AdvancedSelection/AdvancedSelectionDialog";
import ShareWithInfo from "../../../Tags/Dialogs/Mutation/AdvancedSelection/ShareWithInfo";
import {
    AdvancedSelectionState,
    buildInitialCreateAdvancedSelectionStateFromTeams,
    isAdvancedSelectionEmpty,
} from "../../../Tags/reducers/advancedSelectionReducers";
import NotificationSettingsButton from "../../Dialog/NotificationSettingsButton";
import OrderRateLimitButton from "../../Dialog/OrderRateLimit/Button";
import { MenuDurationSelect } from "../../SelectComponents/DurationSelect";
import MealTypeSelect from "../../SelectComponents/MealTypeSelect";
import PrepTimeSelect from "../../SelectComponents/PrepTimeSelect";
import StartTimeSelect from "../../SelectComponents/StartTimeSelect";
import TeamMultiSelect from "../../SelectComponents/TeamMultiSelect";
import TimezoneSelect from "../../SelectComponents/TimezoneSelect";
import { ShareMealMenuState } from "../../types";
import { getEmptyShareState } from "../../utils";
import { ImportMenuOptionsState } from "./utils";

const SubHeader = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  gap: spacing(2),
  alignItems: "flex-end",
}));

const LAST_ORDER_DUE_BEFORE_END_IN_MINUTES_OPTIONS = [0, 15, 30, 45, 60, 90, 120, 150, 180];

interface ImportDialogOtherSettingsProps {
  state: ImportMenuOptionsState;
  readOnlyTimes?: boolean;
  onChange: (state: ImportMenuOptionsState) => void;
  allTeams: readonly TeamMealMenuPreviewFragment[];
}

const handleAdvancedSelectionState = (advancedSelectionState: AdvancedSelectionState): ShareMealMenuState => {
  if (isAdvancedSelectionEmpty(advancedSelectionState)) {
    return getEmptyShareState();
  } else {
    return {
      __typename: "Tags",
      teams: null,
      advancedSelectionState,
    };
  }
};

const ImportDialogOtherSettings = ({ onChange, allTeams, state, readOnlyTimes }: ImportDialogOtherSettingsProps) => {
  const [advancedSelectOpen, setAdvancedSelectOpen] = useState(false);

  const makeHandleChangeMenuTime =
    <K extends "durationInMinutes" | "startTime" | "mealType">(menuName: string, key: K) =>
    (value: ImportMenuTimeInput[K]) => {
      onChange({
        ...state,
        menuTimes: state.menuTimes.map(mt => {
          if (mt.menuName === menuName) {
            return {
              ...mt,
              [key]: value,
            };
          } else {
            return mt;
          }
        }),
      });
    };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4, pt: 2, px: 5 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 3, border: 1, borderColor: "grey.400" }}>
        <Typography variant="h4">Menu/Order Details</Typography>
        <SubHeader sx={{ width: 700 }}>
          {state.shareState.__typename === "Teams" && (
            <TeamMultiSelect
              sx={{ flex: 1 }}
              allTeams={allTeams}
              selectedTeams={state.shareState.teams}
              onChange={teams =>
                onChange({
                  ...state,
                  shareState: {
                    __typename: "Teams",
                    teams,
                    advancedSelectionState: null,
                  },
                })
              }
            />
          )}
          {state.shareState.__typename === "Tags" && (
            <ShareWithInfo
              state={state.shareState.advancedSelectionState}
              maxChips={4}
              showWrapper
              isForSmallSpace
              containerSx={{ minWidth: 120, flex: 1 }}
              showAdvancedSelectionClear={false}
            />
          )}
          <Button variant="outlined" onClick={() => setAdvancedSelectOpen(true)}>
            {state.shareState.__typename === "Teams" ? "Advanced Selection" : "Edit Advanced Selection"}
          </Button>
        </SubHeader>
        <SubHeader sx={{ gap: 2 }}>
          <TimezoneSelect
            value={state.timezone}
            onChange={timezone =>
              onChange({
                ...state,
                timezone,
              })
            }
          />
          <TextField
            select
            label="Last Order Due"
            value={state.lastOrderTimeBeforeEndInMinutes}
            onChange={e =>
              onChange({
                ...state,
                lastOrderTimeBeforeEndInMinutes: parseFloat(e.target.value),
              })
            }
            sx={{ minWidth: 165 }}
          >
            {LAST_ORDER_DUE_BEFORE_END_IN_MINUTES_OPTIONS.map(pt => (
              <MenuItem key={pt} value={pt}>
                {pt === 0 ? `By end of meal` : pt < 60 ? `${pt}m before end` : `${round(pt / 60, 1)}hr before end`}
              </MenuItem>
            ))}
          </TextField>
          <NotificationSettingsButton
            value={state.notificationSentBeforeOrderDueInMinutes}
            onChange={notificationSentBeforeOrderDueInMinutes =>
              onChange({
                ...state,
                notificationSentBeforeOrderDueInMinutes,
              })
            }
          />
          <OrderRateLimitButton
            orderRateLimit={state.orderRateLimit}
            onChangeOrderRateLimit={orderRateLimit =>
              onChange({
                ...state,
                orderRateLimit,
              })
            }
            teams={allTeams}
          />
          <PrepTimeSelect value={state.prepTimeInMinutes} onChange={prepTimeInMinutes => onChange({ ...state, prepTimeInMinutes })} />
        </SubHeader>
        <SubHeader>
          <FormControlLabel
            label="Available For Order"
            control={
              <Checkbox
                checked={state.availableForOrder}
                onChange={(_, availableForOrder) =>
                  onChange({
                    ...state,
                    availableForOrder,
                  })
                }
              />
            }
          />
          <FormControlLabel
            label="Allow Special Requests"
            disabled={!state.availableForOrder}
            control={
              <Checkbox
                checked={state.availableForOrder && state.allowSpecialRequests}
                onChange={(_, allowSpecialRequests) =>
                  onChange({
                    ...state,
                    allowSpecialRequests,
                  })
                }
              />
            }
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              sx={{ mr: 1 }}
              label="Override Menus"
              control={
                <Checkbox
                  checked={state.overrideIdentity}
                  onChange={(_, checked) =>
                    onChange({
                      ...state,
                      overrideIdentity: checked,
                    })
                  }
                />
              }
            />
            <Tooltip
              title={
                <Typography variant="subtitle1">
                  Overriding menus will make them editable by default, but no updates will be received through re-import
                </Typography>
              }
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Box>
          <UserOrderLimit
            limit={state.userOrderLimit}
            onChange={value => {
              onChange({
                ...state,
                userOrderLimit: {
                  limit: value !== null ? parseInt(value) : null,
                },
              });
            }}
          />
        </SubHeader>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h4">Selected Meal Times</Typography>

        {state.menuTimes.map(menuTime => (
          <ImportMenuTimeForm
            key={menuTime.menuName}
            menuTime={menuTime}
            readOnlyTime={readOnlyTimes}
            onChangeDuration={makeHandleChangeMenuTime(menuTime.menuName, "durationInMinutes")}
            onChangeMealType={makeHandleChangeMenuTime(menuTime.menuName, "mealType")}
            onChangeStart={makeHandleChangeMenuTime(menuTime.menuName, "startTime")}
          />
        ))}
      </Box>
      {advancedSelectOpen && (
        <AdvancedSelectionDialog
          title="Share Menu"
          open={advancedSelectOpen}
          onClose={() => setAdvancedSelectOpen(false)}
          onSave={advancedSelectionState => {
            onChange({
              ...state,
              shareState: handleAdvancedSelectionState(advancedSelectionState),
            });
            setAdvancedSelectOpen(false);
          }}
          initialState={
            state.shareState.__typename === "Teams"
              ? buildInitialCreateAdvancedSelectionStateFromTeams(state.shareState.teams)
              : state.shareState.advancedSelectionState
          }
        />
      )}
    </Box>
  );
};

export default ImportDialogOtherSettings;

interface ImportMenuTimeFormProps {
  menuTime: ImportMenuTimeInput;
  readOnlyTime?: boolean;
  onChangeMealType: (mealType: MealType) => void;
  onChangeStart: (startTime: string) => void;
  onChangeDuration: (durationInMinutes: number) => void;
}

const ImportMenuTimeForm = ({ menuTime, readOnlyTime, onChangeDuration, onChangeMealType, onChangeStart }: ImportMenuTimeFormProps) => {
  const { menuName, mealType, startTime, durationInMinutes } = menuTime;

  return (
    <Box sx={{ display: "flex", p: 2, backgroundColor: "grey.200", alignItems: "center", width: "100%" }}>
      <Typography variant="h4" sx={{ width: 180 }}>
        {`${menuName}:`}
      </Typography>
      <MealTypeSelect
        value={mealType}
        onChange={onChangeMealType}
        sx={{ width: 120, mx: 5, backgroundColor: "white" }}
        menuName={menuName}
      />
      {readOnlyTime ? (
        <TextField
          disabled
          label="Start"
          sx={{ width: 130, backgroundColor: "white" }}
          value={formatTime(`${startTime}`, { alwaysShowMinutes: true })}
        />
      ) : (
        <StartTimeSelect
          label="Start"
          value={startTime}
          onChange={onChangeStart}
          sx={{ minWidth: 110, backgroundColor: "white" }} />
      )}
      <Typography sx={{ mx: 2 }}>to</Typography>
      {readOnlyTime ? (
        <TextField
          disabled
          label="End"
          sx={{ width: 130, backgroundColor: "white" }}
          value={formatTime(addMinutes(parseTime(startTime), durationInMinutes), { alwaysShowMinutes: true })}
        />
      ) : (
        <MenuDurationSelect
          value={durationInMinutes}
          startTime={startTime}
          onChange={onChangeDuration}
          sx={{ minWidth: 200, backgroundColor: "white" }}
        />
      )}
    </Box>
  );
};
