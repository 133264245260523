import BarCharIcon from "@mui/icons-material/BarChart";
import { Box, IconButton, Tooltip } from "@mui/material";
import { ExchangeFractionLabel, exchangeTotalsToAmounts, getExchangeAmountsMacros, getServingAmountsMacros, MacrosProgressMode, sumExchangeTotals, useApolloResolverContext, useServingAmountsWithExchangeTotals } from "@notemeal/shared-ui";
import { useState } from "react";
import { ExchangeAmountFragment, FullExchangeSetFragment, FullServingAmountFragment } from "../../../types";
import { MacrosBar } from "../../MacrosProgress/Bars/MacrosBar";
import { MacrosProgressBarAndDials } from "../../MacrosProgress/MacrosProgressBarAndDials";
import { MACROS_PROGRESS_SMALL_HEIGHT, MACROS_PROGRESS_SMALL_WIDTH } from "../../MacrosProgress/MacrosProgressUtils";
import NutrientAmountDialog from "../../NutrientAmount/Modal";

interface MealOptionExchangeMacrosProgressProps {
  mode: "ExchangeTargets" | MacrosProgressMode;
  onChangeMode: () => void;
  servingAmounts: readonly FullServingAmountFragment[];
  exchangeSet: FullExchangeSetFragment;
  targetExchangeAmounts: readonly ExchangeAmountFragment[];
}

export const MealOptionExchangeMacrosProgress = ({
  mode,
  onChangeMode,
  servingAmounts,
  exchangeSet,
  targetExchangeAmounts,
}: MealOptionExchangeMacrosProgressProps) => {
  const context = useApolloResolverContext();
  const targetMacros = getExchangeAmountsMacros(targetExchangeAmounts);

  const servingAmountsWithExchangeTotals = useServingAmountsWithExchangeTotals(servingAmounts, exchangeSet, targetExchangeAmounts, context);

  const exchanges = exchangeSet.exchanges;
  const currentExchangeTotals = sumExchangeTotals(servingAmountsWithExchangeTotals.map(({ exchangeTotals }) => exchangeTotals));
  const currentExchangeAmounts = exchangeTotalsToAmounts(exchanges, currentExchangeTotals);
  const currentMacros = getServingAmountsMacros(servingAmounts);

  const [nutrientsOpen, setNutrientsOpen] = useState(false);

  return (
    <>
      {mode !== "ExchangeTargets" && (
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <MacrosProgressBarAndDials
            mode={mode}
            onChangeMode={onChangeMode}
            variant="sm"
            currentMacros={currentMacros}
            targetMacros={targetMacros}
          />
          <Tooltip title="View Meal Nutrients">
            <IconButton
              onClick={e => {
                e.stopPropagation();
                setNutrientsOpen(true);
              }}
              size="small"
            >
              <BarCharIcon sx={{ color: "accents.yellow.400" }} />
            </IconButton>
          </Tooltip>
          {nutrientsOpen && (
            <NutrientAmountDialog
              disableDRI
              open={nutrientsOpen}
              onClose={() => setNutrientsOpen(false)}
              servingAmounts={servingAmounts} />
          )}
        </Box>
      )}
      {mode === "ExchangeTargets" && (
        <Box
          sx={{
            fill: 1,
            width: MACROS_PROGRESS_SMALL_WIDTH + 50,
            height: MACROS_PROGRESS_SMALL_HEIGHT,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            justifyContent: "flex-start",
            "&:hover": { cursor: "pointer" },
          }}
          onClick={onChangeMode}
        >
          <MacrosBar
            mode="HasTargets_ShowNumbers"
            macros={currentMacros}
            targetMacros={targetMacros || undefined} />
          <ExchangeFractionLabel
            currentExchangeAmounts={currentExchangeAmounts}
            targetExchangeAmounts={targetExchangeAmounts}
            variant="sm"
          />
        </Box>
      )}
    </>
  );
};
