import { FormControl, InputLabel, Select } from "@mui/material";
import MultiSelectCalendar from "../../../components/universal/MultiSelectCalendar";

import { useDateFormatting } from "@notemeal/shared-ui";
import { addDays } from "date-fns";

interface MenuStartDatesMultiSelectProps {
  dates: string[];
  onChange: (dates: string[]) => void;
  className?: string;
}

const MenuStartDatesMultiSelect = ({ onChange, dates, className }: MenuStartDatesMultiSelectProps) => {
  const { formatDateWithLocale } = useDateFormatting();

  const value =
    dates.length === 0 ? "No dates selected" : dates.length === 1 ? formatDateWithLocale(dates[0]) : `${dates.length} dates selected`;

  return (
    <FormControl variant="outlined" className={className}>
      <InputLabel>Dates</InputLabel>
      <Select
        sx={{ height: "36px", mt: 2 }}
        label="Dates"
        value={value}
        renderValue={v => <>{v}</>}
        error={!dates.length}>
        <MultiSelectCalendar
          dates={dates}
          onChange={onChange}
          minDate={addDays(new Date(), -1)} />
      </Select>
    </FormControl>
  );
};

export default MenuStartDatesMultiSelect;
