import { ApolloCache, useApolloClient } from "@apollo/client";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import Edit from "@mui/icons-material/Edit";
import { Badge, Button, Dialog, DialogContent, Divider, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MealMenuLogItemWithAppearance, areServingAmountsEqual, getCommentsUnviewedNotificationIds, servingAmountsToInputs, useClientTimezone } from "@notemeal/shared-ui";
import { formatTimeRangeInTimezone } from "@notemeal/utils-date-time";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import {
    EditTimelineMealDetailsInput,
    FullServingAmountFragment,
    TimelineMealFragment,
    TimelineMealFragmentDoc,
    useAddMealMenuLogItemMutation,
    useEditTimelineMealDetailsMutation,
    useRemoveMealMenuLogItemMutation,
    useRemoveTimelineMealServingAmountMutation,
    useSetTimelineMealServingAmountsForAthleteMutation,
} from "apps/web/src/types";
import classNames from "classnames";
import { useState } from "react";
import { useContentStyles } from "../../../../components/MenuOrder/useContentStyles";
import FoodLogMealEditTimeModal from "../FoodLogMeal/Edit/TimeModal";
import { TimelineMealForModal, getTimelineMealInput } from "../utils";
import TimelineMealComments from "./Comments";
import LogTab from "./Log";
import TimelineMealModalOrderTab from "./OrderTab";
import TimelineMealOverview from "./Overview";
import { useTimelineMealModalContext } from "./TimelineMealModalContext";
import { LOG_TAB, ORDER_CLOSED_TAB, ORDER_TAB } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      alignItems: "center",
    },
    commentBadge: {
      top: 10,
      right: -2,
    },
    paper: {
      height: `calc(100% - 64px)`,
    },
    tab: {
      padding: theme.spacing(),
      "&:hover": { cursor: "pointer" },
    },
    uppercase: { textTransform: "uppercase" },
    selectedTab: {
      borderBottomWidth: "2px",
      borderBottomColor: theme.palette.info.light,
      borderBottom: "solid",
    },
    disabledTab: {
      cursor: " not-allowed",
      "&:hover": { cursor: " not-allowed" },
      opacity: 0.5,
    },
    tabRow: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: theme.spacing(3),
    },
    menu: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      overflowY: "auto",
      minHeight: "100%",
    },
    backButton: {
      display: "flex",
      alignItems: "center",
      textTransform: "none",
      color: theme.palette.grey[500],
      padding: theme.spacing(0, 3),
      "&:hover": { cursor: "pointer" },
    },
    grow: { flexGrow: 1 },
    editIcon: { color: theme.palette.grey[600], marginLeft: theme.spacing(2) },
  })
);

interface TimelineMealModalProps {
  open: boolean;
  onClose: () => void;
  timelineMeal: TimelineMealForModal;
  onChangeDetails: (details: Omit<EditTimelineMealDetailsInput, "timelineMealId">) => void;
  athleteId: string;
  date: string;
  updateMealInCacheFn: (timelineMeal: TimelineMealFragment, cache: ApolloCache<any>) => void;
}

const TimelineMealModal = ({
  open,
  onClose,
  timelineMeal,
  athleteId,
  onChangeDetails,
  date,
  updateMealInCacheFn,
}: TimelineMealModalProps) => {
  const classes = useStyles();
  const contentClasses = useContentStyles();

  const {
    cartFns: { cartHasChanges },
    pickupTimeChanged,
    currentTab,
    setCurrentTab,
    logType,
    setLogType,
    modalTabs,
  } = useTimelineMealModalContext();

  const [editTimeModalOpen, setEditTimeModalOpen] = useState(false);
  const clientTimezone = useClientTimezone();
  const timeRange = formatTimeRangeInTimezone(timelineMeal.start, timelineMeal.durationInMinutes, timelineMeal.timezone, {
    excludeTimezoneSuffix: clientTimezone === timelineMeal.timezone,
  });
  const header = `${timelineMeal.name} (${timeRange})`;

  const client = useApolloClient();

  const [unsavedChangesAlertOpen, setUnsavedChangesAlertOpen] = useState<boolean>(false);

  const timelineMealInput = getTimelineMealInput(timelineMeal);

  const handleClose = () => {
    if (cartHasChanges() || pickupTimeChanged) {
      setUnsavedChangesAlertOpen(true);
    } else {
      onClose();
    }
  };

  const [setTimelineMealServingAmounts] = useSetTimelineMealServingAmountsForAthleteMutation({
    onCompleted: data => {
      if (data.setTimelineMealServingAmountsForAthlete.timelineMeal) {
        const needsUpdate = !areServingAmountsEqual(
          timelineMeal.servingAmounts,
          data.setTimelineMealServingAmountsForAthlete.timelineMeal.servingAmounts
        );
        if (needsUpdate) {
          setTimelineMealServingAmounts({
            variables: {
              input: {
                athleteId,
                timelineMeal: timelineMealInput,
                servingAmounts: servingAmountsToInputs(timelineMeal.servingAmounts),
              },
            },
          });
        }
      }
    },
    update: (cache, { data }) => {
      if (data && data.setTimelineMealServingAmountsForAthlete.timelineMeal) {
        updateMealInCacheFn(data.setTimelineMealServingAmountsForAthlete.timelineMeal, cache);
      }
    },
  });

  const [removeTimelineMealServingAmount] = useRemoveTimelineMealServingAmountMutation({});

  const handleRemoveServingAmount = (id: string) => {
    removeTimelineMealServingAmount({ variables: { input: { timelineMealId: timelineMeal.id, removeServingAmountId: id } } });
    updateServingAmountsCache(timelineMeal.servingAmounts.filter(sa => sa.id !== id));
  };

  const updateServingAmountsCache = (servingAmounts: readonly FullServingAmountFragment[]) => {
    client.writeFragment({
      fragment: TimelineMealFragmentDoc,
      fragmentName: "TimelineMeal",
      id: `TimelineMeal:${timelineMeal.id}`,
      data: {
        ...timelineMeal,
        servingAmounts,
      },
    });
  };

  const handleSetServingAmounts = (servingAmounts: readonly FullServingAmountFragment[]) => {
    updateServingAmountsCache(servingAmounts);
    setTimelineMealServingAmounts({
      variables: {
        input: { timelineMeal: timelineMealInput, servingAmounts: servingAmountsToInputs(servingAmounts), athleteId: athleteId },
      },
    });
  };
  const [addMealMenuLogItem] = useAddMealMenuLogItemMutation({
    update: (cache, { data }) => {
      if (data && data.addMealMenuLogItemForAthlete.timelineMeal) {
        updateMealInCacheFn(data.addMealMenuLogItemForAthlete.timelineMeal, cache);
      }
    },
  });

  const handleAddMealMenuLogItem = ({ amount, menuItem, options }: MealMenuLogItemWithAppearance, mealMenuId: string) => {
    addMealMenuLogItem({
      variables: {
        input: {
          athleteId,
          mealMenuId,
          timelineMeal: timelineMealInput,
          item: {
            amount,
            menuItemId: menuItem.id,
            options: options.map(o => ({
              amount: o.amount,
              menuItemChoiceOptionId: o.menuItemChoiceOption.id,
            })),
          },
        },
      },
    });
  };

  const [removeMealMenuLogItem] = useRemoveMealMenuLogItemMutation({
    update: (cache, { data }) => {
      if (data && data.removeMealMenuLogItem.removedTimelineMealId) {
        cache.evict({
          id: `MealMenuLogItem:${data.removeMealMenuLogItem.mealMenuLogItemId}`,
          broadcast: true,
        });
      }
    },
  });
  const handleRemoveMealMenuLogItem = (id: string) => {
    removeMealMenuLogItem({ variables: { input: { mealMenuLogItemId: id } } });
  };

  const commentNotificationsCount = getCommentsUnviewedNotificationIds(timelineMeal.comments).length;

  const [editTimelineMealDetails] = useEditTimelineMealDetailsMutation();
  const handleEditTimelineMealDetails = async (input: Omit<EditTimelineMealDetailsInput, "timelineMealId">) => {
    if (timelineMeal.__typename === "TimelineMeal") {
      await editTimelineMealDetails({
        variables: {
          input: {
            timelineMealId: timelineMeal.id,
            ...input,
          },
        },
      });
    }
    onChangeDetails(input);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        classes={{ paper: classes.paper }}>
        <DialogTitle title={header} onClose={handleClose} />
        <DialogContent>
          <Button
            sx={{ alignSelf: "flex-start" }}
            variant="outlined"
            onClick={() => setEditTimeModalOpen(true)}
            endIcon={<Edit />}>
            Edit Meal Time
          </Button>
          <div className={contentClasses.content}>
            <div className={classes.menu}>
              <div className={classes.tabRow}>
                {modalTabs.map(modalTab => {
                  return (
                    <Badge
                      key={modalTab}
                      color="error"
                      badgeContent={modalTab === "Comment" ? commentNotificationsCount : 0}
                      classes={{
                        badge: classes.commentBadge,
                      }}
                    >
                      <div
                        key={modalTab}
                        onClick={modalTab === ORDER_CLOSED_TAB ? () => {} : () => setCurrentTab(modalTab)}
                        className={classNames([
                          classes.tab,
                          { [classes.selectedTab]: modalTab === currentTab, [classes.disabledTab]: modalTab === ORDER_CLOSED_TAB },
                        ])}
                      >
                        <Typography
                          variant="body1"
                          color={modalTab === currentTab ? "info" : "textSecondary"}
                          className={classes.uppercase}
                        >
                          {modalTab} {modalTab === "Comment" && `(${timelineMeal.comments.length})`}
                        </Typography>
                      </div>
                    </Badge>
                  );
                })}
                {currentTab === LOG_TAB && logType !== null && timelineMeal.mealMenus.length > 0 && (
                  <>
                    <div className={classes.grow} />
                    <Button
                      variant="text"
                      className={classes.backButton}
                      onClick={() => setLogType(null)}>
                      <ChevronLeft /> <Typography>Back to logging options</Typography>
                    </Button>
                  </>
                )}
              </div>

              {currentTab === ORDER_CLOSED_TAB ? (
                // The user should never see this but adding just in case
                <Typography>Ordering is Closed for all available Menu. Please use the Log Tab</Typography>
              ) : currentTab === ORDER_TAB ? (
                <TimelineMealModalOrderTab timelineMeal={timelineMeal} athleteId={athleteId} />
              ) : currentTab === LOG_TAB ? (
                <LogTab
                  timelineMeal={timelineMeal}
                  servingAmounts={timelineMeal.servingAmounts}
                  setServingAmounts={handleSetServingAmounts}
                  addMenuLogItem={handleAddMealMenuLogItem}
                />
              ) : (
                <TimelineMealComments
                  timelineMeal={timelineMeal}
                  athleteId={athleteId}
                  timezone={clientTimezone}
                  date={date} />
              )}
            </div>
            <Divider orientation="vertical" />
            <div className={contentClasses.order}>
              <TimelineMealOverview
                timelineMeal={timelineMeal}
                onClose={onClose}
                timezone={clientTimezone}
                date={date}
                onChangePickupTime={start =>
                  handleEditTimelineMealDetails({
                    start,
                    durationInMinutes: 30,
                    timezone: timelineMeal.timezone,
                    name: timelineMeal.name,
                    type: timelineMeal.type,
                  })
                }
                onRemoveServingAmount={handleRemoveServingAmount}
                onRemoveMealMenuLogItem={handleRemoveMealMenuLogItem}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={unsavedChangesAlertOpen}
        title="Invalid Order"
        message="You have unsaved changes. Do you still wish to leave?"
        onCancel={() => setUnsavedChangesAlertOpen(false)}
        onConfirm={() => onClose()}
        confirmLabel="Discard"
        variant="containedDestructive"
      />
      {editTimeModalOpen && (
        <FoodLogMealEditTimeModal
          meal={timelineMeal}
          onClose={() => setEditTimeModalOpen(false)}
          open={editTimeModalOpen}
          onSave={handleEditTimelineMealDetails}
          date={date}
        />
      )}
    </>
  );
};

export default TimelineMealModal;
