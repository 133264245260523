import { capitalize } from "@mui/material/utils";
import { getStaffFoodSources } from "@notemeal/food-utils";
import { FoodDataSource } from "@notemeal/graphql-types";
import React from "react";
import LabeledSelectNoId from "../../../universal/LabeledSelectNoId";

interface FoodDataSourceSelectProps {
  source: FoodDataSource;
  isEdit: boolean;
  disabled?: boolean;
  error: boolean;
  helperText?: string;
  onChange: (value: string) => void;
}

export const FoodDataSourceSelect = ({ source, isEdit, disabled, error, helperText, onChange }: FoodDataSourceSelectProps) => {
  // only show mccance if editing
  const dataSources = getStaffFoodSources().filter(fs => isEdit || fs !== FoodDataSource.mccance);

  return (
    <LabeledSelectNoId
      selectedOption={source}
      placeholder="Food Source"
      options={dataSources}
      error={error}
      helperText={helperText}
      optionToName={(name: string) => capitalize(name)}
      disabled={disabled}
      onChange={t => onChange(t)}
    />
  );
};
