import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { AddMenuOrderPayload, MenuOrderItemWithAppearance, PickupTime, getAddMenuOrderInputsOrErrors, getMealTemplateMacroTargets, isMealMenuClosed, usePickupTimeState } from "@notemeal/shared-ui";
import React, { useState } from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";
import {
    MealMenuDiningStationWithItemPreviewsFragment,
    MealMenuForOrderFormFragment,
    MealTemplateForTargetsFragment,
    MenuOrderFormFragment,
} from "../../types";
import { AthleteOrderStateAction, OrderFormAthleteOrderState } from "../../views/Kiosk/Order/orderStateReducer";
import { useSnackbar } from "../Snackbar/SnackbarContext";
import MenuOrderDialogContent from "./DialogContent";
import { useContentStyles } from "./useContentStyles";

interface MenuOrderNewDialogContentProps {
  onAddMenuOrder: (payload: AddMenuOrderPayload, mealMenuId: string, mealTemplateId: string | null) => void;
  mealTemplate: MealTemplateForTargetsFragment | null;
  mealMenu: MealMenuForOrderFormFragment;
  teamId: string;
  viewOnly: boolean;
  onClose: () => void;
  mealMenuDiningStations: readonly MealMenuDiningStationWithItemPreviewsFragment[];
  avoidFoodGroupIds: string[];
  athleteId: string;
  athleteUserId?: string;
  menuOrders?: readonly MenuOrderFormFragment[];
  athleteOrderState: OrderFormAthleteOrderState;
  athleteOrderStateDispatch: React.Dispatch<AthleteOrderStateAction>;
}

const MenuOrderNewDialogContent = ({
  onAddMenuOrder,
  mealMenu,
  mealTemplate,
  teamId,
  viewOnly,
  onClose,
  mealMenuDiningStations,
  avoidFoodGroupIds,
  athleteId,
  athleteUserId,
  menuOrders,
  athleteOrderState,
  athleteOrderStateDispatch,
}: MenuOrderNewDialogContentProps) => {
  const classes = useContentStyles();
  const [fixOrderItemsAlertOpen, setFixOrderItemsAlertOpen] = useState(false);
  const { setMessage } = useSnackbar();

  const [menuOrderItems, setMenuOrderItems] = useState<readonly MenuOrderItemWithAppearance[]>([]);

  const [openTimePicker, setOpenTimePicker] = useState<boolean | undefined>(undefined);

  const anyItemsForOrder = menuOrderItems.some(i => i.forOrder);

  const [doneOnPickupChange, setDoneOnPickupChange] = useState(false);

  const { pickupTime, setPickupTime, orderPickupTimes } = usePickupTimeState({
    mealMenuId: mealMenu.id,
    teamId,
    mealMenuStart: mealMenu.start,
    mealMenuDurationInMinutes: mealMenu.durationInMinutes,
    currentMenuOrderPickupTime: null,
    mealMenuPrepTimeInMinutes: mealMenu.prepTimeInMinutes,
  });

  const handleSetPickupTime = (pickupTime: PickupTime) => {
    setPickupTime(pickupTime);
    if (doneOnPickupChange) {
      handleDoneWithPickupTime(pickupTime);
    }
  };

  const handleDone = () => {
    if (pickupTime === null) {
      setOpenTimePicker(!openTimePicker);
      setDoneOnPickupChange(true);
      return;
    }
    handleDoneWithPickupTime(pickupTime);
  };

  const handleDoneWithPickupTime = (pickupTime: PickupTime) => {
    const result = getAddMenuOrderInputsOrErrors(menuOrderItems, mealMenuDiningStations, null, pickupTime);
    if (result.type === "inputs") {
      onAddMenuOrder(
        {
          pickupTime: result.pickupTime,
          logItems: result.logItems,
          orderItems: result.orderItems,
          images: result.images,
        },
        mealMenu.id,
        mealTemplate?.id || null
      );
      onClose();
    } else {
      if (result.invalidOrder) {
        setFixOrderItemsAlertOpen(true);
      } else {
        setMessage("error", result.error || "");
      }
    }
  };

  const actionText = anyItemsForOrder ? "Place Order" : "Save Log";
  const menuOrderingClosed = isMealMenuClosed(mealMenu);

  return (
    <>
      <MenuOrderDialogContent
        viewOnly={viewOnly}
        athleteId={athleteId}
        athleteUserId={athleteUserId}
        targetMacros={mealTemplate && getMealTemplateMacroTargets(mealTemplate)}
        mealMenu={mealMenu}
        menuItemIds={mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances.map(mia => mia.menuItem.id))}
        mealMenuDiningStations={mealMenuDiningStations}
        pickupTime={pickupTime}
        onChangePickupTime={handleSetPickupTime}
        orderPickupTimes={orderPickupTimes}
        menuOrderingClosed={menuOrderingClosed}
        mealMenuTimezone={mealMenu.timezone}
        menuOrderItems={menuOrderItems}
        onChangeMenuOrderItems={setMenuOrderItems}
        avoidFoodGroupIds={avoidFoodGroupIds}
        openTimePicker={openTimePicker}
        orderStatus="new"
        menuOrders={menuOrders}
        athleteOrderState={athleteOrderState}
        athleteOrderStateDispatch={athleteOrderStateDispatch}
        orderButtons={
          menuOrderItems.length > 0 && (
            <div className={classes.orderButtons}>
              <Button onClick={handleDone} className={classes.orderButton}>
                {actionText}
              </Button>
            </div>
          )
        }
      />
      <Dialog open={fixOrderItemsAlertOpen} onClose={() => setFixOrderItemsAlertOpen(false)}>
        <DialogTitle title="Invalid Order" onClose={onClose} />
        <DialogContent>
          <DialogContentText>Fix the menu order items highlighted in red to proceed</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFixOrderItemsAlertOpen(false)}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuOrderNewDialogContent;
