import { MenuItem, SxProps, TextField } from "@mui/material";
import { round } from "@notemeal/shared-ui";
import { formatTime, parseTime } from "@notemeal/utils-date-time";
import { addMinutes } from "date-fns";
import { useMenusStyles } from "../styles";
import { getDurationOptions } from "./utils";

const HIDDEN_DURATION_OPTIONS = [
  75, 105, 135, 165, 195, 225, 255, 285, 315, 330, 345, 360, 375, 390, 405, 420, 435, 450, 465, 480, 495, 510, 525, 540, 555, 570, 585, 600,
];

interface MenuDurationSelectProps {
  value: number;
  startTime: string;
  onChange: (value: number) => void;
  sx?: SxProps;
}

const formatDuration = (dur: number) => `${dur >= 60 ? round(dur / 60, 2) : dur} ${dur === 60 ? "hr" : dur > 60 ? "hrs" : "mins"}  `;

export const MenuDurationSelect = ({ value, onChange, startTime, sx }: MenuDurationSelectProps) => {
  const menuClasses = useMenusStyles();

  const startDateTime = parseTime(startTime);

  const formatTimeAfterStart = (duration: number) => {
    return `${formatTime(addMinutes(startDateTime, duration), {
      alwaysShowMinutes: true,
    })} (${formatDuration(duration)})`;
  };

  return (
    <TextField
      select
      label="End"
      margin="dense"
      value={value}
      sx={sx}
      onChange={e => onChange(parseInt(e.target.value))}
      SelectProps={{
        classes: {
          select: menuClasses.selectRoot,
        },
        MenuProps: { sx: { maxHeight: "calc(100vh - 300px)" } },
      }}
    >
      {getDurationOptions(startDateTime, value).map(dur => {
        return (
          <MenuItem key={dur} value={dur}>
            {formatTimeAfterStart(dur)}
          </MenuItem>
        );
      })}
      {HIDDEN_DURATION_OPTIONS.map(dur => (
        <MenuItem
          sx={dur !== value ? { display: "none" } : undefined}
          key={dur}
          value={dur}>
          {formatTimeAfterStart(dur)}
        </MenuItem>
      ))}
    </TextField>
  );
};
