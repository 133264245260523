import PlusOneIcon from "@mui/icons-material/PlusOne";
import { createFilterOptions, ListItemIcon, Typography } from "@mui/material";
import { newServingAmount } from "@notemeal/shared-ui";
import { useState } from "react";
import { MenuItemFormFragment, NutritionixBrandedFood, useAddNixBrandedFoodMutation } from "../../../types";
import { useServingAmountModeContext } from "../../ServingAmounts/contexts/ServingAmountMode";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import { newMenuItemState } from "../utils";
import BaseMenuItem from "./BaseMenuItem";
import { ONE_OFF_OPTION } from "./utils";

type RestaurantFood = NutritionixBrandedFood & {
  __typename: "RestaurantFood";
};

type Option = RestaurantFood | typeof ONE_OFF_OPTION;

interface MenuItemSearchBarProps {
  usedMenuItemNames: string[];
  onCreate: (name: string, isOneOff: boolean) => void;
  onSelectRestaurantFood: (menuItem: MenuItemFormFragment) => void;
}

const RestaurantMenuItemSearchBar = ({ onCreate, usedMenuItemNames, onSelectRestaurantFood }: MenuItemSearchBarProps) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const servingAmountModeContext = useServingAmountModeContext();
  const restaurantNutritionixFoods: RestaurantFood[] = (
    servingAmountModeContext.type === "restaurant" ? servingAmountModeContext.restaurantNutritionixFoods : []
  ).map(rf => ({ ...rf, __typename: "RestaurantFood" }));

  const { setMessage } = useSnackbar();

  const [addNixBrandedFood] = useAddNixBrandedFoodMutation({
    onError: e => {
      setMessage("error", `Failed to add food: ${e.name}`);
      setLoading(false);
    },
    onCompleted: data => {
      if (data.addNixBrandedFood.food) {
        const food = data.addNixBrandedFood.food;
        const newMenuItem = newMenuItemState(food.name, false);
        onSelectRestaurantFood({
          ...newMenuItem.menuItem,
          servingAmounts: [newServingAmount(data.addNixBrandedFood.food.defaultServing, 1)],
        });
      } else {
        setMessage("error", "Unable to add food please try adding again or try adding a different food.");
      }
      setLoading(false);
    },
  });

  const handleAddNixBrandedFood = (food: NutritionixBrandedFood) => {
    setLoading(true);
    addNixBrandedFood({
      variables: {
        input: { nixItemId: food.nixItemId, nixBrandType: food.brand.type },
      },
    });
  };

  const renderOption = (option: Option) => {
    if (option.__typename === "ONE_OFF_OPTION") {
      const text = inputValue ? `Use "${inputValue}" as one-off menu item` : `Add one-off menu item`;
      return (
        <>
          <ListItemIcon>
            <PlusOneIcon />
          </ListItemIcon>
          <Typography>{text}</Typography>
        </>
      );
    } else {
      return <Typography>{option.name}</Typography>;
    }
  };

  const handleSelect = (option: Option | string | null) => {
    if (typeof option === "string" || option === null) {
      return;
    }
    if (option.__typename === "ONE_OFF_OPTION") {
      onCreate(inputValue, true);
    } else {
      handleAddNixBrandedFood(option);
    }
  };

  const getOptionLabel = (option: Option | string) =>
    typeof option === "string" ? option : option.__typename === "RestaurantFood" ? option.name : inputValue;

  const filterOptions = createFilterOptions({
    stringify: (option: Option) => (option.__typename === "ONE_OFF_OPTION" ? inputValue : option.name),
  });

  const menuItems = restaurantNutritionixFoods.filter(menuItem => !usedMenuItemNames.includes(menuItem.name));

  const options: Option[] = [ONE_OFF_OPTION, ...menuItems];

  return (
    <BaseMenuItem<Option>
      loading={loading}
      inputValue={inputValue}
      setInputValue={setInputValue}
      getOptionLabel={getOptionLabel}
      options={options}
      renderOption={renderOption}
      filterOptions={filterOptions}
      handleSelect={handleSelect}
    />
  );
};

export default RestaurantMenuItemSearchBar;
