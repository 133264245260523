import { Typography } from "@mui/material";

export const getFileKeyFromUrl = (presignedUrl: string): string | null => {
  try {
    // Create a URL object to easily access URL components
    const url = new URL(presignedUrl);

    // Remove the leading "/" to get just "path/to/your-file.png"
    return url.pathname.substring(1);
  } catch (err) {
    console.error({
      type: "misc",
      message: (err as Error).message,
    });
    return null;
  }
};

const getFileType = (fileUrl: string) => {
  const extension = (fileUrl.split(".").pop() || "").toLowerCase();
  switch (extension) {
    // Office Files
    case "docx":
    case "xlsx":
    case "pptx":
      return "office";

    // PDF
    case "pdf":
      return "pdf";

    // Images
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
    case "svg":
      return "image";

    // Default case for unsupported file types
    default:
      return "unsupported";
  }
};

export const getFileTypeForResource = (fileKey: string) => {
  const lastDotIndex = fileKey.lastIndexOf(".");
  // get the file type from the last dot in the file key
  if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileKey.length - 1) {
    return "";
  }
  return `${fileKey.slice(lastDotIndex + 1)}`;
};
export const getContentForFilePreview = (fileName: string, fileUrl: string, fileKey: string) => {
  const fileType = getFileType(fileKey);

  let content;
  if (fileType === "office") {
    content = (
      <iframe
        title={fileName}
        style={{ border: "none", objectFit: "contain" }}
        width="100%"
        height="100%"
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`}
      />
    );
  } else if (fileType === "pdf") {
    content = (
      <iframe
        src={fileUrl}
        title={fileName}
        allowFullScreen
        style={{ border: "none", objectFit: "contain" }}
        width="100%"
        height="100%" />
    );
  } else if (fileType === "image") {
    content = <img
      src={fileUrl}
      alt={fileName}
      style={{ width: "100%", height: "100%", objectFit: "contain" }} />;
  } else {
    content = <Typography>Unsupported file type</Typography>;
  }

  return content;
};
