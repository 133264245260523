import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import GetAppIcon from "@mui/icons-material/GetApp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import {
    Button,
    Chip,
    IconButton,
    InputAdornment,
    TableCell,
    TableRow,
    TableSortLabel,
    TextField,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import { round, useDateFormatting } from "@notemeal/shared-ui";
import { parseDateTime } from "@notemeal/utils-date-time";
import { sortByKey } from "@notemeal/utils-sort";
import { getNavOrgAthlete } from "apps/web/src/pages/Auth/Org/Athlete/AthletePaths";
import { getRowsForTablePage, useOffsetPagination } from "apps/web/src/utils/pagination";
import React, { Fragment, ReactNode, useState } from "react";
import { Link } from "react-router-dom-v5-compat";
import TablePage from "../../universal/TablePage";
import { exportToExcel } from "./Export";
import {
    Aggregations,
    AthleteAnthropometryParentRow,
    AthleteAnthropometryRow,
    OutlierFlag,
    TeamPageAthletes,
    emptyAnthropometryEntry,
    getHighlightColor,
    getOutlierFlag,
    getPercentileBand,
    getPercentileClass,
    getPercentileExplanation,
    getPercentileLabel,
    useStyles,
} from "./utils";

export interface AnthropometryEntryTableProps {
  /* the most recent anthropometry entry for each athlete */
  mostRecentAnthropometryEntries: AthleteAnthropometryParentRow[];
  /* all anthropometry entries from all athletes */
  anthropometryEntries: AthleteAnthropometryRow[];
  /* athletes to display in the table */
  rows: TeamPageAthletes;
  filteredAggregations: Aggregations;
  teamAggregations: Aggregations;
  exportFilename: string;
  highlight: boolean;
  theme: Theme;
}

export const AnthropometrySummaryTable = ({
  exportFilename,
  rows: athleteRows,
  mostRecentAnthropometryEntries,
  anthropometryEntries,
  teamAggregations,
  filteredAggregations,
  theme,
  highlight,
}: AnthropometryEntryTableProps) => {
  const utilsClasses = useStyles();
  const paginationHooks = useOffsetPagination();
  const { page, limit, queryText, onChangeQueryText } = paginationHooks;
  const { formatDateWithLocale } = useDateFormatting();
  const [sortOrderAscending, setSortOrderAscending] = useState(true);
  const [sortColumnName, setSortColumnName] = useState<keyof AthleteAnthropometryParentRow>("name");
  const [expandedParentIds, setExpandedParentIds] = useState<Set<string>>(new Set());
  const parentRows = athleteRows.map(({ id, firstName, lastName, position }) => {
    const { parentAthleteId, ...rest } = mostRecentAnthropometryEntries.find(ae => ae.parentAthleteId === id) || {
      ...emptyAnthropometryEntry,
      type: "estimate",
      parentAthleteId: null,
    };
    return {
      // This is the "parentRow"
      ...rest,
      id,
      athleteId: id,
      name: `${lastName}, ${firstName ? firstName[0] : ""}.`,
      firstName: firstName || undefined,
      lastName: lastName || undefined,
      position: position ? position.name : "",
    };
  });
  const getRows = () =>
    sortByKey<AthleteAnthropometryParentRow>(parentRows, sortColumnName, { reverse: !sortOrderAscending }).filter(filterRow) || [];
  const filterRow = (parentRow: AthleteAnthropometryParentRow) => {
    const {
      name,
      position,
      datetime,
      leanBodyMass,
      leanBodyMassChange,
      bodyFatMass,
      bodyFatMassChange,
      percentBodyFat,
      percentBodyFatChange,
      weight,
      weightChange,
      boneMineralDensityZScore,
      boneMineralContent,
    } = parentRow;

    const rowText = `${name} ${position} ${datetime} ${leanBodyMass} ${leanBodyMassChange} ${bodyFatMass} ${bodyFatMassChange} ${percentBodyFat} ${percentBodyFatChange} ${weight} ${weightChange} ${boneMineralDensityZScore} ${boneMineralContent}`;

    return rowText.toLowerCase().indexOf(queryText.toLowerCase()) !== -1;
  };
  const getSortOrder = () => (sortOrderAscending ? "asc" : "desc");
  const toggleSortOrder = (fieldName: keyof AthleteAnthropometryParentRow) => {
    setSortColumnName(fieldName);
    setSortOrderAscending(!sortOrderAscending);
  };
  const rows = getRows();
  const pageRows = getRowsForTablePage({ rows, limit, page });

  const toggleParentRow = (id: string) => {
    if (!expandedParentIds.has(id)) {
      setExpandedParentIds(new Set(expandedParentIds).add(id));
    } else {
      const newSet = new Set(expandedParentIds);
      newSet.delete(id);
      setExpandedParentIds(newSet);
    }
  };

  const exportData = () => {
    // get the anthro entries from the rows, preserving the sort on the rows
    const entries = rows.flatMap(row => {
      return anthropometryEntries.filter(entry => entry.parentAthleteId === row.athleteId);
    });

    exportToExcel({
      exportFilename,
      anthropometryEntries: entries,
      mostRecentAnthropometryEntries: parentRows,
      teamAggregations,
      filteredAggregations,
      theme,
      highlight,
    });
  };

  const header = (
    <>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Clear">
                <IconButton onClick={() => onChangeQueryText("")} size="large">
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        placeholder="Search"
        value={queryText}
        onChange={e => onChangeQueryText(e.target.value)}
      />
      <Tooltip title="Export to Excel">
        <IconButton onClick={exportData} size="large">
          <GetAppIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const getSummaryCellFragment = (avgMetric: keyof Aggregations, stdMetric: keyof Aggregations) => {
    return (
      <React.Fragment>
        <Typography
          variant="subtitle1"
          component="p"
          classes={{ root: utilsClasses.denseText }}>
          Avg: {round(filteredAggregations[avgMetric])}
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          classes={{ root: utilsClasses.denseText }}>
          Std: {round(filteredAggregations[stdMetric])}
        </Typography>
      </React.Fragment>
    );
  };

  const getTableSummaryRow = () => (
    <Fragment key={`tableSummaryRow`}>
      <TableRow>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>{getSummaryCellFragment("leanBodyMassAvg", "leanBodyMassStdev")}</TableCell>
        <TableCell>{getSummaryCellFragment("leanBodyMassChangeAvg", "leanBodyMassChangeStdev")}</TableCell>
        <TableCell>{getSummaryCellFragment("bodyFatMassAvg", "bodyFatMassStdev")}</TableCell>
        <TableCell>{getSummaryCellFragment("bodyFatMassChangeAvg", "bodyFatMassChangeStdev")}</TableCell>
        <TableCell>{getSummaryCellFragment("percentBodyFatAvg", "percentBodyFatStdev")}</TableCell>
        <TableCell>{getSummaryCellFragment("percentBodyFatChangeAvg", "percentBodyFatChangeStdev")}</TableCell>
        <TableCell>{getSummaryCellFragment("weightAvg", "weightStdev")}</TableCell>
        <TableCell>{getSummaryCellFragment("weightChangeAvg", "weightChangeStdev")}</TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    </Fragment>
  );

  const getTableHeaderRowCell = (name: string, fieldName: keyof AthleteAnthropometryParentRow) => (
    <>
      <TableCell>
        <TableSortLabel direction={getSortOrder()} onClick={() => toggleSortOrder(fieldName)}>
          <Typography sx={{ fontWeight: "bold" }} variant="body2">
            {name}
          </Typography>
        </TableSortLabel>
      </TableCell>
    </>
  );

  type HeaderColumn = [string, keyof AthleteAnthropometryParentRow];
  const headerColumns: Array<HeaderColumn> = [
    ["Pos", "position"],
    ["Date", "datetime"],
    ["Lean", "leanBodyMass"],
    ["Δ Lean", "leanBodyMassChange"],
    ["Fat", "bodyFatMass"],
    ["Δ Fat", "bodyFatMassChange"],
    ["% BF", "percentBodyFat"],
    ["Δ % BF", "percentBodyFatChange"],
    ["Weight", "weight"],
    ["Δ Weight", "weightChange"],
    ["BMD Z-Score", "boneMineralDensityZScore"],
    ["BMC", "boneMineralContent"],
  ];

  const getTableHeaderRow = () => (
    <TableRow>
      <TableCell />
      {getTableHeaderRowCell("Name", "name")}
      <TableCell>
        <Typography sx={{ fontWeight: "bold" }} variant="body2">
          Rank
        </Typography>
      </TableCell>
      {headerColumns.map(([name, fieldName]: [string, keyof AthleteAnthropometryParentRow]) => getTableHeaderRowCell(name, fieldName))}
    </TableRow>
  );

  const getRank = (rowData: AthleteAnthropometryParentRow) => {
    if (!!rowData.parentAthleteId) {
      return null;
    } // Only show this Chip for Parent rows
    const percentile = getPercentileBand(mostRecentAnthropometryEntries, rowData, "percentBodyFat");
    if (percentile === null) {
      return null;
    } else {
      const cls = getPercentileClass({
        percentile,
        positiveIsGood: false,
        classes: utilsClasses,
      });
      const label = getPercentileLabel({
        percentile,
        positiveIsGood: false,
        classes: utilsClasses,
      });
      const exp = getPercentileExplanation(percentile, false);
      return (
        <Tooltip title={exp || ""}>
          <Chip
            label={label}
            className={utilsClasses.denseChip}
            classes={{ label: utilsClasses.denseChipLabel, root: cls }} />
        </Tooltip>
      );
    }
  };

  const getOutlierTooltip = (outlierFlag: OutlierFlag) =>
    outlierFlag === 1 || outlierFlag === -1
      ? "Outlier: value outside of +/- 1 std. devs"
      : outlierFlag === 2 || outlierFlag === -2
      ? "Outlier: value outside of +/- 2 std. devs"
      : "Not an outlier";

  const getTableRow = (parentRow: AthleteAnthropometryParentRow, hasChildren: boolean) => {
    const {
      id,
      name,
      position,
      datetime,
      leanBodyMass,
      leanBodyMassChange,
      bodyFatMass,
      bodyFatMassChange,
      percentBodyFat,
      percentBodyFatChange,
      weight,
      weightChange,
      boneMineralDensityZScore,
      boneMineralContent,
    } = parentRow;
    const leanBodyMassOutlierFlag = getOutlierFlag(parentRow, "leanBodyMass", filteredAggregations);
    const leanBodyMassHighlightColor = (highlight && getHighlightColor(leanBodyMassOutlierFlag)) || "inherit";
    const leanBodyMassTooltip = getOutlierTooltip(leanBodyMassOutlierFlag);
    const bodyFatMassOutlierFlag = getOutlierFlag(parentRow, "bodyFatMass", filteredAggregations);
    const bodyFatMassHighlightColor = (highlight && getHighlightColor(bodyFatMassOutlierFlag)) || "inherit";
    const bodyFatMassTooltip = getOutlierTooltip(bodyFatMassOutlierFlag);
    const percentBodyFatOutlierFlag = getOutlierFlag(parentRow, "percentBodyFat", filteredAggregations);
    const percentBodyFatHighlightColor = (highlight && getHighlightColor(percentBodyFatOutlierFlag)) || "inherit";
    const percentBodyFatTooltip = getOutlierTooltip(percentBodyFatOutlierFlag);

    return (
      <Fragment key={id}>
        <TableRow onClick={() => hasChildren && toggleParentRow(id)}>
          <TableCell>
            {hasChildren && !expandedParentIds.has(id) && <ChevronRightIcon />}
            {hasChildren && expandedParentIds.has(id) && <KeyboardArrowDownIcon />}
          </TableCell>
          <TableCell>
            <Button
              variant="text"
              component={Link}
              to={getNavOrgAthlete(parentRow.athleteId || "")}>
              {name}
            </Button>
          </TableCell>
          <TableCell>{getRank(parentRow)}</TableCell>
          <TableCell>{position}</TableCell>
          <TableCell>{datetime ? formatDateWithLocale(parseDateTime(datetime)) : null}</TableCell>
          <TableCell className={utilsClasses[leanBodyMassHighlightColor]}>
            {leanBodyMassOutlierFlag !== 0 && (
              <Tooltip title={leanBodyMassTooltip}>
                <Typography>{leanBodyMass}</Typography>
              </Tooltip>
            )}
            {leanBodyMassOutlierFlag === 0 && <Typography>{leanBodyMass}</Typography>}
          </TableCell>
          <TableCell>{leanBodyMassChange}</TableCell>
          <TableCell className={utilsClasses[bodyFatMassHighlightColor]}>
            {bodyFatMassOutlierFlag !== 0 && (
              <Tooltip title={bodyFatMassTooltip}>
                <Typography>{bodyFatMass}</Typography>
              </Tooltip>
            )}
            {bodyFatMassOutlierFlag === 0 && <Typography>{bodyFatMass}</Typography>}
          </TableCell>
          <TableCell>{bodyFatMassChange}</TableCell>
          <TableCell className={utilsClasses[percentBodyFatHighlightColor]}>
            {percentBodyFatOutlierFlag !== 0 && (
              <Tooltip title={percentBodyFatTooltip}>
                <Typography>{percentBodyFat}</Typography>
              </Tooltip>
            )}
            {percentBodyFatOutlierFlag === 0 && <Typography>{percentBodyFat}</Typography>}
          </TableCell>
          <TableCell>{percentBodyFatChange}</TableCell>
          <TableCell>{weight}</TableCell>
          <TableCell>{weightChange}</TableCell>
          <TableCell>{boneMineralDensityZScore}</TableCell>
          <TableCell>{boneMineralContent}</TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const getTableChildRow = (childRow: AthleteAnthropometryRow, index: number) => {
    const {
      id,
      datetime,
      leanBodyMass,
      leanBodyMassChange,
      bodyFatMass,
      bodyFatMassChange,
      percentBodyFat,
      percentBodyFatChange,
      weight,
      weightChange,
      boneMineralDensityZScore,
      boneMineralContent,
    } = childRow;
    return (
      <Fragment key={`${id} ${index}`}>
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell>{datetime ? formatDateWithLocale(parseDateTime(datetime)) : null}</TableCell>
          <TableCell>{leanBodyMass}</TableCell>
          <TableCell>{leanBodyMassChange}</TableCell>
          <TableCell>{bodyFatMass}</TableCell>
          <TableCell>{bodyFatMassChange}</TableCell>
          <TableCell>{percentBodyFat}</TableCell>
          <TableCell>{percentBodyFatChange}</TableCell>
          <TableCell>{weight}</TableCell>
          <TableCell>{weightChange}</TableCell>
          <TableCell>{boneMineralDensityZScore}</TableCell>
          <TableCell>{boneMineralContent}</TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const getTableBodyRows = () => {
    let bodyRows: ReactNode[] = [];
    bodyRows.push(getTableSummaryRow());
    bodyRows = bodyRows.concat(
      pageRows.flatMap(pageRow => {
        const children = anthropometryEntries.filter(row => row.parentAthleteId === pageRow.athleteId);
        const hasChildren = children.length > 1;
        let rows: JSX.Element[] = [];
        rows.push(getTableRow(pageRow, hasChildren));
        if (expandedParentIds.has(pageRow.id) && hasChildren) {
          rows = rows.concat(children.map((athleteAnthropometry, index) => getTableChildRow(athleteAnthropometry, index)));
        }

        return rows;
      })
    );

    return bodyRows;
  };

  return (
    <TablePage
      header={header}
      tableHeaderRow={getTableHeaderRow()}
      tableBodyRows={getTableBodyRows()}
      loading={false}
      paginationHooks={paginationHooks}
      total={parentRows.length}
    />
  );
};
