import TodayIcon from "@mui/icons-material/Today";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { useDateFormatting } from "@notemeal/shared-ui";
import { parseDateWithoutTz, serializeDate } from "@notemeal/utils-date-time";
import { isCommentNotification, isFoodLogNotification } from "apps/web/src/components/Notification/utils";
import { useState } from "react";
import { useCalendarContext } from "./CalendarContext";

interface TimelineDateColumnSelectProps {
  selectedDate: string | null;
  onChangeSelectedDate: (date: string | null) => void;
}

export const TimelineDateColumnSelect = ({ selectedDate, onChangeSelectedDate }: TimelineDateColumnSelectProps) => {
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLElement | null>(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const { mealsByDate, onChangeMonth } = useCalendarContext();
  const { formatDateWithLocale } = useDateFormatting();

  const mealDays = new Set<string>();
  const notificationDays = new Set<string>();
  mealsByDate.forEach(meal => {
    const { date, mealsAndItemsCount, notifications } = meal;
    if (mealsAndItemsCount) {
      mealDays.add(date);
    }
    if (
      notifications.some(
        notification => notification.viewedAt === null && (isCommentNotification(notification) || isFoodLogNotification(notification))
      )
    ) {
      notificationDays.add(date);
    }
  });

  return (
    <>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography variant="body1" ref={r => r && setCalendarAnchorEl(r as HTMLElement)}>
          <b>{selectedDate ? <span>{formatDateWithLocale(selectedDate)}</span> : "Select Date"}</b>
        </Typography>
        <IconButton
          size="small"
          sx={{ p: 0 }}
          onClick={e => setCalendarOpen(true)}>
          <TodayIcon />
        </IconButton>
      </Box>
      {calendarAnchorEl && calendarOpen && (
        <Popover
          anchorEl={calendarAnchorEl}
          open={calendarOpen}
          onClose={() => setCalendarOpen(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DateCalendar
            referenceDate={selectedDate ? parseDateWithoutTz(selectedDate) : new Date()}
            disableHighlightToday
            onChange={(date: Date | null) => {
              if (date) {
                onChangeSelectedDate(serializeDate(date));
                setCalendarOpen(false);
              }
            }}
            onMonthChange={date => {
              if (date) {
                onChangeMonth(serializeDate(date));
              }
            }}
            slots={{ day: props => <Day
              {...props}
              mealDays={mealDays}
              notificationDays={notificationDays} /> }}
          />
        </Popover>
      )}
    </>
  );
};

type DayProps = {
  mealDays: Set<string>;
  notificationDays: Set<string>;
};

const Day = (props: PickersDayProps<Date> & DayProps) => {
  const { mealDays, notificationDays, ...other } = props;
  const day = other.day ? serializeDate(other.day) : "";
  const isMealDay = mealDays.has(day);
  const isNotificationDay = notificationDays.has(day);
  const sharedStyle = {
    position: "absolute",
    height: 0,
    width: 0,
    border: "2px solid",
    borderRadius: 4,
    transform: "translateX(1px)",
    top: "80%",
  };

  return (
    <Box sx={{ position: "relative" }}>
      <PickersDay
        {...other}
        selected={false}
        disableMargin
        onFocus={e => e.stopPropagation()}></PickersDay>
      <Box sx={{ ...(isMealDay && { ...sharedStyle, borderColor: "info.main", right: isNotificationDay ? "60%" : "50%" }) }} />
      <Box sx={{ ...(isMealDay && isNotificationDay && { ...sharedStyle, borderColor: "error.main", right: "40%" }) }} />
    </Box>
  );
};
