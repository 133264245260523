import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";

import { sortByKey } from "@notemeal/utils-sort";
import { MenuItemAppearancePreviewReadonly } from "../../../components/MenuItemAppearance/MenuItemAppearancePreview/MenuItemAppearancePreviewReadonly";
import { RestaurantMenuLinkState } from "./types";

interface RestauranMenuLinkReadonlyFormProps {
  restaurantMenuLink: RestaurantMenuLinkState;
}

const RestauranMenuLinkReadonlyForm = ({ restaurantMenuLink }: RestauranMenuLinkReadonlyFormProps) => {
  return (
    <>
      <Box sx={{ px: 2, py: 1, display: "flex", alignItems: "center" }}>
        <Typography variant="h3">{restaurantMenuLink.restaurant.name} Menu</Typography>
      </Box>
      {sortByKey(restaurantMenuLink.restaurantMenu.sections, "position")
        .filter(s => s.menuItemAppearances.filter(mia => mia.included).length > 0)
        .map(section => (
          <Fragment key={section.id}>
            <Box sx={{ p: 1, display: "flex", alignItems: "center" }}>
              <Typography variant="h3">{section.name}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {sortByKey(section.menuItemAppearances, "position")
                .filter(mia => mia.included)
                .map(mia => (
                  <MenuItemAppearancePreviewReadonly
                    key={mia.menuItem.id}
                    sx={{ width: "calc(50% - 16px)", m: 1 }}
                    menuItemAppearance={mia}
                    orderCount={null}
                  />
                ))}
            </Box>
          </Fragment>
        ))}
    </>
  );
};

export default RestauranMenuLinkReadonlyForm;
