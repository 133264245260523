import LinkIcon from "@mui/icons-material/Link";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, MenuItem, TextField, Typography } from "@mui/material";
import { invalidPhoneNumber } from "@notemeal/utils-phone-number";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import React, { useState } from "react";
import AddPhoneNumberOrEmailDialog from "../../../../components/global/AddPhoneNumberOrEmailDialog";
import { AccountVerificationMethod } from "../../../../types";
import { invalidEmail } from "../../../../utils/invalidEmail";

interface EnableAccountModalProps {
  onClose: () => void;
  onEnable: (method: AccountVerificationMethod) => Promise<void>;
  open: boolean;
  athlete: { email: string | null; phoneNumber: string | null; id: string };
  activationLink: string | null;
}

export const EnableMobileAccountModal = ({ onClose, open, onEnable, athlete }: EnableAccountModalProps) => {
  const [activationMethod, setActivationMethod] = useState<AccountVerificationMethod>("txt");
  const [activationStatus, setActivationStatus] = useState<null | "saving" | "done">(null);
  const [loading, setLoading] = useState(false);

  const emailIsValid = () => {
    if (!athlete.email || invalidEmail(athlete.email)) {
      return false;
    }
    return true;
  };

  const phoneIsValid = () => {
    if (!athlete.phoneNumber || invalidPhoneNumber(athlete.phoneNumber)) {
      return false;
    }
    return true;
  };
  const handleEnable = (method: AccountVerificationMethod) => {
    setActivationStatus("saving");
    if (
      (emailIsValid() && method === "email") ||
      (phoneIsValid() && method === "txt") ||
      (method === "selfServiceLink" && (emailIsValid() || phoneIsValid()))
    ) {
      onEnable(method);
      onClose();
    }
  };

  const handleEnableWithoutValidation = () => {
    // This only gets called via the `onAdd()` callback in AddPhoneNumberOrEmailDialog
    // so we invariantly have a valid email/phone saved in the DB
    onEnable(activationMethod);
    onClose();
  };
  const handleGenerateLink = () => {
    setLoading(true);
    setActivationMethod("selfServiceLink");
    handleEnable("selfServiceLink");
  };

  const isGenerateLinkButtonDisabled = () => {
    return loading || (!emailIsValid() && !phoneIsValid());
  };

  return (
    <>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={onClose}>
        <DialogTitle title="Enable Mobile App" onClose={onClose} />
        <DialogContent>
          <Box style={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="body1Semibold">Send Activation Link</Typography>
            <Typography variant="body2" color="mediumEmphasisTextLight">
              Select the option to send the account activation link
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 2, pb: 10 }}>
            <TextField
              select
              label="Send Activation Link via"
              defaultValue={"txt"}
              sx={{ width: "338px" }}
              onChange={e => setActivationMethod(e.target.value as AccountVerificationMethod)}
            >
              <MenuItem value={"txt"}>Text</MenuItem>
              <MenuItem value={"email"}>Email</MenuItem>
            </TextField>
            <Button sx={{ height: "36px", mt: 3 }} onClick={() => handleEnable(activationMethod)}>
              Send
            </Button>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <TWTooltip
            title=""
            subcopy={isGenerateLinkButtonDisabled() ? "Athlete must have an email or phone # in their profile to generate link" : ""}
            disableHoverListener={!isGenerateLinkButtonDisabled()}
          >
            <span>
              <Button
                variant="outlined"
                disabled={isGenerateLinkButtonDisabled()}
                onClick={handleGenerateLink}
                startIcon={<LinkIcon sx={{ ml: 0.5 }} />}
              >
                {loading ? <CircularProgress size={24} /> : "Generate Link"}
              </Button>
            </span>
          </TWTooltip>
        </DialogActions>
        {!emailIsValid() && activationStatus === "saving" && (
          <AddPhoneNumberOrEmailDialog
            open={!emailIsValid() && activationStatus === "saving"}
            onClose={() => setActivationStatus(null)}
            onAdd={handleEnableWithoutValidation}
            athleteId={athlete.id}
            type="email"
          />
        )}
        {!phoneIsValid() && activationStatus === "saving" && (
          <AddPhoneNumberOrEmailDialog
            open={!phoneIsValid() && activationStatus === "saving"}
            onClose={() => setActivationStatus(null)}
            onAdd={handleEnableWithoutValidation}
            athleteId={athlete.id}
            type="phoneNumber"
          />
        )}
      </Dialog>
    </>
  );
};
