import { useProgressViews } from "@notemeal/shared-ui";
import { Macros } from "@notemeal/shared-utils-macro-protocol";
import { MacroProgressViews } from "apps/web/src/types";
import { MacrosProgressBarAndDials } from "./MacrosProgressBarAndDials";

interface MacrosProgressProps {
  currentMacros: Macros;
  targetMacros: Macros | null;
  variant?: "lg" | "md" | "sm";
  disableChangeMode?: boolean;
  macroProgressViews: MacroProgressViews;
}

export const MacrosProgress = ({
  currentMacros,
  targetMacros,
  variant = "lg",
  disableChangeMode = false,
  macroProgressViews,
}: MacrosProgressProps) => {
  const { mode, onChangeMode } = useProgressViews({
    macroProgressViews,
    hasTargets: !!targetMacros,
  });

  const handleChangeMode = disableChangeMode ? () => {} : onChangeMode;

  if (macroProgressViews === "none") {
    return null;
  }

  return (
    <MacrosProgressBarAndDials
      mode={mode}
      onChangeMode={handleChangeMode}
      currentMacros={currentMacros}
      targetMacros={targetMacros}
      variant={variant}
    />
  );
};
