import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BarChartIcon from "@mui/icons-material/BarChart";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import PrintIcon from "@mui/icons-material/Print";
import ReceiptIcon from "@mui/icons-material/Receipt";
import VisibilityOnIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { Card, CardContent, Divider, IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { reduceStatuses } from "@notemeal/shared-ui";
import { MenuOrderItemStatus } from "../../../types";
import SummaryActionButton from "./Buttons/SummaryActionButton";
import { GenericOrderPreview } from "./types";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    orderSummaryText: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    topRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      marginBottom: spacing(1),
    },
    flexCenterRow: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    flexCenterColumn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    square: {
      height: "120px",
      width: "120px",
      display: "flex",
    },
    icon: {
      fontSize: 64,
    },
    eye: {
      color: grey[500],
    },
    spacer: {
      flexGrow: 1,
    },
    paddingLeft: {
      paddingLeft: spacing(2),
    },
    dividerMargin: {
      margin: spacing(2),
    },
    orderContainer: {
      padding: spacing(),
      maxHeight: "calc(100vh - 128px)",
      overflowY: "auto",
    },
    contentRoot: {
      padding: spacing(1.5),
    },
    orderSummaryMsgColumn: {
      display: "flex",
      flexDirection: "column",
    },
    orderCardContent: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

interface KdsTicketSummaryProps {
  sortedOrders: GenericOrderPreview[];
  setStatusWhitelist: (ls: MenuOrderItemStatus[]) => void;
  statusWhitelist: MenuOrderItemStatus[];
  setPrintAll: (b: boolean) => void;
  setDisplayMenuItemDialog: (b: boolean) => void;
  inBulkSelect: boolean;
  activateBulkSelect: () => void;
  proceedBulkSelect: () => void;
  selectedOrderIds: string[];
  selectedPlateOrderIds: string[];
  cancelBulkSelect: () => void;
  selectAllShownOrders: () => void;
  deselectAll: () => void;
}
const getStatus = (o: GenericOrderPreview) => {
  return reduceStatuses(o.itemsByDiningStation.map(i => i.status));
};

const KdsTicketSummary = ({
  statusWhitelist,
  setStatusWhitelist,
  setDisplayMenuItemDialog,
  setPrintAll,
  sortedOrders,
  inBulkSelect,
  activateBulkSelect,
  proceedBulkSelect,
  selectedOrderIds,
  selectedPlateOrderIds,
  cancelBulkSelect,
  selectAllShownOrders,
  deselectAll,
}: KdsTicketSummaryProps) => {
  const classes = useStyles();
  const statusDisplayToggle = (status: MenuOrderItemStatus) =>
    statusWhitelist.includes(status) ? (
      <IconButton onClick={() => setStatusWhitelist(statusWhitelist.filter(s => s !== status))} size="large">
        <VisibilityOnIcon className={classes.eye} fontSize="large" />
      </IconButton>
    ) : (
      <IconButton onClick={() => setStatusWhitelist([...statusWhitelist, status])} size="large">
        <VisibilityOffIcon className={classes.eye} fontSize="large" />
      </IconButton>
    );

  const renderActionSection = () => {
    if (inBulkSelect) {
      return (
        <>
          <div className={classes.topRow}>
            <div className={classes.flexCenterRow}>
              <SummaryActionButton
                icon={<CancelIcon sx={{ width: "64px", height: "64px", color: "error.main" }} />}
                text="Cancel Select"
                action={cancelBulkSelect}
              />
            </div>
            <div className={classes.flexCenterRow}>
              <SummaryActionButton
                icon={<ArrowForwardIcon sx={{ width: "64px", height: "64px" }} />}
                text={`Proceed (${[...selectedOrderIds, ...selectedPlateOrderIds].length})`}
                action={proceedBulkSelect}
                disabled={[...selectedOrderIds, ...selectedPlateOrderIds].length === 0}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.flexCenterRow}>
              <SummaryActionButton
                icon={<CheckBoxIcon sx={{ width: "64px", height: "64px", color: "success.main" }} />}
                text="Select Shown"
                action={selectAllShownOrders}
              />
            </div>
            <div className={classes.flexCenterRow}>
              <SummaryActionButton
                icon={<IndeterminateCheckBoxIcon sx={{ width: "64px", height: "64px", color: "error.main" }} />}
                text="Deselect All"
                action={deselectAll}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={classes.topRow}>
            <div className={classes.flexCenterRow}>
              <SummaryActionButton
                icon={<EditIcon classes={{ root: classes.icon }} />}
                text="Bulk Select"
                action={activateBulkSelect} />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.flexCenterRow}>
              <SummaryActionButton
                icon={<PrintIcon classes={{ root: classes.icon }} />}
                text="All"
                action={() => {
                  setPrintAll(true);
                }}
              />
            </div>
            <div className={classes.flexCenterRow}>
              <SummaryActionButton
                icon={<BarChartIcon classes={{ root: classes.icon }} />}
                text="Analyze"
                action={() => {
                  setDisplayMenuItemDialog(true);
                }}
              />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div key="first-kds-slide" className={classes.orderContainer}>
      <Card raised sx={{ borderWidth: "4px", borderStyle: "solid", borderColor: "info.light", height: "100%" }}>
        <CardContent classes={{ root: classes.contentRoot }}>
          <div className={classes.orderCardContent}>
            <div className={classes.orderSummaryMsgColumn}>
              {sortedOrders.length > 0 ? (
                <>
                  <div className={classes.orderSummaryText}>
                    <ReceiptIcon sx={{ color: "orderStatusNew.main" }} fontSize="large" />
                    <Typography
                      className={classes.paddingLeft}
                      variant="h3"
                      color="textSecondary">
                      {`  ${sortedOrders.filter(o => getStatus(o) === "new").length} new `}
                    </Typography>
                    <div className={classes.spacer} />
                    {statusDisplayToggle("new")}
                  </div>
                  <div className={classes.orderSummaryText}>
                    <WhatshotIcon sx={{ color: "orderStatusCooking.main" }} fontSize="large" />
                    <Typography
                      className={classes.paddingLeft}
                      variant="h3"
                      color="textSecondary">
                      {`  ${sortedOrders.filter(o => getStatus(o) === "cooking").length} cooking `}
                    </Typography>
                    <div className={classes.spacer} />
                    {statusDisplayToggle("cooking")}
                  </div>
                  <div className={classes.orderSummaryText}>
                    <CheckCircleIcon sx={{ color: "orderStatusDone.main" }} fontSize="large" />
                    <Typography
                      className={classes.paddingLeft}
                      variant="h3"
                      color="textSecondary">
                      {`  ${sortedOrders.filter(o => getStatus(o) === "done").length} done `}
                    </Typography>
                    <div className={classes.spacer} />
                    {statusDisplayToggle("done")}
                  </div>
                  <div className={classes.orderSummaryText}>
                    <CancelIcon sx={{ color: "orderStatusCancelled.main" }} fontSize="large" />
                    <Typography
                      className={classes.paddingLeft}
                      variant="h3"
                      color="textSecondary">
                      {`  ${sortedOrders.filter(o => getStatus(o) === "cancelled").length} cancelled `}
                    </Typography>
                    <div className={classes.spacer} />
                    {statusDisplayToggle("cancelled")}
                  </div>
                  <Divider className={classes.dividerMargin} />
                  {renderActionSection()}
                </>
              ) : (
                <Typography variant="h4" color="textSecondary">
                  No orders. 😅
                </Typography>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default KdsTicketSummary;
