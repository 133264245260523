import { OktaAuth, OktaAuthOptions } from "@okta/okta-auth-js";
import { environment } from "../environment";

const config: OktaAuthOptions = {
  scopes: ["openid", "profile", "email", "offline_access"],
  issuer: `https://${environment.VITE_TW_OKTA_DOMAIN}/oauth2/default`,
  clientId: environment.VITE_TW_OKTA_WEB_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  pkce: true,
};

export const oktaAuth = new OktaAuth(config);
