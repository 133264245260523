import CloseIcon from "@mui/icons-material/Close";
import { IconButton, List, ListItemSecondaryAction, Typography } from "@mui/material";
import { RestaurantMenuLinkPlateItemWithAppearance } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import MenuSelectionItemListItem from "../../../../../../components/MenuSelectionItem/ListItem";
import MenuSelectionItemSpecialRequestsCaption from "../../../../../../components/MenuSelectionItem/SpecialRequests/Caption";

interface RestaurantMenuLinkPlateItemsFormProps {
  className?: string;
  plateItems: readonly RestaurantMenuLinkPlateItemWithAppearance[];
  selectedItemId: string | null;
  onSelectItem: (item: RestaurantMenuLinkPlateItemWithAppearance) => void;
  onRemoveItem: (id: string) => void;
}

const RestaurantMenuLinkPlateItemsForm = ({
  className,
  plateItems,
  selectedItemId,
  onSelectItem,
  onRemoveItem,
}: RestaurantMenuLinkPlateItemsFormProps) => {
  return (
    <div className={className}>
      <Typography variant="h3">
        <b>Plate Items</b>
      </Typography>
      <List dense>
        {sortByKey(plateItems, "position").map(plateItem => (
          <MenuSelectionItemListItem
            key={plateItem.id}
            menuSelectionItem={plateItem}
            ListItemProps={{
              onClick: () => onSelectItem(plateItem),
              selected: plateItem.id === selectedItemId,
            }}
            hidePercentConsumed
            textChildren={<MenuSelectionItemSpecialRequestsCaption specialRequests={plateItem.specialRequests} />}
          >
            <ListItemSecondaryAction>
              <IconButton size="small" onClick={() => onRemoveItem(plateItem.id)}>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </MenuSelectionItemListItem>
        ))}
      </List>
    </div>
  );
};

export default RestaurantMenuLinkPlateItemsForm;
