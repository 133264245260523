import CheckIcon from "@mui/icons-material/Check";
import {
    Checkbox,
    FormControlLabel,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useLocaleContext } from "@notemeal/shared-ui";
import { getHeightUnitForLocale, getWeightUnitForLocale } from "@notemeal/shared-utils-macro-protocol";
import { Semibold } from "apps/web/src/pages/Theme/Common/TypographyCommon";
import { ChangeEvent, Dispatch, ReactNode } from "react";
import { AnthropometryFormField } from "../../../../types";
import { AnthropometryAction, AnthropometryState } from "../reducer/anthropometryReducer";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    tableRow: {
      borderBottom: "1px solid",
    },
    tableBodyCell: {
      textAlign: "center",
      padding: spacing(0, 0, 0, 1),
      height: 50,
    },
    tableCheckBoxCell: {
      padding: spacing(0, 0, 0, 1),
      height: 50,
    },
  })
);

const BodyCell = ({ children }: { children?: ReactNode }) => <TableCell className={useStyles().tableBodyCell}>{children}</TableCell>;
const CheckBoxCell = ({ children }: { children?: ReactNode }) => (
  <TableCell className={useStyles().tableCheckBoxCell}>{children}</TableCell>
);

interface AnthropometryTableProps {
  state: AnthropometryState;
  dispatch: Dispatch<AnthropometryAction>;
}

interface HTMLInputToFormField {
  checked: boolean;
  value: AnthropometryFormField;
}

const AnthropometryTable = ({ state, dispatch }: AnthropometryTableProps) => {
  const formFields = state.__typename === "FormFields" ? state.formFields : [];
  const { isMetricLocale } = useLocaleContext();

  const onEditFormFields = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value }: HTMLInputToFormField = event.target as HTMLInputToFormField;
    const formFieldsSet = new Set(formFields);
    if (checked) {
      formFieldsSet.add(value);
    } else {
      formFieldsSet.delete(value);
    }
    dispatch({ type: "EDIT_FORM_FIELDS", payload: Array.from(formFieldsSet) });
  };

  const useFormFields = state.__typename === "FormFields";

  return (
    <Table sx={{ tableLayout: "fixed", width: "100%" }}>
      <TableHead>
        <TableRow sx={{ fontWeight: Semibold, backgroundColor: "greyscale.200", p: 2 }}>
          <TableCell sx={{ textAlign: "center", width: 190 }}></TableCell>
          <TableCell sx={{ textAlign: "center" }}>Cunningham</TableCell>
          <TableCell sx={{ textAlign: "center" }}>Mifflin</TableCell>
          <TableCell sx={{ textAlign: "center" }}>Harris-Benedict</TableCell>
          <TableCell sx={{ textAlign: "center" }}>Average</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <CheckBoxCell>
            {useFormFields ? (
              <FormControlLabel
                label="Height"
                control={<Checkbox
                  autoFocus
                  checked={formFields.includes("height")}
                  value="height"
                  onChange={onEditFormFields} />}
              />
            ) : (
              <TextField
                sx={{ mt: 4, mb: 1 }}
                label="Height"
                type="number"
                value={state.heightInput}
                onChange={event => dispatch({ type: "EDIT_HEIGHT", payload: event.target.value, isMetricLocale })}
                InputProps={{ endAdornment: <InputAdornment position="end">{getHeightUnitForLocale(isMetricLocale)}.</InputAdornment> }}
              />
            )}
          </CheckBoxCell>
          <BodyCell />
          <BodyCell>
            <CheckIcon />
          </BodyCell>
          <BodyCell>
            <CheckIcon />
          </BodyCell>
          <BodyCell>
            <CheckIcon />
          </BodyCell>
        </TableRow>
        <TableRow>
          <CheckBoxCell>
            {useFormFields ? (
              <FormControlLabel
                label="Weight"
                control={<Checkbox
                  checked={formFields.includes("weight")}
                  value="weight"
                  disabled
                  onChange={onEditFormFields} />}
              />
            ) : (
              <TextField
                sx={{ mt: 4, mb: 1 }}
                label="Weight"
                type="number"
                value={state.weightInput}
                onChange={event => dispatch({ type: "EDIT_WEIGHT", payload: event.target.value, isMetricLocale })}
                InputProps={{ endAdornment: <InputAdornment position="end">{getWeightUnitForLocale(isMetricLocale)}.</InputAdornment> }}
              />
            )}
          </CheckBoxCell>
          <BodyCell>
            <CheckIcon />
          </BodyCell>
          <BodyCell>
            <CheckIcon />
          </BodyCell>
          <BodyCell>
            <CheckIcon />
          </BodyCell>
          <BodyCell>
            <CheckIcon />
          </BodyCell>
        </TableRow>
        <TableRow>
          <CheckBoxCell>
            {useFormFields ? (
              <FormControlLabel
                label="Percent Body Fat"
                control={<Checkbox
                  checked={formFields.includes("percentBodyFat")}
                  value="percentBodyFat"
                  onChange={onEditFormFields} />}
              />
            ) : (
              <TextField
                sx={{ mt: 4, mb: 1 }}
                label="Percent Body Fat"
                type="number"
                value={state.percentBodyFatInput}
                onChange={event => dispatch({ type: "EDIT_PERCENT_BODY_FAT", payload: event.target.value })}
                InputProps={{ endAdornment: <InputAdornment position="end">pct.</InputAdornment> }}
              />
            )}
          </CheckBoxCell>
          <BodyCell>
            <CheckIcon />
          </BodyCell>
          <BodyCell />
          <BodyCell />
          <BodyCell>
            <CheckIcon />
          </BodyCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default AnthropometryTable;
