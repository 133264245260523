import { Box, Card, CardContent, SxProps, Typography, styled } from "@mui/material";
import { ScoreBadge, useAthleteHighlightFromMenuItemPreview } from "@notemeal/shared-ui";
import FoodPlaceholderImage from "../../componentLibrary/FoodPlaceholderImage";
import { MenuItemAppearancePreviewFragment } from "../../types";
import { ImageWrapper, MenuCardActionArea } from "../MenuItemAppearance/MenuItemAppearancePreview/utils";

const condensedStyle = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
};

const descriptionStyle = {
  WebkitLineClamp: 2,
  ...condensedStyle,
};

const IMG_WIDTH_PCT = 50;

const ImageContainer = styled(ImageWrapper)(() => ({
  width: "40%",
}));

interface MenuItemPreviewProps {
  menuItemAppearance: MenuItemAppearancePreviewFragment;
  onClick: () => void;
  sx?: {
    root?: SxProps;
    actionArea?: SxProps;
  };
}

/**
 * Condensed version of a MenuItem for views with many menu items to select from (ie: restaurant menu or dining hall menu)
 * Gives a preview of the name, description, and image.
 */
export const MenuItemPreview = ({ onClick, menuItemAppearance, sx }: MenuItemPreviewProps) => {
  const { highlightType: highlightColor, reason } = useAthleteHighlightFromMenuItemPreview(menuItemAppearance.menuItem);
  const { name, description, imageUrl } = menuItemAppearance.menuItem;

  const showContains = reason.length > 0 && highlightColor === "Red";
  const showDescription = !showContains && !!description;

  const score = menuItemAppearance.menuItem.score;

  return (
    <Card sx={sx?.root}>
      <MenuCardActionArea onClick={onClick} sx={{ ...sx?.actionArea }}>
        <CardContent
          sx={{ flexGrow: 1, p: 1, display: "flex", flexDirection: "column", boxSizing: "border-box", minWidth: `${100 - IMG_WIDTH_PCT}%` }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Typography
              variant="body1Semibold"
              sx={{
                WebkitLineClamp: 3,
                ...condensedStyle,
                ...(highlightColor === "Red"
                  ? {
                      color: "error.main",
                      "&:focus": {
                        color: "error.light",
                      },
                    }
                  : {}),
              }}
            >
              {name}
            </Typography>
            <ScoreBadge score={score} />
          </Box>
          {showContains && (
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={descriptionStyle}>
              {`Contains: ${reason.join(", ")}`}
            </Typography>
          )}
          {showDescription && (
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={descriptionStyle}>
              {description}
            </Typography>
          )}
        </CardContent>
        {imageUrl ? (
          <>
            <ImageContainer
              sx={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(${imageUrl})`,
              }}
            />
          </>
        ) : (
          <ImageContainer>
            <FoodPlaceholderImage />
          </ImageContainer>
        )}
      </MenuCardActionArea>
    </Card>
  );
};
