import { Reference } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ActivityItem, MealItem, sortedScheduleComponents } from "@notemeal/shared-ui";
import { useState } from "react";
import ScheduleModal from "../../../../components/Schedule/Modal";
import LabeledSelect from "../../../../components/universal/LabeledSelect";
import { ScheduleModalEditorState } from "../../../../reducers/ScheduleModalEditor";
import { TeamDashboardScheduleFragment, useCreateScheduleMutation, useEditScheduleMutation } from "../../../../types";
import {
    initialScheduleModalEditorState,
    scheduleModalEditorStateToCreateInput,
    scheduleModalEditorStateToEditInput,
} from "../../../../utils/ScheduleModalEditor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    content: {
      overflow: "auto",
      flexGrow: 1,
    },
    header: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    buttonWithMargin: {
      margin: theme.spacing(0, 1),
    },
    selectSchedule: {
      width: 300,
    },
    spacer: {
      flexGrow: 1,
    },
  })
);

interface TeamSchedulesContentProps {
  teamId: string;
  schedules: readonly TeamDashboardScheduleFragment[];
}

const TeamSchedulesContent = ({ teamId, schedules }: TeamSchedulesContentProps) => {
  const classes = useStyles();
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState<string | null>(null);
  const noSchedules = !schedules.length;
  const [createOrEdit, setCreateOrEdit] = useState<"create" | "edit">("edit");

  const selectedSchedule = schedules.find(s => s.id === selectedScheduleId) ?? null;

  const filledSched = selectedSchedule || {
    meals: [],
    activities: [],
    id: null,
    name: "",
    mealPlans: [],
  };

  const editableScheduleModalState = {
    ...initialScheduleModalEditorState(filledSched.name, true, filledSched.meals, filledSched.activities),
    scheduleId: filledSched.id,
  };

  const newScheduleModalState = initialScheduleModalEditorState("", true, [], []);

  const [createSchedule] = useCreateScheduleMutation({
    update: (cache, { data }) => {
      if (data) {
        const __ref = cache.identify(data.createSchedule.schedule);
        if (__ref) {
          cache.modify({
            id: `Team:${teamId}`,
            fields: {
              schedules: (schedules: Reference[]): Reference[] => [...schedules, { __ref }],
            },
          });
        }
      }
    },
    onCompleted: () => setScheduleModalOpen(false),
  });

  const [editSchedule] = useEditScheduleMutation({
    update: (cache, { data }) => {
      if (data) {
        data.editSchedule.schedule.mealPlans.forEach(mp => cache.evict({ id: `MealPlan:${mp.id}` }));
      }
    },
    onCompleted: () => setScheduleModalOpen(false),
  });

  const handleClickEditSchedule = () => {
    setCreateOrEdit("edit");
    setScheduleModalOpen(true);
  };

  const handleClickNewSchedule = () => {
    setCreateOrEdit("create");
    setScheduleModalOpen(true);
  };

  const handleSave = (state: ScheduleModalEditorState) => {
    if (createOrEdit === "edit") {
      const scheduleId = state.scheduleId;
      if (!scheduleId) {
        throw new Error("scheduleId should always be non-null when editing team schedule!");
      }
      editSchedule({
        variables: {
          input: {
            ...scheduleModalEditorStateToEditInput(state),
            name: state.scheduleName,
            activityFactor: state.activityFactor,
            scheduleId,
          },
        },
      });
    } else {
      // Create new schedule
      createSchedule({
        variables: {
          input: {
            ...scheduleModalEditorStateToCreateInput(state),
            name: state.scheduleName,
            teamId,
            activityFactor: state.activityFactor,
          },
        },
        update: (dataProxy, result) => {
          if (result.data) {
            setSelectedScheduleId(result.data.createSchedule.schedule.id);
          }
        },
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.selectSchedule}>
          <LabeledSelect
            placeholder="Select schedule"
            optionToName={s => s.name}
            selectedOption={selectedSchedule}
            options={[...schedules]}
            onChange={s => setSelectedScheduleId(s.id)}
            textFieldProps={{ margin: "dense", disabled: noSchedules }}
          />
        </div>

        <div className={classes.spacer} />
        {selectedSchedule && (
          <IconButton
            className={classes.buttonWithMargin}
            onClick={handleClickEditSchedule}
            size="small">
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        <IconButton
          className={classes.buttonWithMargin}
          onClick={handleClickNewSchedule}
          size="small">
          <AddIcon fontSize="small" />
        </IconButton>
      </div>

      <div className={classes.content}>
        {sortedScheduleComponents({
          meals: filledSched.meals,
          mealToStart: m => m.start,
          renderMeal: m => <MealItem key={m.id} meal={m} />,
          activities: filledSched.activities,
          activityToStart: a => a.start,
          renderActivity: a => <ActivityItem key={a.id} activity={a} />,
        }).map(({ component }) => component)}
      </div>

      {scheduleModalOpen && (
        <ScheduleModal
          open={scheduleModalOpen}
          onClose={() => setScheduleModalOpen(false)}
          onSave={handleSave}
          numberMealPlans={filledSched.mealPlans.length}
          initialState={createOrEdit === "edit" ? editableScheduleModalState : newScheduleModalState}
          title={createOrEdit === "edit" ? "Edit Schedule" : "New Schedule"}
        />
      )}
    </div>
  );
};

export default TeamSchedulesContent;
