import { useTheme } from "@mui/material";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { serializeDate } from "@notemeal/utils-date-time";
import { addDays } from "date-fns";
import React from "react";
import { GroupedMealMenuMap } from "./bulkCopyHelpers";

interface BulkCopyCalendarProps {
  groupedDates: GroupedMealMenuMap;
  anchorDates: Date[];
  onAddAnchor: (newAnchor: Date) => void;
  clientTimezone: string;
}

const BulkCopyCalendar = ({ groupedDates, anchorDates, onAddAnchor, clientTimezone }: BulkCopyCalendarProps) => {
  const minDate = addDays(new Date(), -1);
  const sourceDays = new Set(groupedDates.keys());
  const targetDays =
    anchorDates !== null
      ? new Set(
          anchorDates.flatMap(anchorDate =>
            [...groupedDates.values()].map(groupedMealMenuInstances => {
              return serializeDate(addDays(anchorDate, groupedMealMenuInstances.daysAfterAnchor));
            })
          )
        )
      : new Set([]);

  const onChange = (date: Date | null) => {
    if (date) {
      onAddAnchor(date);
    }
  };

  return (
    <DateCalendar
      showDaysOutsideCurrentMonth
      disableHighlightToday
      views={["day"]}
      onChange={onChange}
      slots={{ day: props => <Day
        {...props}
        sourceDays={sourceDays}
        targetDays={targetDays} /> }}
      minDate={minDate}
    />
  );
};

export default BulkCopyCalendar;

type DayProps = {
  sourceDays: Set<string>;
  targetDays: Set<string>;
};

const Day = (props: PickersDayProps<Date> & DayProps) => {
  const {
    palette: { info, grey },
  } = useTheme();
  const { sourceDays, targetDays, ...other } = props;
  const day = other.day ? serializeDate(other.day) : "";
  const isSourceDay = sourceDays.has(day);
  const isTargetDay = targetDays.has(day);

  return (
    <PickersDay
      {...other}
      sx={{
        ...(isSourceDay && {
          outline: `1px solid ${grey[500]}`,
          outlineOffset: "-2px",
        }),
        ...(isTargetDay && {
          outline: theme => `1px solid ${info.main}`,
          outlineOffset: "-2px",
        }),
      }}
      selected={false}
      disableMargin
      onFocus={e => e.stopPropagation()}
    />
  );
};
