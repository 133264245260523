import { coercePhoneNumber } from "../../../../../../components/global/PhoneNumberInput";
import { RestaurantLocationContactInput, ContactFragment, PreferredContactMethodType } from "../../../../../../types";
import { invalidEmail } from "../../../../../../utils/invalidEmail";
import { invalidPhoneNumber } from "@notemeal/utils-phone-number";

export interface RestaurantLocationContactFormState {
  id: string | null;
  firstName: string;
  lastName: string;
  title: string | null;
  email: string | null;
  phoneNumber: string | null;
  preferredContactMethod: PreferredContactMethodType;
}

interface ChangeFirstName {
  type: "CHANGE_FIRST_NAME";
  payload: {
    firstName: string;
  };
}

interface ChangeLastName {
  type: "CHANGE_LAST_NAME";
  payload: {
    lastName: string;
  };
}

interface ChangeTitle {
  type: "CHANGE_TITLE";
  payload: {
    title: string;
  };
}

interface ChangeEmail {
  type: "CHANGE_EMAIL";
  payload: {
    email: string;
  };
}

interface ChangePhoneNumber {
  type: "CHANGE_PHONE_NUMBER";
  payload: {
    phoneNumber: string;
  };
}

interface ChangePreferredContactMethod {
  type: "CHANGE_PREFERRED_CONTACT_METHOD";
  payload: {
    preferredContactMethod: PreferredContactMethodType;
  };
}

export type RestaurantLocationContactFormAction =
  | ChangeFirstName
  | ChangeLastName
  | ChangeTitle
  | ChangePreferredContactMethod
  | ChangeEmail
  | ChangePhoneNumber;

export function restaurantLocationContactFormReducer<T extends RestaurantLocationContactFormState>(
  state: T,
  action: RestaurantLocationContactFormAction
): T {
  switch (action.type) {
    case "CHANGE_FIRST_NAME":
      return { ...state, firstName: action.payload.firstName };
    case "CHANGE_LAST_NAME":
      return { ...state, lastName: action.payload.lastName };
    case "CHANGE_TITLE":
      return { ...state, title: action.payload.title };
    case "CHANGE_PREFERRED_CONTACT_METHOD":
      return {
        ...state,
        preferredContactMethod: action.payload.preferredContactMethod,
      };
    case "CHANGE_EMAIL":
      return { ...state, email: action.payload.email };
    case "CHANGE_PHONE_NUMBER":
      return { ...state, phoneNumber: action.payload.phoneNumber };
    default:
      return state;
  }
}

export const buildContactFormTooltips = (state: RestaurantLocationContactFormState): string[] => {
  const tooltips: string[] = [];
  if (!state.firstName.trim()) {
    tooltips.push("'First Name' is required");
  }
  if (!state.lastName.trim()) {
    tooltips.push("'Last Name' is required");
  }
  if (!state.phoneNumber?.trim() && !state.email?.trim()) {
    tooltips.push("One contact method is required");
  }
  if (state.phoneNumber && coercePhoneNumber(state.phoneNumber) && invalidPhoneNumber(state.phoneNumber)) {
    tooltips.push("Invalid phone number");
  }
  if (state.email && invalidEmail(state.email)) {
    tooltips.push("Invalid email");
  }
  return tooltips;
};

export const buildInitialCreateFormState = (): RestaurantLocationContactFormState => ({
  id: null,
  firstName: "",
  lastName: "",
  title: null,
  email: null,
  phoneNumber: null,
  preferredContactMethod: "email",
});

export const buildRestaurantLocationContactInput = (state: RestaurantLocationContactFormState): RestaurantLocationContactInput => {
  const { id, phoneNumber, email, ...rest } = state;
  const maybePhoneNumber = phoneNumber && coercePhoneNumber(phoneNumber); //coercePhoneNumber may return ""
  return {
    phoneNumber: maybePhoneNumber ? maybePhoneNumber : null,
    email: email ? email : null,
    ...rest,
  };
};

export const buildInitialEditFormState = ({
  id,
  firstName,
  lastName,
  title,
  email,
  phoneNumber,
  preferredContactMethod,
}: ContactFragment): RestaurantLocationContactFormState => ({
  id,
  firstName,
  lastName,
  title,
  email: email || null,
  phoneNumber: phoneNumber || null,
  preferredContactMethod,
});
