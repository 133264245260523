import { List, ListItem, ListItemText, SxProps } from "@mui/material";
import { sortByFn } from "@notemeal/utils-sort";
import React from "react";
import { useSnackbar } from "../../components/Snackbar/SnackbarContext";
import { KioskAthlete } from "./utils";

interface KioskTeamsListProps {
  teams: readonly KioskAthlete["team"][];
  selectedTeamIds: readonly string[];
  onClick: (teamId: string) => void;
  disabledTeamIds: readonly string[];
  sx?: SxProps;
}

const KioskTeamsList = ({ teams, selectedTeamIds, onClick, disabledTeamIds, sx }: KioskTeamsListProps) => {
  const { setMessage } = useSnackbar();
  const handleSelectTeam = (teamId: string, disabled: boolean) => {
    if (disabled) {
      setMessage("warning", "No menus include this team.");
    } else {
      onClick(teamId);
    }
  };

  return (
    <List sx={sx}>
      {sortByFn(teams, t => (disabledTeamIds.includes(t.id) ? 2 : 1)).map(team => {
        const selected = selectedTeamIds.includes(team.id);
        const disabled = disabledTeamIds.includes(team.id);
        return (
          <ListItem
            onClick={() => handleSelectTeam(team.id, disabled)}
            selected={selected}
            key={team.id}
            button
            disabled={disabledTeamIds.includes(team.id)}
            disableGutters
          >
            <ListItemText>{team.name}</ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

export default KioskTeamsList;
