import { Button, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { CompassRecipe } from "@notemeal/graphql-types";
import React from "react";
import { QueryLabel, queryLabels } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelButtonsDiv: {
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(),
    },
  })
);

interface ButtonsProps {
  setSelectedLabel: (label: QueryLabel) => void;
  selectedLabel: QueryLabel;
  setSelectedRecipe: (recipe: CompassRecipe | null) => void;
}

export const Buttons = ({ setSelectedLabel, selectedLabel, setSelectedRecipe }: ButtonsProps) => {
  const classes = useStyles();
  const lastIndex = queryLabels.length - 1;
  return (
    <div className={classes.labelButtonsDiv}>
      {queryLabels.map((label, index) => {
        const isTopButton = index === 0;
        const isBottomButton = index === lastIndex;
        return (
          <Button
            sx={[
              {
                m: 0,
                py: 0,
                px: 1,
                width: "100%",
                fontSize: "0.675rem",
                borderRadius: 0,
                color: selectedLabel === label ? "common.white" : "common.black",
                backgroundColor: selectedLabel === label ? "common.black" : "transparent",
                "&:hover": {
                  backgroundColor: selectedLabel === label ? "greyscale.600" : "transparent",
                },
              },
              isTopButton && {
                borderBottom: "none",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                "&:hover": {
                  borderBottom: "none",
                },
              },
              isBottomButton && {
                borderTop: "none",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
                "&:hover": {
                  borderTop: "none",
                },
              },
            ]}
            variant={selectedLabel === label ? "contained" : "outlined"}
            onClick={() => {
              setSelectedLabel(label);
              setSelectedRecipe(null);
            }}
            key={`button-label-${label}`}
          >
            {label}
          </Button>
        );
      })}
    </div>
  );
};
