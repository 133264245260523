import { useQuery } from "@apollo/client";
import { Dialog } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { ExchangeSetDocument, ExchangeSetQuery } from "../../../types";
import Content from "./Content";

interface ExchangeSetModalProps {
  exchangeSetId: string | null;
  open: boolean;
  onClose: () => void;
}

const ExchangeSetModal = ({ exchangeSetId, open, onClose }: ExchangeSetModalProps) => {
  const { data, loading } = useQuery<ExchangeSetQuery>(ExchangeSetDocument, { skip: !exchangeSetId, variables: { id: exchangeSetId } });

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      onClose={onClose}
      open={open}>
      <DialogTitle title={`${exchangeSetId ? "Edit" : "Create"} Exchange Set`} onClose={onClose} />
      {loading && <Loading progressSize="md" />}
      {!loading && <Content
        open={open}
        onClose={onClose}
        exchangeSet={data ? data.exchangeSet : null} />}
    </Dialog>
  );
};

export default ExchangeSetModal;
