import { Dialog, DialogContent } from "@mui/material";
import { Loading, addSelectionOptionsToMenuItem } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { KdsAnalyzeDialogOrderFragment, KdsAnalyzeDialogPlateOrderFragment, useKdsAnalyzeDialogMealMenusQuery } from "../../../types";
import { GenericOrderPreview } from "../Ticket/types";
import MenuItemDialogContent from "./Content";
import { MenuOrderItemSummary } from "./types";

interface MenuItemDialogProps {
  onClose: () => void;
  open: boolean;
  diningStationIds: string[];
  sortedAndFilteredOrders: GenericOrderPreview[];
  mealMenuIds: string[];
}

const makeMenuOrderItemSummaries = (
  orders: readonly (KdsAnalyzeDialogOrderFragment | KdsAnalyzeDialogPlateOrderFragment)[]
): MenuOrderItemSummary[] => {
  return Object.values(
    orders
      .flatMap(o => o.itemsByDiningStation)
      .map(({ menuItem, amount, options }) => {
        const { choices } = addSelectionOptionsToMenuItem(menuItem, options);
        return { choices, menuItem, amount };
      })
      .reduce(
        (curr, summ) => ({
          ...curr,
          [summ.menuItem.id]: {
            ...(curr[summ.menuItem.id] || {}),
            amount: (curr[summ.menuItem.id]?.amount || 0) + (summ.amount || 0),
            menuItem: {
              id: summ.menuItem.id,
              name: summ.menuItem.name,
              servingName: summ.menuItem.servingName,
            },
            choices: summ.choices.map(c => {
              const existingChoice = curr[summ.menuItem.id]?.choices.find(exChoice => exChoice.id === c.id);
              return !existingChoice
                ? {
                    ...c,
                    options: c.options.map(newOpt => ({
                      id: newOpt.id,
                      name: newOpt.name,
                      amount: (newOpt.menuSelectionItemOption?.amount || 0) * summ.amount,
                    })),
                  }
                : {
                    ...existingChoice,
                    options: c.options.map(newOpt => ({
                      id: newOpt.id,
                      name: newOpt.name,
                      amount:
                        (newOpt.menuSelectionItemOption?.amount || 0) * summ.amount +
                        (existingChoice.options.find(existingOpt => existingOpt.id === newOpt.id)?.amount || 0),
                    })),
                  };
            }),
          },
        }),
        {} as { [menuItemId: string]: MenuOrderItemSummary | undefined }
      )
  ).flatMap(o => (!!o ? [o] : []));
};

const KdsAnalyzeDialog = ({ onClose, open, sortedAndFilteredOrders, diningStationIds, mealMenuIds }: MenuItemDialogProps) => {
  const { data, loading } = useKdsAnalyzeDialogMealMenusQuery({
    variables: {
      diningStationIds,
      mealMenuIds,
    },
    fetchPolicy: "network-only",
  });
  const flatMenuOrders = data && !loading ? data.mealMenus.flatMap(mm => mm.allOrders) : [];
  const flatMealMenuPlateOrders = data && !loading ? data.mealMenus.flatMap(mm => mm.allMealMenuPlateOrders) : [];
  const flatOrders = [...flatMenuOrders, ...flatMealMenuPlateOrders];
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth>
      <DialogTitle title="Menu Item Counts" onClose={onClose} />
      <DialogContent>
        {!data || loading ? (
          <Loading progressSize="lg" />
        ) : (
          <MenuItemDialogContent
            menuOrders={makeMenuOrderItemSummaries(
              flatOrders.filter(o => !!sortedAndFilteredOrders.find(sfo => sfo.id === o.id && sfo.__typename === o.__typename))
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default KdsAnalyzeDialog;
