import { Box, Typography } from "@mui/material";
import { sortByKey } from "@notemeal/utils-sort";
import React from "react";
import { MenuItemPreview } from "../../../../../../components/MenuSelection/MenuItemPreview";
import { MenuItemAppearancePreviewFragment, RestaurantMenuSectionPreviewFragment } from "../../../../../../types";

interface RestaurantMenuSectionProps {
  restaurantMenuSection: RestaurantMenuSectionPreviewFragment;
  onSelectItem: (appearance: MenuItemAppearancePreviewFragment) => void;
}

const RestaurantMenuSection = ({ onSelectItem, restaurantMenuSection }: RestaurantMenuSectionProps) => {
  return (
    <>
      <Typography variant="h3">{restaurantMenuSection.name}</Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {sortByKey(restaurantMenuSection.menuItemAppearances, "position").map(mia => (
          <MenuItemPreview
            key={mia.menuItem.id}
            sx={{ root: { width: "calc(50% - 16px)", m: 1, position: "relative" } }}
            menuItemAppearance={mia}
            onClick={() => onSelectItem(mia)}
          />
        ))}
      </Box>
    </>
  );
};

export default RestaurantMenuSection;
