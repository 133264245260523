import { FORM_NUTRIENTS, NutrientAmountState } from "@notemeal/shared-ui";
import { convertVitaminDToMcg } from "@notemeal/shared-ui";
import { round } from "@notemeal/shared-ui";
import { ZERO_MACROS } from "@notemeal/shared-utils-macro-protocol";
import { BrandedFood, NutrientAmountFragment, OrgEditableFoodFragment, SharedEditableFoodFragment } from "../../../types";
import { getServingUnits, servingUnitsToState } from "../../Serving/utils";
import { IFoodRow } from "./TableRow";
import { GenericFood } from "@notemeal/graphql-types";
import { formatDistanceToNowStrict } from "date-fns";

export const foodsToRows = (foods: readonly (OrgEditableFoodFragment | SharedEditableFoodFragment)[]): IFoodRow[] => {
  return foods.map(food => {
    const {
      __typename: type,
      usdaFdcDataType,
      foodGroups,
      usdaFdcFoodCategoryId,
      usdaFdcDescription,
      usdaFdcId,
      servings,
      id,
      name,
      choPer100g,
      fatPer100g,
      proPer100g,
      exchangeTypes,
      isRecipeIngredientOnly,
      excludeFromSuggestions,
      // createdBy,
      nutrientAmounts,
      thumbnailUrl,
      groceryListCategory,
      source,
      locales,
      updatedAt,
      ...rest
    } = food;

    // TODO: Remove this when createdBy can be fetched correctly for shared foods
    const createdBy = (food as OrgEditableFoodFragment).createdBy ?? null;
    const brand = type === "BrandedFood" ? (rest as BrandedFood).brand : null;
    const org = type === "GenericFood" ? (rest as GenericFood).org : null;
    const orgGroup = type === "GenericFood" ? (rest as GenericFood).orgGroup : null;
    const owner = type === "GenericFood" ? (rest as GenericFood).owner : null;

    const pickedServings = servings.map(serving => {
      const { defaultAmount, usdaWeightSeq, id, weight, isDefault, isRecipeServingOnly, macros } = serving;
      return {
        id,
        isNewServing: false,
        defaultAmount,
        usdaWeightSeq: usdaWeightSeq || "",
        units: servingUnitsToState(serving),
        weight,
        cho: macros.cho,
        pro: macros.pro,
        fat: macros.fat,
        isDefault,
        isRecipeServingOnly,
        nutrientAmounts: null,
      };
    });
    const defaultServing = servings.find(s => !!s.isDefault);
    const {
      unit,
      unitSuffix,
      unitPrefix,
      customUnits,
      defaultAmount: defaultServingAmount,
      macros: defaultServingMacros,
    } = defaultServing || {
      customUnits: "None",
      unitSuffix: null,
      unitPrefix: null,
      unit: null,
      weight: null,
      defaultAmount: 0,
      macros: ZERO_MACROS,
      nutrientAmounts: null,
    };
    return {
      id,
      usdaFdcDescription,
      usdaFdcId,
      usdaFdcDataType,
      usdaFdcFoodCategoryId,
      name,
      choPer100g,
      fatPer100g,
      proPer100g,
      exchangeTypes,
      foodGroups,
      brand,
      type,
      isRecipeIngredientOnly,
      excludeFromSuggestions,
      servings: pickedServings,
      nutrientAmounts: nutrientAmountsForRows(nutrientAmounts, 100),
      defaultServing: {
        units: getServingUnits({
          unit,
          unitSuffix,
          unitPrefix,
          customUnits,
        }),
        amount: defaultServingAmount,
      },
      defaultServingCho: defaultServingMacros.cho * defaultServingAmount,
      defaultServingPro: defaultServingMacros.pro * defaultServingAmount,
      defaultServingFat: defaultServingMacros.fat * defaultServingAmount,
      createdBy,
      createdByString: createdBy ? `${createdBy.firstName[0].toUpperCase()}. ${createdBy.lastName}` : "",
      thumbnailUrl,
      groceryListCategory: groceryListCategory || null,
      source,
      locales: locales || [],
      managedByString: owner ? `${owner.firstName[0].toUpperCase()}. ${owner.lastName}` : "",
      ownerId: owner?.id ?? null,
      orgName: org?.name ?? null,
      orgGroupName: orgGroup?.name ?? null,
      orgGroupId: orgGroup?.id ?? null,
      lastEditedString: formatDistanceToNowStrict(new Date(updatedAt), {
        addSuffix: true,
        unit: "day",
      }),
    };
  });
};

const nutrientAmountsForRows = (nutrientAmounts: readonly NutrientAmountFragment[], weight: number): NutrientAmountState => {
  let nutrientAmountsForState: NutrientAmountState = {};
  FORM_NUTRIENTS.forEach(nut => {
    const value = maybeNutrientDisplayValueFromNutrientAmounts(nutrientAmounts, nut, weight);
    nutrientAmountsForState[nut] = value === null ? "" : round(value).toString();
  });
  return nutrientAmountsForState;
};

const maybeNutrientDisplayValueFromNutrientAmounts = (
  nutrientAmounts: readonly NutrientAmountFragment[],
  nutrient: (typeof FORM_NUTRIENTS)[number],
  weight: number
): number | null => {
  const nutrientAmount = nutrientAmounts.find(nut => nut.nutrient?.name === nutrient)?.amount;
  if (nutrientAmount === undefined) {
    return null;
  }
  const convertedValue = nutrient === "Vitamin D (D2 + D3), International Units" ? convertVitaminDToMcg(nutrientAmount) : nutrientAmount;
  return (convertedValue / 100) * weight;
};
