import AddToHomeScreen from "@mui/icons-material/AddToHomeScreen";
import MobileFriendly from "@mui/icons-material/MobileFriendly";
import MobileScreenShare from "@mui/icons-material/MobileScreenShare";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import { AthleteMobileState } from "@notemeal/shared-ui";
import React from "react";

export const mobileStateToIcon = (state: AthleteMobileState, grey: string, green: string | undefined): React.ReactElement => {
  switch (state) {
    case "active":
      return <MobileFriendly sx={{ color: green }} />;
    case "inactive":
      return <AddToHomeScreen />;
    case "expired":
      return <PhonelinkEraseIcon color="error" />;
    case "waiting":
      return <MobileScreenShare sx={{ color: grey }} />;
    default:
      return <></>;
  }
};
