import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FormControl, IconButton, Menu, MenuItem, Switch, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import {
    EditEducationResourceInput,
    EducationResourceTableItemFragment,
    FullEducationResourceTemplateFragment,
    useArchiveEducationResourceMutation,
    useDeleteEducationResourceMutation,
    useEditEducationResourceMutation,
    useEditEducationResourceSharingScopeMutation,
} from "apps/web/src/types";
import { useState } from "react";
import { TWTooltip } from "../../componentLibrary/TWTooltip/TWTooltip";
import { ResourcePreviewModal } from "../../components/EducationResource/ResourcePreviewModal";
import { useDeleteResource } from "../../components/universal/FileUpload/deleteResource";
import { ArchiveEducationResourceDialog } from "./Dialogs/ArchiveEducationResourceDialog";
import { DeleteEducationResourceDialog } from "./Dialogs/DeleteEducationResourceDialog";
import { EditEducationResourceDialog } from "./Dialogs/EditEducationResourceDialog";
import { ShareWithCell } from "./ShareWithCell";
import { EducationResourceViewHistoryDrawer } from "./ViewHistory/EducationResourceViewHistoryDrawer";
import { getResourceInfo } from "./utils";

interface EductationResourceTableRowsProps {
  rows: readonly EducationResourceTableItemFragment[];
  refetchOffsetConnection: () => void;
}

export const EducationResourceTableRows = ({ rows, refetchOffsetConnection }: EductationResourceTableRowsProps) => {
  const { formatDateWithLocale } = useDateFormatting();
  const { setMessage } = useSnackbar();
  const deleteResource = useDeleteResource();

  const [currentResource, setCurrentResource] = useState<EducationResourceTableItemFragment | null>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLElement | null>(null);
  const [editResource, setEditResource] = useState(false);
  const [deleteResourceOpen, setDeleteResourceOpen] = useState(false);
  const [archiveResource, setArchiveResource] = useState(false);
  const [viewHistoryOpen, setViewHistoryOpen] = useState(false);
  const [previewResourceOpen, setPreviewResourceOpen] = useState(false);

  const onEdit = (educationResource: FullEducationResourceTemplateFragment, teams?: string[], athleteCount?: number) => {
    let successMessage = `${educationResource.name} has been updated`;
    if (teams?.length) {
      const teamNames = teams.join(", ");
      successMessage += ` and shared with ${teamNames}`;
    } else if (athleteCount !== undefined && educationResource.hasAdvancedSelection) {
      successMessage += ` and shared with ${athleteCount} athletes`;
    }
    setMessage("success", successMessage);
  };

  const onDelete = (name: string) => {
    setMessage("success", `${name} has been deleted`);
  };

  const clearMoreButton = () => {
    setMoreAnchorEl(null);
  };

  const [editEducationResource] = useEditEducationResourceMutation({
    onCompleted: data => {
      const teamNames = (data.editEducationResource.educationResource.teams ?? []).map(team => team.name);
      const athleteCount = data.editEducationResource.educationResource.athleteCountForEducationResource;
      const educationResource = data.editEducationResource.educationResource;
      onEdit(educationResource, teamNames, athleteCount);
      setEditResource(false);
      refetchOffsetConnection();
    },
    onError: () => {
      setMessage("error", "There was an error updating the resource");
    },
  });

  const [deleteEducationResource] = useDeleteEducationResourceMutation({
    onCompleted: async data => {
      await deleteResource(data.deleteEducationResource.fileKey); // delete file from s3 bucket
      onDelete(data.deleteEducationResource.educationResourceName);
      setDeleteResourceOpen(false);
      refetchOffsetConnection();
    },
    onError: () => {
      setMessage("error", "There was an error deleting the resource");
    },
  });

  const [archiveEducationResource] = useArchiveEducationResourceMutation({
    onCompleted: data => {
      setArchiveResource(false);
      refetchOffsetConnection();
    },
    onError: () => {
      setMessage("error", "There was an error archiving the resource");
    },
  });

  const [updateShareScope] = useEditEducationResourceSharingScopeMutation({
    onCompleted: data => {
      refetchOffsetConnection();
    },
  });

  const handleEditResource = (input: EditEducationResourceInput) => {
    editEducationResource({ variables: { input } });
  };

  const handleDeleteResource = async (id: string, resourceName: string, fileKey: string) => {
    deleteEducationResource({ variables: { input: { educationResourceId: id, educationResourceName: resourceName, fileKey } } });
  };

  const handleArchiveResource = (id: string) => {
    archiveEducationResource({ variables: { input: { id } } });
    setArchiveResource(true);
  };

  const handleEditIsShared = (id: string, isShared: boolean) => {
    updateShareScope({ variables: { input: { educationResourceId: id, isShared } } });
  };

  return (
    <>
      {rows.map(row => {
        const { id, name, category, description, updatedAt, updatedBy, createdBy, createdAt, isArchived } = row;
        return (
          <TableRow
            id={id}
            key={id}
            hover
            sx={{ cursor: "pointer" }}
            onClick={() => {
              if (!isArchived) {
                setCurrentResource(row);
                setEditResource(true);
              }
            }}
          >
            {!isArchived && (
              <TableCell>
                <Tooltip title={"Share resource with athletes"}>
                  <FormControl>
                    <Switch
                      size="medium"
                      inputProps={{
                        "aria-label": "Share resource with athletes",
                      }}
                      checked={row.isShared}
                      onClick={e => e.stopPropagation()}
                      onChange={e => {
                        handleEditIsShared(row.id, e.target.checked);
                      }}
                    />
                  </FormControl>
                </Tooltip>
              </TableCell>
            )}
            <TableCell>
              <Typography variant="body2">{name}</Typography>
            </TableCell>
            <TableCell sx={{ maxWidth: "340px", overflow: "hidden" }}>
              {description ? (
                <TWTooltip title={`Description`} subcopy={description}>
                  <Typography
                    variant="body2"
                    sx={{
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {description}
                  </Typography>
                </TWTooltip>
              ) : null}
            </TableCell>
            {!isArchived && (
              <TableCell>
                <Typography variant="body2">{category.name}</Typography>
              </TableCell>
            )}
            <TableCell sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              <ShareWithCell educationResource={getResourceInfo(row)} />
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {isArchived ? `${updatedBy?.firstName ?? ""} ${updatedBy?.lastName ?? ""}` : `${createdBy.firstName} ${createdBy.lastName}`}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{isArchived ? formatDateWithLocale(updatedAt) : formatDateWithLocale(createdAt)}</Typography>
            </TableCell>
            {!isArchived && (
              <TableCell>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    setCurrentResource(row);
                    setMoreAnchorEl(e.currentTarget);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        );
      })}
      <Menu
        anchorEl={moreAnchorEl}
        open={Boolean(moreAnchorEl)}
        onClose={clearMoreButton}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          dense
          onClick={() => {
            setMoreAnchorEl(null);
            setPreviewResourceOpen(true);
          }}
        >
          Preview File
        </MenuItem>
        <MenuItem
          dense
          onClick={() => {
            setMoreAnchorEl(null);
            setViewHistoryOpen(true);
          }}
        >
          Check view history
        </MenuItem>
        <MenuItem
          dense
          onClick={() => {
            setMoreAnchorEl(null);
            setArchiveResource(true);
          }}
        >
          Archive
        </MenuItem>
        <MenuItem
          dense
          onClick={() => {
            setMoreAnchorEl(null);
            setDeleteResourceOpen(true);
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      {currentResource && editResource && (
        <EditEducationResourceDialog
          isOpen={editResource}
          onClose={() => {
            setEditResource(false);
            setCurrentResource(null);
          }}
          onResourceUpload={handleEditResource}
          initialValues={currentResource}
        />
      )}
      {currentResource && deleteResourceOpen && (
        <DeleteEducationResourceDialog
          open={deleteResourceOpen}
          onCancel={() => {
            setDeleteResourceOpen(false);
            setCurrentResource(null);
          }}
          onDelete={() => handleDeleteResource(currentResource.id, currentResource.name, currentResource.fileKey)}
        />
      )}
      {currentResource && archiveResource && (
        <ArchiveEducationResourceDialog
          open={archiveResource}
          onCancel={() => {
            setArchiveResource(false);
            setCurrentResource(null);
          }}
          onArchive={() => handleArchiveResource(currentResource.id)}
        />
      )}
      {currentResource && viewHistoryOpen && (
        <EducationResourceViewHistoryDrawer educationResource={currentResource} onClose={() => setViewHistoryOpen(false)} />
      )}
      {currentResource && previewResourceOpen && (
        <ResourcePreviewModal
          educationResource={currentResource}
          open={previewResourceOpen}
          onClose={() => setPreviewResourceOpen(false)}
        />
      )}
    </>
  );
};
