import React, { useCallback, useEffect, useRef } from "react";

interface IntersectRowProps {
  next: () => void;
  renderChild: (childRef: React.MutableRefObject<HTMLDivElement | null>) => JSX.Element;
  viewPortRef?: React.MutableRefObject<HTMLDivElement | null>; // This must be an ancestor of the child.
}

const IntersectRow = ({ next, renderChild, viewPortRef }: IntersectRowProps) => {
  // TODO: what is the right typing here? does it need to be generic extendinng Element
  const childRef = useRef<HTMLDivElement | null>(null);
  const handleIntersect = useCallback<IntersectionObserverCallback>(
    entries => {
      const entry = entries.length > 0 ? entries[0] : undefined;
      if (entry?.isIntersecting) {
        next();
      }
    },
    [next]
  );
  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const options = {
      root: viewPortRef?.current ?? null, // default to the viewport or use a provided viewport
      rootMargin: "16px", // once the child is within 16px of edge of viewport trigger
      threshold: 0, // trigger once any part of the child enters the viewport with margins
    };
    if (childRef.current) {
      const target = childRef.current;
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(target);
      return () => {
        observer.unobserve(target);
      };
    }
    return;
  }, [handleIntersect, childRef, viewPortRef]);

  return renderChild(childRef);
};

export default IntersectRow;
