type ErrorName = "INVALID_FILE_ERROR" | "UPLOAD_INIT_ERROR" | "UPLOAD_ERROR" | "INVALID_IMAGE_ERROR" | "INVALID_URL_ERROR" | "GPT_ERROR";

export class TranscribeError extends Error {
  override name: ErrorName;
  override message: string;
  override cause: any;

  constructor({ name, message, cause }: { name: ErrorName; message: string; cause?: any }) {
    super();
    this.name = name;
    this.message = message;
    this.cause = cause;
  }
}

export const getUserFriendlyMessage = (error: any) => {
  let userFriendlyMessage;
  if (error instanceof TranscribeError) {
    userFriendlyMessage = error.message;
  } else if (error.message.includes("Unexpected number in JSON at position")) {
    userFriendlyMessage = "Please try again with a different serving amount";
  } else if (error.message.includes("User is not a member of an org")) {
    userFriendlyMessage = "You must be a member of an organization to generate a recipe";
  } else if (error.message.includes("customer support")) {
    userFriendlyMessage = error.message;
  } else {
    userFriendlyMessage = `Error with generating a recipe`;
  }
  return userFriendlyMessage;
};
