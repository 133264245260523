import { datadogRum } from "@datadog/browser-rum";
import { MaybeJwtUser } from "@notemeal/shared-ui";
import axios from "axios";
import React from "react";
import { AppError } from "../components/AppError/AppError";
import { getNoAuthErrorStrings } from "../components/AppError/AppErrorUtils";
import { environment } from "../environment";
import { getUser } from "../utils/tokens";
import { AppUpdateCheckPage } from "./Auth/AppUpdateCheckPage";

interface ErrorBoundaryProps {
  user: MaybeJwtUser;
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  override componentDidCatch(error: Error, info: any) {
    console.error({ error, info });
    datadogRum.addError(error, { info });
    const user = this.props.user;
    const userString = user ? `${user.username} (${user.orgMembership?.org.name})` : "Public User Error";
    axios({
      url: environment.VITE_CLIENT_ERROR_SLACK_API_URL,
      method: "post",
      data: JSON.stringify({
        attachments: [
          {
            pretext: "ErrorBoundary caught the following error",
            text: `*User: * ${userString}\r\n\`${error.message}\`\r\n*Stacktrace: * \`\`\`${error.stack}\`\`\``,
            color: "#D92121",
            ts: new Date().getTime(),
          },
          {
            pretext: "Component Stack Trace",
            text: `\`\`\`${info.componentStack}\`\`\``,
            color: "#FFC30B",
          },
        ],
      }),
    });
  }

  override render() {
    if (this.state.hasError) {
      return !getUser() ? (
        // When an exception is thrown in a no auth page, the navigate(-1) inside AppError may not work.  It appears to be
        // because useNavigation causes a re-render of the page that threw the error before navigating according to this discussion:
        //
        // react router issue: useNavigate hook causes waste rendering
        // https://github.com/remix-run/react-router/issues/7634
        //
        // I tried some of the fixes but I ran into trouble because we're really using react router 6 via back compat to react router 5.
        //
        // So for now, upon seeing a catastrophic error in a no auth page that won't navigate back, the user would have to do their own browser refresh.
        <AppError {...getNoAuthErrorStrings()} handleFinished={() => this.setState({ hasError: false })} />
      ) : (
        <AppUpdateCheckPage onUpdated={() => this.setState({ hasError: false })} wasError />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
