import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ReplayIcon from "@mui/icons-material/Replay";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { newId, ScheduleContainer } from "@notemeal/shared-ui";
import { parseTime, safeSerializeTime } from "@notemeal/utils-date-time";
import { MealType } from "../../../types";
import Activity from "./Activity";
import Meal from "./Meal";
import { FoodLogMergeFormActivityState, FoodLogMergeFormState, TimelineMealForState, TimelineMealsById } from "./utils";

const DEFAULT_START = "06:00:00";
const DEFAULT_END = "06:30:00";

interface FoodLogMergeFormProps {
  allTimelineMeals: TimelineMealForState[];
  state: FoodLogMergeFormState;
  onChange: (foodLog: FoodLogMergeFormState) => void;
  onReset: () => void;
}

const FoodLogMergeForm = ({ state, onChange, onReset, allTimelineMeals }: FoodLogMergeFormProps) => {
  const handleAddMeal = () =>
    onChange({
      ...state,
      meals: [
        ...state.meals,
        {
          id: newId(),
          name: "",
          type: "snack",
          timelineMealIds: [],
          startTime: DEFAULT_START,
          endTime: DEFAULT_END,
          startValue: parseTime(DEFAULT_START),
          endValue: parseTime(DEFAULT_END),
        },
      ],
    });

  const handleAddActivity = () =>
    onChange({
      ...state,
      activities: [
        ...state.activities,
        {
          id: newId(),
          name: "",
          type: "practice",
          startTime: DEFAULT_START,
          endTime: DEFAULT_END,
          startValue: parseTime(DEFAULT_START),
          endValue: parseTime(DEFAULT_END),
        },
      ],
    });

  const handleChangeMealName = (mealId: string, name: string) =>
    onChange({
      ...state,
      meals: state.meals.map(m =>
        m.id !== mealId
          ? m
          : {
              ...m,
              name,
            }
      ),
    });

  const handleChangeMealStartEnd = (mealId: string, timeKey: "startTime" | "endTime", value: Date | null) => {
    const timeValueKey = timeKey === "startTime" ? "startValue" : "endValue";
    const stringValue = safeSerializeTime(value);
    onChange({
      ...state,
      meals: state.meals.map(m =>
        m.id !== mealId
          ? m
          : {
              ...m,
              [timeValueKey]: value,
              [timeKey]: stringValue ? stringValue : m[timeKey],
            }
      ),
    });
  };

  const handleChangeMealType = (mealId: string, type: MealType) =>
    onChange({
      ...state,
      meals: state.meals.map(m =>
        m.id !== mealId
          ? m
          : {
              ...m,
              type,
            }
      ),
    });

  const handleMoveFoodLogMeal = (mealId: string, timelineMealId: string) =>
    onChange({
      ...state,
      meals: state.meals.map(m =>
        m.id !== mealId
          ? {
              ...m,
              timelineMealIds: m.timelineMealIds.filter(tlmId => tlmId !== timelineMealId),
            }
          : {
              ...m,
              timelineMealIds: [...m.timelineMealIds, timelineMealId],
            }
      ),
    });

  const handleRemoveFoodLogMeal = (mealId: string, timelineMealId: string) =>
    onChange({
      ...state,
      meals: state.meals.map(m =>
        m.id !== mealId
          ? m
          : {
              ...m,
              timelineMealIds: m.timelineMealIds.filter(tlmId => tlmId !== timelineMealId),
            }
      ),
    });

  const handleRemoveMeal = (mealId: string) =>
    onChange({
      ...state,
      meals: state.meals.filter(m => m.id !== mealId),
    });

  const handleChangeActivity = (activityId: string, activity: FoodLogMergeFormActivityState) =>
    onChange({
      ...state,
      activities: state.activities.map(a => (a.id === activityId ? activity : a)),
    });

  const handleRemoveActivity = (activityId: string) =>
    onChange({
      ...state,
      activities: state.activities.filter(a => a.id !== activityId),
    });

  const allTimelineMealsById = allTimelineMeals.reduce<TimelineMealsById>((timelineMealsById, meal) => {
    return {
      ...timelineMealsById,
      [meal.id]: meal,
    };
  }, {});

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body1Semibold">Drag and drop meals to customize meal plan</Typography>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Button
            variant="outlined"
            startIcon={<RestaurantIcon />}
            onClick={handleAddMeal}>
            Add Meal
          </Button>
          <Button
            variant="outlined"
            startIcon={<DirectionsRunIcon />}
            onClick={handleAddActivity}>
            Add Activity
          </Button>
          <IconButton onClick={onReset}>
            <ReplayIcon />
          </IconButton>
        </Box>
      </Box>
      <ScheduleContainer
        sx={{ overflow: "auto" }}
        meals={state.meals}
        mealToStart={m => m.startTime}
        renderMeal={meal => (
          <Meal
            state={meal}
            onChangeName={name => handleChangeMealName(meal.id, name)}
            onChangeStart={start => handleChangeMealStartEnd(meal.id, "startTime", start)}
            onChangeEnd={end => handleChangeMealStartEnd(meal.id, "endTime", end)}
            onChangeType={type => handleChangeMealType(meal.id, type)}
            onMoveFoodLogMeal={tlmId => handleMoveFoodLogMeal(meal.id, tlmId)}
            onRemoveFoodLogMeal={tlmId => handleRemoveFoodLogMeal(meal.id, tlmId)}
            onRemove={() => handleRemoveMeal(meal.id)}
            allTimelineMeals={allTimelineMealsById}
          />
        )}
        activities={state.activities}
        activityToStart={a => a.startTime}
        renderActivity={activity => (
          <Activity
            state={activity}
            onChange={a => handleChangeActivity(activity.id, a)}
            onRemove={() => handleRemoveActivity(activity.id)}
          />
        )}
      />
    </>
  );
};

export default FoodLogMergeForm;
