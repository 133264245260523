import BackIcon from "@mui/icons-material/ArrowBack";
import { Dialog, DialogContent, Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import {
    KdsPageMealMenuPlateOrderPreviewFragment,
    KdsPageMenuOrderPreviewFragment,
    KdsTicketMealMenuPlateOrderFragment,
    KdsTicketMenuOrderFragment,
    useKdsTicketsMultipleMenuOrdersAndPlateOrdersQuery,
} from "../../types";
import Button from "./ChangeStatusDialog/Button";
import KdsPrintableLabel from "./Ticket/PrintableLabel";

import DialogTitle from "../../componentLibrary/DialogTitle";
import { MarkCancelButton, MarkCookButton, MarkDoneButton, MarkNewButton, PrintButton } from "./ChangeStatusDialog/ActionButtons";
import { MakeHandleAirPrintFn, PrintMethod, WebPrinterTicketOrder } from "./types";
import { createTicketOrderForWebPrinter, sortMenuOrders } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // Needed to print labels!
    noprint: {
      "@media print": {
        display: "none !important",
      },
      "@media screen": {
        display: "block",
      },
    },
    content: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    icon: {
      fontSize: 96,
    },
    grey: { color: theme.palette.grey[500] },

    square: {
      height: "40vh",
      display: "flex",
    },
    flexCenterColumn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    dialogTitleRoot: {
      paddingBottom: 0,
      "@media print": {
        display: "none !important",
      },
      "@media screen": {
        display: "block",
      },
    },
  })
);

interface BulkSelectDialogProps {
  onClose: () => void;
  sortedOrderPreviews: KdsPageMenuOrderPreviewFragment[];
  sortedPlateOrderPreviews: KdsPageMealMenuPlateOrderPreviewFragment[];
  diningStationIds: string[];
  handleCompleteOrders: (sortedOrders: KdsTicketMenuOrderFragment[], plateOrders: KdsTicketMealMenuPlateOrderFragment[]) => void;
  handleCookOrders: (sortedOrders: KdsTicketMenuOrderFragment[], plateOrders: KdsTicketMealMenuPlateOrderFragment[]) => void;
  handleMarkOrdersNew: (sortedOrders: KdsTicketMenuOrderFragment[], plateOrders: KdsTicketMealMenuPlateOrderFragment[]) => void;
  handleCancelOrders: (sortedOrders: KdsTicketMenuOrderFragment[], plateOrders: KdsTicketMealMenuPlateOrderFragment[]) => void;
  menuOrderItemIdsToDiningStationName: Record<string, string>;
  makeHandleAirPrintMany: MakeHandleAirPrintFn;
  printMethod: PrintMethod;
  handleWebPrintMany?: (sortedOrders: WebPrinterTicketOrder[]) => Promise<void>;
}

const BulkSelectDialog = ({
  onClose,
  sortedOrderPreviews,
  sortedPlateOrderPreviews,
  diningStationIds,
  handleCompleteOrders,
  handleCookOrders,
  handleMarkOrdersNew,
  handleCancelOrders,
  handleWebPrintMany,
  makeHandleAirPrintMany,
  printMethod,
  menuOrderItemIdsToDiningStationName,
}: BulkSelectDialogProps) => {
  const classes = useStyles();
  const { data, loading } = useKdsTicketsMultipleMenuOrdersAndPlateOrdersQuery({
    variables: {
      menuOrderIds: sortedOrderPreviews.map(menuOrder => menuOrder.id),
      mealMenuPlateOrderIds: sortedPlateOrderPreviews.map(plateOrder => plateOrder.id),
      diningStationIds,
    },
  });

  const orders = data && data.menuOrders && !loading ? data.menuOrders : [];
  const plateOrders = data && data.mealMenuPlateOrders && !loading ? data.mealMenuPlateOrders : [];
  const _createTicketOrderForWebPrinter = (orderPreview: KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment) => {
    return createTicketOrderForWebPrinter(orderPreview, [...orders, ...plateOrders], menuOrderItemIdsToDiningStationName);
  };

  const createPrintableOrder = (orderPreview: KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment) => {
    const ticketOrderForWebPrinter = _createTicketOrderForWebPrinter(orderPreview);
    if (ticketOrderForWebPrinter) {
      return <KdsPrintableLabel key={ticketOrderForWebPrinter.id} order={ticketOrderForWebPrinter} />;
    }
    return null;
  };

  const combinedSortedOrderPreviews = sortMenuOrders([...sortedOrderPreviews, ...sortedPlateOrderPreviews]);
  const Print = (
    <PrintButton
      key="print-button"
      onClose={onClose}
      action={async () =>
        printMethod === "WebPrint" && handleWebPrintMany
          ? await handleWebPrintMany(combinedSortedOrderPreviews.map(_createTicketOrderForWebPrinter))
          : makeHandleAirPrintMany(() => "selected-kds-orders")()
      }
    />
  );

  const MarkDone = (
    <MarkDoneButton
      key="mark-done-button"
      onClose={onClose}
      action={() => handleCompleteOrders([...orders], [...plateOrders])} />
  );

  const MarkCook = (
    <MarkCookButton
      key="mark-cook-button"
      onClose={onClose}
      action={() => handleCookOrders([...orders], [...plateOrders])} />
  );

  const MarkNew = (
    <MarkNewButton
      key="mark-new-button"
      onClose={onClose}
      action={() => handleMarkOrdersNew([...orders], [...plateOrders])} />
  );

  const MarkCancel = (
    <MarkCancelButton
      key="mark-cancel-button"
      onClose={onClose}
      action={() => handleCancelOrders([...orders], [...plateOrders])} />
  );

  const renderContent = () => {
    if (!data || !data.menuOrders || loading) {
      return <Loading progressSize="lg" />;
    }
    return (
      <>
        <Grid container spacing={3}>
          <Button
            onClose={onClose}
            onClick={() => onClose()}
            label="Back">
            <BackIcon className={classes.grey} classes={{ root: classes.icon }} />
          </Button>
          {[Print, MarkDone, MarkCook, MarkNew, MarkCancel]}
        </Grid>
        <div id="selected-kds-orders">{combinedSortedOrderPreviews.map(orderPreview => createPrintableOrder(orderPreview))}</div>
      </>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen
      classes={{ root: classes.noprint }}>
      <DialogTitle title={`${combinedSortedOrderPreviews.length} Order(s) selected`} onClose={onClose} />
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  );
};

export default BulkSelectDialog;
