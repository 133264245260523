import { ScheduleContainer } from "@notemeal/shared-ui";
import { Macros, initMacros } from "@notemeal/shared-utils-macro-protocol";
import { Dispatch } from "react";
import { Activity } from "../../MealTemplate/Acitivity";
import { getTotalParts } from "../mealPlanTemplateUtils";
import EditMealMacroTargets from "./EditMealMacroTargets";
import { EditMealOptions } from "./EditMealOptions";
import { ScheduleAction, ScheduleState } from "./scheduleReducer";

interface ScheduleProps {
  showMealMacroTargets: boolean;
  viewOnly?: boolean;
  isShared: boolean;
  scheduleState: ScheduleState;
  macros: Macros;
  dispatch: Dispatch<ScheduleAction>;
}

const Schedule = ({ showMealMacroTargets, viewOnly, isShared, scheduleState, macros, dispatch }: ScheduleProps) => {
  const { meals, activities } = scheduleState;
  const { cho: choGrams, pro: proGrams, fat: fatGrams } = macros;
  const totalParts = getTotalParts(meals);
  const { choTotalParts, proTotalParts, fatTotalParts } = totalParts;

  return (
    <div>
      <ScheduleContainer
        sx={{ px: 2, minWidth: "1000px" }}
        meals={meals}
        activities={activities}
        mealToStart={mealTemplate => mealTemplate.meal.start}
        activityToStart={activityTemplate => activityTemplate.start}
        renderMeal={meal => {
          const { choRatio, proRatio, fatRatio } = meal;
          const cho = choGrams * (choRatio / choTotalParts);
          const pro = proGrams * (proRatio / proTotalParts);
          const fat = fatGrams * (fatRatio / fatTotalParts);
          const macros = initMacros(cho, pro, fat);
          return showMealMacroTargets ? (
            <EditMealMacroTargets
              meal={meal}
              macros={macros}
              viewOnly={viewOnly}
              dispatch={dispatch}
              {...totalParts} />
          ) : (
            <EditMealOptions
              meal={meal}
              viewOnly={viewOnly}
              isShared={isShared}
              macros={macros}
              dispatch={dispatch} />
          );
        }}
        renderActivity={activityTemplateState => <Activity activity={{ ...activityTemplateState, __typename: "Activity" }} />}
      />
    </div>
  );
};

export default Schedule;
