import { setContext } from "@apollo/client/link/context";
import { nutritionixRestLink, nutritionixV1RestLink } from "@notemeal/shared-ui";

import { String } from "runtypes";
import { getLocalStorageValue } from "../utils/localStorage";
import { getUser } from "../utils/tokens";

const nutritionixAuthLink = setContext(async () => {
  const user = getUser();
  const nixAppId = getLocalStorageValue("nixAppId", String);
  const nixAppKey = getLocalStorageValue("nixAppKey", String);
  if (!nixAppId || !nixAppKey) {
    console.error("Can't fetch nutritionix foods w/o nixAppId/key!");
  }
  return {
    headers: {
      "x-app-id": nixAppId,
      "x-app-key": nixAppKey,
      "x-remote-user-id": user?.id,
    },
  };
});

export const nutritionixLink = nutritionixAuthLink.concat(nutritionixRestLink);
export const nutritionixV1Link = nutritionixV1RestLink;
