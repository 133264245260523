import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, TextField, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { SelfServiceResponse, sendSelfServiceRequest } from "@notemeal/shared-ui";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom-v5-compat";
import {
    SelfServiceAthleteForm,
    selfServiceAthleteFormDefaultValues,
    SelfServiceAthleteFormSchema,
} from "../../components/Athlete/Form/SelfServiceFormSchema";
import { SelfServiceAthletesResult } from "./SelfServiceAthletesResult";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
  })
);

export const SelfServiceAthletesPage = () => {
  const classes = useStyles();
  const { orgId } = useParams();
  const [response, setResponse] = useState<SelfServiceResponse>();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<SelfServiceAthleteForm>({
    defaultValues: selfServiceAthleteFormDefaultValues,
    resolver: zodResolver(SelfServiceAthleteFormSchema),
  });

  const onSubmit: SubmitHandler<SelfServiceAthleteForm> = async data => {
    try {
      const res = await sendSelfServiceRequest(data);
      setResponse(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (orgId && setValue) {
      setValue("orgId", orgId);
    }
  }, [orgId, setValue]);

  return response ? (
    <SelfServiceAthletesResult data={response} />
  ) : (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={({ spacing }) => ({
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: 414,
          justifyContent: "center",
          alignItems: "center",
          gap: spacing(2),
          padding: spacing(2),
        })}
      >
        <Typography component="span" variant="h3">
          Request Activation Link
        </Typography>
        <Typography variant="body2" color="mediumEmphasisText">
          Fill out the details below. Please note that the name must match the one on the school roster.
        </Typography>
        <Controller
          name={"firstName"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="First Name"
              required
              disabled={!orgId}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName?.message ?? " "}
              autoFocus={!field.value}
              {...field}
              sx={{ width: "100%" }}
            />
          )}
        />
        <Controller
          name={"lastName"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Last Name"
              required
              disabled={!orgId}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName?.message ?? " "}
              {...field}
              sx={{ width: "100%" }}
            />
          )}
        />
        <Controller
          name={"email"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Email"
              required
              disabled={!orgId}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              {...field}
              sx={{ width: "100%" }}
            />
          )}
        />
        <Controller
          name={"orgId"}
          control={control}
          render={({ field: { ref, ...field } }) => <TextField
            {...field}
            value={orgId}
            sx={{ display: "none" }} />}
        />
        <Button
          type="submit"
          size={"medium"}
          sx={({ spacing }) => ({
            marginTop: spacing(4),
            width: "100%",
          })}
        >
          Send Request
        </Button>
      </Box>
    </form>
  );
};
