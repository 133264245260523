import { scaleServingAmounts } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { inputToNumber } from "@notemeal/shared-ui";
import {
  ExcessiveServingAmountFragment,
  MealMenuIngredientsAnalyzerFragment,
  MenuItemIngredientsAnalyzerFragment,
  ServingAmountForConversionFragment,
} from "../../../types";

export interface MenuIngredientsAnalyzerState {
  readonly diningStations: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly menuItems: readonly MenuIngredientsAnalyzerState_Item[];
  }>;
}

export interface MenuIngredientsAnalyzerState_Item {
  readonly id: string;
  readonly name: string;
  readonly amount: number;
  readonly amountInput: string;
  readonly expanded: boolean;
  readonly servingName: string;
  readonly servingAmounts: readonly ExcessiveServingAmountFragment[];
  readonly choices: readonly MenuIngredientsAnalyzerState_ItemChoice[];
}

interface MenuIngredientsAnalyzerState_ItemChoice {
  readonly id: string;
  readonly name: string;
  readonly options: readonly MenuIngredientsAnalyzerState_ItemChoiceOption[];
}

interface MenuIngredientsAnalyzerState_ItemChoiceOption {
  readonly id: string;
  readonly name: string;
  readonly amount: number;
  readonly amountInput: string;
  readonly servingAmounts: readonly ExcessiveServingAmountFragment[];
}

export const getMenuIngredientsAnalyzerState = (menu: MealMenuIngredientsAnalyzerFragment): MenuIngredientsAnalyzerState => {
  return {
    diningStations: sortByKey(menu.mealMenuDiningStations, "position").map(({ id, name, menuItemAppearances }) => {
      return {
        id,
        name,
        menuItems: sortByKey(menuItemAppearances, "position").map(({ menuItem }) => getMenuIngredientsAnalyzerState_Item(menuItem)),
      };
    }),
  };
};

const getMenuIngredientsAnalyzerState_Item = ({
  id,
  name,
  servingAmounts,
  servingName,
  choices,
}: MenuItemIngredientsAnalyzerFragment): MenuIngredientsAnalyzerState_Item => {
  return {
    id,
    name,
    servingName,
    servingAmounts,
    expanded: false,
    amount: 0,
    amountInput: "0",
    choices: sortByKey(choices, "position").map(({ id, name, options }) => {
      return {
        id,
        name,
        options: sortByKey(options, "position").map(({ id, name, servingAmounts }) => ({
          id,
          name,
          servingAmounts,
          amount: 0,
          amountInput: "0",
        })),
      };
    }),
  };
};

interface ChangeMenuItemAmount {
  type: "ChangeMenuItemAmount";
  payload: {
    menuItemId: string;
    amountInput: string;
  };
}

interface ToggleMenuItemExpanded {
  type: "ToggleMenuItemExpanded";
  payload: {
    menuItemId: string;
  };
}

interface ChangeMenuItemChoiceOptionAmount {
  type: "ChangeMenuItemChoiceOptionAmount";
  payload: {
    menuItemId: string;
    menuItemChoiceOptionId: string;
    amountInput: string;
  };
}

export type MenuIngredientsAnalyzerAction = ChangeMenuItemAmount | ToggleMenuItemExpanded | ChangeMenuItemChoiceOptionAmount;

export const menuIngredientsAnalyzerReducer = (
  state: MenuIngredientsAnalyzerState,
  action: MenuIngredientsAnalyzerAction
): MenuIngredientsAnalyzerState => {
  switch (action.type) {
    case "ToggleMenuItemExpanded":
      return reducerHelperMenuItem(state, action.payload.menuItemId, mi => ({
        ...mi,
        expanded: !mi.expanded,
      }));
    case "ChangeMenuItemAmount":
      return reducerHelperMenuItem(state, action.payload.menuItemId, mi => ({
        ...mi,
        amountInput: action.payload.amountInput,
        amount: inputToNumber(action.payload.amountInput) ?? 0,
      }));
    case "ChangeMenuItemChoiceOptionAmount":
      return reducerHelperMenuItem(state, action.payload.menuItemId, mi => ({
        ...mi,
        choices: mi.choices.map(mic =>
          !mic.options.map(o => o.id).includes(action.payload.menuItemChoiceOptionId)
            ? mic
            : {
                ...mic,
                options: mic.options.map(mico =>
                  mico.id !== action.payload.menuItemChoiceOptionId
                    ? mico
                    : {
                        ...mico,
                        amountInput: action.payload.amountInput,
                        amount: inputToNumber(action.payload.amountInput) ?? 0,
                      }
                ),
              }
        ),
      }));
  }
};

const reducerHelperMenuItem = (
  state: MenuIngredientsAnalyzerState,
  menuItemId: string,
  updateFn: (mi: MenuIngredientsAnalyzerState_Item) => MenuIngredientsAnalyzerState_Item
): MenuIngredientsAnalyzerState => {
  return {
    ...state,
    diningStations: state.diningStations.map(ds => ({
      ...ds,
      menuItems: !ds.menuItems.map(mi => mi.id).includes(menuItemId)
        ? ds.menuItems
        : ds.menuItems.map(mi => (mi.id !== menuItemId ? mi : updateFn(mi))),
    })),
  };
};

export const getCombinedMenuItemServingAmounts = (state: MenuIngredientsAnalyzerState): readonly ServingAmountForConversionFragment[] => {
  return state.diningStations.flatMap(ds => ds.menuItems.flatMap(getMenuItemServingAmounts));
};

export const getMenuItemServingAmounts = (menuItem: MenuIngredientsAnalyzerState_Item): readonly ServingAmountForConversionFragment[] => {
  const optionServingAmounts = menuItem.choices.flatMap(mic => mic.options.flatMap(o => scaleServingAmounts(o.servingAmounts, o.amount)));

  if (
    menuItem.servingAmounts.length === 1 &&
    menuItem.servingAmounts[0].serving.foodOrRecipe.__typename === "Recipe" &&
    menuItem.servingAmounts[0].serving.perRecipeYield !== null
  ) {
    const recipeIngredients = scaleServingAmounts(
      menuItem.servingAmounts[0].serving.foodOrRecipe.ingredients,
      (menuItem.amount * menuItem.servingAmounts[0].amount) / menuItem.servingAmounts[0].serving.perRecipeYield
    );
    return [...recipeIngredients, ...optionServingAmounts];
  }

  return [...scaleServingAmounts(menuItem.servingAmounts, menuItem.amount), ...optionServingAmounts];
};
