import { TeamsTableTeamFragment } from "../../types";
import { sortByFn, sortByKey } from "@notemeal/utils-sort";

export const isSubscribed = (team: TeamsTableTeamFragment) => {
  return team.foodLogNotificationSettings ? Object.values(team.foodLogNotificationSettings).some(s => s === true) : false;
};

export const filterAndSortTeamsForTable = (teams: readonly TeamsTableTeamFragment[], searchTerm: string) => {
  const filteredTeams = teams.filter(t => t.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));

  return filteredTeams ? sortByFn(sortByKey(filteredTeams, "name"), t => (isSubscribed(t) ? 0 : 1)) : [];
};

export const getMobileUserCountForTeam = (team: TeamsTableTeamFragment) => {
  return team.athletes.filter(athlete => athlete.orgMembership?.isActive && !athlete.isArchived).length;
};
