import { Grid } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import { ReactNode } from "react";
import { MenuItemFormFragment, useMyScoringSystemQuery } from "../../../types";
import { ReadonlyAddOns } from "./AddOns";
import { ReadonlyImage } from "./Image";
import { ReadonlyIngredients } from "./Ingredients";
import { ReadonlyItemDetails } from "./ItemDetails";

interface MenuItemReadonlyDialogContentProps {
  menuItem: MenuItemFormFragment;
  orderDetails?: ReactNode;
}

const MenuItemReadonlyDialogContent = ({ menuItem, orderDetails }: MenuItemReadonlyDialogContentProps) => {
  const { name, servingName, description, servingAmounts, choices, imageUrl, suggestionCategory, foodCategory, score } = menuItem;
  const { data: scoringSystemData, loading: loadingScoringSystem } = useMyScoringSystemQuery();
  const scoringSystem = scoringSystemData?.myScoringSystem;

  return (
    <Grid
      sx={{ p: 2 }}
      container
      spacing={4}
      direction="row">
      {/* Left Panel */}
      <Grid item xs={8}>
        <Grid
          container
          direction={"row"}
          spacing={3}>
          {/** Details and Order Details row */}
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                {loadingScoringSystem ? (
                  <Loading />
                ) : (
                  <ReadonlyItemDetails
                    name={name}
                    servingName={servingName}
                    description={description}
                    suggestionCategory={suggestionCategory}
                    foodCategory={foodCategory}
                    score={score}
                    scoringSystem={scoringSystem}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {orderDetails}
              </Grid>
            </Grid>
          </Grid>
          {/* Ingredients and Add ons row */}
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <ReadonlyIngredients servingAmounts={servingAmounts} />
              </Grid>
              <Grid item xs={6}>
                <ReadonlyAddOns choices={choices} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <ReadonlyImage imageUrl={imageUrl} />
      </Grid>
    </Grid>
  );
};

export default MenuItemReadonlyDialogContent;
