import CloseIcon from "@mui/icons-material/Close";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Card, IconButton, TextField, Typography } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { useDrop } from "react-dnd";
import { MealType } from "../../../types";
import MealTypeSelect from "../../Meal/TypeSelect";
import FoodLogMeal from "./FoodLogMeal";
import { FoodLogMealDnDType, FoodLogMergeFormMealState, TimelineMealsById } from "./utils";

interface FoodLogMergeFormMealProps {
  state: FoodLogMergeFormMealState;
  allTimelineMeals: TimelineMealsById;
  onChangeName: (name: string) => void;
  onChangeStart: (start: Date | null) => void;
  onChangeEnd: (end: Date | null) => void;
  onChangeType: (type: MealType) => void;
  onMoveFoodLogMeal: (foodLogMealId: string) => void;
  onRemoveFoodLogMeal: (foodLogMealId: string) => void;
  onRemove: () => void;
}

const FoodLogMergeFormMeal = ({
  state,
  allTimelineMeals,
  onChangeName,
  onChangeStart,
  onChangeEnd,
  onChangeType,
  onMoveFoodLogMeal,
  onRemoveFoodLogMeal,
  onRemove,
}: FoodLogMergeFormMealProps) => {
  const { name, type, startValue, endValue, timelineMealIds } = state;

  const [{ canDrop }, dropRef] = useDrop<{ id: string; type: string }, any, any>({
    accept: FoodLogMealDnDType,
    drop: item => {
      if (!timelineMealIds.includes(item.id)) {
        onMoveFoodLogMeal(item.id);
      }
    },
    collect: monitor => ({
      canDrop: monitor.canDrop() && monitor.isOver(),
    }),
  });

  return (
    <Card
      sx={{
        flex: 1,
        height: "188px",
        display: "flex",
        gap: 2,
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
      }}
    >
      <Box sx={{ mr: "2px", height: "100%", width: "6px", backgroundColor: "greyscale.400" }} />
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 1 }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
          <RestaurantIcon fontSize="small" sx={{ alignSelf: "center", color: "greyscale.400" }} />
          <TextField
            sx={{ flex: 1 }}
            autoFocus={!name}
            label="Name"
            value={name}
            onChange={e => onChangeName(e.target.value)} />
          <DesktopTimePicker
            sx={{ flex: 1 }}
            label="Start"
            value={startValue}
            onChange={datetime => onChangeStart(datetime)} />
          <DesktopTimePicker
            sx={{ flex: 1 }}
            label="End"
            value={endValue}
            onChange={datetime => onChangeEnd(datetime)} />
          <MealTypeSelect
            sx={{ flex: 1 }}
            value={type}
            onChange={mt => onChangeType(mt)} />
          <IconButton onClick={onRemove}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            mb: 1,
            flex: 1,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            borderRadius: "6px",
            backgroundColor: canDrop ? "greyscale.200" : "inherit",
          }}
          ref={dropRef}
        >
          {timelineMealIds.map(id => {
            const meal = allTimelineMeals[id];
            return meal && <FoodLogMeal
              key={id}
              timelineMeal={meal}
              onRemove={() => onRemoveFoodLogMeal(id)} />;
          })}
          {timelineMealIds.length === 0 && (
            <Typography sx={{ alignSelf: "center", width: "100%", textAlign: "center" }}>Drag meal cards here!</Typography>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default FoodLogMergeFormMeal;
