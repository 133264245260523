import { Box } from "@mui/material";
import { ImageWithLockedAspectRatio } from "@notemeal/shared-ui";
import { IMG_WIDTH_PCT, ImageWrapper } from "./utils";

interface MenuItemAppearancePreviewImageProps {
  imageUrl: string | null;
  usePlaceHolderForImage?: boolean;
}

export const MenuItemAppearancePreviewImage = ({ imageUrl, usePlaceHolderForImage }: MenuItemAppearancePreviewImageProps) => {
  const imageSize = `${IMG_WIDTH_PCT}%`;

  if (imageUrl) {
    return (
      <ImageWrapper sx={{ flexShrink: 0, minWidth: imageSize, maxWidth: imageSize }}>
        <ImageWithLockedAspectRatio imageUrl={imageUrl} borderRadius="0" />
      </ImageWrapper>
    );
  }

  if (usePlaceHolderForImage) {
    return <Box sx={{ width: 120 }} />;
  } else {
    return null;
  }
};
