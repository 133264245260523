import { TableCell, TableRow } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import { AthleteGoalConfigurationForCompliancePageFragment, GoalTrackerGoalForComplianceFragment } from "apps/web/src/types";
import { getRowDataFromGoalForTable } from "./utils";

interface AthleteGoalComplianceRowProps {
  row: GoalTrackerGoalForComplianceFragment;
  goalConfiguration: AthleteGoalConfigurationForCompliancePageFragment;
}

export const AthleteGoalComplianceRow = ({ row, goalConfiguration }: AthleteGoalComplianceRowProps) => {
  const { id } = row;
  const { target } = goalConfiguration;
  const hasTarget = target !== null;
  const { formatTimeInTimezoneWithLocale, formatDateWithLocale } = useDateFormatting();

  const rowData = getRowDataFromGoalForTable(row, goalConfiguration, formatDateWithLocale, formatTimeInTimezoneWithLocale);

  return (
    <TableRow key={id}>
      <TableCell>{rowData.date}</TableCell>
      <TableCell>{rowData.completedTime}</TableCell>
      {hasTarget ? (
        <>
          <TableCell>{rowData.value}</TableCell>
          <TableCell>{rowData.target}</TableCell>
          <TableCell>{rowData.targetUnit}</TableCell>
        </>
      ) : (
        <TableCell>{rowData.completed}</TableCell>
      )}
    </TableRow>
  );
};
