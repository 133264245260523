import { Typography } from "@mui/material/";
import { Loading, useLocaleContext } from "@notemeal/shared-ui";
import { measurementConversionToMetric } from "@notemeal/shared-utils-macro-protocol";
import { serializeDateTime } from "@notemeal/utils-date-time";
import { AthleteSchema, EmailSchema, JerseyNumberSchema, PhoneNumberSchmea, PositionSchema } from "@notemeal/validators";
import { format } from "date-fns";
import React from "react";
import { z } from "zod";
import {
  AllAthletesAndAnthropometryEntriesDocument,
  AllAthletesDocument,
  CreateMetricAthleteInput,
  MetricAnthropometryEntryFormInput,
  SexType,
  TeamRosterDocument,
  TeamsForImportAthleteTeamFragment,
  TeamsPageDocument,
  useAllAthletesForImportAthleteQuery,
  useCreateMetricAthletesMutation,
  useEditMetricAthletesMutation,
  useMyOrgFullySyncedQuery,
  useTeamsForImportAthleteQuery,
} from "../../../../types";
import { stripPunctuation } from "../../../../utils/import/punctuation";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import { ValidateFn } from "../../../universal/Import/Button";
import { ImportTabPanel, ImportTabPanelProps, TemplateFile } from "../ImportTabPanel";
import { ICrudHandler } from "../types";
import { parseImportError } from "../utils";
import { ImportableAthlete, Linkable, LinkableTeam } from "./types";
const emptyMetricAnthroEntry: Omit<MetricAnthropometryEntryFormInput, "datetime" | "sex" | "weightInKg"> = {
  heightInCm: null,
  leanBodyMassInKg: null,
  bodyFatMassInKg: null,
  percentBodyFat: null,
  comment: null,
  boneMineralDensity: null,
  boneMineralContent: null,
  boneMineralDensityZScore: null,
  boneMineralDensityTScore: null,
  boneArea: null,
  boneMass: null,
  dryLeanMassInKg: null,
  skeletalMuscleMassInKg: null,
  trunkFatInKg: null,
  visceralFatArea: null,
  type: "estimate",
};

export const teamToName = (t: { name: string }) => `${t.name}`;
export const teamAndPositionToPositionId = (t: TeamsForImportAthleteTeamFragment, positionName: string) => {
  const teamPositions = t.sport && t.sport.positions;
  const position = teamPositions
    ? teamPositions.find(p => stripPunctuation(positionName).toLowerCase() === stripPunctuation(p.name).toLowerCase())
    : null;
  return position ? position.id : null;
};

const ImportableAthleteSchema = AthleteSchema.merge(
  z.object({
    phoneNumber: PhoneNumberSchmea.nullish(),
    email: EmailSchema.nullish(),
    position: PositionSchema.nullish(),
    inbodyUid: z.string().nullish(),
    secaUid: z.string().nullish(),
    jerseyNumber: JerseyNumberSchema.nullish(),
  })
);

interface AthleteImportTabPanelProps
  extends Pick<
    ImportTabPanelProps<Linkable, ImportableAthlete, LinkableTeam>,
    "matchFields" | "loadAndParse" | "linkFields" | "linkOnFields"
  > {
  fileExtension: string;
  templateFile: TemplateFile;
  keyEntries?: string[];
  keyDisclaimer?: React.ReactNode;
}

export const ImportAthlete = ({
  matchFields,
  loadAndParse,
  linkFields,
  linkOnFields,
  fileExtension,
  keyEntries,
  keyDisclaimer,
  templateFile,
}: AthleteImportTabPanelProps) => {
  // TODO: metric update run phase - user chosen measurement system import
  // TODO: metric update - R&D metric import sources
  // TODO: should not be final implementation, current implementation for internal staff use
  const { isMetricLocale } = useLocaleContext();
  type L = LinkableTeam;
  type I = ImportableAthlete;

  const { setMessage } = useSnackbar();
  const [createMetricAthletes] = useCreateMetricAthletesMutation({
    onError: e => setMessage("error", parseImportError(e)),
  });
  const [editMetricAthletes] = useEditMetricAthletesMutation();
  const { data: athleteData, loading: loadingAthletes } = useAllAthletesForImportAthleteQuery();
  const { data: teamData, loading: loadingTeams } = useTeamsForImportAthleteQuery();
  const { data: orgData, loading: loadingOrg } = useMyOrgFullySyncedQuery();
  if (!athleteData || !teamData || !orgData || loadingAthletes || loadingTeams || loadingOrg) {
    return <Loading progressSize="lg" />;
  }

  const existingAthletes = athleteData.athletes.map(({ team, position, mostRecentAnthropometryEntry, ...rest }) => ({
    ...rest,
    teamName: teamToName(team),
    teamId: team.id,
    positionName: position ? position.name : null,
    positionId: position ? position.id : null,
    height: mostRecentAnthropometryEntry ? mostRecentAnthropometryEntry.height : null,
    weight: mostRecentAnthropometryEntry ? mostRecentAnthropometryEntry.weight : null,
  }));
  const existingTeams = teamData.teams.map(t => ({
    teamName: t.name,
    teamId: t.id,
    sex: (t.gender === "Men's" ? "male" : t.gender === "Women's" ? "female" : null) as SexType | null,
  }));
  const handleInsertAthletes = ({ matches, onCacheUpdate }: ICrudHandler<L & I>) => {
    const input: CreateMetricAthleteInput[] = matches.map(
      ({ row: { teamName, sex, teamId, positionId, positionName, height, weight, ...athlete } }) => {
        if (!sex) {
          throw new Error(
            `Team '${teamName}' is gender-neutral and athlete '${athlete.firstName} ${athlete.lastName}' has no 'sex' in the imported file. Either (1) set the 'Sex' of the team, or (2) fill in the empty 'Sex' value for the athlete, and re-import`
          );
        }
        // TODO: Use a "link" modal to link positionName to position
        const team = teamData.teams.find(t => t.id === teamId);
        if (!team) {
          throw new Error("Can't insert athlete without a team!");
        }
        return {
          teamId,
          isNotemealOnly: false, // Can not do this in NotemealLinkedOrgs
          accountVerificationMethod: null,
          athlete: {
            ...athlete,
            birthDate: athlete.birthDate ? format(new Date(athlete.birthDate), "yyyy-MM-dd") : null,
            positionId: positionName ? teamAndPositionToPositionId(team, positionName) : null,
            sex,
            secaUid: null,
            inbodyUid: athlete.inbodyUid,
            jerseyNumber: null,
            // Don't add or remove any on import
            removedTagIds: [],
            addedTagIds: [],
          },
          goal: null,
          anthropometryEntries: weight
            ? [
                {
                  ...emptyMetricAnthroEntry,
                  sex,
                  heightInCm: measurementConversionToMetric(isMetricLocale, height, "length"),
                  weightInKg: measurementConversionToMetric(isMetricLocale, weight, "weight")!,
                  datetime: serializeDateTime(new Date()),
                },
              ]
            : [],
        };
      }
    );
    const refetchQueries = [
      ...matches.flatMap(({ row: { teamId } }) => ({
        query: TeamRosterDocument,
        variables: { id: teamId },
      })),
      { query: TeamsPageDocument },
    ];
    createMetricAthletes({
      variables: {
        input,
      },
      update: onCacheUpdate,
      refetchQueries: [...refetchQueries, { query: AllAthletesDocument }, { query: AllAthletesAndAnthropometryEntriesDocument }],
    });
  };

  const handleUpdateAthletes = ({ matches, onCacheUpdate }: ICrudHandler<L & I>) => {
    //  matches.matchedRows should be a single list!
    const input = matches.map(({ matchedRows, row: { teamName, sex, teamId, positionName, weight, height, inbodyUid, ...athlete } }) => {
      if (!sex) {
        throw new Error(
          `Team '${teamName}' is gender-neutral and athlete '${athlete.firstName} ${athlete.lastName}' has no 'sex' in the imported file. Either (1) set the 'Gender' of the team, or (2) fill in the empty 'Sex' value for the athlete, and re-import`
        );
      }

      //only add weight & height if athlete has no existing anthros
      const metricAnthropometryEntry =
        !matchedRows[0].weight && weight
          ? {
              ...emptyMetricAnthroEntry,
              sex,
              heightInCm: measurementConversionToMetric(isMetricLocale, height, "length"),
              weightInKg: measurementConversionToMetric(isMetricLocale, weight, "weight")!,
              datetime: serializeDateTime(new Date()),
            }
          : null;

      const team = teamData.teams.find(t => t.id === teamId);
      const positionId = positionName && team ? teamAndPositionToPositionId(team, positionName) : matchedRows[0].positionId;
      return {
        athleteId: matchedRows[0].id,
        athlete: {
          ...athlete,
          birthDate: athlete.birthDate ? format(new Date(athlete.birthDate), "yyyy-MM-dd") : null,
          secaUid: null,
          inbodyUid,
          positionId,
          sex,
          jerseyNumber: null,
          // Don't add or remove any on import
          removedTagIds: [],
          addedTagIds: [],
        },
        teamId,
        anthropometryEntry: metricAnthropometryEntry,
      };
    });
    const refetchQueries = [
      ...matches.flatMap(({ row: { teamId } }) => ({
        query: TeamRosterDocument,
        variables: { id: teamId },
      })),
      { query: TeamsPageDocument },
    ];
    editMetricAthletes({
      variables: {
        input,
      },
      update: onCacheUpdate,
      refetchQueries: [...refetchQueries, { query: AllAthletesDocument }, { query: AllAthletesAndAnthropometryEntriesDocument }],
    });
  };

  const validate: ValidateFn<Linkable, ImportableAthlete, LinkableTeam> = entities => {
    return entities.map(entity => {
      const result = ImportableAthleteSchema.safeParse(entity.row);

      if (!result.success) {
        result.error.errors.forEach(err => {
          delete entity.row[String(err.path) as keyof ImportableAthlete & keyof LinkableTeam];
        });
      }

      return {
        ...entity,
        error: result.success ? undefined : result.error,
      };
    });
  };

  if (orgData.myOrg.isNotemealLinked) {
    return <Typography>Cannot import athletes on an org that is Nutrition Linked</Typography>;
  }
  return (
    <ImportTabPanel
      groupKey="teamName"
      updateSelectedMatches={handleUpdateAthletes}
      insertSelectedMatches={handleInsertAthletes}
      matchableRows={existingAthletes}
      matchFields={matchFields} //["lastName", "firstName"]}
      entityName={"Athlete"}
      linkToEntityName={"Team"}
      linkableRows={existingTeams}
      linkFields={linkFields} //["teamId", "sex", "teamName"]}
      linkOnFields={linkOnFields} //["teamName"]}
      loadAndParse={loadAndParse} //loadTeamworksImportableAthletes}
      keyEntries={keyEntries}
      keyDisclaimer={keyDisclaimer}
      templateFile={templateFile}
      validate={validate}
    >
      <span>This imports {fileExtension} files with specific headers. Refer to the key on the right for the format.</span>
    </ImportTabPanel>
  );
};
