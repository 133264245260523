import { Box, Button, MenuItem, TextField, Theme, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AnthropometryEntrySnapshot, EditImperialMacroProtocolState, EditMetricMacroProtocolState, getDriKcal, getMetricDriKcal, Loading, useClientTimezone, useLocaleContext } from "@notemeal/shared-ui";
import {
  applyWeightTarget,
  athleteBirthDateToAge,
  macroGPerKgToGramsMacros,
  macroGPerKgToGramsMetricMacros,
  maybeAthleteBirthDateToAge,
} from "@notemeal/shared-utils-macro-protocol";
import { useEffect, useState } from "react";
import { AthleteTimelineQuery, MacroProgressViews, useAthleteRecentTimelineCalendarDatesWithActivityQuery } from "../../../types";
import { newMacroProtocolEditState, newMetricMacroProtocolEditMetricState } from "../../../utils/macroProtocol";
import { deserializeImperialMacroProtocolState, deserializeMetricMacroProtocolState } from "./AthleteFoodLogsUtils";
import CreateMealPlanFromFoodLogsModal from "./CreateMealPlanFromFoodLogsModal";
import DateColumn from "./DateColumn";
import { ImperialMacroProtocolEdit } from "./MacroProtocol/ImperialMacroProtocolEdit";
import { ImperialMacroProtocolView } from "./MacroProtocol/ImperialMacroProtocolView";
import { MetricMacroProtocolEdit } from "./MacroProtocol/MetricMacroProtocolEdit";
import { MetricMacroProtocolView } from "./MacroProtocol/MetricMacroProtocolView";
import { DriParamsContextProvider } from "./contexts/useDriParams";
import { FoodLogAthleteContextProvider } from "./contexts/useFoodLogAthlete";
import { MacroProgressViewsContextProvider } from "./contexts/useMacroProgressViews";
import { useCreateMealPlanModalHelper } from "./useCreateMealPlanModalHelper";
import { useTimelineForDate } from "./useTimelineForDate";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightXl: {
      flexGrow: 1,
      display: "grid",
      gridTemplateColumns: "0px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
      gridTemplateRows: "auto auto 1fr",
    },
    rightLg: {
      flexGrow: 1,
      display: "grid",
      gridTemplateColumns: "0px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
      gridTemplateRows: "auto auto 1fr",
    },
    rightMd: {
      flexGrow: 1,
      display: "grid",
      gridTemplateColumns: "0px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
      gridTemplateRows: "auto auto 1fr",
    },
    rightSm: {
      flexGrow: 1,
      display: "grid",
      gridTemplateColumns: "0px minmax(0, 1fr) minmax(0, 1fr)",
      gridTemplateRows: "auto auto 1fr",
    },
  })
);

interface AthleteFoodLogsProps {
  data: AthleteTimelineQuery;
}

export const AthleteFoodLogs = ({ data }: AthleteFoodLogsProps) => {
  const classes = useStyles();
  const clientTimezone = useClientTimezone();
  const { isMetricLocale } = useLocaleContext();

  const matchesXl = useMediaQuery("(min-width:1700px)");
  const matchesLg = useMediaQuery("(min-width:1400px)");
  const matchesMd = useMediaQuery("(min-width:1100px)");
  const rightClassName = matchesXl ? classes.rightXl : matchesLg ? classes.rightLg : matchesMd ? classes.rightMd : classes.rightSm;

  const [date1, setDate1] = useState<string | null>(null);
  const [date2, setDate2] = useState<string | null>(null);
  const [date3, setDate3] = useState<string | null>(null);
  const [date4, setDate4] = useState<string | null>(null);
  const [date5, setDate5] = useState<string | null>(null);
  const noDatesSelected = !date1 && !date2 && !date3 && !date4 && !date5;

  const { athlete, goalTypes, exchangeSets } = data;
  const { id: athleteId, birthDate, sex, team, currentGoal, mealPlans, mostRecentAnthropometryEntry } = athlete;

  const recentActivityDatesPayload = useAthleteRecentTimelineCalendarDatesWithActivityQuery({
    variables: { athleteId, timezone: clientTimezone },
  });

  useEffect(() => {
    const screenLimit = matchesXl ? 5 : matchesLg ? 4 : matchesMd ? 3 : 2;
    const _recentDates = recentActivityDatesPayload.data?.athlete.recentTimelineCalendarDatesWithActivity ?? [];
    // .slice(-1 * screenLimit) is to ensure that the most recent dates are shown by starting the slice from the end of the array
    const recentDates = _recentDates.slice(-1 * screenLimit);

    setDate1(recentDates[0] || null);
    setDate2(recentDates[1] || null);
    setDate3(recentDates[2] || null);
    setDate4(recentDates[3] || null);
    setDate5(recentDates[4] || null);
  }, [recentActivityDatesPayload.data?.athlete.recentTimelineCalendarDatesWithActivity, matchesMd, matchesLg, matchesXl]);

  // TODO: Foodlogs Update to use Athlete.timelineDate similar to useAthleteTimelineForDateQuery in apps/mobile/src/modes/Dietitian/Teams/Athlete/Timeline/Modal.tsx
  const date1Payload = useTimelineForDate({ athleteId, date: date1 });
  const date2Payload = useTimelineForDate({ athleteId, date: date2 });
  const date3Payload = useTimelineForDate({ athleteId, date: date3 });
  const date4Payload = useTimelineForDate({ athleteId, date: date4 });
  const date5Payload = useTimelineForDate({ athleteId, date: date5 });

  const ATHLETE_EDIT_IMPERIAL_MACRO_PROTOCOL_STATE_KEY = "AthleteEditImperialMacroProtocolState";
  const ATHLETE_EDIT_METRIC_MACRO_PROTOCOL_STATE_KEY = "AthleteEditMetricMacroProtocolState";

  const {
    loading: createMealPlanLoading,
    onChangeOpen: onChangeCreateMealPlanOpen,
    mealsAndActivities: mealsAndActivitiesForCreateMealPlan,
  } = useCreateMealPlanModalHelper([date1Payload, date2Payload, date3Payload, date4Payload, date5Payload]);

  const [macrosProgressViews, setMacrosProgressViews] = useState<MacroProgressViews>("all");

  const athleteAge = maybeAthleteBirthDateToAge(birthDate);
  const anthropometrySnapshot: AnthropometryEntrySnapshot | null = mostRecentAnthropometryEntry && {
    ...mostRecentAnthropometryEntry,
    ...applyWeightTarget(
      {
        ...mostRecentAnthropometryEntry,
        age: maybeAthleteBirthDateToAge(birthDate),
      },
      null
    ),
  };

  const initImperialMacroProtocolStateFunc = () => {
    const stateJson = localStorage.getItem(`${ATHLETE_EDIT_IMPERIAL_MACRO_PROTOCOL_STATE_KEY}:${athleteId}`);
    return deserializeImperialMacroProtocolState(athleteId, stateJson) || null;
  };

  const initMetricMacroProtocolStateFunc = () => {
    const stateJson = localStorage.getItem(`${ATHLETE_EDIT_METRIC_MACRO_PROTOCOL_STATE_KEY}:${athleteId}`);
    return deserializeMetricMacroProtocolState(athleteId, stateJson) || null;
  };

  const [imperialMacroProtocolState, _setImperialMacroProtocolState] = useState<EditImperialMacroProtocolState | null>(
    initImperialMacroProtocolStateFunc
  );
  const [metricMacroProtocolState, _setMetricMacroProtocolState] = useState<EditMetricMacroProtocolState | null>(
    initMetricMacroProtocolStateFunc
  );

  const setImperialMacroProtocolState = (state: EditImperialMacroProtocolState | null) => {
    localStorage.setItem(`${ATHLETE_EDIT_IMPERIAL_MACRO_PROTOCOL_STATE_KEY}:${athleteId}`, JSON.stringify(state));
    _setImperialMacroProtocolState(state);
  };

  const setMetricMacroProtocolState = (state: EditMetricMacroProtocolState | null) => {
    localStorage.setItem(`${ATHLETE_EDIT_METRIC_MACRO_PROTOCOL_STATE_KEY}:${athleteId}`, JSON.stringify(state));
    _setMetricMacroProtocolState(state);
  };

  const driKcal = isMetricLocale
    ? getMetricDriKcal(anthropometrySnapshot, metricMacroProtocolState)
    : getDriKcal(anthropometrySnapshot, imperialMacroProtocolState);

  const handleInitialSetExampleMacros = () => (isMetricLocale ? setEditMetricMacroProtocol(true) : setEditImperialMacroProtocol(true));

  const hasMetricMacroProtocol = isMetricLocale && !!metricMacroProtocolState;
  const hasImperialMacroProtocol = !isMetricLocale && !!imperialMacroProtocolState;
  const showSetInitialExampleMacrosButton = !hasMetricMacroProtocol && !hasImperialMacroProtocol;
  const canSetInitialExampleMacros = showSetInitialExampleMacrosButton && !!anthropometrySnapshot;
  const canConvertToMealPlan = !noDatesSelected && !createMealPlanLoading;

  const setInitialExampleMacrosTooltip = canSetInitialExampleMacros ? (
    ""
  ) : (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="body2Semibold">Cannot set example macros</Typography>
      <Typography variant="body2">Athlete has no anthropometry data</Typography>
    </Box>
  );

  const convertToMealPlanTooltip = noDatesSelected ? (
    <Typography variant="body2">Select at least one date with a food log</Typography>
  ) : createMealPlanLoading ? (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="body2">Loading timeline data for selected dates...</Typography>
      <Loading progressSize="xs" />
    </Box>
  ) : (
    ""
  );

  const [editMetricMacroProtocol, setEditMetricMacroProtocol] = useState(false);
  const [editImperialMacroProtocol, setEditImperialMacroProtocol] = useState(false);

  const getMetricMacroProtocolStateFromAnthro = (anthropometrySnapshot: AnthropometryEntrySnapshot) =>
    newMetricMacroProtocolEditMetricState(anthropometrySnapshot, athleteAge, goalTypes, currentGoal || undefined);
  const getImperialMacroProtocolStateFromAnthro = (anthropometrySnapshot: AnthropometryEntrySnapshot) =>
    newMacroProtocolEditState(anthropometrySnapshot, athleteAge, goalTypes, currentGoal || undefined);

  const editMetricMacroProtocolState =
    metricMacroProtocolState || (anthropometrySnapshot && getMetricMacroProtocolStateFromAnthro(anthropometrySnapshot));
  const editImperialMacroProtocolState =
    imperialMacroProtocolState || (anthropometrySnapshot && getImperialMacroProtocolStateFromAnthro(anthropometrySnapshot));

  let targetDailyMacros = null;
  if (!isMetricLocale && editImperialMacroProtocolState && anthropometrySnapshot) {
    const { cho, pro, fat } = editImperialMacroProtocolState;
    const { weight } = anthropometrySnapshot;
    targetDailyMacros = macroGPerKgToGramsMacros(cho, pro, fat, weight);
  } else if (isMetricLocale && editMetricMacroProtocolState && anthropometrySnapshot) {
    const { cho, pro, fat } = editMetricMacroProtocolState;
    const { weightInKg } = anthropometrySnapshot;
    targetDailyMacros = macroGPerKgToGramsMetricMacros(cho, pro, fat, weightInKg);
  }

  const baseDateColumnProps = { athleteId, athleteBirthdate: birthDate, athleteMealPlans: mealPlans, targetDailyMacros };
  const showMetricExampleDailyMacros = macrosProgressViews === "all" && hasMetricMacroProtocol;
  const showImperialExampleDailyMacros = macrosProgressViews === "all" && hasImperialMacroProtocol;

  return (
    <MacroProgressViewsContextProvider macrosProgressViews={macrosProgressViews}>
      <DriParamsContextProvider
        age={birthDate ? athleteBirthDateToAge(birthDate) : null}
        sex={sex}
        kcal={driKcal}>
        <FoodLogAthleteContextProvider athlete={athlete}>
          <Box sx={{ height: "100%", overflow: "hidden", display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
              <Box sx={{ display: "flex", gap: 2 }}>
                {showMetricExampleDailyMacros && (
                  <MetricMacroProtocolView macroProtocol={metricMacroProtocolState} onEdit={handleInitialSetExampleMacros} />
                )}
                {showImperialExampleDailyMacros && (
                  <ImperialMacroProtocolView macroProtocol={imperialMacroProtocolState} onEdit={handleInitialSetExampleMacros} />
                )}
                <TextField
                  sx={{ width: 230 }}
                  select
                  value={macrosProgressViews}
                  onChange={e => setMacrosProgressViews(e.target.value as MacroProgressViews)}
                  label="Food Log View Settings"
                >
                  <MenuItem value="all">Show all progress views</MenuItem>
                  <MenuItem value="hideNumbers">Hide gram values</MenuItem>
                  <MenuItem value="none">Hide all progress views</MenuItem>
                </TextField>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                {showSetInitialExampleMacrosButton && (
                  <Tooltip title={setInitialExampleMacrosTooltip}>
                    <span>
                      <Button
                        variant="outlined"
                        disabled={!canSetInitialExampleMacros}
                        onClick={handleInitialSetExampleMacros}>
                        Set Example Macros
                      </Button>
                    </span>
                  </Tooltip>
                )}
                <Tooltip title={convertToMealPlanTooltip}>
                  <span>
                    <Button disabled={!canConvertToMealPlan} onClick={() => onChangeCreateMealPlanOpen(true)}>
                      Convert to Meal Plan
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ overflowY: "auto", display: "flex", gap: 2, boxSizing: "border-box" }}>
              <div className={rightClassName}>
                <DateColumn
                  {...baseDateColumnProps}
                  gridColumn={1}
                  date={date1}
                  datePayload={date1Payload}
                  onChangeDate={setDate1} />
                <DateColumn
                  {...baseDateColumnProps}
                  gridColumn={2}
                  date={date2}
                  datePayload={date2Payload}
                  onChangeDate={setDate2} />
                {matchesMd && (
                  <DateColumn
                    {...baseDateColumnProps}
                    gridColumn={3}
                    date={date3}
                    datePayload={date3Payload}
                    onChangeDate={setDate3} />
                )}
                {matchesLg && (
                  <DateColumn
                    {...baseDateColumnProps}
                    gridColumn={4}
                    date={date4}
                    datePayload={date4Payload}
                    onChangeDate={setDate4} />
                )}
                {matchesXl && (
                  <DateColumn
                    {...baseDateColumnProps}
                    gridColumn={5}
                    date={date5}
                    datePayload={date5Payload}
                    onChangeDate={setDate5} />
                )}
              </div>
            </Box>
          </Box>
          {editMetricMacroProtocol && editMetricMacroProtocolState && (
            <MetricMacroProtocolEdit
              initialState={editMetricMacroProtocolState}
              open={editMetricMacroProtocol}
              onClose={() => setEditMetricMacroProtocol(false)}
              onSave={setMetricMacroProtocolState}
              goalTypes={goalTypes}
            />
          )}
          {editImperialMacroProtocol && editImperialMacroProtocolState && (
            <ImperialMacroProtocolEdit
              initialState={editImperialMacroProtocolState}
              open={editImperialMacroProtocol}
              onClose={() => setEditImperialMacroProtocol(false)}
              onSave={setImperialMacroProtocolState}
              goalTypes={goalTypes}
            />
          )}
          {mealsAndActivitiesForCreateMealPlan && mostRecentAnthropometryEntry && (
            <CreateMealPlanFromFoodLogsModal
              open={mealsAndActivitiesForCreateMealPlan !== null}
              onClose={() => onChangeCreateMealPlanOpen(false)}
              athleteId={athleteId}
              currentGoal={currentGoal}
              mostRecentAnthropometryEntry={mostRecentAnthropometryEntry}
              athleteBirthdate={birthDate}
              exchangeSets={exchangeSets}
              goalTypes={goalTypes}
              sportName={team.sport ? team.sport.name : ""}
              timelineMeals={mealsAndActivitiesForCreateMealPlan.meals}
              foodLogActivities={mealsAndActivitiesForCreateMealPlan.activities}
            />
          )}
        </FoodLogAthleteContextProvider>
      </DriParamsContextProvider>
    </MacroProgressViewsContextProvider>
  );
};
