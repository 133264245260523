import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import { useState } from "react";
import { BulkImportFoodFragment } from "../../../../types";
import { Mode } from "../../../Food/FoodFormSchema/utils";
import { IFileImport } from "../../../universal/Import/Button";
import ImportInfoDialog, { FieldDefinition } from "../InfoDialog";
import ImportTabPanel from "../TabPanel";
import { ICrudHandler } from "../types";
import { ImportableFoodEntry } from "./types";
import { AdditionalFoodInfo, getImportableExistingFoodsFromFoods } from "./utils";

export interface ImportFoodProps {
  loadAndParse: ({ file, onError }: IFileImport) => Promise<ImportableFoodEntry[] | null>;
  matchFields: (keyof ImportableFoodEntry)[];
  fields: FieldDefinition[];
  description: string;
  useFoodsQuery: () => {
    foods: readonly BulkImportFoodFragment[] | undefined;
    loading: boolean;
  };
  mode: Mode;
  handleInsertFoods: (obj: ICrudHandler<ImportableFoodEntry, AdditionalFoodInfo>) => void;
  handleUpdateFoods: (
    obj: ICrudHandler<ImportableFoodEntry, AdditionalFoodInfo> & {
      foods: readonly BulkImportFoodFragment[] | undefined;
    }
  ) => void;
}

const BaseFoodImportTabPanel = ({
  loadAndParse,
  matchFields,
  fields,
  description,
  useFoodsQuery,
  mode,
  handleInsertFoods,
  handleUpdateFoods: _handleUpdateFoods,
}: ImportFoodProps) => {
  const { foods, loading } = useFoodsQuery();
  const [infoOpen, setInfoOpen] = useState(false);

  const existingFoods = getImportableExistingFoodsFromFoods(foods);

  const handleUpdateFoods = ({ matches, onCacheUpdate }: ICrudHandler<ImportableFoodEntry, AdditionalFoodInfo>) =>
    _handleUpdateFoods({ matches, onCacheUpdate, foods });

  return loading ? (
    <Loading />
  ) : (
    <ImportTabPanel<{}, ImportableFoodEntry, {}, AdditionalFoodInfo>
      linkableRows={[]}
      linkFields={[]}
      linkOnFields={[]}
      groupKey={null}
      linkToEntityName="Food"
      updateSelectedMatches={handleUpdateFoods}
      insertSelectedMatches={handleInsertFoods}
      matchableRows={existingFoods || []}
      matchFields={matchFields}
      entityName={"Food"}
      loadAndParse={loadAndParse}
      disableManualMatch={true}
      allowShowImportRow={true}
    >
      <span>
        {description}
        <IconButton
          sx={{ color: "common.black" }}
          onClick={() => setInfoOpen(true)}
          aria-label="More info"
          size="small">
          <InfoIcon fontSize="small" />
        </IconButton>
      </span>
      <ImportInfoDialog
        open={infoOpen}
        setOpen={setInfoOpen}
        fields={fields} />
    </ImportTabPanel>
  );
};

export default BaseFoodImportTabPanel;
