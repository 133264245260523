import { Dialog, DialogContent, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useDebounce } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
    DeliveryLocationMenuLinkFormFragment,
    RestaurantLocationSearchResultFragment,
    RestaurantMenuSearchResultFragment,
    useRestaurantLocationSearchQuery,
    useRestaurantMenuSearchQuery,
} from "../../../types";
import InfiniteScrollContainer from "../../universal/InfiniteScroll/InfiniteScrollContainer";
import useInfiniteCursorConnectionScroll from "../../universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import LoadingBackdrop from "../../universal/LoadingBackdrop";
import FilterContainer from "./FilterContainer";
import LocationResultItem from "./LocationResultItem";
import MenuResultItem from "./MenuResultItem";
import {
    buildInitialState,
    getLocationSearchVariables,
    getMenuSearchVariables,
    getRestaurantSearchMode,
    restaurantSearchReducer,
} from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      height: `calc(100% - 64px)`,
    },
    flexContainer: {
      display: "flex",
      height: "100%",
    },
    facetsContainer: {
      width: `calc(22% - calc(${theme.spacing(3)} + 1px))`,
      paddingRight: theme.spacing(3),
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      height: "100%",
      overflow: "auto",
    },
    searchContentContainer: {
      width: `calc(78% - ${theme.spacing(3)})`,
      marginLeft: theme.spacing(3),
      height: "100%",
      overflow: "auto",
    },
    centeredTextDiv: {
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    autoCompletes: {
      marginTop: theme.spacing(4),
    },
  })
);

interface RestaurantSearchDialogProps {
  open: boolean;
  onClose: () => void;
  onSelectLocation: (result: RestaurantLocationSearchResultFragment, deliveryLocation: DeliveryLocationMenuLinkFormFragment) => void;
  onSelectMenu: (result: RestaurantMenuSearchResultFragment) => void;
  saving: boolean;
  usedRestaurantIds: string[];
  deliveryLocation?: DeliveryLocationMenuLinkFormFragment;
}

const RestaurantSearchDialog = ({
  open,
  onClose,
  onSelectLocation,
  onSelectMenu,
  saving,
  usedRestaurantIds,
  deliveryLocation,
}: RestaurantSearchDialogProps) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(restaurantSearchReducer, buildInitialState(deliveryLocation));
  const searchMode = getRestaurantSearchMode(state);

  const [deliveryLocationTipForceOpen, setDeliveryLocationTipForceOpen] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setDeliveryLocationTipForceOpen(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const locationSearchVariables = useMemo(() => {
    return getLocationSearchVariables(
      {
        teamLabel: state.teamLabel,
        deliveryLocation: state.deliveryLocation,
        cuisines: state.cuisines,
        diets: state.diets,
        restaurantTypes: state.restaurantTypes,
        paymentMethods: state.paymentMethods,
        query: state.query,
        restaurants: state.restaurants,
      },
      null
    );
  }, [
    state.teamLabel,
    state.deliveryLocation,
    state.cuisines,
    state.diets,
    state.restaurantTypes,
    state.paymentMethods,
    state.query,
    state.restaurants,
  ]);
  const debouncedLocationSearchVariables = useDebounce(locationSearchVariables, 200);

  const menuSearchVariables = useMemo(() => {
    return getMenuSearchVariables(
      {
        teamLabel: state.teamLabel,
        deliveryLocation: state.deliveryLocation,
        cuisines: state.cuisines,
        diets: state.diets,
        restaurantTypes: state.restaurantTypes,
        paymentMethods: state.paymentMethods,
        query: state.query,
        restaurants: state.restaurants,
      },
      null
    );
  }, [
    state.teamLabel,
    state.deliveryLocation,
    state.cuisines,
    state.diets,
    state.restaurantTypes,
    state.paymentMethods,
    state.query,
    state.restaurants,
  ]);
  const debouncedMenuSearchVariables = useDebounce(menuSearchVariables, 200);

  const locationResults = useInfiniteCursorConnectionScroll({
    useCursorConnectionQuery: useRestaurantLocationSearchQuery,
    getQueryVariablesFromPagination: useCallback(
      ({ cursor, limit }) =>
        debouncedLocationSearchVariables
          ? {
              variables: {
                ...debouncedLocationSearchVariables,
                pagination: {
                  cursor,
                  limit,
                },
              },
              skip: false,
            }
          : {
              variables: undefined,
              skip: true,
            },
      [debouncedLocationSearchVariables]
    ),
    queryKey: "restaurantLocationSearch",
    edgesAreEqual: useCallback((edge1: RestaurantLocationSearchResultFragment, edge2: RestaurantLocationSearchResultFragment) => {
      return edge1.restaurantLocation.id === edge2.restaurantLocation.id;
    }, []),
  });

  const menuResults = useInfiniteCursorConnectionScroll({
    useCursorConnectionQuery: useRestaurantMenuSearchQuery,
    getQueryVariablesFromPagination: useCallback(
      ({ cursor, limit }) =>
        debouncedMenuSearchVariables
          ? {
              variables: {
                ...debouncedMenuSearchVariables,
                pagination: {
                  cursor,
                  limit,
                },
              },
              skip: searchMode !== "Menu",
            }
          : {
              variables: undefined,
              skip: true,
            },
      [debouncedMenuSearchVariables, searchMode]
    ),
    queryKey: "restaurantMenuSearch",
    edgesAreEqual: useCallback((edge1: RestaurantMenuSearchResultFragment, edge2: RestaurantMenuSearchResultFragment) => {
      return edge1.restaurantMenu.id === edge2.restaurantMenu.id;
    }, []),
  });

  const noItemsComponent = (
    <div className={classes.centeredTextDiv}>
      <Typography variant="h3">
        No results found!
        <br />
        <br />
        Try using fewer filters to see more results
      </Typography>
    </div>
  );

  return saving ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle title="Restaurant Search" onClose={onClose} />
      <DialogContent>
        <div className={classes.flexContainer}>
          <div className={classes.facetsContainer}>
            <FilterContainer
              state={state}
              dispatch={dispatch}
              deliveryLocationTipForceOpen={deliveryLocationTipForceOpen} />
          </div>
          <div className={classes.searchContentContainer}>
            {deliveryLocationTipForceOpen ? null : searchMode === "Location" ? (
              <InfiniteScrollContainer
                infiniteScrollResults={locationResults}
                renderItem={(edge, forwardRef) => (
                  <LocationResultItem
                    key={edge.restaurantLocation.id}
                    forwardRef={forwardRef}
                    result={edge}
                    disabled={usedRestaurantIds.includes(edge.restaurant.id)}
                    onClick={() => {
                      if (state.deliveryLocation) {
                        onSelectLocation(edge, state.deliveryLocation);
                      }
                    }}
                  />
                )}
                noItemsComponent={noItemsComponent}
              />
            ) : searchMode === "Menu" ? (
              <InfiniteScrollContainer
                infiniteScrollResults={menuResults}
                renderItem={(edge, forwardRef) => (
                  <MenuResultItem
                    key={edge.restaurantMenu.id}
                    forwardRef={forwardRef}
                    result={edge}
                    disabled={usedRestaurantIds.includes(edge.restaurant.id)}
                    onClick={() => onSelectMenu(edge)}
                  />
                )}
                noItemsComponent={noItemsComponent}
              />
            ) : (
              <div className={classes.centeredTextDiv}>
                <Typography variant="h3">
                  Select a Delivery Location to see nearby Restaurant Locations
                  <br />
                  <br />
                  <b>OR</b>
                  <br />
                  <br />
                  Clear the selected Team to search all Restaurants
                </Typography>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RestaurantSearchDialog;
