import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Fab, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortExchanges } from "@notemeal/shared-ui";
import { memo, useState } from "react";
import { animated, useSpring } from "react-spring";
import { FullExchangeSetFragment } from "../../../../../types";
import { ExchangeTargetsById } from "../../../../MealPlan/Edit/Exchange/utils";
import { ExchangeAmountCard } from "./ExchangeAmountCard";
import { SelectedExchangeAmountCard } from "./SelectedExchangeAmountCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: "40px auto 40px",
      gridTemplateRows: "auto 40px auto",
      minWidth: 0,
      flexGrow: 1,
    },
    container: {
      gridRowStart: 1,
      gridRowEnd: 4,
      gridColumnStart: 1,
      gridColumnEnd: 4,
      overflowX: "hidden",
      display: "flex",
    },
    content: {
      display: "flex",
      alignItems: "stretch",
      height: 300,
    },
    contentExpanded: {
      display: "flex",
      alignItems: "stretch",
      height: 500,
    },
    leftButton: {
      gridRowStart: 2,
      gridColumnStart: 1,
      marginLeft: theme.spacing(-1.5),
    },
    rightButton: {
      gridRowStart: 2,
      gridColumnStart: 3,
      marginLeft: theme.spacing(1.5),
    },
  })
);

interface ExpandedExchangeAmountsProps {
  mealTemplateId: string;
  exchangeTargetsById: ExchangeTargetsById;
  exchangeSet: FullExchangeSetFragment;
}

export const ExpandedExchangeAmounts = memo(({ mealTemplateId, exchangeTargetsById, exchangeSet }: ExpandedExchangeAmountsProps) => {
  const classes = useStyles();
  const { exchanges } = exchangeSet;
  const sortedExchanges = sortExchanges(exchanges);
  const [selectedExchangeId, setSelectedExchangeId] = useState<string | null>(null);

  const [contentWidth, setContentWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [scrollPosition, setScrollPosition] = useState<"left" | "right">("left");

  const scrollOffset = Math.min(0, containerWidth - contentWidth);
  const showLeftButton = Boolean(scrollOffset && scrollPosition === "right");
  const showRightButton = Boolean(scrollOffset && scrollPosition === "left");

  const { x } = useSpring({ x: scrollPosition === "left" ? 0 : scrollOffset });

  return (
    <div className={classes.root}>
      <div className={classes.container} ref={ref => ref && setContainerWidth(ref.offsetWidth)}>
        <animated.div
          className={selectedExchangeId === null ? classes.content : classes.contentExpanded}
          ref={ref => ref && setContentWidth(ref.offsetWidth)}
          style={{ transform: x.to(x => `translateX(${x}px)`) }}
        >
          {sortedExchanges.map(({ id, exchangeServingList }) =>
            id === selectedExchangeId ? (
              <SelectedExchangeAmountCard
                key={id}
                mealTemplateId={mealTemplateId}
                exchangeAmount={exchangeTargetsById[id]}
                servingList={exchangeServingList}
              />
            ) : (
              <ExchangeAmountCard
                key={id}
                mealTemplateId={mealTemplateId}
                exchangeAmount={exchangeTargetsById[id]}
                onSelect={id => setSelectedExchangeId(id === selectedExchangeId ? null : id)}
                servingList={exchangeServingList}
              />
            )
          )}
        </animated.div>
      </div>
      {showLeftButton && (
        <Fab
          className={classes.leftButton}
          size="small"
          onClick={() => setScrollPosition("left")}>
          <NavigateBeforeIcon />
        </Fab>
      )}
      {showRightButton && (
        <Fab
          className={classes.rightButton}
          size="small"
          onClick={() => setScrollPosition("right")}>
          <NavigateNextIcon />
        </Fab>
      )}
    </div>
  );
});
