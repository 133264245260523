import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading, useDateFormatting } from "@notemeal/shared-ui";
import { useOrderPageMealMenuQuery } from "../../types";
import PageContent from "./PageContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
    },
    backButton: {
      marginRight: theme.spacing(),
      marginLeft: theme.spacing(2),
      alignSelf: "center",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    headerTitle: {
      padding: theme.spacing(2),
    },
    titleLoading: {
      display: "inline-block",
    },
  })
);
interface OrdersPageProps {
  open: boolean;
  onClose: () => void;
  mealMenuId: string;
  mealMenuStart: string;
}

export const ViewMenuOrdersDialog = ({ open, onClose, mealMenuId, mealMenuStart }: OrdersPageProps) => {
  const classes = useStyles();
  const { formatDateWithLocale } = useDateFormatting();

  const { data, loading } = useOrderPageMealMenuQuery({
    variables: {
      mealMenuId,
    },
    fetchPolicy: "network-only",
  });

  const menuName = data?.mealMenu.name ?? <Loading classes={{ root: classes.titleLoading }} progressSize="xs" />;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen>
      <DialogTitle>
        <div className={classes.header}>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h4"
            color="textSecondary"
            className={classes.headerTitle}>
            {menuName} - {formatDateWithLocale(mealMenuStart)}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          {data && !loading ? <PageContent mealMenu={data.mealMenu} start={mealMenuStart} /> : <Loading progressSize="lg" />}
        </div>
      </DialogContent>
    </Dialog>
  );
};
