import { MenuItem, SxProps, TextField, TextFieldProps } from "@mui/material";
import { sortByKey } from "@notemeal/utils-sort";
import React from "react";
import { PositionFragment } from "../../../types";

export interface MenusTeamMultiSelectProps {
  onChange: (selectedPositions: readonly PositionFragment[]) => void;
  allPositions: readonly PositionFragment[];
  selectedPositions: readonly PositionFragment[];
  className?: string;
  sx?: SxProps;
  disabled?: boolean;
  fullWidth?: boolean;
  displayedPositionsCount?: number;
  props?: Partial<TextFieldProps>;
}

export const PositionsMultiSelect = ({
  selectedPositions,
  onChange,
  allPositions,
  className,
  sx,
  disabled,
  fullWidth,
  displayedPositionsCount,
  props,
}: MenusTeamMultiSelectProps) => {
  const handleChange = (positionIds: string[]) => {
    onChange(allPositions.filter(p => positionIds.includes(p.id)));
  };

  const selectedPositionIds = selectedPositions.map(p => p.id);

  return (
    <TextField
      select
      SelectProps={{
        multiple: true,
        renderValue: value => {
          const positionIds = value as string[];
          const displayPositionIds = positionIds.slice(0, displayedPositionsCount || 3);
          const displayTeamNames = displayPositionIds.map(tId => {
            const matchingTeam = allPositions.find(t => t.id === tId);
            return matchingTeam ? matchingTeam.name : "";
          });
          const hiddenPositionCount = positionIds.slice(displayedPositionsCount || 3).length;
          return (
            <div>
              {displayTeamNames.join(", ")} {hiddenPositionCount > 0 ? `(+${hiddenPositionCount})` : ""}
            </div>
          );
        },
      }}
      label="Positions"
      inputProps={{
        "aria-label": "Positions",
      }}
      value={selectedPositions.map(p => p.id)}
      className={className}
      sx={sx}
      disabled={disabled}
      fullWidth={fullWidth}
      onChange={e => handleChange(e.target.value as unknown as string[])}
      {...props}
    >
      {sortByKey(allPositions, "name").map(p => (
        <MenuItem
          key={p.id}
          value={p.id}
          sx={{
            fontWeight: selectedPositionIds.includes(p.id) ? "bold" : "normal",
          }}
        >
          {p.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
