import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useHasFeature } from "@notemeal/shared-ui";
import { useState } from "react";
import { OrderPageMealMenuFragment } from "../../types";
import { ViewOnlyMenuAvatars } from "../Menus/Dialog/MenuAvatars";
import { getInitialSelectedRestaurantMenuLinkId } from "../Menus/RestaurantMenu/utils";
import Content from "./Content";
import RestaurantOrdersPage from "./RestaurantOrders";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      position: "relative",
      padding: 0,
    },
    innerContent: {
      padding: `0 12%`,
      overflowY: "auto",
      height: "100%",
    },
    avatars: {
      position: "absolute",
      top: 0,
      left: 0,
    },
  })
);
interface OrdersPageContentProps {
  mealMenu: OrderPageMealMenuFragment;
  start: string;
}

const OrdersPageContent = ({ start, mealMenu }: OrdersPageContentProps) => {
  const classes = useStyles();
  const hasRestaurantFeatureMenus = useHasFeature("hasRestaurantMenus");

  const initialSelectedRMLId = getInitialSelectedRestaurantMenuLinkId(mealMenu.restaurantMenuLinks, mealMenu.mealMenuDiningStations);

  const [selectedRestaurantMenuLinkId, setSelectedRestaurantMenuLinkId] = useState<string | null>(initialSelectedRMLId);

  const selectedRestaurantMenuLink = mealMenu.restaurantMenuLinks.find(link => link.id === selectedRestaurantMenuLinkId) ?? null;
  return (
    <div className={classes.content}>
      {hasRestaurantFeatureMenus && (
        <ViewOnlyMenuAvatars
          selectedRestaurantMenuLinkId={selectedRestaurantMenuLinkId}
          onSelectRestaurantMenuLink={newId => setSelectedRestaurantMenuLinkId(newId)}
          hasDiningStations={mealMenu.mealMenuDiningStations.length > 0}
          restaurantMenuLinks={mealMenu.restaurantMenuLinks}
          className={classes.avatars}
        />
      )}
      <div className={classes.innerContent}>
        {selectedRestaurantMenuLink === null ? (
          <Content
            allOrders={mealMenu.allOrders}
            mealMenuDiningStations={mealMenu.mealMenuDiningStations.map(ds => ({
              ...ds,
              mealMenuId: mealMenu.id,
            }))}
          />
        ) : (
          <RestaurantOrdersPage
            restaurantMenuLinkPreview={selectedRestaurantMenuLink}
            start={start}
            mealMenuName={mealMenu.name} />
        )}
      </div>
    </div>
  );
};

export default OrdersPageContent;
