import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { CarbNutrientGroup } from "@notemeal/shared-ui";
import NutrientTreeItem from "./NutrientTreeItem";
import { CloseSquare, MinusSquare, PlusSquare } from "./StyledTreeItem";
import { generateTreeStyles } from "./styles";

const useStyles = makeStyles((theme: Theme) => createStyles(generateTreeStyles(theme.palette.accents.greenLight[50], theme)));

export interface CarbohydrateTreeProps {
  carbs: CarbNutrientGroup;
  displayDri: boolean;
}

const CarbohydrateTree = ({ carbs, displayDri }: CarbohydrateTreeProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h3">
        Carbohydrates
      </Typography>
      <div className={classes.content}>
        <TreeView
          className={classes.tree}
          defaultExpanded={["1"]}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
        >
          <NutrientTreeItem
            isRoot
            nodeId="1"
            nutrientAmount={carbs["Total Carbs"]}
            displayDri={displayDri}>
            <NutrientTreeItem
              nodeId="2"
              nutrientAmount={carbs["Fiber"]}
              displayDri={displayDri} />
            <NutrientTreeItem
              nodeId="3"
              nutrientAmount={carbs["Starch"]}
              displayDri={displayDri} />
            <NutrientTreeItem
              nodeId="4"
              nutrientAmount={carbs["Total Sugars"]}
              displayDri={displayDri} />
          </NutrientTreeItem>
        </TreeView>
      </div>
    </div>
  );
};

export default CarbohydrateTree;
