import { parseDate, serializeDate } from "@notemeal/utils-date-time";
import { addDays, startOfMonth, startOfWeek } from "date-fns";
import React, { ReactNode, createContext, useContext, useState } from "react";

type IMealPlanCalendarContext = {
  startOfWeekDate: Date;
  startOfNextWeek: string;
  start: string;
  end: string;
  onChangeDate: (date: string) => void;
  onForwardsWeek: () => void;
  onBackwardsWeek: () => void;
  startOfMonthDate: Date;
  onChangeStartOfMonthDate: (date: Date) => void;
};

const MealPlanCalendarContext = createContext<IMealPlanCalendarContext>({
  startOfMonthDate: new Date(),
  onChangeStartOfMonthDate: () => {},
  startOfWeekDate: new Date(),
  startOfNextWeek: "",
  start: "",
  end: "",
  onChangeDate: () => {},
  onBackwardsWeek: () => {},
  onForwardsWeek: () => {},
});

interface MealPlanCalendarContextProviderProps {
  children: ReactNode;
}

export const MealPlanCalendarContextProvider = ({ children }: MealPlanCalendarContextProviderProps) => {
  // Week and month dates are independent, separate views on screen at same time
  const [startOfMonthDate, setStartOfMonthDate] = useState(startOfMonth(new Date()));

  const [startOfWeekDate, setStartOfWeekDate] = useState(startOfWeek(new Date()));
  const start = serializeDate(startOfWeekDate);
  const end = serializeDate(addDays(startOfWeekDate, 6));
  const startOfNextWeek = serializeDate(addDays(parseDate(end), 1));

  // TODO: Should this change both startOfWeekDate and startOfMonthDate?
  const onChangeDate = (date: string) => {
    setStartOfWeekDate(startOfWeek(parseDate(date)));
  };

  const onForwardsWeek = () => setStartOfWeekDate(d => addDays(d, 7));

  const onBackwardsWeek = () => setStartOfWeekDate(d => addDays(d, -7));

  return (
    <MealPlanCalendarContext.Provider
      value={{
        startOfWeekDate,
        startOfNextWeek,
        start,
        end,
        onChangeDate,
        onBackwardsWeek,
        onForwardsWeek,
        startOfMonthDate,
        onChangeStartOfMonthDate: setStartOfMonthDate,
      }}
    >
      {children}
    </MealPlanCalendarContext.Provider>
  );
};

export const useMealPlanCalendarContext = () => useContext(MealPlanCalendarContext);
