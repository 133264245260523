import { ApolloQueryResult } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import SomethingWentWrongDialog from "apps/web/src/componentLibrary/ConfirmationDialog/SomethingWentWrongDialog";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import LoadingButton from "apps/web/src/components/universal/LoadingButton";
import { MealMenusInDateRangeQuery, useImportPlannedMenuMutation } from "apps/web/src/types";
import { useState } from "react";
import { useImportPlannedMenu } from "./ImportPlannedMenuContext";
import { ImportPlannedMenuFormHeader } from "./ImportPlannedMenuFormHeader";
import { ImportPlannedMenuFormMeals } from "./ImportPlannedMenuFormMeals";
import { buildImportPlannedMenuInput } from "./utils";

interface ImportPlannedMenuDialogProps {
  refetch: () => Promise<ApolloQueryResult<MealMenusInDateRangeQuery>>;
}

export const ImportPlannedMenuDialog = ({ refetch }: ImportPlannedMenuDialogProps) => {
  const { onClose, onSuccess, plannedMenu, plannedMenuWeeksWithMeals, mealsLoading, form, selectedWeeks, shareState } =
    useImportPlannedMenu();

  const [errorWhileSavingDialog, setErrorWhileSavingDialog] = useState(false);

  const [importPlannedMenu, { loading }] = useImportPlannedMenuMutation({
    onError: e => {
      console.error(e);
      setErrorWhileSavingDialog(true);
    },
    onCompleted: () => {
      refetch();
      if (plannedMenu) {
        datadogRum.addAction("menu_builder.table.published_planned_menu", { menuId: plannedMenu.id });
        onSuccess(plannedMenu.name);
      }
      onClose();
    },
  });

  const handleImport = () => {
    if (plannedMenu && selectedWeeks) {
      const input = buildImportPlannedMenuInput(form.getValues(), plannedMenuWeeksWithMeals, plannedMenu, shareState);
      importPlannedMenu({ variables: { input } });
    }
  };

  return (
    <>
      <Dialog
        open={true}
        maxWidth="lg"
        sx={{ minWidth: "1200px" }}
        fullWidth>
        <DialogTitle onClose={onClose} title="Publish Menu" />
        <DialogContent sx={{ pt: 0 }}>
          {plannedMenu ? (
            <>
              <ImportPlannedMenuFormHeader plannedMenu={plannedMenu} />
              {plannedMenuWeeksWithMeals.length > 0 && !mealsLoading ? (
                <ImportPlannedMenuFormMeals />
              ) : (
                <Box sx={{ height: `calc(100vh - 280px)` }} />
              )}
            </>
          ) : (
            <Loading />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            buttonText="Publish"
            onClick={handleImport}
            loading={loading}
            disabled={plannedMenuWeeksWithMeals.length < 1}
          ></LoadingButton>
        </DialogActions>
      </Dialog>
      {errorWhileSavingDialog && (
        <SomethingWentWrongDialog
          open={errorWhileSavingDialog}
          onClose={() => setErrorWhileSavingDialog(false)}
          content={"An unknown error occurred. Check your network connection or try again in a few minutes."}
        />
      )}
    </>
  );
};
