import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { capitalize, useClientTimezone } from "@notemeal/shared-ui";
import { dateAndTimeToIsoInTz, safeSerializeTime } from "@notemeal/utils-date-time";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { addMinutes, differenceInMinutes } from "date-fns";
import { useState } from "react";
import TWItemizedTooltip from "../../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import MealTypeSelect from "../../../../../components/Meal/TypeSelect";
import { AddTimelineMealInput, MealType } from "../../../../../types";

interface TimelineMealAddModalProps {
  open: boolean;
  onClose: () => void;
  date: string;
  onAdd: (meal: Omit<AddTimelineMealInput, "mealTemplateId">) => void;
}

const TimelineMealAddModal = ({ open, onClose, onAdd, date }: TimelineMealAddModalProps) => {
  const [type, setType] = useState<MealType | null>(null);
  const [startDatetime, setStartDatetime] = useState<Date | null>(null);
  const [endDatetime, setEndDatetime] = useState<Date | null>(null);
  const startTime = safeSerializeTime(startDatetime);
  const endTime = safeSerializeTime(endDatetime);
  const clientTimezone = useClientTimezone();

  let tooltips = [];
  if (!type) {
    tooltips.push("Select meal type");
  }
  if (!startTime) {
    tooltips.push("Select start time");
  }
  if (!endTime) {
    tooltips.push("Select end time");
  }

  const handleSetStart = (datetime: Date | null) => {
    setStartDatetime(datetime);
    if (datetime && !endDatetime) {
      setEndDatetime(addMinutes(datetime, 30));
    }
  };

  const handleAdd = () => {
    if (tooltips.length > 0 || !type || !startTime || !endTime) {
      return;
    }

    const start = new Date(dateAndTimeToIsoInTz({ date, time: startTime }, clientTimezone));
    const end = new Date(dateAndTimeToIsoInTz({ date, time: endTime }, clientTimezone));
    const durationInMinutes = differenceInMinutes(end, start);

    onAdd({
      name: capitalize(type),
      type,
      start: start.toISOString(),
      durationInMinutes,
      timezone: clientTimezone,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title="Add Meal" onClose={onClose} />
      <DialogContent sx={{ width: 300, display: "flex", flexDirection: "column", alignContent: "stretch" }}>
        <MealTypeSelect value={type} onChange={setType} />
        <DesktopTimePicker
          sx={{ my: 3 }}
          label="Start"
          value={startDatetime}
          onChange={handleSetStart} />
        <DesktopTimePicker
          label="End"
          value={endDatetime}
          onChange={datetime => setEndDatetime(datetime)} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={tooltips}>
          <Button onClick={handleAdd}>Add</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default TimelineMealAddModal;
