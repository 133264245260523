import { TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { addWeightToAmbiguousServingString, round } from "@notemeal/shared-ui";
import React from "react";
import { FullServingFragment } from "../../../types";
import { isErrantServingAmount } from "./utils";

interface ServingAmountEditAmountProps {
  onSetAmount: (amount: number) => void;
  amount: number;
  serving: FullServingFragment;
  onClick: (event: React.MouseEvent<any>) => void;
  servingsMenuDisabled?: boolean;
  ariaLabel?: string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    servingSize: {
      minWidth: "40px",
      maxWidth: "40px",
    },
    errantServingSizeInput: {
      color: "red",
      height: "18px",
    },
    flex: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-start",
    },
    servingSizeInput: {
      backgroundColor: "inherit",
      height: "17px",
      fontSize: 15,
    },
    link: {
      "&:hover": {
        textDecoration: "underline",
        color: theme.palette.info.light,
      },
    },
    hiddenNumberButtons: {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "-moz-appearance": "textfield",
    },
    servingNameWrapper: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      minWidth: 36,
    },
    brandName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
    },
    brandNameWrapper: {
      width: theme.spacing(24),
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

const ServingAmountEditAmount = ({
  onSetAmount,
  amount,
  serving,
  onClick,
  ariaLabel = "",
  servingsMenuDisabled,
  disabled,
}: ServingAmountEditAmountProps) => {
  const classes = useStyles();
  const coerceThirds = (size: number) => {
    const sizeFixed = size.toFixed(3);
    const sizeFrac = sizeFixed.split(".")[1];
    const sizeInt = Number(sizeFixed.split(".")[0] || 0);
    return sizeFrac === "333"
      ? (sizeInt + 0.333).toFixed(3)
      : sizeFrac === "667"
      ? (sizeInt + 0.666).toFixed(3)
      : sizeFrac === "999"
      ? (sizeInt + 1).toFixed(3)
      : round(size, 3);
  };

  const onChange = (size: string) => {
    const servingAmount = Number(size);
    onSetAmount(servingAmount);
  };
  const isError = isErrantServingAmount({ amount });

  return (
    <div className={classes.flex}>
      <TextField
        disabled={disabled}
        variant="standard"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        error={isError}
        value={amount ? coerceThirds(amount) : ""}
        type="number"
        onChange={e => onChange(e.target.value)}
        classes={{ root: classes.servingSize }}
        inputProps={{
          step: 0.25,
          className: classes.hiddenNumberButtons,
          "aria-label": ariaLabel,
        }}
        InputProps={{
          classes: {
            root: isError ? classes.errantServingSizeInput : classes.servingSizeInput,
          },
        }}
      />
      <div className={classes.servingNameWrapper}>
        <Typography
          className={servingsMenuDisabled ? undefined : classes.link}
          variant="subtitle1"
          onClick={servingsMenuDisabled ? undefined : onClick}
        >
          {addWeightToAmbiguousServingString(serving.units, serving.weight ? serving.weight * amount : null)}
        </Typography>
      </div>
      {serving.foodOrRecipe.__typename === "BrandedFood" && serving.foodOrRecipe.brand && (
        <div className={classes.brandNameWrapper}>
          <Typography className={classes.brandName} variant="subtitle1">
            ({serving.foodOrRecipe.brand.name})
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ServingAmountEditAmount;
