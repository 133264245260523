import { ExchangeTypeSchema, FoodDataSourceSchema, FoodTypeSchema } from "@notemeal/food-utils";
import { z } from "zod";
import { NutrientAmountSchema } from "./NutrientAmountSchema";
import { BaseFoodServingSchema } from "./FoodServingSchema";
import { IntegerSchema } from "@notemeal/validators";
import { createNewFoodServingState } from "./foodServingsUtils";
import { Mode } from "./utils";
import { FoodDataSource } from "@notemeal/graphql-types";

const FoodNutrientAmountFormInputSchema = z.object({
  fdcNutrientId: IntegerSchema,
  amount: z.number(),
  footnote: z.string().nullable(),
  dataPoints: IntegerSchema.nullable(),
  min: z.number().nullable(),
  max: z.number().nullable(),
  median: z.number().nullable(),
});

const BrandSchema = z.object({ id: z.string(), name: z.string(), usdaManufacturerName: z.string().nullable() });
const FoodGroupSchema = z.object({ id: z.string(), name: z.string() });

export const BaseFoodFormSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1),
  source: FoodDataSourceSchema,
  mode: z.enum(["staff", "restaurant", "org"]),
  type: FoodTypeSchema,

  thumbnailUrl: z.string().nullable(),
  isRecipeIngredientOnly: z.boolean(),
  excludeFromSuggestions: z.boolean(),

  // allow these to be nullable to skirt around the issue of
  // mui text fields not allowing the base zero to be deleted
  // when entering a number
  choPer100g: z.number().nonnegative(),
  proPer100g: z.number().nonnegative(),
  fatPer100g: z.number().nonnegative(),

  exchangeTypes: z.array(ExchangeTypeSchema).readonly().nullable(),
  isFreeFood: z.boolean(),
  servings: z.array(BaseFoodServingSchema),
  foodGroups: z.array(FoodGroupSchema).readonly(),

  nutrientAmounts: z.array(FoodNutrientAmountFormInputSchema).nullable(),
  nutrientAmountsManualEntry: NutrientAmountSchema.nullable(),
  brand: BrandSchema.nullable(),
  groceryListCategoryId: z.string().nullable(),

  usdaManufacturerName: z.string().optional(),
  usdaFdcId: z.number().nullable(),
  usdaFdcDescription: z.string().nullable(),
  usdaFdcDataType: z.string().nullable(),
  usdaFdcFoodCategoryId: z.number().nullable(),

  locales: z.array(z.string()).readonly().nullable(),
});

export type BaseFoodFormType = z.infer<typeof BaseFoodFormSchema>;

export const getBaseFoodFormDefaultValues = (mode: Mode): Partial<BaseFoodFormType> => {
  const isRestaurantMode = mode === "restaurant";

  const type = isRestaurantMode ? "BrandedFood" : "GenericFood";
  const source = isRestaurantMode ? "restaurant" : "manual";
  const exchangeTypes = isRestaurantMode ? null : [];

  return {
    name: "",
    thumbnailUrl: null,
    choPer100g: 0,
    proPer100g: 0,
    fatPer100g: 0,
    type,
    exchangeTypes,
    isRecipeIngredientOnly: false,
    excludeFromSuggestions: false,
    isFreeFood: false,
    usdaFdcId: null,
    usdaFdcDescription: null,
    usdaFdcDataType: null,
    usdaFdcFoodCategoryId: null,
    nutrientAmounts: null,
    nutrientAmountsManualEntry: null,
    servings: [createNewFoodServingState(true)],
    foodGroups: [],
    brand: null,
    groceryListCategoryId: null,
    locales: [],
    source,
    mode,
  };
};

export const FoodFormSchema = (options?: { overrides: z.ZodObject<{}> }) =>
  BaseFoodFormSchema.merge(options?.overrides ?? z.object({})).superRefine((state, ctx) => {
    const { mode, exchangeTypes, isFreeFood, source, servings, locales } = state;

    const isRestaurantMode = mode === "restaurant";
    const isStaffMode = mode === "staff";

    if ((!exchangeTypes || exchangeTypes.length === 0) && !isFreeFood && !isRestaurantMode) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Food must have 'exchanges'. Select 'N/A' for a 'free food'",
        path: ["exchangeTypes"],
      });
    }

    if (servings.length < 1 || !servings.some(s => s.isDefault)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Food must have a default serving",
        path: ["servings"],
      });
    }

    if (source === FoodDataSource.usda && !state.usdaFdcId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "USDA foods must have a USDA ID",
        path: ["source"],
      });
    }

    // check for staff manual foods with no locales. Org manual food locales are handled on the back end for now
    if (source === FoodDataSource.manual && isStaffMode && (!locales || locales.length === 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Manual foods must have at least one locale",
        path: ["locales"],
      });
    }
  });
