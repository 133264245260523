import { Typography, styled } from "@mui/material";
import { useClientTimezone } from "@notemeal/shared-ui";
import { formatTimeRangeInTimezone } from "@notemeal/utils-date-time";
import { CalendarEvent } from "apps/web/src/components/Calendar/Week/utils";

export const LineItem = styled(Typography)(() => ({
  lineHeight: 1.5,
}));

export const TimeLineItem = ({ event }: { event: CalendarEvent }) => {
  const clientTimezone = useClientTimezone();

  const displayTime = formatTimeRangeInTimezone(event.start, event.durationInMinutes, clientTimezone, {
    excludeTimezoneSuffix: true,
  });

  if (event.isAllDay) {
    return <LineItem variant="body2">Time: All Day</LineItem>;
  } else {
    return <LineItem variant="body2">Time: {displayTime}</LineItem>;
  }
};
