import { getNextPosition } from "@notemeal/shared-ui";
import { newId } from "@notemeal/shared-ui";
import { RestaurantMenuLinkPlateItemFormFragment } from "../../../types";
import { RestaurantMenuLinkState } from "./types";

interface RestaurantMenuLink_EditSectionMaxAmountAction {
  type: "RestaurantMenuLink_EditSectionMaxAmountAction";
  payload: {
    restaurantMenuSectionId: string;
    maxAmount: number | null;
  };
}

interface RestaurantMenuLink_EditMenuItemAppearanceAction {
  type: "RestaurantMenuLink_EditMenuItemAppearanceAction";
  payload: {
    restaurantMenuSectionId: string;
    menuItemId: string;
    maxAmount: number | null;
    availableForOrder: boolean;
    allowSpecialRequests: boolean;
    included: boolean;
  };
}

interface RestaurantMenuLink_ToggleSectionIncludedAction {
  type: "RestaurantMenuLink_ToggleSectionIncludedAction";
  payload: {
    restaurantMenuSectionId: string;
    included: boolean;
  };
}

interface RestaurantMenuLink_AllItemsAllowSpecialRequestsAction {
  type: "RestaurantMenuLink_AllItemsAllowSpecialRequestsAction";
  payload: {
    allowSpecialRequests: boolean;
  };
}

interface RestaurantMenuLink_AddPlate {
  type: "RestaurantMenuLink_AddPlate";
  payload: {
    items: readonly RestaurantMenuLinkPlateItemFormFragment[];
  };
}

interface RestaurantMenuLink_EditPlateAmount {
  type: "RestaurantMenuLink_EditPlateAmount";
  payload: {
    restaurantMenuLinkPlateId: string;
    bulkOrderAmountInput: string;
  };
}

interface RestaurantMenuLink_EditPlateItems {
  type: "RestaurantMenuLink_EditPlateItems";
  payload: {
    restaurantMenuLinkPlateId: string;
    editedItemIds: readonly string[];
    items: readonly RestaurantMenuLinkPlateItemFormFragment[];
  };
}

interface RestaurantMenuLink_RemovePlate {
  type: "RestaurantMenuLink_RemovePlate";
  payload: {
    restaurantMenuLinkPlateId: string;
  };
}

interface RestaurantMenuLink_SetDefaultPlate {
  type: "RestaurantMenuLink_SetDefaultPlate";
  payload: {
    restaurantMenuLinkPlateId: string;
    restaurantMenuLinkPlateDefaultValue: boolean;
  };
}

interface RestaurantMenuLink_ToggleAllowCustomOrders {
  type: "RestaurantMenuLink_ToggleAllowCustomOrders";
  payload: {
    allowCustomOrders: boolean;
  };
}
interface RestaurantMenuLink_ToggleAllowPlateOrders {
  type: "RestaurantMenuLink_ToggleAllowPlateOrders";
  payload: {
    allowPlateOrders: boolean;
  };
}

export type RestaurantMenuLinkAction =
  | RestaurantMenuLink_EditSectionMaxAmountAction
  | RestaurantMenuLink_EditMenuItemAppearanceAction
  | RestaurantMenuLink_ToggleSectionIncludedAction
  | RestaurantMenuLink_AllItemsAllowSpecialRequestsAction
  | RestaurantMenuLink_AddPlate
  | RestaurantMenuLink_EditPlateAmount
  | RestaurantMenuLink_EditPlateItems
  | RestaurantMenuLink_RemovePlate
  | RestaurantMenuLink_SetDefaultPlate
  | RestaurantMenuLink_ToggleAllowCustomOrders
  | RestaurantMenuLink_ToggleAllowPlateOrders;

export const restaurantMenuLinkReducer = (state: RestaurantMenuLinkState, action: RestaurantMenuLinkAction): RestaurantMenuLinkState => {
  switch (action.type) {
    case "RestaurantMenuLink_EditSectionMaxAmountAction":
      return {
        ...state,
        restaurantMenu: {
          ...state.restaurantMenu,
          sections: state.restaurantMenu.sections.map(section => {
            if (section.id !== action.payload.restaurantMenuSectionId) {
              return section;
            }

            return { ...section, maxAmount: action.payload.maxAmount };
          }),
        },
      };
    case "RestaurantMenuLink_EditMenuItemAppearanceAction":
      return {
        ...state,
        restaurantMenu: {
          ...state.restaurantMenu,
          sections: state.restaurantMenu.sections.map(section => {
            if (section.id !== action.payload.restaurantMenuSectionId) {
              return section;
            }
            return {
              ...section,
              menuItemAppearances: section.menuItemAppearances.map(mia => {
                if (mia.menuItem.id !== action.payload.menuItemId) {
                  return mia;
                }
                return {
                  ...mia,
                  included: action.payload.included,
                  maxAmount: action.payload.maxAmount,
                  availableForOrder: action.payload.availableForOrder,
                  allowSpecialRequests: action.payload.allowSpecialRequests,
                };
              }),
            };
          }),
        },
      };
    case "RestaurantMenuLink_ToggleSectionIncludedAction":
      return {
        ...state,
        restaurantMenu: {
          ...state.restaurantMenu,
          sections: state.restaurantMenu.sections.map(section => {
            if (section.id !== action.payload.restaurantMenuSectionId) {
              return section;
            }
            return {
              ...section,
              menuItemAppearances: section.menuItemAppearances.map(mia => ({
                ...mia,
                included: action.payload.included,
              })),
            };
          }),
        },
      };
    case "RestaurantMenuLink_AllItemsAllowSpecialRequestsAction":
      return {
        ...state,
        restaurantMenu: {
          ...state.restaurantMenu,
          sections: state.restaurantMenu.sections.map(section => ({
            ...section,
            menuItemAppearances: section.menuItemAppearances.map(mia => ({
              ...mia,
              allowSpecialRequests: action.payload.allowSpecialRequests,
            })),
          })),
        },
      };
    case "RestaurantMenuLink_AddPlate":
      return {
        ...state,
        plates: [
          ...state.plates,
          {
            id: newId(),
            bulkOrderAmountInput: "",
            initialItemIds: [],
            editedItemIds: [],
            items: action.payload.items,
            isDefault: false,
            position: getNextPosition(state.plates),
            orders: [],
          },
        ],
      };
    case "RestaurantMenuLink_EditPlateItems":
      return {
        ...state,
        plates: state.plates.map(plate => {
          if (plate.id !== action.payload.restaurantMenuLinkPlateId) {
            return plate;
          } else {
            return {
              ...plate,
              editedItemIds: action.payload.editedItemIds,
              items: action.payload.items,
            };
          }
        }),
      };
    case "RestaurantMenuLink_EditPlateAmount":
      return {
        ...state,
        plates: state.plates.map(plate => {
          if (plate.id !== action.payload.restaurantMenuLinkPlateId) {
            return plate;
          } else {
            return {
              ...plate,
              bulkOrderAmountInput: action.payload.bulkOrderAmountInput,
            };
          }
        }),
      };
    case "RestaurantMenuLink_RemovePlate":
      return {
        ...state,
        plates: state.plates.filter(p => p.id !== action.payload.restaurantMenuLinkPlateId),
      };
    case "RestaurantMenuLink_SetDefaultPlate":
      return {
        ...state,
        plates: state.plates.map(plate => {
          if (action.payload.restaurantMenuLinkPlateDefaultValue) {
            plate.isDefault = plate.id === action.payload.restaurantMenuLinkPlateId;
          } else {
            plate.isDefault = plate.id === action.payload.restaurantMenuLinkPlateId ? false : plate.isDefault;
          }
          return plate;
        }),
      };
    case "RestaurantMenuLink_ToggleAllowCustomOrders":
      const includeAllMenuItems = !state.restaurantMenu.sections.some(s => s.menuItemAppearances.some(mia => mia.included));
      return {
        ...state,
        allowCustomOrders: action.payload.allowCustomOrders,
        restaurantMenu: {
          ...state.restaurantMenu,
          //if no items were included previously, they all will be included. Otherwise, state is remembered.
          sections: includeAllMenuItems
            ? state.restaurantMenu.sections.map(section => {
                return {
                  ...section,
                  menuItemAppearances: section.menuItemAppearances.map(mia => ({
                    ...mia,
                    included: true,
                  })),
                };
              })
            : state.restaurantMenu.sections,
        },
      };
    case "RestaurantMenuLink_ToggleAllowPlateOrders":
      return {
        ...state,
        allowPlateOrders: action.payload.allowPlateOrders,
      };
  }
};
