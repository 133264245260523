import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { Loading, NutrientReadyServingAmount, useNutrientAmountGroups } from "@notemeal/shared-ui";
import DialogTitle from "../../componentLibrary/DialogTitle";
import { SexType } from "../../types";
import CarbohydrateTree from "./CarbohydrateTree";
import LipidTree from "./LipidTree";
import MineralTree from "./MineralTree";
import ProteinTree from "./ProteinTree";
import VitaminTree from "./VitaminTree";

interface NutrientAmountDialogProps {
  servingAmounts: readonly NutrientReadyServingAmount[];
  open: boolean;
  onClose: () => void;
  age?: number | null;
  sex?: SexType | null;
  kcal?: number | null;
  disableDRI?: boolean;
}

const DEFAULT_AGE = 21;
const DEFAULT_SEX = "male";
const DEFAULT_KCAL = 3000;

const NutrientAmountDialog = ({ onClose, open, servingAmounts, age, sex, kcal, disableDRI = false }: NutrientAmountDialogProps) => {
  const sexAndAgeProvided = age && sex;
  const { loading, allNutrientGroups } = useNutrientAmountGroups({
    servingAmounts,
    age: age || DEFAULT_AGE,
    sex: sex || DEFAULT_SEX,
    kcal: kcal || DEFAULT_KCAL,
  });

  const { carbs, proteins, lipids, vitamins, minerals } = allNutrientGroups;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"lg"}
      fullWidth={true}>
      <DialogTitle title="Nutrient Analysis" onClose={onClose} />
      {loading ? (
        <DialogContent>
          <Loading progressSize="lg" />
        </DialogContent>
      ) : (
        <DialogContent>
          {!sexAndAgeProvided && !disableDRI && (
            <Typography variant="subtitle1">
              *No athlete gender or age available. Assuming age '{DEFAULT_AGE}' and gender '{DEFAULT_SEX}'.
            </Typography>
          )}
          {!kcal && !disableDRI && <Typography variant="subtitle1">*No athlete kcal available, assuming '{DEFAULT_KCAL}kcal'.</Typography>}
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}>
              <CarbohydrateTree carbs={carbs} displayDri={!disableDRI} />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}>
              <ProteinTree proteins={proteins} displayDri={!disableDRI} />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}>
              <LipidTree lipids={lipids} displayDri={!disableDRI} />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}>
              <VitaminTree vitamins={vitamins} displayDri={!disableDRI} />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}>
              <MineralTree minerals={minerals} displayDri={!disableDRI} />
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NutrientAmountDialog;
