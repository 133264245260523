import ErrorIcon from "@mui/icons-material/Error";
import { Badge, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Loading } from "@notemeal/shared-ui";
import React from "react";

interface ImportResultBadgeProps {
  state: "loading" | "done" | "error" | null;
  numberRecords: number | null;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badgeMargin: {
      // margin: theme.spacing(2)
    },
    progressMargin: {
      marginLeft: theme.spacing(3),
    },
    progressCircleAndTitle: {
      display: "flex",
      flexFlow: "row wrap",
    },
    root: {},
  })
);

const ImportResultBadge = ({ state, numberRecords, children }: ImportResultBadgeProps) => {
  const classes = useStyles();
  switch (state) {
    case "done":
      return (
        <Badge
          classes={{ root: classes.root }}
          max={999}
          badgeContent={numberRecords}
          className={classes.badgeMargin}>
          {children}
        </Badge>
      );
    case "loading":
      return (
        <div className={classes.progressCircleAndTitle}>
          {children}
          <div className={classes.progressMargin}>
            <Loading progressSize="sm" />
          </div>
        </div>
      );
    case "error":
      return (
        <div className={classes.progressCircleAndTitle}>
          {children}
          <div className={classes.progressMargin}>
            <ErrorIcon color="error" />
          </div>
        </div>
      );
    default:
      return <>{children}</>;
  }
};

export default ImportResultBadge;
