import { Button, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Fragment, useState } from "react";

import GoalCreateModal from "../../../../components/Goal/CreateModal";
import None from "./None";

import { formatKcalOffset, useDateFormatting } from "@notemeal/shared-ui";
import { FullGoalFragment } from "../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: `1fr 1fr`,
      gridTemplateRows: "1fr 1fr 1fr",
      flexGrow: 1,
      justifyItems: "start",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    header: {
      display: "flex",
      flexGrow: 0,
    },
    spacer: {
      flexGrow: 1,
    },
    type: {
      gridRowStart: 1,
      gridColumnStart: 1,
    },
    kcal: {
      gridRowStart: 1,
      gridColumnStart: 2,
    },
    start: {
      gridRowStart: 2,
      gridColumnStart: 1,
    },
    end: {
      gridRowStart: 2,
      gridColumnStart: 2,
    },
  })
);

interface AthleteSummaryCurrentGoalProps {
  athleteId: string;
  goal: FullGoalFragment | null;
}

const AthleteSummaryCurrentGoal = ({ athleteId, goal }: AthleteSummaryCurrentGoalProps) => {
  const classes = useStyles();
  const [createOpen, setCreateOpen] = useState(false);
  const { formatDateWithLocale } = useDateFormatting();

  if (!goal) {
    return <None athleteId={athleteId} />;
  }

  return (
    <Fragment>
      <div className={classes.header}>
        <Typography variant="h3">Current Weight Target</Typography>
        <div className={classes.spacer} />
        <Button
          variant="outlined"
          size="small"
          onClick={() => setCreateOpen(true)}>
          Add
        </Button>
      </div>
      <div className={classes.root}>
        <div className={classes.type}>
          <Typography variant="body1">
            <b>Type:</b> {goal.type.name}
          </Typography>
        </div>
        <div className={classes.kcal}>
          <Typography variant="body1">
            <b>Offset:</b> {formatKcalOffset(goal.kcalOffset)}
          </Typography>
        </div>
        <div className={classes.start}>
          <Typography variant="body1">
            <b>Start Date: </b>
            {formatDateWithLocale(goal.start)}
          </Typography>
        </div>
        <div className={classes.end}>
          <Typography variant="body1">
            <b>End Date: </b>
            {goal.end ? <span> {formatDateWithLocale(goal.end)} </span> : "None"}
          </Typography>
        </div>
      </div>
      {createOpen && <GoalCreateModal
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        athleteId={athleteId} />}
    </Fragment>
  );
};

export default AthleteSummaryCurrentGoal;
