import FilterIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Checkbox,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MacrosSummaryLabel, getMacroProtocolMacros, getMealPlanRmrErrors } from "@notemeal/shared-ui";
import { getAthleteListName, maybeAthleteBirthDateToAge } from "@notemeal/shared-utils-macro-protocol";
import { sortByKey } from "@notemeal/utils-sort";
import { useState } from "react";
import { Link } from "react-router-dom";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { getNavOrgTeamRoster } from "../../../pages/Auth/Org/Team/TeamPaths";
import { AthleteWithGoalAndAnthroFragment, CopyableMealPlanFragment } from "../../../types";

interface ChooseAthletesListProps {
  toCopyMealPlan: CopyableMealPlanFragment;
  selectedTeamId: string;
  setSelectedAthleteIds: (ids: string[]) => void;
  selectedAthleteIds: string[];
  athletes: readonly AthleteWithGoalAndAnthroFragment[];
}

const useStyles = makeStyles(({ spacing, palette: { common, text } }: Theme) =>
  createStyles({
    column: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      minWidth: "700px",
    },
    columnText: {
      padding: spacing(1, 0),
      flexGrow: 0,
      flexShrink: 0,
    },

    // Title
    titleRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },

    searchBody: {
      flexGrow: 1,
      flexShrink: 1,
      height: "80%",
      width: "100%",
      border: "1px solid lightgray",
      borderRadius: "4px",
    },

    // Search/Filter row
    filterAndSearchRow: {
      padding: spacing(1, 1),
      display: "flex",
      flexFlow: "row",
      justifyContent: "space-between",
    },
    searchTextField: {
      margin: spacing(),
    },
    filterTextField: {
      margin: spacing(),
    },

    // List
    listRoot: {
      overflowY: "scroll",
      flexGrow: 1,
      flexShrink: 1,
      height: "78%",
      width: "100%",
    },
    listItem: {
      cursor: "pointer",
      padding: spacing(0, 2),
      width: "100%",
    },
    disabledListItemText: {
      color: text.disabled,
    },
  })
);

const athleteSecondaryText = (ath: AthleteWithGoalAndAnthroFragment, mp: CopyableMealPlanFragment) => {
  const weight = ath.mostRecentAnthropometryEntry?.weight;
  const goal = ath.currentGoal?.type.name;
  const macroLabel = createMacroLabel(ath, mp);

  // create `(weight)lbs - goal | macros` text, using available values
  return (
    <>
      {weight && `${weight}lbs`}
      {(goal || macroLabel) && " - "}
      {goal}
      {goal && macroLabel && " | "}
      {macroLabel}
    </>
  );
};

const createMacroLabel = (ath: AthleteWithGoalAndAnthroFragment, mp: CopyableMealPlanFragment) => {
  if (!ath.mostRecentAnthropometryEntry || !ath.currentGoal) {
    return null;
  }
  const calorieBudget = mp.macroProtocol.calorieBudget;
  const macros = getMacroProtocolMacros(
    {
      ...mp.macroProtocol,
      calorieBudget: calorieBudget
        ? {
            ...calorieBudget,
            __typename: "CalorieBudget" as const,
            goalSnapshot: ath.currentGoal,
          }
        : null,
      anthropometrySnapshot: ath.mostRecentAnthropometryEntry,
    },
    ath.birthDate
  );
  return <MacrosSummaryLabel
    variant="sm"
    kcalPrefix
    macros={macros} />;
};

const ChooseAthletesList = ({
  toCopyMealPlan,
  selectedTeamId,
  setSelectedAthleteIds,
  selectedAthleteIds,
  athletes,
}: ChooseAthletesListProps) => {
  const classes = useStyles();
  const [searchAthleteValue, setSearchAthleteValue] = useState("");
  const [filteredGoalType, setFilteredGoalType] = useState("All Goals");

  const rmrMethod = toCopyMealPlan.macroProtocol.calorieBudget && toCopyMealPlan.macroProtocol.calorieBudget.rmrMethod;

  return (
    <div className={classes.column}>
      <div className={classes.titleRow}>
        <Typography
          variant="h3"
          className={classes.columnText}
          color="textSecondary"
          align="left">
          Choose Athletes to Copy To
        </Typography>
        <Link target="_blank" to={getNavOrgTeamRoster(selectedTeamId)}>
          <Button>Edit Goals + Anthro</Button>
        </Link>
      </div>

      <div className={classes.searchBody}>
        <div className={classes.filterAndSearchRow}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" disablePointerEvents>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Search Athletes"}
            className={classes.searchTextField}
            value={searchAthleteValue}
            onChange={e => setSearchAthleteValue(e.target.value)}
          />
          <TextField
            select
            className={classes.filterTextField}
            value={filteredGoalType}
            onChange={e => setFilteredGoalType(e.target.value as string)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FilterIcon />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={"All Goals"}>*All Goals</MenuItem>
            <MenuItem value={"Weight Loss"}>Weight Loss</MenuItem>
            <MenuItem value={"Weight Maintain"}>Weight Maintain</MenuItem>
            <MenuItem value={"Weight Gain"}>Weight Gain</MenuItem>
            <MenuItem value={"Injury Recovery"}>Injury Recovery</MenuItem>
          </TextField>
        </div>
        <List classes={{ root: classes.listRoot }}>
          {athletes &&
            sortByKey(
              athletes.filter(ath => {
                const cleanAthName = getAthleteListName(ath).toLowerCase();
                const cleanSearchValue = searchAthleteValue.trim().toLowerCase();
                return (
                  cleanAthName.includes(cleanSearchValue) &&
                  (ath.currentGoal?.type.name === filteredGoalType || filteredGoalType === "All Goals")
                );
              }),
              "lastName"
            ).map(ath => {
              const anthropometrySnapshot = !ath.mostRecentAnthropometryEntry
                ? null
                : {
                    ...ath.mostRecentAnthropometryEntry,
                    age: maybeAthleteBirthDateToAge(ath.birthDate),
                  };
              let disabledReasons: string[] = [];
              if (anthropometrySnapshot) {
                if (rmrMethod) {
                  disabledReasons = getMealPlanRmrErrors(rmrMethod, anthropometrySnapshot);
                }
              } else {
                disabledReasons.push("Athlete has no anthropometry entries");
              }
              if (!ath.currentGoal) {
                disabledReasons.push("Athlete has no goals");
              }
              const disabled = disabledReasons.length > 0;

              return (
                <TWItemizedTooltip
                  key={ath.id}
                  title="Cannot copy meal plan to athlete:"
                  items={disabledReasons}>
                  <ListItem
                    button={(!disabled || undefined) as true}
                    className={classes.listItem}
                    onClick={() =>
                      !disabled &&
                      setSelectedAthleteIds(
                        selectedAthleteIds.includes(ath.id)
                          ? selectedAthleteIds.filter(saId => saId !== ath.id)
                          : [...selectedAthleteIds, ath.id]
                      )
                    }
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedAthleteIds.includes(ath.id)}
                        tabIndex={-1}
                        disableRipple />
                    </ListItemIcon>
                    <ListItemText
                      primary={getAthleteListName(ath)}
                      secondary={!disabled && athleteSecondaryText(ath, toCopyMealPlan)}
                      className={disabled ? classes.disabledListItemText : undefined}
                    />
                  </ListItem>
                </TWItemizedTooltip>
              );
            })}
        </List>
        <Divider />
        {athletes && (
          <Typography
            variant="body2"
            className={classes.columnText}
            align="center">
            (Showing{" "}
            {
              athletes.filter(ath => {
                const cleanAthName = getAthleteListName(ath).toLowerCase();
                const cleanSearchValue = searchAthleteValue.trim().toLowerCase();
                return (
                  cleanAthName.includes(cleanSearchValue) &&
                  (ath.currentGoal?.type.name === filteredGoalType || filteredGoalType === "All Goals")
                );
              }).length
            }{" "}
            of {athletes.length} results)
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ChooseAthletesList;
