import Info from "@mui/icons-material/Info";
import { Box, Tooltip, Typography } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import { greyscaleLight } from "apps/web/src/pages/Theme/Colors/Greyscale";
import { AthleteGoalConfigurationForCompliancePageFragment } from "apps/web/src/types";
import { TextWithLabel } from "./TextWithLabel";
interface GoalConfigurationHeaderSummaryProps {
  goalConfiguration: AthleteGoalConfigurationForCompliancePageFragment;
}

export const GoalConfigurationHeaderSummary = ({ goalConfiguration }: GoalConfigurationHeaderSummaryProps) => {
  const { emoji, name, category, target, startDate, endDate, completedGoalCount, totalGoalCount } = goalConfiguration;
  const { formatDateWithLocale } = useDateFormatting();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: greyscaleLight[25], m: 6, p: 3, gap: 3, borderRadius: "6px" }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="h3">
          {emoji} {name}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <TextWithLabel label="Type" text={category.name} />
        <TextWithLabel label="Completion Criteria" text={!target ? "Mark as Complete" : "Update Progress"} />
        <TextWithLabel label="Start Date" text={startDate ? formatDateWithLocale(startDate) : "None"} />
        <TextWithLabel label="End Date" text={endDate ? formatDateWithLocale(endDate) : "None"} />
        <TextWithLabel
          label="Compliance"
          text={`${completedGoalCount}/${totalGoalCount}`}
          icon={
            <Tooltip
              title={
                <>
                  <Typography sx={{ color: "highEmphasisTextDark" }} variant="h4">
                    Compliance
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: "mediumEmphasisTextDark" }}>
                    {
                      "This number is {# of days that the task has been completed} / {# of days that have passed where the task was assigned}"
                    }
                  </Typography>
                </>
              }
            >
              <Box sx={{ px: "8px", height: "16px" }}>
                <Info htmlColor="black" />
              </Box>
            </Tooltip>
          }
        />
      </Box>
    </Box>
  );
};
