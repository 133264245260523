import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { CropArea, getBase64string, ImageCropper } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useState } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: {
      minHeight: "calc(100% - 128px)",
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "white",
      zIndex: 1,
    },
    dialogActions: {
      backgroundColor: "white",
      zIndex: 1,
    },
  })
);

interface CropperModalProps {
  open: boolean;
  onClose: () => void;
  imageUrl: string;
  onSave: (cropppedImageUrl: string) => Promise<void>;
}

const CropperModal = ({ open, onClose, onSave, imageUrl }: CropperModalProps) => {
  const classes = useStyles();

  const [cropArea, setCropArea] = useState<CropArea | null>(null);

  const handleSave = async () => {
    if (cropArea) {
      const croppedImage = await getBase64string({ cropArea, imageUrl });
      if (croppedImage) {
        await onSave(croppedImage);
      }
    }
    onClose();
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle title="Crop Image" onClose={onClose} />
      <DialogContent className={classes.content}>
        <ImageCropper imageUrl={imageUrl} setCropArea={setCropArea}></ImageCropper>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropperModal;
