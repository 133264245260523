import { useDateFormatting } from "@notemeal/shared-ui";
import { FormatEventDateTimeOptions } from "@notemeal/utils-date-time";
import React from "react";
import { NoteWithTypeFragment } from "../../types";
import GenericTable, { ICol } from "../universal/GenericTable";

function formatTableCell(
  record: NoteWithTypeFragment,
  key: keyof NoteWithTypeFragment,
  formatEventDatetimeWithLocale: (date: Date | string, options?: FormatEventDateTimeOptions) => string
): string | React.ReactNode {
  switch (key) {
    case "datetime":
      return `${formatEventDatetimeWithLocale(record[key])}`;
    case "type":
      const noteType = record[key];
      return !!noteType ? noteType.name.toUpperCase() : "";
    default:
      return record[key] === null ? "" : String(record[key]);
  }
}
const cols: ICol<NoteWithTypeFragment>[] = [
  { id: "datetime", isNumeric: false, disablePadding: false, label: "Date" },
  { id: "content", isNumeric: false, disablePadding: false, label: "Content" },
  { id: "type", isNumeric: false, disablePadding: false, label: "Type" },
];

export interface NoteTableProps {
  tableRows: NoteWithTypeFragment[];
  onClickAddEntry: () => void;
  onClickRow: (row: NoteWithTypeFragment) => void;
  onClickDeleteRow: (row: NoteWithTypeFragment) => void;
}

const NoteTable = ({ tableRows, onClickRow, onClickAddEntry, onClickDeleteRow }: NoteTableProps) => {
  const { formatEventDatetimeWithLocale } = useDateFormatting();

  const handleFormatTableCell = (record: NoteWithTypeFragment, key: keyof NoteWithTypeFragment): string | React.ReactNode => {
    return formatTableCell(record, key, formatEventDatetimeWithLocale);
  };
  return (
    <GenericTable
      tableRows={tableRows}
      tableCols={cols}
      addButtonText={"Add Note"}
      onClickAddEntry={onClickAddEntry}
      onClickRow={onClickRow}
      onClickDeleteRow={onClickDeleteRow}
      formatTableCell={handleFormatTableCell}
      defaultOrderBy={"datetime"}
    />
  );
};

export default NoteTable;
