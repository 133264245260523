import { Chip, Grid, Typography } from "@mui/material";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { BrandFragment, BrandsDocument, BrandsQuery, BrandsQueryVariables } from "../../../../types";
import { AutocompleteQuerySearchBar } from "../../../universal/AutocompleteQuerySearchBar/AutocompleteQuerySearchBar";
import LabeledSelectNoId from "../../../universal/LabeledSelectNoId";
import { BaseFoodFormType } from "../../FoodFormSchema/FoodFormSchema";

type FoodType = 'GenericFood' | 'BrandedFood';

interface FoodTypeSelectProps {
  form: UseFormReturn<BaseFoodFormType>;
  disabled?: boolean;
}

export const FoodTypeSelect = ({ form, disabled }: FoodTypeSelectProps) => {
  const { control, watch, setValue } = form;
  const [watchedMode, watchedType, watchedBrand, watchedUsdaManufacturerName] = watch(["mode", "type", "brand", "usdaManufacturerName"]);

  const onChangeType = (type: FoodType) => {
    if (watchedMode === "restaurant") {
      return;
    }

    setValue("type", type);
    if (type === "GenericFood") {
      setValue("brand", null);
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <Controller
          name={"type"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <LabeledSelectNoId
              {...field}
              onChange={onChangeType}
              selectedOption={field.value}
              placeholder="Type"
              options={["GenericFood", "BrandedFood"]}
              optionToName={(name: string) => name}
              disabled={disabled}
            />
          )}
        />
      </Grid>

      {watchedType === "BrandedFood" && (
        <Grid
          item
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          xs={12}>
          <Controller
            name={"brand"}
            control={control}
            render={({ field: { onChange } }) => (
              <AutocompleteQuerySearchBar<BrandFragment, BrandsQuery, BrandsQueryVariables>
                query={BrandsDocument}
                placeholder="Search for brands"
                fullWidth
                disabled={disabled}
                getOptionLabel={o => o.name}
                getOptionsFromQueryData={data => [...data.brands]}
                getQueryOptions={searchTerm => ({
                  variables: { searchTerm, limit: 25 },
                })}
                onChange={o => onChange(o)}
                getUserFriendlyQueryErrorMessage={() => "Error searching brands, please try again."}
                clearOnSelect={false}
              />
            )}
          />
          {watchedBrand ? (
            <Controller
              name={"brand"}
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <Chip
                  disabled={disabled}
                  label={field.value?.name}
                  onDelete={() => onChange(null)}
                  sx={{ width: "fit-content" }}
                  color="default"
                />
              )}
            />
          ) : watchedUsdaManufacturerName ? (
            <Typography variant="subtitle1" color="error">
              '{watchedUsdaManufacturerName}' not found in 'brands' table.
            </Typography>
          ) : (
            <React.Fragment />
          )}
        </Grid>
      )}
    </>
  );
};
