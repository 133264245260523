import { sortFnByFn } from "@notemeal/utils-sort";
import React from "react";
import { CardMode } from "../../../shared/DisplayCard";
import DisplayList, { IDAbleStringOrNumber, RenderListItemArgs } from "../../../shared/DisplayList";
import ProfileCard, { PROFILE_CARD_ADDITIONAL_INFO_HEIGHT, PROFILE_CARD_HEIGHT, ProfileForDisplay } from "./ProfileCard";

interface ProfilesListProps<T extends IDAbleStringOrNumber> {
  profiles: readonly T[];
  onAdd?: (profile: T) => void;
  onLink?: (profile: T) => void;
  onEdit?: (profile: T) => void;
  onCancel?: (profile: T) => void;
  onUnlink?: (profile: T) => void;
  onUnlinkArchive?: (profile: T) => void;
  selectedId: string | null;
  title: string;
  columnMode: CardMode;
  disableOnSelected?: boolean;
  getProfileForDisplay: (profile: T) => ProfileForDisplay;
  searchProps?: {
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
  };
  hasAdditionalProfileInfo?: boolean;
  showUnlinkOptions?: boolean;
}

const ProfilesList = <T extends IDAbleStringOrNumber>({
  profiles,
  searchProps,
  onAdd,
  onLink,
  onEdit,
  onCancel,
  onUnlink,
  onUnlinkArchive,
  selectedId,
  title,
  columnMode,
  disableOnSelected,
  getProfileForDisplay,
  hasAdditionalProfileInfo,
}: ProfilesListProps<T>) => {
  const renderListItem = ({ key, ...props }: RenderListItemArgs<T>) => (
    <ProfileCard
      onAdd={onAdd}
      onLink={onLink}
      onEdit={onEdit}
      onCancel={onCancel}
      onUnlink={onUnlink}
      onUnlinkArchive={onUnlinkArchive}
      getProfileForDisplay={getProfileForDisplay}
      key={key}
      {...props}
      hasAdditionalProfileInfo
    />
  );

  return (
    <DisplayList
      title={`${title} (${profiles.length})`}
      columnMode={columnMode}
      renderListItem={renderListItem}
      searchProps={searchProps}
      elements={[...profiles].sort(sortFnByFn(p => getProfileForDisplay(p).lastName || ""))}
      disabled={disableOnSelected && selectedId !== null}
      loading={false}
      selectedId={selectedId}
      headerVariant="body1"
      virtualizeProps={{
        itemSize: hasAdditionalProfileInfo ? PROFILE_CARD_ADDITIONAL_INFO_HEIGHT : PROFILE_CARD_HEIGHT,
      }}
    />
  );
};

export default ProfilesList;
