import { getMealPlanRmrErrors } from "@notemeal/shared-ui";
import { maybeAthleteBirthDateToAge, rmrMethodToText } from "@notemeal/shared-utils-macro-protocol";
import {
  AssignFromMealPlanTemplateAthleteFragment,
  AssignFromMealPlanTemplateFragment,
  AssignMetricMealPlanTemplateToAthleteInput,
} from "apps/web/src/types";
import { getOverrideMealPlanDateAssignmentInput, mealPlanDateAssignmentToSaveTooltips } from "../Content/Calendar/Form/utils";
import { AssignFromMealPlanTemplateState } from "./reducer";

export const mealPlanTemplateForAthleteMessage = (
  mealPlanTemplate: AssignFromMealPlanTemplateFragment,
  athlete: AssignFromMealPlanTemplateAthleteFragment
): string[] => {
  const age = maybeAthleteBirthDateToAge(athlete.birthDate);
  const rmrMethod = mealPlanTemplate.macroProtocol.calorieBudget?.rmrMethod;

  if (mealPlanTemplate.macroProtocol.calorieBudget) {
    if (rmrMethod) {
      if (athlete.mostRecentAnthropometryEntry) {
        const rmrErrors = getMealPlanRmrErrors(rmrMethod, { ...athlete.mostRecentAnthropometryEntry, age });
        return rmrErrors.length > 0 ? [`RMR method ${rmrMethodToText(rmrMethod)}:`].concat(rmrErrors) : [];
      } else {
        return ["Athlete is missing an anthropometry entry, which is required for this template."];
      }
    } else {
      return ["No RMR Method specified"];
    }
  } else {
    return [];
  }
};

export const getCanSaveTooltipItems = (activeStep: number, state: AssignFromMealPlanTemplateState): string[] => {
  let tooltips: string[] = [];
  switch (activeStep) {
    case 0:
      if (state.selectedTemplate === null) {
        tooltips.push("Select a Meal Plan Template");
      }
      if (state.selectedTemplate?.macroProtocol.calorieBudget?.goals.length && !state.selectedGoal) {
        tooltips.push("Goal is required");
      }
      return tooltips;
    case 1:
      return mealPlanDateAssignmentToSaveTooltips(state.dateAssignment);
    case 2:
      if (state.name.trim() === "") {
        tooltips.push("Name is required");
      }
      return tooltips;
    default:
      return [];
  }
};

export const getInputWithMetricAnthro = (
  state: AssignFromMealPlanTemplateState,
  athlete: AssignFromMealPlanTemplateAthleteFragment
): AssignMetricMealPlanTemplateToAthleteInput | null => {
  if (!state.selectedTemplate) {
    return null;
  }
  const existingAnthropometryEntryId = athlete.mostRecentAnthropometryEntry?.id ?? null;
  return {
    athleteId: athlete.id,
    birthDate: null,
    anthropometry: {
      existingAnthropometryEntryId,
      anthropometryEntryInput: null,
    },
    foodPreferences: null,
    mealPlanTemplateGoalId: state.selectedGoal?.id ?? null,
    mealPlanTemplateId: state.selectedTemplate.id,
    dateAssignment: getOverrideMealPlanDateAssignmentInput(state.dateAssignment),
    isAutoSuggestionsEnabled: state.isAutoSuggestionsEnabled,
    description: state.note,
    name: state.name,
  };
};
