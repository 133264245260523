import { sortByKey } from "@notemeal/utils-sort";
import {
  AddAndLinkTeamworksTeamsInput,
  OrgLinkerOrgFragment,
  PositionsMappingInput,
  SportsMappingInput,
  TeamLinkPreviewFragment,
  TeamworksOrgFragment,
} from "../../../../../types";
import { BaseCombinedSportState, mapSportStateToInputs } from "../Sports/utils";

export interface NewTeamState extends BaseCombinedSportState {
  __type: "Add";
  teamworksId: number;
  name: string;
  sportName: string | null;
  teamworksSportName: string | null;
  gender: string | null;
}
export interface LinkedTeamState extends BaseCombinedSportState {
  __type: "Link";
  id: string;
  teamworksId: number;
  teamworksName: string;
  teamworksSportName: string | null;
  notemealName: string;
  name: string;
  gender: string | null;
  sportName: string | null;
}

export type PendingTeam =
  | LinkedTeamState
  | (NewTeamState & {
      id: number;
    });

export type NotemealTeam = TeamLinkPreviewFragment & {
  __type: "NotemealTeam";
  sportName: string | null;
};
export type TeamworksTeam = TeamworksOrgFragment["teams"][number] & {
  __type: "TeamworksTeam";
  sportName: string | null;
  pendingTeams: PendingTeam[];
};

export interface AddAndLinkTeamsState {
  teamworksOrg: TeamworksOrgFragment;
  org: OrgLinkerOrgFragment;
  notemealTeams: readonly NotemealTeam[];
  teamworksTeams: readonly TeamworksTeam[];
  addedTeams: NewTeamState[];
  linkedTeams: LinkedTeamState[];
}

export type PartialAddAndLinkTeamsState = Omit<AddAndLinkTeamsState, "notemealTeams">;

interface LinkTeamsAction {
  type: "LINK_TEAMS_ACTION";
  payload: Omit<LinkedTeamState, "__type">;
}

interface AddTeamAction {
  type: "ADD_TEAM_ACTION";
  payload: Omit<NewTeamState, "__type">;
}

interface RemovePendingTeamAction {
  type: "REMOVE_PENDING_TEAM_ACTION";
  payload: NewTeamState | LinkedTeamState;
}

interface EditPendingTeamAction {
  type: "EDIT_PENDING_TEAM_ACTION";
  payload: NewTeamState | LinkedTeamState;
}

interface UpdateExistingTeamAction {
  type: "UPDATE_EXISTING_TEAM";
  payload: NotemealTeam;
}

export type AddAndLinkTeamsAction =
  | AddTeamAction
  | LinkTeamsAction
  | RemovePendingTeamAction
  | EditPendingTeamAction
  | UpdateExistingTeamAction;

export const addAndLinkTeamsReducer = (state: AddAndLinkTeamsState, action: AddAndLinkTeamsAction): AddAndLinkTeamsState => {
  switch (action.type) {
    case "ADD_TEAM_ACTION":
      return {
        ...state,
        addedTeams: state.addedTeams.concat([{ ...action.payload, __type: "Add" }]),
      };
    case "LINK_TEAMS_ACTION":
      return {
        ...state,
        linkedTeams: state.linkedTeams.concat([{ ...action.payload, __type: "Link" }]),
      };
    case "EDIT_PENDING_TEAM_ACTION": {
      const team = action.payload;
      if (team.__type === "Link") {
        return {
          ...state,
          linkedTeams: [...state.linkedTeams.filter(t => t.id !== team.id), { ...team, __type: "Link" }],
        };
      } else if (team.__type === "Add") {
        return {
          ...state,
          addedTeams: [...state.addedTeams.filter(t => t.teamworksId !== team.teamworksId), { ...team, __type: "Add" }],
        };
      }
      return state;
    }
    case "REMOVE_PENDING_TEAM_ACTION": {
      const team = action.payload;
      if (team.__type === "Link") {
        return {
          ...state,
          linkedTeams: state.linkedTeams.filter(t => t.id !== team.id),
        };
      } else if (team.__type === "Add") {
        return {
          ...state,
          addedTeams: state.addedTeams.filter(t => t.teamworksId !== team.teamworksId),
        };
      }
      return state;
    }
    case "UPDATE_EXISTING_TEAM": {
      const updatedTeam = action.payload;
      const prevIndex = state.notemealTeams.findIndex(({ id }) => id === updatedTeam.id);
      if (prevIndex < 0) {
        return state;
      }
      const notemealTeams = [...state.notemealTeams.slice(0, prevIndex), updatedTeam, ...state.notemealTeams.slice(prevIndex + 1)];
      return {
        ...state,
        notemealTeams,
      };
    }
  }
};

export const buildPartialAddAndLinkTeamsState = (
  teamworksOrg: TeamworksOrgFragment,
  org: OrgLinkerOrgFragment
): PartialAddAndLinkTeamsState => ({
  teamworksOrg,
  org,
  teamworksTeams: sortByKey(teamworksOrg.teams, "name").map(t => ({
    ...t,
    __type: "TeamworksTeam",
    sportName: t.sport,
    pendingTeams: [],
  })),
  addedTeams: [],
  linkedTeams: [],
});

export const buildInitialAddAndLinkTeamsState = (
  partialState: PartialAddAndLinkTeamsState,
  notemealTeams: readonly TeamLinkPreviewFragment[]
): AddAndLinkTeamsState => ({
  ...partialState,
  notemealTeams: sortByKey(notemealTeams, "name").map(t => ({
    ...t,
    __type: "NotemealTeam",
    sportName: t.sport?.name ?? null,
  })),
});

export const mapStateToInputs = (
  state: AddAndLinkTeamsState,
  addedSportsMappings: SportsMappingInput[],
  addPositionsMappings: PositionsMappingInput[]
): AddAndLinkTeamworksTeamsInput => {
  return {
    id: state.org.id,
    addedSportsMappings,
    addPositionsMappings,
    linkedTeams: state.linkedTeams.map(t => ({
      id: t.id,
      teamworksId: t.teamworksId,
      name: t.name,
      gender: t.gender,
      ...mapSportStateToInputs({
        sportState: t.sportState,
      }),
    })),
    newTeams: state.addedTeams.map(t => ({
      teamworksId: t.teamworksId,
      name: t.name,
      gender: t.gender,
      ...mapSportStateToInputs({
        sportState: t.sportState,
      }),
    })),
  };
};
