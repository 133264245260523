import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";

interface ICookieContext {
  getCookieValue: (cookieName: string) => string | null | undefined;
  forceRefresh: () => void;
}

type CookieCache = Record<string, string | null | undefined>;

const getAllCookies = () => {
  const cookies = new Cookies();
  return cookies.getAll<CookieCache>({ doNotParse: true });
};

const CookieContext = createContext<ICookieContext>({
  getCookieValue: () => undefined,
  forceRefresh: () => {},
});

interface CookieContextProviderProps {
  children: ReactNode;
}

export const CookieContextProvider = ({ children }: CookieContextProviderProps) => {
  const [cookieCache, setCookieCache] = useState<CookieCache>(getAllCookies());

  const forceRefresh = () => {
    setCookieCache(getAllCookies());
  };

  const getCookieValue = (cookieName: string) => {
    return cookieCache[cookieName];
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const cookies = getAllCookies();
      if (
        cookies['accessTokenPayloadAndHeader'] !== cookieCache['accessTokenPayloadAndHeader'] ||
        cookies['refreshTokenPayloadAndHeader'] !== cookieCache['refreshTokenPayloadAndHeader']
      ) {
        setCookieCache(cookies);
      }
    }, 250);
    return () => clearInterval(interval);
  }, [setCookieCache, cookieCache['accessTokenPayloadAndHeader'], cookieCache['refreshTokenPayloadAndHeader']]);

  return <CookieContext.Provider value={{ forceRefresh, getCookieValue }}>{children}</CookieContext.Provider>;
};

export const useCookieContext = () => useContext(CookieContext);
