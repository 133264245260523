import { Box, Divider, useTheme } from "@mui/material";
import { useClientTimezone } from "@notemeal/shared-ui";
import { serializeDate } from "@notemeal/utils-date-time";
import CalendarWeek from "apps/web/src/components/Calendar/Week";
import CalendarWeekNavigation from "apps/web/src/components/Calendar/Week/Navigation";
import { RenderCalendarEventArgs } from "apps/web/src/components/Calendar/Week/utils";
import { Dispatch, ReactNode, useState } from "react";
import { useMealPlanCalendarContext } from "../../../contexts/Calendar";
import { useMealPlanColorsContext } from "../../../contexts/Colors";
import MealPlanCalendarDateHeader from "../DateHeader";
import MealPlanCalendarDayColumn from "../DayColumn";
import EventPaperNew from "../Event/NewPaper";
import EventPaper from "../Event/Paper";
import EventLoaderNew from "../Event/Popover/NewEvent/NewEventLoader";
import EventPopoverNewTime from "../Event/Popover/NewTime";
import NotemealEventPopoverPreview from "../Event/Popover/NotemealPreview";
import SuggestionEventPopoverPreview from "../Event/Popover/SuggestionPreview";
import TeamworksEventPopoverPreview from "../Event/Popover/TeamworksPreview";
import ScheduleKey from "../ScheduleKey";

import DateAssignment, { StartDateInPastBehavior } from "./DateAssignment";
import EditScheduleDialog from "./EditSchedule/Dialog";
import { EditSchedule, MealPlanCalendarAction } from "./reducer";
import { EditMealPlanCalendarState, MealPlanCalendarState } from "./types";

import { trackEvent } from "apps/web/src/reporting/reporting";
import { useExternalCalendarsQuery } from "apps/web/src/types";
import {
    getEventArgsForEvent as getEventArgsForEventWithMealPlanId,
    getOverridingMealPlanName as getOverridingMealPlanNameWithMealPlanId,
} from "../../utils";
import CurrentMealPlanStatus from "../CurrentMealPlanStatus";
import ExternalCalendarStatus from "../ExternalCalendar/ExternalCalendarStatus";
import { getKeyForSuggestion, getSuggestedEvents, resolveEventsWithSuggestions } from "../Suggestions/utils";
import { MealPlanCalendarEvent, TemplateEventType, isActivityTemplateCalendarEvent, isMealTemplateCalendarEvent } from "../types";
import { getDayOfWeek, getTeamworksCalendarAsCalendarEvents } from "../utils";
import { MenuSection } from "./Sections";
import SuggestionsSection from "./SuggestionsSection";
import {
    NewEvent,
    createActivityTemplateTimeFragmentFromEvent,
    createMealTemplateTimeFragmentFromEvent,
    getEventsInRangeForMealPlanCalendarState,
    getScheduleKeyForForm,
    getScheduleName,
    isMealPlanCalendarMonthLoading,
    isMealPlanCalendarWeekLoading,
    resolveTemplatesToSchedule,
} from "./utils";

export type CalendarMenuSection = "schedule" | "dateAssignment" | null;

interface MealPlanCalendarFormProps {
  state: MealPlanCalendarState;
  dispatch: Dispatch<MealPlanCalendarAction>;
  renderScheduleMenuSection: (args: { onEditSchedule: () => void }) => ReactNode;
  startDateInPastBehavior: StartDateInPastBehavior;
  initialExpandedMenuSection?: CalendarMenuSection;
}

export const MealPlanCalendarForm = ({
  state,
  dispatch,
  renderScheduleMenuSection,
  startDateInPastBehavior,
  initialExpandedMenuSection,
}: MealPlanCalendarFormProps) => {
  const {
    palette: { teamworksCalendarEvent },
  } = useTheme();
  const clientTimezone = useClientTimezone();
  const { athleteId, type, mealTemplates, activityTemplates, dateAssignment, suggestionsState, lockedDates, otherMealPlanDateAssignments } =
    state;
  const { isAutoSuggestionsEnabled, acceptedSuggestions, rejectedSuggestions } = suggestionsState;

  const [isSuggestionsSectionExpanded, setIsSuggestionsSectionExpanded] = useState(isAutoSuggestionsEnabled);
  const [expandedMenuSection, setExpandedMenuSection] = useState<CalendarMenuSection>(
    isSuggestionsSectionExpanded ? null : initialExpandedMenuSection ?? "schedule"
  );
  const [editScheduleOpen, setEditScheduleOpen] = useState(false);
  const { getMealPlanColor, getNewMealPlanColor } = useMealPlanColorsContext();
  const mealPlanColor = type === "Create" ? getNewMealPlanColor() : getMealPlanColor(state.mealPlanId);
  const displaySettings = {
    disableEditPast: true,
  };

  const { start, end, startOfWeekDate, startOfNextWeek, onBackwardsWeek, onForwardsWeek, onChangeDate } = useMealPlanCalendarContext();
  const { events } = getEventsInRangeForMealPlanCalendarState(state, start, end, clientTimezone);

  const modifiedMealTemplateIds = mealTemplates.flatMap(mt => {
    const { dateModifications, dayOfWeekModifications } = mt;
    return dateModifications.length > 0 || dayOfWeekModifications.length > 0 ? [mt.id] : [];
  });

  // Get teamworks schedule
  const scheduleResult = useExternalCalendarsQuery({
    variables: {
      athleteId,
      start,
      end: startOfNextWeek,
    },
  });

  const externalEventsLoading = scheduleResult.loading;
  const twEvents = scheduleResult.data?.teamworksCalendarForAthleteInRange.events ?? [];
  const twCalendarEvents = getTeamworksCalendarAsCalendarEvents(twEvents);

  // Get meal event suggestions
  const getNewMealSuggestions = () => {
    return getSuggestedEvents({
      twCalendarEvents,
      events,
      mealPlanId: type === "Create" ? null : state.mealPlanId,
      modifiedMealTemplateIds,
    });
  };
  const suggestedEvents = isSuggestionsSectionExpanded ? getNewMealSuggestions() : [];

  // Resolve events with suggestions
  const resolvedEvents = resolveEventsWithSuggestions(
    isAutoSuggestionsEnabled,
    events,
    suggestedEvents,
    acceptedSuggestions.map(e => getKeyForSuggestion(e)),
    rejectedSuggestions.map(e => getKeyForSuggestion(e))
  );
  const calendarEvents = [...resolvedEvents, ...twCalendarEvents];

  // Build Schedule Key
  const schedules = getScheduleKeyForForm({
    state,
    teamworksCalendarEventColor: teamworksCalendarEvent,
    curMealPlanColor: mealPlanColor,
    getMealPlanColor,
    twCalendarEvents,
  });

  const handleClickMenuSection = (clickedMenuSection: "schedule" | "dateAssignment") => {
    setExpandedMenuSection(expandedMenuSection === clickedMenuSection ? null : clickedMenuSection);
  };

  const handleDeleteEvent = (eventId: string, eventType: TemplateEventType) => {
    const schedule = resolveTemplatesToSchedule(mealTemplates, activityTemplates);
    let payload: EditSchedule["payload"];

    if (eventType === "MealTemplate") {
      const { mealTemplates, ...rest } = schedule;
      payload = { ...rest, mealTemplates: mealTemplates.filter(mt => mt.id !== eventId) };
    } else {
      const { activityTemplates, ...rest } = schedule;
      payload = { ...rest, activityTemplates: activityTemplates.filter(at => at.id !== eventId) };
    }

    dispatch({
      type: "EditSchedule",
      payload,
    });
  };

  const handleAddEventSchedule = (event: NewEvent) => {
    let schedule = resolveTemplatesToSchedule(mealTemplates, activityTemplates);
    let payload: EditSchedule["payload"];

    if (event.__typename === "Meal") {
      const newTemplate = createMealTemplateTimeFragmentFromEvent(event);
      const { mealTemplates, ...rest } = schedule;
      payload = { ...rest, mealTemplates: mealTemplates.concat(newTemplate) };
    } else {
      const newTemplate = createActivityTemplateTimeFragmentFromEvent(event);
      const { activityTemplates, ...rest } = schedule;
      payload = { ...rest, activityTemplates: activityTemplates.concat(newTemplate) };
    }

    dispatch({
      type: "EditSchedule",
      payload,
    });
  };

  const handleAddEventDate = (date: Date) => {
    const isoDate = serializeDate(date);

    if (dateAssignment.mode === "individual") {
      if (!dateAssignment.individualDates.includes(isoDate)) {
        dispatch({
          type: "ToggleIndividualDateAssignment",
          payload: {
            date: isoDate,
          },
        });
      }
    } else {
      let dayOfWeek = getDayOfWeek(isoDate);
      let dayOfWeekPriorities = dateAssignment.dayOfWeekPriorities;
      const matchingDayOfWeek = dayOfWeekPriorities.find(d => d.dayOfWeek === dayOfWeek);
      if (!matchingDayOfWeek) {
        dispatch({
          type: "ToggleDayOfWeekAssignment",
          payload: {
            dayOfWeek: dayOfWeek,
          },
        });
      }
    }
  };

  const handleAcceptSuggestion = (eventKey: string) => {
    const suggestion = suggestedEvents.find(e => getKeyForSuggestion(e) === eventKey);
    if (suggestion) {
      dispatch({
        type: "AddAcceptedSuggestions",
        payload: {
          suggestions: [suggestion],
        },
      });
    }
  };

  const handleRejectSuggestion = (eventKey: string) => {
    const suggestion = suggestedEvents.find(e => getKeyForSuggestion(e) === eventKey);
    if (suggestion) {
      dispatch({
        type: "AddRejectedSuggestions",
        payload: {
          suggestions: [suggestion],
        },
      });
    }
  };

  const handleRevertWeek = () => {
    const startOfNextWeekDate = new Date(startOfNextWeek);
    // remove this weeks ids from both accepted and rejected suggestions
    dispatch({
      type: "RevertSuggestions",
      payload: {
        suggestionKeys: suggestedEvents
          .filter(e => e.start >= startOfWeekDate && e.start < startOfNextWeekDate)
          .map(e => getKeyForSuggestion(e)),
      },
    });
  };

  const handleAcceptWeek = () => {
    // get suggested events for this week that haven't been rejected or accepted yet
    const startOfNextWeekDate = new Date(startOfNextWeek);
    const suggestionsForWeek = suggestedEvents.filter(
      e =>
        e.start >= startOfWeekDate &&
        e.start < startOfNextWeekDate &&
        !acceptedSuggestions.find(as => getKeyForSuggestion(as) === getKeyForSuggestion(e)) &&
        !rejectedSuggestions.find(rs => getKeyForSuggestion(rs) === getKeyForSuggestion(e))
    );
    dispatch({
      type: "AddAcceptedSuggestions",
      payload: {
        suggestions: suggestionsForWeek,
      },
    });
  };

  const handleToggleSuggestionSection = () => {
    const isSectionExpanded = !isSuggestionsSectionExpanded;
    setIsSuggestionsSectionExpanded(isSectionExpanded);
    setExpandedMenuSection(isSectionExpanded ? null : "schedule");

    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Toggled Time Suggestions", { timeSuggestionsEnabled: isSectionExpanded });

    // disable auto suggestions and clear accepted/rejected if section is toggled off
    if (!isSectionExpanded) {
      // add accepted suggestions as modifications if any exist
      if (acceptedSuggestions.length > 0) {
        dispatch({
          type: "AddAcceptedSuggestionsAsModifications",
        });
      }

      dispatch({
        type: "ToggleAutoSuggestions",
        payload: {
          isAutoSuggestionsEnabled: false,
        },
      });
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Box
        sx={{
          // Based on MUI calendar width to prevent jumpy LHS
          minWidth: 332,
          flexGrow: 0,
          pr: 1,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <MenuSection
          title="Schedule*"
          expanded={expandedMenuSection === "schedule"}
          onClick={() => {
            handleClickMenuSection("schedule");
          }}
        >
          {renderScheduleMenuSection({ onEditSchedule: () => setEditScheduleOpen(true) })}
          {editScheduleOpen && (
            <EditScheduleDialog
              open={editScheduleOpen}
              onClose={() => setEditScheduleOpen(false)}
              scheduleName={getScheduleName(state)}
              onDone={payload =>
                dispatch({
                  type: "EditSchedule",
                  payload,
                })
              }
              calendarState={state}
            />
          )}
        </MenuSection>
        <MenuSection
          title="Date Assignment*"
          expanded={expandedMenuSection === "dateAssignment"}
          onClick={() => handleClickMenuSection("dateAssignment")}
        >
          <Box
            sx={{
              maxHeight: 320,
              overflowY: "auto",
            }}
          >
            <DateAssignment
              state={dateAssignment}
              dispatch={dispatch}
              onClickCalendarDate={onChangeDate}
              startDateInPastBehavior={startDateInPastBehavior}
              isLoading={startOfMonthDate => isMealPlanCalendarMonthLoading(state, startOfMonthDate)}
              otherMealPlanDateAssignments={otherMealPlanDateAssignments}
              mealPlanColor={mealPlanColor}
            />
          </Box>
        </MenuSection>
        <SuggestionsSection
          isSectionExpanded={isSuggestionsSectionExpanded}
          startOfWeekDate={startOfWeekDate}
          startOfNextWeekDate={new Date(startOfNextWeek)}
          state={suggestionsState}
          suggestedEvents={suggestedEvents}
          hasMealModifications={modifiedMealTemplateIds.length > 0}
          onRevertWeek={handleRevertWeek}
          onAcceptWeek={handleAcceptWeek}
          dispatch={dispatch}
          onToggleSection={handleToggleSuggestionSection}
        />
        <ScheduleKey sx={{ mt: 1 }} entries={schedules} />
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ ml: 2 }} />
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <CalendarWeekNavigation
            sx={{ ml: 8.5, pb: 1 }}
            startOfWeekDate={startOfWeekDate}
            onForwards={onForwardsWeek}
            onBackwards={onBackwardsWeek}
            typographyProps={{ variant: "h3" }}
          />
          <Box sx={{ display: "flex" }}>
            <ExternalCalendarStatus
              isLoading={externalEventsLoading}
              isErrored={Boolean(scheduleResult.error)}
              isPartialSuccess={Boolean(scheduleResult.data?.teamworksCalendarForAthleteInRange.isPartialSuccess)}
            />
            <CurrentMealPlanStatus mealPlanName={type === "Edit" ? state.mealPlanName : undefined} mealPlanColor={mealPlanColor} />
          </Box>
        </Box>
        <CalendarWeek
          sx={{ flexGrow: 1, overflowY: "auto" }}
          startOfWeek={start}
          displaySettings={displaySettings}
          events={calendarEvents}
          clientTimezone={clientTimezone}
          renderEvent={{
            renderPaper: (event, args) => <EventPaper event={event} args={getEventArgsForEvent(args, event, state, events)} />,
            renderPopover: (event, args) => {
              if (event.type === "Teamworks") {
                return <TeamworksEventPopoverPreview
                  key={event.id}
                  event={event}
                  anchorEl={args.anchorEl}
                  onClose={args.onClose} />;
              } else if (event.type === "SuggestedMealTemplate") {
                return (
                  <SuggestionEventPopoverPreview
                    key={event.oldId}
                    event={event}
                    anchorEl={args.anchorEl}
                    onAccept={handleAcceptSuggestion}
                    onReject={handleRejectSuggestion}
                    onClose={args.onClose}
                  />
                );
              }

              return args.action === "click" ? (
                <NotemealEventPopoverPreview
                  event={event}
                  anchorEl={args.anchorEl}
                  onDelete={
                    type === "Edit" && event.mealPlanId === state.mealPlanId
                      ? (eventId, eventType) => {
                          handleDeleteEvent(eventId, eventType);
                        }
                      : undefined
                  }
                  onClose={args.onClose}
                />
              ) : (
                <EventPopoverNewTime
                  event={event}
                  anchorEl={args.anchorEl}
                  onClose={args.onClose}
                  newStart={args.newStart}
                  assignmentMode={dateAssignment.mode}
                  isAutoSuggestionsEnabled={isAutoSuggestionsEnabled}
                  onDone={payload =>
                    dispatch({
                      type: "EditEventTime",
                      payload,
                    })
                  }
                />
              );
            },
          }}
          renderNewEvent={{
            renderPaper: (newEvent, args) => <EventPaperNew
              newEvent={newEvent}
              mealPlanColor={mealPlanColor}
              args={args} />,
            renderPopover: (newEvent, { anchorEl, onClose }) => {
              const daysOfWeek = dateAssignment.dayOfWeekPriorities.map(d => d.dayOfWeek);
              const individualDates = dateAssignment.individualDates;
              const curDate = serializeDate(newEvent.start);

              const isAssignedDayOfWeek =
                dateAssignment.mode === "weekly" ? daysOfWeek.includes(getDayOfWeek(curDate)) : individualDates.includes(curDate);

              return (
                <EventLoaderNew
                  newEvent={newEvent}
                  anchorEl={anchorEl}
                  isAssignedDayOfWeek={isAssignedDayOfWeek}
                  onClose={onClose}
                  onConfirm={payload => {
                    handleAddEventSchedule(payload);
                    handleAddEventDate(newEvent.start);
                  }}
                />
              );
            },
          }}
          dragSettings={{
            renderEventPaper: (event, args) => <EventPaper event={event} args={args} />,
            canDragEvent: e => {
              const isTemplate = e.type === "MealTemplate" || e.type === "ActivityTemplate";
              const isEditingMealPlan = type === "Edit" ? e.mealPlanId === state.mealPlanId : e.mealPlanId === null;
              return isEditingMealPlan && isTemplate;
            },
          }}
          renderDayColumn={props => <MealPlanCalendarDayColumn
            {...props}
            sx={{ mb: 4 }}
            isLockedDay={lockedDates.includes(props.date)} />}
          renderDateHeader={props => (
            <MealPlanCalendarDateHeader
              {...props}
              overridingMealPlanName={type === "Edit" ? getOverridingMealPlanName(state, props.date, events) : undefined}
              displaySettings={displaySettings}
            />
          )}
          loading={isMealPlanCalendarWeekLoading(state, startOfWeekDate)}
        />
      </Box>
    </Box>
  );
};

const isEventForCurrentMealPlan = (event: MealPlanCalendarEvent, type: "Create" | "Edit", mealPlanId: string | null): boolean => {
  if (type === "Create") {
    const isMealPlanEvent = isMealTemplateCalendarEvent(event) || isActivityTemplateCalendarEvent(event);
    return isMealPlanEvent && event.mealPlanId === null;
  } else {
    return event.mealPlanId === mealPlanId;
  }
};

const getOverridingMealPlanName = (
  state: EditMealPlanCalendarState,
  event_date: string,
  events: readonly MealPlanCalendarEvent[]
): string | undefined => {
  return getOverridingMealPlanNameWithMealPlanId(
    event_date,
    state.otherMealPlanDateAssignments,
    state.mealPlanId,
    events.filter(e => e.mealPlanId === state.mealPlanId).map(e => serializeDate(e.start))
  );
};

const getEventArgsForEvent = (
  _args: RenderCalendarEventArgs,
  event: MealPlanCalendarEvent,
  state: MealPlanCalendarState,
  events: readonly MealPlanCalendarEvent[]
): RenderCalendarEventArgs => {
  const isDisabled = !isEventForCurrentMealPlan(event, state.type, state.type === "Create" ? null : state.mealPlanId);
  const args = {
    ..._args,
    eventStyle: {
      ..._args.eventStyle,
      ...(isDisabled ? { opacity: 0.5 } : {}),
    },
  };

  if (state.type === "Edit") {
    return getEventArgsForEventWithMealPlanId(
      args,
      event,
      state.otherMealPlanDateAssignments,
      state.mealPlanId,
      events.filter(e => e.mealPlanId === state.mealPlanId).map(e => serializeDate(e.start))
    );
  } else {
    return args;
  }
};
