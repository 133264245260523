import { Box } from "@mui/material";
import { MealPlanFoodPreferenceContextProvider, useClientTimezone } from "@notemeal/shared-ui";
import { Macros } from "@notemeal/shared-utils-macro-protocol";
import { PreviewMealPlanFragment, useEditMealPlanDateMutation, useRemoveMealPlanDateMutation } from "../../../../types";
import { evictAthleteTimelineInCache } from "../cache";
import { useTimelineForDatePayload } from "../useTimelineForDate";
import { getTimelineItemsForDate, getTimelineMealServingAmounts } from "../utils";
import ExpandedView from "./ExpandedView";
import { TimelineDateColumnHeader } from "./TimelineDateColumnHeader";

export interface AthleteTimelineDateColumnContentProps {
  athleteId: string;
  date: string;
  datePayload: useTimelineForDatePayload;
  athleteBirthdate: string | null;
  athleteMealPlans: readonly PreviewMealPlanFragment[];
  targetDailyMacros: Macros | null;
  gridColumn: number;
  onChangeDate: (date: string | null) => void;
}

const AthleteTimelineDateColumnContent = ({
  athleteId,
  athleteBirthdate,
  athleteMealPlans,
  targetDailyMacros,
  gridColumn,
  date,
  datePayload,
  onChangeDate,
}: AthleteTimelineDateColumnContentProps) => {
  const clientTimezone = useClientTimezone();
  const { data, loading } = datePayload;

  const timelineDate = data?.athlete.timelineDate;
  const mealPlan = timelineDate?.mealPlan ?? null;
  const meals = timelineDate?.meals ?? [];
  const mealMenusOnDate = data?.athlete.mealMenusOnDate || [];

  const items = timelineDate ? getTimelineItemsForDate(timelineDate, mealMenusOnDate) : [];

  const allServingAmounts = meals.flatMap(meal => (meal.__typename === "TimelineMeal" ? getTimelineMealServingAmounts(meal) : []));

  // MealPlanDate mutations
  const [editMealPlanDate] = useEditMealPlanDateMutation({
    update: (cache, { data }) => {
      if (data) {
        evictAthleteTimelineInCache({
          athleteId,
          timezone: clientTimezone,
          date,
          cache,
        });
      }
    },
  });
  const handleEditMealPlanDate = (mealPlanId: string) => {
    editMealPlanDate({
      variables: {
        input: { date, mealPlanId, athleteId, timezone: clientTimezone },
      },
    });
  };

  const [removeMealPlanDate] = useRemoveMealPlanDateMutation({
    update: (cache, { data }) => {
      if (data) {
        evictAthleteTimelineInCache({
          athleteId,
          timezone: clientTimezone,
          date,
          cache,
        });
      }
    },
  });
  const handleRemoveMealPlanDate = () => {
    removeMealPlanDate({
      variables: { input: { date, athleteId } },
    });
  };

  return (
    <MealPlanFoodPreferenceContextProvider
      promotedFoods={mealPlan?.promotedFoods || []}
      avoidedFoods={mealPlan?.avoidedFoods || []}
      promotedFoodGroups={mealPlan?.promotedFoodGroups || []}
      avoidedFoodGroups={mealPlan?.avoidedFoodGroups || []}
    >
      <Box sx={{ p: 1, gridColumnStart: gridColumn + 1, gridRowStart: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TimelineDateColumnHeader
            athleteBirthdate={athleteBirthdate}
            athleteMealPlans={athleteMealPlans}
            athleteId={athleteId}
            mealPlan={mealPlan}
            loading={loading}
            date={date}
            onChangeDate={onChangeDate}
            allServingAmounts={allServingAmounts}
            targetDailyMacros={targetDailyMacros}
            onEditMealPlanDate={handleEditMealPlanDate}
            onRemoveMealPlanDate={handleRemoveMealPlanDate}
          />
          <ExpandedView
            date={date}
            athleteId={athleteId}
            items={items} />
        </Box>
      </Box>
    </MealPlanFoodPreferenceContextProvider>
  );
};

export default AthleteTimelineDateColumnContent;
