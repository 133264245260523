import { UnitWithConversionsFragment, UsdaFoodDetailFragment, UsdaFoodNutrientFragment } from "apps/web/src/types";
import { BaseFoodFormType } from "./FoodFormSchema";
import { UseFormReturn } from "react-hook-form";
import { createNewFoodServingState } from "./foodServingsUtils";
import { convertVitaminDToMcg } from "@notemeal/shared-ui";
import { round } from "@notemeal/shared-ui";
import { getWeightAndAmountFromImportedServing } from "../../Serving/Units/utils";
import { standardizeUnitsState } from "@notemeal/unit-utils";
import { FORM_NUTRIENTS, NutrientAmountState } from "@notemeal/shared-ui";
import { FoodDataSource } from "@notemeal/graphql-types";

interface SetUsdaFoodProps {
  usdaFdcDescription: string;
  usdaFdcId: number;
  usdaFdcDataType: string;
  form: UseFormReturn<BaseFoodFormType>;
}

interface SelectUsdaFoodDetailsProps {
  form: UseFormReturn<BaseFoodFormType>;
  units: readonly UnitWithConversionsFragment[];
  gram: UnitWithConversionsFragment;
  usdaFood: UsdaFoodDetailFragment;
}

export const deselectUsdaFood = (form: UseFormReturn<BaseFoodFormType>) => {
  const { setValue } = form;
  setValue("usdaFdcId", null);
  setValue("usdaFdcDescription", null);
  setValue("usdaFdcFoodCategoryId", null);
  setValue("usdaFdcDataType", null);
  setValue("source", FoodDataSource.manual);
};

export const setUsdaFood = ({ usdaFdcId, usdaFdcDataType, usdaFdcDescription, form }: SetUsdaFoodProps) => {
  const { setValue, getValues } = form;
  const { mode, name } = getValues();

  if (mode === "restaurant") {
    return;
  }

  const formattedName = usdaFdcDescription
    ? usdaFdcDescription
        .split(" ")
        .map(w => (w.length > 0 ? w[0].toUpperCase() + w.substr(1).toLowerCase() : w))
        .join(" ")
    : "";

  const newName = name ?? formattedName;

  setValue("name", newName);
  setValue("usdaFdcId", usdaFdcId);
  setValue("usdaFdcDataType", usdaFdcDataType);
  setValue("usdaFdcDescription", usdaFdcDescription);
  setValue("usdaFdcFoodCategoryId", null);
  setValue("source", FoodDataSource.usda);
};

export const selectUsdaFoodDetails = ({ form, usdaFood, units, gram }: SelectUsdaFoodDetailsProps) => {
  const { setValue, getValues } = form;

  const { servings: oldServings } = getValues();
  const { foodNutrients, cho, pro, fat, usdaFdcDataType } = usdaFood;

  setValue("type", usdaFdcDataType === "Branded" ? "BrandedFood" : "GenericFood");
  setValue("choPer100g", cho.amount);
  setValue("proPer100g", pro.amount);
  setValue("fatPer100g", fat.amount);

  // get new nutrient amounts
  const nutrientAmounts = foodNutrients.map(({ id, amount, min, max, median, footnote, dataPoints }) => ({
    fdcNutrientId: Number(id),
    amount,
    footnote,
    min,
    max,
    median,
    dataPoints,
  }));
  setValue("nutrientAmounts", nutrientAmounts);
  setValue("nutrientAmountsManualEntry", nutrientAmountsFromUSDAForState(foodNutrients, 100));

  const newServings =
    oldServings.length === 1
      ? // Fill in blank servings form
        getNewServingsWithUsdaFood(usdaFood, gram, units)
      : // Merge Usda food with existing servings
        oldServings.map(serving => {
          const weight = (serving.weight || 0) / (serving.defaultAmount || 1);
          return {
            ...serving,
            nutrientAmounts: nutrientAmountsFromUSDAForState(foodNutrients, weight),
            cho: (cho.amount * weight * (serving.defaultAmount || 1)) / 100,
            pro: (pro.amount * weight * (serving.defaultAmount || 1)) / 100,
            fat: (fat.amount * weight * (serving.defaultAmount || 1)) / 100,
          };
        });
  setValue("servings", newServings);
};

/**
 * Creates new servings using usda food
 */
const getNewServingsWithUsdaFood = (
  usdaFood: UsdaFoodDetailFragment,
  gram: UnitWithConversionsFragment,
  units: readonly UnitWithConversionsFragment[]
) => {
  const { servings: usdaServings, foodNutrients, cho, pro, fat } = usdaFood;

  return usdaServings.map((serving, i) => {
    const standardizedUnits = standardizeUnitsState(
      {
        customUnits: serving.units,
        unit: null,
        unitPrefix: null,
        unitSuffix: null,
      },
      units || []
    );
    const { weight, defaultAmount } = getWeightAndAmountFromImportedServing({
      _weight: serving.weight,
      _defaultAmount: serving.defaultAmount,
      unitsState: standardizedUnits,
      units: units || [],
      gram,
    });
    return {
      ...createNewFoodServingState(i === 0),
      units: standardizedUnits,
      weight,
      defaultAmount,
      cho: (cho.amount * weight * serving.defaultAmount) / 100,
      pro: (pro.amount * weight * serving.defaultAmount) / 100,
      fat: (fat.amount * weight * serving.defaultAmount) / 100,
      nutrientAmounts: nutrientAmountsFromUSDAForState(foodNutrients, weight),
    };
  });
};

const nutrientAmountsFromUSDAForState = (nutrientAmounts: readonly UsdaFoodNutrientFragment[], weight: number): NutrientAmountState => {
  let nutrientAmountsForState: NutrientAmountState = {};
  FORM_NUTRIENTS.forEach(nut => {
    const value = maybeNutrientValueFromUSDANutrients(nutrientAmounts, nut, weight);
    nutrientAmountsForState[nut] = round(value).toString();
  });
  return nutrientAmountsForState;
};

const maybeNutrientValueFromUSDANutrients = (
  nutrients: readonly UsdaFoodNutrientFragment[],
  nutrient: (typeof FORM_NUTRIENTS)[number],
  weight: number
): number => {
  const nutrientAmount = nutrients.find(nut => nut.name === nutrient)?.amount || 0;
  const convertedValue = nutrient === "Vitamin D (D2 + D3), International Units" ? convertVitaminDToMcg(nutrientAmount) : nutrientAmount;
  return convertedValue * (weight / 100);
};
