import { Tooltip } from "@mui/material";
import { environment } from "apps/web/src/environment";
import { UseFormReturn } from "react-hook-form";
import {
  UnitWithConversionsFragment,
  UsdaFood,
  UsdaFoodsComponent,
  UsdaFoodsQuery,
  UsdaFoodsQueryVariables,
  useUsdaFoodDetailsLazyQuery,
} from "../../../../types";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import SearchBarWithChip from "../../../universal/SearchBar/WithChip";
import { renderSuggestion as renderSuggestionDefault, renderSuggestionProps } from "../../../universal/SearchBar/utils";
import { BaseFoodFormType } from "../../FoodFormSchema/FoodFormSchema";
import { deselectUsdaFood, selectUsdaFoodDetails, setUsdaFood } from "../../FoodFormSchema/usdaFoodUtils";

const usdaFoodToName = (food: UsdaFood): string => {
  const { description, brandOwner } = food;
  return `${description}${!!brandOwner ? ` (${brandOwner})` : ""}`;
};

interface UsdaSearchBarProps {
  form: UseFormReturn<BaseFoodFormType>;
  unitsData: readonly UnitWithConversionsFragment[];
  gramUnit: UnitWithConversionsFragment;
  setIsLoading: (isLoading: boolean) => void;
  onChange?: (searchTerm: string) => void;
  disabled?: boolean;
}

export const UsdaSearchBar = ({ form, unitsData, gramUnit, setIsLoading, onChange, disabled }: UsdaSearchBarProps) => {
  const { setMessage } = useSnackbar();
  const { watch } = form;
  const [watchedusdaFdcDescription] = watch(["usdaFdcDescription"]);

  const [usdaFoodDetailQuery] = useUsdaFoodDetailsLazyQuery({
    onCompleted: data => {
      selectUsdaFoodDetails({ form, units: unitsData, gram: gramUnit, usdaFood: data.usdaFoodDetail });
      setIsLoading(false);
    },
    onError: e => setMessage("error", e.message),
  });

  const onSelectFood = (food: UsdaFood) => {
    setUsdaFood({ form, usdaFdcDescription: food.description, usdaFdcId: Number(food.id), usdaFdcDataType: food.dataType });
    setIsLoading(true);

    usdaFoodDetailQuery({
      variables: {
        usdaFdcId: food.id,
        apiKey: environment.VITE_USDA_API_KEY,
      },
    });
  };

  const onDeselectFood = () => {
    deselectUsdaFood(form);
  };

  const inputToQueryVariables = (inputValue: string) => {
    onChange && onChange(inputValue);
    return {
      searchTerm: inputValue,
      apiKey: environment.VITE_USDA_API_KEY,
      brandOwner: "",
      includedDataTypes: ["Survey (FNDDS)", "SR Legacy"].join(","),
    };
  };

  const renderSuggestionWithTooltip = ({ value, ...props }: renderSuggestionProps<UsdaFood>, idx: number, inputValue: string | null) => (
    <Tooltip title={value} key={props.key}>
      {renderSuggestionDefault({ value, ...props }, idx, inputValue)}
    </Tooltip>
  );

  return (
    <SearchBarWithChip<UsdaFood, UsdaFoodsQuery, UsdaFoodsQueryVariables>
      fullWidth
      disabled={disabled}
      queryComponent={UsdaFoodsComponent}
      debounce
      label="Link to USDA Food"
      objectToName={usdaFoodToName}
      selectedChipLabel={watchedusdaFdcDescription}
      handleDelete={() => onDeselectFood()}
      sx={{ maxHeight: "300px" }}
      inputToQueryVariables={inputToQueryVariables}
      queryResultToObjects={data => [...data.usdaFoods]}
      onSelectObject={food => onSelectFood(food)}
      renderSuggestion={(props: renderSuggestionProps<UsdaFood>, idx: number, inputValue: string | null) =>
        props.value.length < 45 ? renderSuggestionDefault(props, idx, inputValue) : renderSuggestionWithTooltip(props, idx, inputValue)
      }
    />
  );
};
