import LeftIcon from "@mui/icons-material/NavigateBefore";
import { Dialog, DialogContent, DialogTitle, IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AddMenuOrderPayload, Loading, useClientTimezone } from "@notemeal/shared-ui";
import { formatTimeRangeInTimezone } from "@notemeal/utils-date-time";
import React from "react";
import Content from "../../../../components/MenuOrder/NewDialogContent";
import {
  KioskMealMenuPreviewFragment,
  MealTemplateForTargetsFragment,
  MenuOrderFormFragment,
  useMealMenuDialogQuery,
} from "../../../../types";
import { AthleteOrderStateAction, OrderFormAthleteOrderState } from "../orderStateReducer";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    paper: {
      height: `calc(100% - 64px)`,
    },
    leftTitle: {
      alignItems: "center",
      display: "flex",
    },
    backButton: {
      marginRight: theme.spacing(),
    },
    content: {
      padding: 0,
    },
  })
);

interface KioskMealMenuDialogProps {
  onAddMenuOrder: (payload: AddMenuOrderPayload, mealMenuId: string, mealTemplateId: string | null) => void;
  mealMenu: KioskMealMenuPreviewFragment;
  mealTemplate: MealTemplateForTargetsFragment | null;
  viewOnly: boolean;
  open: boolean;
  onClose: () => void;
  athleteName: string;
  teamId: string;
  athleteId: string;
  athleteUserId?: string;
  avoidFoodGroupIds: string[];
  menuOrders: readonly MenuOrderFormFragment[];
  athleteOrderState: OrderFormAthleteOrderState;
  athleteOrderStateDispatch: React.Dispatch<AthleteOrderStateAction>;
}

const KioskMealMenuDialog = ({
  onAddMenuOrder,
  mealMenu,
  mealTemplate,
  open,
  onClose,
  avoidFoodGroupIds,
  athleteName,
  teamId,
  athleteId,
  athleteUserId,
  menuOrders,
  athleteOrderState,
  athleteOrderStateDispatch,
  viewOnly,
}: KioskMealMenuDialogProps) => {
  const classes = useStyles();
  const clientTimezone = useClientTimezone();
  const { data } = useMealMenuDialogQuery({
    variables: {
      mealMenuId: mealMenu.id,
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen>
      <DialogTitle className={classes.title}>
        <div className={classes.leftTitle}>
          <IconButton
            size="small"
            onClick={onClose}
            className={classes.backButton}>
            <LeftIcon fontSize="large" />
          </IconButton>
          <Typography variant="h3">
            {mealMenu.name} (
            {formatTimeRangeInTimezone(mealMenu.start, mealMenu.durationInMinutes, mealMenu.timezone, {
              excludeTimezoneSuffix: mealMenu.timezone === clientTimezone,
            })}
            )
          </Typography>
        </div>
        <Typography variant="h3" color="textSecondary">
          Ordering for {athleteName}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {!data ? (
          <Loading />
        ) : (
          <Content
            viewOnly={viewOnly}
            onAddMenuOrder={onAddMenuOrder}
            mealMenu={mealMenu}
            mealTemplate={mealTemplate}
            teamId={teamId}
            onClose={onClose}
            mealMenuDiningStations={data.mealMenu.mealMenuDiningStations}
            avoidFoodGroupIds={avoidFoodGroupIds}
            athleteId={athleteId}
            athleteUserId={athleteUserId}
            athleteOrderState={athleteOrderState}
            menuOrders={menuOrders}
            athleteOrderStateDispatch={athleteOrderStateDispatch}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default KioskMealMenuDialog;
