import { datadogRum } from "@datadog/browser-rum";
import { MaybeJwtUser } from "@notemeal/shared-ui";
import { getOrgUserDetails } from "../pages/Auth/OrgUtils";
import { ReportingOrgFragment, ReportingUserFragment } from "../types";
import { ReportingEvent } from "./events";
import { initDatadogRum } from "@notemeal/reporting-datadog-rum";
import { environment } from "../environment";

const gainsightPxTag = `
(function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
    (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
  var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
  var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
})(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js","${environment.VITE_GAINSIGHT_PX_KEY_TAG}");
`;

// Axle integration todo: update IDs per this example: https://www.notion.so/teamworksoss/Teamworks-Gainsight-Implementation-Guide-12e6e70bf842495084e4e11f893cec1f?pvs=4

export const initReporting = async () => {
  // Inject Gainsight PX script
  const gainsightPxScript = document.createElement("script");
  gainsightPxScript.textContent = gainsightPxTag;
  document.body.appendChild(gainsightPxScript);

  if (environment.VITE_DATADOG_RUM_ENABLED === "true") {
    initDatadogRum({
      applicationId: "44bdbca4-8dcf-42b7-b844-102aa58eefbe",
      clientToken: "pub88d883e6b6b4db3d43e5bc9202ef7c52",
      service: "notemeal-web",
      version: environment.VITE_COMMIT_HASH,
      commit: environment.VITE_COMMIT_HASH,
      datadogEnv: environment.VITE_DATADOG_ENV,
      sessionReplaySampleRate: 100,
    });
  }
};

export const clearReportingUser = () => {
  window['aptrinsic']("reset");
  datadogRum.setUser({});
};

export const setReportingUser = (
  jwtUser: MaybeJwtUser,
  reportingUser: ReportingUserFragment,
  reportingOrg: ReportingOrgFragment | null,
  forStaff: boolean
) => {
  const { userId: id, userName, isStaff, orgName } = getOrgUserDetails(jwtUser);
  const roles = jwtUser?.orgMembership?.roles || [];
  const twnOrgId = forStaff ? "staff" : reportingOrg?.id;

  datadogRum.setUser({
    id,
    name: userName,
    isStaff,
    orgName,
  });

  window['aptrinsic'](
    "identify",
    {
      id: `twn_${reportingUser.id}_${twnOrgId}`, // prefixing ids with twn to avoid confustion with axel ids in the future
      nutritionUserId: reportingUser.id,
      email: reportingUser.email,
      firstName: reportingUser.firstName,
      lastName: reportingUser.lastName,
      nutritionUsername: jwtUser?.username,
      nutritionUserRole: roles.sort().join(", "),
      nutritionHubProfileId: jwtUser?.orgMembership?.teamworksId,
      nutritionIsActive: reportingUser.isActive,
      nutritionIsSuperuser: jwtUser?.isSuperuser,
      signUpDate: reportingUser.dateJoined,
    },
    {
      id: `twn_${twnOrgId}`, // prefixing required ids with twn to avoid confustion with axel ids in the future
      name: reportingOrg?.name,
      nutritionHubOrgId: reportingOrg?.teamworksId,
      nutritionOrgTerritory: reportingOrg?.territoryName,
      twnHasPK: reportingOrg?.performanceKitchen,
    }
  );
};

export const trackEvent = <E extends keyof ReportingEvent>(eventName: E, payload: ReportingEvent[E]) => {
  window['aptrinsic']("track", eventName, payload);
  datadogRum.addAction(eventName, payload);
};
