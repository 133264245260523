import { DocumentNode, useApolloClient } from "@apollo/client";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Drawer, IconButton, Typography, useTheme } from "@mui/material";
import { useClientTimezone, useDateFormatting } from "@notemeal/shared-ui";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import LoadingButton from "apps/web/src/components/universal/LoadingButton";
import { useOffsetPagination } from "apps/web/src/utils/pagination";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { useState } from "react";
import {
    EducationResourceEventOffsetConnectionDocument,
    EducationResourceEventOffsetConnectionQuery,
    EducationResourceEventOffsetConnectionQueryVariables,
    EducationResourceTableItemFragment,
    OffsetPaginationInput,
    useEducationResourceEventOffsetConnectionQuery,
} from "../../../types";
import { ShareWithCell } from "../ShareWithCell";
import { getResourceInfo } from "../utils";
import { EducationResourceViewHistoryTable } from "./EducationResourceViewHistoryTable";
import { exportViewHistoryColumns, serializeViewHistoryRows } from "./Export/athleteViewHistories";

interface EducationResourceViewHistoryDrawerProps {
  educationResource: EducationResourceTableItemFragment;
  onClose: () => void;
}

const ROWS_PER_PAGE = 10;

const EXPORT_DATA_REQ_CHUNK_SIZE = 50;

export const EducationResourceViewHistoryDrawer = ({ educationResource, onClose }: EducationResourceViewHistoryDrawerProps) => {
  const theme = useTheme();
  const { formatDateInTimezoneWithLocale, formatTimeInTimezoneWithLocale } = useDateFormatting();
  const timezone = useClientTimezone();
  const apolloClient = useApolloClient();
  const { setMessage } = useSnackbar();

  const paginationHooks = useOffsetPagination(ROWS_PER_PAGE);

  const { limit, offset } = paginationHooks;
  const { data, loading } = useEducationResourceEventOffsetConnectionQuery({
    variables: { pagination: { offset, limit }, educationResourceId: educationResource.id },
  });

  const [exportLoading, setExportLoading] = useState(false);

  const hasViews = (data?.educationResourceEventOffsetConnection.edges?.length ?? 0) > 0;

  const fetchDataInChunksSequentially = async <Q, V extends { pagination: OffsetPaginationInput }>(
    total: number,
    queryDoc: DocumentNode,
    getVariables: (pagination: OffsetPaginationInput) => V
  ) => {
    const results: Q[] = [];
    let errored = false;
    const inputs = Array(Math.ceil(total / EXPORT_DATA_REQ_CHUNK_SIZE))
      .fill(0)
      .map((_, i) => {
        return { offset: i * EXPORT_DATA_REQ_CHUNK_SIZE, limit: EXPORT_DATA_REQ_CHUNK_SIZE };
      });

    for (const input of inputs) {
      const { data, errors } = await apolloClient.query<Q, V>({
        query: queryDoc,
        variables: getVariables(input),
      });

      if (errors) {
        errored = true;
        setMessage("error", "Failed to export data");
        break;
      }

      results.push(data);
    }

    return errored ? [] : results;
  };

  async function handleExport() {
    setExportLoading(true);

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();
    worksheet.name = `ViewHistory_${educationResource.name}`;

    worksheet.columns = exportViewHistoryColumns.map(col => ({
      header: col.header,
      key: col.key,
      width: col.width,
    }));

    // Insert an empty row at beginning so that a title can be added
    worksheet.spliceRows(1, 0, []);

    // Add Title/Header to the Worksheet
    const title = `View History for ${educationResource.name} - ${formatDateInTimezoneWithLocale(new Date(), timezone)}`;
    worksheet.mergeCells("A1:E1");
    const titleCell = worksheet.getCell("A1");
    titleCell.value = title;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell.font = { size: 16, bold: true };

    const headerRow = worksheet.getRow(2);
    headerRow.alignment = { horizontal: "center", vertical: "middle" };
    headerRow.font = { bold: true };
    headerRow.height = 15;

    const results = await fetchDataInChunksSequentially<
      EducationResourceEventOffsetConnectionQuery,
      EducationResourceEventOffsetConnectionQueryVariables
    >(data?.educationResourceEventOffsetConnection.pageInfo.total ?? 0, EducationResourceEventOffsetConnectionDocument, pagination => ({
      pagination,
      educationResourceId: educationResource.id,
    }));

    const rows = serializeViewHistoryRows(
      results?.flatMap(r => r.educationResourceEventOffsetConnection.edges ?? []) ?? [],
      formatDateInTimezoneWithLocale,
      formatTimeInTimezoneWithLocale,
      timezone
    );

    // Add Data Rows
    if (rows.length) {
      worksheet.addRows(rows);
      const toExport = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([toExport]), `${worksheet.name}.xlsx`);
    } else {
      console.warn("No rows to export.");
    }

    setExportLoading(false);
  }

  return (
    <Drawer
      anchor="left"
      open
      onClose={onClose}
      PaperProps={{
        sx: { height: "100vh", width: "100vw" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 2,
          borderBottom: `1px solid ${theme.palette.greyscaleLight[300]}`,
          boxShadow: theme.palette.boxShadowMedium,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={onClose}>
              <ArrowBackRoundedIcon sx={{ color: theme.palette.highEmphasisTextLight, fontSize: "32px" }} />
            </IconButton>
            <Typography variant="h2" sx={{ marginLeft: theme.spacing(2) }}>
              Check View History
            </Typography>
          </Box>
          <Box>
            <LoadingButton
              loading={exportLoading}
              buttonText="Export"
              variant="contained"
              onClick={handleExport}
              disabled={!hasViews} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          p: theme.spacing(4),
          gap: theme.spacing(5),
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.greyscaleLight[25],
            boxShadow: theme.palette.boxShadowSmall,
            borderRadius: "6px",
            p: theme.spacing(3),
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
            <Box>
              <Typography variant="h3">{educationResource.name}</Typography>
              <Typography variant="body1" sx={{ color: theme.palette.mediumEmphasisTextLight }}>
                {educationResource.description}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: theme.spacing(8) }}>
              <Box>
                <Typography variant="body2Medium" sx={{ color: theme.palette.mediumEmphasisTextLight }}>
                  Share With
                </Typography>
                <ShareWithCell educationResource={getResourceInfo(educationResource)} />
              </Box>
              <Box>
                <Typography variant="body2Medium" sx={{ color: theme.palette.mediumEmphasisTextLight }}>
                  Uploaded By
                </Typography>
                <Typography variant="h4">{`${educationResource.createdBy?.firstName} ${educationResource.createdBy?.lastName}`}</Typography>
              </Box>
              <Box>
                <Typography variant="body2Medium" sx={{ color: theme.palette.mediumEmphasisTextLight }}>
                  Uploaded Date
                </Typography>
                <Typography variant="h4">{formatDateInTimezoneWithLocale(educationResource.createdAt, timezone)}</Typography>
              </Box>
              <Box>
                <Typography variant="body2Medium" sx={{ color: theme.palette.mediumEmphasisTextLight }}>
                  Total Views
                </Typography>
                <Typography variant="h4">{data?.educationResourceEventOffsetConnection.pageInfo.total}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {!loading && hasViews && (
          <EducationResourceViewHistoryTable
            rows={data?.educationResourceEventOffsetConnection.edges ?? []}
            paginationHooks={paginationHooks}
            loading={loading}
            totalRows={data?.educationResourceEventOffsetConnection.pageInfo.total ?? 0}
            timezone={timezone}
          />
        )}
      </Box>
    </Drawer>
  );
};
