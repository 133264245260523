import { Box } from "@mui/material";
import { ScheduleContainer } from "@notemeal/shared-ui";
import { Activity } from "apps/web/src/components/MealTemplate/Acitivity";
import { EditFullMealPlanFragment } from "apps/web/src/types";
import { MealPlanEditExchangeTargets } from "../../../../components/MealPlan/Edit/Exchange/Targets";
import { EditMacroTargets } from "../../../../components/MealTemplate/Edit/Targets/EditMacroTargets";
import { EditExchangeTargets } from "../../../../components/MealTemplate/Edit/Targets/Exchange/EditExchangeTargets";
import { useMealPlanSideEffects } from "./utils";

interface MealPlanTargetsProps {
  mealPlan: EditFullMealPlanFragment;
}

export const MealPlanTargets = ({ mealPlan }: MealPlanTargetsProps) => {
  useMealPlanSideEffects(mealPlan, "targets");

  const { exchangeSet, exchangeTargets } = mealPlan;
  if (mealPlan.type === "exchange" && exchangeSet && exchangeTargets) {
    const mealTemplates = mealPlan.mealTemplates.flatMap(mt => (mt.__typename === "ExchangeMealTemplate" ? mt : []));
    return (
      <Box sx={{ width: "100%", height: "100%", overflowY: "hidden", display: "flex", flexDirection: "column", gap: 2 }}>
        <MealPlanEditExchangeTargets
          mealPlanId={mealPlan.id}
          exchangeSet={exchangeSet}
          exchangeTargets={exchangeTargets}
          mealTemplates={mealTemplates}
        />
        <ScheduleContainer
          sx={{ overflowY: "auto" }}
          meals={mealTemplates}
          activities={mealPlan.activityTemplates}
          mealToStart={mt => mt.meal.start}
          activityToStart={at => at.activity.start}
          renderMeal={mt => <EditExchangeTargets mealTemplate={mt} exchangeSet={exchangeSet} />}
          renderActivity={at => <Activity activity={at.activity} />}
        />
      </Box>
    );
  } else if (mealPlan.type === "macro") {
    const mealTemplates = mealPlan.mealTemplates.flatMap(mt => (mt.__typename === "MacroMealTemplate" ? mt : []));
    return (
      <ScheduleContainer
        sx={{ maxWidth: 1100 }}
        meals={mealTemplates}
        activities={mealPlan.activityTemplates}
        mealToStart={mt => mt.meal.start}
        activityToStart={at => at.activity.start}
        renderMeal={mt => <EditMacroTargets mealTemplate={mt} />}
        renderActivity={at => <Activity activity={at.activity} />}
      />
    );
  }

  return null;
};
