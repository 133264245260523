import { sortByKey } from "@notemeal/utils-sort";
import { ServingAmountForConversionFragment, UnitConversionFullFragment } from "../../types";

interface getServingAmountsWithConversionsArgs<SA extends ServingAmountForConversionFragment> {
  servingAmounts: readonly SA[];
  unitConversions: readonly UnitConversionFullFragment[];
}

interface ServingAmountConversion {
  __typename: "ServingAmountConversion";
  amount: number;
  position: number;
  foodOrRecipe: {
    id: string;
    name: string;
  };
  unitPrefix: string | null;
  unitSuffix: string | null;
  servingId: string;
  toUnit: {
    id: string;
    name: string;
  };
}

export type ServingAmountWithConversion<SA extends ServingAmountForConversionFragment = ServingAmountForConversionFragment> =
  | SA
  | ServingAmountConversion;

/**
 * Converts a list of serving amounts to standard amounts as best possible given a list of unitConversions
 *
 * If a servingAmount.serving.unit is part of any unitConversions, then it's equivalent ServingAmounts will
 *   conform to the ServingAmountConversion type
 * Otherwise, the original FullServingAmount type will be returned (albiet removing duplicates based on servingId,)
 *
 * Also tries to preserve order as best as possible, substituting the resulting servingAmounts
 *   Into the first position used by any servingAmount grouped by convertible units,
 *   Or using the first instance of a serving (no unit or non-convertible unit)
 *
 * TODO: Currently only looks for whole-integer amounts of units. However, it may be desirable
 *   to allow fraction amounts for some units (i.e. 1/2 cup is preferable to 8 tbsp)
 */
export const getServingAmountsWithConversions = <SA extends ServingAmountForConversionFragment>({
  servingAmounts,
  unitConversions,
}: getServingAmountsWithConversionsArgs<SA>): readonly ServingAmountWithConversion<SA>[] => {
  const unitConversionGroupMap = getUnitConversionGroupMap(unitConversions);

  // 1. Group servingAmounts
  // If servingAmount has a unit in a unitConversionGroup, then group by foodOrRecipeId and unitConversionGroupId
  // Otherwise group by servingId
  const groupedServingAmounts = sortByKey(servingAmounts, "position").reduce<
    Record<
      string,
      | {
          servingId: string;
          unitConversionGroup: null;
          servingAmounts: [SA, ...SA[]];
        }
      | {
          servingId: string;
          unitConversionGroup: UnitConversionGroup;
          foodOrRecipe: {
            id: string;
            name: string;
          };
          unitPrefix: string | null;
          unitSuffix: string | null;
          servingAmounts: [SA, ...SA[]];
        }
      | undefined
    >
  >((servingAmountsGroups, servingAmount) => {
    const { serving } = servingAmount;
    const unitConversionGroup = serving.unit && unitConversionGroupMap.get(serving.unit.id);
    if (!serving.unit || !unitConversionGroup) {
      const matchingServingAmountsGroup = servingAmountsGroups[serving.id];
      if (matchingServingAmountsGroup) {
        return {
          ...servingAmountsGroups,
          [serving.id]: {
            ...matchingServingAmountsGroup,
            unitConversionGroup: null,
            servingAmounts: [servingAmount, ...matchingServingAmountsGroup.servingAmounts],
          },
        };
      } else {
        return {
          ...servingAmountsGroups,
          [serving.id]: {
            unitConversionGroup: null,
            servingId: serving.id,
            servingAmounts: [servingAmount],
          },
        };
      }
    } else {
      const key = `${serving.foodOrRecipe.id}:${serving.unitPrefix || ""}:${serving.unitSuffix || ""}:${unitConversionGroup.groupId}`;
      const matchingServingAmountsGroup = servingAmountsGroups[key];
      if (matchingServingAmountsGroup) {
        return {
          ...servingAmountsGroups,
          [key]: {
            ...matchingServingAmountsGroup,
            servingAmounts: [servingAmount, ...matchingServingAmountsGroup.servingAmounts],
          },
        };
      } else {
        return {
          ...servingAmountsGroups,
          [key]: {
            servingId: serving.id,
            foodOrRecipe: {
              id: serving.foodOrRecipe.id,
              name: serving.foodOrRecipe.name,
            },
            unitPrefix: serving.unitPrefix,
            unitSuffix: serving.unitSuffix,
            unitConversionGroup,
            servingAmounts: [servingAmount],
          },
        };
      }
    }
  }, {});

  // 2. Converts each servingAmountGroup into a list of ServingAmountWithConversion[]
  const convertedServingAmountGroups = Object.values(groupedServingAmounts).map<{
    firstPosition: number;
    servingAmountsWithConversions: readonly ServingAmountWithConversion<SA>[];
  }>(group => {
    if (!group) {
      return {
        firstPosition: 0,
        servingAmountsWithConversions: [],
      };
    }
    const { servingAmounts, servingId } = group;
    const firstPosition = Math.min(...servingAmounts.map(sa => sa.position));

    // 2a. Group is based on servingId
    // Sum up the amounts to consolidate to one servingAmount
    if (!group.unitConversionGroup) {
      // Groups without servingAmountGroups were grouped based on servingIds
      // So combine into one servingAmount by summing up the amounts
      const amount = servingAmounts.reduce((sum, sa) => sum + sa.amount, 0);
      // Filter out amount = 0 servingAmounts
      if (amount === 0) {
        return {
          firstPosition,
          servingAmountsWithConversions: [],
        };
      }
      return {
        firstPosition,
        servingAmountsWithConversions: [
          {
            ...servingAmounts[0],
            position: 1, // Dummy value, gets overriden later
            amount: servingAmounts.reduce((sum, sa) => sum + sa.amount, 0),
          },
        ],
      };
    }

    // 2b. Group is based on unitConversionGroup
    // Calculate the amount needed in terms of the smallest unit in group
    // Then, starting with the biggest unit in group, find largest integer amount that can be used under the remaining amount
    const {
      unitConversionGroup: { sortedUnitScales },
      foodOrRecipe,
      unitPrefix,
      unitSuffix,
    } = group;
    const totalAmountInSmallestUnitScale = servingAmounts.reduce((total, sa) => {
      const matchingUnitScale = sortedUnitScales.find(u => u.unit.id === sa.serving.unit?.id);
      if (!matchingUnitScale) {
        return total;
      }

      return total + sa.amount * matchingUnitScale.fromSmallestUnitScale;
    }, 0);

    // Reverse to start with biggest units. Try to find integer amount for unit that will fit with remaining amount
    const { servingAmountsWithConversions } = [...sortedUnitScales].reverse().reduce<{
      remainingAmount: number;
      servingAmountsWithConversions: readonly ServingAmountWithConversion<SA>[];
    }>(
      ({ remainingAmount, servingAmountsWithConversions }, unitScale, currentIndex) => {
        const amount =
          // Last unit is special case, because all remaining amount must be used by it
          currentIndex === sortedUnitScales.length - 1 ? remainingAmount : Math.floor(remainingAmount / unitScale.fromSmallestUnitScale);

        if (amount === 0) {
          return { remainingAmount, servingAmountsWithConversions };
        }
        return {
          remainingAmount: remainingAmount - amount * unitScale.fromSmallestUnitScale,
          servingAmountsWithConversions: [
            ...servingAmountsWithConversions,
            {
              __typename: "ServingAmountConversion",
              servingId,
              foodOrRecipe,
              unitPrefix,
              unitSuffix,
              toUnit: {
                id: unitScale.unit.id,
                name: unitScale.unit.name,
              },
              amount,
              position: 1, // Dummy value, get's overriden in final step
            },
          ],
        };
      },
      {
        remainingAmount: totalAmountInSmallestUnitScale,
        servingAmountsWithConversions: [],
      }
    );

    return {
      firstPosition,
      servingAmountsWithConversions,
    };
  });

  // 3. Merge converted servingAmount groups, setting position correctly to best
  const { servingAmountsWithConversions } = sortByKey(convertedServingAmountGroups, "firstPosition").reduce<{
    currentPosition: number;
    servingAmountsWithConversions: readonly ServingAmountWithConversion<SA>[];
  }>(
    ({ currentPosition, servingAmountsWithConversions }, { servingAmountsWithConversions: nextServingAmountsWithConversions }) => {
      return {
        currentPosition: currentPosition + nextServingAmountsWithConversions.length,
        servingAmountsWithConversions: [
          ...servingAmountsWithConversions,
          ...nextServingAmountsWithConversions.map((sa, i) => ({
            ...sa,
            position: currentPosition + i,
          })),
        ],
      };
    },
    { currentPosition: 0, servingAmountsWithConversions: [] }
  );
  return servingAmountsWithConversions;
};

interface UnitConversionGroup {
  // A unique identifier for this group (all of the unit ids joined together)
  readonly groupId: string;

  // All of the units sorted in ascending order based on scale
  // Where scale is the unit conversion factor from the first (aka smallest) unit
  readonly sortedUnitScales: readonly [UnitScale, ...UnitScale[]];
}

interface UnitScale {
  unit: {
    id: string;
    name: string;
  };
  fromSmallestUnitScale: number;
}

/**
 * Finds all unitConversionGroups in provided unitConversions
 * For each group with more than one unit, add an entry to the returned map from the unitId to the group it belongs to.
 *
 * Units are grouped together if unit conversions from one another exist with integer from/to quantities
 *
 * Assumes that provided unitConversions has full cartesian product of conversions between units that are possible
 *   (Unit conversions from backend should satisfy this propery, just need to pass them all in)
 */
const getUnitConversionGroupMap = (unitConversions: readonly UnitConversionFullFragment[]): Map<string, UnitConversionGroup> => {
  // 1. Group all conversions that transitively have any units in common
  const groupedConversions = unitConversions.reduce<[UnitConversionFullFragment, ...UnitConversionFullFragment[]][]>(
    (accGroups, nextUnitConversion) => {
      // Ignore conversions with non-integer quantities to keep numbers nice
      if (!Number.isInteger(nextUnitConversion.fromQuantity) || !Number.isInteger(nextUnitConversion.toQuantity)) {
        return accGroups;
      }

      const { matchingGroups, nonMatchingGroups } = accGroups.reduce<{
        matchingGroups: [UnitConversionFullFragment, ...UnitConversionFullFragment[]][];
        nonMatchingGroups: [UnitConversionFullFragment, ...UnitConversionFullFragment[]][];
      }>(
        ({ matchingGroups, nonMatchingGroups }, group) => {
          const allUnitIds = group.flatMap(g => [g.toUnit.id, g.fromUnit.id]);
          const matches = allUnitIds.includes(nextUnitConversion.fromUnit.id) || allUnitIds.includes(nextUnitConversion.toUnit.id);
          if (matches) {
            return {
              matchingGroups: [...matchingGroups, group],
              nonMatchingGroups,
            };
          } else {
            return {
              matchingGroups,
              nonMatchingGroups: [...nonMatchingGroups, group],
            };
          }
        },
        { matchingGroups: [], nonMatchingGroups: [] }
      );

      return [...nonMatchingGroups, [nextUnitConversion, ...matchingGroups.flat()]];
    },
    []
  );

  // 2. Convert each unitConversionGroup into a sorted list of units with their relative scale
  // List should start with smallest unit and scale of 1
  // Each subsequent entry should be the next biggest unit
  //   Where it's scale is its effective conversion factor from the smallest unit
  const sortedUnitScalesGroups = groupedConversions.map<UnitScale[]>(allConversions => {
    // Pick a baseUnit to anchor the comparisions, and
    const baseUnit = allConversions[0].fromUnit;
    // Only include all other units once, by filtering to just conversions from baseUnit
    const conversions = allConversions.filter(c => c.fromUnit.id === baseUnit.id);

    const { sortedUnitScales } = conversions.reduce<{
      sortedUnitScales: UnitScale[];
      // Need to keep track of how much the baseUnit gets scaled up by smaller units to adjust later conversions
      baseUnitScale: number;
    }>(
      ({ sortedUnitScales, baseUnitScale }, nextConversion) => {
        const nextFromSmallestUnitScale = (nextConversion.fromQuantity / nextConversion.toQuantity) * baseUnitScale;
        // Find where this fits in amongst currentUnitScales
        const nextIndex = sortedUnitScales.findIndex(u => {
          return nextFromSmallestUnitScale < u.fromSmallestUnitScale;
        });
        if (nextIndex === 0) {
          // If index equals 0, then this is the new smallest unit
          // Must put this unit at the front and re-scale all of the fromSmallestUnitScale's
          // And set the baseUnitScale appropriately

          return {
            sortedUnitScales: [
              { unit: nextConversion.toUnit, fromSmallestUnitScale: 1 },
              ...sortedUnitScales.map(u => ({
                unit: u.unit,
                fromSmallestUnitScale: u.fromSmallestUnitScale / nextFromSmallestUnitScale,
              })),
            ],
            baseUnitScale: baseUnitScale / nextFromSmallestUnitScale,
          };
        } else if (nextIndex === -1) {
          return {
            sortedUnitScales: [
              ...sortedUnitScales,
              {
                unit: nextConversion.toUnit,
                fromSmallestUnitScale: nextFromSmallestUnitScale,
              },
            ],
            baseUnitScale,
          };
        }

        return {
          sortedUnitScales: [
            ...sortedUnitScales.slice(0, nextIndex),
            {
              unit: nextConversion.toUnit,
              fromSmallestUnitScale: nextFromSmallestUnitScale,
            },
            ...sortedUnitScales.slice(nextIndex),
          ],
          baseUnitScale,
        };
      },
      {
        sortedUnitScales: [{ unit: baseUnit, fromSmallestUnitScale: 1 }],
        baseUnitScale: 1,
      }
    );
    return sortedUnitScales;
  });

  // 3. Turn each sortedUnitScales into a UnitConversionGroup by pairing it with a groupId,
  // and return a Map with an entry for each unit from its .id to its UnitConversionGroup
  return new Map(
    sortedUnitScalesGroups.flatMap((sortedUnitScales): [string, UnitConversionGroup][] => {
      const unitConversionGroup: UnitConversionGroup = {
        groupId: sortedUnitScales.map(u => u.unit.id).join(","),
        sortedUnitScales: [sortedUnitScales[0], ...sortedUnitScales.slice(1)],
      };
      return sortedUnitScales.map(u => [u.unit.id, unitConversionGroup]);
    })
  );
};
