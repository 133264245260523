import { Box, styled, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { isFormDirty } from "apps/web/src/utils/form";
import { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import AnthropometryEntryTypeSelect from "../TypeSelect";
import { MetricAnthropometryEntryType } from "./MetricAnthropometryEntryFormSchema";

const Row = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  gap: spacing(2),
  alignItems: "flex-end",
}));

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) => {
  return createStyles({
    field: {
      flex: "1 1 0",
      width: 0,
      maxWidth: "50%",
    },
  });
});

export interface MetricAnthropometryEntryFormProps {
  form: UseFormReturn<MetricAnthropometryEntryType>;
  hideCommentField?: boolean;
  fauxEditMode?: boolean;
}

export const MetricAnthropometryEntryForm = ({ form, hideCommentField, fauxEditMode }: MetricAnthropometryEntryFormProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = form;
  const classes = useStyles();

  const formType = form.watch("type");
  const isDirty = isFormDirty(form);
  const disableDates = fauxEditMode && !isDirty;

  useEffect(() => {
    if (fauxEditMode && isDirty) {
      setValue("date", new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, fauxEditMode]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="body1Medium">Anthropometry Data</Typography>
      <Row>
        <Controller
          control={control}
          name={"date"}
          render={({ field }) => (
            <DesktopDatePicker
              sx={{ mt: 1 }}
              {...field}
              className={classes.field}
              label="Date"
              slotProps={{
                textField: {
                  error: Boolean(errors.date),
                  helperText: errors.date?.message,
                },
              }}
              disabled={disableDates}
            />
          )}
        />
        <Controller
          control={control}
          name={"type"}
          render={({ field: { value, onChange } }) => (
            <AnthropometryEntryTypeSelect
              className={classes.field}
              selectedType={value}
              onChange={onChange}
              error={Boolean(errors.type)}
              helperText={errors.type?.message}
            />
          )}
        />
        <div className={classes.field} />
      </Row>
      <Row>
        <Controller
          control={control}
          name={"heightInCm"}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ""}
              className={classes.field}
              label="Height (cm)"
              margin="dense"
              error={Boolean(errors.heightInCm)}
              helperText={errors.heightInCm?.message}
            />
          )}
        />

        <Controller
          control={control}
          name={"weightInKg"}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.field}
              margin="dense"
              label={`Weight (kg)`}
              type="number"
              inputProps={{ min: 0 }}
              error={Boolean(errors.weightInKg)}
              helperText={errors.weightInKg?.message}
            />
          )}
        />
      </Row>

      <Row>
        <Controller
          control={control}
          name={"leanBodyMassInKg"}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.field}
              margin="dense"
              label={`Lean Mass (kg)`}
              type="number"
              inputProps={{ min: 0 }}
              error={Boolean(errors.leanBodyMassInKg)}
              helperText={errors.leanBodyMassInKg?.message}
            />
          )}
        />

        <Controller
          control={control}
          name={"bodyFatMassInKg"}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.field}
              margin="dense"
              label={`Fat Mass (kg)`}
              type="number"
              inputProps={{ min: 0 }}
              error={Boolean(errors.bodyFatMassInKg)}
              helperText={errors.bodyFatMassInKg?.message}
            />
          )}
        />

        <Controller
          control={control}
          name={"percentBodyFat"}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.field}
              margin="dense"
              label="% Body Fat"
              type="number"
              inputProps={{ min: 0, max: 100, step: 0.1 }}
              error={Boolean(errors.percentBodyFat)}
              helperText={errors.percentBodyFat?.message}
            />
          )}
        />

        <Controller
          control={control}
          name={"boneMineralDensityZScore"}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.field}
              margin="dense"
              label="BMD (Z-score)"
              type="number"
              error={Boolean(errors.boneMineralDensityZScore)}
              helperText={errors.boneMineralDensityZScore?.message}
            />
          )}
        />
      </Row>

      {formType === "bioelectricalImpedance" && (
        <Row>
          <Controller
            control={control}
            name={"skeletalMuscleMassInKg"}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.field}
                margin="dense"
                label={`Skeletal Muscle Mass (kg)`}
                type="number"
                inputProps={{ min: 0 }}
                error={Boolean(errors.skeletalMuscleMassInKg)}
                helperText={errors.skeletalMuscleMassInKg?.message}
              />
            )}
          />

          <Controller
            control={control}
            name={"dryLeanMassInKg"}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.field}
                margin="dense"
                label={`Dry Lean Mass (kg)`}
                type="number"
                inputProps={{ min: 0 }}
                error={Boolean(errors.dryLeanMassInKg)}
                helperText={errors.dryLeanMassInKg?.message}
              />
            )}
          />

          <Controller
            control={control}
            name={"visceralFatArea"}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.field}
                margin="dense"
                label="Visceral Fat Area (cm²)"
                type="number"
                inputProps={{ min: 0, max: 100, step: 0.1 }}
                error={Boolean(errors.visceralFatArea)}
                helperText={errors.visceralFatArea?.message}
              />
            )}
          />

          <Controller
            control={control}
            name={"trunkFatInKg"}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.field}
                margin="dense"
                label={`Trunk Fat (kg)`}
                type="number"
                error={Boolean(errors.trunkFatInKg)}
                helperText={errors.trunkFatInKg?.message}
              />
            )}
          />
        </Row>
      )}

      {!hideCommentField && (
        <Controller
          control={control}
          name={"comment"}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ flex: "1 100%", mt: 2 }}
              margin="dense"
              label="Comment"
              multiline
              rows={3}
              error={Boolean(errors.comment)}
              helperText={errors.comment?.message}
            />
          )}
        />
      )}
    </Box>
  );
};
