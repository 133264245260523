import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useDateFormatting } from "@notemeal/shared-ui";
import { getLongDateFormatStingForLocale } from "@notemeal/utils-date-time";
import { format } from "date-fns";
import React from "react";
import { MealPlanCalendarEvent, TemplateEventType } from "../../types";
import { getNameForMealPlanCalendarEvent } from "../../utils";
import PopoverBase from "./Base";
import { TimeLineItem } from "./LineItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: 24,
      marginLeft: theme.spacing(),
    },
  })
);

interface MealPlanCalendarEventPreviewLoaderProps {
  event: MealPlanCalendarEvent;
  anchorEl: HTMLElement;
  onDelete?: (eventId: string, eventType: TemplateEventType) => void;
  onClose: () => void;
}

const MealPlanCalendarEventPreviewLoader = ({ event, anchorEl, onDelete, onClose }: MealPlanCalendarEventPreviewLoaderProps) => {
  const {
    palette: { grey },
  } = useTheme();
  const classes = useStyles();
  const name = getNameForMealPlanCalendarEvent(event);

  const { dateFnsLocale } = useDateFormatting();

  // TODO: Locale Cleanup
  const formatString = getLongDateFormatStingForLocale(dateFnsLocale, true);
  const displayDate = format(event.start, formatString);

  const [deletePopoverOpen, setDeletePopoverOpen] = React.useState<boolean>(false);
  const [previewPopoverOpen, setPreviewPopoverOpen] = React.useState<boolean>(true);

  return (
    <PopoverBase
      anchorEl={anchorEl}
      onClose={onClose}
      variant="lg">
      {onDelete && deletePopoverOpen && (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
            <Typography variant="h3">Delete Events</Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant="body2" sx={{ pb: 2 }}>
            Are you sure you want to delete the event? Doing so will remove the event from this and all selected days.
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, borderTop: `thin solid ${grey[300]}`, py: 2 }}>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color="error"
              onClick={() => {
                onDelete(event.id, event.type);
                onClose();
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      )}
      {previewPopoverOpen && (
        <>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Typography>{name}</Typography>
            <img
              alt="Nutrition"
              src="/img/nutrition_512.png"
              className={classes.logo} />
            {onDelete && (
              <IconButton
                sx={{ marginLeft: "auto" }}
                onClick={() => {
                  setDeletePopoverOpen(true);
                  setPreviewPopoverOpen(false);
                }}
              >
                <DeleteIcon color="action" />
              </IconButton>
            )}
          </Box>
          <Typography variant="body2">Date: {displayDate}</Typography>
          <TimeLineItem event={event} />
        </>
      )}
    </PopoverBase>
  );
};

export default MealPlanCalendarEventPreviewLoader;
