import { subDays, addMinutes, isBefore } from "date-fns";
import { parseTime, parseDateTime, dateAndTimeToIsoInTz, serializeDate } from "@notemeal/utils-date-time";
import { BaseMenuDialogState, NewMenuDialogState, StandaloneMenuDialogState } from "../types";

const getBaseMenuDialogTooltips = (state: BaseMenuDialogState): string[] => {
  let tooltipItems = [];
  if (!state.name) {
    tooltipItems.push("Menu name required");
  }

  if (state.durationInMinutes <= 0) {
    tooltipItems.push("End time must be after start time");
  }

  if (isLastOrderInvalid(state) === "invalid") {
    tooltipItems.push("Last order due is after end of meal");
  }

  const mealMenusWithoutName = state.mealMenuDiningStations.flatMap(ds => ds.menuItemAppearances).filter(mia => mia.menuItem.name === "");

  if (mealMenusWithoutName.length > 0) {
    tooltipItems.push("All menu items must have a name");
  }

  const mealMenusWithNaNMaxAmount = state.mealMenuDiningStations
    .flatMap(ds => ds.menuItemAppearances)
    .filter(mia => mia.maxAmount !== null && isNaN(mia.maxAmount));

  if (mealMenusWithNaNMaxAmount.length > 0) {
    tooltipItems.push("Menu item max per order must be a number");
  }

  if (state.userOrderLimit !== null && state.userOrderLimit.limit !== null && isNaN(state.userOrderLimit.limit)) {
    tooltipItems.push("Value for order limit must be at least 1");
  }

  return tooltipItems;
};

export const getNewMenuDialogTooltips = (state: NewMenuDialogState): string[] => {
  const baseTooltips = getBaseMenuDialogTooltips(state);
  let newTooltips = [];
  if (!state.startDates.length) {
    newTooltips.push("Select at least one date to create menu on");
  }
  if (
    state.startDates.some(startDate => {
      const startIso = dateAndTimeToIsoInTz({ date: startDate, time: state.startTime }, state.timezone);
      const endJsDate = addMinutes(parseDateTime(startIso), state.durationInMinutes);
      return isBefore(endJsDate, new Date());
    })
  ) {
    newTooltips.push("Cannot create menu that ends in the past");
  }

  return [...baseTooltips, ...newTooltips];
};

export const getStandaloneMenuDialogTooltips = (state: StandaloneMenuDialogState): string[] => {
  const baseTooltips = getBaseMenuDialogTooltips(state);
  let newTooltips = [];

  const startIso = dateAndTimeToIsoInTz({ date: state.startDate, time: state.startTime }, state.timezone);
  const endJsDate = addMinutes(parseDateTime(startIso), state.durationInMinutes);
  if (isBefore(endJsDate, new Date())) {
    newTooltips.push("Cannot edit menu to a time that ends in the past");
  }

  return [...baseTooltips, ...newTooltips];
};

interface MenuTimeInfo {
  startTime: string;
  durationInMinutes: number;
  lastOrderDaysBefore: number;
  lastOrderTime: string;
  prepTimeInMinutes: number;
}

// Does the relative calculations for the time, duration, and lastOrderDue
export const isLastOrderInvalid = (state: MenuTimeInfo): "invalid" | "potential" | null => {
  const { startTime, lastOrderTime, prepTimeInMinutes, lastOrderDaysBefore, durationInMinutes } = state;
  const endDate = addMinutes(parseTime(startTime), durationInMinutes);

  const lastOrderDueDate = subDays(parseTime(lastOrderTime), lastOrderDaysBefore);

  if (isBefore(endDate, lastOrderDueDate)) {
    return "invalid";
  }

  const lastOrderDueWithPrepTime = addMinutes(lastOrderDueDate, prepTimeInMinutes);
  if (isBefore(endDate, lastOrderDueWithPrepTime)) {
    return "potential";
  }

  return null;
};

type StartAndEndStateInfo = Pick<MenuTimeInfo, "startTime" | "durationInMinutes">;

export const endsOnDifferentDay = (state: StartAndEndStateInfo): boolean => {
  const startDate = parseTime(state.startTime);
  const endDate = addMinutes(startDate, state.durationInMinutes);
  return serializeDate(startDate) !== serializeDate(endDate);
};

interface LastOrderDueInfo extends StartAndEndStateInfo {
  lastOrderDaysBefore: number;
  lastOrderTime: string;
}
export const resetLastOrderDue = <T extends LastOrderDueInfo>(state: T): T => {
  if (!endsOnDifferentDay(state) && state.lastOrderDaysBefore === -1) {
    return { ...state, lastOrderDaysBefore: 0, lastOrderTime: state.startTime };
  }
  return state;
};
