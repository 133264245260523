import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, CardActionArea, CardContent, CardHeader, IconButton, List, SxProps, Typography } from "@mui/material";
import { getMenuSelectionItemServingAmounts } from "@notemeal/shared-ui";
import { Macros } from "@notemeal/shared-utils-macro-protocol";
import { ReactNode } from "react";
import { MenuSelectionFormFragment, MenuSelectionItemFormFragment } from "../../types";
import MealMacrosAndNutrients from "../Macros/MealMacrosAndNutrients";
import { SUGGESTIONS } from "../MenuOrder/MenuOrderUtils";
import MenuSelectionItemListItem from "../MenuSelectionItem/ListItem";

interface MenuPlateCardProps {
  menuSelectionItems: readonly MenuSelectionItemFormFragment[];
  targetMacros: Macros | null;
  plateTitle?: string;
  sx?: SxProps;
  children?: ReactNode;
  onRemove?: () => void;
  onClick?: () => void;
  index?: number;
  onChoose?: (suggestion: MenuSelectionFormFragment) => void;
  menuSelection?: MenuSelectionFormFragment;
  tab?: string;
  showNutrientsButton?: boolean;
}

/**
 * Overview of a single Plate in a menu.
 */
const MenuPlateCard = ({
  menuSelectionItems,
  targetMacros,
  sx,
  children,
  index,
  plateTitle,
  onRemove,
  onClick,
  onChoose,
  menuSelection,
  tab,
  showNutrientsButton,
}: MenuPlateCardProps) => {
  const wrapWithStopPropagation = (node: ReactNode) =>
    onClick ? (
      <div onClick={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}>
        {node}
      </div>
    ) : (
      node
    );

  const content = (
    <CardContent sx={{ pl: 2 }}>
      <List
        dense
        sx={{ flexGrow: 1, overflowY: "auto", borderBottom: "solid thin lightgray" }}
        onClick={() => (onChoose && menuSelection ? onChoose(menuSelection) : null)}
      >
        {menuSelectionItems.map((item, index) => (
          <MenuSelectionItemListItem
            key={`${item.menuItem.id}:${index}`}
            menuSelectionItem={item}
            hidePercentConsumed />
        ))}
      </List>
      {wrapWithStopPropagation(
        <Box sx={{ pt: 2, width: "100%", display: "flex", justifyContent: "space-around" }}>
          <MealMacrosAndNutrients
            mealServingAmounts={menuSelectionItems.flatMap(getMenuSelectionItemServingAmounts)}
            targetMacros={targetMacros}
            showNutrientsButton={showNutrientsButton}
          />
        </Box>
      )}
      {wrapWithStopPropagation(children)}
    </CardContent>
  );

  const renderCardInnerHeader = () => {
    if (tab && onChoose && menuSelection) {
      return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h3">{`${tab === SUGGESTIONS ? "Suggestions" : "Recent Order "} ${index}`}</Typography>
          <CheckCircleOutlineIcon color="action" />
        </Box>
      );
    }

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {plateTitle}
        {onRemove && (
          <IconButton
            onClick={onRemove}
            size="small"
            sx={{ position: "absolute", right: 1 }}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <Card raised sx={sx}>
      {wrapWithStopPropagation(
        <CardHeader
          title={renderCardInnerHeader()}
          sx={{ fontWeight: "bold", py: 0.5, px: 2, backgroundColor: "grey.100", minHeight: 30 }}
          titleTypographyProps={{ variant: "h3" }}
          onClick={() => (onChoose && menuSelection ? onChoose(menuSelection) : null)}
        />
      )}
      {onChoose && menuSelection ? (
        <CardActionArea
          onClick={onClick}
          sx={{
            focusHighlight: { backgroundColor: "transparent" },
          }}
          disableRipple
        >
          {content}
        </CardActionArea>
      ) : (
        content
      )}
    </Card>
  );
};

export default MenuPlateCard;
