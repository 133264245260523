import { ApolloError } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import AddToHomeScreen from "@mui/icons-material/AddToHomeScreen";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import SyncAltOutlinedIcon from "@mui/icons-material/SyncAltOutlined";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { Loading, NEW_ATHLETE_ROLES, getAthleteMobileState, useDateFormatting } from "@notemeal/shared-ui";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { getNavOrgTeam } from "apps/web/src/pages/Auth/Org/Team/TeamPaths";
import { formatRelative } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { ConfirmationDialog } from "../../../../componentLibrary";
import { Athlete } from "../../../../components/Athlete/types";
import ArchiveModal from "../../../../components/universal/ArchiveModal";
import {
  AccountVerificationMethod,
  useArchiveAthleteMutation,
  useAthleteTransferOrgGroupsQuery,
  useCreateOrgMembershipForAthleteMutation,
  useDeleteAthleteMutation,
  useMyOrgFullySyncedQuery,
  useReclaimAthleteMutation,
  useTransferAthleteMutation,
  useUnarchiveAthleteMutation,
} from "../../../../types";
import { useUser } from "../../../../utils/tokens";
import { ActivationLinkModal } from "./ActivationLinkModal";
import { EnableMobileAccountModal } from "./EnableMobileAccountModal";
import { ReclaimAthleteModal } from "./ReclaimAthleteModal";
import { TransferAthleteModal } from "./TransferAthleteModal";
import { updateCacheSetIsArchived, updateCacheUnarchiveAthlete } from "./cache";

interface AthleteSummaryAccountManagementProps {
  athlete: Athlete;
}

const AthleteSummaryAccountManagement = ({ athlete }: AthleteSummaryAccountManagementProps) => {
  const { setMessage } = useSnackbar();
  const [provisionAccountOpenOpen, setEnableMobileAccountOpenOpen] = useState<boolean>(false);
  const [activationLink, setActivationLink] = useState<string | null>(null);
  const [archiveOpen, setArchiveOpen] = useState<boolean>(false);
  const [transferOpen, setTransferOpen] = useState<boolean>(false);
  const [reclaimOpen, setReclaimOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const { data: orgData, loading } = useMyOrgFullySyncedQuery();
  const user = useUser();
  const navigate = useNavigate();
  const { data: athleteTransferOrgGroupsData } = useAthleteTransferOrgGroupsQuery();
  const { dateFnsLocale } = useDateFormatting();
  const shouldDisplayTransfers = (athleteTransferOrgGroupsData?.athleteTransferOrgGroups.length ?? 0) > 0;
  const onError = (e: ApolloError) => {
    setMessage("error", e.message);
  };

  // Mutations
  const [createOrgMembershipForAthlete] = useCreateOrgMembershipForAthleteMutation({
    onError,
    update: (_, { data }) => {
      if (data?.createOrgMembershipForAthlete?.generatedSelfServiceActivationLink) {
        setActivationLink(data.createOrgMembershipForAthlete.generatedSelfServiceActivationLink);
      } else {
        setMessage("success", `Activation link has been sent`);
      }
    },
  });
  const [archiveAthlete] = useArchiveAthleteMutation({
    update: (cache, { data }) => {
      if (data) {
        cache.evict({
          id: `Athlete:${data.archiveAthlete.athlete.id}`,
          broadcast: true,
        });
      }
    },
    onError,
  });
  const [unarchiveAthlete] = useUnarchiveAthleteMutation({
    update: (cache, mutationResult) => {
      if (mutationResult.data && mutationResult.data.unarchiveAthlete) {
        updateCacheUnarchiveAthlete({
          cache,
          mutationResult,
          teamId: athlete.team.id,
        });
        updateCacheSetIsArchived(cache, athlete.id, false);
      }
    },
    onError,
  });

  const [deleteAthlete, { loading: deleteLoading }] = useDeleteAthleteMutation({
    update: (cache, { data }) => {
      if (data) {
        cache.evict({
          id: `Athlete:${data.deleteAthlete.athleteId}`,
          broadcast: true,
        });
      }
    },
    onCompleted: () => {
      setMessage("success", `Successfully Deleted Athlete: ${athlete.firstName} ${athlete.lastName}`);
      // TODO: What is the best way to handle this?
      navigate(getNavOrgTeam(athlete.team.id));
    },
    onError,
  });

  const [transferAthlete] = useTransferAthleteMutation({
    onCompleted: () => {
      setMessage("success", `Initiated transfer of ${athlete.lastName}, ${athlete.firstName}`);
      setTransferOpen(false);
    },
    onError,
  });

  const [reclaimAthlete] = useReclaimAthleteMutation({
    onCompleted: () => {
      setMessage("success", `Reclaimed ${athlete.lastName}, ${athlete.firstName} from Transfer Portal`);
      setReclaimOpen(false);
    },
    onError,
  });

  if (loading || !orgData) {
    return <Loading progressSize="lg" />;
  }

  const { isNotemealLinked } = orgData.myOrg;

  const handleEnableMobileAccount = async (method: AccountVerificationMethod): Promise<void> => {
    if (isNotemealLinked) {
      setMessage("error", "You can not perform this action on a Nutrition Linked org");
      return;
    }
    createOrgMembershipForAthlete({
      variables: {
        input: {
          athlete: {
            athleteId: athlete.id,
            method,
            teamName: athlete.team.name || "their team",
            roles: NEW_ATHLETE_ROLES,
          },
        },
      },
    });
  };

  const handleDeleteAthlete = () => {
    deleteAthlete({ variables: { input: { id: athlete.id } } });
  };

  const handleArchiveAthlete = () => {
    archiveAthlete({ variables: { input: { id: athlete.id } } });
  };
  const handleUnarchiveAthlete = async () => {
    unarchiveAthlete({ variables: { input: { id: athlete.id } } });
  };

  const handleTransferAthlete = (note: string, orgGroupId: string) => {
    transferAthlete({ variables: { input: { athleteId: athlete.id, note, orgGroupId } } });
  };

  const handleReclaimAthlete = () => {
    if (athlete.activeTransfer) {
      reclaimAthlete({ variables: { input: { id: athlete.activeTransfer.id } } });
    }
  };

  const mobileAppState = getAthleteMobileState(athlete);
  const disabled = !user?.orgMembership?.isAdmin;

  const athleteIsSynced = !!athlete.orgMembership && (athlete.orgMembership.isNotemealOnly || athlete.orgMembership.teamworksId !== null);
  const athleteIsNotemealOnly = !!athlete.orgMembership && athlete.orgMembership.isNotemealOnly;
  const showUnsyncWarning = athlete.isArchived && isNotemealLinked && !athleteIsSynced;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h3">Account Management</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "flex-start" }}>
        {!athlete.isArchived && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            {mobileAppState === "active" ? (
              <>
                <Button
                  variant="outlined"
                  startIcon={<DoneIcon />}
                  disabled>
                  Mobile App Enabled
                </Button>
                <Typography variant="subtitle1">
                  <strong>Username: </strong>
                  {athlete.user && athlete.user.username}
                </Typography>
              </>
            ) : // If org isNotemealLinked no other opperation is valid
            isNotemealLinked && !athleteIsNotemealOnly ? null : mobileAppState === "waiting" && athlete && athlete.activationLinkSent ? (
              <>
                <Tooltip title={disabled ? "Admin permissions required" : "Account is pending athlete verification. Click to resend link."}>
                  <Button
                    variant="outlined"
                    startIcon={<AddToHomeScreen />}
                    onClick={() => setEnableMobileAccountOpenOpen(true)}
                    disabled={disabled}
                  >
                    Resend Mobile App Link
                  </Button>
                </Tooltip>
                <Typography variant="subtitle1">
                  <strong>Last sent: </strong>
                  {
                    formatRelative(new Date(athlete.activationLinkSent), new Date(), { locale: dateFnsLocale }) // TODO: Locale Cleanup make function
                  }
                </Typography>
              </>
            ) : mobileAppState === "inactive" ? (
              <Tooltip title={disabled ? "Admin permissions required" : ""}>
                <Button
                  variant="outlined"
                  startIcon={<AddToHomeScreen />}
                  onClick={() => setEnableMobileAccountOpenOpen(true)}
                  disabled={disabled}
                >
                  Enable Mobile App
                </Button>
              </Tooltip>
            ) : mobileAppState === "expired" && athlete.activationLinkSent ? (
              <>
                <Tooltip title={disabled ? "Admin permissions required" : "Account activation link expired! Click to resend link."}>
                  <Button
                    variant="outlined"
                    startIcon={<PhonelinkEraseIcon />}
                    onClick={() => setEnableMobileAccountOpenOpen(true)}
                    disabled={!user?.orgMembership?.isAdmin}
                  >
                    Resend Mobile App Link
                  </Button>
                </Tooltip>
                <Typography variant="subtitle1">
                  <strong>Last sent: </strong>
                  {
                    formatRelative(new Date(athlete.activationLinkSent), new Date(), { locale: dateFnsLocale }) // TODO: Locale Cleanup make function
                  }
                </Typography>
              </>
            ) : null}
          </Box>
        )}
        {athlete.isArchived ? (
          <Tooltip title={disabled ? "Admin permissions required" : ""}>
            <Button
              variant="outlined"
              startIcon={<UnarchiveIcon />}
              onClick={() => setArchiveOpen(true)}
              disabled={disabled}>
              Unarchive Athlete
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title={disabled ? "Admin permissions required" : ""}>
            <Button
              variant="outlined"
              startIcon={<ArchiveIcon />}
              onClick={() => setArchiveOpen(true)}
              disabled={disabled}>
              Archive Athlete
            </Button>
          </Tooltip>
        )}
        {shouldDisplayTransfers &&
          (athlete.activeTransfer ? (
            <Tooltip title={disabled ? "Admin permissions required" : ""}>
              <Button
                variant="outlined"
                startIcon={<UndoOutlinedIcon />}
                disabled={disabled}
                onClick={() => setReclaimOpen(true)}>
                Reclaim Athlete
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={disabled ? "Admin permissions required" : ""}>
              <Button
                variant="outlined"
                startIcon={<SyncAltOutlinedIcon />}
                onClick={() => setTransferOpen(true)}
                disabled={disabled}>
                Transfer Athlete
              </Button>
            </Tooltip>
          ))}
        <Tooltip title={disabled ? "Admin permissions required" : ""}>
          <Button
            variant="outlinedDestructive"
            startIcon={<DeleteIcon />}
            onClick={() => setDeleteOpen(true)}
            disabled={disabled}>
            Delete Athlete
          </Button>
        </Tooltip>
      </Box>
      {provisionAccountOpenOpen && (
        <EnableMobileAccountModal
          open={provisionAccountOpenOpen}
          onClose={() => setEnableMobileAccountOpenOpen(false)}
          onEnable={handleEnableMobileAccount}
          athlete={athlete}
          activationLink={activationLink}
        />
      )}
      {activationLink && (
        <ActivationLinkModal
          onClose={() => setActivationLink(null)}
          open={!!activationLink}
          activationLink={activationLink}
          onCopyLink={() => datadogRum.addAction("generate_activation_link", { athleteId: athlete.id })}
        />
      )}
      {archiveOpen && (
        <ArchiveModal
          open={archiveOpen}
          archived={athlete.isArchived}
          synced={showUnsyncWarning}
          onClose={() => setArchiveOpen(false)}
          onArchive={athlete.isArchived ? handleUnarchiveAthlete : handleArchiveAthlete}
        />
      )}
      {deleteOpen && (
        <ConfirmationDialog
          title="Delete Athlete"
          message="Are you sure you want to delete this athlete? Doing so will remove them from your org and all stored athlete data."
          onCancel={() => setDeleteOpen(false)}
          onConfirm={() => handleDeleteAthlete()}
          open={deleteOpen}
          variant="containedDestructive"
          loading={deleteLoading}
        />
      )}
      {transferOpen && (
        <TransferAthleteModal
          onTransfer={handleTransferAthlete}
          open={transferOpen}
          onClose={() => setTransferOpen(false)} />
      )}
      {reclaimOpen && <ReclaimAthleteModal
        onReclaim={handleReclaimAthlete}
        open={reclaimOpen}
        onClose={() => setReclaimOpen(false)} />}
    </Box>
  );
};

export default AthleteSummaryAccountManagement;
