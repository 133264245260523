import { DocumentNode, useApolloClient } from "@apollo/client";
import { getAthleteMobileState } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { useState } from "react";
import TablePage from "../../../../components/universal/TablePage";
import {
    AthletesForTeamEngagementOffsetConnectionDocument,
    AthletesForTeamEngagementOffsetConnectionQuery,
    AthletesForTeamEngagementOffsetConnectionQueryVariables,
    AthletesForTeamFoodPreferencesOffsetConnectionDocument,
    AthletesForTeamFoodPreferencesOffsetConnectionQuery,
    AthletesForTeamFoodPreferencesOffsetConnectionQueryVariables,
    AthletesForTeamOffsetConnectionDocument,
    AthletesForTeamOffsetConnectionQuery,
    AthletesForTeamOffsetConnectionQueryVariables,
    FoodLogNotificationSettings,
    NamedTagForSelectionFragment,
    OffsetPaginationInput,
    useAthletesForTeamEngagementOffsetConnectionQuery,
    useAthletesForTeamFoodPreferencesOffsetConnectionQuery,
} from "../../../../types";
import { PaginationHooks } from "../../../../utils/pagination";
import AthleteEngagementRow from "./EngagementRow";
import { exportColumns as engagementColumns, serializeRows as serializeEngagementRows } from "./Export/Engagement";
import { exportColumns as preferenceColumns, serializeRows as serializePreferenceRows } from "./Export/Preferences";
import { exportColumns as profileColumns, serializeRows as serializeProfileRows } from "./Export/Profiles";
import AthletePreferenceRow from "./PreferenceRow";
import { AthletesTableHeaderRow, AthletesTableRow } from "./ProfileRow";
import TableToolbar, { AVOIDED_FOODS, ENGAGEMENT, PREFERENCES, PROFILES, SortType, TableFilter, TableView } from "./Toolbar";
import {
    IAthleteEngagementRowInput,
    IAthletePreferenceRowInput,
    IAthleteProfileRowInput,
    athletesToEngagementRows,
    athletesToPreferenceRows,
    athletesToProfileRows,
    getAthleteFilter,
} from "./utils";

export interface EnableAthlete {
  phoneNumber: string | null;
  email: string | null;
  id: string;
  activationLinkSent: string | null;
  orgMembership: { id: string; isActive: boolean } | null;
}

const EXPORT_DATA_REQ_CHUNK_SIZE = 50;

export interface AthleteTableProps {
  teamId: string;
  displayEnableAccountModal: (athlete: EnableAthlete) => void;
  displaySubscribeToAthleteModal: (args: { athleteNotificationSettings: FoodLogNotificationSettings | null; athleteId: string }) => void;
  teamFoodLogNotificationSettings: FoodLogNotificationSettings | null;
  filter: TableFilter;
  setFilter: (filter: TableFilter) => void;
  paginationHooks: PaginationHooks;
  rows: IAthleteProfileRowInput[];
  loading: boolean;
  total: number;
  sort: SortType | null;
  setSort: (s: SortType | null) => void;
  isNotemealLinked: boolean;
  selectedNamedTags: NamedTagForSelectionFragment[];
  onChangeNamedTags: (newTags: NamedTagForSelectionFragment[]) => void;
  onCreateAthlete: () => void;
  onEditAthlete: (athlete: IAthleteProfileRowInput) => void;
}

export const AthleteTable = ({
  teamId,
  displaySubscribeToAthleteModal,
  displayEnableAccountModal,
  teamFoodLogNotificationSettings,
  paginationHooks,
  rows,
  loading,
  total,
  filter,
  setFilter,
  sort,
  setSort,
  isNotemealLinked,
  selectedNamedTags,
  onChangeNamedTags,
  onCreateAthlete,
  onEditAthlete,
}: AthleteTableProps) => {
  const { query, queryText, onChangeQueryText, resetOffset, offset, limit } = paginationHooks;
  const [inLastDays, setInLastDays] = useState(7);
  const [exportLoading, setExportLoading] = useState(false);
  const apolloClient = useApolloClient();
  const { setMessage } = useSnackbar();

  const tagIds = selectedNamedTags.map(({ tag: { id } }) => id);

  const queryProps = {
    query,
    inLastDays,
    filter: getAthleteFilter(filter),
    teamId,
    tagIds,
    priorityTagIds: tagIds,
  };

  const [engagementRows, setEngagementRows] = useState<IAthleteEngagementRowInput[]>([]);
  const [preferenceRows, setPreferenceRows] = useState<IAthletePreferenceRowInput[]>([]);

  const handleSetEngagementRows = (rows: IAthleteEngagementRowInput[], _sort: SortType | null) => {
    if (_sort === null || _sort === AVOIDED_FOODS) {
      setEngagementRows(sortByKey(rows, "lastName").concat());
    } else {
      setEngagementRows(sortByKey(rows, _sort, { reverse: true }).concat());
    }
  };

  const handleSetPreferenceRows = (rows: IAthletePreferenceRowInput[], _sort: SortType | null) => {
    const sortedRows =
      _sort === AVOIDED_FOODS
        ? [...rows.filter(a => a.dislikedFoodGroupNames.length > 0), ...rows.filter(a => !(a.dislikedFoodGroupNames.length > 0))]
        : sortByKey(rows, "lastName").concat();
    setPreferenceRows(sortedRows);
  };

  const handleSort = (sort: SortType | null) => {
    setSort(sort);
  };

  const [view, setView] = useState<TableView>(PROFILES);

  const { loading: engagementLoading } = useAthletesForTeamEngagementOffsetConnectionQuery({
    skip: view !== ENGAGEMENT,
    variables: { ...queryProps, inLastDays, input: { offset, limit } },
    onCompleted: data => {
      handleSetEngagementRows(athletesToEngagementRows(data.athletesForTeamOffsetConnection.edges), sort);
    },
  });

  const { loading: preferenceLoading } = useAthletesForTeamFoodPreferencesOffsetConnectionQuery({
    skip: view !== PREFERENCES,
    variables: { ...queryProps, input: { offset, limit } },
    onCompleted: data => {
      handleSetPreferenceRows(athletesToPreferenceRows(data.athletesForTeamOffsetConnection.edges), sort);
    },
  });

  const handleSetFilter = (_filter: TableFilter) => {
    setFilter(_filter);
    resetOffset();
  };

  const handleClickEnableAccount = (athlete: EnableAthlete) => {
    getAthleteMobileState(athlete) !== "active" && displayEnableAccountModal(athlete);
  };

  const fetchDataInChunksSequentially = async <Q, V extends { input: OffsetPaginationInput }>(
    total: number,
    queryDoc: DocumentNode,
    getVariables: (input: OffsetPaginationInput) => V
  ) => {
    const results: Q[] = [];
    let errored = false;
    const inputs = Array(Math.ceil(total / EXPORT_DATA_REQ_CHUNK_SIZE))
      .fill(0)
      .map((_, i) => {
        return { offset: i * EXPORT_DATA_REQ_CHUNK_SIZE, limit: EXPORT_DATA_REQ_CHUNK_SIZE };
      });

    for (const input of inputs) {
      const { data, errors } = await apolloClient.query<Q, V>({
        query: queryDoc,
        variables: getVariables(input),
      });

      if (errors) {
        errored = true;
        setMessage("error", "Failed to export data");
        break;
      }

      results.push(data);
    }

    return errored ? [] : results;
  };

  const handleSetView = (view: TableView) => {
    resetOffset();
    setView(view);
  };

  async function handleExport() {
    setExportLoading(true);
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();
    worksheet.getRow(1).alignment = {
      horizontal: "center",
      vertical: "middle",
    };

    let rows: any = [];

    if (view === PROFILES) {
      worksheet.columns = profileColumns;
      worksheet.name = "AthleteProfiles";

      const results = await fetchDataInChunksSequentially<
        AthletesForTeamOffsetConnectionQuery,
        AthletesForTeamOffsetConnectionQueryVariables
      >(total, AthletesForTeamOffsetConnectionDocument, input => ({ ...queryProps, input }));

      rows = serializeProfileRows(results.flatMap(data => athletesToProfileRows(data.athletesForTeamOffsetConnection.edges)));
    } else if (view === ENGAGEMENT) {
      worksheet.columns = engagementColumns;
      worksheet.name = "AthleteEngagement";

      const results = await fetchDataInChunksSequentially<
        AthletesForTeamEngagementOffsetConnectionQuery,
        AthletesForTeamEngagementOffsetConnectionQueryVariables
      >(total, AthletesForTeamEngagementOffsetConnectionDocument, input => ({ ...queryProps, input }));

      rows = serializeEngagementRows(results.flatMap(data => athletesToEngagementRows(data.athletesForTeamOffsetConnection.edges)));
    } else if (view === PREFERENCES) {
      worksheet.columns = preferenceColumns;
      worksheet.name = "AthleteFoodPreferences";

      const results = await fetchDataInChunksSequentially<
        AthletesForTeamFoodPreferencesOffsetConnectionQuery,
        AthletesForTeamFoodPreferencesOffsetConnectionQueryVariables
      >(total, AthletesForTeamFoodPreferencesOffsetConnectionDocument, input => ({ ...queryProps, input }));

      rows = serializePreferenceRows(results.flatMap(data => athletesToPreferenceRows(data.athletesForTeamOffsetConnection.edges)));
    }

    if (rows.length) {
      worksheet.addRows(rows);
      const toExport = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([toExport]), `${worksheet.name}.xlsx`);
    }

    setExportLoading(false);
  }

  const baseRowProps = {
    onClickEnableAccount: handleClickEnableAccount,
    displaySubscribeToAthleteModal: displaySubscribeToAthleteModal,
    teamFoodLogNotificationSettings: teamFoodLogNotificationSettings,
  };

  return (
    <TablePage
      tableHeaderRow={<AthletesTableHeaderRow
        view={view}
        sort={sort}
        setSort={handleSort} />}
      header={
        <TableToolbar
          title="Roster"
          inLastDays={inLastDays}
          setInLastDays={(inLastDays: number) => setInLastDays(inLastDays)}
          setView={handleSetView}
          view={view}
          filter={filter}
          setFilter={handleSetFilter}
          queryText={queryText}
          setQueryText={onChangeQueryText}
          onClickAdd={onCreateAthlete}
          onExport={handleExport}
          selectedNamedTags={selectedNamedTags}
          onChangeNamedTags={onChangeNamedTags}
          isNotemealLinked={isNotemealLinked}
          exportLoading={exportLoading}
        />
      }
      paginationHooks={paginationHooks}
      loading={loading || preferenceLoading || engagementLoading}
      total={total}
      tableBodyRows={
        !!rows
          ? view === ENGAGEMENT
            ? engagementRows.map(a => (
                <AthleteEngagementRow
                  row={a}
                  key={a.id}
                  {...baseRowProps}
                  displayEnableAccount={!isNotemealLinked || a.isProfileNotemealOnly}
                />
              ))
            : view === PREFERENCES
            ? preferenceRows.map(a => (
                <AthletePreferenceRow
                  row={a}
                  key={a.id}
                  {...baseRowProps}
                  displayEnableAccount={!isNotemealLinked || a.isProfileNotemealOnly}
                />
              ))
            : rows.map(row => (
                <AthletesTableRow
                  key={row.id}
                  setEditing={() => onEditAthlete(row)}
                  row={row}
                  view={view}
                  isNotemealLinked={isNotemealLinked}
                  {...baseRowProps}
                />
              ))
          : null
      }
    />
  );
};
