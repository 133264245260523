import { capitalize } from "@mui/material";
import { formatEventDatetime } from "@notemeal/utils-date-time";
import { Locale } from "date-fns";
import { enUS } from "date-fns/locale";
import React from "react";
import GenericTable, { ICol } from "../../../components/universal/GenericTable";
import { StaffDashboardOrgMembershipFragment } from "../../../types";

export type AdminOrgMembershipTableRow = Omit<StaffDashboardOrgMembershipFragment, "user"> & StaffDashboardOrgMembershipFragment["user"];

function formatTableCell(
  record: AdminOrgMembershipTableRow,
  key: keyof AdminOrgMembershipTableRow,
  locale: Locale
): string | React.ReactNode {
  switch (key) {
    case "roles":
      return record.roles.map(r => capitalize(r)).join(", ");
    case "lastLogin":
      return record.lastLogin ? formatEventDatetime(new Date(record.lastLogin), locale) : "Never";
    case "lastRefresh":
      return record.lastRefresh ? formatEventDatetime(new Date(record.lastRefresh), locale) : "Never";
    case "isActive":
      return record.isActive ? "✓" : "";
    case "isAdmin":
      return record.isAdmin ? "✓" : "";
    case "teamworksId":
      return record.teamworksId !== null ? "✓" : "";
    default:
      return record[key];
  }
}

const cols: ICol<AdminOrgMembershipTableRow>[] = [
  {
    id: "firstName",
    isNumeric: false,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lastName",
    isNumeric: false,
    disablePadding: false,
    label: "Last Name",
  },
  { id: "roles", isNumeric: false, disablePadding: false, label: "Roles" },
  {
    id: "username",
    isNumeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "lastRefresh",
    isNumeric: false,
    disablePadding: false,
    label: "Last Refresh",
  },
  {
    id: "lastLogin",
    isNumeric: false,
    disablePadding: false,
    label: "Last Login",
  },
  { id: "isActive", isNumeric: false, disablePadding: false, label: "Active?" },
  { id: "isAdmin", isNumeric: false, disablePadding: false, label: "Admin?" },
  { id: "teamworksId", isNumeric: false, disablePadding: false, label: "Teamworks?" },
];

export interface OrgMembershipTableProps {
  tableRows: AdminOrgMembershipTableRow[];
  onClickAddEntry: () => void;
  onClickRow: (row: AdminOrgMembershipTableRow) => void;
}

const UserTable = ({ tableRows, onClickRow, onClickAddEntry }: OrgMembershipTableProps) => {
  return (
    <GenericTable
      initRowsPerPage={25}
      size="small"
      tableRows={tableRows}
      tableCols={cols}
      addButtonText={"Create Org Membership"}
      onClickAddEntry={onClickAddEntry}
      onClickRow={onClickRow}
      formatTableCell={(record, key) => formatTableCell(record, key, enUS)} // TODO: Locale Staff view fixes
      defaultOrderBy={"lastLogin"}
    />
  );
};

export default UserTable;
