import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { environment } from "../../environment";

const DisableTryItOutPlugin = function () {
  return {
    statePlugins: {
      spec: {
        wrapSelectors: {
          allowTryItOutFor: () => () => false,
        },
      },
    },
  };
};

const DisableAuthorizePlugin = function () {
  return {
    wrapComponents: {
      AuthorizeBtn: () => () => null,
    },
  };
};

export const ApiDocsPage = () => {
  const openApiJsonUrl = `${environment.VITE_URL_SCHEME}${environment.VITE_SERVER_DOMAIN}/api/v1/openapi.json`;
  return <SwaggerUI plugins={[DisableTryItOutPlugin, DisableAuthorizePlugin]} url={openApiJsonUrl} />;
};
