import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogActions, DialogContent, Tooltip } from "@mui/material";
import { nullifyEmptyStringValues, useLocaleContext } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useForm } from "react-hook-form";
import { useRefetchQueriesContext } from "../../../contexts/RefetchQueries";
import { SexType, useCreateMetricAnthropometryEntryMutation, useEditMetricAnthropometryEntryMutation } from "../../../types";
import {
    AnthropometryEntrySchema,
    AnthropometryEntryType,
    anthropometryEntryFormStateToMetricCreateInput,
    anthropometryEntryFormStateToMetricEditInput,
} from "../Form/AnthropometryEntryFormSchema";
import { ImperialAnthropometryEntryForm } from "../Form/ImperialAnthropometryForm";
import {
    MetricAnthropometryEntrySchema,
    MetricAnthropometryEntryType,
    metricAnthropometryEntryFormStateToCreateInput,
    metricAnthropometryEntryFormStateToEditInput,
} from "../Form/MetricAnthropometryEntryFormSchema";
import { MetricAnthropometryEntryForm } from "../Form/MetricAnthropometryForm";
import { evictRecentAnthropometryEntriesForAthlete } from "../cache";

interface AnthropometryEntryModalProps {
  athleteId: string;
  open: boolean;
  onClose: () => void;
  athleteSex: SexType;
  initialFormState: Partial<AnthropometryEntryType>;
}

export const AnthropometryEntryModal = ({ athleteId, open, onClose, athleteSex, initialFormState }: AnthropometryEntryModalProps) => {
  const refetchQueries = useRefetchQueriesContext();

  const { isMetricLocale } = useLocaleContext();

  const imperialForm = useForm<AnthropometryEntryType>({
    defaultValues: initialFormState,
    resolver: zodResolver(AnthropometryEntrySchema()),
  });

  const metricForm = useForm<MetricAnthropometryEntryType>({
    defaultValues: initialFormState,
    resolver: zodResolver(MetricAnthropometryEntrySchema()),
  });

  const watchedId = isMetricLocale ? metricForm.watch("id") : imperialForm.watch("id");
  const hasFormErrors = isMetricLocale
    ? metricForm.formState.isSubmitted && !metricForm.formState.isValid
    : imperialForm.formState.isSubmitted && !imperialForm.formState.isValid;

  const [editMetricAnthro] = useEditMetricAnthropometryEntryMutation({
    refetchQueries,
    update: (cache, { data }) => {
      if (data) {
        evictRecentAnthropometryEntriesForAthlete(athleteId, cache);
      }
    },
  });

  const [createMetricAnthro] = useCreateMetricAnthropometryEntryMutation({
    refetchQueries,
    update: (cache, { data }) => {
      if (data) {
        evictRecentAnthropometryEntriesForAthlete(athleteId, cache);
      }
    },
  });

  const submitImperialForm = () => {
    imperialForm.reset(nullifyEmptyStringValues(imperialForm.getValues()));
    imperialForm.handleSubmit(anthroEntry => {
      const type = anthroEntry.type;
      const date = anthroEntry.date;
      const id = anthroEntry.id;

      if (!id) {
        createMetricAnthro({
          variables: {
            input: anthropometryEntryFormStateToMetricCreateInput({ ...anthroEntry, type, date }, athleteId, athleteSex),
          },
        });
      } else {
        editMetricAnthro({
          variables: {
            input: anthropometryEntryFormStateToMetricEditInput({ ...anthroEntry, type, date, id }, athleteSex),
          },
        });
      }
      onClose();
    })();
  };

  const submitMetricForm = () => {
    metricForm.reset(nullifyEmptyStringValues(metricForm.getValues()));
    metricForm.handleSubmit(anthroEntry => {
      const type = anthroEntry.type;
      const date = anthroEntry.date;
      const id = anthroEntry.id;

      if (!id) {
        createMetricAnthro({
          variables: {
            input: metricAnthropometryEntryFormStateToCreateInput({ ...anthroEntry, type, date }, athleteId, athleteSex),
          },
        });
      } else {
        editMetricAnthro({
          variables: {
            input: metricAnthropometryEntryFormStateToEditInput({ ...anthroEntry, type, date, id }, athleteSex),
          },
        });
      }
      onClose();
    })();
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title={`${!watchedId ? "Add" : "Edit"} Anthropometry Entry`} onClose={onClose} />
      <DialogContent>
        {isMetricLocale ? <MetricAnthropometryEntryForm form={metricForm} /> : <ImperialAnthropometryEntryForm form={imperialForm} />}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip title={hasFormErrors ? "Resolve the errors above to continue" : ""}>
          <Button onClick={() => (isMetricLocale ? submitMetricForm() : submitImperialForm())}>Save</Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
