import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import EmailIcon from "@mui/icons-material/Email";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Paper } from "@mui/material";
import { grayBackground } from "@notemeal/palette";
import {
  ExchangeMealPlanLegacyDisplaySettings,
  MacroMealPlanLegacyDisplaySettings,
  initialExchangeMealPlanLegacyDisplaySettings,
  initialMacroMealPlanLegacyDisplaySettings,
} from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { environment } from "apps/web/src/environment";
import axios from "axios";
import { useReducer, useState } from "react";
import { FullMealPlanFragment, useRecipesByIdQuery } from "../../../../types";
import AddPhoneNumberOrEmailDialog from "../../../global/AddPhoneNumberOrEmailDialog";
import LoadingBackdrop from "../../../universal/LoadingBackdrop";
import MealPlanExportContainer from "../Container";
import FormPdf from "../Toolbar/PdfToolbarForm";
import { SCALE_FACTOR, getMealPlanFilename } from "../utils";
import { createInitialExportFormState, exportFormReducer } from "../utils/pdfReducer";
import { mealPlanJpegsToPdf, mealPlanPageDivsToJpegs } from "./utils";

interface MealPlanExportModalSinglePdfProps {
  open: boolean;
  onClose: () => void;
  mealPlan: FullMealPlanFragment;
}

const MealPlanExportModalSinglePdf = ({ mealPlan, open, onClose }: MealPlanExportModalSinglePdfProps) => {
  const [pdfState, pdfDispatch] = useReducer(exportFormReducer, createInitialExportFormState());

  const recipeIds =
    mealPlan.mealTemplates.flatMap(mt =>
      mt.mealOptions.flatMap(mo =>
        mo.servingAmounts.flatMap(sa =>
          sa.serving.foodOrRecipe.__typename === "Recipe" && sa.serving.foodOrRecipe.hasFullAccess ? sa.serving.foodOrRecipe.id : []
        )
      )
    ) ?? [];
  const recipesResult = useRecipesByIdQuery({
    variables: {
      ids: recipeIds,
    },
  });

  const initialDisplaySettings: MacroMealPlanLegacyDisplaySettings | ExchangeMealPlanLegacyDisplaySettings =
    mealPlan.type === "macro" ? initialMacroMealPlanLegacyDisplaySettings : initialExchangeMealPlanLegacyDisplaySettings;

  const [displaySettings, setDisplaySettings] = useState<MacroMealPlanLegacyDisplaySettings | ExchangeMealPlanLegacyDisplaySettings>(
    initialDisplaySettings
  );

  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [pageDivs, setPageDivs] = useState<HTMLDivElement[]>([]);

  const mealPlanFileName = getMealPlanFilename(mealPlan);
  const mealPlanHasDates = Boolean(mealPlan.startDate !== null || (mealPlan.dates && mealPlan.dates.length));

  const [emailButtonStatus, setEmailButtonStatus] = useState<"called" | "error" | "success">();

  const getMealPlanPdf = async (pageDivs: HTMLDivElement[]) => {
    const jpegs = await mealPlanPageDivsToJpegs(pageDivs);
    return mealPlanJpegsToPdf(jpegs, pdfState.orientation);
  };

  const handleClickDownload = async () => {
    const pdf = await getMealPlanPdf(pageDivs);
    pdf.save(`${mealPlanFileName}.pdf`);
  };

  const handleClickEmail = async () => {
    if (mealPlan.athlete.email === null) {
      setEmailDialogOpen(true);
    } else {
      setEmailButtonStatus("called");

      const pdf = await getMealPlanPdf(pageDivs);
      const mealPlanPdf = pdf.output("blob");

      const formData = new FormData();
      formData.append(mealPlan.id, mealPlanPdf);

      const mealPlanPdfUploadUrl = `${environment.VITE_URL_SCHEME}${environment.VITE_SERVER_DOMAIN}/upload/meal-plans`;
      axios
        .post(mealPlanPdfUploadUrl, formData, {
          withCredentials: true,
        })
        .then(() => setEmailButtonStatus("success"))
        .catch(() => setEmailButtonStatus("error"));
    }
  };

  if (!recipesResult.data) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}>
      <DialogTitle title="Send Meal Plan" onClose={onClose} />
      <DialogContent sx={{ display: "flex", flexDirection: "row" }}>
        <Paper
          elevation={1}
          sx={{ p: 1, display: "flex", flexDirection: "row", backgroundColor: grayBackground, maxHeight: "100%", overflow: "auto" }}
        >
          <Paper
            elevation={15}
            style={{
              padding: `${0.1}in`,
              height: `${pdfState.orientation === "portrait" ? 11 * SCALE_FACTOR : 8.5 * SCALE_FACTOR}in`,
              overflowY: "auto",
              overflowX: "auto",
            }}
          >
            <MealPlanExportContainer
              toolbarState={pdfState}
              displaySettings={displaySettings}
              mealPlan={mealPlan}
              recipes={recipesResult.data.recipesById}
              onChangePageDivs={setPageDivs}
            />
          </Paper>
          <Box sx={{ display: "block", height: "100%", ml: 2 }}>
            <FormPdf
              state={pdfState}
              dispatch={pdfDispatch}
              type={mealPlan.type}
              hasDates={mealPlanHasDates}
              displaySettings={displaySettings}
              onChangeDisplaySettings={setDisplaySettings}
            />
          </Box>

          {emailDialogOpen && (
            <AddPhoneNumberOrEmailDialog
              open={emailDialogOpen}
              onClose={() => setEmailDialogOpen(false)}
              onAdd={handleClickEmail}
              athleteId={mealPlan.athlete.id}
              type="email"
            />
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
            p: 1,
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <Button
            variant="outlined"
            startIcon={
              !emailButtonStatus ? (
                <EmailIcon />
              ) : emailButtonStatus === "called" ? (
                <CircularProgress size={20} sx={{ marginRight: "5px" }} />
              ) : emailButtonStatus === "success" ? (
                <CheckIcon color="success" />
              ) : (
                <ErrorIcon color="error" />
              )
            }
            onClick={handleClickEmail}
            disabled={!!emailButtonStatus}
          >
            Email
          </Button>
          <Button startIcon={<DownloadIcon />} onClick={handleClickDownload}>
            Download
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default MealPlanExportModalSinglePdf;
