import { ListItem, ListItemIcon, ListItemText, Radio, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MenuItemChoiceForModal, useAthleteHighlightFromServingAmounts } from "@notemeal/shared-ui";
import classNames from "classnames";
import ItemAmountButtons from "../universal/ItemAmountButtons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amountButtons: {
      padding: theme.spacing(),
    },
    redHighlight: {
      color: theme.palette.error.main,
      "&:focus": {
        color: theme.palette.error.light,
      },
    },
  })
);

interface ChoiceRadioItemProps {
  onChangeOption: (menuItemChoiceOptionId: string) => void;
  onIncrementOption: (menuItemChoiceOptionId: string) => void;
  onDecrementOption: (menuItemChoiceOptionId: string) => void;
  mico: MenuItemChoiceForModal["options"][number];
  selectedOptionId: string;
}

const ChoiceRadioItem = ({ onChangeOption, onIncrementOption, onDecrementOption, mico, selectedOptionId }: ChoiceRadioItemProps) => {
  const classes = useStyles();
  const selected = selectedOptionId === mico.id;

  const { servingAmounts } = mico;
  const { highlightType: highlightColor, reason } = useAthleteHighlightFromServingAmounts([...servingAmounts]);
  const highlightColorClass = classNames({
    [classes.redHighlight]: highlightColor === "Red",
  });

  const containsText = reason.length > 0 && highlightColor === "Red" ? `Contains: ${reason.join(", ")}` : undefined;

  return (
    <ListItem
      key={mico.id}
      button={(!mico.canEditAmount || !selected) as any}
      onClick={() => {
        if (mico.canEditAmount) {
          !selected && onChangeOption(mico.id);
        } else {
          onChangeOption(mico.id);
        }
      }}
    >
      <ListItemIcon>
        {mico.canEditAmount ? (
          <ItemAmountButtons
            onIncrement={() => onIncrementOption(mico.id)}
            onDecrement={() => onDecrementOption(mico.id)}
            className={classes.amountButtons}
            maxAmount={mico.maxAmount}
            amount={mico.menuSelectionItemOption?.amount}
          />
        ) : (
          <Radio
            edge="start"
            checked={selected}
            disableRipple />
        )}
      </ListItemIcon>
      <ListItemText
        primary={mico.name}
        className={highlightColorClass}
        secondary={containsText} />
    </ListItem>
  );
};

export default ChoiceRadioItem;
