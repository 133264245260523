import { Box, Card, CardActionArea, CardHeader, Divider, List, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ServingAmountViewChipList, getMealTemplateMacroTargets, getPreviewImage, useClientTimezone } from "@notemeal/shared-ui";
import { formatTimeRangeInTimezone } from "@notemeal/utils-date-time";
import MealMacrosAndNutrients from "apps/web/src/components/Macros/MealMacrosAndNutrients";
import NotificationCountLabel from "apps/web/src/components/Notification/CountLabel";
import { isCommentNotification } from "apps/web/src/components/Notification/utils";
import { useMarkNotificationsAsViewed } from "apps/web/src/utils/notifications";
import { Fragment, useState } from "react";
import CommentButton from "../../../components/Comment/Button";
import MenuOrderItemListItem from "../../../components/MenuOrderItem/ListItem";
import ImageModal from "../FoodLogs/Images/ImageModal";
import FoodLogPreviewImage from "../FoodLogs/Images/PreviewImage";
import { TimelineMeal, getTimelineMealOverviewCounts, getTimelineMealServingAmounts } from "./utils";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    chipList: {
      overflowY: "auto",
      maxHeight: 152,
    },
    image: {
      minWidth: "100%",
      maxWidth: "100%",
      maxHeight: 175,
      objectFit: "cover",
      overflow: "hidden",
      margin: theme.spacing(1, 0),
      borderRadius: theme.spacing(1),
      flexShrink: 0,
      cursor: "pointer",
    },
    actionArea: {
      padding: theme.spacing(0, 2),
      "&:hover $focusHighlight": {
        opacity: 0,
      },
    },
    focusHighlight: {},
    divider: { margin: theme.spacing(1) },
    list: { padding: theme.spacing(0) },
    buttonContainer: { padding: theme.spacing(0, 2), color: theme.palette.grey[100] },
    buttonText: { display: "flex", flexDirection: "column", alignItems: "center", textTransform: "capitalize" },
    button: { backgroundColor: theme.palette.grey[100] },
  });
});

interface TimelineMealCardProps {
  timelineMeal: TimelineMeal;
  onClick: () => void;
  onClickComment: () => void;
}

const TimelineMealCard = ({ timelineMeal, onClick, onClickComment }: TimelineMealCardProps) => {
  const clientTimezone = useClientTimezone();
  const classes = useStyles();
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const timeRange = formatTimeRangeInTimezone(timelineMeal.start, timelineMeal.durationInMinutes, timelineMeal.timezone, {
    excludeTimezoneSuffix: clientTimezone === timelineMeal.timezone,
  });
  const previewImage = getPreviewImage(timelineMeal.images);
  const cardHeader = `${timelineMeal.name} (${timeRange})`;
  const targetMacros = timelineMeal.mealTemplate && getMealTemplateMacroTargets(timelineMeal.mealTemplate);

  const isOrderable = timelineMeal.mealMenus.some(mm => mm.isOrderable);
  const actionText = isOrderable ? "Click to Place Order / Save Log" : "Click to Log Meal";

  const counts = getTimelineMealOverviewCounts(timelineMeal);

  const nonCommentNotificationIds = timelineMeal.notifications.filter(n => !isCommentNotification(n)).map(n => n.id);
  useMarkNotificationsAsViewed(nonCommentNotificationIds, 3000);

  const allRestaurantMenuLinks = timelineMeal.mealMenus.flatMap(({ restaurantMenuLinks }) => restaurantMenuLinks) ?? [];
  const orderList =
    counts.orderCount > 0 ? (
      <List className={classes.list}>
        <Typography variant="h4">Order Details</Typography>
        {timelineMeal.menuOrders.map(inHouseOrder => (
          <Fragment key={inHouseOrder.id}>
            <Typography>{inHouseOrder.mealMenu.name}</Typography>
            <List dense disablePadding>
              {inHouseOrder.items.map((i, index) => (
                <>
                  <MenuOrderItemListItem key={i.id} menuOrderItem={i} />
                  {index !== inHouseOrder.items.length - 1 && <Divider />}
                </>
              ))}
            </List>
          </Fragment>
        ))}
        {timelineMeal.restaurantMenuLinkOrders.map((rmlo, index) => {
          const restaurantMenuLink = allRestaurantMenuLinks.find(rml => rml.id === rmlo.restaurantMenuLink.id);
          const rmloItems = rmlo.items;

          return (
            rmloItems.length > 0 &&
            restaurantMenuLink && (
              <Fragment key={`${rmlo.id}_${index}`}>
                <Typography>{restaurantMenuLink.restaurant.name}</Typography>
                <List dense disablePadding>
                  {rmloItems.map((i, index) => (
                    <MenuOrderItemListItem key={i.id} menuOrderItem={i} />
                  ))}
                </List>
              </Fragment>
            )
          );
        })}
        {timelineMeal.restaurantMenuLinkPlateOrders.map((plateOrder, index) => {
          const restaurantMenuLink = allRestaurantMenuLinks.find(rml => rml.id === plateOrder.restaurantMenuLink.id);
          // type casting happening here, but should be okay as the rmlpo items and rmlo items are the same entity
          const rmlpoItems = plateOrder.plate.items;
          return (
            rmlpoItems.length > 0 &&
            restaurantMenuLink && (
              <Fragment key={`${plateOrder.id}_${index}`}>
                <Typography>{restaurantMenuLink.restaurant.name}</Typography>
                <List dense disablePadding>
                  {rmlpoItems.map((i, index) => (
                    <>
                      <MenuOrderItemListItem key={i.id} menuOrderItem={i} />
                      {index !== rmlpoItems.length - 1 && <Divider />}
                    </>
                  ))}
                </List>
              </Fragment>
            )
          );
        })}
      </List>
    ) : (
      <></>
    );

  const logList = (
    <List>
      <Typography variant="body1">
        <b>Log Items</b>
      </Typography>
      {timelineMeal.mealMenuLogs.map(
        log =>
          log.items.length > 0 && (
            <Fragment key={log.id}>
              <Typography>{log.mealMenu.name}</Typography>
              <List dense disablePadding>
                {log.items.map((i, index) => (
                  <>
                    <MenuOrderItemListItem key={i.id} menuOrderItem={{ ...i, specialRequests: null }} />
                    {index !== log.items.length - 1 && <Divider />}
                  </>
                ))}
              </List>
            </Fragment>
          )
      )}
      {timelineMeal.restaurantMenuLogs.map(log => (
        <List dense disablePadding>
          <Typography>{log.restaurant.name}</Typography>
          {log.items.map((i, index) => (
            <>
              <MenuOrderItemListItem key={i.id} menuOrderItem={{ ...i, specialRequests: null }} />
              {index !== log.items.length - 1 && <Divider />}
            </>
          ))}
        </List>
      ))}
      {timelineMeal.servingAmounts.length > 0 && (
        <List dense disablePadding>
          <ServingAmountViewChipList servingAmounts={timelineMeal.servingAmounts} />
        </List>
      )}
    </List>
  );

  const {
    palette: { greyscale },
  } = useTheme();

  return (
    <>
      <Card sx={{ border: `1px solid ${greyscale[100]}` }}>
        <CardHeader
          title={
            <div className={classes.header}>
              <Typography variant="h3">
                <b>{cardHeader}</b>
              </Typography>
              <NotificationCountLabel
                notifications={timelineMeal.notifications}
                max={9}
                onClickCommentNotification={onClickComment} />
            </div>
          }
          disableTypography
        />

        {previewImage && (
          <FoodLogPreviewImage
            imageCount={timelineMeal.images.length}
            previewImageUrl={previewImage.url}
            imageClassName={classes.image}
            onClick={() => setImageModalOpen(true)}
          ></FoodLogPreviewImage>
        )}
        <CardActionArea
          onClick={onClick}
          className={classes.actionArea}
          classes={{ focusHighlight: classes.focusHighlight }}>
          {counts.orderCount > 0 && orderList}
          {counts.logCount > 0 && logList}
          {counts.logCount === 0 && counts.orderCount === 0 && <Typography variant="body2">{actionText}</Typography>}
          <Divider className={classes.divider} />
        </CardActionArea>
        <Box sx={{ mt: 0, mb: 1, display: "flex", justifyContent: "space-around" }}>
          <MealMacrosAndNutrients mealServingAmounts={getTimelineMealServingAmounts(timelineMeal)} targetMacros={targetMacros} />
        </Box>
        <Divider className={classes.divider} />
        <CommentButton onClick={onClickComment} commentCount={timelineMeal.comments.length} />
      </Card>
      {imageModalOpen && <ImageModal
        open={imageModalOpen}
        onClose={() => setImageModalOpen(false)}
        images={timelineMeal.images} />}
    </>
  );
};

export default TimelineMealCard;
