import { AthleteFoodPreferenceContextProvider, ClientTimezoneContextProvider, Loading } from "@notemeal/shared-ui";
import { useUser } from "apps/web/src/utils/tokens";
import { useParams } from "react-router-dom-v5-compat";
import { useAthleteTimelineQuery, usePublishedTimelineMealSubscription } from "../../../../types";
import { AthleteFoodLogs } from "../../../../views/Athlete/FoodLogs/AthleteFoodLogs";
import { CalendarContextProvider } from "../../../../views/Athlete/FoodLogs/DateColumn/CalendarContext";
import { addTimelineMealComment } from "../../../../views/Athlete/FoodLogs/cache";

export const AthleteFoodLogsPage = () => {
  const { athleteId: maybeAthleteId } = useParams();
  const athleteId = maybeAthleteId || "";
  const { data, loading } = useAthleteTimelineQuery({
    variables: { athleteId },
  });

  const user = useUser();

  usePublishedTimelineMealSubscription({
    onSubscriptionData: ({ subscriptionData, client }) => {
      if (subscriptionData.data) {
        if (
          subscriptionData.data.timelineMeal.payload.__typename === "AddTimelineMealCommentPayload" &&
          subscriptionData.data.timelineMeal.payload.comment.by.id !== user?.id
        ) {
          const { payload } = subscriptionData.data.timelineMeal;
          addTimelineMealComment({
            client,
            timelineMealId: payload.timelineMeal.id,
            comment: payload.comment,
          });
        }
      }
    },
  });

  if (loading || !data) {
    return <Loading progressSize="lg" />;
  }

  return (
    <ClientTimezoneContextProvider>
      <CalendarContextProvider athleteId={athleteId}>
        <AthleteFoodPreferenceContextProvider
          likedFoods={[...data.athlete.likedFoods]}
          dislikedFoods={[...data.athlete.dislikedFoods]}
          likedFoodGroups={[...data.athlete.likedFoodGroups]}
          dislikedFoodGroups={[...data.athlete.dislikedFoodGroups]}
        >
          <AthleteFoodLogs data={data} />
        </AthleteFoodPreferenceContextProvider>
      </CalendarContextProvider>
    </ClientTimezoneContextProvider>
  );
};
