import { inputToNumber } from "@notemeal/shared-ui";
import { getMacrosInput, scaleMacros } from "@notemeal/shared-utils-macro-protocol";
import {
  ExchangeType,
  FoodCategoryType,
  FullServingAmountFragment,
  GptRecipeFragment,
  Macros,
  MealType,
  RecipeFullInput,
  RecipeServingFullInput,
  UnmatchedIngredients,
} from "../../../types";
import { ServingUnitsState, servingUnitsToInput } from "../../Serving/utils";
import { isErrantServingAmount } from "../../ServingAmounts/Edit/utils";
import { sortByKey } from "@notemeal/utils-sort";
import { newServingAmountWithAmount } from "@notemeal/shared-ui";

export interface Image {
  url: string;
  position: number;
  id: string;
}

export interface RecipeFormState {
  id?: string;
  name: string;
  ingredients: readonly FullServingAmountFragment[];
  exchangeTypes: readonly ExchangeType[] | null;
  steps: readonly string[];
  note: string;
  serving: RecipeServingFormState;
  choInput: string;
  proInput: string;
  fatInput: string;
  manualMacros: boolean;
  prepTimeInMinutesInput: string;
  cookTimeInMinutesInput: string;
  images: Image[];
  isShared: boolean;
  mealTypes: readonly MealType[];
  edited: boolean;
  saveClicked: boolean;
  foodCategory: FoodCategoryType | null;
  scoreValue: number | null;
  unmatchedIngredients: UnmatchedIngredients;
  isAiGenerated: boolean;
  isAiTranscribed: boolean;
}

export interface RecipeServingFormState {
  id?: string;
  units: ServingUnitsState;
  defaultAmountInput: string;
  defaultAmount: number;
  perRecipeYieldInput: string;
  perRecipeYield: number;
}

interface ChangeNameAction {
  type: "CHANGE_NAME";
  value: string;
}

interface ChangePrepTimeAction {
  type: "CHANGE_PREP_TIME";
  value: string;
}

interface ChangeCookTimeAction {
  type: "CHANGE_COOK_TIME";
  value: string;
}

interface ChangeIngredientsAction {
  type: "CHANGE_INGREDIENTS";
  value: readonly FullServingAmountFragment[];
}

interface ChangeStepsAction {
  type: "CHANGE_STEPS";
  value: string[];
}

interface ChangeNoteAction {
  type: "CHANGE_NOTE";
  value: string;
}

interface ChangeExchangeTypesAction {
  type: "CHANGE_EXCHANGE_TYPES";
  value: readonly ExchangeType[];
}

interface ChangeServingsAction {
  type: "CHANGE_SERVINGS";
  value: RecipeServingFormState;
}

interface ChangeChoInput {
  type: "CHANGE_CHO_INPUT";
  value: string;
}

interface ChangeProInput {
  type: "CHANGE_PRO_INPUT";
  value: string;
}

interface ChangeFatInput {
  type: "CHANGE_FAT_INPUT";
  value: string;
}

interface ChangeManualMacros {
  type: "CHANGE_MANUAL_MACROS";
  value: boolean;
}

interface ChangeImagesAction {
  type: "CHANGE_IMAGES";
  value: Image[];
}
interface ChangeIsShared {
  type: "CHANGE_IS_SHARED";
  value: boolean;
}
interface ChangeMealTypes {
  type: "CHANGE_MEAL_TYPES";
  value: readonly MealType[];
}

interface ChangeFoodCategory {
  type: "CHANGE_FOOD_CATEGORY";
  foodCategory: FoodCategoryType | null;
}

interface ChangeScore {
  type: "CHANGE_SCORE";
  value: number | null;
}

interface ReplaceState {
  type: "REPLACE_STATE";
  payload: RecipeFormState;
}

interface ChangeRecipeScale {
  type: "CHANGE_RECIPE_SCALE";
  payload: RecipeFormState;
}
interface ChangeEditedStateAction {
  type: "CHANGE_EDITED_STATE";
  payload: boolean;
}
interface RecipeSaveClicked {
  type: "CHANGE_RECIPE_SAVE_CLICK";
  payload: boolean;
}

export type RecipeFormAction =
  | ChangeNameAction
  | ChangeIngredientsAction
  | ChangeStepsAction
  | ChangeNoteAction
  | ChangeServingsAction
  | ChangeExchangeTypesAction
  | ChangeChoInput
  | ChangeProInput
  | ChangeFatInput
  | ChangeManualMacros
  | ChangePrepTimeAction
  | ChangeCookTimeAction
  | ChangeImagesAction
  | ChangeIsShared
  | ChangeMealTypes
  | ChangeFoodCategory
  | ChangeScore
  | ReplaceState
  | ChangeRecipeScale
  | ChangeEditedStateAction
  | RecipeSaveClicked;

export const recipeFormReducer = (state: RecipeFormState, action: RecipeFormAction): RecipeFormState => {
  switch (action.type) {
    case "CHANGE_NAME":
      return {
        ...state,
        edited: true,
        name: action.value,
      };
    case "CHANGE_INGREDIENTS":
      return {
        ...state,
        edited: true,
        // collapse and remove duplicate positions
        ingredients: sortByKey(action.value, "position").map((sa, index) => ({ ...sa, position: index + 1 })),
      };
    case "CHANGE_STEPS":
      return {
        ...state,
        edited: true,
        steps: action.value,
      };
    case "CHANGE_NOTE":
      return {
        ...state,
        edited: true,
        note: action.value,
      };
    case "CHANGE_SERVINGS":
      return {
        ...state,
        edited: true,
        serving: action.value,
      };
    case "CHANGE_EXCHANGE_TYPES":
      return {
        ...state,
        edited: true,
        exchangeTypes: action.value,
      };
    case "CHANGE_CHO_INPUT":
      return {
        ...state,
        edited: true,
        choInput: action.value,
      };
    case "CHANGE_PRO_INPUT":
      return {
        ...state,
        edited: true,
        proInput: action.value,
      };
    case "CHANGE_FAT_INPUT":
      return {
        ...state,
        edited: true,
        fatInput: action.value,
      };
    case "CHANGE_MANUAL_MACROS":
      return {
        ...state,
        edited: true,
        manualMacros: action.value,
        exchangeTypes: action.value ? [] : null,
      };
    case "CHANGE_PREP_TIME":
      return {
        ...state,
        edited: true,
        prepTimeInMinutesInput: action.value,
      };
    case "CHANGE_COOK_TIME":
      return {
        ...state,
        edited: true,
        cookTimeInMinutesInput: action.value,
      };
    case "CHANGE_IMAGES":
      return {
        ...state,
        edited: true,
        images: action.value,
      };
    case "CHANGE_IS_SHARED":
      return {
        ...state,
        edited: true,
        isShared: action.value,
      };
    case "CHANGE_MEAL_TYPES":
      return {
        ...state,
        edited: true,
        mealTypes: action.value,
      };
    case "CHANGE_FOOD_CATEGORY":
      return {
        ...state,
        edited: true,
        foodCategory: action.foodCategory,
      };
    case "CHANGE_SCORE":
      return {
        ...state,
        edited: true,
        scoreValue: action.value,
      };
    case "REPLACE_STATE":
      return action.payload;
    case "CHANGE_RECIPE_SCALE":
      return action.payload;
    case "CHANGE_EDITED_STATE":
      return {
        ...state,
        edited: action.payload,
      };
    case "CHANGE_RECIPE_SAVE_CLICK":
      return {
        ...state,
        saveClicked: action.payload,
      };
  }
};

export const newRecipeServingFormState = (): RecipeServingFormState => ({
  units: {
    customUnits: "serving(s)",
    unit: null,
    unitPrefix: null,
    unitSuffix: null,
  },
  perRecipeYield: 1,
  perRecipeYieldInput: "1",
  defaultAmount: 1,
  defaultAmountInput: "1",
});

export const newRecipeFormState = (initialName?: string, initialIngredients?: readonly FullServingAmountFragment[]): RecipeFormState => ({
  name: initialName || "",
  steps: [""],
  note: "",
  exchangeTypes: null,
  ingredients: initialIngredients ? initialIngredients.map(i => ({ ...i, expanded: false })) : [],
  serving: newRecipeServingFormState(),
  choInput: "",
  proInput: "",
  fatInput: "",
  manualMacros: false,
  prepTimeInMinutesInput: "",
  cookTimeInMinutesInput: "",
  images: [],
  isShared: false,
  mealTypes: [],
  edited: false,
  saveClicked: false,
  scoreValue: null,
  foodCategory: null,
  unmatchedIngredients: {
    unmatchedName: [],
    unmatchedServings: [],
  },
  isAiGenerated: false,
  isAiTranscribed: false,
});

export const newRecipeFormStateFromGPTRecipe = ({
  name,
  servingYield,
  ingredients,
  steps,
  unmatchedIngredients,
  aiType,
}: GptRecipeFragment): RecipeFormState => {
  const baseState = newRecipeFormState();
  return {
    ...baseState,
    name,
    ingredients: ingredients.map(i => newServingAmountWithAmount(i.serving, i.position, i.amount)),
    steps,
    unmatchedIngredients: unmatchedIngredients,
    serving: {
      ...baseState.serving,
      ...(servingYield && { perRecipeYield: servingYield.amount }),
      ...(servingYield && { perRecipeYieldInput: String(servingYield.amount) }),
    },
    edited: true,
    isAiGenerated: aiType === "generated",
    isAiTranscribed: aiType === "transcribed",
  };
};

export const formValidationMessages = {
  RECIPE_NAME: "Recipe name is required",
  INGREDIENTS: "At least one ingredient is required",
};

export const canSaveRecipeFormTooltips = (state: RecipeFormState): string[] => {
  const tooltips = [];
  if (!state.name) {
    tooltips.push(formValidationMessages.RECIPE_NAME);
  }
  if (!state.ingredients.length && !state.manualMacros) {
    tooltips.push(formValidationMessages.INGREDIENTS);
  }
  const badServing =
    inputToNumber(state.serving.perRecipeYieldInput) !== state.serving.perRecipeYield ||
    inputToNumber(state.serving.defaultAmountInput) !== state.serving.defaultAmount ||
    !state.serving.units;
  if (badServing) {
    tooltips.push("Servings not formatted correctly");
  }
  if (state.manualMacros) {
    if (inputToNumber(state.choInput) === null) {
      tooltips.push("CHO is required");
    }
    if (inputToNumber(state.proInput) === null) {
      tooltips.push("PRO is required");
    }
    if (inputToNumber(state.fatInput) === null) {
      tooltips.push("FAT is required");
    }
  }
  if (
    state.ingredients.some(i => {
      const amount = i.amount;
      return isErrantServingAmount({ amount });
    })
  ) {
    tooltips.push("Incorrect serving sizes.");
  }
  return tooltips;
};

export const recipeFormToInput = ({
  name,
  ingredients,
  exchangeTypes,
  steps,
  note,
  choInput,
  proInput,
  fatInput,
  manualMacros,
  prepTimeInMinutesInput,
  cookTimeInMinutesInput,
  serving,
  images,
  isShared,
  mealTypes,
  scoreValue,
  foodCategory,
  isAiGenerated,
  isAiTranscribed,
}: RecipeFormState): RecipeFullInput | null => {
  const { perRecipeYield, defaultAmount } = serving;

  let macros: Macros | null;
  if (!manualMacros) {
    macros = null;
  } else {
    const servingCho = inputToNumber(choInput);
    const servingPro = inputToNumber(proInput);
    const servingFat = inputToNumber(fatInput);
    if (servingCho !== null && servingPro !== null && servingFat !== null) {
      macros = scaleMacros(
        {
          cho: servingCho,
          pro: servingPro,
          fat: servingFat,
          kcal: 0,
        },
        perRecipeYield / defaultAmount
      );
    } else {
      return null;
    }
  }

  const imagesStripped = images.map(image => {
    return { url: image.url, position: image.position };
  });

  return {
    name,
    steps: steps.length > 1 || steps[0] ? steps : null,
    note: note || null,
    exchangeTypes,
    macros: macros ? getMacrosInput(macros) : null,
    prepTimeInMinutes: inputToNumber(prepTimeInMinutesInput),
    cookTimeInMinutes: inputToNumber(cookTimeInMinutesInput),
    ingredients: ingredients.map(({ serving, amount, position }) => ({
      servingId: serving.id,
      amount,
      position,
    })),
    images: imagesStripped,
    isShared,
    mealTypes,
    scoreValue,
    foodCategory,
    isAiGenerated,
    isAiTranscribed,
  };
};

export const recipeServingFormToInput = ({ units, defaultAmount, perRecipeYield }: RecipeServingFormState): RecipeServingFullInput => ({
  units: servingUnitsToInput(units),
  perRecipeYield,
  defaultAmount,
  isDefault: true,
});

export const scaleTheRecipeWithYield = (newYield: number, state: RecipeFormState): RecipeFormState => {
  const incrementFactor = newYield / state.serving.perRecipeYield;

  return {
    ...state,
    ingredients: state.ingredients.map(sa => {
      return { ...sa, amount: sa.amount * incrementFactor };
    }),
    serving: {
      ...state.serving,
      perRecipeYieldInput: `${newYield}`,
      perRecipeYield: newYield || state.serving.perRecipeYield,
    },
  };
};
