import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { EditMetricMacroProtocolState, editMetricMacroProtocolReducer } from "@notemeal/shared-ui";
import { useReducer } from "react";
import DialogTitle from "../../../../componentLibrary/DialogTitle";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { MetricMacroProtocolForm } from "../../../../components/MacroProtocol/Form/MetricMacroProtocolForm";
import { metricMacroProtocolStateToSaveTooltips } from "../../../../components/MacroProtocol/Form/utils";
import { GoalTypeFragment } from "../../../../types";

interface MetricMacroProtocolEditProps {
  initialState: EditMetricMacroProtocolState;
  goalTypes: readonly GoalTypeFragment[];
  open: boolean;
  onClose: () => void;
  onSave: (state: EditMetricMacroProtocolState) => void;
}

export const MetricMacroProtocolEdit = ({ initialState, goalTypes, onClose, open, onSave }: MetricMacroProtocolEditProps) => {
  const [metricState, metricDispatch] = useReducer(editMetricMacroProtocolReducer, initialState);

  const canSaveTooltips = metricMacroProtocolStateToSaveTooltips(metricState);

  const handleSave = () => {
    if (canSaveTooltips.length) {
      return;
    }
    onSave(metricState);
    onClose();
  };

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Macronutrient Protocol" onClose={onClose} />
      <DialogContent sx={{ pb: 0, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ color: "rgba(0, 0, 0, 0.60)" }}>
          <Typography variant="body2">
            * Example daily macros are never shown to the athlete and only exist as a helpful reference when you are reviewing food logs
            with an athlete who does not have a meal plan assigned. Daily Target Macros are for local reference only. Athletes will not see
            these changes.
          </Typography>
        </Box>
        <MetricMacroProtocolForm
          state={metricState}
          dispatch={metricDispatch}
          goalTypes={goalTypes} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
          <Button onClick={handleSave} disabled={canSaveTooltips.length > 0}>
            Done
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};
