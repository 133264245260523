import NotificationsIcon from "@mui/icons-material/Notifications";
import { Button, Dialog, DialogActions, DialogContent, MenuItem, SxProps, TextField, Tooltip } from "@mui/material";
import { round } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useState } from "react";

const OPTIONS = [15, 30, 45, 60, 90, 120, 150, 180, 210, 240, 270, 300, 360, 420, 480, 540, 600, 660, 720];

interface MenuNotificationSettingsButtonProps {
  value: number | null;
  onChange: (value: number | null) => void;
  noItemsAvailableForOrder?: boolean;
  sx?: SxProps;
}

const formatDuration = (dur: number) => `${dur >= 60 ? round(dur / 60, 2) : dur} ${dur === 60 ? "hr" : dur > 60 ? "hrs" : "mins"}  `;

// TODO: Allow custon time input as well as select form drop down
const MenuNotificationSettingsButton = ({
  value: _value,
  onChange,
  sx,
  noItemsAvailableForOrder = false,
}: MenuNotificationSettingsButtonProps) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(_value);

  return (
    <>
      <Tooltip
        title={
          <span>
            Edit Notification Settings
            {noItemsAvailableForOrder && (
              <>
                <br />
                No Items available for order!
              </>
            )}
          </span>
        }
      >
        <Button
          variant="text"
          startIcon={<NotificationsIcon sx={{ color: "greyscale.500" }} />}
          sx={sx}
          onClick={() => !noItemsAvailableForOrder && setOpen(true)}
        >
          {_value !== null && !noItemsAvailableForOrder ? formatDuration(_value) : "Off"}
        </Button>
      </Tooltip>
      {open && (
        <Dialog onClose={() => setOpen(false)} open={open}>
          <DialogTitle title="Time to Send Order Due Notifications?" onClose={() => setOpen(false)} />
          <DialogContent>
            <TextField
              select
              variant="outlined"
              margin="dense"
              value={value || "Never"}
              fullWidth
              onChange={e => setValue(parseInt(e.target.value) || null)}
            >
              <MenuItem value="Never">Never Send Order Due Notification</MenuItem>
              {OPTIONS.map(before => {
                return (
                  <MenuItem key={before} value={before}>
                    Send {formatDuration(before)} Before Order Due
                  </MenuItem>
                );
              })}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onChange(value);
                setOpen(false);
              }}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default MenuNotificationSettingsButton;
