import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Box, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { getMealPlanTemplateRmrErrors } from "@notemeal/shared-ui";
import { RmrCaloriesArgs, RmrMethod, getRmrCalories, getTotalCalories, rmrMethodToText } from "@notemeal/shared-utils-macro-protocol";
import { Dispatch } from "react";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { RMR_METHOD_ORDER } from "../../MacroProtocol/utils";
import { AnthropometryState } from "./reducer/anthropometryReducer";
import { CalorieBudgetAction, CalorieBudgetState, getCalorieBudgetFromState } from "./reducer/calorieBudgetReducer";
import { getAnthroSnapshotFromAnthroState } from "./utils/macroProtocolUtils";

const COLUMN1_WIDTH = 110;
const COLUMN2_WIDTH = 160;

interface CalorieBudgetProps {
  state: CalorieBudgetState;
  dispatch: Dispatch<CalorieBudgetAction>;
  anthropometry: AnthropometryState;
  disabled: boolean;
}

// Should receive anthro snapshot w/ weight target include already
const MealPlanTemplateCalorieBudget = ({ state, anthropometry, dispatch, disabled }: CalorieBudgetProps) => {
  const { rmrMethod, activityFactor, activityFactorInput, selectedPreviewGoal } = state;
  const anthropometrySnapshot = getAnthroSnapshotFromAnthroState(anthropometry);
  const calorieBudget = getCalorieBudgetFromState(state);
  const rmrCaloriesArgs: RmrCaloriesArgs = { rmrMethod, ...anthropometrySnapshot };

  const warningTitle = "RMR Method is not compatible with selected Anthropometry. Use the 'Back' button to make changes.";
  const rmrErrors = getMealPlanTemplateRmrErrors(rmrMethod, anthropometrySnapshot);
  const hasRmrErrors = rmrErrors.length > 0;

  let rmrCalories = 0;
  let totalCalories = 0;
  if (!hasRmrErrors) {
    try {
      rmrCalories = Math.round(getRmrCalories(rmrCaloriesArgs));
      totalCalories = Math.round(getTotalCalories(anthropometrySnapshot, calorieBudget));
    } catch (e) {}
  }

  const activityFactorCalories = Math.round((activityFactor - 1) * rmrCalories);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      {/* RMR Method */}
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography sx={{ width: COLUMN1_WIDTH }}>RMR Method</Typography>
        <Select
          sx={{ width: COLUMN2_WIDTH }}
          value={rmrMethod}
          disabled={disabled}
          onChange={event => {
            const rmrMethod = event.target.value as RmrMethod;
            dispatch({
              type: "EDIT_RMR_METHOD",
              payload: rmrMethod,
            });
          }}
        >
          {RMR_METHOD_ORDER.map(orderedRmrMethod => (
            <MenuItem value={orderedRmrMethod} key={orderedRmrMethod}>
              {rmrMethodToText(orderedRmrMethod)}
            </MenuItem>
          ))}
        </Select>

        {!hasRmrErrors && <Typography>{rmrCalories} kcal</Typography>}
        {hasRmrErrors && (
          <TWItemizedTooltip title={warningTitle} items={rmrErrors}>
            <PriorityHighIcon sx={{ color: "error.main" }} />
          </TWItemizedTooltip>
        )}
      </Box>

      {/* Activity Factor */}
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography sx={{ width: COLUMN1_WIDTH }}>Activity Factor</Typography>
        <TextField
          sx={{ mt: 0, width: COLUMN2_WIDTH }}
          disabled={disabled}
          type="number"
          inputProps={{ min: 1, max: 10, step: 0.05 }}
          value={!disabled && activityFactorInput}
          onChange={event => {
            dispatch({
              type: "EDIT_ACTIVITY_FACTOR",
              payload: event.target.value,
            });
          }}
        />
        <Typography>{!disabled && `${activityFactorCalories} kcal`}</Typography>
      </Box>

      {/* Goal */}
      <Box sx={{ height: 36, display: "flex", gap: 1, alignItems: "center" }}>
        <Typography sx={{ width: COLUMN1_WIDTH }}>Goal</Typography>
        <Tooltip title={selectedPreviewGoal?.name ?? ""}>
          <Typography noWrap sx={{ width: COLUMN2_WIDTH }}>
            {selectedPreviewGoal?.name ?? ""}
          </Typography>
        </Tooltip>
        <Typography>{!disabled && selectedPreviewGoal && `${selectedPreviewGoal.kcalOffset} kcal`}</Typography>
      </Box>

      {/* Totals */}
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography sx={{ width: COLUMN1_WIDTH }} variant="body1Semibold">
          TOTAL
        </Typography>
        <Box sx={{ width: COLUMN2_WIDTH }} />
        <Typography variant="body1Semibold">{!disabled && `${totalCalories} kcal`}</Typography>
      </Box>
    </Box>
  );
};

export default MealPlanTemplateCalorieBudget;
