import { formatHeight, useDateFormatting, useLocaleContext } from "@notemeal/shared-ui";
import { AnthropometryEntry } from "../../types";
import GenericTable, { ICol } from "../universal/GenericTable";
import { anthropometryEntryTypeToName, roundAnthroEntry } from "./utils";

interface AnthropometryEntryRow extends AnthropometryEntry {
  height_inches: string;
}

function formatTableCell(
  record: AnthropometryEntryRow,
  key: keyof AnthropometryEntryRow,
  formatDateWithLocale: (date: Date | string) => string
): string {
  switch (key) {
    case "height":
      return record["height"] ? formatHeight(record["height"]) : "";
    case "height_inches":
      return record["height_inches"] ? `${record["height_inches"]}"` : "";
    case "datetime":
      return formatDateWithLocale(record[key]);
    case "type":
      return anthropometryEntryTypeToName(record[key]);
    case "percentBodyFat":
      const pbf = record[key];
      return pbf === null ? "" : String(Math.round(10 * pbf) / 10);
    default:
      return record[key] === null ? "" : String(record[key]);
  }
}

export interface AnthropometryEntryTableProps {
  tableRows: AnthropometryEntry[];
  onClickAddEntry: () => void;
  onClickRow: (row: AnthropometryEntryRow) => void;
  onClickDeleteRow: (row: AnthropometryEntryRow) => void;
}

export const AnthropometryEntryTable = ({ tableRows, onClickAddEntry, onClickRow, onClickDeleteRow }: AnthropometryEntryTableProps) => {
  const { formatDateWithLocale } = useDateFormatting();
  const { isMetricLocale } = useLocaleContext();

  const tableRowsWithHeightInches = tableRows.map(anthrow => {
    const r = roundAnthroEntry(anthrow);
    return {
      ...r,
      height_inches: r["height"] ? String(r["height"]) : "",
    };
  });

  const imperialCols: ICol<AnthropometryEntry & { height_inches: string }>[] = [
    { id: "datetime", isNumeric: false, disablePadding: false, label: "Date" },
    { id: "height", isNumeric: true, disablePadding: false, label: "Height" },
    {
      id: "height_inches",
      isNumeric: true,
      disablePadding: false,
      label: "Height (in.)",
    },
    {
      id: "weight",
      isNumeric: true,
      disablePadding: false,
      label: "Weight (lbs)",
    },
    {
      id: "percentBodyFat",
      isNumeric: true,
      disablePadding: false,
      label: "Percent Body Fat (%)",
    },
    {
      id: "leanBodyMass",
      isNumeric: true,
      disablePadding: false,
      label: "Lean Mass (lbs)",
    },
    {
      id: "bodyFatMass",
      isNumeric: true,
      disablePadding: false,
      label: "Fat Mass (lbs)",
    },
    {
      id: "boneMineralDensityZScore",
      isNumeric: true,
      disablePadding: false,
      label: "BMD Z-Score",
    },
    { id: "type", isNumeric: false, disablePadding: false, label: "Type" },
  ];

  const handleFormatTableCell = (record: AnthropometryEntryRow, key: keyof AnthropometryEntryRow): string => {
    return formatTableCell(record, key, formatDateWithLocale);
  };
  const metricCols: ICol<AnthropometryEntry>[] = [
    { id: "datetime", isNumeric: false, disablePadding: false, label: "Date" },
    { id: "heightInCm", isNumeric: true, disablePadding: false, label: "Height (cm)" },
    {
      id: "weightInKg",
      isNumeric: true,
      disablePadding: false,
      label: "Weight (kg)",
    },
    {
      id: "percentBodyFat",
      isNumeric: true,
      disablePadding: false,
      label: "Percent Body Fat (%)",
    },
    {
      id: "leanBodyMassInKg",
      isNumeric: true,
      disablePadding: false,
      label: "Lean Mass (kg)",
    },
    {
      id: "bodyFatMassInKg",
      isNumeric: true,
      disablePadding: false,
      label: "Fat Mass (kg)",
    },
    {
      id: "boneMineralDensityZScore",
      isNumeric: true,
      disablePadding: false,
      label: "BMD Z-Score",
    },
    { id: "type", isNumeric: false, disablePadding: false, label: "Type" },
  ];

  const cols = isMetricLocale ? metricCols : imperialCols;

  return (
    <GenericTable
      size="small"
      tableRows={tableRowsWithHeightInches}
      tableCols={cols}
      addButtonText={"Add Entry"}
      onClickAddEntry={onClickAddEntry}
      onClickRow={onClickRow}
      onClickDeleteRow={onClickDeleteRow}
      formatTableCell={handleFormatTableCell}
      defaultOrderBy={"datetime"}
    />
  );
};
