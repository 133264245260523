import { Box, useTheme } from "@mui/material";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { serializeDate } from "@notemeal/utils-date-time";
import CalendarMonth, { CalendarMonthRendDayProps } from "apps/web/src/components/Calendar/Month";
import { useAthleteCalendarInRangePreviewQuery } from "apps/web/src/types";
import { addWeeks, endOfMonth, startOfMonth } from "date-fns";
import React from "react";
import { useMealPlanCalendarContext } from "../../contexts/Calendar";
import { useMealPlanColorsContext } from "../../contexts/Colors";
import ScheduleKey from "./ScheduleKey";

interface MealPlanCalendarPreviewProps {
  athleteId: string;
}

const MealPlanCalendarPreview = ({ athleteId }: MealPlanCalendarPreviewProps) => {
  const {
    palette: { teamworksCalendarEvent },
  } = useTheme();
  const { startOfMonthDate, onChangeDate } = useMealPlanCalendarContext();
  const { getMealPlanColor } = useMealPlanColorsContext();

  // Add / subtract extra week to handle days not part of the month still shown on calendar
  const start = serializeDate(addWeeks(startOfMonth(startOfMonthDate), -1));
  const end = serializeDate(addWeeks(endOfMonth(startOfMonthDate), 1));

  const { data, loading } = useAthleteCalendarInRangePreviewQuery({
    variables: {
      athleteId,
      start,
      end,
    },
  });

  const mealPlanDateAssignments = data?.athlete.mealPlanDateAssignmentsInRange ?? [];

  const renderDay = (props: PickersDayProps<Date> & CalendarMonthRendDayProps) => {
    const { isoDate, dayText, isCurrentMonth, ...other } = props;
    const dateAssignment = mealPlanDateAssignments.find(a => a.date === isoDate);

    return (
      <Box
        sx={{ position: "relative" }}
        onClick={() => {
          onChangeDate(isoDate);
        }}
      >
        <PickersDay
          {...other}
          disabled={!isCurrentMonth}
          selected={false}>
          {dayText}
        </PickersDay>

        {dateAssignment && (
          <Box
            sx={{
              position: "absolute",
              height: 6,
              width: 6,
              borderRadius: "50%",
              bottom: 2,
              left: "calc(50% - 3px)",
              backgroundColor: getMealPlanColor(dateAssignment.mealPlan.id),
            }}
          />
        )}
      </Box>
    );
  };

  // TODO: Move dedupe util to lib and use here
  const dedupedMealPlans = mealPlanDateAssignments.reduce<{ id: string; name: string }[]>((acc, { mealPlan }) => {
    if (!acc.find(a => a.id === mealPlan.id)) {
      acc.push(mealPlan);
    }
    return acc;
  }, []);

  let scheduleEntries = dedupedMealPlans.map(({ id, name }) => ({ id, label: name, color: getMealPlanColor(id) }));
  scheduleEntries = scheduleEntries.concat({
    id: "Teamworks",
    label: "Teamworks Calendar",
    color: teamworksCalendarEvent,
  });
  return (
    <>
      {!loading && <ScheduleKey
        titleProps={{ variant: "h3", sx: { pb: 2 } }}
        sx={{ py: 2, px: 1 }}
        entries={scheduleEntries} />}
      <Box sx={{ alignSelf: "center", ml: -5 }}>
        <CalendarMonth renderDay={renderDay} loading={loading} />
      </Box>
    </>
  );
};

export default MealPlanCalendarPreview;
