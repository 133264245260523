import { Dialog, DialogContent, DialogContentText, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocaleContext } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";

export interface FieldDefinition {
  fields: string[];
  required?: boolean;
  transform?: (x: any) => any;
  showDateFormat?: boolean;
}

interface ImportInfoDialogProps {
  fields: FieldDefinition[];
  open: boolean;
  setOpen: (x: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  padLeft: {
    paddingLeft: theme.spacing(2),
  },
  footer: {
    fontSize: 12,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const ImportInfoDialog = ({ fields, open, setOpen }: ImportInfoDialogProps) => {
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const { locale } = useLocaleContext();
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}>
      <DialogTitle title="Expected Column Headers" onClose={handleClose} />
      <DialogContent>
        <DialogContentText>Expects the following column headers to must be present in uploaded file:</DialogContentText>
        <br></br>
        <div className={classes.padLeft}>
          {fields.map(({ fields, required, transform, showDateFormat }) => (
            <Typography key={String(fields)} variant="subtitle1">
              <strong>{fields[0]}</strong>
              {required && "*"}
              {showDateFormat && `  Required Format: ${new Date().toLocaleDateString(locale)}`}
              <br />
            </Typography>
          ))}
          <DialogContentText className={classes.footer}>* indicates a required field.</DialogContentText>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ImportInfoDialog;
