import { List, styled } from "@mui/material";
import { newId, servingAmountsUseOldRecipe } from "@notemeal/shared-ui";
import {
  AddMenuItemChoiceInput,
  AddMenuItemChoiceOptionInput,
  EditMenuItemChoiceInput,
  EditMenuItemInput,
  MenuItemChoiceFormFragment,
  MenuItemChoiceOptionFormFragment,
} from "../../types";

interface getMenuItemChoiceDiffInputsArgs {
  initial: readonly MenuItemChoiceFormFragment[];
  final: readonly MenuItemChoiceFormFragment[];
}

type getMenuItemChoiceDiffInputsValue = Pick<EditMenuItemInput, "addChoices" | "editChoices" | "removeChoices">;

export const getMenuItemChoiceDiffInputs = ({ initial, final }: getMenuItemChoiceDiffInputsArgs): getMenuItemChoiceDiffInputsValue => {
  const initialIds = initial.map(mi => mi.id);
  const finalIds = final.map(mi => mi.id);

  return {
    addChoices: final.filter(mi => !initialIds.includes(mi.id)).map(getMenuItemChoiceInput),
    // TODO: Only add here if actually changed
    editChoices: final
      .filter(mic => initialIds.includes(mic.id))
      .map(finalMIC => {
        const initialMIC = initial.find(mic => mic.id === finalMIC.id);
        if (!initialMIC) {
          // TODO: Could refactor to avoid this error but it'd be a pain, should never reach this point
          throw new Error("Invariant violation");
        } else {
          return {
            menuItemChoiceId: finalMIC.id,
            name: finalMIC.name,
            position: finalMIC.position,
            required: finalMIC.required,
            maxOptionsCount: finalMIC.maxOptionsCount,
            ...getMenuItemChoiceOptionDiffInputs({
              initial: initialMIC.options,
              final: finalMIC.options,
            }),
          };
        }
      }),
    removeChoices: initialIds.filter(id => !finalIds.includes(id)).map(id => ({ menuItemChoiceId: id })),
  };
};

export const getMenuItemChoiceInput = (menuItemChoice: MenuItemChoiceFormFragment): AddMenuItemChoiceInput => ({
  name: menuItemChoice.name,
  position: menuItemChoice.position,
  required: menuItemChoice.required,
  maxOptionsCount: menuItemChoice.maxOptionsCount,
  options: menuItemChoice.options.map(getMenuItemChoiceOptionInput),
});

interface getMenuItemChoiceOptionDiffInputsArgs {
  initial: readonly MenuItemChoiceOptionFormFragment[];
  final: readonly MenuItemChoiceOptionFormFragment[];
}

type getMenuItemChoiceOptionDiffInputsValue = Pick<EditMenuItemChoiceInput, "addOptions" | "editOptions" | "removeOptions">;

const getMenuItemChoiceOptionDiffInputs = ({
  initial,
  final,
}: getMenuItemChoiceOptionDiffInputsArgs): getMenuItemChoiceOptionDiffInputsValue => {
  const initialIds = initial.map(mico => mico.id);
  const finalIds = final.map(mico => mico.id);

  return {
    addOptions: final.filter(mico => !initialIds.includes(mico.id)).map(getMenuItemChoiceOptionInput),
    // TODO: Only add here if actually changed
    editOptions: final
      .filter(mico => initialIds.includes(mico.id))
      .map(mico => ({
        menuItemChoiceOptionId: mico.id,
        menuItemChoiceOption: {
          name: mico.name,
          position: mico.position,
          canEditAmount: mico.canEditAmount,
          defaultAmount: mico.defaultAmount,
          maxAmount: mico.maxAmount,
          servingAmounts: mico.servingAmounts.map(sa => ({
            servingId: sa.serving.id,
            amount: sa.amount,
            position: sa.position,
          })),
        },
      })),
    removeOptions: initialIds.filter(id => !finalIds.includes(id)).map(id => ({ menuItemChoiceOptionId: id })),
  };
};

export const getMenuItemChoiceOptionInput = (menuItemChoiceOption: MenuItemChoiceOptionFormFragment): AddMenuItemChoiceOptionInput => ({
  name: menuItemChoiceOption.name,
  position: menuItemChoiceOption.position,
  canEditAmount: menuItemChoiceOption.canEditAmount,
  defaultAmount: menuItemChoiceOption.defaultAmount,
  maxAmount: menuItemChoiceOption.maxAmount,
  servingAmounts: menuItemChoiceOption.servingAmounts.map(sa => ({
    servingId: sa.serving.id,
    amount: sa.amount,
    position: sa.position,
  })),
});

export const menuItemChoiceIsMissingAllIngredients = (menuItemChoice: MenuItemChoiceFormFragment): boolean => {
  return menuItemChoice.options.every(o => o.servingAmounts.length === 0);
};

export const menuItemChoiceIsMissingAnyIngredients = (menuItemChoice: MenuItemChoiceFormFragment): boolean => {
  return menuItemChoice.options.some(o => o.servingAmounts.length === 0);
};

export const menuItemChoiceUsesOldRecipe = (menuItemChoice: MenuItemChoiceFormFragment): boolean => {
  return servingAmountsUseOldRecipe(menuItemChoice.options.flatMap(o => o.servingAmounts));
};

export const newMenuItemChoice = (position: number): MenuItemChoiceFormFragment => {
  return {
    id: newId(),
    name: "",
    position,
    required: false,
    maxOptionsCount: 1,
    options: [],
  };
};

export const newMenuItemChoiceOption = (position: number): MenuItemChoiceOptionFormFragment => {
  return {
    id: newId(),
    name: "",
    position,
    canEditAmount: false,
    defaultAmount: 1,
    maxAmount: 1,
    servingAmounts: [],
  };
};

//menu item choice tool tip messaging
export const ADD_AT_LEAST_ONE_OPTION = "Add at least one option";
export const CHOICE_NAME_REQUIRED = "Choice name is required";
export const MAX_OPTION_COUNT = "Max options count must be a number";
export const DEFAULT_GREATER_THAN_MAX_AMOUNT = "Option default amount cannot be greater than max amount";
export const DEFAULT_AMOUNT_REQUIRED = "Option default amount is required";
export const MAX_AMOUNT_REQUIRED = "Option max amount is required";

export const getMenuItemChoiceToolTip = (menuItemChoice: MenuItemChoiceFormFragment) => {
  let tooltipItems = [];
  if (!menuItemChoice.options.length) {
    tooltipItems.push(ADD_AT_LEAST_ONE_OPTION);
  }
  if (!menuItemChoice.name.length) {
    tooltipItems.push(CHOICE_NAME_REQUIRED);
  }
  if (menuItemChoice.maxOptionsCount !== null && isNaN(menuItemChoice.maxOptionsCount)) {
    tooltipItems.push(MAX_OPTION_COUNT);
  }
  if (menuItemChoice.options.some(o => o.defaultAmount > o.maxAmount)) {
    tooltipItems.push(DEFAULT_GREATER_THAN_MAX_AMOUNT);
  }
  if (menuItemChoice.options.some(o => isNaN(o.defaultAmount))) {
    tooltipItems.push(DEFAULT_AMOUNT_REQUIRED);
  }
  if (menuItemChoice.options.some(o => isNaN(o.maxAmount))) {
    tooltipItems.push(MAX_AMOUNT_REQUIRED);
  }
  return tooltipItems;
};

export const OptionListWrapper = styled(List)(({ theme: { spacing } }) => ({
  marginRight: spacing(),
  height: "100%",
  overflow: "auto",
}));
