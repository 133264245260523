import { AthleteMobileState, newId } from "@notemeal/shared-ui";
import { roundToHundredthsFloor } from "@notemeal/shared-utils-macro-protocol";
import { serializeDate } from "@notemeal/utils-date-time";
import {
  AthleteFilter,
  EditableRosterAthleteOrgMembershipFragment,
  EditableRosterAthleteUserFragment,
  EngagementTableAthleteFragment,
  FoodLogNotificationSettings,
  GoalTypeFragment,
  MyNamedTagsPreviewFragment,
  PositionFragment,
  PreferencesTableAthleteFragment,
  ProfileTableAthleteFragment,
} from "../../../../types";
import { TableFilter } from "./Toolbar";
export type Position = PositionFragment;
export type GoalType = GoalTypeFragment;

export interface AthleteProfileState {
  __typename: "Athlete";
  id: string;
  activationLinkSent: string | null;
  user: EditableRosterAthleteUserFragment | null;
  orgMembership: EditableRosterAthleteOrgMembershipFragment | null;
  username: string | null;
  leanBodyMass: number | null;
  weight: number | null;
  height: number | null;
  firstName: string | null;
  lastName: string | null;
  birthDate: string | null;
  position: Position | null;
  phoneNumber: string | null;
  email: string | null;
  secaUid: string | null;
  inbodyUid: string | null;
  goalType: GoalType | null;
  kcalOffset: number | null;
  foodLogNotificationSettings: FoodLogNotificationSettings | null;
  jerseyNumber: string | null;
}

export interface IAthleteRowInputBase {
  __typename: "Athlete";
  id: string;
  activationLinkSent: string | null;
  user: EditableRosterAthleteUserFragment | null;
  orgMembership: EditableRosterAthleteOrgMembershipFragment | null;
  username: string;
  firstName: string;
  lastName: string;
  position: Position | null;
  jerseyNumber: string | null;
  phoneNumber: string;
  email: string;
  foodLogNotificationSettings: FoodLogNotificationSettings | null;
  isArchived: boolean;
  isProfileNotemealOnly: boolean;
}

export interface IAthleteEngagementRowInput extends IAthleteRowInputBase {
  mealsLoggedCount: number;
  mealsOrderedCount: number;
  totalMealsCount: number;
  imageUploadCount: number;
  mealPlanViewCount: number;
}

export interface IAthleteProfileRowInput extends IAthleteRowInputBase {
  height: string;
  weight: string;
  heightInCm: string;
  weightInKg: string;
  leanBodyMass: string;
  leanBodyMassInKg: string;
  birthDate: string;
  secaUid: string;
  inbodyUid: string;
  goalType: GoalType | null;
  kcalOffset: string;
  myNamedTagsPreview: MyNamedTagsPreviewFragment;
}

export interface IAthletePreferenceRowInput extends IAthleteRowInputBase {
  dislikedFoodGroupNames: string;
}

export const athletesToProfileRows = (data: readonly ProfileTableAthleteFragment[]): IAthleteProfileRowInput[] => {
  return data.map(ath => {
    const { leanBodyMass, weight, height, weightInKg, heightInCm, leanBodyMassInKg } = ath.mostRecentAnthropometryEntry || {
      leanBodyMass: null,
      weight: null,
      height: null,
      leanBodyMassInKg: null,
      weightInKg: null,
      heightInCm: null,
      percentBodyFat: null,
    };
    const goal = ath.currentGoal || {
      type: { id: null, name: "" },
      kcalOffset: null,
    };
    const kcalOffset = goal.kcalOffset;
    const {
      firstName,
      lastName,
      birthDate,
      position,
      phoneNumber,
      email,
      secaUid,
      inbodyUid,
      foodLogNotificationSettings,
      isArchived,
      jerseyNumber,
      isProfileNotemealOnly,
    } = ath;

    return {
      __typename: ath.__typename,
      id: ath.id,
      user: ath.user,
      orgMembership: ath.orgMembership,
      username: ath?.user?.username || "",
      activationLinkSent: ath.activationLinkSent,
      foodLogNotificationSettings,
      firstName: firstName || "",
      lastName: lastName || "",
      birthDate: birthDate || "",
      position,
      phoneNumber: phoneNumber || "",
      email: email || "",
      secaUid: secaUid || "",
      inbodyUid: inbodyUid || "",
      goalType: goal.type as GoalType,
      kcalOffset: kcalOffset ? String(kcalOffset) : "",
      leanBodyMass: leanBodyMass ? String(roundToHundredthsFloor(leanBodyMass)) : "",
      weight: weight ? String(roundToHundredthsFloor(weight)) : "",
      height: height ? String(roundToHundredthsFloor(height)) : "",
      leanBodyMassInKg: leanBodyMassInKg ? String(roundToHundredthsFloor(leanBodyMassInKg)) : "",
      weightInKg: weightInKg ? String(roundToHundredthsFloor(weightInKg)) : "",
      heightInCm: heightInCm ? String(roundToHundredthsFloor(heightInCm)) : "",
      isArchived,
      jerseyNumber,
      myNamedTagsPreview: ath.myNamedTagsPreview,
      isProfileNotemealOnly,
    };
  });
};

export const athletesToEngagementRows = (data: readonly EngagementTableAthleteFragment[]): IAthleteEngagementRowInput[] => {
  return data.map(ath => {
    const { firstName, lastName, position, phoneNumber, email, foodLogNotificationSettings, jerseyNumber, isProfileNotemealOnly } = ath;

    return {
      __typename: ath.__typename,
      id: ath.id,
      user: ath.user,
      orgMembership: ath.orgMembership,
      username: ath?.user?.username || "",
      activationLinkSent: ath.activationLinkSent,
      foodLogNotificationSettings,
      firstName: firstName || "",
      lastName: lastName || "",
      position,
      jerseyNumber,
      phoneNumber: phoneNumber || "",
      email: email || "",
      mealsLoggedCount: ath.mealsLoggedCount,
      mealsOrderedCount: ath.mealsOrderedCount,
      totalMealsCount: ath.totalMealsCount,
      mealPlanViewCount: ath.mealPlanViewCount,
      imageUploadCount: ath.imageUploadCount,
      isArchived: ath.isArchived,
      isProfileNotemealOnly,
    };
  });
};

export const athletesToPreferenceRows = (data: readonly PreferencesTableAthleteFragment[]): IAthletePreferenceRowInput[] => {
  return data.map(ath => {
    return {
      __typename: ath.__typename,
      id: ath.id,
      user: ath.user,
      orgMembership: ath.orgMembership,
      username: ath?.user?.username || "",
      activationLinkSent: ath.activationLinkSent,
      foodLogNotificationSettings: ath.foodLogNotificationSettings,
      firstName: ath.firstName || "",
      lastName: ath.lastName || "",
      position: ath.position,
      phoneNumber: ath.phoneNumber || "",
      email: ath.email || "",
      dislikedFoodGroupNames: ath.dislikedFoodGroups.map(f => f.name).join(", "),
      isArchived: ath.isArchived,
      jerseyNumber: ath.jerseyNumber,
      isProfileNotemealOnly: ath.isProfileNotemealOnly,
    };
  });
};

export const mobileStateToTooltip = (state: AthleteMobileState): string => {
  switch (state) {
    case "active":
      return "Mobile app active.";
    case "inactive":
      return "Click to send activation link.";
    case "expired":
      return "Activation link expired. Click to resend.";
    case "waiting":
      return "Waiting on athlete activation. Click to resend link.";
    default:
      return "Error";
  }
};

export const buildInitialCreateFormState = (): AthleteProfileState => {
  return {
    __typename: "Athlete",
    id: newId(),
    user: null,
    orgMembership: null,
    username: "",
    activationLinkSent: null,
    foodLogNotificationSettings: null,
    firstName: "",
    lastName: "",
    birthDate: null,
    position: null,
    phoneNumber: "",
    email: "",
    secaUid: "",
    inbodyUid: "",
    goalType: null,
    kcalOffset: null,
    leanBodyMass: null,
    weight: null,
    height: null,
    jerseyNumber: null,
  };
};

interface ChangeSecaUid {
  type: "CHANGE_SECA_UID";
  payload: string;
}
interface ChangeInbodyUid {
  type: "CHANGE_INBODY_UID";
  payload: string;
}

interface ChangeFirstName {
  type: "CHANGE_FIRST_NAME";
  payload: string;
}

interface ChangeLastName {
  type: "CHANGE_LAST_NAME";
  payload: string;
}

interface ChangeBirthDate {
  type: "CHANGE_BIRTH_DATE";
  payload: Date | null;
}

interface ChangeEmail {
  type: "CHANGE_EMAIL";
  payload: string;
}

interface ChangePhoneNumber {
  type: "CHANGE_PHONE_NUMBER";
  payload: string;
}

interface ChangePosition {
  type: "CHANGE_POSITION";
  payload: Position;
}

interface ChangeHeight {
  type: "CHANGE_HEIGHT";
  payload: string;
}

interface ChangeWeight {
  type: "CHANGE_WEIGHT";
  payload: string;
}
interface ChangeLeanMass {
  type: "CHANGE_LEAN_MASS";
  payload: string;
}
interface ChangeGoalType {
  type: "CHANGE_GOAL_TYPE";
  payload: GoalType;
}
interface ChangeKcalOffset {
  type: "CHANGE_KCAL_OFFSET";
  payload: string;
}
interface ChangeJerseyNumber {
  type: "CHANGE_JERSEY_NUMBER";
  payload: string;
}

export type AthleteTableAction =
  | ChangeFirstName
  | ChangeLastName
  | ChangeBirthDate
  | ChangeEmail
  | ChangePhoneNumber
  | ChangePosition
  | ChangeSecaUid
  | ChangeInbodyUid
  | ChangeHeight
  | ChangeWeight
  | ChangeLeanMass
  | ChangeKcalOffset
  | ChangeGoalType
  | ChangeJerseyNumber;

export const athleteTableReducer = (state: AthleteProfileState, action: AthleteTableAction): AthleteProfileState => {
  switch (action.type) {
    case "CHANGE_SECA_UID":
      return { ...state, secaUid: action.payload };
    case "CHANGE_INBODY_UID":
      return { ...state, inbodyUid: action.payload };
    case "CHANGE_FIRST_NAME":
      return { ...state, firstName: action.payload };
    case "CHANGE_LAST_NAME":
      return { ...state, lastName: action.payload };
    case "CHANGE_BIRTH_DATE":
      return {
        ...state,
        birthDate: action.payload ? serializeDate(action.payload) : null,
      };
    case "CHANGE_EMAIL":
      return { ...state, email: action.payload };
    case "CHANGE_PHONE_NUMBER":
      return { ...state, phoneNumber: action.payload };
    case "CHANGE_POSITION":
      return { ...state, position: action.payload };
    case "CHANGE_HEIGHT":
      return { ...state, height: Number(action.payload) };
    case "CHANGE_WEIGHT":
      return { ...state, weight: Number(action.payload) };
    case "CHANGE_LEAN_MASS":
      return { ...state, leanBodyMass: Number(action.payload) };
    case "CHANGE_GOAL_TYPE":
      return { ...state, goalType: action.payload };
    case "CHANGE_KCAL_OFFSET":
      return { ...state, kcalOffset: Number(action.payload) };
    case "CHANGE_JERSEY_NUMBER":
      return { ...state, jerseyNumber: action.payload };
  }
};

export const getAthleteFilter = (tableFilter: TableFilter): AthleteFilter | null => {
  if (tableFilter === "all") {
    return null;
  }
  return tableFilter;
};
