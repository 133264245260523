import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import EditMealPlanTemplate from "../../components/MealPlanTemplate/EditMealPlanTemplate";
import LoadingBackdrop from "../../components/universal/LoadingBackdrop";
import {
  FullMealPlanTemplateFragment,
  GoalTypeFragment,
  useDeleteMacroMealPlanTemplatePendingStateMutation,
  useEditMacroMealPlanTemplateMutation,
  useMealPlanTemplateQuery,
  usePublishMacroMealPlanTemplateMutation,
} from "../../types";

interface EditOrgMealPlanTemplateProps {
  open: boolean;
  onEditSuccess: (mealPlanTemplate: FullMealPlanTemplateFragment) => void;
  onDiscardEditSuccess: () => void;
  onPublishSuccess: (mealPlanTemplate: FullMealPlanTemplateFragment) => void;
  onClose: () => void;
  goalTypes: readonly GoalTypeFragment[];
  id: string;
  copyMealPlanTemplate: () => void;
  removeMealPlanTemplate: (mealPlanTemplate: FullMealPlanTemplateFragment) => void;
}

export const EditOrgMealPlanTemplate = ({
  open,
  onEditSuccess,
  onDiscardEditSuccess,
  onPublishSuccess,
  onClose,
  goalTypes,
  id,
  copyMealPlanTemplate,
  removeMealPlanTemplate,
}: EditOrgMealPlanTemplateProps) => {
  const [editMealPlanTemplate] = useEditMacroMealPlanTemplateMutation({
    onCompleted: data => onEditSuccess(data.editMacroMealPlanTemplate.macroMealPlanTemplate),
  });
  const [publishMealPlanTemplate] = usePublishMacroMealPlanTemplateMutation({
    onCompleted: data => onPublishSuccess(data.publishMacroMealPlanTemplate.macroMealPlanTemplate),
  });
  const [deletePendingState] = useDeleteMacroMealPlanTemplatePendingStateMutation({
    onCompleted: data => onDiscardEditSuccess(),
  });
  const { data, loading } = useMealPlanTemplateQuery({ variables: { id } });

  return loading || !data?.mealPlanTemplate ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen>
      <DialogContent>
        <EditMealPlanTemplate
          mealPlanTemplate={data.mealPlanTemplate}
          editMealPlanTemplate={input => editMealPlanTemplate({ variables: { input } })}
          publishMealPlanTemplate={input => publishMealPlanTemplate({ variables: { input } })}
          deletePendingState={input => deletePendingState({ variables: { input } })}
          onClose={onClose}
          goalTypes={goalTypes}
          copyMealPlanTemplate={copyMealPlanTemplate}
          removeMealPlanTemplate={() => removeMealPlanTemplate(data.mealPlanTemplate)}
        />
      </DialogContent>
    </Dialog>
  );
};
