import { useLocaleContext } from "@notemeal/shared-ui";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { useState } from "react";
import { AnthropometryImportTabPanel } from "../../../../components/views/Import/Anthropometry/AnthropometryTabPanel";
import { AnthroImportType, GENERAL, anthroImportTabs, getAnthroImportProps } from "./Utils";

export const ImportAnthropometryPage = () => {
  const [selected, setSelected] = useState(GENERAL);
  const { isMetricLocale } = useLocaleContext();

  // TODO: expand GB anthro import to other types
  const localeAnthroImportTabs = isMetricLocale ? [GENERAL] : anthroImportTabs;

  return (
    <>
      {!isMetricLocale && <TWTabGroup tabs={localeAnthroImportTabs} onSelected={selected => setSelected(selected)} />}
      <AnthropometryImportTabPanel
        {...getAnthroImportProps(selected as AnthroImportType)}
        useExistingWeight={false}
        disableInsert={false}
      />
    </>
  );
};
