import { TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { EXCHANGE_TYPE_ORDER } from "@notemeal/shared-ui";
import { Dispatch } from "react";
import { ExchangeType } from "../../../types";
import ExchangeGroup from "./ExchangeGroup";
import ExchangeInput from "./ExchangeInput";
import { ExchangeSetFormAction, ExchangeSetFormState } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: "3fr 11.5fr",
      gridTemplateRows: "auto 1fr",
      height: "100%",
    },
    name: {
      gridColumnStart: 1,
      gridRowStart: 1,
    },
    right: {
      gridColumnStart: 2,
      gridRowStart: 1,
      gridRowEnd: 3,
    },
    exchangesList: {
      gridColumnStart: 1,
      gridRowStart: 2,
      overflowY: "auto",
    },
  })
);

interface ExchangeSetFormProps {
  state: ExchangeSetFormState;
  dispatch: Dispatch<ExchangeSetFormAction>;
}

const ExchangeSetForm = ({ state, dispatch }: ExchangeSetFormProps) => {
  const classes = useStyles();

  const handleSelect = (id: string) => dispatch({ type: "SELECT_EXCHANGE_BY_ID", payload: id });
  const handleAdd = (type: ExchangeType) => dispatch({ type: "ADD_EXCHANGE_BY_TYPE", payload: type });
  const handleRemove = (id: string) => dispatch({ type: "REMOVE_EXCHANGE_BY_ID", payload: id });

  const selectedExchange = state.exchanges.find(ex => ex.id === state.selectedExchangeId);

  return (
    <div className={classes.root}>
      <TextField
        autoFocus
        value={state.name}
        className={classes.name}
        onChange={e =>
          dispatch({
            type: "CHANGE_NAME",
            payload: e.target.value,
          })
        }
        label="Exchange Set Name"
      />
      <div className={classes.exchangesList}>
        {EXCHANGE_TYPE_ORDER.map(type => (
          <ExchangeGroup
            key={type}
            type={type}
            exchanges={state.exchanges}
            selectedExchangeId={state.selectedExchangeId}
            onAdd={handleAdd}
            onSelect={handleSelect}
            onRemove={handleRemove}
          />
        ))}
      </div>
      <div className={classes.right}>
        {selectedExchange && (
          <ExchangeInput
            exchange={selectedExchange}
            onEditName={name =>
              dispatch({
                type: "EDIT_EXCHANGE_NAME",
                payload: { id: selectedExchange.id, name },
              })
            }
            onEditShortName={shortName =>
              dispatch({
                type: "EDIT_EXCHANGE_SHORT_NAME",
                payload: { id: selectedExchange.id, shortName },
              })
            }
            onEditMacroInput={(input, macro) =>
              dispatch({
                type: "EDIT_EXCHANGE_MACRO",
                payload: { id: selectedExchange.id, input, macro },
              })
            }
            onEditMasterServingListServingAmounts={servingAmounts =>
              dispatch({
                type: "EDIT_EXCHANGE_MASTER_SERVING_LIST_SERVING_AMOUNTS",
                payload: { id: selectedExchange.id, servingAmounts },
              })
            }
            onEditMasterServingListAutoScale={() =>
              dispatch({
                type: "EDIT_EXCHANGE_AUTOSCALE_MASTER_SERVING_LIST",
                payload: { id: selectedExchange.id },
              })
            }
          />
        )}
        {/*
            onAddServingList={() => dispatch({
              type: 'EDIT_EXCHANGE_ADD_SERVING_LIST',
              payload: {id: selectedExchange.id}
            })}
            onEditServingListName={(servingListId, name) => dispatch({
              type: 'EDIT_EXCHANGE_SERVING_LIST_NAME',
              payload: {id: selectedExchange.id, servingListId, name}
            })}
              */}
      </div>
    </div>
  );
};

export default ExchangeSetForm;
