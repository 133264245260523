import { RestaurantMenuLinkPlateItemWithAppearance } from "@notemeal/shared-ui";
import { useState } from "react";
import { MenuItemAppearancePreviewFragment } from "../../../../../types";

type RestaurantMenuLinkPlateDialogState =
  | {
      type: "newMenuItem";
      menuItemAppearance: MenuItemAppearancePreviewFragment;
      newPlateItem: RestaurantMenuLinkPlateItemWithAppearance | null;
    }
  | {
      type: "editMenuItem";
      plateItem: RestaurantMenuLinkPlateItemWithAppearance;
    }
  | {
      type: "menuSections";
    };

export const usePlateDialogState = () => {
  return useState<RestaurantMenuLinkPlateDialogState>({
    type: "menuSections",
  });
};
