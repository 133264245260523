import { datadogRum } from "@datadog/browser-rum";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { Box, Button, IconButton, MenuItem, TextField } from "@mui/material";
import { grayBackground } from "@notemeal/palette";
import { parseTime, serializeTime } from "@notemeal/utils-date-time";
import ReorderDialog, { Reorderable } from "apps/web/src/components/universal/ReorderDialog";
import { addMinutes, eachMinuteOfInterval, format, startOfToday, startOfTomorrow, subMinutes } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, UseFormReturn, useFieldArray } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { useMenuBuilderContext } from "../MenuBuilderProvider";
import { MenuBuilderMealGrid } from "./MenuBuilderMealGrid";
import { MenuBuilderType, getPlannedMenuMealRowFormValues } from "./MenuBuilderMealSchema";

interface MenuBuilderMealProps {
  mealFormIndex: number;
  weekFormIndex: number;
  form: UseFormReturn<MenuBuilderType>;
  deleteMeal: () => void;
}

export const MenuBuilderMeal = ({ mealFormIndex, weekFormIndex, form, deleteMeal }: MenuBuilderMealProps) => {
  const { mealGridWidth, menuId, isEditable, markMealAsUpdated } = useMenuBuilderContext();
  const [expanded, setExpanded] = useState(true);
  const [reorderDialogOpen, setReorderDialogOpen] = useState(false);
  const {
    control,
    formState: { errors },
  } = form;

  const rowsFieldArray = useFieldArray<MenuBuilderType>({
    control: form.control,
    name: `weeks.${weekFormIndex}.meals.${mealFormIndex}.rows`,
  });

  const addRow = useCallback(() => {
    rowsFieldArray.append(getPlannedMenuMealRowFormValues(null, rowsFieldArray.fields.length));
  }, [rowsFieldArray]);

  useEffect(() => {
    if (rowsFieldArray.fields.length === 0) {
      addRow();
    }
  }, [rowsFieldArray.fields.length, addRow]);

  const [startTime, endTime] = form.watch([
    `weeks.${weekFormIndex}.meals.${mealFormIndex}.startTime`,
    `weeks.${weekFormIndex}.meals.${mealFormIndex}.endTime`,
  ]);

  // limit start and end time selections so start time must be before end time
  // values are formatted 13:15:00 and display is 01:15 PM
  const timeFormat = "hh:mm aa";
  const startTimeOptions = () => {
    const quarterBeforeEndTime = subMinutes(endTime.length > 0 ? parseTime(endTime) : startOfTomorrow(), 15);
    // avoid erroring when the interval would be 00:00:00 to 00:00:00
    if (endTime === "00:15:00") {
      return [startOfToday()].map(serializeTime);
    }
    return eachMinuteOfInterval({ start: startOfToday(), end: quarterBeforeEndTime }, { step: 15 }).map(serializeTime);
  };
  const endTimeOptions = () => {
    const quarterAfterStartTime = addMinutes(startTime.length > 0 ? parseTime(startTime) : startOfToday(), 15);
    const quarterBeforeTomorrow = subMinutes(startOfTomorrow(), 15);
    // avoid erroring when the interval would be 23:45:00 to 23:45:00
    if (startTime === "23:30:00") {
      return [quarterBeforeTomorrow].map(serializeTime);
    }
    return eachMinuteOfInterval({ start: quarterAfterStartTime, end: quarterBeforeTomorrow }, { step: 15 }).map(serializeTime);
  };

  const onReOrderRows = (reOrderedArray: readonly Reorderable[]) => {
    const rows = form.getValues(`weeks.${weekFormIndex}.meals.${mealFormIndex}.rows`) || [];

    const updatedRows = rows.map(row => {
      const externalMatch = reOrderedArray.find(
        ext => ext.id === row.id || (row.id === "" && ext.name.toLocaleLowerCase() === row.diningStationName.toLocaleLowerCase())
      );
      return externalMatch ? { ...row, position: externalMatch.position } : row;
    });

    const sortedRows = [...updatedRows].sort((a, b) => a.position - b.position);

    form.setValue(`weeks.${weekFormIndex}.meals.${mealFormIndex}.rows`, sortedRows);

    markMealAsUpdated(`weeks.${weekFormIndex}.meals.${mealFormIndex}`);
  };

  const mealMenuDiningStations = (form.getValues(`weeks.${weekFormIndex}.meals.${mealFormIndex}.rows`) || [])
    .filter(row => row.id !== null)
    .map(row => ({
      id: `${row.id === "" ? uuid() : row.id}`,
      name: row.diningStationName,
      position: row.position,
    }));

  const currentMealErrors = errors?.weeks?.[weekFormIndex]?.meals?.[mealFormIndex];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start" }}>
      <Box sx={{ display: "flex", width: mealGridWidth, flexDirection: "column" }}>
        <Box sx={{ p: 2, pb: 0, backgroundColor: grayBackground, border: "1px solid #D2D9DA" }}>
          <Box
            sx={{
              columnGap: 3,
              display: "grid",
              gridTemplateColumns: "24px 300px 200px 160px 160px 1fr",
              gridTemplateRows: "auto 1fr",
              alignItems: "center",
              gap: 2,
            }}
          >
            <IconButton sx={{ p: 0 }} onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Controller
              name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.mealName`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  label={"Meal Name"}
                  placeholder="Name"
                  error={Boolean(currentMealErrors?.mealName)}
                  // non-empty helperText to keep input from moving when it has an error
                  helperText={currentMealErrors?.mealName ? currentMealErrors?.mealName.message : " "}
                  InputProps={{ disabled: !isEditable }}
                  onChange={e => {
                    field.onChange(e);
                    markMealAsUpdated(`weeks.${weekFormIndex}.meals.${mealFormIndex}`);
                  }}
                />
              )}
            />
            <Controller
              name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.mealType`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  select
                  label={"Meal Type"}
                  error={Boolean(currentMealErrors?.mealType)}
                  // non-empty helperText to keep input from moving when it has an error
                  helperText={currentMealErrors?.mealType ? "Required" : " "}
                  InputProps={{ disabled: !isEditable }}
                  onChange={e => {
                    field.onChange(e);
                    markMealAsUpdated(`weeks.${weekFormIndex}.meals.${mealFormIndex}`);
                  }}
                >
                  <MenuItem value={"breakfast"}>Breakfast</MenuItem>
                  <MenuItem value={"lunch"}>Lunch</MenuItem>
                  <MenuItem value={"dinner"}>Dinner</MenuItem>
                  <MenuItem value={"snack"}>Snack</MenuItem>
                </TextField>
              )}
            />
            <Controller
              name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.startTime`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  select
                  label="Start Time (Default)"
                  error={Boolean(currentMealErrors?.startTime)}
                  // non-empty helperText to keep input from moving when it has an error
                  helperText={currentMealErrors?.startTime ? currentMealErrors?.startTime.message : " "}
                  SelectProps={{ IconComponent: AccessTimeIcon }}
                  InputProps={{ disabled: !isEditable }}
                  onChange={e => {
                    field.onChange(e);
                    markMealAsUpdated(`weeks.${weekFormIndex}.meals.${mealFormIndex}`);
                  }}
                >
                  {startTimeOptions().map(time => {
                    return (
                      <MenuItem key={time} value={time}>
                        {format(parseTime(time), timeFormat)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />
            <Controller
              name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.endTime`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  select
                  label="End Time (Default)"
                  error={Boolean(currentMealErrors?.endTime)}
                  // non-empty helperText to keep input from moving when it has an error
                  helperText={currentMealErrors?.endTime ? currentMealErrors?.endTime.message : " "}
                  SelectProps={{ IconComponent: AccessTimeIcon }}
                  InputProps={{ disabled: !isEditable }}
                  onChange={e => {
                    field.onChange(e);
                    markMealAsUpdated(`weeks.${weekFormIndex}.meals.${mealFormIndex}`);
                  }}
                >
                  {endTimeOptions().map(time => {
                    return (
                      <MenuItem key={time} value={time}>
                        {format(parseTime(time), timeFormat)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <IconButton onClick={() => setReorderDialogOpen(true)}>
                <LowPriorityIcon />
              </IconButton>
              {isEditable && (
                <IconButton sx={{ justifySelf: "right" }} onClick={deleteMeal}>
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
        {reorderDialogOpen && (
          <ReorderDialog
            open={reorderDialogOpen}
            title={"Reorder Dining Stations"}
            onClose={() => setReorderDialogOpen(false)}
            reorderableArray={mealMenuDiningStations}
            onDone={mealMenuDiningStations => {
              onReOrderRows(mealMenuDiningStations);
              setReorderDialogOpen(false);
            }}
          />
        )}
        {expanded && <MenuBuilderMealGrid
          rows={rowsFieldArray}
          form={form}
          weekFormIndex={weekFormIndex}
          mealFormIndex={mealFormIndex} />}
      </Box>
      {expanded && isEditable && (
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={() => {
            datadogRum.addAction("menu_builder.added_dining_station", { menuId });
            markMealAsUpdated(`weeks.${weekFormIndex}.meals.${mealFormIndex}`);
            addRow();
          }}
        >
          Add Dining Station
        </Button>
      )}
    </Box>
  );
};
