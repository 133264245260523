import { ListItem, ListItemText, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useExchangeTargets, useServingAmountCallbacks } from "@notemeal/shared-ui";
import classnames from "classnames";
import { ReactNode } from "react";
import { ExchangeAmountFragment, FullExchangeSetFragment, FullServingAmountFragment, RecipeFullFragment } from "../../../../types";
import RecipeCreateDialog from "../../../Recipe/RecipeCreateDialog";
import ServingAmountsEditChip from "../../../ServingAmounts/Edit/Chip";
import ExchangeTarget from "./ExchangeTarget";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowY: "auto",
    },
  })
);

interface MealOptionPickListModeContentProps {
  renderTotals: (servingAmounts: readonly FullServingAmountFragment[]) => ReactNode;
  mealOptionServingAmounts: readonly FullServingAmountFragment[];
  targetExchangeAmounts: readonly ExchangeAmountFragment[];
  exchangeSet: FullExchangeSetFragment;
  contentClassName: string;
  totalsClassName: string;
  onEditServingAmounts: (servingAmounts: readonly FullServingAmountFragment[]) => void;
  recipeDialogOpen: boolean;
  onCloseRecipeDialog: () => void;
  onCreateRecipe: (recipe: RecipeFullFragment) => void;
}

const MealOptionPickListModeContent = ({
  renderTotals,
  mealOptionServingAmounts,
  contentClassName,
  totalsClassName,
  onEditServingAmounts,
  recipeDialogOpen,
  onCloseRecipeDialog,
  onCreateRecipe,
  exchangeSet,
  targetExchangeAmounts,
}: MealOptionPickListModeContentProps) => {
  const classes = useStyles();
  const { exchangeTargets, unassignedServingAmounts, onChangeExchangeTargetServingAmounts } = useExchangeTargets({
    exchangeSet,
    targetExchangeAmounts,
    servingAmounts: mealOptionServingAmounts,
    onEditServingAmounts,
  });

  const {
    // onChangeOrder,
    onDecrementByDefaultAmount,
    onDelete,
    onIncrementByDefaultAmount,
    onSetAmount,
    onReplaceServing,
    // sortedServingAmounts,
  } = useServingAmountCallbacks({
    servingAmounts: mealOptionServingAmounts,
    onChange: onEditServingAmounts,
  });

  return (
    <>
      <div className={totalsClassName}>{renderTotals(mealOptionServingAmounts)}</div>
      <div className={classnames(contentClassName, classes.root)}>
        {exchangeTargets.map(exchangeTarget => (
          <ExchangeTarget
            key={exchangeTarget.exchange.id}
            exchangeTarget={exchangeTarget}
            onEditServingAmounts={servingAmounts => onChangeExchangeTargetServingAmounts(exchangeTarget, servingAmounts)}
          />
        ))}
        {unassignedServingAmounts.length > 0 && (
          <ListItem dense>
            <ListItemText primary="No Exchanges" />
          </ListItem>
        )}
        {unassignedServingAmounts.map(sa => (
          <ServingAmountsEditChip
            key={sa.serving.id}
            servingAmount={sa}
            onIncrement={() => onIncrementByDefaultAmount(sa)}
            onDecrement={() => onDecrementByDefaultAmount(sa)}
            onDelete={() => onDelete(sa)}
            onReplaceServing={serving => onReplaceServing(sa, serving)}
            onSetAmount={amount => onSetAmount(sa, amount)}
            expanded={false}
            onChangeExpanded={() => {}}
            onLoadIngredients={() => {}}
            onDeconstructRecipe={() => {}}
            // expanded={idToExpanded(sa.id)}
            // onChangeExpanded={expanded => setIdExpanded(sa.id, expanded)}
            // onDeconstructRecipe={handleDeconstructRecipe}
            bindProps={undefined}
            spring={undefined}
            recipeIngredient={false}
          />
        ))}
      </div>
      {recipeDialogOpen && (
        <RecipeCreateDialog
          open={recipeDialogOpen}
          onClose={onCloseRecipeDialog}
          onCreate={onCreateRecipe}
          initialIngredients={mealOptionServingAmounts}
        />
      )}
    </>
  );
};

export default MealOptionPickListModeContent;
