import { datadogRum } from "@datadog/browser-rum";
import { Loading, NEW_ATHLETE_ROLES } from "@notemeal/shared-ui";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { ActivationLinkModal } from "apps/web/src/views/Athlete/Summary/AccountManagement/ActivationLinkModal";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import FoodLogNotificationSubcriptionTopicsModal from "../../../../components/FoodLog/NotificationSettings/AthleteModal";
import { RefetchQueriesContextProvider } from "../../../../contexts/RefetchQueries";
import {
  AccountVerificationMethod,
  CreateOrgMembershipForAthleteObjectInput,
  FoodLogNotificationSettings,
  NamedTagForSelectionFragment,
  TeamEditableRosterDocument,
  useAthletesForTeamOffsetConnectionQuery,
  useCreateOrgMembershipForAthleteMutation,
  useGoalTypesQuery,
  useMyOrgFullySyncedQuery,
  useTeamEditableRosterQuery,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import { EnableMobileAccountModal } from "../../../../views/Athlete/Summary/AccountManagement/EnableMobileAccountModal";
import { EditRosterAthleteModal } from "../../../../views/Team/Athletes/Roster/EditRosterAthleteModal";
import { AthleteTable } from "../../../../views/Team/Athletes/Roster/Table";
import { ACTIVE, SortType, TableFilter } from "../../../../views/Team/Athletes/Roster/Toolbar";
import { IAthleteProfileRowInput, athletesToProfileRows, getAthleteFilter } from "../../../../views/Team/Athletes/Roster/utils";
import AthleteCreateModal from "../../../../views/Team/CreateAthleteModal";
import { getNavOrgAthlete } from "../Athlete/AthletePaths";

export const TeamRosterPage = () => {
  const navigate = useNavigate();
  const { teamId: maybeTeamId } = useParams();
  const teamId = maybeTeamId || "";
  const { loading: gtLoading, data: gtData } = useGoalTypesQuery();
  const { data: orgData, loading: orgLoading } = useMyOrgFullySyncedQuery();
  const [filter, setFilter] = useState<TableFilter>(ACTIVE);
  const [namedTagsForFilter, setNamedTagsForFilter] = useState<NamedTagForSelectionFragment[]>([]);
  const [sort, setSort] = useState<SortType | null>(null);
  const [createAthleteModalOpen, setCreateAthleteModalOpen] = useState(false);
  const [editingAthlete, setEditingAthlete] = useState<IAthleteProfileRowInput | null>(null);
  const [activationLinkData, setActivationLinkData] = useState<{ link: string; athleteId: string } | null>(null);

  const [enablingAthlete, setEnablingAthlete] = useState<{
    email: string | null;
    phoneNumber: string | null;
    id: string;
  } | null>(null);
  const [editingAthleteSubscription, setEditingAthleteSubscription] = useState<{
    athleteNotificationSettings: FoodLogNotificationSettings | null;
    athleteId: string;
  } | null>(null);

  const getTeamVars = { id: teamId };
  const { loading: teamLoading, data: teamData } = useTeamEditableRosterQuery({
    variables: { id: teamId },
  });

  const refetchQueries = [{ query: TeamEditableRosterDocument, variables: getTeamVars }];
  const { setMessage } = useSnackbar();
  const [createOrgMembershipForAthlete] = useCreateOrgMembershipForAthleteMutation({
    onError: e => {
      setMessage("error", e.message);
    },
    update: (cache, mutationResult) => {
      if (mutationResult.data?.createOrgMembershipForAthlete?.generatedSelfServiceActivationLink) {
        setActivationLinkData({
          link: mutationResult.data.createOrgMembershipForAthlete.generatedSelfServiceActivationLink,
          athleteId: mutationResult.data?.createOrgMembershipForAthlete.athlete.id,
        });
      } else {
        setMessage("success", `Activation link has been sent`);
      }
    },
  });

  const paginationHooks = useOffsetPagination();
  const { query, offset, limit, resetOffset } = paginationHooks;

  const tagIds = namedTagsForFilter.map(({ tag: { id } }) => id);

  const {
    data: profilesData,
    loading: profilesLoading,
    refetch: profilesRefetch,
  } = useAthletesForTeamOffsetConnectionQuery({
    variables: {
      teamId,
      tagIds,
      input: { offset, limit },
      filter: getAthleteFilter(filter),
      query,
      priorityTagIds: tagIds,
    },
  });

  if (teamLoading || gtLoading || orgLoading || !gtData || !teamData || !orgData) {
    return <Loading progressSize="lg" />;
  }
  const isNotemealLinked = orgData.myOrg.isNotemealLinked;

  const teamName = `${teamData.team.name}`;
  const { foodLogNotificationSettings: teamFoodLogNotificationSettings } = teamData.team;

  const handleEnableMobileAccount = async (payload: CreateOrgMembershipForAthleteObjectInput): Promise<void> => {
    createOrgMembershipForAthlete({
      variables: {
        input: {
          athlete: payload,
        },
      },
    });
  };
  const rowData = profilesData ? athletesToProfileRows(profilesData.athletesForTeamOffsetConnection.edges) : [];

  return (
    <RefetchQueriesContextProvider refetchQueries={refetchQueries}>
      <AthleteTable
        teamId={teamId}
        displayEnableAccountModal={setEnablingAthlete}
        displaySubscribeToAthleteModal={setEditingAthleteSubscription}
        teamFoodLogNotificationSettings={teamFoodLogNotificationSettings}
        paginationHooks={paginationHooks}
        loading={profilesLoading}
        rows={rowData}
        total={profilesData?.athletesForTeamOffsetConnection.pageInfo.total || 0}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
        isNotemealLinked={isNotemealLinked}
        selectedNamedTags={namedTagsForFilter}
        onChangeNamedTags={newNamedTags => {
          setNamedTagsForFilter(newNamedTags);
          resetOffset();
        }}
        onCreateAthlete={() => setCreateAthleteModalOpen(true)}
        onEditAthlete={row => setEditingAthlete(row)}
      />
      {enablingAthlete && (
        <EnableMobileAccountModal
          open={!!enablingAthlete}
          onClose={() => setEnablingAthlete(null)}
          onEnable={(method: AccountVerificationMethod) =>
            handleEnableMobileAccount({
              athleteId: enablingAthlete.id,
              teamName,
              method,
              roles: NEW_ATHLETE_ROLES,
            })
          }
          athlete={enablingAthlete}
          activationLink={activationLinkData?.link ?? null}
        />
      )}
      {activationLinkData?.link && (
        <ActivationLinkModal
          onClose={() => setActivationLinkData(null)}
          open={!!activationLinkData.link}
          activationLink={activationLinkData.link}
          onCopyLink={() => datadogRum.addAction("generate_activation_link", { athleteId: activationLinkData.athleteId })}
        />
      )}
      {editingAthleteSubscription && (
        <FoodLogNotificationSubcriptionTopicsModal
          open={!!editingAthleteSubscription}
          onClose={() => setEditingAthleteSubscription(null)}
          athleteId={editingAthleteSubscription.athleteId}
          athleteNotificationSettings={editingAthleteSubscription.athleteNotificationSettings}
          teamNotificationSettings={teamData.team.foodLogNotificationSettings}
        />
      )}
      {createAthleteModalOpen && (
        <AthleteCreateModal
          teams={[teamData.team]}
          teamId={teamId}
          open={createAthleteModalOpen}
          onClose={() => setCreateAthleteModalOpen(false)}
          onFinish={athleteId => {
            setCreateAthleteModalOpen(false);
            navigate(getNavOrgAthlete(athleteId));
          }}
        />
      )}
      {editingAthlete && (
        <EditRosterAthleteModal
          teamId={teamId}
          open={Boolean(editingAthlete)}
          onClose={() => setEditingAthlete(null)}
          onSuccess={() => profilesRefetch()}
          row={editingAthlete}
          isNotemealLinked={orgData.myOrg.isNotemealLinked}
        />
      )}
    </RefetchQueriesContextProvider>
  );
};
