import { dateAndTimeToIsoInTz } from "@notemeal/utils-date-time";
import { TimelineMealModalIntent } from "@notemeal/shared-ui";
import {
  FullServingAmountFragment,
  MenuSelectionItemWithMacrosFragment,
  TimelineDateFragment,
  MealMenuTimelineFragment,
  TimelineMealOrTemplateFragment,
  TimelineActivityTemplateFragment,
  FoodLogActivityTimelineFragment,
  TimelineMealFragment,
  MealTemplateTimelineFragment,
  AddTimelineMealInput,
  TimelineMealTemplateFragment,
  TimelineMealInput,
  RestaurantMenuLogTimelineFragment,
} from "../../../types";
import { differenceInMinutes } from "date-fns";
import { getMenuSelectionItemServingAmounts } from "@notemeal/shared-ui";
import { sortByKey } from "@notemeal/utils-sort";
import { v4 as uuidV4 } from "uuid";

export type TimelineMeal = TimelineMealFragment;
export type TempTimelineMeal = Omit<TimelineMealFragment, "__typename"> & {
  __typename: "TempTimelineMeal";
};
export type TimelineMealForModal = TimelineMeal | TempTimelineMeal;

export type FoodLogActivityTimelineItem = Omit<FoodLogActivityTimelineFragment, "date" | "startTime" | "endTime"> & {
  start: string;
  durationInMinutes: number;
};

export type RestaurantMenuLogTimelineItem = Omit<RestaurantMenuLogTimelineFragment, "dateTime"> & {
  start: string;
  durationInMinutes: number;
};

export type TimelineMealModalState = {
  intent: TimelineMealModalIntent | null;
  timelineMeal: Pick<TimelineMealFragment, "id" | "timezone" | "name" | "start" | "durationInMinutes" | "type"> & {
    mealMenus: readonly MealMenuTimelineFragment[];
    mealTemplate: MealTemplateTimelineFragment | null;
  };
};

export type TimelineItemForDate =
  | TimelineMealOrTemplateFragment
  | TimelineActivityTemplateFragment
  | FoodLogActivityTimelineItem
  | MealMenuTimelineFragment;

export const getTimelineItemsForDate = (
  timelineDate: TimelineDateFragment,
  mealMenus: readonly MealMenuTimelineFragment[]
): readonly TimelineItemForDate[] => {
  const timelineMealMenuIds = timelineDate.meals.flatMap(m => m.mealMenus.map(mm => mm.id));
  const unmatchedMealMenus = mealMenus.filter(mm => !timelineMealMenuIds.includes(mm.id));

  const activities = timelineDate.activities.map(activity => {
    if (activity.__typename === "TimelineActivityTemplate") {
      return activity;
    }

    const { date, startTime, endTime, timezone, ...rest } = activity;

    const start = dateAndTimeToIsoInTz({ date, time: startTime }, timezone);
    const end = dateAndTimeToIsoInTz({ date, time: endTime }, timezone);
    const durationInMinutes = differenceInMinutes(new Date(end), new Date(start));

    return {
      ...rest,
      start,
      durationInMinutes,
      timezone,
    };
  });

  return sortByKey([...timelineDate.meals, ...activities, ...unmatchedMealMenus], "start");
};

export const createTimelineMealModalStateFromMeal = (
  { id, timezone, name, start, type, durationInMinutes, mealMenus, mealTemplate }: TimelineMealFragment,
  intent: TimelineMealModalIntent | null
): TimelineMealModalState => {
  return {
    intent,
    timelineMeal: {
      id,
      timezone,
      name,
      type,
      start,
      durationInMinutes,
      mealMenus,
      mealTemplate,
    },
  };
};

export const createTimelineMealModalStateFromMealInput = (
  { name, start, timezone, durationInMinutes, type }: Omit<AddTimelineMealInput, "mealTemplateId">,
  intent: TimelineMealModalIntent | null
): TimelineMealModalState => {
  return {
    intent,
    timelineMeal: {
      id: uuidV4(),
      timezone,
      name,
      type,
      start,
      durationInMinutes,
      mealMenus: [],
      mealTemplate: null,
    },
  };
};

const DEFAULT_DURATION = 15;

export const createTimelineMealModalStateFromMenu = (
  mealMenu: MealMenuTimelineFragment,
  intent: TimelineMealModalIntent | null
): TimelineMealModalState => {
  const name = mealMenu.name;
  const start = mealMenu.start;
  const durationInMinutes = DEFAULT_DURATION;
  const type = mealMenu.mealType;
  const timezone = mealMenu.timezone;

  return {
    intent,
    timelineMeal: {
      id: uuidV4(),
      timezone,
      name,
      type,
      start,
      durationInMinutes,
      mealMenus: [mealMenu],
      mealTemplate: null,
    },
  };
};

export const getTimelineMealInput = (timelineMeal: TimelineMealForModal, newPickupTime?: string): TimelineMealInput => {
  let start: string;
  let durationInMinutes: number;
  if (newPickupTime) {
    start = newPickupTime;
    durationInMinutes = DEFAULT_DURATION;
  } else {
    start = timelineMeal.start;
    durationInMinutes = timelineMeal.durationInMinutes;
  }

  return {
    timelineMealId: timelineMeal.id,
    addTimelineMeal: {
      name: timelineMeal.name,
      start,
      durationInMinutes,
      type: timelineMeal.type,
      timezone: timelineMeal.timezone,
      mealTemplateId: timelineMeal.mealTemplate?.id ?? null,
    },
  };
};

export const createTimelineMealModalStateFromTemplate = (
  mealTemplate: TimelineMealTemplateFragment,
  clientTimezone: string,
  intent: TimelineMealModalIntent | null
): TimelineMealModalState => {
  const name = mealTemplate.template.meal.name;
  const timezone = clientTimezone;
  const type = mealTemplate.template.meal.type;
  const start = mealTemplate.start;
  const durationInMinutes = mealTemplate.durationInMinutes;
  const mealMenus = mealTemplate.mealMenus;

  return {
    intent,
    timelineMeal: {
      id: uuidV4(),
      timezone,
      name,
      type,
      start,
      durationInMinutes,
      mealMenus,
      mealTemplate: mealTemplate.template,
    },
  };
};

export const getTimelineMealAndItemStartAndDuration = (item: TimelineItemForDate): { start: Date; durationInMinutes: number } => {
  return {
    start: new Date(item.start),
    durationInMinutes: item.durationInMinutes,
  };
};

export const getTimelineMealServingAmounts = (
  {
    servingAmounts,
    menuOrders,
    mealMenuLogs,
    restaurantMenuLinkOrders,
    restaurantMenuLinkPlateOrders,
    restaurantMenuLogs,
  }: TimelineMealForModal,
  additionalItems: readonly MenuSelectionItemWithMacrosFragment[] = []
): readonly FullServingAmountFragment[] => {
  const allMenuSelectionItems: MenuSelectionItemWithMacrosFragment[] = [
    ...menuOrders.flatMap(s => s.items),
    ...mealMenuLogs.flatMap(s => s.items),
    ...restaurantMenuLinkOrders.flatMap(s => s.items),
    ...restaurantMenuLinkPlateOrders.flatMap(s => s.items),
    ...restaurantMenuLogs.flatMap(s => s.items),
    ...additionalItems,
  ];
  return allMenuSelectionItems.flatMap(getMenuSelectionItemServingAmounts).concat(servingAmounts);
};

export const getTimelineMealOverviewCounts = ({
  servingAmounts,
  mealMenuLogs,
  restaurantMenuLinkOrders,
  restaurantMenuLinkPlateOrders,
  restaurantMenuLogs,
  menuOrders,
}: TimelineMealForModal): { orderCount: number; logCount: number } => {
  const orderItems = [
    ...menuOrders.flatMap(s => s.items),
    ...restaurantMenuLinkOrders.flatMap(s => s.items),
    ...restaurantMenuLinkPlateOrders.flatMap(s => s.plate.items),
  ];
  const logItems = [...mealMenuLogs.flatMap(s => s.items), ...restaurantMenuLogs.flatMap(s => s.items), ...servingAmounts];
  return {
    orderCount: orderItems.length,
    logCount: logItems.length,
  };
};

export const getTimelineMealForModal = (
  state: TimelineMealModalState | null,
  timelineMeals: readonly TimelineMeal[]
): TimelineMealForModal | null => {
  if (!state) {
    return null;
  }
  const matchingTimelineMeal = timelineMeals.find(tlm => tlm.id === state.timelineMeal.id);
  if (matchingTimelineMeal) {
    return { ...matchingTimelineMeal, mealMenus: matchingTimelineMeal.mealMenus ?? state.timelineMeal.mealMenus };
  } else {
    return {
      __typename: "TempTimelineMeal",
      ...state.timelineMeal,
      images: [],
      comments: [],
      notifications: [],
      menuOrders: [],
      restaurantMenuLinkOrders: [],
      restaurantMenuLinkPlateOrders: [],
      restaurantMenuLogs: [],
      mealMenuLogs: [],
      servingAmounts: [],
    };
  }
};
