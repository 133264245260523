import { Box, Typography, styled } from "@mui/material";
import { HOURS } from "@notemeal/utils-date-time";
import { format, parse } from "date-fns";
import React from "react";
import { HOUR_HEIGHT } from "./utils";

const HourDiv = styled("div", { shouldForwardProp: prop => prop !== "hourHeight" })<{ hourHeight: number }>(({ hourHeight }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  borderRight: "thin solid lightgray",
  boxSizing: "border-box",
  minHeight: hourHeight,
}));

interface WeekCalendarHoursColumnProps {
  hours?: string[];
  hourHeight?: number;
  hasAllDayEvents?: boolean;
}

const WeekCalendarHoursColumn = ({ hours = HOURS, hourHeight = HOUR_HEIGHT, hasAllDayEvents = false }: WeekCalendarHoursColumnProps) => {
  return (
    <Box sx={{ width: 70, display: "flex", flexDirection: "column" }}>
      {hasAllDayEvents && <HourDiv key={"all_day"} hourHeight={hourHeight} />}
      {hours.map((h, i) => {
        const hourTime = `${h}:00:00`;
        return (
          <HourDiv key={h} hourHeight={hourHeight}>
            {/* Don't show 12AM */}
            {i !== 0 && (
              <Typography
                variant="subtitle1"
                color="textSecondary"
                sx={({ spacing }) => ({ mr: spacing(), mt: spacing(-1.5) })}>
                {/* TODO: move to lib? */}
                {format(parse(hourTime, "HH:mm:ss", new Date()), "h a")}
              </Typography>
            )}
            {i !== hours.length && <Box sx={({ spacing }) => ({ width: spacing(), borderBottom: "thin solid lightgray" })} />}
          </HourDiv>
        );
      })}
    </Box>
  );
};

export default WeekCalendarHoursColumn;
