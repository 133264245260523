import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, Divider, IconButton, Tooltip } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { ImportPlannedMenuType } from "./ImportMenuSchema";
import { useImportPlannedMenu } from "./ImportPlannedMenuContext";
import { ImportPlannedMenuFormMeal } from "./ImportPlannedMenuFormMeal";
interface ImportPlannedMenuFormMealWeekProps {
  weekFormIndex: number;
}
export const BORDER_COLOR = "#D0D5DD";

export const ImportPlannedMenuFormWeek = ({ weekFormIndex }: ImportPlannedMenuFormMealWeekProps) => {
  const { selectedWeeks, form, plannedMenuWeeksWithMeals, allWeeks } = useImportPlannedMenu();
  const { formatYearlessDateWithLocale } = useDateFormatting();
  const [expanded, setExpanded] = useState(false);
  const mealsArray = useFieldArray<ImportPlannedMenuType>({
    control: form.control,
    name: `weeks.${weekFormIndex}.meals`,
  });

  const week = plannedMenuWeeksWithMeals[weekFormIndex];
  if (!week) {
    return null;
  }

  const hasMeals = mealsArray.fields && mealsArray.fields.length > 0;
  const weekNumber = allWeeks.findIndex(w => w.id === week.id);
  const { startDate, endDate } = selectedWeeks[weekFormIndex];
  const formattedWeek = `Week ${weekNumber + 1} (${formatYearlessDateWithLocale(startDate)} - ${formatYearlessDateWithLocale(endDate)})`;

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: hasMeals ? "space-between" : "flex-start", alignItems: "center" }}>
        <h4>{formattedWeek}</h4>
        {!hasMeals ? (
          <Tooltip
            title={
              <>
                <strong>No menu items</strong>
                <br />
                <span>No menu items have been added to this week yet</span>
              </>
            }
          >
            <WarningRoundedIcon sx={{ ml: 2 }} color="warning" />
          </Tooltip>
        ) : (
          <IconButton
            sx={{ p: 0, margin: 2 }}
            size="small"
            onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLessIcon sx={{ color: "black" }} /> : <ExpandMoreIcon sx={{ color: "black" }} />}
          </IconButton>
        )}
      </Box>
      {expanded && hasMeals && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb: 2 }}>
          {mealsArray.fields.map((meal, mealIndex) => (
            <ImportPlannedMenuFormMeal
              key={meal.id}
              weekFormIndex={weekFormIndex}
              mealFormIndex={mealIndex} />
          ))}
        </Box>
      )}
      <Divider />
    </Box>
  );
};
