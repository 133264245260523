import { EditImperialMacroProtocolState, EditMetricMacroProtocolState } from "@notemeal/shared-ui";
import { inputToNumber } from "@notemeal/shared-ui";

// NOTE: separate measurement flows for first phase of measurement system
// TODO: metric update run phase? commonize and or combine separate measurement system flows
export const macroProtocolStateToSaveTooltips = (state: EditImperialMacroProtocolState): string[] => {
  let tooltips = [];
  const goal = state.calorieBudget.goalSnapshot;
  if (state.usingCalorieBudget && state.cho.percent + state.pro.percent + state.fat.percent !== 100) {
    tooltips.push("Macro percentages must sum to 100%");
  }
  if (state.usingCalorieBudget && state.calorieBudget.rmrMethod === null && state.calorieBudget.rmrCalories === null) {
    tooltips.push("RMR method or calories is required");
  }
  if (state.usingCalorieBudget && (!goal.type || inputToNumber(goal.kcalOffsetInput) !== goal.kcalOffset)) {
    tooltips.push("Goal is incomplete and required");
  }
  if (state.usingWeightTarget && inputToNumber(state.weightTargetInput) !== state.weightTarget) {
    tooltips.push("Weight target is formatted incorrectly");
  }
  return tooltips;
};

export const metricMacroProtocolStateToSaveTooltips = (state: EditMetricMacroProtocolState): string[] => {
  let tooltips = [];
  const goal = state.calorieBudget.goalSnapshot;
  if (state.usingCalorieBudget && state.cho.percent + state.pro.percent + state.fat.percent !== 100) {
    tooltips.push("Macro percentages must sum to 100%");
  }
  if (state.usingCalorieBudget && state.calorieBudget.rmrMethod === null && state.calorieBudget.rmrCalories === null) {
    tooltips.push("RMR method or calories is required");
  }
  if (state.usingCalorieBudget && (!goal.type || inputToNumber(goal.kcalOffsetInput) !== goal.kcalOffset)) {
    tooltips.push("Goal is incomplete and required");
  }
  if (state.usingWeightTarget && inputToNumber(state.weightTargetInput) !== state.weightTargetInKg) {
    tooltips.push("Weight target is formatted incorrectly");
  }
  return tooltips;
};
