import { FetchResult } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogActions, DialogContent, Tooltip } from "@mui/material";
import { Loading, useLocaleContext } from "@notemeal/shared-ui";
import { parseDate } from "@notemeal/utils-date-time";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import DialogTitleWithTooltip from "apps/web/src/componentLibrary/DialogTitleWithToolTip";
import { ImperialAnthropometryEntryForm } from "apps/web/src/components/AnthropometryEntry/Form/ImperialAnthropometryForm";
import {
  MetricAnthropometryEntrySchema,
  MetricAnthropometryEntryType,
  metricAnthropometryEntryFormDefaultValues,
  metricAnthropometryEntryFormStateToCreateInput,
} from "apps/web/src/components/AnthropometryEntry/Form/MetricAnthropometryEntryFormSchema";
import { MetricAnthropometryEntryForm } from "apps/web/src/components/AnthropometryEntry/Form/MetricAnthropometryForm";
import { editAthleteMutationUpdater, editSyncedAthleteMutationUpdater } from "apps/web/src/components/Athlete/EditModal";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { isFormDirty } from "apps/web/src/utils/form";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  AnthropometryEntrySchema,
  AnthropometryEntryType,
  anthropometryEntryFormDefaultValues,
  anthropometryEntryFormStateToMetricCreateInput,
} from "../../../../components/AnthropometryEntry/Form/AnthropometryEntryFormSchema";
import AthleteForm from "../../../../components/Athlete/Form";
import {
  AthleteFormSchema,
  AthleteFormType,
  athleteFormDefaultValues,
  athleteFormToEditInput,
  syncedAthleteForToEditInput,
} from "../../../../components/Athlete/Form/AthleteFormSchema";
import GoalForm from "../../../../components/Goal/Form";
import { GoalSchema, GoalType, goalFormDefaultValues, goalFormToCreateInput } from "../../../../components/Goal/Form/GoalFormSchema";
import {
  useAthleteSummaryQuery,
  useCreateGoalMutation,
  useCreateMetricAnthropometryEntryMutation,
  useEditAthleteMutation,
  useEditSyncedAthleteMutation,
} from "../../../../types";
import { IAthleteProfileRowInput } from "./utils";

interface TeamCreateAthleteModalProps {
  teamId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  row: IAthleteProfileRowInput;
  isNotemealLinked: boolean;
}

export const EditRosterAthleteModal = ({ teamId, onClose, onSuccess, open, row, isNotemealLinked }: TeamCreateAthleteModalProps) => {
  const isForSyncedAthlete = isNotemealLinked && !row.isProfileNotemealOnly;
  const isForNotemealOnlyAthlete = isNotemealLinked && row.isProfileNotemealOnly;
  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [hasAnthroEntry, setHasAnthroEntry] = useState(false);
  const [hasGoal, setHasGoal] = useState(false);

  const { isMetricLocale } = useLocaleContext();

  const { setMessage } = useSnackbar();

  const { data: athleteData, loading: athleteLoading } = useAthleteSummaryQuery({
    variables: { id: row.id },
  });

  const athleteForm = useForm<AthleteFormType>({ resolver: zodResolver(AthleteFormSchema) });

  const imperialAnthroForm = useForm<AnthropometryEntryType>({ resolver: zodResolver(AnthropometryEntrySchema()) });
  const metricAnthroForm = useForm<MetricAnthropometryEntryType>({ resolver: zodResolver(MetricAnthropometryEntrySchema()) });

  const goalForm = useForm<GoalType>({ resolver: zodResolver(GoalSchema()) });

  useEffect(() => {
    if (athleteData) {
      const {
        athlete: { currentGoal, mostRecentAnthropometryEntry },
      } = athleteData;
      const initGoal: Partial<GoalType> = {};

      setHasGoal(Boolean(currentGoal));
      setHasAnthroEntry(Boolean(mostRecentAnthropometryEntry));

      if (currentGoal) {
        initGoal.start = parseDate(currentGoal.start);
        initGoal.kcalOffset = currentGoal.kcalOffset;
        initGoal.type = currentGoal.type;

        if (currentGoal.end) {
          initGoal.end = parseDate(currentGoal.end);
        }
      }

      athleteForm.reset(athleteFormDefaultValues(teamId, athleteData.athlete));
      goalForm.reset({
        ...goalFormDefaultValues(),
        ...initGoal,
      });
      imperialAnthroForm.reset({
        ...anthropometryEntryFormDefaultValues(),
        ...mostRecentAnthropometryEntry,
        date: mostRecentAnthropometryEntry?.datetime ? parseDate(mostRecentAnthropometryEntry?.datetime) : new Date(),
      });

      metricAnthroForm.reset({
        ...metricAnthropometryEntryFormDefaultValues(),
        ...mostRecentAnthropometryEntry,
        date: mostRecentAnthropometryEntry?.datetime ? parseDate(mostRecentAnthropometryEntry?.datetime) : new Date(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [athleteData, teamId]);

  const teamIdRef = useRef(teamId);
  const [editAthlete] = useEditAthleteMutation({
    update: editAthleteMutationUpdater(teamIdRef, row.id),
  });

  const [editSyncedAthlete] = useEditSyncedAthleteMutation({
    update: editSyncedAthleteMutationUpdater(teamIdRef, row.id),
  });

  const [createMetricAnthro] = useCreateMetricAnthropometryEntryMutation();

  const [createGoal] = useCreateGoalMutation();

  const onSubmit = async () => {
    if (!athleteData) {
      return;
    }

    let updatedAthlete: AthleteFormType | null = null;
    let newImperialAnthroEntry: AnthropometryEntryType | null = null;
    let newMetricAnthroEntry: MetricAnthropometryEntryType | null = null;
    let newGoal: GoalType | null = null;

    let hasValidationError = false;

    let firstName = row.firstName;
    let lastName = row.lastName;

    if (isFormDirty(athleteForm)) {
      await athleteForm.handleSubmit(
        athlete => {
          updatedAthlete = athlete;
          firstName = athlete.firstName;
          lastName = athlete.lastName;
        },
        () => (hasValidationError = true)
      )();
    }

    if (isMetricLocale && isFormDirty(metricAnthroForm)) {
      await metricAnthroForm.handleSubmit(
        entry => (newMetricAnthroEntry = entry),
        () => (hasValidationError = true)
      )();
    }

    if (!isMetricLocale && isFormDirty(imperialAnthroForm)) {
      await imperialAnthroForm.handleSubmit(
        entry => (newImperialAnthroEntry = entry),
        () => (hasValidationError = true)
      )();
    }

    if (isFormDirty(goalForm)) {
      await goalForm.handleSubmit(
        goal => (newGoal = goal),
        () => (hasValidationError = true)
      )();
    }

    if (hasValidationError) {
      setHasFormErrors(true);
      return;
    }

    const promises: Promise<FetchResult<unknown, Record<string, any>, Record<string, any>>>[] = [];

    if (updatedAthlete) {
      const editAthletePromise = isForSyncedAthlete
        ? editSyncedAthlete({
            variables: {
              input: syncedAthleteForToEditInput(updatedAthlete, row.id, athleteData.athlete.allOrderedNamedTags ?? []),
            },
          })
        : editAthlete({
            variables: {
              input: athleteFormToEditInput(updatedAthlete, row.id, athleteData.athlete.allOrderedNamedTags ?? []),
            },
          });
      promises.push(editAthletePromise);
    }

    if (newImperialAnthroEntry) {
      promises.push(
        createMetricAnthro({
          variables: {
            input: anthropometryEntryFormStateToMetricCreateInput(newImperialAnthroEntry, row.id, athleteData.athlete.sex),
          },
        })
      );
    } else if (newMetricAnthroEntry) {
      promises.push(
        createMetricAnthro({
          variables: {
            input: metricAnthropometryEntryFormStateToCreateInput(newMetricAnthroEntry, row.id, athleteData.athlete.sex),
          },
        })
      );
    }

    if (newGoal) {
      promises.push(
        createGoal({
          variables: {
            input: goalFormToCreateInput(newGoal, row.id),
          },
        })
      );
    }

    const errors = (await Promise.all(promises)).flatMap(result => result.errors).filter(err => !!err);

    if (!errors.length) {
      setMessage("success", `Updated information for ${lastName}, ${firstName}`);
      onSuccess();
      onClose();
    } else {
      setMessage("error", `Unable to update information for ${lastName}, ${firstName}`);
    }
  };

  if (athleteLoading || !athleteData) {
    return <Loading progressSize="lg" />;
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      {isForNotemealOnlyAthlete ? (
        <DialogTitleWithTooltip
          title="Edit Nutrition-Only Athlete"
          tooltipText="This athlete will be added as a Nutrition-only user and will not have a profile created in Teamworks. Nutrition-only users will not be able to use the Teamworks SSO to sign in or have their profile information synced with Teamworks."
          onClose={onClose}
        />
      ) : (
        <DialogTitle title="Edit Athlete" onClose={onClose} />
      )}
      <DialogContent>
        <AthleteForm
          displayUidFields
          teams={athleteData.teams}
          isForSyncedAthlete={isForSyncedAthlete}
          form={athleteForm}
          username={row.username}
        />
        {isMetricLocale ? (
          <MetricAnthropometryEntryForm
            fauxEditMode={hasAnthroEntry}
            form={metricAnthroForm}
            hideCommentField />
        ) : (
          <ImperialAnthropometryEntryForm
            fauxEditMode={hasAnthroEntry}
            form={imperialAnthroForm}
            hideCommentField />
        )}
        <GoalForm form={goalForm} editOptions={hasGoal ? { disableStartDateUntilDirty: true } : undefined} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip title={hasFormErrors ? "Resolve the errors above to continue" : ""}>
          <Button onClick={onSubmit}>Save</Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
