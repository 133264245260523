import { DndContext, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { Box, Divider, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { HOURS, dateAndTimeToIsoInTz, dateToIsoInTz } from "@notemeal/utils-date-time";
import { addDays, differenceInMinutes, isWithinInterval } from "date-fns";
import React, { useEffect, useState } from "react";
import CalendarAllDayCell from "./AllDayCell";
import EventContainer from "./EventContainer/Default";
import DraggedEventContainer from "./EventContainer/Dragged";
import NewEventContainer from "./EventContainer/New";
import CalendarHourCell from "./HourCell";
import { BaseCalendarDayColumnProps, CalendarEvent, HOUR_HEIGHT, getCalendarEventsWithSlots, isDateInPast } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    event: {
      position: "absolute",
      cursor: "pointer",
      overflowX: "hidden",
    },
    eventOutlined: {
      border: "1px solid white",
      marginTop: -1,
      marginLeft: -1,
    },
    timeIndicatorDivider: {
      position: "absolute",
      backgroundColor: theme.palette.error.main,
      width: "100%",
      height: 2,
      zIndex: 10,
    },
    timeIndicatorDiv: {
      position: "absolute",
      backgroundColor: theme.palette.error.main,
      height: 12,
      width: 12,
      borderRadius: "50%",
      marginLeft: -6.5,
      marginTop: -5,
      zIndex: 10,
    },
  })
);

interface CalendarDayColumnProps<E extends CalendarEvent> extends BaseCalendarDayColumnProps<E> {
  children?: React.ReactNode;
  showAllDayCell?: boolean;
}

const CalendarDayColumn = <E extends CalendarEvent>({
  hours = HOURS,
  hourHeight = HOUR_HEIGHT,
  date,
  clientTimezone,
  events,
  renderEvent,
  renderNewEvent,
  dragSettings,
  displaySettings,
  children,
  showAllDayCell = false,
  sx,
}: CalendarDayColumnProps<E>) => {
  const classes = useStyles();
  const dateStart = new Date(dateToIsoInTz(date, clientTimezone));
  const [currentTime, setCurrentTime] = useState(new Date());
  const [newEventStart, setNewEventStart] = useState<Date | null>(null);
  const [dragEventData, setDragEventData] = useState<{ eventId: string; newStart: Date; isDoneDragging: boolean } | null>(null);
  const draggedEvent = events.find(e => e.id === dragEventData?.eventId);
  const isInPast = isDateInPast(currentTime, dateStart);

  const handleClickHour = (hour: string) => {
    if (hour === "all_day") {
      setNewEventStart(null);
    } else {
      setNewEventStart(
        new Date(
          dateAndTimeToIsoInTz(
            {
              date,
              time: `${hour}:00:00`,
            },
            clientTimezone
          )
        )
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(new Date()), 1000 * 120);
    return () => clearInterval(interval);
  }, [setCurrentTime]);

  const eventsWithSlot = getCalendarEventsWithSlots(events);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: HOUR_HEIGHT / 4,
      },
    })
  );

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", position: "relative", mr: 2, ...sx }}>
      {showAllDayCell && (
        <CalendarAllDayCell
          key={"all_day"}
          height={hourHeight}
          onClick={() => {}}
          disableDrag={true}
          isDisabled={displaySettings?.disableEditPast && isInPast}
        />
      )}
      <DndContext
        sensors={sensors}
        onDragOver={args => {
          if (args.collisions && args.collisions[0]) {
            const collision = args.collisions[0];
            const time = collision.id as string;
            setDragEventData({
              eventId: args.active.id as string,
              newStart: new Date(
                dateAndTimeToIsoInTz(
                  {
                    date,
                    time,
                  },
                  clientTimezone
                )
              ),
              isDoneDragging: false,
            });
          }
        }}
        onDragEnd={() => {
          if (dragEventData) {
            setDragEventData({ ...dragEventData, isDoneDragging: true });
          }
        }}
        // TODO: When is this triggered?
        onDragCancel={() => setDragEventData(null)}
      >
        {children}
        {hours.map(h => (
          <CalendarHourCell
            key={h}
            hour={h}
            height={hourHeight}
            onClick={() => handleClickHour(h)}
            disableDrag={!dragSettings}
            isDisabled={displaySettings?.disableEditPast && isInPast}
          />
        ))}
        {eventsWithSlot.map(eventWithSlot => (
          <EventContainer
            key={eventWithSlot.event.id}
            hourHeight={hourHeight}
            date={date}
            eventWithSlot={eventWithSlot}
            clientTimezone={clientTimezone}
            hasAllDayCell={showAllDayCell}
            renderEvent={renderEvent}
            canDragEvent={dragSettings?.canDragEvent}
            doneDraggingData={
              dragEventData && dragEventData.eventId === eventWithSlot.event.id && dragEventData.isDoneDragging
                ? {
                    newStart: dragEventData.newStart,
                  }
                : null
            }
            onResolveDragging={() => setDragEventData(null)}
          />
        ))}
        {renderNewEvent && newEventStart && (
          <NewEventContainer
            hourHeight={hourHeight}
            date={date}
            event={{
              id: "NEW",
              start: newEventStart,
              durationInMinutes: 60,
            }}
            clientTimezone={clientTimezone}
            hasAllDayCell={showAllDayCell}
            renderEvent={renderNewEvent}
            onClose={() => setNewEventStart(null)}
          />
        )}
        {dragSettings && draggedEvent && dragEventData && !dragEventData.isDoneDragging && (
          <DraggedEventContainer<E>
            hourHeight={hourHeight}
            date={date}
            event={{ ...draggedEvent, start: dragEventData.newStart }}
            clientTimezone={clientTimezone}
            hasAllDayCell={showAllDayCell}
            renderEventPaper={dragSettings.renderEventPaper}
          />
        )}
        {isWithinInterval(currentTime, {
          start: dateStart,
          end: addDays(dateStart, 1),
        }) && (
          <>
            <div
              className={classes.timeIndicatorDiv}
              style={{
                top: (differenceInMinutes(currentTime, dateStart) / 60) * hourHeight + (showAllDayCell ? hourHeight : 0),
              }}
            />
            <Divider
              className={classes.timeIndicatorDivider}
              style={{
                top: (differenceInMinutes(currentTime, dateStart) / 60) * hourHeight + (showAllDayCell ? hourHeight : 0),
              }}
            />
          </>
        )}
      </DndContext>
    </Box>
  );
};

export default CalendarDayColumn;
