import { addMinutes } from "date-fns";
import { MealType, ActivityType, Omit } from "../types";
import { serializeTime, parseTime, canSaveDate } from "@notemeal/utils-date-time";

export interface ScheduleModalEditorMealState {
  id: string;
  name: string;
  type: MealType;
  start: string;
  startValue: Date | null;
  end: string;
  endValue: Date | null;
  diningSources: never[];
}

export interface ScheduleModalEditorActivityState {
  id: string;
  name: string;
  type: ActivityType;
  start: string;
  startValue: Date | null;
  end: string;
  endValue: Date | null;
}

export interface ScheduleModalEditorState {
  scheduleId: string | null;
  scheduleName: string;
  canEditScheduleName: boolean;
  // View State
  meals: ScheduleModalEditorMealState[];
  activities: ScheduleModalEditorActivityState[];
  // Pre-mutation State
  initialMeals: ScheduleModalEditorMealState[];
  initialActivities: ScheduleModalEditorActivityState[];
  activityFactor: number | null;
}

export interface ChangeNameAction {
  type: "CHANGE_NAME";
  payload: {
    name: string;
  };
}

export interface ChangeStartAction {
  type: "CHANGE_START";
  payload: {
    value: Date | null;
  };
}

export interface ChangeEndAction {
  type: "CHANGE_END";
  payload: {
    value: Date | null;
  };
}

export interface ChangeStartErrorAction {
  type: "CHANGE_START_ERROR";
  payload: {
    value: boolean;
  };
}

export interface ChangeEndErrorAction {
  type: "CHANGE_END_ERROR";
  payload: {
    value: boolean;
  };
}

// Meal Specific
export interface ChangeMealTypeAction {
  type: "CHANGE_MEAL_TYPE";
  payload: {
    type: MealType;
  } & MealPayload;
}

export interface AddMealAction {
  type: "ADD_MEAL";
  payload: MealPayload;
}

export interface RemoveMealAction {
  type: "REMOVE_MEAL";
  payload: MealPayload;
}

// Activity Specific
export interface ChangeActivityTypeAction {
  type: "CHANGE_ACTIVITY_TYPE";
  payload: {
    type: ActivityType;
  } & ActivityPaylod;
}

export interface AddActivityAction {
  type: "ADD_ACTIVITY";
  payload: ActivityPaylod;
}

export interface RemoveActivityAction {
  type: "REMOVE_ACTIVITY";
  payload: ActivityPaylod;
}

// Payload Interfaces
export interface MealPayload {
  mealId: string;
  activityId: null;
}

export interface ActivityPaylod {
  mealId: null;
  activityId: string;
}

export interface ChangeScheduleNameAction {
  type: "CHANGE_SCHEDULE_NAME";
  payload: {
    value: string;
    mealId: null;
    activityId: null;
  };
}

type BaseAction = ChangeNameAction | ChangeStartAction | ChangeEndAction | ChangeStartErrorAction | ChangeEndErrorAction;
type MealAction = (BaseAction & { payload: MealPayload }) | AddMealAction | RemoveMealAction | ChangeMealTypeAction;
type ActivityAction = (BaseAction & { payload: ActivityPaylod }) | AddActivityAction | RemoveActivityAction | ChangeActivityTypeAction;
export type ScheduleModalEditorAction = MealAction | ActivityAction | ChangeScheduleNameAction;

export const scheduleModalEditorReducer = (
  state: ScheduleModalEditorState,
  action: ScheduleModalEditorAction
): ScheduleModalEditorState => {
  switch (action.type) {
    case "CHANGE_SCHEDULE_NAME":
      return {
        ...state,
        scheduleName: action.payload.value,
      };
    default:
      const meals = mealsReducer(state.meals, action);
      const activities = activitiesReducer(state.activities, action);
      return {
        ...state,
        meals,
        activities,
      };
  }
};

const mealsReducer = (state: ScheduleModalEditorMealState[], action: ScheduleModalEditorAction): ScheduleModalEditorMealState[] => {
  if (action.payload.mealId === null) {
    return state;
  }
  switch (action.type) {
    case "ADD_MEAL":
      return [...state, { id: action.payload.mealId, ...newMeal() }];
    case "REMOVE_MEAL":
      return state.filter(m => m.id !== action.payload.mealId);
    case "CHANGE_NAME":
      return state.map(m => (m.id === action.payload.mealId ? { ...m, name: action.payload.name } : m));
    case "CHANGE_START":
      return state.map(m => {
        const startValue = action.payload.value;
        const canSaveStartValue = canSaveDate(startValue);
        return m.id === action.payload.mealId
          ? {
              ...m,
              startValue,
              start: startValue && canSaveStartValue ? serializeTime(startValue) : m.start,
              endValue: startValue && canSaveStartValue ? addMinutes(startValue, 30) : m.endValue,
              end: startValue && canSaveStartValue ? serializeTime(addMinutes(startValue, 30)) : m.end,
            }
          : m;
      });
    case "CHANGE_END":
      return state.map(m => {
        const endValue = action.payload.value;
        return m.id === action.payload.mealId
          ? {
              ...m,
              endValue,
              end: endValue && canSaveDate(endValue) ? serializeTime(endValue) : m.end,
            }
          : m;
      });
    case "CHANGE_MEAL_TYPE":
      return state.map(m => (m.id === action.payload.mealId ? { ...m, type: action.payload.type } : m));
    default:
      return state;
  }
};

const activitiesReducer = (
  state: ScheduleModalEditorActivityState[],
  action: ScheduleModalEditorAction
): ScheduleModalEditorActivityState[] => {
  if (action.payload.activityId === null) {
    return state;
  }
  switch (action.type) {
    case "ADD_ACTIVITY":
      return [...state, { id: action.payload.activityId, ...newActivity() }];
    case "REMOVE_ACTIVITY":
      return state.filter(a => a.id !== action.payload.activityId);
    case "CHANGE_NAME":
      return state.map(a => (a.id === action.payload.activityId ? { ...a, name: action.payload.name } : a));
    case "CHANGE_START":
      return state.map(a => {
        const startValue = action.payload.value;
        const canSaveStartValue = canSaveDate(startValue);
        return a.id === action.payload.activityId
          ? {
              ...a,
              startValue,
              start: startValue && canSaveStartValue ? serializeTime(startValue) : a.start,
              endValue: startValue && canSaveStartValue ? addMinutes(startValue, 30) : a.endValue,
              end: startValue && canSaveStartValue ? serializeTime(addMinutes(startValue, 30)) : a.end,
            }
          : a;
      });
    case "CHANGE_END":
      return state.map(a => {
        const endValue = action.payload.value;
        return a.id === action.payload.activityId
          ? {
              ...a,
              endValue,
              end: endValue && canSaveDate(endValue) ? serializeTime(endValue) : a.end,
            }
          : a;
      });
    case "CHANGE_ACTIVITY_TYPE":
      return state.map(a => (a.id === action.payload.activityId ? { ...a, type: action.payload.type } : a));
    default:
      return state;
  }
};

const DEFAULT_START_TIME = "06:00:00";
const DEFAULT_END_TIME = "06:30:00";

const newMeal = (): Omit<ScheduleModalEditorMealState, "id"> => ({
  name: "",
  type: "snack",
  diningSources: [],
  startValue: parseTime(DEFAULT_START_TIME),
  start: DEFAULT_START_TIME,
  endValue: parseTime(DEFAULT_END_TIME),
  end: DEFAULT_END_TIME,
});

const newActivity = (): Omit<ScheduleModalEditorActivityState, "id"> => ({
  name: "",
  type: "practice",
  startValue: parseTime(DEFAULT_START_TIME),
  start: DEFAULT_START_TIME,
  endValue: parseTime(DEFAULT_END_TIME),
  end: DEFAULT_END_TIME,
});
