import { eachDateOfInterval, eventToIsoOnDateInTz, parseDateTime, serializeDate, serializeTime } from "@notemeal/utils-date-time";
import {
  ActivityTemplateScheduleFragment,
  AddActivityTemplateCalendarInput,
  AddMealTemplateCalendarInput,
  CreateMealPlanCalendarInput,
  DayOfWeek,
  DayOfWeekPriorityFragment,
  DayOfWeekPriorityInput,
  EditActivityTemplateCalendarInput,
  EditMealPlanCalendarInput,
  EditMealTemplateCalendarInput,
  MealPlanConflictsQuery,
  MealPlanConflictsQueryVariables,
  OverrideMealPlanDateAssignmentInput,
  MealTemplateScheduleFragment,
  MealType,
  ActivityType,
  MealTemplateTimeFragment,
  ActivityTemplateTimeFragment,
  MealPlan,
} from "apps/web/src/types";
import { addMinutes, isValid } from "date-fns";
import {
  ActivityTemplateCalendarEvent,
  MealPlanScheduleEvent,
  MealTemplateCalendarEvent,
  SuggestedMealTemplateCalendarEvent,
  TeamworksCalendarEvent,
} from "../types";
import { getDayOfWeek, getMealPlanCalendarEventsForDate } from "../utils";
import {
  CreateMealPlanCalendarState,
  EditMealPlanCalendarState,
  MealPlanCalendarActivityTemplate,
  MealPlanCalendarMealTemplate,
  MealPlanCalendarState,
  MealPlanDateAssignment,
  MealPlanDateAssignmentMode,
} from "./types";
import { startOfMonth, addDays } from "date-fns";
import { QueryHookOptions } from "@apollo/client";
import { sortByFn } from "@notemeal/utils-sort";
import { EditScheduleEvent } from "./EditSchedule/types";
import { EditSchedule } from "./reducer";
import { v4 } from "uuid";

type OtherMealPlan = {
  id: string;
  name: string;
};

export const LOCKED_DAY_REASON =
  "The athlete has already recorded a meal for this date, so no action can be taken to edit the existing meal plan or assign a new meal plan.";

export const getEventsInRangeForMealPlanCalendarState = (
  state: MealPlanCalendarState,
  start: string,
  end: string,
  timezone: string
): {
  events: readonly MealPlanScheduleEvent[];
  otherMealPlans: readonly OtherMealPlan[];
} => {
  const assignedDates = getMealPlanAssignedDatesInRange(state.dateAssignment, start, end);
  const formMealPlanEvents = assignedDates.flatMap(date => {
    if (state.lockedDates.includes(date)) {
      return [];
    }
    return getEventsOnDateForMealPlanCalendarState(
      date,
      timezone,
      state.type === "Edit" ? state.mealPlanId : null,
      state.dateAssignment.mode,
      state.mealTemplates,
      state.activityTemplates
    );
  });

  const allDates = eachDateOfInterval({ start, end });
  const otherMealPlanResults = allDates.flatMap(date => {
    if (state.lockedDates.includes(date)) {
      return [];
    }
    const otherMealPlanDateAssignment = state.otherMealPlanDateAssignments.find(m => m.date === date);
    if (!otherMealPlanDateAssignment) {
      return [];
    }

    const dayOfWeek = getDayOfWeek(date);
    const events = getMealPlanCalendarEventsForDate(
      date,
      timezone,
      otherMealPlanDateAssignment.mealPlan.id,
      otherMealPlanDateAssignment.mealPlan.mealTemplates,
      otherMealPlanDateAssignment.mealPlan.activityTemplates,
      otherMealPlanDateAssignment,
      otherMealPlanDateAssignment.mealPlan.daysOfWeek.find(d => d.dayOfWeekPriority.dayOfWeek === dayOfWeek)
    );

    return {
      events,
      otherMealPlan: otherMealPlanDateAssignment && {
        id: otherMealPlanDateAssignment.mealPlan.id,
        name: otherMealPlanDateAssignment.mealPlan.name,
      },
    };
  });

  const otherMealPlans = otherMealPlanResults.reduce<readonly OtherMealPlan[]>((otherMealPlans, { otherMealPlan }) => {
    if (!otherMealPlan) {
      return otherMealPlans;
    }
    const existing = otherMealPlans.find(m => m.id === otherMealPlan.id);
    if (existing) {
      return otherMealPlans;
    }
    return [...otherMealPlans, otherMealPlan];
  }, []);

  return {
    events: formMealPlanEvents.concat(otherMealPlanResults.flatMap(r => r.events)),
    otherMealPlans,
  };
};

const getEventsOnDateForMealPlanCalendarState = (
  date: string,
  timezone: string,
  mealPlanId: string | null,
  dateAssignmentMode: MealPlanDateAssignmentMode,
  mealTemplates: readonly MealPlanCalendarMealTemplate[],
  activityTemplates: readonly MealPlanCalendarActivityTemplate[]
): MealPlanScheduleEvent[] => {
  const dayOfWeek = getDayOfWeek(date);

  const mealTemplateEvents: readonly MealTemplateCalendarEvent[] = mealTemplates.map(mealTemplate => {
    const modificationByDate = mealTemplate.dateModifications.find(m => m.date === date);
    const modificationByDayOfWeek =
      dateAssignmentMode === "weekly" ? mealTemplate.dayOfWeekModifications.find(m => m.dayOfWeek === dayOfWeek) : undefined;

    // Modifications on an individual date take priority over day of week modifications
    const modification = modificationByDate ?? modificationByDayOfWeek;

    const { durationInMinutes, start } = eventToIsoOnDateInTz(modification ?? mealTemplate.meal, date, timezone);
    return {
      type: "MealTemplate",
      id: mealTemplate.id,
      mealTemplate,
      start: new Date(start),
      durationInMinutes,
      mealPlanId,
    };
  });

  const activityTemplateEvents: readonly ActivityTemplateCalendarEvent[] = activityTemplates.map(activityTemplate => {
    const modificationByDate = activityTemplate.dateModifications.find(m => m.date === date);
    const modificationByDayOfWeek = activityTemplate.dayOfWeekModifications.find(m => m.dayOfWeek === dayOfWeek);

    // Modifications on an individual date take priority over day of week modifications
    const modification = modificationByDate ?? modificationByDayOfWeek;

    const { durationInMinutes, start } = eventToIsoOnDateInTz(modification ?? activityTemplate.activity, date, timezone);
    return {
      type: "ActivityTemplate",
      id: activityTemplate.id,
      activityTemplate,
      start: new Date(start),
      durationInMinutes,
      mealPlanId,
    };
  });

  return [...mealTemplateEvents, ...activityTemplateEvents];
};

export const getMealPlanAssignedDatesInRange = (dateAssignment: MealPlanDateAssignment, start: string, end: string) => {
  if (dateAssignment.mode === "individual") {
    return dateAssignment.individualDates.filter(date => date >= start && date <= end);
  } else {
    const daysOfWeek = dateAssignment.dayOfWeekPriorities.map(d => d.dayOfWeek);
    return eachDateOfInterval({ start, end }).filter(date => {
      const onDayOfWeek = daysOfWeek.includes(getDayOfWeek(date));
      const afterStart = date >= dateAssignment.startDate;
      const beforeEnd = dateAssignment.endDate === null ? true : date <= dateAssignment.endDate;

      return onDayOfWeek && afterStart && beforeEnd;
    });
  }
};

export const getScheduleName = (state: MealPlanCalendarState): string => {
  if (state.type === "Create") {
    const selectedSchedule = state.schedules.concat(state.teamSchedules).find(s => s.id === state.selectedScheduleId);
    return selectedSchedule?.name ?? "No Schedule Selected";
  } else {
    return state.schedule?.name ?? "Custom Schedule";
  }
};

export const ORDERED_DAYS_OF_WEEK: readonly DayOfWeek[] = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export const mealPlanCalendarStateToSaveTooltips = ({ dateAssignment, ...rest }: MealPlanCalendarState): string[] => {
  let tooltips = mealPlanDateAssignmentToSaveTooltips(dateAssignment);

  if (rest.type === "Create") {
    if (serializeDate(new Date()) > dateAssignment.startDate) {
      tooltips.push("Start Date cannot be in the past");
    }

    if (!rest.selectedScheduleId) {
      tooltips.push("Schedule is required");
    }
  }

  return tooltips;
};

export const mealPlanDateAssignmentToSaveTooltips = ({
  mode,
  individualDates,
  startDate,
  startDateRaw,
  endDate,
  endDateRaw,
}: MealPlanDateAssignment): string[] => {
  let tooltips = [];
  if (mode === "individual") {
    if (individualDates.length === 0) {
      tooltips.push("Add at least one date");
    }
  } else {
    if (!startDateRaw) {
      tooltips.push("Start Date is required");
    }

    if (!isValidDateInputAndValue(startDateRaw, startDate)) {
      tooltips.push("Invalid Start Date");
    }

    if (!isValidDateInputAndValue(endDateRaw, endDate)) {
      tooltips.push("Invalid End Date");
    }

    if (endDate && startDate > endDate) {
      tooltips.push("End Date cannot not be before Start Date");
    }
  }

  return tooltips;
};

const isValidDateInputAndValue = (rawDate: Date | null, date: string | null): boolean => {
  if (!rawDate && !date) {
    return true;
  }

  return Boolean(rawDate && isValid(rawDate) && serializeDate(rawDate) === date);
};

export const getCreateMealPlanCalendarInput = (
  state: CreateMealPlanCalendarState,
  conflictResolutions: readonly MealPlanDateAssignmentConflictResolution[]
): CreateMealPlanCalendarInput | null => {
  if (!state.selectedScheduleId) {
    return null;
  }
  // if any suggestions have been accepted, replace the meal templates with the accepted suggestions
  const resolvedStateMealTemplates = resolveMealTemplatesWithSuggestions(state.mealTemplates, state.suggestionsState.acceptedSuggestions);

  const common = {
    scheduleId: state.selectedScheduleId,
    isAutoSuggestionsEnabled: state.suggestionsState.isAutoSuggestionsEnabled,
    mealTemplates: resolvedStateMealTemplates.map(getAddMealTemplateCalendarInput(state.dateAssignment.mode)),
    activityTemplates: state.activityTemplates.map(getAddActivityTemplateCalendarInput(state.dateAssignment.mode)),
  };

  if (state.dateAssignment.mode === "individual") {
    return {
      ...common,
      individualDates: getIndividualDatesByConflictResolutions(state.dateAssignment.individualDates, conflictResolutions),
      startDate: null,
      endDate: null,
      dayOfWeekPriorities: null,
      removeConflictingIndividualDates: null,
    };
  } else {
    return {
      ...common,
      individualDates: null,
      startDate: state.dateAssignment.startDate,
      endDate: state.dateAssignment.endDate,
      ...getDayOfWeekPrioritiesByConflictResolutions(state.dateAssignment.dayOfWeekPriorities, conflictResolutions, "Create"),
    };
  }
};

export const getOverrideMealPlanDateAssignmentInput = (state: MealPlanDateAssignment): OverrideMealPlanDateAssignmentInput => {
  if (state.mode === "individual") {
    return {
      individualDates: state.individualDates,
      startDate: null,
      endDate: null,
      daysOfWeek: null,
    };
  } else {
    return {
      individualDates: null,
      startDate: state.startDate,
      endDate: state.endDate,
      daysOfWeek: state.dayOfWeekPriorities.map(({ dayOfWeek }) => dayOfWeek),
    };
  }
};

const getIndividualDatesByConflictResolutions = (
  individualDates: readonly string[],
  conflictResolutions: readonly MealPlanDateAssignmentConflictResolution[]
) => {
  return individualDates.filter(date => {
    const conflictResolution = conflictResolutions.find(r => r.current.date === date);
    return !conflictResolution || conflictResolution.override;
  });
};

const getDayOfWeekPrioritiesByConflictResolutions = (
  dayOfWeekPriorities: readonly DayOfWeekPriorityFragment[],
  conflictResolutions: readonly MealPlanDateAssignmentConflictResolution[],
  type: MealPlanCalendarState["type"]
): { dayOfWeekPriorities: readonly DayOfWeekPriorityInput[]; removeConflictingIndividualDates: readonly string[] } => {
  const results = dayOfWeekPriorities.flatMap<{
    dayOfWeekPriority: DayOfWeekPriorityInput;
    removeConflictingIndividualDate: string | undefined;
  }>(({ dayOfWeek, priority }) => {
    const conflictResolution = conflictResolutions.find(r => r.current.dayOfWeekPriority?.dayOfWeek === dayOfWeek);
    if (!conflictResolution) {
      return {
        dayOfWeekPriority: {
          dayOfWeek,
          priority,
        },
        removeConflictingIndividualDate: undefined,
      };
    } else if (conflictResolution.override) {
      // conflicting.dayOfWeekPriority shouldn't be null based on find fn above
      const conflictingPriority = conflictResolution.conflicting.dayOfWeekPriority?.priority ?? 1;
      return {
        dayOfWeekPriority: {
          dayOfWeek,
          priority: conflictingPriority + 1,
        },
        removeConflictingIndividualDate: conflictResolution.conflicting.date,
      };
    } else if (type === "Edit") {
      // If editing keep the current priority (if not overriding)
      return {
        dayOfWeekPriority: {
          dayOfWeek,
          priority,
        },
        removeConflictingIndividualDate: undefined,
      };
    } else {
      // If creating filter (if not overriding)
      return [];
    }
  });

  return {
    dayOfWeekPriorities: results.map(r => r.dayOfWeekPriority),
    removeConflictingIndividualDates: results.flatMap(r => r.removeConflictingIndividualDate ?? []),
  };
};

const getAddMealTemplateCalendarInput =
  (dateAssignmentMode: MealPlanDateAssignmentMode) =>
  ({
    meal: { name, start, end, type },
    dayOfWeekModifications,
    dateModifications,
  }: MealPlanCalendarMealTemplate): AddMealTemplateCalendarInput => {
    return {
      meal: {
        name,
        start,
        end,
        type,
      },
      dayOfWeekModifications:
        dateAssignmentMode === "weekly" ? dayOfWeekModifications.map(({ dayOfWeek, start, end }) => ({ dayOfWeek, start, end })) : [],
      dateModifications: dateModifications.map(({ date, start, end }) => ({ date, start, end })),
    };
  };

const getAddActivityTemplateCalendarInput =
  (dateAssignmentMode: MealPlanDateAssignmentMode) =>
  ({
    activity: { name, start, end, type },
    dayOfWeekModifications,
    dateModifications,
  }: MealPlanCalendarActivityTemplate): AddActivityTemplateCalendarInput => {
    return {
      activity: {
        name,
        start,
        end,
        type,
      },
      dayOfWeekModifications:
        dateAssignmentMode === "weekly" ? dayOfWeekModifications.map(({ dayOfWeek, start, end }) => ({ dayOfWeek, start, end })) : [],
      dateModifications: dateModifications.map(({ date, start, end }) => ({ date, start, end })),
    };
  };

export const getEditMealPlanCalendarInput = (
  state: EditMealPlanCalendarState,
  initMealTemplates: readonly MealTemplateScheduleFragment[],
  initActivityTemplates: readonly ActivityTemplateScheduleFragment[],
  conflictResolutions: readonly MealPlanDateAssignmentConflictResolution[]
): EditMealPlanCalendarInput => {
  // if any suggestions have been accepted, replace the meal templates with the accepted suggestions
  const resolvedStateMealTemplates = resolveMealTemplatesWithSuggestions(state.mealTemplates, state.suggestionsState.acceptedSuggestions);

  const initialMealTemplateIds = initMealTemplates.map(m => m.id);
  const finalMealTemplateIds = resolvedStateMealTemplates.map(m => m.id);

  const initialActivityTemplateIds = initActivityTemplates.map(a => a.id);
  const finalActivityTemplateIds = state.activityTemplates.map(a => a.id);

  const commonInput = {
    mealPlanId: state.mealPlanId,
    isAutoSuggestionsEnabled: state.suggestionsState.isAutoSuggestionsEnabled,
    addMealTemplates: resolvedStateMealTemplates
      .filter(m => !initialMealTemplateIds.includes(m.id))
      .map(getAddMealTemplateCalendarInput(state.dateAssignment.mode)),
    addActivityTemplates: state.activityTemplates
      .filter(a => !initialActivityTemplateIds.includes(a.id))
      .map(getAddActivityTemplateCalendarInput(state.dateAssignment.mode)),
    editMealTemplates: resolvedStateMealTemplates
      .filter(m => initialMealTemplateIds.includes(m.id))
      .map(getEditMealTemplateCalendarInput(state.dateAssignment.mode)),
    editActivityTemplates: state.activityTemplates
      .filter(a => initialActivityTemplateIds.includes(a.id))
      .map(getEditActivityTemplateCalendarInput(state.dateAssignment.mode)),
    removeMealTemplateIds: initMealTemplates.filter(m => !finalMealTemplateIds.includes(m.id)).map(m => m.id),
    removeActivityTemplateIds: initActivityTemplates.filter(a => !finalActivityTemplateIds.includes(a.id)).map(a => a.id),
  };

  // If I haven't loaded the dates, then they cannot be removed..
  // Need to keep track of which individual dates are present on first load of a given month (loadedIndividualDates)
  if (state.dateAssignment.mode === "individual") {
    const addIndividualDates = getIndividualDatesByConflictResolutions(
      state.dateAssignment.individualDates.filter(d => !state.loadedIndividualDates.includes(d)),
      conflictResolutions
    );
    const removeIndividualDates = state.loadedIndividualDates.filter(d => !state.dateAssignment.individualDates.includes(d));
    return {
      ...commonInput,
      startDate: null,
      endDate: null,
      dayOfWeekPriorities: null,
      removeConflictingIndividualDates: null,
      addIndividualDates,
      removeIndividualDates,
    };
  } else {
    return {
      ...commonInput,
      addIndividualDates: null,
      removeIndividualDates: null,
      startDate: state.dateAssignment.startDate,
      endDate: state.dateAssignment.endDate,
      ...getDayOfWeekPrioritiesByConflictResolutions(state.dateAssignment.dayOfWeekPriorities, conflictResolutions, state.type),
    };
  }
};

const getEditMealTemplateCalendarInput =
  (dateAssignmentMode: MealPlanDateAssignmentMode) =>
  ({
    id: mealTemplateId,
    resetModifications,
    meal: { name, start, end, type },
    dayOfWeekModifications,
    dateModifications,
  }: MealPlanCalendarMealTemplate): EditMealTemplateCalendarInput => {
    return {
      mealTemplateId,
      resetModifications,
      meal: {
        name,
        start,
        end,
        type,
      },
      dayOfWeekModifications:
        dateAssignmentMode === "weekly" ? dayOfWeekModifications.map(({ dayOfWeek, start, end }) => ({ dayOfWeek, start, end })) : [],
      dateModifications: dateModifications.map(({ date, start, end }) => ({ date, start, end })),
    };
  };

const getEditActivityTemplateCalendarInput =
  (dateAssignmentMode: MealPlanDateAssignmentMode) =>
  ({
    id: activityTemplateId,
    resetModifications,
    activity: { name, start, end, type },
    dayOfWeekModifications,
    dateModifications,
  }: MealPlanCalendarActivityTemplate): EditActivityTemplateCalendarInput => {
    return {
      activityTemplateId,
      resetModifications,
      activity: {
        name,
        start,
        end,
        type,
      },
      dayOfWeekModifications:
        dateAssignmentMode === "weekly" ? dayOfWeekModifications.map(({ dayOfWeek, start, end }) => ({ dayOfWeek, start, end })) : [],
      dateModifications: dateModifications.map(({ date, start, end }) => ({ date, start, end })),
    };
  };

export const isMealPlanCalendarWeekLoading = (state: MealPlanCalendarState, startOfWeekDate: Date) => {
  // Meal plan calendar loads based on month
  // Since a week may cross the month boundary, we need to check if both the start and end of the week are loaded
  const requiredStartOfMonths = [startOfWeekDate, addDays(startOfWeekDate, 6)].map(date => serializeDate(startOfMonth(startOfWeekDate)));

  return !requiredStartOfMonths.every(date => state.loadedStartOfMonths.includes(date));
};

export const isMealPlanCalendarMonthLoading = (state: MealPlanCalendarState, startOfMonthDate: Date): boolean => {
  return !state.loadedStartOfMonths.includes(serializeDate(startOfMonthDate));
};

export const getMealPlanName = (state: MealPlanCalendarState): string => {
  if (state.type === "Create") {
    return "New Meal Plan";
  } else {
    return state.mealPlanName;
  }
};

export const getMealPlanConflictsQueryVariables = (
  athleteId: string,
  { dateAssignment }: MealPlanCalendarState
): QueryHookOptions<MealPlanConflictsQuery, MealPlanConflictsQueryVariables> => {
  if (dateAssignment.mode === "individual") {
    if (dateAssignment.individualDates.length === 0) {
      return {
        skip: true,
      };
    }
    const sortedDates = sortByFn(dateAssignment.individualDates, date => date);
    return {
      variables: {
        athleteId,
        start: sortedDates[0],
        end: sortedDates[sortedDates.length - 1],
      },
    };
  } else {
    if (!dateAssignment.startDate) {
      return {
        skip: true,
      };
    }
    return {
      variables: {
        athleteId,
        start: dateAssignment.startDate,
        end: dateAssignment.endDate,
      },
    };
  }
};

type DateOrDayOfWeek =
  | {
      date?: undefined;
      dayOfWeekPriority: DayOfWeekPriorityFragment;
    }
  | {
      dayOfWeekPriority?: undefined;
      date: string;
    };

export type MealPlanDateAssignmentConflict = {
  mealPlan: { id: string; name: string };
  current: DateOrDayOfWeek;
  conflicting: DateOrDayOfWeek;
  initOverride: boolean;
};

export type MealPlanDateAssignmentConflictResolution = MealPlanDateAssignmentConflict & {
  override: boolean;
};

export const getDateAssignmentConflicts = (
  state: MealPlanCalendarState,
  data: MealPlanConflictsQuery
): MealPlanDateAssignmentConflict[] => {
  const mealPlanId = state.type === "Edit" ? state.mealPlanId : null;
  if (state.dateAssignment.mode === "weekly") {
    const dayOfWeekConflicts: MealPlanDateAssignmentConflict[] = state.dateAssignment.dayOfWeekPriorities.flatMap(
      ({ dayOfWeek, priority }) => {
        const matching = data.athlete.priorityMealPlanDaysOfWeekInRange.find(
          d => d.dayOfWeekPriority.dayOfWeek === dayOfWeek && d.mealPlan.id !== mealPlanId
        );
        if (!matching) {
          return [];
        }
        return {
          current: { dayOfWeekPriority: { dayOfWeek, priority } },
          conflicting: { dayOfWeekPriority: matching.dayOfWeekPriority },
          mealPlan: matching.mealPlan,
          initOverride: priority > matching.dayOfWeekPriority.priority,
        };
      }
    );
    const individualDateConflicts: MealPlanDateAssignmentConflict[] = state.dateAssignment.dayOfWeekPriorities.flatMap(
      ({ dayOfWeek, priority }) => {
        const matching = data.athlete.mealPlanIndividualDatesInRange.find(
          ({ mealPlan, date }) => getDayOfWeek(date) === dayOfWeek && mealPlan.id !== mealPlanId
        );
        if (!matching) {
          return [];
        }
        return {
          current: { dayOfWeekPriority: { dayOfWeek, priority } },
          conflicting: { date: matching.date },
          mealPlan: matching.mealPlan,
          initOverride: false,
        };
      }
    );
    return dayOfWeekConflicts.concat(individualDateConflicts);
  } else {
    const individualDateConflicts: MealPlanDateAssignmentConflict[] = state.dateAssignment.individualDates.flatMap(date => {
      const matching = data.athlete.mealPlanIndividualDatesInRange.find(
        ({ mealPlan, date: individualDate }) => date === individualDate && mealPlan.id !== mealPlanId
      );
      if (!matching) {
        return [];
      }
      return {
        current: { date },
        conflicting: { date: matching.date },
        mealPlan: matching.mealPlan,
        initOverride: false,
      };
    });
    return individualDateConflicts;
  }
};

export const resolveEditScheduleEventsToSchedule = (events: readonly EditScheduleEvent[]): EditSchedule["payload"] => {
  return {
    mealTemplates: events.flatMap(e => {
      if (e.type !== "meal") {
        return [];
      }
      return {
        id: e.id,
        meal: {
          __typename: "Meal",
          id: e.mealId,
          name: e.name,
          start: e.start,
          end: e.end,
          type: e.mealType,
        },
      };
    }),
    activityTemplates: events.flatMap(e => {
      if (e.type !== "activity") {
        return [];
      }
      return {
        id: e.id,
        activity: {
          __typename: "Activity",
          id: e.activityId,
          name: e.name,
          start: e.start,
          end: e.end,
          type: e.activityType,
        },
      };
    }),
    resetMealTemplateIds: events.flatMap(m => (m.type === "meal" && !m.modified ? m.id : [])),
    resetActivityTemplateIds: events.flatMap(m => (m.type === "activity" && !m.modified ? m.id : [])),
  };
};

export const resolveTemplatesToSchedule = (
  mealTemplates: readonly MealPlanCalendarMealTemplate[],
  activityTemplates: readonly MealPlanCalendarActivityTemplate[]
): EditSchedule["payload"] => {
  return {
    mealTemplates: mealTemplates.flatMap(mt => {
      const meal = mt.meal;
      return {
        id: mt.id,
        meal: {
          __typename: "Meal",
          id: meal.id,
          name: meal.name,
          start: meal.start,
          end: meal.end,
          type: meal.type,
        },
      };
    }),
    activityTemplates: activityTemplates.flatMap(at => {
      const activity = at.activity;
      return {
        id: at.id,
        activity: {
          __typename: "Activity",
          id: activity.id,
          name: activity.name,
          start: activity.start,
          end: activity.end,
          type: activity.type,
        },
      };
    }),
    resetMealTemplateIds: [],
    resetActivityTemplateIds: [],
  };
};

interface BaseNewEvent {
  name: string;
  start: string;
  end: string;
}

interface NewMealEvent extends BaseNewEvent {
  type: MealType;
  __typename: "Meal";
}

interface NewActivityEvent extends BaseNewEvent {
  type: ActivityType;
  __typename: "Activity";
}

export type NewEvent = NewMealEvent | NewActivityEvent;

export const createMealTemplateTimeFragmentFromEvent = ({ name, type, start, end }: NewMealEvent): MealTemplateTimeFragment => {
  const startTime = serializeTime(parseDateTime(start));
  const endTime = serializeTime(parseDateTime(end));

  return {
    id: v4(),
    meal: {
      __typename: "Meal",
      id: v4(),
      name,
      start: startTime,
      end: endTime,
      type,
    },
  };
};

export const createActivityTemplateTimeFragmentFromEvent = ({ name, type, start, end }: NewActivityEvent): ActivityTemplateTimeFragment => {
  const startTime = serializeTime(parseDateTime(start));
  const endTime = serializeTime(parseDateTime(end));

  return {
    id: v4(),
    activity: {
      __typename: "Activity",
      id: v4(),
      name,
      start: startTime,
      end: endTime,
      type,
    },
  };
};

interface ScheduleKeyProps {
  state: MealPlanCalendarState;
  teamworksCalendarEventColor: string;
  curMealPlanColor: string;
  getMealPlanColor: (mealPlanId: string) => string;
  twCalendarEvents: TeamworksCalendarEvent[];
}

export const getScheduleKeyForForm = ({
  state,
  teamworksCalendarEventColor,
  curMealPlanColor,
  getMealPlanColor,
  twCalendarEvents,
}: ScheduleKeyProps) => {
  const otherMealPlans = state.otherMealPlanDateAssignments
    .map(asgnt => asgnt.mealPlan)
    .reduce((uniqueMealPlans: Pick<MealPlan, "id" | "name">[], mp) => {
      if (!uniqueMealPlans.some(otherMp => otherMp.id === mp.id)) {
        uniqueMealPlans.push(mp);
      }
      return uniqueMealPlans;
    }, []);

  const currMealPlan = {
    id: state.type === "Edit" ? state.mealPlanId : "New",
    label: getMealPlanName(state),
    color: curMealPlanColor,
  };

  let schedules = [currMealPlan, ...otherMealPlans.map(({ id, name }) => ({ id, label: name, color: getMealPlanColor(id) }))];

  if (twCalendarEvents.length > 0) {
    schedules = schedules.concat({
      id: "Teamworks",
      label: "Teamworks Calendar",
      color: teamworksCalendarEventColor,
    });
  }
  return schedules;
};

const resolveMealTemplatesWithSuggestions = (
  mealTemplates: readonly MealPlanCalendarMealTemplate[],
  suggestions: readonly SuggestedMealTemplateCalendarEvent[]
) => {
  return mealTemplates.map(m => {
    const matchedSuggestion = suggestions.find(s => s.oldId === m.id);
    if (!matchedSuggestion) {
      return m;
    }

    const { start, durationInMinutes } = matchedSuggestion;
    return {
      ...m,
      hasDateModifications: true,
      dateModifications: [
        ...m.dateModifications,
        {
          date: serializeDate(start),
          start: serializeTime(start),
          end: serializeTime(addMinutes(start, durationInMinutes)),
        },
      ],
    };
  });
};
