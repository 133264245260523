import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

import { formatPickupTime, getPickupTimePickerLabel, isPickupTimeError, PickupTime, useClientTimezone } from "@notemeal/shared-ui";

interface MenuOrderFormTimePickerProps {
  className?: string;
  // label: string;
  forOrder: boolean;
  pickupTime: PickupTime | null;
  pickupTimes: PickupTime[];
  onChangePickupTime: (pickupTime: PickupTime) => void;
  mealMenuTimezone: string;
  disabled: boolean;
  openTimePicker?: boolean;
}

const MenuOrderFormTimePicker = ({
  className,
  // label,
  forOrder,
  disabled,
  onChangePickupTime,
  pickupTime,
  pickupTimes,
  mealMenuTimezone,
  openTimePicker,
}: MenuOrderFormTimePickerProps) => {
  const clientTimezone = useClientTimezone();

  const [pickerOpen, setPickerOpen] = useState(false);

  useEffect(() => {
    if (openTimePicker !== undefined) {
      setPickerOpen(true);
    }
  }, [openTimePicker]);

  const pickupTimesWithFormat = pickupTimes.map(pickupTime => ({
    pickupTime,
    format: formatPickupTime({
      pickupTime,
      mealMenuTimezone,
      clientTimezone,
      forLog: !forOrder,
    }),
  }));

  const matchingPickupTime = pickupTimes.find(pt => {
    if (pickupTime?.type === "Time") {
      return pt.type === "Time" && pt.value === pickupTime.value;
    } else {
      return pt.type === "ASAP";
    }
  });

  const label = getPickupTimePickerLabel(forOrder);
  const error = isPickupTimeError(pickupTime, forOrder);

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
        onOpen={() => setPickerOpen(true)}
        onClose={() => setPickerOpen(false)}
        open={pickerOpen}
        className={className}
        margin="none"
        error={error}
        disabled={disabled}
        value={
          pickupTime &&
          formatPickupTime({
            pickupTime,
            mealMenuTimezone,
            clientTimezone,
            forLog: !forOrder,
          })
        }
        onChange={e => {
          const matchingPickupTime = pickupTimesWithFormat.find(pt => pt.format === e.target.value);
          if (matchingPickupTime) {
            onChangePickupTime(matchingPickupTime.pickupTime);
          }
        }}
      >
        {pickupTimesWithFormat.map(({ format }) => (
          <MenuItem key={format} value={format}>
            {format}
          </MenuItem>
        ))}
        {pickupTime && !matchingPickupTime && (
          <MenuItem
            value={formatPickupTime({
              pickupTime,
              mealMenuTimezone,
              clientTimezone,
              forLog: !forOrder,
            })}
          >
            {formatPickupTime({
              pickupTime,
              mealMenuTimezone,
              clientTimezone,
              forLog: !forOrder,
            })}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default MenuOrderFormTimePicker;
