import { MealPlanType, ExchangeSetFragment } from "../../../types";
import {newId} from "@notemeal/shared-ui";

export interface MealPlanFormState {
  id: string;
  name: string;
  description: string;
  type: MealPlanType;
  initialExchangeSetId: string | null;
  exchangeSet: ExchangeSetFragment | null;
  exchangeSets: readonly ExchangeSetFragment[];
  isShared: boolean;
}

interface ChangeName {
  type: "CHANGE_NAME";
  payload: string;
}

interface ChangeDescription {
  type: "CHANGE_DESCRIPTION";
  payload: string;
}

interface ChangeIsShared {
  type: "CHANGE_IS_SHARED";
  payload: boolean;
}

interface ChangeType {
  type: "CHANGE_TYPE";
  payload: MealPlanType;
}

interface ChangeExchangeSet {
  type: "CHANGE_EXCHANGE_SET";
  payload: ExchangeSetFragment | null;
}

export type MealPlanFormAction = ChangeName | ChangeType | ChangeDescription | ChangeIsShared | ChangeExchangeSet;

export const mealPlanFormReducer = (state: MealPlanFormState, action: MealPlanFormAction): MealPlanFormState => {
  switch (action.type) {
    case "CHANGE_NAME":
      return { ...state, name: action.payload };
    case "CHANGE_DESCRIPTION":
      return { ...state, description: action.payload };
    case "CHANGE_IS_SHARED":
      return { ...state, isShared: action.payload };
    case "CHANGE_TYPE":
      return {
        ...state,
        type: action.payload,
        exchangeSet: action.payload === "macro" ? null : state.exchangeSet === null ? state.exchangeSets[0] || null : state.exchangeSet,
      };
    case "CHANGE_EXCHANGE_SET":
      return { ...state, exchangeSet: action.payload };
  }
};

export const mealPlanFormToSaveTooltips = (state: MealPlanFormState): string[] => {
  let tooltips = [];
  if (!state.name) {
    tooltips.push("Name is required");
  }
  if (state.type === "exchange" && !state.exchangeSet) {
    tooltips.push("Must select an exchange set");
  }
  return tooltips;
};

export const initialMealPlanFormState = (exchangeSets: readonly ExchangeSetFragment[]): MealPlanFormState => {
  return {
    id: newId(),
    name: "",
    description: "",
    type: "macro",
    isShared: false,
    exchangeSet: null,
    initialExchangeSetId: null,
    exchangeSets,
  };
};
