import { DayOfWeek } from "@notemeal/graphql-types";
import { PlannedMenuOccurrence, PlannedMenuWeekSummaryFragment } from "apps/web/src/types";
import { addWeeks, parseISO, subWeeks } from "date-fns";

export type MenuBuilderWeek = {
  id: string;
  weekNumber: number;
  startDate: Date;
  endDate: Date;
};

type PlannedMenu = {
  firstOccurrence: string;
  lastOccurrence: string;
};

export const transformWeeks = (weeks: readonly PlannedMenuWeekSummaryFragment[], menu: PlannedMenu): MenuBuilderWeek[] => {
  const firstDate = parseISO(menu.firstOccurrence);
  const lastDate = parseISO(menu.lastOccurrence);
  return weeks.map((week, index) => {
    return {
      id: week.id,
      weekNumber: week.week,
      // adding/subtracting weeks from the dates avoids having to know the occurrence
      startDate: addWeeks(firstDate, index),
      endDate: subWeeks(lastDate, weeks.length - index - 1),
    };
  });
};

export const getDays = (occurrence: PlannedMenuOccurrence): DayOfWeek[] => {
  if (occurrence === "daily") {
    return [
      DayOfWeek.monday,
      DayOfWeek.tuesday,
      DayOfWeek.wednesday,
      DayOfWeek.thursday,
      DayOfWeek.friday,
      DayOfWeek.saturday,
      DayOfWeek.sunday,
    ];
  }
  return [DayOfWeek.monday, DayOfWeek.tuesday, DayOfWeek.wednesday, DayOfWeek.thursday, DayOfWeek.friday];
};
