import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, Grid, IconButton, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortByFn, sortByKey } from "@notemeal/utils-sort";
import TextFieldWrapper from "apps/web/src/components/universal/TextFieldWrapper";
import classNames from "classnames";
import React, { Fragment } from "react";
import ChipListPreview from "../../../../../components/universal/ChipList/Preview";
import {
    AthleteForCustomTagFragment,
    NamedTagForSelectionFragment,
    PositionWitSportFragment,
    TeamPreviewFragment,
} from "../../../../../types";
import NamedTagChip from "../../../NamedTagChip";
import {
    AdvancedSelectionCondition,
    AdvancedSelectionGroup,
    AdvancedSelectionState,
    TagCondition,
    isConditionEmpty,
    isGroupEmpty,
} from "../../../reducers/advancedSelectionReducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listSpacer: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    period: {
      marginLeft: -theme.spacing(1),
      "&:last-child": {
        marginRight: theme.spacing(0),
      },
    },
    container: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      flexWrap: "wrap",
      marginTop: -theme.spacing(1),
    },
  })
);

const HIDE_ATHLETES_AFTER = 2;

interface BaseShareWithInfoProps {
  state: AdvancedSelectionState;
  maxChips: number;
  showWrapper?: boolean;
  containerSx?: SxProps;
  containerClassName?: string;
  isForSmallSpace?: boolean;
  includeTrailingPeriod?: boolean;
}

interface ShareWithInfoPropsWithClear extends BaseShareWithInfoProps {
  onClear: () => void;
  showAdvancedSelectionClear: true;
}

interface ShareWithInfoPropsWithOutClear extends BaseShareWithInfoProps {
  onClear?: () => void;
  showAdvancedSelectionClear: false;
}

type ShareWithInfoProps = ShareWithInfoPropsWithClear | ShareWithInfoPropsWithOutClear;

interface MergedConditionStatement {
  team: TeamPreviewFragment | null;
  position: PositionWitSportFragment | null;
  tags: NamedTagForSelectionFragment[];
}

interface MergedGroup {
  mergedIncludeConditions: MergedConditionStatement[];
  mergedExcludeConditions: MergedConditionStatement[];
  isFullGroup: boolean;
  groupId: string;
}

type ShareWithInfoGroupProps = Omit<MergedGroup, "groupId"> & {
  includeTrailingPeriod?: boolean;
};

interface ShareWithInfoGroupStatementProps {
  mergedConditions: MergedConditionStatement[];
}

interface ShareWithInfoGroupsProps {
  mergedGroups: MergedGroup[];
  includeTrailingPeriod?: boolean;
}

interface AthleteChipListProps {
  athletes: readonly AthleteForCustomTagFragment[];
}

const getBaseCondition = (): MergedConditionStatement => ({
  team: null,
  position: null,
  tags: [],
});

const mergeConditions = (
  teams: readonly TeamPreviewFragment[],
  positions: readonly PositionWitSportFragment[],
  tagConditions: readonly TagCondition[]
): MergedConditionStatement[] => {
  const baseCondition = getBaseCondition();
  const mappedTeams: MergedConditionStatement[] = teams.map(team => ({ ...baseCondition, team }));

  // Combine flattened teams and positions
  const teamsAndPositions: MergedConditionStatement[] =
    positions.length === 0
      ? mappedTeams
      : mappedTeams.length > 0
      ? positions.reduce((combinedList, position) => {
          return combinedList.concat(mappedTeams.map(mappedTeam => ({ ...mappedTeam, position })));
        }, [] as MergedConditionStatement[])
      : positions.map(position => ({ ...baseCondition, position }));

  const mergeConditions: MergedConditionStatement[] = tagConditions.reduce((combinedList, tagCondition) => {
    let outerCombinedList: MergedConditionStatement[] = combinedList;
    // operate on the combined list so it will combine the tags together as needed
    outerCombinedList =
      tagCondition.tags.length === 0
        ? outerCombinedList
        : sortByFn(tagCondition.tags, namedTag => namedTag.tagName.name).reduce(
            (innerCombinedList, tag) =>
              innerCombinedList.concat(
                outerCombinedList.length > 0
                  ? outerCombinedList.map(mergedCondition => ({ ...mergedCondition, tags: mergedCondition.tags.concat(tag) }))
                  : {
                      tags: [tag],
                      position: null,
                      team: null,
                    }
              ),
            [] as MergedConditionStatement[]
          );
    return outerCombinedList;
  }, teamsAndPositions);

  return mergeConditions;
};

const Period = () => {
  const classes = useStyles();
  return <Typography className={classNames(classes.listSpacer, classes.period)}>.</Typography>;
};

interface MergedGroupStatements {
  mergedIncludeConditions: MergedConditionStatement[];
  mergedExcludeConditions: MergedConditionStatement[];
}

// Used to merge together all include statements xor all exclude statements inside of a group
const getMergedConditionsForConditionGrouping = (_conditions: AdvancedSelectionCondition[]): MergedConditionStatement[] => {
  const conditions = _conditions.filter(condition => !isConditionEmpty(condition));
  const teams = sortByKey(
    conditions.flatMap(condition => (condition.__typename === "Teams" ? condition.teams : [])),
    "name"
  );
  const positions = sortByKey(
    conditions.flatMap(condition => (condition.__typename === "Positions" ? condition.positions : [])),
    "name"
  );
  const tagConditions = conditions.flatMap(condition => (condition.__typename === "Tags" ? condition : []));

  const mergedConditions = mergeConditions(teams, positions, tagConditions);

  const filteredMergedConditions = mergedConditions.filter(
    ({ team, position, tags }) => team !== null || position !== null || tags.length > 0
  );
  return filteredMergedConditions;
};

const getMergedConditionsForGroup = ({ conditions }: AdvancedSelectionGroup): MergedGroupStatements => {
  const includeConditions = conditions.filter(condition => condition.isComplement === false);
  const excludeConditions = conditions.filter(condition => condition.isComplement === true);

  const mergedIncludeConditions = getMergedConditionsForConditionGrouping(includeConditions);
  const mergedExcludeConditions = getMergedConditionsForConditionGrouping(excludeConditions);

  return {
    mergedIncludeConditions,
    mergedExcludeConditions,
  };
};

const conditionToString = ({ team, position, tags }: MergedConditionStatement, isLastInGrouping: boolean): string => {
  const teamString = team ? `${team.name}` : "";
  const positionString = position ? `(${position.name})` : "";
  const comboString = [teamString, positionString, ...tags.map(({ tagName }) => `(${tagName.name})`)].join(" ");
  const endString = isLastInGrouping || comboString === "" ? "" : " and";
  return comboString + endString;
};

const ShareWithInfoGroupStatement = ({ mergedConditions }: ShareWithInfoGroupStatementProps) => {
  const classes = useStyles();

  const mergedChipLists = mergedConditions.map(({ team, position, tags }, index) => {
    return (
      <Fragment key={`${team?.id}:${position?.id}:${tags.map(({ tag }) => tag.id).join(":")}`}>
        {team && <Chip
          size="small"
          className={classes.listSpacer}
          label={team.name} />}
        {position && <Chip
          size="small"
          className={classes.listSpacer}
          label={position.name} />}
        {tags.map(namedTag => (
          <NamedTagChip
            size="small"
            className={classes.listSpacer}
            namedTag={namedTag}
            key={namedTag.tag.id} />
        ))}
        {index !== mergedConditions.length - 1 && <Typography className={classes.listSpacer}>and</Typography>}
      </Fragment>
    );
  });

  return <>{mergedChipLists}</>;
};

const ShareWithInfoGroup = ({
  mergedIncludeConditions,
  mergedExcludeConditions,
  isFullGroup,
  includeTrailingPeriod = true,
}: ShareWithInfoGroupProps) => {
  const classes = useStyles();

  const hasInclude = mergedIncludeConditions.length > 0;
  const hasExclude = mergedExcludeConditions.length > 0;

  const excludeText = mergedIncludeConditions.length > 0 ? "but exclude" : "exclude";
  return (
    <>
      {hasInclude && <ShareWithInfoGroupStatement mergedConditions={mergedIncludeConditions} />}
      {hasExclude && (
        <>
          <Typography className={classes.listSpacer}>{excludeText}</Typography>
          <ShareWithInfoGroupStatement mergedConditions={mergedExcludeConditions} />
        </>
      )}
      {isFullGroup && includeTrailingPeriod && <Period />}
    </>
  );
};

const ShareWithInfoGroups = ({ mergedGroups, includeTrailingPeriod = true }: ShareWithInfoGroupsProps) => {
  const classes = useStyles();
  return (
    <>
      {mergedGroups.map(({ mergedIncludeConditions, mergedExcludeConditions, groupId, isFullGroup }, index) => {
        return (
          <Fragment key={groupId}>
            <ShareWithInfoGroup
              mergedIncludeConditions={mergedIncludeConditions}
              mergedExcludeConditions={mergedExcludeConditions}
              isFullGroup={isFullGroup}
              includeTrailingPeriod={includeTrailingPeriod}
            />
            {index !== mergedGroups.length - 1 && <Typography className={classes.listSpacer}>Also share with</Typography>}
          </Fragment>
        );
      })}
    </>
  );
};

const AthleteChipList = ({ athletes }: AthleteChipListProps) => {
  const classes = useStyles();
  return (
    <ChipListPreview
      items={sortByFn(
        athletes.map(athlete => ({ label: `${athlete.lastName}, ${athlete.firstName}`, id: athlete.id })),
        ({ label }) => label
      )}
      hideItemsAfter={HIDE_ATHLETES_AFTER}
      chipClassName={classes.listSpacer}
      getToolTipText={items => items.map(({ label }) => label).join("; ")}
    />
  );
};

interface TruncateInfoForMaxChipsArgs {
  mergedGroups: Omit<MergedGroup, "isFullGroup">[];
  includeAthletes: readonly AthleteForCustomTagFragment[];
  excludeAthletes: readonly AthleteForCustomTagFragment[];
}

interface TruncateInfoPayload extends Omit<TruncateInfoForMaxChipsArgs, "mergedGroups"> {
  mergedGroups: MergedGroup[];
  leftOverCount: number;
  tooltipString: string;
}

const getChipCountForMergedCondition = (mergedCondition: MergedConditionStatement): number => {
  const positionCount = mergedCondition.position ? 1 : 0;
  const teamCount = mergedCondition.team ? 1 : 0;
  const tagsCount = mergedCondition.tags.length;
  return positionCount + teamCount + tagsCount;
};

const truncateInfoForMaxChips = (
  { mergedGroups, includeAthletes, excludeAthletes }: TruncateInfoForMaxChipsArgs,
  _maxChips: number,
  isForSmallSpace: boolean
): TruncateInfoPayload => {
  const displayGroups: MergedGroup[] = [];
  let usedChips = 0;
  let leftOverCount = 0;
  let containsCombos = false;
  const groupTooltipsStrings: string[] = [];

  const maxChips = isForSmallSpace && mergedGroups.length > 1 ? 2 : _maxChips;

  mergedGroups.forEach(({ mergedIncludeConditions, mergedExcludeConditions, groupId }) => {
    const displayMergedIncludeConditions: MergedConditionStatement[] = [];
    const displayMergedExcludeConditions: MergedConditionStatement[] = [];
    let isFullGroup = true;
    const includeTooltipStrings: string[] = [];
    const excludeTooltipStrings: string[] = [];

    // process groups first
    mergedIncludeConditions.forEach((condition, index) => {
      const chipCount = getChipCountForMergedCondition(condition);
      usedChips += chipCount;

      if (usedChips > maxChips && containsCombos) {
        isFullGroup = false;
        leftOverCount += 1;
        const conditionString = conditionToString(condition, index === mergedIncludeConditions.length - 1);
        includeTooltipStrings.push(conditionString);
      } else {
        containsCombos = true;
        displayMergedIncludeConditions.push(condition);
      }
    });

    mergedExcludeConditions.forEach((condition, index) => {
      const chipCount = getChipCountForMergedCondition(condition);
      usedChips += chipCount;

      if (usedChips > maxChips && containsCombos) {
        isFullGroup = false;
        leftOverCount += 1;
        const conditionString = conditionToString(condition, index === mergedExcludeConditions.length - 1);
        excludeTooltipStrings.push(conditionString);
      } else {
        containsCombos = true;
        displayMergedExcludeConditions.push(condition);
      }
    });
    // Add to display groups if there is info to display
    if (displayMergedIncludeConditions.length > 0 || displayMergedExcludeConditions.length > 0) {
      displayGroups.push({
        isFullGroup,
        groupId,
        mergedExcludeConditions: displayMergedExcludeConditions,
        mergedIncludeConditions: displayMergedIncludeConditions,
      });
    }
    // Add to tool tip if there is info to display
    if (includeTooltipStrings.length > 0 || excludeTooltipStrings.length > 0) {
      const hasChipExclude = displayMergedExcludeConditions.length > 0;
      const hasNoTooltipExclude = excludeTooltipStrings.length === 0;
      const hasAnyInclude = includeTooltipStrings.length + displayMergedIncludeConditions.length > 0;
      const excludeText = hasChipExclude || hasNoTooltipExclude ? [] : hasAnyInclude ? ["but exclude"] : ["exclude"];
      const combinedString = `${[...includeTooltipStrings, ...excludeText, ...excludeTooltipStrings].join(" ")}.`;
      groupTooltipsStrings.push(combinedString);
    }
  });

  const startAlsoShareWith = displayGroups.length > 1 && displayGroups[displayGroups.length - 1].isFullGroup ? "Also share with " : "";
  const groupTooltipSting = `${startAlsoShareWith}${groupTooltipsStrings.join(" Also share with ")}`;

  let displayIncludeAthletes: AthleteForCustomTagFragment[] = [];
  const includeAthleteTooltipStrings: string[] = [];
  if (maxChips - usedChips > HIDE_ATHLETES_AFTER) {
    displayIncludeAthletes = displayIncludeAthletes.concat(includeAthletes);
    const increment = HIDE_ATHLETES_AFTER;
    usedChips += increment;
  } else {
    includeAthletes.forEach(athlete => {
      usedChips += 1;
      if (usedChips > maxChips && containsCombos) {
        leftOverCount += 1;
        includeAthleteTooltipStrings.push(`${athlete.lastName}, ${athlete.firstName}`);
      } else {
        containsCombos = true;
        displayIncludeAthletes.push(athlete);
      }
    });
  }

  let displayExcludeAthletes: AthleteForCustomTagFragment[] = [];
  const excludeAthleteTooltipStrings: string[] = [];
  if (maxChips - usedChips > HIDE_ATHLETES_AFTER) {
    displayExcludeAthletes = displayExcludeAthletes.concat(excludeAthletes);
    const increment = HIDE_ATHLETES_AFTER;
    usedChips += increment;
  } else {
    excludeAthletes.forEach(athlete => {
      usedChips += 1;
      if (usedChips > maxChips && containsCombos) {
        leftOverCount += 1;
        excludeAthleteTooltipStrings.push(`${athlete.lastName}, ${athlete.firstName}`);
      } else {
        containsCombos = true;
        displayExcludeAthletes.push(athlete);
      }
    });
  }

  const startAthleteString =
    displayIncludeAthletes.length === 0 && includeAthleteTooltipStrings.length > 0 ? ["Also include athletes"] : [];

  const hasChipExclude = displayExcludeAthletes.length > 0;
  const hasNoTooltipExclude = excludeAthleteTooltipStrings.length === 0;
  const hasAnyInclude = displayIncludeAthletes.length + includeAthleteTooltipStrings.length > 0;
  const excludeAthleteString = hasChipExclude || hasNoTooltipExclude ? [] : hasAnyInclude ? ["but exclude"] : ["Exclude"];

  const athleteTooltipString = [
    ...startAthleteString,
    ...(includeAthleteTooltipStrings.length > 0 ? [includeAthleteTooltipStrings.join("; ")] : []),
    ...excludeAthleteString,
    ...(excludeAthleteTooltipStrings.length > 0 ? [excludeAthleteTooltipStrings.join("; ")] : []),
  ].join(" ");

  const tooltipString = [groupTooltipSting, athleteTooltipString].filter(s => s !== "").join(" ");

  return {
    mergedGroups: displayGroups,
    includeAthletes: displayIncludeAthletes,
    excludeAthletes: displayExcludeAthletes,
    leftOverCount,
    tooltipString,
  };
};

const ShareWithInfo = ({
  state: { groups, includeAthletes, excludeAthletes },
  maxChips,
  showWrapper,
  containerClassName,
  containerSx,
  isForSmallSpace = false,
  includeTrailingPeriod = true,
  onClear,
  showAdvancedSelectionClear,
}: ShareWithInfoProps) => {
  const classes = useStyles();
  const filteredGroups = groups.filter(group => !isGroupEmpty(group));

  const _mergedGroups = filteredGroups.map(group => ({ ...getMergedConditionsForGroup(group), groupId: group.id }));
  const {
    mergedGroups,
    includeAthletes: processedIncludeAthletes,
    excludeAthletes: processedExcludeAthletes,
    leftOverCount,
    tooltipString,
  } = truncateInfoForMaxChips(
    {
      mergedGroups: _mergedGroups,
      includeAthletes,
      excludeAthletes,
    },
    maxChips,
    isForSmallSpace
  );

  const hasInclude = processedIncludeAthletes.length > 0;
  const hasExclude = processedExcludeAthletes.length > 0;
  const excludeText = hasInclude ? "but exclude" : "Exclude";
  const hasLeftOver = leftOverCount > 0;

  const hasContent = hasInclude || hasExclude || mergedGroups.length > 0;

  const content = (
    <Grid container wrap="nowrap">
      <Grid item>
        <Box className={classNames(classes.container, containerClassName)} sx={containerSx}>
          {mergedGroups.length > 0 && <ShareWithInfoGroups mergedGroups={mergedGroups} includeTrailingPeriod={includeTrailingPeriod} />}
          {hasInclude && (
            <>
              <Typography className={classes.listSpacer}>Also include athletes</Typography>
              <AthleteChipList athletes={processedIncludeAthletes} />
              {!hasExclude && !hasLeftOver && includeTrailingPeriod && <Period />}
            </>
          )}
          {hasExclude && (
            <>
              <Typography className={classes.listSpacer}>{excludeText}</Typography>
              <AthleteChipList athletes={processedExcludeAthletes} />
              {!hasLeftOver && includeTrailingPeriod && <Period />}
            </>
          )}
          {hasLeftOver && (
            <Tooltip title={tooltipString}>
              <Chip
                label={`+${leftOverCount}`}
                size="small"
                className={classes.listSpacer} />
            </Tooltip>
          )}
        </Box>
      </Grid>
      <Grid item alignSelf="center">
        {hasContent && showAdvancedSelectionClear && (
          <IconButton onClick={onClear}>
            <CloseIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );

  return hasContent && showWrapper ? (
    <TextFieldWrapper
      label="Share With Advanced Selection"
      className={containerClassName}
      sx={containerSx}>
      {content}
    </TextFieldWrapper>
  ) : (
    content
  );
};

export default ShareWithInfo;
