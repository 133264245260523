import { Button, DialogActions, DialogContent } from "@mui/material";
import { Loading } from "@notemeal/shared-ui";
import ActionDialog from "apps/web/src/componentLibrary/ActionDialog/Dialog";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import FoodPreferenceMealPlanForm from "apps/web/src/components/FoodPreference/MealPlan/Form";
import { useBrowserBackAndRefreshWarning } from "apps/web/src/hooks/useBrowserBackAndRefreshWarning";
import { getNavOrgAthleteMealPlansMealPlan } from "apps/web/src/pages/Auth/Org/Athlete/AthletePaths";
import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import {
    foodPreferenceReducer,
    initialFoodPreferenceState,
    pluckIdsFromFoodPreferenceState,
} from "../../../../components/FoodPreference/MealPlan/reducer";
import { mealPlanFormReducer, mealPlanFormToSaveTooltips } from "../../../../components/MealPlan/Form/utils";
import { NameAndTypeForm } from "../../../../components/MealPlan/NameAndTypeForm";
import { useRefetchQueriesContext } from "../../../../contexts/RefetchQueries";
import { ExchangeSetFragment, PreviewMealPlanFragment, useEditMealPlanMutation } from "../../../../types";
import { initialEditMealPlanFormState } from "./utils";

interface EditMealPlanDialogProps {
  athleteId: string;
  open: boolean;
  onClose: () => void;
  mealPlan: PreviewMealPlanFragment;
  exchangeSets: readonly ExchangeSetFragment[];
}

const EditMealPlanDialog = ({ athleteId, onClose, open, mealPlan, exchangeSets }: EditMealPlanDialogProps) => {
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(mealPlanFormReducer, initialEditMealPlanFormState(mealPlan, exchangeSets));
  const { setBrowserBackAndRefreshWarningEnabled } = useBrowserBackAndRefreshWarning();

  React.useEffect(() => {
    // could be improved by tracing if form is dirty
    setBrowserBackAndRefreshWarningEnabled(true);
  }, [setBrowserBackAndRefreshWarningEnabled]);

  const onDialogClose = () => {
    const confirmed = window.confirm("Discard Unsaved Changes? All unsaved changes will be lost.");

    if (confirmed) {
      onClose();
    }
  };

  const { avoidedFoodGroups, promotedFoodGroups, avoidedFoods, promotedFoods } = mealPlan;
  const [foodPreferenceFormState, foodPreferenceFormDispatch] = useReducer(
    foodPreferenceReducer,
    { avoidedFoodGroups, promotedFoodGroups, avoidedFoods, promotedFoods },
    initialFoodPreferenceState
  );

  const [saving, setSaving] = useState(false);

  const NAME_AND_TYPE = "Name and Type";
  const FOOD_PREFERENCES = "Food Preferences";
  const [selected, setSelected] = useState(NAME_AND_TYPE);
  const tabs = [NAME_AND_TYPE, FOOD_PREFERENCES];

  const canSaveTooltips = mealPlanFormToSaveTooltips(state);
  const refetchQueries = useRefetchQueriesContext();

  const [editMealPlan] = useEditMealPlanMutation({
    update: (_, result) => {
      const data = result.data;
      if (data) {
        const mealPlanId = data.editMealPlanNew.mealPlan.id;
        onClose();
        navigate(getNavOrgAthleteMealPlansMealPlan(athleteId, mealPlanId));
      }
    },
    onError: () => {
      setBrowserBackAndRefreshWarningEnabled(true);
    },
    refetchQueries,
  });

  const handleSave = () => {
    const exchangeSetId = state.exchangeSet ? state.exchangeSet.id : null;
    if (canSaveTooltips.length) {
      return;
    }
    setSaving(true);
    setBrowserBackAndRefreshWarningEnabled(false);
    editMealPlan({
      variables: {
        input: {
          foodPreferences: pluckIdsFromFoodPreferenceState(foodPreferenceFormState),
          mealPlanId: mealPlan.id,
          exchangeSetId,
          name: state.name,
          description: state.description,
          isShared: state.isShared,
        },
      },
    });
  };

  return (
    <ActionDialog open={open}>
      <DialogTitle title="Edit Meal Plan" onClose={onDialogClose} />
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <TWTabGroup tabs={tabs} onSelected={setSelected} />
        {saving ? (
          <Loading progressSize="md" />
        ) : selected === NAME_AND_TYPE ? (
          <NameAndTypeForm
            state={state}
            dispatch={dispatch}
            editMode />
        ) : (
          <FoodPreferenceMealPlanForm state={foodPreferenceFormState} dispatch={foodPreferenceFormDispatch} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onDialogClose}
          disabled={saving}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
          <Button onClick={handleSave} disabled={saving || canSaveTooltips.length > 0}>
            Save
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
    </ActionDialog>
  );
};

export default EditMealPlanDialog;
