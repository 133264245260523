import { Box, Button, FormControlLabel, Radio, Typography, useTheme } from "@mui/material";
import { capitalize, useClientTimezone } from "@notemeal/shared-ui";
import { formatTimeRangeInTimezone, jsDateToDateAndTimeInTz } from "@notemeal/utils-date-time";
import { addMinutes } from "date-fns";
import { useState } from "react";
import { EditEventTime, EventModificationScope } from "../../Form/reducer";
import { MealPlanDateAssignmentMode } from "../../Form/types";
import { MealPlanCalendarEvent } from "../../types";
import { getDayOfWeek, getNameForMealPlanCalendarEvent } from "../../utils";
import ActionPopoverBase from "./ActionBase";

interface MealPlanCalendarEventNewTimePopoverProps {
  event: MealPlanCalendarEvent;
  newStart: Date;
  anchorEl: HTMLElement;
  assignmentMode: MealPlanDateAssignmentMode;
  onDone: (payload: EditEventTime["payload"]) => void;
  onClose: () => void;
  isAutoSuggestionsEnabled: boolean;
}

const MealPlanCalendarEventNewTimePopoverPreview = ({
  event,
  newStart,
  anchorEl,
  onClose,
  assignmentMode,
  onDone,
  isAutoSuggestionsEnabled,
}: MealPlanCalendarEventNewTimePopoverProps) => {
  const {
    palette: { grey },
  } = useTheme();
  const clientTimezone = useClientTimezone();
  const [scope, setScope] = useState<EventModificationScope | null>(null);

  const name = getNameForMealPlanCalendarEvent(event);
  const { date, time: newStartTime } = jsDateToDateAndTimeInTz(newStart, clientTimezone);
  const { time: newEndTime } = jsDateToDateAndTimeInTz(addMinutes(newStart, event.durationInMinutes), clientTimezone);
  const dayOfWeekText = capitalize(getDayOfWeek(date));

  const displayTime = formatTimeRangeInTimezone(newStart, event.durationInMinutes, clientTimezone, {
    excludeTimezoneSuffix: true,
  });

  const handleDone = () => {
    if (!scope) {
      return;
    }
    onDone({
      eventId: event.id,
      date,
      start: newStartTime,
      end: newEndTime,
      scope,
    });
    onClose();
  };

  return (
    <ActionPopoverBase
      anchorEl={anchorEl}
      onClose={onClose}
      title={name}
      variant="lg"
      buttons={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleDone}>Save</Button>
        </>
      }
    >
      {isAutoSuggestionsEnabled && (
        <Box sx={{ backgroundColor: grey[100], p: 1, pb: 1, mb: 2 }}>
          <Typography variant="subtitle1" color="textSecondary">
            * Any modifications will override your auto-suggestions for this meal/snack
          </Typography>
        </Box>
      )}
      <Typography variant="body2">
        Move {name} to {displayTime} for
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", pt: 1, pb: 3, whiteSpace: "nowrap" }}>
        <FormControlLabel
          onChange={e => setScope("one")}
          control={<Radio checked={scope === "one"} size="small" />}
          label={<Typography variant="body2">This {dayOfWeekText}</Typography>}
        />
        {assignmentMode === "weekly" && (
          <FormControlLabel
            onChange={e => setScope("dayOfWeek")}
            control={<Radio checked={scope === "dayOfWeek"} size="small" />}
            label={<Typography variant="body2">This and all {dayOfWeekText}s</Typography>}
          />
        )}
        <FormControlLabel
          onChange={e => setScope("every")}
          control={<Radio checked={scope === "every"} size="small" />}
          label={<Typography variant="body2">This and all selected days{assignmentMode === "weekly" && " of the week"}</Typography>}
        />
      </Box>
    </ActionPopoverBase>
  );
};

export default MealPlanCalendarEventNewTimePopoverPreview;
