import { Button } from "@mui/material";
import { useState } from "react";

import DisplaySettingsPreview from "../../../../components/DisplaySettings/Preview";
import DashboardCard from "../Card";
import EditModal from "./EditModal";

import { useHasFeature } from "@notemeal/shared-ui";
import { getOrgUserDetails } from "apps/web/src/pages/Auth/OrgUtils";
import { useUser } from "apps/web/src/utils/tokens";
import {
    ExchangeMealPlanDisplaySettingsFragment,
    MacroMealPlanDisplaySettingsFragment,
    NoTargetsDisplaySettingsFragment,
} from "../../../../types";

interface TeamDashboardDisplaySettingsCardProps {
  teamId: string;
  defaultMacroDisplaySettings: MacroMealPlanDisplaySettingsFragment | null;
  defaultExchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment | null;
  defaultNoTargetsDisplaySettings: NoTargetsDisplaySettingsFragment | null;
  defaultHideAnthropometry: boolean | null;
  requestNewEdsaSurvey: string | null;
}

const TeamDashboardDisplaySettingsCard = ({
  defaultMacroDisplaySettings,
  defaultExchangeDisplaySettings,
  defaultNoTargetsDisplaySettings,
  defaultHideAnthropometry,
  requestNewEdsaSurvey,
  teamId,
}: TeamDashboardDisplaySettingsCardProps) => {
  const [editOpen, setEditOpen] = useState(false);
  const editButton = (
    <Button
      size="small"
      variant="text"
      onClick={() => setEditOpen(true)}
      aria-label="Edit Display Settings">
      Edit
    </Button>
  );
  const hasRDRole = getOrgUserDetails(useUser()).hasRoleDietitian;
  const showEDSA = useHasFeature("eatingDisorderScreens") && hasRDRole;
  const subtitle = showEDSA ? `(EDSA ${requestNewEdsaSurvey ? "enabled" : "disabled"})` : undefined;
  return (
    <DashboardCard
      title="Display Settings"
      options={editButton}
      subtitle={subtitle}>
      <DisplaySettingsPreview
        macroDisplaySettings={defaultMacroDisplaySettings}
        exchangeDisplaySettings={defaultExchangeDisplaySettings}
        noTargetsDisplaySettings={defaultNoTargetsDisplaySettings}
      />
      {editOpen && (
        <EditModal
          teamId={teamId}
          defaultExchangeDisplaySettings={defaultExchangeDisplaySettings}
          defaultMacroDisplaySettings={defaultMacroDisplaySettings}
          noTargetsDisplaySettings={defaultNoTargetsDisplaySettings}
          hideAnthropometry={defaultHideAnthropometry ?? false}
          showEDSA={showEDSA}
          isEDSAEnabled={requestNewEdsaSurvey ? true : false}
          open={editOpen}
          onClose={() => setEditOpen(false)}
        />
      )}
    </DashboardCard>
  );
};

export default TeamDashboardDisplaySettingsCard;
