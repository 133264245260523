import { AssignmentRounded } from "@mui/icons-material";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import { TWChip, TWChipStyles } from "apps/web/src/componentLibrary/TWChip/TWChip";
import { AssignMealPlanTemplateJobOffsetConnectionQuery, useAssignMealPlanTemplateJobOffsetConnectionQuery } from "apps/web/src/types";
import { getMuiTablePaginationProps, useOffsetPagination } from "apps/web/src/utils/pagination";
import { format } from "date-fns";

const ROWS_PER_PAGE = 10;

const getJobStatus = (job: AssignMealPlanTemplateJobOffsetConnectionQuery["bulkAssignMealPlanTemplateJobOffsetConnection"]["edges"][0]) => {
  if (job.finishedAt) {
    if (job.athleteCount === job.athleteSucceededCount) {
      return <TWChip label="Success" chipStyle={TWChipStyles.green} />;
    }
    if (job.athleteSucceededCount > 0) {
      return <TWChip label="Partial Success" chipStyle={TWChipStyles.orange} />;
    }
    return <TWChip label="Failed" chipStyle={TWChipStyles.red} />;
  } else if (job.processingAt) {
    return <TWChip label="In Progress" chipStyle={TWChipStyles.blue} />;
  }
  return <TWChip label="Waiting" chipStyle={TWChipStyles.gray} />;
};

export const BulkAssignMealPlanTemplateStatus = () => {
  const pagination = useOffsetPagination(ROWS_PER_PAGE);
  const { dateFnsLocale } = useDateFormatting();

  const { loading, data } = useAssignMealPlanTemplateJobOffsetConnectionQuery({
    variables: { input: { limit: pagination.limit, offset: pagination.offset } },
  });

  if (loading || !data) {
    return <Typography>Loading...</Typography>;
  }

  if (data.bulkAssignMealPlanTemplateJobOffsetConnection.edges.length === 0) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", flex: 1 }}>
        <AssignmentRounded fontSize="extraLarge" />
        <Typography variant="h4" sx={{ mt: 1, textAlign: "center" }}>
          No assignments
        </Typography>
        <Typography variant="body2" sx={{ mt: 1, textAlign: "center", pb: 8 /* spacing for visual centering */ }}>
          No meal plan templates have been assigned to athletes yet.
          <br />
          Get started by creating and assigning one today.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <TableContainer sx={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Assigned By</TableCell>
              <TableCell>Date Assigned</TableCell>
              <TableCell>Assignment</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.bulkAssignMealPlanTemplateJobOffsetConnection.edges.map(job => (
              <TableRow key={job.id}>
                <TableCell>{job.name}</TableCell>
                <TableCell>
                  {job.createdBy?.firstName} {job.createdBy?.lastName}
                </TableCell>
                <TableCell>{format(new Date(job.createdAt), "MM/dd/yyyy", { locale: dateFnsLocale })}</TableCell>
                <TableCell>
                  {job.athleteSucceededCount + job.athleteFailedCount}/{job.athleteCount}
                </TableCell>
                <TableCell>{getJobStatus(job)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        sx={{ ".MuiTablePagination-actions": { mr: 8 } }}
        {...getMuiTablePaginationProps(pagination, data.bulkAssignMealPlanTemplateJobOffsetConnection.pageInfo.total)}
      />
    </Box>
  );
};
