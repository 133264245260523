import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useReducer } from "react";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { useRefetchQueriesContext } from "../../../contexts/RefetchQueries";
import Form from "../Form";
import {
  NoteFormState,
  noteFormReducer,
  noteFormStateToCreateInput,
  noteFormStateToEditInput,
  noteFormToSaveTooltips,
} from "../Form/utils";

import { Loading } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useCreateNoteMutation, useEditNoteMutation, useNoteTypesQuery } from "../../../types";

interface NoteModalProps {
  athleteId: string;
  open: boolean;
  onClose: () => void;
  initialFormState: NoteFormState;
}

const NoteModal = ({ athleteId, open, onClose, initialFormState }: NoteModalProps) => {
  const [state, dispatch] = useReducer(noteFormReducer, initialFormState);
  const { data, loading } = useNoteTypesQuery();
  const canSaveTooltips = noteFormToSaveTooltips(state);
  const refetchQueries = useRefetchQueriesContext();

  const [createNote] = useCreateNoteMutation({
    refetchQueries,
  });
  const [editNote] = useEditNoteMutation({
    refetchQueries,
  });

  const handleSave = () => {
    const { id, datetime } = state;
    if (canSaveTooltips.length || !datetime) {
      return;
    }
    if (!id) {
      // Create
      createNote({
        variables: {
          input: noteFormStateToCreateInput({ ...state, datetime }, athleteId),
        },
      });
    } else {
      // Edit
      editNote({
        variables: {
          input: noteFormStateToEditInput({ ...state, datetime, id }),
        },
      });
    }
    onClose();
  };

  if (!data || loading) {
    return <Loading />;
  }
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title={`${!state.id ? "Add" : "Edit"} Note`} onClose={onClose} />
      <DialogContent>
        <Form
          state={state}
          dispatch={dispatch}
          noteTypes={data.noteTypes} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={canSaveTooltips}>
          <Button onClick={handleSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default NoteModal;
