import { getMenuItemMaxAmount, getMenuSectionAmountsForMenuItem, MenuSection, MenuSelectionItemWithAppearance } from "@notemeal/shared-ui";
import EditForm from "./EditForm";

interface MenuSelectionItemEditProps {
  menuSelectionItem: MenuSelectionItemWithAppearance;
  menuSections: readonly MenuSection[];
  currentAmountForMenuItem: number;
  menuSectionAmountsForSelection: Record<string, number | undefined>;
  onEdit: (menuSelectionItem: MenuSelectionItemWithAppearance) => void;
  onDone: () => void;
}

export const KioskMenuSelectionItemEdit = ({
  menuSelectionItem,
  currentAmountForMenuItem,
  menuSectionAmountsForSelection,
  menuSections,
  onEdit,
  onDone,
}: MenuSelectionItemEditProps) => {
  const { currentAmountForMenuSection, maxAmountForMenuSection } = getMenuSectionAmountsForMenuItem(
    menuSelectionItem.menuItem.id,
    menuSections,
    menuSectionAmountsForSelection
  );

  const maxAmount = getMenuItemMaxAmount({
    currentAmountForMenuItem,
    maxAmountForMenuItem: menuSelectionItem.menuItemAppearance.maxAmount,
    currentAmountForMenuSection,
    maxAmountForMenuSection,
    menuSelectionItemAmount: menuSelectionItem.amount,
  });

  return (
    <EditForm
      availableForOrder={menuSelectionItem.menuItemAppearance.availableForOrder}
      allowSpecialRequests={menuSelectionItem.menuItemAppearance.allowSpecialRequests}
      maxAmount={maxAmount}
      onEdit={onEdit}
      menuSelectionItem={menuSelectionItem}
      onDone={onDone}
    />
  );
};
