import { getExchangeAmountsMacros, useProgressViews } from "@notemeal/shared-ui";
import { memo } from "react";
import {
    EditFullMealOptionFragment,
    ExchangeAmountFragment,
    FullExchangeSetFragment,
    FullServingAmountFragment,
    MealType,
} from "../../../types";
import { EditMealOption } from "./EditMealOption";
import { MealOptionExchangeMacrosProgress } from "./MealOptionExchangeMacrosProgress";
import { PickListMode } from "./PickListMode";

interface EditExchangeMealOptionProps {
  mealTemplateId: string;
  mealOption: EditFullMealOptionFragment;
  exchangeSet: FullExchangeSetFragment;
  targetExchangeAmounts: readonly ExchangeAmountFragment[];
  selected: boolean;
  isNew: boolean;
  mealType: MealType;
}

export const EditExchangeMealOption = memo(
  ({ mealTemplateId, mealOption, selected, isNew, exchangeSet, targetExchangeAmounts, mealType }: EditExchangeMealOptionProps) => {
    const targetMacros = getExchangeAmountsMacros(targetExchangeAmounts);

    const { mode, onChangeMode } = useProgressViews({
      macroProgressViews: "all",
      hasTargets: true,
      additionalModesFirst: true,
      additionalModes: ["ExchangeTargets"],
    });

    const renderTotals = (servingAmounts: readonly FullServingAmountFragment[]) => {
      return (
        <MealOptionExchangeMacrosProgress
          mode={mode}
          onChangeMode={onChangeMode}
          servingAmounts={servingAmounts}
          exchangeSet={exchangeSet}
          targetExchangeAmounts={targetExchangeAmounts}
        />
      );
    };

    return (
      <>
        <EditMealOption
          renderTotals={renderTotals}
          targetMacros={targetMacros}
          mealTemplateId={mealTemplateId}
          mealTemplateTypename="ExchangeMealTemplate"
          mealOption={mealOption}
          selected={selected}
          isNew={isNew}
          mealType={mealType}
          additionalModes={[PickListMode]}
          targetExchangeAmounts={targetExchangeAmounts}
          exchangeSet={exchangeSet}
        />
      </>
    );
  }
);
