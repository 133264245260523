import { EditMacroState } from "@notemeal/shared-ui";
import { AnthropometrySnapshot, hasRmrErrors, metricGPerKgToPercent, percentToMetricGPerKg } from "@notemeal/shared-utils-macro-protocol";
import { CalorieBudgetState, getCalorieBudgetFromState } from "./calorieBudgetReducer";
import { MacroProtocolState } from "./macroProtocolReducer";

export type MacroType = "cho" | "pro" | "fat";

export interface MacroPayload {
  macro: MacroType;
  value: string;
}
interface ChangeMacroPercentAction {
  type: "CHANGE_MACRO_PERCENT";
  payload: MacroPayload;
}

export interface ChangeMacroGPerKGAction {
  type: "CHANGE_MACRO_G_PER_KG";
  payload: MacroPayload;
}

interface ChangeMacroWithCalorieBudgetAction {
  type: "CHANGE_MACRO_W_CALORIE_BUDGET";
  payload: {
    macro: MacroType;
    value: CalorieBudgetState;
  };
}

export type EditMacrosAction = ChangeMacroPercentAction | ChangeMacroGPerKGAction | ChangeMacroWithCalorieBudgetAction;

export const createMacroCalorieAction = (macro: MacroType, calorieBudget: CalorieBudgetState): ChangeMacroWithCalorieBudgetAction => ({
  type: "CHANGE_MACRO_W_CALORIE_BUDGET",
  payload: { macro: macro, value: calorieBudget },
});

export const macroReducer = (
  state: MacroProtocolState,
  action: EditMacrosAction,
  targetAnthroSnapshot: AnthropometrySnapshot
): EditMacroState => {
  const macro = action.payload.macro;
  const macroState = state[macro];
  const calorieBudget = getCalorieBudgetFromState(state.calorieBudget);

  let percent: number = macroState.percent;
  let percentInput: string = macroState.percentInput;
  let gPerKg: number = macroState.gPerKg;
  let gPerKGInput: string = macroState.gPerKGInput;
  let usesPercent: boolean = macroState.usesPercent;

  if (hasRmrErrors({ rmrMethod: calorieBudget.rmrMethod, ...targetAnthroSnapshot })) {
    return macroState;
  }

  switch (action.type) {
    case "CHANGE_MACRO_PERCENT":
      percentInput = action.payload.value;
      if (!isNaN(Number(percentInput)) && percentInput !== "") {
        percent = Number(percentInput);
        gPerKg = percentToMetricGPerKg(percent, macro, targetAnthroSnapshot, calorieBudget);
        gPerKGInput = gPerKg.toString();
        usesPercent = true;
      }
      break;
    case "CHANGE_MACRO_G_PER_KG":
      gPerKGInput = action.payload.value;
      if (!isNaN(Number(gPerKGInput)) && gPerKGInput !== "") {
        gPerKg = Number(gPerKGInput);
        percent = metricGPerKgToPercent(gPerKg, macro, targetAnthroSnapshot, calorieBudget);
        percentInput = percent.toString();
        usesPercent = false;
      }
      break;
    case "CHANGE_MACRO_W_CALORIE_BUDGET":
      const newCalorieBudget = getCalorieBudgetFromState(action.payload.value);
      if (hasRmrErrors({ rmrMethod: newCalorieBudget.rmrMethod, ...targetAnthroSnapshot })) {
        return macroState;
      }

      if (usesPercent) {
        gPerKg = percentToMetricGPerKg(percent, macro, targetAnthroSnapshot, newCalorieBudget);
        gPerKGInput = gPerKg.toString();
      } else {
        percent = metricGPerKgToPercent(gPerKg, macro, targetAnthroSnapshot, newCalorieBudget);
        percentInput = percent.toString();
      }
  }
  return {
    percent,
    percentInput,
    gPerKg,
    gPerKGInput,
    usesPercent,
  };
};
