import { Box, Stack, Typography, styled } from "@mui/material";
import { DigitalDisplaysColorIcon, NutritionColorIcon } from "@notemeal/shared-ui";

const PoweredByBanner = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 2,
  margin: spacing(0),
  padding: spacing(2),
  backgroundColor: "black",
  justifyContent: "center",
  alignItems: "center",
}));

const DetailsBox = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  margin: spacing(0),
  backgroundColor: "black",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: spacing(1.5),
}));

const BrandStack = styled(Stack)(() => ({
  alignItems: "center",
}));

const SubText = styled(Typography)(() => ({
  color: "white",
  fontWeight: 600,
  fontSize: "14px",
}));

const MenuTitle = styled(Typography)(() => ({
  color: "white",
  fontWeight: 600,
  fontSize: "20px",
  justifyContent: "center",
}));

interface BannerProps {
  menuName: string;
  date: string;
  duration: string;
  includeDetails: boolean;
}
export const Banner = ({ menuName, date, duration, includeDetails }: BannerProps) => {
  return (
    <PoweredByBanner>
      <BrandStack direction="row" gap={0.5}>
        <SubText>Powered by</SubText>
        <NutritionColorIcon sx={{ alignItems: "center", width: 24, height: 24 }} viewBox="0 0 20 20" />
        <SubText>x</SubText>
        <DigitalDisplaysColorIcon sx={{ alignItems: "center", width: 24, height: 24 }} viewBox="0 0 20 20" />
      </BrandStack>
      {includeDetails && (
        <DetailsBox>
          <Box>
            <MenuTitle>{menuName}</MenuTitle>
          </Box>
          <Box paddingTop={0.5}>
            <SubText>
              {date} | {duration}
            </SubText>
          </Box>
        </DetailsBox>
      )}
    </PoweredByBanner>
  );
};
