import { EditImperialMacroProtocolState, EditMetricMacroProtocolState } from "@notemeal/shared-ui";
import { FoodPreferenceState, pluckIdsFromFoodPreferenceState } from "apps/web/src/components/FoodPreference/MealPlan/reducer";
import { MealPlanFormState } from "apps/web/src/components/MealPlan/Form/utils";
import { CreateMealPlanNewInput, FullGoalFragment, CreateMetricMealPlanInput } from "apps/web/src/types";
import {
  editImperialMacroProtocolStateInMealPlanToMetricMacroProtocolInput,
  editMetricMacroProtocolStateInMealPlanToMetricMacroProtocolInput,
  editStateToMacroProtocolNewInput,
} from "apps/web/src/utils/macroProtocol";
import { CreateMealPlanCalendarState } from "../Content/Calendar/Form/types";
import { getCreateMealPlanCalendarInput, MealPlanDateAssignmentConflictResolution } from "../Content/Calendar/Form/utils";

export const activeModal = ["Calendar", "Macro Protocol", "Name and Type", "Food Preferences"];

export const getCreateMealPlanInput = (
  athleteId: string,
  currentGoal: FullGoalFragment | null,
  calendarState: CreateMealPlanCalendarState,
  conflictResolutions: readonly MealPlanDateAssignmentConflictResolution[],
  macroProtocolState: EditImperialMacroProtocolState,
  // TODO: Replace this with simplified form's type
  { type, name, description, exchangeSet, isShared }: MealPlanFormState,
  foodPreferenceState: FoodPreferenceState
): CreateMealPlanNewInput | null => {
  const calendar = getCreateMealPlanCalendarInput(calendarState, conflictResolutions);
  const macroProtocol = editStateToMacroProtocolNewInput(macroProtocolState, currentGoal);
  if (!calendar || !macroProtocol) {
    return null;
  }

  return {
    athleteId,
    type,
    name,
    description,
    isShared,
    isAutoSuggestionsEnabled: calendarState.suggestionsState.isAutoSuggestionsEnabled,
    exchangeSetId: exchangeSet?.id ?? null,
    calendar,
    macroProtocol,
    foodPreferences: pluckIdsFromFoodPreferenceState(foodPreferenceState),
  };
};

export const getCreateMetricMealPlanInputFromImperialMacroProtocol = (
  athleteId: string,
  currentGoal: FullGoalFragment | null,
  calendarState: CreateMealPlanCalendarState,
  conflictResolutions: readonly MealPlanDateAssignmentConflictResolution[],
  macroProtocolState: EditImperialMacroProtocolState,
  // TODO: Replace this with simplified form's type
  { type, name, description, exchangeSet, isShared }: MealPlanFormState,
  foodPreferenceState: FoodPreferenceState
): CreateMetricMealPlanInput | null => {
  const calendar = getCreateMealPlanCalendarInput(calendarState, conflictResolutions);
  const macroProtocol = editImperialMacroProtocolStateInMealPlanToMetricMacroProtocolInput(macroProtocolState, currentGoal);
  if (!calendar || !macroProtocol) {
    return null;
  }

  return {
    athleteId,
    type,
    name,
    description,
    isShared,
    isAutoSuggestionsEnabled: calendarState.suggestionsState.isAutoSuggestionsEnabled,
    exchangeSetId: exchangeSet?.id ?? null,
    calendar,
    macroProtocol,
    foodPreferences: pluckIdsFromFoodPreferenceState(foodPreferenceState),
  };
};

export const getCreateMetricMealPlanInputFromMetricMacroProtocol = (
  athleteId: string,
  currentGoal: FullGoalFragment | null,
  calendarState: CreateMealPlanCalendarState,
  conflictResolutions: readonly MealPlanDateAssignmentConflictResolution[],
  macroProtocolState: EditMetricMacroProtocolState,
  // TODO: Replace this with simplified form's type
  { type, name, description, exchangeSet, isShared }: MealPlanFormState,
  foodPreferenceState: FoodPreferenceState
): CreateMetricMealPlanInput | null => {
  const calendar = getCreateMealPlanCalendarInput(calendarState, conflictResolutions);
  const macroProtocol = editMetricMacroProtocolStateInMealPlanToMetricMacroProtocolInput(macroProtocolState, currentGoal);
  if (!calendar || !macroProtocol) {
    return null;
  }

  return {
    athleteId,
    type,
    name,
    description,
    isShared,
    isAutoSuggestionsEnabled: calendarState.suggestionsState.isAutoSuggestionsEnabled,
    exchangeSetId: exchangeSet?.id ?? null,
    calendar,
    macroProtocol,
    foodPreferences: pluckIdsFromFoodPreferenceState(foodPreferenceState),
  };
};
