import { TableCell, TableRow, Typography } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import { EducationResourceEventTableItemFragment } from "apps/web/src/types";

interface EductationResourceViewHistoryTableRowsProps {
  rows: readonly EducationResourceEventTableItemFragment[];
  timezone: string;
}

export const EducationResourceViewHistoryTableRows = ({ rows, timezone }: EductationResourceViewHistoryTableRowsProps) => {
  const { formatDateInTimezoneWithLocale, formatTimeInTimezoneWithLocale } = useDateFormatting();

  return (
    <>
      {rows.map(row => {
        const { createdBy, createdAt } = row;
        return (
          <TableRow hover sx={{ cursor: "pointer" }}>
            <TableCell>
              <Typography variant="body2">{formatDateInTimezoneWithLocale(createdAt, timezone)}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{formatTimeInTimezoneWithLocale(createdAt, timezone)}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{`${createdBy.lastName}, ${createdBy.firstName}`}</Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
