import { IFileImport } from "../../../universal/Import/Button";
import { loadCsv, loadWorkbook } from "../../../../utils/import/load";
import { ImportedField, parseDelimitedFile, parseWorksheet } from "../../../../utils/import/parse";
import { ImportableFoodEntry } from "./types";
import { sortByKey } from "@notemeal/utils-sort";

interface GeneralFileImportFields {
  "Food Name": string;
  "Weight, g": number;
  "Carbohydrate, g": number;
  "Protein, g": number;
  "Fat, g": number;

  "Serving Name": string | null;
  "Default Amount": number | null;

  "Saturated Fat, g": number | null;
  "Trans Fat, g": number | null;
  "Cholesterol, mg": number | null;
  "Sodium, mg": number | null;
  "Fiber, g": number | null;
  "Total Sugars, g": number | null;
  "Added Sugars, g": number | null;
  "Vitamin D, mcg": number | null;
  "Calcium, mg": number | null;
  "Iron, mg": number | null;
  "Potassium, mg": number | null;
}

type GeneralFileImportFieldsRaw = {
  [key in keyof GeneralFileImportFields]: string;
};

export const MatchFields: (keyof ImportableFoodEntry)[] = ["name" as const];

type I = ImportableFoodEntry;

const numberFields: ImportedField<GeneralFileImportFieldsRaw, I>[] = [
  {
    fields: ["Default Amount" as const],
    required: false,
    importableField: "defaultAmount" as const,
  },
  {
    fields: ["Weight, g" as const],
    required: true,
    importableField: "weight" as const,
  },
  {
    fields: ["Carbohydrate, g" as const],
    required: true,
    importableField: "carbohydrate" as const,
  },
  {
    fields: ["Protein, g" as const],
    required: true,
    importableField: "protein" as const,
  },
  {
    fields: ["Fat, g" as const],
    required: true,
    importableField: "fat" as const,
  },
  {
    fields: ["Saturated Fat, g" as const],
    required: false,
    importableField: "saturatedFat" as const,
  },
  {
    fields: ["Trans Fat, g" as const],
    required: false,
    importableField: "transFat" as const,
  },
  {
    fields: ["Cholesterol, mg" as const],
    required: false,
    importableField: "cholesterol" as const,
  },
  {
    fields: ["Sodium, mg" as const],
    required: false,
    importableField: "sodium" as const,
  },
  {
    fields: ["Fiber, g" as const],
    required: false,
    importableField: "fiber" as const,
  },
  {
    fields: ["Total Sugars, g" as const],
    required: false,
    importableField: "totalSugars" as const,
  },
  {
    fields: ["Added Sugars, g" as const],
    required: false,
    importableField: "addedSugars" as const,
  },
  {
    fields: ["Vitamin D, mcg" as const],
    required: false,
    importableField: "vitaminDmcg" as const,
  },
  {
    fields: ["Calcium, mg" as const],
    required: false,
    importableField: "calcium" as const,
  },
  {
    fields: ["Iron, mg" as const],
    required: false,
    importableField: "iron" as const,
  },
  {
    fields: ["Potassium, mg" as const],
    required: false,
    importableField: "potassium" as const,
  },
];
const stringFields: ImportedField<GeneralFileImportFieldsRaw, I>[] = [
  {
    fields: ["Food Name" as const],
    required: true,
    importableField: "name" as const,
  },
  {
    fields: ["Serving Name" as const],
    required: false,
    importableField: "servingName" as const,
  },
];

const dateFields: ImportedField<GeneralFileImportFieldsRaw, I>[] = [];

export const fields = sortByKey([...numberFields, ...stringFields], "required", { reverse: true }).map(x => x);

export const loadFoodEntriesFromCSV = async ({ file, onError, locale }: IFileImport): Promise<I[] | null> => {
  type F = GeneralFileImportFieldsRaw;
  const rows = await loadCsv<F>({ file, onError });
  if (rows === null) {
    return null;
  }
  const parsedRows = parseDelimitedFile<F, I>({
    rows,
    numberFields,
    stringFields,
    dateFields,
    onError,
    locale,
  });
  return parsedRows;
};

export const loadFoodEntriesFromExcel = async ({ file, onError, locale }: IFileImport): Promise<ImportableFoodEntry[] | null> => {
  const worksheet = await loadWorkbook({ file, onError });
  if (worksheet === null) {
    return null;
  }
  const rows = parseWorksheet<GeneralFileImportFieldsRaw, I>({
    worksheet,
    numberFields,
    dateFields,
    stringFields,
    onError,
    locale,
  });
  return rows;
};
