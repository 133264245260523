import CommentIcon from "@mui/icons-material/Comment";
import { Theme } from "@mui/material";
import Badge from "@mui/material/Badge";
import { createStyles, makeStyles } from "@mui/styles";
import { FoodLogIcon } from "@notemeal/shared-ui";
import { NotificationStubFragment } from "../../types";
import { isCommentNotification, isFoodLogNotification } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badgeRoot: {
      paddingLeft: theme.spacing(3),
    },
    onHover: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

interface NotificationCountLabelProps {
  notifications: readonly NotificationStubFragment[];
  max: number;
  onClickCommentNotification?: () => void;
}

const NotificationCountLabel = ({ max, notifications, onClickCommentNotification }: NotificationCountLabelProps) => {
  const classes = useStyles();

  const commentNotifications = notifications.filter(n => isCommentNotification(n) && n.viewedAt === null);
  const timelineMealNotifications = notifications.filter(n => isFoodLogNotification(n) && n.viewedAt === null);

  return (
    <>
      {commentNotifications.length !== 0 && (
        <div className={onClickCommentNotification ? classes.onHover : undefined} onClick={onClickCommentNotification}>
          <Badge
            max={max}
            badgeContent={commentNotifications.length}
            color="error"
            classes={{
              root: classes.badgeRoot,
            }}
          >
            <CommentIcon fontSize="small" />
          </Badge>
        </div>
      )}
      {timelineMealNotifications.length !== 0 && (
        <Badge
          max={max}
          badgeContent={timelineMealNotifications.length}
          color="info"
          classes={{
            root: classes.badgeRoot,
          }}
        >
          <FoodLogIcon fontSize="small" />
        </Badge>
      )}
    </>
  );
};

export default NotificationCountLabel;
