import AddIcon from "@mui/icons-material/Add";
import { Box, SxProps, Typography } from "@mui/material";
import { getNutritionixBrandedRegionForLocale } from "@notemeal/locale-utils";
import { getNextPosition, newServingAmount, useAthleteFoodPreferenceContext, useLocaleContext, useMealPlanFoodPreferenceContext } from "@notemeal/shared-ui";
import React, { useCallback, useRef, useState } from "react";
import { TWButtonGroup } from "../../componentLibrary/TWButtonGroup/TWButtonGroup";
import {
    FoodWithDefaultServingFragment,
    FoodsWithDefaultServingOnlyDocument,
    FoodsWithDefaultServingOnlyQuery,
    FoodsWithDefaultServingOnlyQueryVariables,
    FullServingAmountFragment,
    NutritionixBrandedFoodFragment,
    NutritionixBrandedInstantSearchDocument,
    NutritionixBrandedInstantSearchQuery,
    NutritionixBrandedInstantSearchQueryVariables,
    RecipeWithServingsFragment,
    RecipesDocument,
    RecipesQuery,
    RecipesQueryVariables,
    RestaurantFoodsWithDefaultServingOnlyDocument,
    RestaurantFoodsWithDefaultServingOnlyQuery,
    RestaurantFoodsWithDefaultServingOnlyQueryVariables,
    useAddNixBrandedFoodMutation,
} from "../../types";
import { useFoodSearchFiltersForUser } from "../../utils/foodSearchFilters/foodSearchFiltersForUser";
import { useSnackbar } from "../Snackbar/SnackbarContext";
import { AutocompleteQuerySearchBar } from "../universal/AutocompleteQuerySearchBar/AutocompleteQuerySearchBar";
import LoadingChip from "./Edit/LoadingChip";
import { useServingAmountModeContext } from "./contexts/ServingAmountMode";

const PREVIOUS_INPUT_VALUES_LIMIT = 3;

type RecipeAndServingAmount = {
  id: string;
  servingAmount: FullServingAmountFragment;
  recipe: RecipeWithServingsFragment;
  orgGroupName: string;
};

type FoodAndServingAmount = {
  id: string;
  servingAmount: FullServingAmountFragment;
  food: FoodWithDefaultServingFragment;
  orgGroupName: string;
};

interface ServingAmountsSearchBarProps {
  selectedServingAmounts: readonly FullServingAmountFragment[];
  onAdd: (servingAmount: FullServingAmountFragment, recipe?: RecipeWithServingsFragment) => void;
  onClickAddFood: () => void;
  noCache: boolean;
  setLoadingChip: (loadingChip: React.ReactNode) => void;
  includeRecipeIngredients?: boolean;
  enableRecipes?: boolean;
  enableBranded?: boolean;
  autoFocus?: boolean;
  addOnSearchBarSx?: SxProps;
}

export const ServingAmountsSearchBar = ({
  enableRecipes,
  enableBranded,
  autoFocus,
  onAdd,
  selectedServingAmounts,
  onClickAddFood,
  noCache,
  setLoadingChip,
  includeRecipeIngredients = false,
  addOnSearchBarSx,
}: ServingAmountsSearchBarProps) => {
  const { locale } = useLocaleContext();
  const servingAmountMode = useServingAmountModeContext();
  const forRestaurant = servingAmountMode.type === "restaurant";
  const restaurantId = servingAmountMode.type === "restaurant" ? servingAmountMode.restaurantId : undefined;
  const { setMessage } = useSnackbar();
  const [addNixBrandedFood] = useAddNixBrandedFoodMutation({
    onError: e => {
      setLoadingChip(null);
      setMessage("error", `Failed to add food: ${e.name}`);
    },
    onCompleted: data => {
      setLoadingChip(null);
      if (data.addNixBrandedFood.food) {
        onAdd(newServingAmount(data.addNixBrandedFood.food.defaultServing, nextPosition));
      } else {
        setMessage("error", "Unable to add food please try adding again or try adding a different food.");
      }
    },
  });
  const { isLikedFood, isDislikedFood } = useAthleteFoodPreferenceContext();
  const { isPromotedFood, isAvoidedFood } = useMealPlanFoodPreferenceContext();
  const isLikedOrPromoted = (id: string) => isLikedFood(id).value || isPromotedFood(id).value;
  const isDislikedOrAvoided = (id: string) => isDislikedFood(id).value || isAvoidedFood(id).value;
  const getLikedAvoidedColor = (id: string) =>
    isLikedOrPromoted(id) ? "success.main" : isDislikedOrAvoided(id) ? "error.main" : undefined;

  const regionQueryValue = getNutritionixBrandedRegionForLocale(locale);

  const previousInputValuesRef = useRef<string[]>([]);

  const inputToNixQueryVariables = (inputValue: string) => {
    return {
      variables: {
        query: inputValue,
        region: regionQueryValue,
      },
    };
  };

  const { localeFilter, dataSourceFilter } = useFoodSearchFiltersForUser();

  const inputToFoodsHookQueryVariables = (inputValue: string) => {
    // Ignore redundant calls
    if (inputValue !== previousInputValuesRef.current[0]) {
      // Update previous input values
      previousInputValuesRef.current = [inputValue, ...previousInputValuesRef.current].slice(0, PREVIOUS_INPUT_VALUES_LIMIT);
    }

    return {
      variables: {
        searchTerm: inputValue,
        limit: 10,
        localeCodes: localeFilter,
        dataSources: dataSourceFilter,
      },
    };
  };

  const handleAddNixBrandedFood = (food: NutritionixBrandedFoodFragment) => {
    addNixBrandedFood({
      variables: {
        input: { nixItemId: food.nixItemId, nixBrandType: food.brand.type },
      },
    });
  };

  const getQueryProps = () => ({
    fetchPolicy: noCache ? "no-cache" : ("network-only" as "network-only" | "no-cache"),
  });

  const nextPosition = getNextPosition(selectedServingAmounts);

  const baseFoodsQueryToServingAmounts = (foods: readonly FoodWithDefaultServingFragment[]) => {
    return foods
      .filter(f => includeRecipeIngredients || !f.isRecipeIngredientOnly)
      .flatMap(food => {
        const serving = food.defaultServing;
        return [newServingAmount({ ...serving, foodOrRecipe: food }, nextPosition)];
      });
  };

  const orgFoodsQueryToServingAmounts = (foods: readonly FoodWithDefaultServingFragment[]): FoodAndServingAmount[] => {
    return foods
      .filter(f => includeRecipeIngredients || !f.isRecipeIngredientOnly)
      .map(food => {
        const serving = food.defaultServing;
        return {
          id: food.id,
          servingAmount: newServingAmount(serving, nextPosition),
          food,
          orgGroupName: food.__typename === "GenericFood" ? food.orgGroup?.name ?? "" : "",
        };
      });
  };

  const foodsQueryToServingAmounts = useCallback(
    (data: FoodsWithDefaultServingOnlyQuery): FoodAndServingAmount[] => {
      return orgFoodsQueryToServingAmounts(data.foods);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nextPosition, includeRecipeIngredients]
  );

  const restaurantFoodsQueryToServingAmounts = useCallback(
    (data: RestaurantFoodsWithDefaultServingOnlyQuery): FullServingAmountFragment[] => {
      return baseFoodsQueryToServingAmounts(data.restaurantFoods);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nextPosition, includeRecipeIngredients]
  );

  const nixFoodsQueryToFoods = useCallback((data: NutritionixBrandedInstantSearchQuery): NutritionixBrandedFoodFragment[] => {
    return [...data.nutritionixBrandedInstantSearch.branded];
  }, []);

  const recipesQueryToServingAmounts = useCallback(
    (data: RecipesQuery): RecipeAndServingAmount[] => {
      const orgRecipes = data.recipes.flatMap(recipe => {
        if (recipe.servings.length === 0) {
          return [];
        }
        const serving = recipe.servings.find(s => s.isDefault) || recipe.servings[0];
        return {
          id: recipe.id,
          servingAmount: newServingAmount({ ...serving, foodOrRecipe: recipe }, nextPosition),
          recipe,
          orgGroupName: "",
        };
      });

      const orgGroupRecipes = data.orgGroupRecipes.flatMap(recipe => {
        if (recipe.servings.length === 0) {
          return [];
        }
        const serving = recipe.servings.find(s => s.isDefault) || recipe.servings[0];
        return {
          id: recipe.id,
          servingAmount: newServingAmount({ ...serving, foodOrRecipe: recipe }, nextPosition),
          recipe,
          orgGroupName: recipe.orgGroup?.name ?? "",
        };
      });

      return [...orgRecipes, ...orgGroupRecipes];
    },
    [nextPosition]
  );

  /*
   * In memoriam: Nutritionix Search Bar
   *
   * Per convo with Chris, we're leaving this commented out for now. This is used
   * for the staff view restaurant menu management modal, but that is currently not
   * intentionally left broken due to the Nutritionix API we used for it no longer existing.
   * Dylan is removing the SearchBar components, so this can't stay here.
   */

  // const renderNutritionixSearchBar = () => {
  //   return (
  //     <SearchBar
  //       label="Nutritionix Foods"
  //       key="nutritionix-food-search-bar"
  //       fullWidth={true}
  //       objectToName={brandedFoodToSearchName}
  //       onSelectObject={(food: NutritionixBrandedFoodFragment) => {
  //         setLoadingChip(<LoadingChip food={food} />);
  //         handleAddNixBrandedFood(food);
  //       }}
  //       menuClassName={classes.menuClassName}
  //       renderSuggestion={renderBrandedSuggestion}
  //       autoFocus={autoFocus}
  //       renderSuggestions={({ renderSuggestion, inputValue }) => {
  //         const matchingFoods =
  //           inputValue === null
  //             ? restaurantNutritionixFoods
  //             : restaurantNutritionixFoods.filter(food => food.name.toLowerCase().includes(inputValue.toLowerCase()));
  //         return matchingFoods.map((obj, idx) => {
  //           const fragmentFood = obj as NutritionixBrandedFoodFragment;
  //           return renderSuggestion(fragmentFood, idx, inputValue);
  //         });
  //       }}
  //       renderInput={({ InputProps, ...props }, { openMenu }) => (
  //         <TextField
  //           InputProps={InputProps}
  //           {...props}
  //           onFocus={() => openMenu()} />
  //       )}
  //     />
  //   );
  // };

  const FOODS = "Foods";
  //const NUTRITIONIX_FOODS = "Nutritionix Foods";
  const RESTAURANT_FOODS = "Restaurant Foods";
  const RECIPES = "Recipes";
  const BRANDED = "Branded";
  const restaurantButtons = [FOODS, /* nutritionix foods, */ RESTAURANT_FOODS];
  const standardButtons = [FOODS, ...(enableRecipes ? [RECIPES] : []), ...(enableBranded ? [BRANDED] : [])];
  const buttons = forRestaurant ? restaurantButtons : standardButtons;
  const [selected, setSelected] = useState(FOODS);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <TWButtonGroup buttons={buttons} onSelected={setSelected} />
      {selected === FOODS && (
        <AutocompleteQuerySearchBar<FoodAndServingAmount, FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>
          sx={{ mt: 0, ...addOnSearchBarSx }}
          query={FoodsWithDefaultServingOnlyDocument}
          placeholder="Search foods"
          key="food"
          fullWidth
          getOptionLabel={o => o.food.name}
          getOptionsFromQueryData={foodsQueryToServingAmounts}
          getQueryOptions={inputToFoodsHookQueryVariables}
          lazyQueryHookOptions={getQueryProps()}
          onChange={o => {
            if (o) {
              onAdd(o.servingAmount);
              previousInputValuesRef.current = ["", ...previousInputValuesRef.current].slice(0, PREVIOUS_INPUT_VALUES_LIMIT);
            }
          }}
          getUserFriendlyQueryErrorMessage={() => "Error searching foods, please try again."}
          autoFocus={autoFocus}
          defaultOption={{
            text: "Request Food",
            onClick: onClickAddFood,
            Icon: AddIcon,
          }}
          foodsSelected={true}
          getOptionSx={option => {
            return { color: getLikedAvoidedColor(option.id) };
          }}
        />
      )}
      {/* {selected === NUTRITIONIX_FOODS && <></>} */}
      {selected === RESTAURANT_FOODS &&
        (restaurantId ? (
          <AutocompleteQuerySearchBar<
            FullServingAmountFragment,
            RestaurantFoodsWithDefaultServingOnlyQuery,
            RestaurantFoodsWithDefaultServingOnlyQueryVariables
          >
            sx={{ mt: 0, ...addOnSearchBarSx }}
            query={RestaurantFoodsWithDefaultServingOnlyDocument}
            placeholder="Search restaurant foods"
            key="db-restaurant-foods-search-bar"
            getOptionLabel={o => o.serving.foodOrRecipe.name}
            getOptionsFromQueryData={restaurantFoodsQueryToServingAmounts}
            getQueryOptions={inputValue => {
              const { variables } = inputToFoodsHookQueryVariables(inputValue);
              return {
                variables: {
                  ...variables,
                  restaurantId,
                },
              };
            }}
            lazyQueryHookOptions={getQueryProps()}
            onChange={o => {
              if (o) {
                onAdd(o);
                previousInputValuesRef.current = ["", ...previousInputValuesRef.current].slice(0, PREVIOUS_INPUT_VALUES_LIMIT);
              }
            }}
            getUserFriendlyQueryErrorMessage={() => "Error searching foods, please try again."}
            autoFocus={autoFocus}
            getOptionSx={option => {
              return { color: getLikedAvoidedColor(option.id) };
            }}
          />
        ) : (
          <Typography key="restaurant-not-found-search">Restaurant Not Found please use other searches or try again.</Typography>
        ))}
      {selected === RECIPES && (
        <AutocompleteQuerySearchBar<RecipeAndServingAmount, RecipesQuery, RecipesQueryVariables>
          sx={{ mt: 0, ...addOnSearchBarSx }}
          query={RecipesDocument}
          placeholder="Search recipes"
          key="recipe"
          fullWidth
          getOptionLabel={o => o.recipe.name}
          getOptionSublabel={o => o.orgGroupName}
          getOptionsFromQueryData={recipesQueryToServingAmounts}
          getQueryOptions={inputToFoodsHookQueryVariables}
          lazyQueryHookOptions={getQueryProps()}
          onChange={o => {
            if (o) {
              onAdd(o.servingAmount, o.recipe);
              previousInputValuesRef.current = ["", ...previousInputValuesRef.current].slice(0, PREVIOUS_INPUT_VALUES_LIMIT);
            }
          }}
          getUserFriendlyQueryErrorMessage={() => "Error searching recipes, please try again."}
          autoFocus={autoFocus}
          getOptionSx={option => {
            return { color: getLikedAvoidedColor(option.id) };
          }}
        />
      )}
      {selected === BRANDED && (
        <AutocompleteQuerySearchBar<
          NutritionixBrandedFoodFragment,
          NutritionixBrandedInstantSearchQuery,
          NutritionixBrandedInstantSearchQueryVariables
        >
          sx={{ mt: 0, ...addOnSearchBarSx }}
          query={NutritionixBrandedInstantSearchDocument}
          placeholder="Search branded foods"
          key="branded"
          fullWidth
          getOptionLabel={o => o.name}
          getOptionSublabel={o => o.brand.name}
          getOptionsFromQueryData={nixFoodsQueryToFoods}
          getQueryOptions={inputToNixQueryVariables}
          minInputChars={2}
          lazyQueryHookOptions={getQueryProps()}
          onChange={o => {
            if (o) {
              setLoadingChip(<LoadingChip food={o} />);
              handleAddNixBrandedFood(o);
            }
          }}
          getUserFriendlyQueryErrorMessage={() => "Error searching foods, please try again."}
          autoFocus={autoFocus}
          getOptionSx={option => {
            return { color: getLikedAvoidedColor(option.id) };
          }}
        />
      )}
    </Box>
  );
};
