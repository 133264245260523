import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import { sortByKey } from "@notemeal/utils-sort";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import React, { useEffect, useState } from "react";
import { MenuOrderItemCounts, menuItemAppearanceIsDeleted } from "../../../components/MenuItemAppearance/utils";
import ReorderDialog from "../../../components/universal/ReorderDialog";
import { MealMenuUserOrderLimitInput } from "../../../types";
import { MenuDialogAction } from "../reducer";
import Form from "./Form";
import QuickActions from "./QuickActions";
import SearchBar from "./SearchBar";
import { MealMenuDiningStationState } from "./types";

interface MealMenuDiningStationGroupFormProps {
  mealMenuDiningStations: readonly MealMenuDiningStationState[];
  orderItemCounts: MenuOrderItemCounts;
  dispatch: (action: MenuDialogAction) => void;
  userOrderLimit: MealMenuUserOrderLimitInput | null;
}

const MealMenuDiningStationGroupForm = ({
  mealMenuDiningStations,
  dispatch,
  orderItemCounts,
  userOrderLimit,
}: MealMenuDiningStationGroupFormProps) => {
  const apolloClient = useApolloClient();

  useEffect(() => {
    apolloClient.cache.evict({
      fieldName: "menuItemCursorConnection",
      broadcast: true,
    });
    apolloClient.cache.evict({
      fieldName: "diningStationTemplateCursorConnection",
      broadcast: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [reorderDialogOpen, setReorderDialogOpen] = useState(false);

  const usedMenuItemIds = mealMenuDiningStations.flatMap(mmds =>
    mmds.menuItemAppearances.filter(mia => !menuItemAppearanceIsDeleted(mia)).map(mia => mia.menuItem.id)
  );

  return (
    <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <SectionHeader
        header="Edit In-House Menu"
        subcopy="Menus are organized by Dining Station. Choose a station from the dropdown to add this menu."
      />
      <QuickActions
        mealMenuDiningStations={mealMenuDiningStations}
        userOrderLimit={userOrderLimit}
        dispatch={dispatch}
        onReorder={() => setReorderDialogOpen(true)}
      />
      <SearchBar
        diningStationTemplateIds={mealMenuDiningStations.flatMap(mmds => mmds.diningStationTemplate?.id ?? [])}
        onAdd={diningStationTemplate =>
          dispatch({
            type: "AddMenuDiningStationAction",
            payload: {
              diningStationTemplate,
            },
          })
        }
        onCreate={name =>
          dispatch({
            type: "CreateMenuDiningStationAction",
            payload: {
              name,
            },
          })
        }
      />
      {sortByKey(mealMenuDiningStations, "position").map(mmds => (
        <Form
          key={mmds.id}
          usedMenuItemIds={usedMenuItemIds}
          mealMenuDiningStation={mmds}
          orderItemCounts={orderItemCounts}
          dispatch={dispatch}
        />
      ))}
      {reorderDialogOpen && (
        <ReorderDialog
          open={reorderDialogOpen}
          onClose={() => setReorderDialogOpen(false)}
          reorderableArray={mealMenuDiningStations}
          onDone={mealMenuDiningStations =>
            dispatch({
              type: "ReorderMenuDiningStationAction",
              payload: {
                mealMenuDiningStations,
              },
            })
          }
        />
      )}
    </Box>
  );
};

export default MealMenuDiningStationGroupForm;
