import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { parseDate, serializeDate } from "@notemeal/utils-date-time";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { getDay } from "date-fns";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import DiscardChangesDialog from "../../../components/universal/DiscardChangesDialog";
import LoadingBackdrop from "../../../components/universal/LoadingBackdrop";
import { PlannedMenuTableItemFragment } from "../../../types";
import { PlannedMenuForm } from "./PlannedMenuForm";
import { PlannedMenuFormSchema, PlannedMenuFormType, plannedMenuFormDefaultValues } from "./PlannedMenuFormSchema";
import { usePlannedMenuPlannedMenuMutation } from "./PlannedMenusFormProvider";

interface PlannedMenuDuplicateDialogProps {
  open: boolean;
  onClose: () => void;
  menuToDuplicate: PlannedMenuTableItemFragment;
}

export const PlannedMenuDuplicateDialog = ({ open, onClose, menuToDuplicate }: PlannedMenuDuplicateDialogProps) => {
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);
  const { duplicatePlannedMenu, duplicatePlannedMenuCalled } = usePlannedMenuPlannedMenuMutation();

  // when duplicating the user must select the same day of the week as the original menu
  // so the duplicated menu doesn't leave meals stranded on disabled days due to a different start day
  const shouldDisableStartDate = (date: Date) => {
    return getDay(date) !== getDay(parseDate(menuToDuplicate.startDate));
  };

  const form = useForm<PlannedMenuFormType>({
    defaultValues: {
      ...plannedMenuFormDefaultValues({ ...menuToDuplicate }, getDay(parseDate(menuToDuplicate.startDate))),
      menuName: `[Copy of] ${menuToDuplicate.name}`,
    },
    resolver: zodResolver(PlannedMenuFormSchema),
    mode: "onChange",
  });

  const { isDirty, isValid } = form.formState;

  const handleClose = () => {
    if (isDirty) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  const submit = async (newMenu: PlannedMenuFormType) => {
    duplicatePlannedMenu({
      variables: {
        input: {
          originalMenuId: menuToDuplicate.id,
          name: newMenu.menuName,
          description: newMenu.menuDescription,
          startDate: serializeDate(newMenu.dateRange.startDate),
          endDate: serializeDate(newMenu.dateRange.endDate),
          timezone: newMenu.timezone,
        },
      },
    });
  };

  return duplicatePlannedMenuCalled ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <Dialog
      PaperProps={{
        sx: { width: "900px", height: "600px" },
      }}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle title="Duplicate Menu" onClose={handleClose} />
      <DialogContent>
        <PlannedMenuForm
          form={form}
          mode="duplicate"
          numWeeks={menuToDuplicate.weeks.length}
          shouldDisableStartDate={shouldDisableStartDate}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={!isValid} onClick={form.handleSubmit(submit)}>
            Save
          </Button>
        </Box>
      </DialogActions>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}
    </Dialog>
  );
};
