import {
  AnthroInfoState,
  EditCalorieBudgetState,
  EditImperialMacroProtocolState,
  EditMacroState,
  EditMetricMacroProtocolState,
  GoalEditState,
  SaveableMacroProtocolState,
  getAnthropometrySnapshotFromAnthroInfoState,
} from "@notemeal/shared-ui";
import { serializeDate } from "@notemeal/utils-date-time";
import { newId } from "@notemeal/shared-ui";
import {
  MacroMath,
  MacroTarget,
  RmrCaloriesArgs,
  applyMetricWeightTarget,
  applyWeightTarget,
  gPerKgToPercent,
  hasRmrErrors,
  macroMathsToMacroTargets,
  maybeRoundToHundredthsFloor,
  measurementConversionToMetric,
  metricGPerKgToPercent,
  metricMacroMathsToMacroTargets,
} from "@notemeal/shared-utils-macro-protocol";
import { RMR_METHOD_ORDER } from "../components/MacroProtocol/utils";
import {
  AnthropometryEntryFragment,
  CalorieBudgetInput,
  FullGoalFragment,
  FullMacroProtocolFragment,
  GoalTypeFragment,
  MacroMathFragment,
  MacroProtocolInput,
  MacroProtocolNewInput,
  MacroProtocolWithNewGoalInput,
  MacroProtocolWithOldGoalInput,
  MetricMacroProtocolInput,
  RmrMethod,
} from "../types";

export interface IMACRO_PROTOCOL_DEFAULTS {
  activityFactor: number;
  kcalOffset: number;
  rmrCalories: number;
  cho: MacroMathFragment;
  pro: MacroMathFragment;
  fat: MacroMathFragment;
}

export const MACRO_PROTOCOL_DEFAULTS: IMACRO_PROTOCOL_DEFAULTS = {
  activityFactor: 1.2,
  kcalOffset: 0,
  rmrCalories: 2000,
  cho: {
    gPerKg: 0,
    leftOverRatio: 50,
  },
  pro: {
    gPerKg: 0,
    leftOverRatio: 25,
  },
  fat: {
    gPerKg: 0,
    leftOverRatio: 25,
  },
};

const macroTargetToEditState = (target: MacroTarget): EditMacroState => ({
  ...target,
  percentInput: target.percent.toString(),
  gPerKGInput: target.gPerKg.toString(),
});

// TODO: metric update - deprecate and use macroProtocolToEditMetricState()?
export const macroProtocolToEditState = (
  protocol: FullMacroProtocolFragment,
  mostRecentAnthropometryEntry: AnthropometryEntryFragment,
  athleteAge: number | null,
  goalTypes: readonly GoalTypeFragment[],
  currentGoal?: FullGoalFragment,
  anthroMode: AnthroInfoState["mode"] = "macroProtocol"
): EditImperialMacroProtocolState => {
  const mostRecentAnthropometrySnapshot = {
    ...mostRecentAnthropometryEntry,
    age: athleteAge,
  };

  const macroProtocolAnthroSnapshot = { ...protocol.anthropometrySnapshot, age: athleteAge };

  const anthroInfo: AnthroInfoState = {
    mode: anthroMode,
    mostRecentAnthropometrySnapshot,
    macroProtocolAnthroSnapshot,
  };

  const anthropometrySnapshot = getAnthropometrySnapshotFromAnthroInfoState(anthroInfo);

  const weightTarget = protocol.weightTarget ? protocol.weightTarget : anthropometrySnapshot.weight;
  const weightTargetState = {
    weightTarget,
    weightTargetInput: String(maybeRoundToHundredthsFloor(weightTarget)),
    usingWeightTarget: protocol.weightTarget !== null,
  };
  const targetAnthroSnapshot = applyWeightTarget(anthropometrySnapshot, weightTarget);

  const activityFactor = (protocol.calorieBudget && protocol.calorieBudget.activityFactor) || MACRO_PROTOCOL_DEFAULTS.activityFactor;
  let rmrMethod: RmrMethod | null = null;
  if (protocol.calorieBudget && protocol.calorieBudget.rmrMethod) {
    const rmrCaloriesArgs: RmrCaloriesArgs = { rmrMethod: protocol.calorieBudget.rmrMethod, ...anthropometrySnapshot };
    if (!hasRmrErrors(rmrCaloriesArgs)) {
      rmrMethod = protocol.calorieBudget.rmrMethod;
    }
  } else if (protocol.calorieBudget && protocol.calorieBudget.rmrMethod === null) {
    rmrMethod = null;
  } else {
    rmrMethod = RMR_METHOD_ORDER.find(rmrMethod => !hasRmrErrors({ rmrMethod, ...anthropometrySnapshot })) || null;
  }
  const rmrCalories =
    protocol.calorieBudget && protocol.calorieBudget.rmrCalories ? protocol.calorieBudget.rmrCalories : MACRO_PROTOCOL_DEFAULTS.rmrCalories;
  const goalSnapshot = currentGoal ? { ...currentGoal, kcalOffsetInput: String(currentGoal.kcalOffset) } : newGoalEditState();

  if (protocol.calorieBudget) {
    const { cho, pro, fat } = protocol;
    const calBudget = { ...protocol.calorieBudget, goalSnapshot };
    const {
      cho: choMacroTarget,
      pro: proMacroTarget,
      fat: fatMacroTarget,
    } = macroMathsToMacroTargets({ cho, pro, fat }, calBudget, targetAnthroSnapshot);

    return {
      anthroInfo,
      calorieBudget: {
        ...protocol.calorieBudget,
        goalSnapshot,
        kcalOffsetInput: protocol.calorieBudget.kcalOffset.toString(),
        activityFactor,
        activityFactorInput: activityFactor.toString(),
        rmrMethod,
        rmrCalories,
        rmrCaloriesInput: String(rmrCalories),
        goalTypes,
      },
      cho: macroTargetToEditState(choMacroTarget),
      pro: macroTargetToEditState(proMacroTarget),
      fat: macroTargetToEditState(fatMacroTarget),
      usingCalorieBudget: true,
      __typename: "imperial",
      ...weightTargetState,
    };
  } else {
    const calorieBudget: EditCalorieBudgetState = {
      id: newId(),
      __typename: "CalorieBudget",
      goalSnapshot,
      kcalOffset: MACRO_PROTOCOL_DEFAULTS.kcalOffset,
      kcalOffsetInput: MACRO_PROTOCOL_DEFAULTS.kcalOffset.toString(),
      activityFactor,
      activityFactorInput: activityFactor.toString(),
      rmrMethod,
      rmrCalories,
      rmrCaloriesInput: String(rmrCalories),
      goalTypes,
    };

    return {
      anthroInfo,
      calorieBudget,
      cho: macroTargetToEditState({
        percent: gPerKgToPercent(protocol.cho.gPerKg, "cho", targetAnthroSnapshot, calorieBudget),
        gPerKg: protocol.cho.gPerKg,
        usesPercent: false,
      }),
      pro: macroTargetToEditState({
        percent: gPerKgToPercent(protocol.pro.gPerKg, "pro", targetAnthroSnapshot, calorieBudget),
        gPerKg: protocol.pro.gPerKg,
        usesPercent: false,
      }),
      fat: macroTargetToEditState({
        percent: gPerKgToPercent(protocol.fat.gPerKg, "fat", targetAnthroSnapshot, calorieBudget),
        gPerKg: protocol.fat.gPerKg,
        usesPercent: false,
      }),
      usingCalorieBudget: false,
      __typename: "imperial",
      ...weightTargetState,
    };
  }
};

export const metricMacroProtocolToEditMetricState = (
  protocol: FullMacroProtocolFragment,
  mostRecentAnthropometryEntry: AnthropometryEntryFragment,
  athleteAge: number | null,
  goalTypes: readonly GoalTypeFragment[],
  currentGoal?: FullGoalFragment,
  anthroMode: AnthroInfoState["mode"] = "macroProtocol"
): EditMetricMacroProtocolState => {
  const mostRecentAnthropometrySnapshot = {
    ...mostRecentAnthropometryEntry,
    age: athleteAge,
  };

  const macroProtocolAnthroSnapshot = { ...protocol.anthropometrySnapshot, age: athleteAge };

  const anthroInfo: AnthroInfoState = {
    mode: anthroMode,
    mostRecentAnthropometrySnapshot,
    macroProtocolAnthroSnapshot,
  };

  const anthropometrySnapshot = getAnthropometrySnapshotFromAnthroInfoState(anthroInfo);

  const weightTargetInKg = protocol.weightTargetInKg ? protocol.weightTargetInKg : anthropometrySnapshot.weightInKg;
  const metricWeightTargetState = {
    weightTargetInKg,
    weightTargetInput: String(maybeRoundToHundredthsFloor(weightTargetInKg)),
    usingWeightTarget: protocol.weightTargetInKg !== null,
  };

  const metricTargetAnthroSnapshot = applyMetricWeightTarget(anthropometrySnapshot, weightTargetInKg);

  const activityFactor = (protocol.calorieBudget && protocol.calorieBudget.activityFactor) || MACRO_PROTOCOL_DEFAULTS.activityFactor;
  let rmrMethod: RmrMethod | null = null;
  if (protocol.calorieBudget && protocol.calorieBudget.rmrMethod) {
    const rmrCaloriesArgs: RmrCaloriesArgs = { rmrMethod: protocol.calorieBudget.rmrMethod, ...anthropometrySnapshot };
    if (!hasRmrErrors(rmrCaloriesArgs)) {
      rmrMethod = protocol.calorieBudget.rmrMethod;
    }
  } else if (protocol.calorieBudget && protocol.calorieBudget.rmrMethod === null) {
    rmrMethod = null;
  } else {
    rmrMethod = RMR_METHOD_ORDER.find(rmrMethod => !hasRmrErrors({ rmrMethod, ...anthropometrySnapshot })) || null;
  }
  const rmrCalories =
    protocol.calorieBudget && protocol.calorieBudget.rmrCalories ? protocol.calorieBudget.rmrCalories : MACRO_PROTOCOL_DEFAULTS.rmrCalories;
  const goalSnapshot = currentGoal ? { ...currentGoal, kcalOffsetInput: String(currentGoal.kcalOffset) } : newGoalEditState();

  if (protocol.calorieBudget) {
    const { cho, pro, fat } = protocol;
    const calBudget = { ...protocol.calorieBudget, goalSnapshot };

    const {
      cho: choMacroTarget,
      pro: proMacroTarget,
      fat: fatMacroTarget,
    } = metricMacroMathsToMacroTargets({ cho, pro, fat }, calBudget, metricTargetAnthroSnapshot);

    return {
      anthroInfo,
      calorieBudget: {
        ...protocol.calorieBudget,
        goalSnapshot,
        kcalOffsetInput: protocol.calorieBudget.kcalOffset.toString(),
        activityFactor,
        activityFactorInput: activityFactor.toString(),
        rmrMethod,
        rmrCalories,
        rmrCaloriesInput: String(rmrCalories),
        goalTypes,
      },
      cho: macroTargetToEditState(choMacroTarget),
      pro: macroTargetToEditState(proMacroTarget),
      fat: macroTargetToEditState(fatMacroTarget),
      usingCalorieBudget: true,
      __typename: "metric",
      ...metricWeightTargetState,
    };
  } else {
    const calorieBudget: EditCalorieBudgetState = {
      id: newId(),
      __typename: "CalorieBudget",
      goalSnapshot,
      kcalOffset: MACRO_PROTOCOL_DEFAULTS.kcalOffset,
      kcalOffsetInput: MACRO_PROTOCOL_DEFAULTS.kcalOffset.toString(),
      activityFactor,
      activityFactorInput: activityFactor.toString(),
      rmrMethod,
      rmrCalories,
      rmrCaloriesInput: String(rmrCalories),
      goalTypes,
    };

    return {
      anthroInfo,
      calorieBudget,
      cho: macroTargetToEditState({
        percent: metricGPerKgToPercent(protocol.cho.gPerKg, "cho", metricTargetAnthroSnapshot, calorieBudget),
        gPerKg: protocol.cho.gPerKg,
        usesPercent: false,
      }),
      pro: macroTargetToEditState({
        percent: metricGPerKgToPercent(protocol.pro.gPerKg, "pro", metricTargetAnthroSnapshot, calorieBudget),
        gPerKg: protocol.pro.gPerKg,
        usesPercent: false,
      }),
      fat: macroTargetToEditState({
        percent: metricGPerKgToPercent(protocol.fat.gPerKg, "fat", metricTargetAnthroSnapshot, calorieBudget),
        gPerKg: protocol.fat.gPerKg,
        usesPercent: false,
      }),
      usingCalorieBudget: false,
      __typename: "metric",
      ...metricWeightTargetState,
    };
  }
};

// TODO: metric update - deprecate and use newMetricMacroProtocolEditMetricState()?
export const newMacroProtocolEditState = (
  mostRecentAnthropometryEntry: AnthropometryEntryFragment,
  athleteAge: number | null,
  goalTypes: readonly GoalTypeFragment[],
  currentGoal?: FullGoalFragment
): EditImperialMacroProtocolState => {
  const anthropometrySnapshot = {
    ...mostRecentAnthropometryEntry,
    age: athleteAge,
  };
  const weightTarget = anthropometrySnapshot.weight;

  const rmrMethod = RMR_METHOD_ORDER.find(rmrMethod => !hasRmrErrors({ rmrMethod, ...anthropometrySnapshot })) || null;

  const goalSnapshot = currentGoal ? { ...currentGoal, kcalOffsetInput: String(currentGoal.kcalOffset) } : newGoalEditState();

  const calorieBudget: EditCalorieBudgetState = {
    id: newId(),
    __typename: "CalorieBudget",
    goalSnapshot,
    goalTypes,
    kcalOffset: MACRO_PROTOCOL_DEFAULTS.kcalOffset,
    kcalOffsetInput: MACRO_PROTOCOL_DEFAULTS.kcalOffset.toString(),
    activityFactor: MACRO_PROTOCOL_DEFAULTS.activityFactor,
    activityFactorInput: MACRO_PROTOCOL_DEFAULTS.activityFactor.toString(),
    rmrMethod,
    rmrCalories: MACRO_PROTOCOL_DEFAULTS.rmrCalories,
    rmrCaloriesInput: MACRO_PROTOCOL_DEFAULTS.rmrCalories.toString(),
  };

  const { cho, pro, fat } = MACRO_PROTOCOL_DEFAULTS;
  const {
    cho: choMacroTarget,
    pro: proMacroTarget,
    fat: fatMacroTarget,
  } = macroMathsToMacroTargets({ cho, pro, fat }, calorieBudget, anthropometrySnapshot);

  return {
    anthroInfo: { macroProtocolAnthroSnapshot: null, mostRecentAnthropometrySnapshot: anthropometrySnapshot, mode: "mostRecent" },
    cho: macroTargetToEditState(choMacroTarget),
    pro: macroTargetToEditState(proMacroTarget),
    fat: macroTargetToEditState(fatMacroTarget),
    calorieBudget,
    usingCalorieBudget: true,
    weightTarget,
    weightTargetInput: String(maybeRoundToHundredthsFloor(weightTarget)),
    usingWeightTarget: false,
    __typename: "imperial",
  };
};

export const newMetricMacroProtocolEditMetricState = (
  mostRecentAnthropometryEntry: AnthropometryEntryFragment,
  athleteAge: number | null,
  goalTypes: readonly GoalTypeFragment[],
  currentGoal?: FullGoalFragment
): EditMetricMacroProtocolState => {
  const anthropometrySnapshot = {
    ...mostRecentAnthropometryEntry,
    age: athleteAge,
  };

  const weightTargetInKg = anthropometrySnapshot.weightInKg;

  const rmrMethod = RMR_METHOD_ORDER.find(rmrMethod => !hasRmrErrors({ rmrMethod, ...anthropometrySnapshot })) || null;

  const goalSnapshot = currentGoal ? { ...currentGoal, kcalOffsetInput: String(currentGoal.kcalOffset) } : newGoalEditState();

  const calorieBudget: EditCalorieBudgetState = {
    id: newId(),
    __typename: "CalorieBudget",
    goalSnapshot,
    goalTypes,
    kcalOffset: MACRO_PROTOCOL_DEFAULTS.kcalOffset,
    kcalOffsetInput: MACRO_PROTOCOL_DEFAULTS.kcalOffset.toString(),
    activityFactor: MACRO_PROTOCOL_DEFAULTS.activityFactor,
    activityFactorInput: MACRO_PROTOCOL_DEFAULTS.activityFactor.toString(),
    rmrMethod,
    rmrCalories: MACRO_PROTOCOL_DEFAULTS.rmrCalories,
    rmrCaloriesInput: MACRO_PROTOCOL_DEFAULTS.rmrCalories.toString(),
  };

  const { cho, pro, fat } = MACRO_PROTOCOL_DEFAULTS;
  const {
    cho: choMacroTarget,
    pro: proMacroTarget,
    fat: fatMacroTarget,
  } = metricMacroMathsToMacroTargets({ cho, pro, fat }, calorieBudget, anthropometrySnapshot);

  return {
    anthroInfo: { macroProtocolAnthroSnapshot: null, mostRecentAnthropometrySnapshot: anthropometrySnapshot, mode: "mostRecent" },
    cho: macroTargetToEditState(choMacroTarget),
    pro: macroTargetToEditState(proMacroTarget),
    fat: macroTargetToEditState(fatMacroTarget),
    calorieBudget,
    usingCalorieBudget: true,
    weightTargetInKg,
    weightTargetInput: String(maybeRoundToHundredthsFloor(weightTargetInKg)),
    usingWeightTarget: false,
    __typename: "metric",
  };
};

// TODO: metric update - deprecate and use editMetricMacroProtocolStateInMealPlanToMetricMacroProtocolInput()?
// seems to be only used for macroprotocols WITHIN meal plans
export const editStateToMacroProtocolNewInput = (
  { cho, pro, fat, usingCalorieBudget, usingWeightTarget, weightTarget, anthroInfo, calorieBudget }: EditImperialMacroProtocolState,
  currentGoal: FullGoalFragment | null
): MacroProtocolNewInput | null => {
  const anthropometrySnapshot = getAnthropometrySnapshotFromAnthroInfoState(anthroInfo);
  const commonInput = {
    cho: macroTargetStateToMathInput(cho, usingCalorieBudget),
    pro: macroTargetStateToMathInput(pro, usingCalorieBudget),
    fat: macroTargetStateToMathInput(fat, usingCalorieBudget),
    weightTarget: usingWeightTarget ? weightTarget && weightTarget : null,
    anthropometryEntryId: anthropometrySnapshot.id,
  };

  if (usingCalorieBudget) {
    if (!calorieBudget.goalSnapshot.type) {
      return null;
    }

    if (currentGoal?.type.id === calorieBudget.goalSnapshot.type.id && currentGoal.kcalOffset === calorieBudget.goalSnapshot.kcalOffset) {
      return {
        ...commonInput,
        calorieBudget: editCalorieBudgetStateToInput(calorieBudget),
        goalId: calorieBudget.goalSnapshot.id,
        goal: null,
      };
    } else {
      return {
        ...commonInput,
        calorieBudget: editCalorieBudgetStateToInput(calorieBudget),
        goalId: null,
        goal: {
          goalTypeId: calorieBudget.goalSnapshot.type.id,
          kcalOffset: calorieBudget.goalSnapshot.kcalOffset,
          start: serializeDate(new Date()),
          end: null,
        },
      };
    }
  } else {
    return {
      ...commonInput,
      calorieBudget: null,
      goalId: null,
      goal: null,
    };
  }
};

// very long function name but hoping to facilitate faster comprehension for future engineers
export const editMetricMacroProtocolStateInMealPlanToMetricMacroProtocolInput = (
  { cho, pro, fat, usingCalorieBudget, usingWeightTarget, weightTargetInKg, anthroInfo, calorieBudget }: EditMetricMacroProtocolState,
  currentGoal: FullGoalFragment | null
): MetricMacroProtocolInput | null => {
  const anthropometrySnapshot = getAnthropometrySnapshotFromAnthroInfoState(anthroInfo);
  const commonInput = {
    cho: macroTargetStateToMathInput(cho, usingCalorieBudget),
    pro: macroTargetStateToMathInput(pro, usingCalorieBudget),
    fat: macroTargetStateToMathInput(fat, usingCalorieBudget),
    weightTargetInKg: usingWeightTarget ? weightTargetInKg && weightTargetInKg : null,
    anthropometryEntryId: anthropometrySnapshot.id,
  };

  if (usingCalorieBudget) {
    if (!calorieBudget.goalSnapshot.type) {
      return null;
    }

    if (currentGoal?.type.id === calorieBudget.goalSnapshot.type.id && currentGoal.kcalOffset === calorieBudget.goalSnapshot.kcalOffset) {
      return {
        ...commonInput,
        calorieBudget: editCalorieBudgetStateToInput(calorieBudget),
        goalId: calorieBudget.goalSnapshot.id,
        goal: null,
      };
    } else {
      return {
        ...commonInput,
        calorieBudget: editCalorieBudgetStateToInput(calorieBudget),
        goalId: null,
        goal: {
          goalTypeId: calorieBudget.goalSnapshot.type.id,
          kcalOffset: calorieBudget.goalSnapshot.kcalOffset,
          start: serializeDate(new Date()),
          end: null,
        },
      };
    }
  } else {
    return {
      ...commonInput,
      calorieBudget: null,
      goalId: null,
      goal: null,
    };
  }
};

export const editImperialMacroProtocolStateInMealPlanToMetricMacroProtocolInput = (
  { cho, pro, fat, usingCalorieBudget, usingWeightTarget, weightTarget, anthroInfo, calorieBudget }: EditImperialMacroProtocolState,
  currentGoal: FullGoalFragment | null
): MetricMacroProtocolInput | null => {
  const anthropometrySnapshot = getAnthropometrySnapshotFromAnthroInfoState(anthroInfo);
  const weightTargetInKg = measurementConversionToMetric(false, weightTarget, "weight");

  const commonInput = {
    cho: macroTargetStateToMathInput(cho, usingCalorieBudget),
    pro: macroTargetStateToMathInput(pro, usingCalorieBudget),
    fat: macroTargetStateToMathInput(fat, usingCalorieBudget),
    weightTargetInKg: usingWeightTarget ? weightTargetInKg && weightTargetInKg : null,
    anthropometryEntryId: anthropometrySnapshot.id,
  };

  if (usingCalorieBudget) {
    if (!calorieBudget.goalSnapshot.type) {
      return null;
    }

    if (currentGoal?.type.id === calorieBudget.goalSnapshot.type.id && currentGoal.kcalOffset === calorieBudget.goalSnapshot.kcalOffset) {
      return {
        ...commonInput,
        calorieBudget: editCalorieBudgetStateToInput(calorieBudget),
        goalId: calorieBudget.goalSnapshot.id,
        goal: null,
      };
    } else {
      return {
        ...commonInput,
        calorieBudget: editCalorieBudgetStateToInput(calorieBudget),
        goalId: null,
        goal: {
          goalTypeId: calorieBudget.goalSnapshot.type.id,
          kcalOffset: calorieBudget.goalSnapshot.kcalOffset,
          start: serializeDate(new Date()),
          end: null,
        },
      };
    }
  } else {
    return {
      ...commonInput,
      calorieBudget: null,
      goalId: null,
      goal: null,
    };
  }
};

// TODO: metric update - to be deprecated and replaced with editMetricStateToMetricMacroProtocolInputCommon
export const editStateToMacroProtocolInput = ({
  cho,
  pro,
  fat,
  usingCalorieBudget,
  usingWeightTarget,
  weightTarget,
  anthroInfo,
}: EditImperialMacroProtocolState): MacroProtocolInput => ({
  cho: macroTargetStateToMathInput(cho, usingCalorieBudget),
  pro: macroTargetStateToMathInput(pro, usingCalorieBudget),
  fat: macroTargetStateToMathInput(fat, usingCalorieBudget),
  weightTarget: usingWeightTarget ? weightTarget && weightTarget : null,
  anthropometryEntryId: getAnthropometrySnapshotFromAnthroInfoState(anthroInfo).id,
});

export const editMetricStateToMetricMacroProtocolInput = (
  metricState: EditMetricMacroProtocolState,
  currentGoal: FullGoalFragment | null
): MetricMacroProtocolInput | null => {
  const { cho, pro, fat, usingCalorieBudget, usingWeightTarget, calorieBudget, weightTargetInKg, anthroInfo } = metricState;
  const { goalSnapshot } = calorieBudget;
  const { type: goalType } = goalSnapshot;
  const anthropometrySnapshotId = getAnthropometrySnapshotFromAnthroInfoState(anthroInfo).id;

  const baseMacroProtocolInput = {
    cho: macroTargetStateToMathInput(cho, usingCalorieBudget),
    pro: macroTargetStateToMathInput(pro, usingCalorieBudget),
    fat: macroTargetStateToMathInput(fat, usingCalorieBudget),
    weightTargetInKg: usingWeightTarget ? weightTargetInKg && weightTargetInKg : null,
    anthropometryEntryId: anthropometrySnapshotId,
    goal: null,
    goalId: null,
    calorieBudget: null,
  };

  if (!usingCalorieBudget) {
    return baseMacroProtocolInput;
  } else if (goalType) {
    if (currentGoal && currentGoal.type.id === goalType.id && currentGoal.kcalOffset === goalSnapshot.kcalOffset) {
      return {
        ...baseMacroProtocolInput,
        calorieBudget: editCalorieBudgetStateToInput(calorieBudget),
        goalId: goalSnapshot.id,
        goal: null,
      };
    } else {
      const newGoal = {
        goalTypeId: goalType.id,
        kcalOffset: goalSnapshot.kcalOffset,
        start: serializeDate(new Date()),
        end: null,
      };

      return {
        ...baseMacroProtocolInput,
        calorieBudget: editCalorieBudgetStateToInput(calorieBudget),
        goal: newGoal,
        goalId: null,
      };
    }
  }
  return null;
};

export const editImperialStateToMetricMacroProtocolInput = (
  imperialState: EditImperialMacroProtocolState,
  currentGoal: FullGoalFragment | null
): MetricMacroProtocolInput | null => {
  const { cho, pro, fat, usingCalorieBudget, usingWeightTarget, calorieBudget: _calorieBudget, weightTarget, anthroInfo } = imperialState;

  const { goalSnapshot } = _calorieBudget;
  const { type: goalType } = goalSnapshot;

  const anthropometrySnapshotId = getAnthropometrySnapshotFromAnthroInfoState(anthroInfo).id;

  const weightTargetInKg = measurementConversionToMetric(false, weightTarget, "weight");

  const baseMacroProtocolInput = {
    cho: macroTargetStateToMathInput(cho, usingCalorieBudget),
    pro: macroTargetStateToMathInput(pro, usingCalorieBudget),
    fat: macroTargetStateToMathInput(fat, usingCalorieBudget),
    weightTargetInKg: usingWeightTarget ? weightTargetInKg && weightTargetInKg : null,
    anthropometryEntryId: anthropometrySnapshotId,
    goal: null,
    goalId: null,
    calorieBudget: null,
  };

  if (!usingCalorieBudget) {
    return baseMacroProtocolInput;
  } else if (goalType) {
    if (currentGoal && currentGoal.type.id === goalType.id && currentGoal.kcalOffset === goalSnapshot.kcalOffset) {
      return {
        ...baseMacroProtocolInput,
        calorieBudget: editCalorieBudgetStateToInput(_calorieBudget),
        goalId: goalSnapshot.id,
        goal: null,
      };
    } else {
      const newGoal = {
        goalTypeId: goalType.id,
        kcalOffset: goalSnapshot.kcalOffset,
        start: serializeDate(new Date()),
        end: null,
      };

      return {
        ...baseMacroProtocolInput,
        calorieBudget: editCalorieBudgetStateToInput(_calorieBudget),
        goal: newGoal,
        goalId: null,
      };
    }
  }
  return null;
};

// TODO: metric update - to be deprecated and replaced with editStateToMetricMacroProtocolInput
export const editStateToMacroProtocolWithOldGoalInput = (state: SaveableMacroProtocolState): MacroProtocolWithOldGoalInput => ({
  ...editStateToMacroProtocolInput(state),
  calorieBudget: editCalorieBudgetStateToInput(state.calorieBudget),
  goalId: state.calorieBudget.goalSnapshot.id,
});

// TODO: metric update - to be deprecated and replaced with editStateToMetricMacroProtocolInput
export const editStateToMacroProtocolWithNewGoalInput = (state: SaveableMacroProtocolState): MacroProtocolWithNewGoalInput => ({
  ...editStateToMacroProtocolInput(state),
  calorieBudget: editCalorieBudgetStateToInput(state.calorieBudget),
  goal: {
    goalTypeId: state.calorieBudget.goalSnapshot.type.id,
    kcalOffset: state.calorieBudget.goalSnapshot.kcalOffset,
    start: serializeDate(new Date()),
    end: null,
  },
});

export const macroTargetStateToMathInput = (state: MacroTarget, usingCalorieBudget: boolean): MacroMath => ({
  // TODO: is this the proper logic with usingCalorieBudget
  gPerKg: usingCalorieBudget && state.usesPercent ? 0 : state.gPerKg,
  leftOverRatio: usingCalorieBudget && state.usesPercent ? state.percent : 0,
});

const editCalorieBudgetStateToInput = ({
  rmrMethod,
  activityFactor,
  kcalOffset,
  rmrCalories,
}: EditCalorieBudgetState): CalorieBudgetInput => ({
  rmrMethod,
  activityFactor,
  kcalOffset,
  rmrCalories: rmrMethod === null ? rmrCalories : null,
});

const newGoalEditState = (): GoalEditState => ({
  id: newId(),
  __typename: "Goal",
  type: null,
  kcalOffset: 0,
  kcalOffsetInput: "",
  // Hacky
  start: "",
  end: "",
});
