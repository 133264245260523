import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { resetPassword } from "@notemeal/shared-ui";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { useCookieContext } from "../../contexts/Cookie";

enum PageState {
  ProvideNewPassword,
  Finished,
}

export const ResetPasswordPage = () => {
  const { resetPasswordToken: maybeResetPasswordToken } = useParams();
  const resetPasswordToken = maybeResetPasswordToken || "";
  const { forceRefresh } = useCookieContext();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [pageState, setPageState] = useState<PageState>(PageState.ProvideNewPassword);

  const handleChangePassword = (password: string) => {
    setPassword(password);
    setErrorPassword("");
    setErrorConfirmPassword("");
  };

  const handleChangeConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);
    setErrorPassword("");
    setErrorConfirmPassword("");
  };

  const handleResetPassword = () => {
    if (password.length < 8) {
      setErrorPassword("Password must have at least 8 characters");
    } else if (password !== confirmPassword) {
      setErrorConfirmPassword("Passwords must match");
    } else {
      resetPassword({ resetPasswordToken, password })
        .then(() => {
          setPageState(PageState.Finished);
        })
        .catch(e => {
          setErrorPassword(e.response?.data?.errors[0]);
        });
    }
  };

  const handleFinished = () => {
    forceRefresh();
    navigate(-1);
  };

  const provideNewPassword = (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h1">Reset password</Typography>
        <Typography variant="subtitle1">Reset your password below.</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="New Password"
          error={!!errorPassword}
          helperText={errorPassword}
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={e => handleChangePassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  size="large">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Confirm New Password"
          error={!!errorConfirmPassword}
          helperText={errorConfirmPassword}
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={e => handleChangeConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle confirm password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  size="large"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Button disabled={!password && !confirmPassword} onClick={handleResetPassword}>
        Reset Password
      </Button>
    </>
  );

  const finished = (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h1">Password confirmed</Typography>
        <Typography variant="subtitle1">Your password has successfully been reset.</Typography>
      </Box>
      <Button onClick={handleFinished}>Take me to the app</Button>
    </>
  );

  return pageState === PageState.ProvideNewPassword ? provideNewPassword : finished;
};
