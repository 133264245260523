import { FORM_NUTRIENTS, NutrientAmountState } from "@notemeal/shared-ui";

export type Mode = "staff" | "org" | "restaurant";

export const scaleNutrientValues = (
  nutrientAmountState: NutrientAmountState,
  newWeight: number,
  oldWeight: number
): NutrientAmountState => {
  let scaledValues: NutrientAmountState = {};
  FORM_NUTRIENTS.forEach(nutrient => {
    const _value = parseFloat(nutrientAmountState[nutrient]) * (newWeight / oldWeight);

    const value = Number.isNaN(_value) ? "" : _value;

    scaledValues = {
      ...scaledValues,
      [nutrient]: value.toString(),
    };
  });
  return scaledValues;
};
